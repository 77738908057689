import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  importMappedData,
  importProcessedResults,
  importProcessedResponse,
} from '../atoms';

const useImport = () => {
  // Setters
  const [mappedData, setMappedData] = useRecoilState(importMappedData);
  const [processedResults, setProcessedResults] = useRecoilState(
    importProcessedResults,
  );
  const [processedResponse, setProcessedResponse] = useRecoilState(
    importProcessedResponse,
  );

  // Resets
  const resetImportMappedData = useResetRecoilState(importMappedData);

  const resetImportProcessedResults = useResetRecoilState(
    importProcessedResults,
  );
  const resetProcessedResponse = useResetRecoilState(importProcessedResponse);

  const reset = () => {
    //  resetImportCurrentStep();
    resetImportMappedData();
    resetImportProcessedResults();
    resetProcessedResponse();
  };

  const setData = (data) => {
    setMappedData(data);
  };

  return {
    setData,
    importedData: mappedData,
    reset,
    setMappedData,
    setProcessedResults,
    processedResults,
    processedResponse,
    setProcessedResponse,
  };
};

export { useImport };
