import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { DELETE_REQUISITION_ATTACHMENT } from '../../../../services/data-layer';

function ConnectModal({ handleClose, handleDelete, fileToDelete }) {
  return (
    <Modal
      data-testid="delete-modal"
      title={<h2>Delete file</h2>}
      className="delete-file-modal"
      onClose={() => {
        handleClose();
      }}
      actions={
        <div className="save-draft-requisition-button-row">
          <Button
            type="button"
            variant="unstyled"
            className="save-draft-requisition-action-button"
            data-testid="save-draft-modal-cancel-button-delete-modal"
            onClick={() => handleClose()}
            label="Cancel"
          />
          <Button
            type="button"
            variant="secondary"
            className="save-draft-requisition-action-button"
            data-testid="submit-requisition-modal-save-button-delete-modal"
            onClick={() => handleDelete()}
            label="Delete file"
          />
        </div>
      }
    >
      <div>
        Are you sure you want to delete <strong>{fileToDelete.name}?</strong>{' '}
        This action cannot be undone.
      </div>
    </Modal>
  );
}

ConnectModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fileToDelete: PropTypes.instanceOf(Object).isRequired,
};

export default function AttachmentDeleteModal({
  isOpen,
  handleClose,
  fileToDelete,
  onCompleted,
}) {
  const DisplayDeleteFileModal = connectModal(ConnectModal);

  const [deleteAttachment] = useMutation(DELETE_REQUISITION_ATTACHMENT);
  const handleDelete = async () => {
    await deleteAttachment({
      variables: {
        attachmentId: fileToDelete?.requisitionAttachmentId || fileToDelete?.id,
      },
    });

    onCompleted();
    handleClose();
  };

  return (
    <DisplayDeleteFileModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleDelete={handleDelete}
      fileToDelete={fileToDelete}
    />
  );
}

AttachmentDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fileToDelete: PropTypes.instanceOf(Object),
  onCompleted: PropTypes.func,
};

AttachmentDeleteModal.defaultProps = {
  onCompleted: () => {},
  fileToDelete: {},
};
