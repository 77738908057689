import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import { defaultVehicleReq } from './ServiceUtils';
import '../MasRequisition.scss';

export default function MasRequirementsModal({ handleClose, selectedModal }) {
  const requirement = defaultVehicleReq.find(
    (req) => req.name === selectedModal,
  );
  const Actions = (
    <>
      <Button
        data-testid="close-btn"
        type="button"
        variant="primary"
        label="Close"
        onClick={handleClose}
      />
    </>
  );

  return (
    <Modal onClose={handleClose} variant="large" actions={Actions}>
      <div className="req-details-modal-container">
        <div className={`${requirement.testId}-req-details-modal-title`}>
          {requirement.name}
        </div>
        <div className="req-details-modal-main-text">
          {requirement.mainText}
        </div>
        {requirement.listArray?.length ? (
          <div className="req-details-modal-list-container">
            <ol className="list">
              {requirement.listArray.map((desc) => (
                <li className="list-item">{desc}</li>
              ))}
            </ol>
          </div>
        ) : null}
        <div className="req-details-modal-sub-text">{requirement.subText}</div>
      </div>
    </Modal>
  );
}

MasRequirementsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedModal: PropTypes.string.isRequired,
};
