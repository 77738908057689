import { cloneDeep } from 'lodash';

const clearAllChecksForOptions = (optionsData) => {
  const optionsDataToReturn = cloneDeep(optionsData);
  optionsDataToReturn.forEach((optCat) => {
    optCat.options.forEach((opt) => {
      /* eslint-disable no-param-reassign */
      delete opt.isChecked;
      delete opt.optionValue;
      opt.vendorValues.forEach((vendorValue) => {
        delete vendorValue.isIncludeExist;
        delete vendorValue.isExcludeExist;
        delete vendorValue.isRequiredExist;
        delete vendorValue.unResolvedExist;
      });
      /* eslint-enable no-param-reassign */
    });
  });
  return optionsDataToReturn;
};

export default clearAllChecksForOptions;
