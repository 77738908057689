import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';
import { LeasingSteps } from '../leasing-consts';
import {useSelector} from "react-redux";
import {RequisitionStatus} from "../../ReviewDetails/RequisitionDetailsUtils.jsx";

const Breadcrumbs = () => {
    const location = useLocation();
    let current = ' Lease Vehicles';
    const {
        requisitionName,
        requisitionStatus
    } = useSelector((state) => state.leasingReducer);

    let trail = [
        <Link key="home" to={`${window.AFP_CONFIG.appURLs.home}/home`}>
            Home
        </Link>,
      
    ];

    if (location.pathname.endsWith(LeasingSteps.REPLACEMENT_VEHICLES)) {
        trail.push(
            <Link key="leasing" to={`${window.AFP_CONFIG.appURLs.store}/lease`}>
               Lease Vehicles
            </Link>
        );
        current = 'Replacement Vehicles';
    }

    if (location.pathname.endsWith(LeasingSteps.DESTINATION_AND_VEHICLE_TYPES)) {
        trail.push(
            <Link key="leasing" to={`${window.AFP_CONFIG.appURLs.store}/lease`}>
                Lease Vehicles
            </Link>
        );
        current = 'Destination and vehicle type';
    }

    if (location.pathname.endsWith(LeasingSteps.VEHICLE_REFERRAL)) {
        trail.push(
            <Link key="leasing" to={`${window.AFP_CONFIG.appURLs.store}/lease`}>
               Lease Vehicles
            </Link>
        );
        current = 'Leased vehicle request details';
    }

    if(location.pathname.endsWith(LeasingSteps.REVIEW_SUBMIT) &&
        [RequisitionStatus.RECEIVED_BY_GSA,
            RequisitionStatus.RETURNED,
            RequisitionStatus.ORDER_RECEIVED].includes(requisitionStatus))
    {
        trail.push(
            <Link key="leasing" to={`${window.AFP_CONFIG.appURLs.store}/`}>
                Store
            </Link>
        );
        trail.push(
            <Link key="leasing" to={`${window.AFP_CONFIG.appURLs.store}/my-requisitions`}>
                Requisitions and Orders
            </Link>
        );
        current = requisitionName;
    }

    return (
        <DSBreadcrumbs
            trail={trail}
            current={current}
        />
    );
};
export default Breadcrumbs;
