import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './PhotosModalComp.scss';
import DefaultImageSvg from '../../assets/images/default-image.svg';

export const PhotosModalComp = ({ imageData }) => {
  const [images, setImages] = useState([]);

  const handleImageError = (image, index) => {
    console.error('error loading image --', image);
    const newImageData = [...images];
    newImageData.splice(index, 1, DefaultImageSvg);
    setImages(newImageData);
  };

  useState(() => {
    setImages(imageData);
  }, [imageData]);

  return (
    <>
      <Carousel showStatus={false} showIndicators={false}>
        {Boolean(images.length) &&
          images.map((image, index) => {
            return (
              <div className="preview-content-section">
                <img
                  alt=""
                  className="modal-image"
                  src={`${image}`}
                  onError={() => handleImageError(image, index)}
                />
              </div>
            );
          })}
      </Carousel>
    </>
  );
};

PhotosModalComp.propTypes = {
  imageData: PropTypes.instanceOf(Array),
};

PhotosModalComp.defaultProps = {
  imageData: [],
};

export default PhotosModalComp;
