export const getResolvedOptionsUpdated = (
  selectedOption,
  contractLineId,
  optionCodeData,
  conflictResolvedOptionsData,
) => {
  let resolvedPayload = [];

  const payload = {
    contractLineId: parseInt(contractLineId, 10),
    requiredOptionSelectionIndex: selectedOption.index,
  };

  conflictResolvedOptionsData.forEach((option) => {
    const resolvedOption = option;
    if (resolvedOption.optionCode === optionCodeData.optionCode) {
      if (typeof resolvedOption.vendorRequiredOptionSelection !== 'undefined') {
        resolvedPayload = resolvedOption.vendorRequiredOptionSelection;
      }
      const checkDuplicates = resolvedPayload.filter((item) => {
        return `${item.contractLineId}` === `${payload.contractLineId}`;
      });

      if (
        JSON.stringify(resolvedOption) !== JSON.stringify(payload) &&
        !checkDuplicates.length
      ) {
        resolvedPayload.push(payload);
      }

      resolvedPayload.forEach((selectionItem, index) => {
        if (`${selectionItem.contractLineId}` === `${payload.contractLineId}`) {
          resolvedPayload.splice(index, 1, payload);
        }
      });

      resolvedOption.vendorRequiredOptionSelection = resolvedPayload;
    }
  });

  return conflictResolvedOptionsData;
};

export const getUpdatedResolvedOptionsOnCancel = (
  data,
  selectedVendor,
  prevPurchaseCollisionSelected,
) => {
  const requiredOptionsData = [];
  const splitIdentifier = selectedVendor.split('-');
  data[0].unResolvedOptions.forEach((unResOpt) => {
    unResOpt.requiredOptions.forEach((itemList) => {
      const payload = {
        contractLineId: splitIdentifier[0],
        requiredOptionSelectionIndex: itemList.index,
      };
      requiredOptionsData.push(payload);
    });
  });

  prevPurchaseCollisionSelected.forEach((purchaseOption) => {
    const selected = purchaseOption;
    const newArr = [];
    if (typeof selected.vendorRequiredOptionSelection !== 'undefined') {
      selected.vendorRequiredOptionSelection.forEach((item, index) => {
        newArr.push(item);
        const requiredFilteredData = requiredOptionsData.filter(
          (option) => JSON.stringify(option) === JSON.stringify(item),
        );

        if (requiredFilteredData.length) {
          newArr.splice(index, 1);
        }
      });
    }
    selected.vendorRequiredOptionSelection = newArr;
  });

  return prevPurchaseCollisionSelected;
};


export const getAfvIncremental = ({afvTotal,quantity})=>{
    if (!afvTotal||!quantity){
      return 0
    }
    return afvTotal * quantity
}