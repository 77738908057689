import { gql } from '@apollo/client';

export const GET_ACTIVE_VEHICLE_GROUPS = gql`
  query GetActiveVehicleGroups($childTag: String) {
    getActiveVehicleGroups(childTag: $childTag) {
      id
      standardCodeMetadataId
      code
      title
      description
    }
  }
`;

export const GET_ACTIVE_VEHICLE_GROUP_TYPES = gql`
  query GetActiveVehicleGroupTypes($groupId: String!, $tag: String) {
    getActiveVehicleGroupTypes(groupId: $groupId, tag: $tag) {
      id
      standardCodeMetadataId
      code
      title
      description
    }
  }
`;

export const GET_CURRENT_YEAR_STANDARD_ITEMS = gql`
  query GetCurrentYearStandardItemsByVehicleType(
    $vehicleTypeIds: [String!]
    $order: OrderBy!
    $offset: Int
    $limit: Int
  ) {
    getCurrentYearStandardItemsByVehicleType(
      vehicleTypeIds: $vehicleTypeIds
      order: $order
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        standardItemNumber
        fedStandardCode
        title
        vehicleType
        vehicleTypeCode {
          code
          title
        }
        tags
        contractsInfo
      }
    }
  }
`;

export const GET_REQUISITION_SINS = gql`
  query RequisitionSinQuery(
    $vehicleTypeIds: [String!]!
    $offset: Int
    $limit: Int
    $leasingQuery: Boolean
  ) {
    getRequisitionSins(
      vehicleTypeIds: $vehicleTypeIds
      offset: $offset
      limit: $limit
      leasingQuery: $leasingQuery
    ) {
      rows {
        id
        standardItemNumber
        vehicleType
        orderStartDate
        tags
        fedStandardCode
        status
        title
        year
      }
      count
      hasMore
    }
  }
`;

export const GET_REQUISITION_SINS_TYPEAHEAD = gql`
  query GetRequisitionSinsTypeahead(
    $searchString: String!
    $offset: Int
    $limit: Int
    $leasingQuery: Boolean
  ) {
    getRequisitionSinsTypeahead(
      searchString: $searchString
      offset: $offset
      limit: $limit
      leasingQuery: $leasingQuery
    ) {
      count
      rows {
        fedStandardCode
        id
        orderStartDate
        standardItemNumber
        status
        tags
        title
        vehicleType
        year
      }
    }
  }
`;

// export const GET_CURRENT_YEAR_STANDARD_ITEMS_WITHOUT_CONTRACT = gql`
//   query GetCurrentYearStandardItemsByVehicleType(
//     $vehicleTypeIds: [String!]
//     $order: OrderBy!
//     $offset: Int
//     $limit: Int
//   ) {
//     getCurrentYearStandardItemsByVehicleType(
//       vehicleTypeIds: $vehicleTypeIds
//       order: $order
//       offset: $offset
//       limit: $limit
//     ) {
//       count
//       rows {
//         id
//         standardItemNumber
//         fedStandardCode
//         title
//         vehicleType
//       }
//     }
//   }
// `;

export const GET_VAL_SINS = gql`
  query GetVALSins($vehicleTypeIds: [String!]!) {
    getVALSins(vehicleTypeIds: $vehicleTypeIds) {
      id
      standardItemNumber
      fedStandardCode
      title
      vehicleType
    }
  }
`;

export const GET_STANDARD_ITEM_BY_ID = gql`
  query GetStandardItem($filter: FilterType!) {
    getStandardItem(filter: $filter) {
      id
      standardItemNumber
      year
      title
      tags
      vehicleType
      contractsInfo
      fedStandardCode
      vehicleTypeCode {
        code
        title
        parentCode
        tags
      }
      fastCode {
        code
        title
      }
      content {
        content
      }
    }
  }
`;

export const GET_STANDARD_ITEMS_TYPEAHEAD = gql`
  query GetStandardItems($offset: Int, $limit: Int, $filters: FilterType!) {
    getStandardItems(offset: $offset, limit: $limit, filters: $filters) {
      count
      rows {
        id
        standardItemNumber
        title
        vehicleType
        vehicleTypeCode {
          code
          title
        }
        tags
        contractsInfo
      }
    }
  }
`;
