import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Icon } from '@gsa/afp-component-library';
import './ReviewSummaryOnHoldHeader.scss';

const ReviewSummaryOnHoldHeader = ({ onHoldRequisitionActivity }) => {
  const { comment, createdUserInfo } = onHoldRequisitionActivity;
  const { email, fullName } = createdUserInfo;

  return (
    <Alert type="error">
      <div className="onhold-error-title">
        This requisition has been placed on hold
      </div>
      <div className="onhold-error-title">
        <div className="fa-svg_user w-embed">
          <Icon iconName="account_circle" className="usa-icon--size-1" />
        </div>
        <div className="user-name">
          <a className="a-tag" href={`mailto:${email}`}>
            {fullName}
          </a>
          , GSA <span className="comment">has commented:</span>
        </div>
        <div className="comment">{comment}</div>
      </div>
    </Alert>
  );
};

ReviewSummaryOnHoldHeader.propTypes = {
  onHoldRequisitionActivity: PropTypes.instanceOf(Object).isRequired,
};

export default ReviewSummaryOnHoldHeader;
