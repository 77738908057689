import React from 'react';

const ReturnIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.790182 4.67981C0.514601 4.94185 0.514601 5.38733 0.790182 5.64937L5.52436 9.39689C5.90015 9.73754 6.52647 9.4493 6.52647 8.899V6.71003C10.4097 6.76243 11.8346 7.60685 10.6109 11.7026C10.4856 12.1219 10.9867 12.4625 11.3124 12.2005C12.4147 11.362 13.4168 9.76347 13.4168 8.165C13.4168 4.67981 10.5349 3.46373 6.52647 3.43752V1.46149C6.52647 0.911197 5.90015 0.622949 5.52436 0.963606L0.790182 4.67981Z"
        fill="white"
      />
    </svg>
  );
};

export default ReturnIcon;
