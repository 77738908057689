import {
  connectModal,
  StatusBadge,
  useModal,
} from '@gsa/afp-component-library';
import React from 'react';
import useBulkOrderModsState from './useBulkOrderModsState';
import { OrderModStatus, OrderModStatusType } from './BulkOrderModTableHelper';
import CancelSvg from '../../assets/images/cancel.svg';
import CancelOrderModModal from './CancelOrderModModal';
import { WarnIcon } from '../../assets/images/warn-icon';

const OrderModificationHeader = () => {
  const { updatedDraft } = useBulkOrderModsState();

  const cancelModal = useModal();
  const DisplayCancelModal = connectModal(CancelOrderModModal);
  const { color, label } = OrderModStatusType[updatedDraft?.orderModStatus] || {
    color: 'Warning-Gray',
    label: `INVALID STATUS (${updatedDraft?.orderModStatus})`,
  };
  const cancelOrderMod = () => {
    cancelModal.openModal();
  };

  return (
    <>
      <div className="order-mod-header" tabIndex="0" role="tab">
        <h1 className="usa-h1">
          {updatedDraft?.orderModName}
          <span className="status-badge">
            <StatusBadge variant={color}>{label}</StatusBadge>
          </span>
        </h1>

        {updatedDraft?.orderModStatus === OrderModStatus.DRAFT_MOD && (
          <div>
            <div className="order-mod-title-right-section">
              <span className="warn-icon">
                <WarnIcon width={15} height={15} />
              </span>
              <span className="auto-save-draft-text">
                Your updates will be saved when you exit to the next or previous
                step
              </span>
            </div>
            <div className="order-mod-cancel-section">
              <span
                onClick={() => {
                  cancelOrderMod();
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    cancelOrderMod();
                  }
                }}
                role="button"
                tabIndex={0}
                className="order-mod-cancel-button "
              >
                <img
                  data-testid="cancel-order-mod-icon"
                  className="order-mod-cancel-icon"
                  src={CancelSvg}
                  alt="CancelSvg"
                />
                Cancel this modification draft
              </span>
            </div>
          </div>
        )}
      </div>

      <DisplayCancelModal
        isOpen={cancelModal.isOpen}
        orderModId={updatedDraft?.orderModificationId}
        orderModName={updatedDraft?.orderModName}
        onClose={() => cancelModal.closeModal()}
      />
    </>
  );
};

export default OrderModificationHeader;
