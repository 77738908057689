import React, { useContext, useState } from 'react';
import { Alert, Button, Modal } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import {
  getResolvedOptionsUpdated,
  getUpdatedResolvedOptionsOnCancel,
} from '../../../utilities/TotalSellingPriceUtils';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

import RenderUnBuildableOptions from '../../TotalSellingPrice/NonBuildConflictModal';

import './AreqUnbuildableBanner.scss';

const AreqUnbuildableBanner = ({
  handleCalculatePrice,
  getPurchaseCollisionDataOnYes,
  getPurchaseCollisionInfo,
  unbuildableContracts,
}) => {
  const unResolvedData =
    getPurchaseCollisionInfo?.getPurchaseCollisionForAllVendor
      ?.vendorCollisionsPerOption;
  const { dispatch, state } = useContext(VehicleRequisitionContext);
  const { requiredOptionSessionState } = state;
  const [modalIsUnBuildable, setModalIsUnBuildable] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [purchaseCollisionSelected, setPurchaseCollisionSelected] = useState(
    [],
  );
  const [selectedVendor, setSelectedVendor] = useState('');
  const standardItem = JSON.parse(sessionStorage.getItem('standardItem'));

  const handleUnBuildableBtnClick = () => {
    setModalIsUnBuildable(true);
  };

  const unResolvedOptionsSelectionHandler = (
    selectedOption,
    selectedVendorIdentifier,
    optionCodeData,
  ) => {
    const conflictResolvedOptionsData = requiredOptionSessionState;
    const updatedPayload = getResolvedOptionsUpdated(
      selectedOption,
      selectedVendorIdentifier,
      optionCodeData,
      conflictResolvedOptionsData,
    );
    setPurchaseCollisionSelected(updatedPayload);
    setIsCanceled(false);
  };

  const modalCloseHandler = () => {
    setIsCanceled(true);
    setModalIsUnBuildable(false);
    const prevPurchaseCollisionSelected = purchaseCollisionSelected;
    const data =
      unResolvedData &&
      unResolvedData.filter((item) => item.contractLineId === selectedVendor);

    const updatedPayload = getUpdatedResolvedOptionsOnCancel(
      data,
      selectedVendor,
      prevPurchaseCollisionSelected,
    );

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
      payload: updatedPayload,
    });
  };

  const handleAfterResolving = () => {
    setModalIsUnBuildable(false);

    if (purchaseCollisionSelected.length && !isCanceled) {
      handleCalculatePrice(true, purchaseCollisionSelected);
      if (typeof getPurchaseCollisionDataOnYes === 'function') {
        getPurchaseCollisionDataOnYes({
          variables: {
            standardItemId: standardItem.standardItemId,
            selectedOptions: purchaseCollisionSelected,
          },
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
          payload: purchaseCollisionSelected,
        });
      }
    }
  };

  const selectVendor = (contract) => {
    setSelectedVendor(contract.contractLineId);
    handleUnBuildableBtnClick();
  };

  return (
    <div id="areq-unbuildable-banner">
      <>
        <Alert
          type="warning"
          className="alert-msg"
          data-testid="areq-unbuildable-banner"
        >
          <strong>PLEASE NOTE:</strong> Due to option conflicts resulting in
          non-buildable-vehicle models, the following vendors will{' '}
          <stong>not</stong> be eligible to accommodate your AREQ:
          <ul>
            {unbuildableContracts?.map((contract) => (
              <li
                data-testid={`areq-unbuildable-banner-contract-${contract.contractLineId}`}
              >
                {contract.vendorName}, {contract.modelName}{' '}
                <a href onClick={() => selectVendor(contract)}>
                  View and resolve conflicts
                </a>
              </li>
            ))}
          </ul>
          <p>
            To make these vehicle models available you will need to{' '}
            <strong>resolve their options conflicts.</strong>
          </p>
        </Alert>
        {modalIsUnBuildable && (
          <div className="afp-modal-overlay modalContainer conflict-modal">
            <Modal
              title="This model is currently non-buildable due to the following conflicts"
              className="collision-found-modal"
              onClose={() => {
                modalCloseHandler();
              }}
              actions={
                <>
                  <Button
                    type="button"
                    className="cancel-button"
                    onClick={() => {
                      modalCloseHandler();
                    }}
                    label="No, cancel my selection"
                  />

                  <Button
                    type="button"
                    data-testid="yes-apply-changes-btn"
                    onClick={() => handleAfterResolving()}
                    label="Yes, apply changes"
                  />
                </>
              }
            >
              <RenderUnBuildableOptions
                unResolvedData={unResolvedData}
                selectedVendor={selectedVendor}
                getPurchaseCollisionDataOnYes={getPurchaseCollisionDataOnYes}
                unResolvedOptionsSelectionHandler={
                  unResolvedOptionsSelectionHandler
                }
              />
            </Modal>
          </div>
        )}
      </>
    </div>
  );
};

AreqUnbuildableBanner.propTypes = {
  handleCalculatePrice: PropTypes.func.isRequired,
  getPurchaseCollisionDataOnYes: PropTypes.func.isRequired,
  getPurchaseCollisionInfo: PropTypes.func.isRequired,
  unbuildableContracts: PropTypes.instanceOf(Array).isRequired,
};

export default AreqUnbuildableBanner;
