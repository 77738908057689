import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { AVAILABLE_EDIT_STEPS } from '../../constants';

const AgencyInformationReview = ({
  title,
  editPage,
  selectedContractAgencyInformation,
  isEditable,
}) => {
  return (
    <div role="tab" tabIndex="0" className="agency-info-review">
      <div className="section-main-title main-title">
        {title}
        {isEditable && (
          <Button
            variant="outline"
            onClick={() => {
              editPage(AVAILABLE_EDIT_STEPS[1]);
            }}
            data-testid="agency-info-edit-section"
            leftIcon={{ name: 'edit' }}
            label="Edit"
            size="small"
          />
        )}
      </div>
      <div className="section-row">
        <div className="row-column">
          <div className="title">Agency</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.agency || '-'}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Bureau</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.bureau || '-'}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Group</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.office || '-'}
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="row-column">
          <div className="title">Agency order number</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.agencyOrderNumber}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Requisition number</div>
          <div className="title-desc">
            {`${selectedContractAgencyInformation?.requisitionBOAC}-${selectedContractAgencyInformation?.requisitionJulian}-${selectedContractAgencyInformation?.requisitionSerialNumber}`}
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="row-column">
          <div className="title">Treasury account symbol</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.treasuryAccountSymbol || '-'}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Signal code</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.signalCode}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Service code</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.serviceCode}
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="row-column">
          <div className="title">Fund code</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.fundCode}
          </div>
        </div>
        <div className="row-column">
          <div className="title">Supplementary address</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.signalSupplementaryAddress ||
              '-'}
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="row-column">
          <div className="title">Agency point of contact</div>
          <div className="title-desc">
            <div>
              {selectedContractAgencyInformation?.firstName}{' '}
              {selectedContractAgencyInformation?.lastName}
            </div>
            <div>{selectedContractAgencyInformation?.email}</div>
            <div>{selectedContractAgencyInformation?.phoneNumber}</div>
          </div>
        </div>
        <div className="row-column">
          <div className="title">Agency financial data</div>
          <div className="title-desc">
            {selectedContractAgencyInformation?.agencyFinancialData}
          </div>
        </div>
      </div>
    </div>
  );
};

AgencyInformationReview.propTypes = {
  title: PropTypes.string.isRequired,
  editPage: PropTypes.func.isRequired,
  selectedContractAgencyInformation: PropTypes.objectOf(PropTypes.string)
    .isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default AgencyInformationReview;
