import React from 'react';

export const OrderingAdminApprovalTaskList = () => {
  const tasks = [
    'Please review all information below established by all parties.',
    'Review vendor quotes details. Select one vendor.',
    'Provide funding confirmation.',
    <>
      Submit to <strong>place order</strong>
    </>,
  ];

  return (
    <div
      data-testid="review-summary-customer-tasks-header"
      className="review-summary-customer-tasks-header"
    >
      <div className="title">Ordering Admin&apos;s tasks:</div>
      <ol>
        {tasks.map((task) => (
          <li key={task} className="task">
            {task}
          </li>
        ))}
      </ol>
    </div>
  );
};
