import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, ButtonDropdownItem } from '@gsa/afp-component-library';

export const REQUISITION_BUTTON_DROPDOWN_ACTIONS = {
  PRINT: 'print',
  PLACE_ORDER: 'placeOrder',
  RENAME: 'rename',
  POST_COMMENT: 'postComment',
  RETURN_TO_SUBMITTER: 'returnToSubmitter',
  CANCEL: 'cancel',
  CLONE: 'clone',
  REOPEN: 'reopen',
  SUBMIT_TO_APPROVER: 'submitToApprover',
};

const MENU_ACTIONS_DEFAULTS = {
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
    defaultLabel: 'Print summary',
    defaultIcon: 'print',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PLACE_ORDER]: {
    defaultLabel: 'Place order',
    defaultIcon: 'check',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.RENAME]: {
    defaultLabel: 'Rename',
    defaultIcon: 'edit',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
    defaultLabel: 'Post comment',
    defaultIcon: 'edit',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.RETURN_TO_SUBMITTER]: {
    defaultLabel: 'Return to submitter',
    defaultIcon: 'undo',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.CANCEL]: {
    defaultLabel: 'Cancel requisition',
    defaultIcon: 'cancel',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.CLONE]: {
    defaultLabel: 'Clone requisition',
    defaultIcon: 'content_copy',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.REOPEN]: {
    defaultLabel: 'Reopen',
    defaultIcon: 'undo',
  },
  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.SUBMIT_TO_APPROVER]: {
    defaultLabel: 'Submit to approver',
    defaultIcon: 'check',
  },
};

export const RequisitionActionsButtonDropdown = ({
  menuItems,
  type,
  variant,
  label,
  className,
  children,
}) => {
  const filteredActions = useMemo(() => {
    if (!menuItems) {
      return [];
    }

    return Object.keys(menuItems)
      .filter((key) => {
        return Object.keys(MENU_ACTIONS_DEFAULTS).some(
          (menuKey) => menuKey === key,
        );
      })
      .map((key) => ({
        label: menuItems[key].label || MENU_ACTIONS_DEFAULTS[key].defaultLabel,
        iconName: menuItems[key].icon || MENU_ACTIONS_DEFAULTS[key].defaultIcon,
        onClick: menuItems[key].onClick,
      }));
  }, [menuItems]);

  return (
    <ButtonDropdown
      type={type}
      variant={variant}
      label={label}
      className={className}
    >
      {filteredActions.map((action) => (
        <ButtonDropdownItem
          key={action.label}
          label={action.label}
          iconName={action.iconName}
          onClick={action.onClick}
        />
      ))}
      {children}
    </ButtonDropdown>
  );
};

RequisitionActionsButtonDropdown.propTypes = {
  menuItems: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

RequisitionActionsButtonDropdown.defaultProps = {
  type: 'button',
  label: 'Requisition actions',
  variant: 'outline',
  className: '',
  children: null,
};
