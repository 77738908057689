export function generateSignalCodeField(field, isDoD = false) {
  return {
    header: `Signal code ${field}`,
    dependentOn: 'signalCode',
    dependentValue: `${field}`,
    className: 'signal-code',
    sections: [
      isDoD && !['C', 'L'].includes(field)
        ? {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'fundCode',
                label: 'Fund code',
                required: true,
                type: 'text',
                element: {
                  control: {
                    maxLength: 2,
                  },
                },
              },
            ],
          }
        : {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'serviceCode',
                label: 'Service code',
                required: ['C', 'L'].includes(field),
                type: 'text',
                element: {
                  control: {
                    maxLength: 2,
                  },
                },
              },
              {
                id: 'fundCode',
                label: 'Fund code',
                required: true,
                type: 'text',
                element: {
                  control: {
                    maxLength: 2,
                  },
                },
              },
            ],
          },
      ['B', 'C', 'K', 'L'].includes(field)
        ? {
            fields: [
              {
                id: 'supplementaryAddressCode',
                label: 'Supplementary address code',
                columnWidth: 'col-8-12',
                required: true,
                disabled: ['C', 'L'].includes(field),
                type: 'text',
                element: {
                  control: {
                    maxLength: 6,
                  },
                },
              },
            ],
          }
        : null,
    ],
  };
}
