import { useQuery } from '@apollo/client';
import { Breadcrumbs, Spinner } from '@gsa/afp-component-library';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import OrderModDetails from './components/OrderModDetails';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import { GET_ORDER_MODIFICATION_DETAILS } from '../../services/data-layer';
import { useQueryParam } from '../../hooks/useQueryParam';
import { Purchase_Vehicles } from '../constants';

const OrderModDetailsContainer = () => {
  const query = useQueryParam();
  const modificationId = query.get('modificationId');
  const navigate = useNavigate();
  const ability = useAppAbility();

  useEffect(() => {
    if (!ability?.can(StoreOperations.View, StoreSubjects.OrderVehicle)) {
      navigate('/unauthorized');
    }
  }, [ability, navigate]);

  const { loading, error, data } = useQuery(GET_ORDER_MODIFICATION_DETAILS, {
    variables: {
      modificationId,
    },
    fetchPolicy: 'network-first',
  });

  if (loading) {
    return <Spinner className="margin-y-9" />;
  }
  if (error) {
    return <div>Error!</div>;
  }
  const orderModDetails = data ? data?.getOrderModificationDetails : null;
  return (
    <>
      {!loading && (
        <div>
          <div
            data-testid="order-mod-details-header"
            className="order-mod-details-header"
          >
            <Breadcrumbs
              trail={[
                <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
                  Home
                </a>,
                <Link to="/purchase">{Purchase_Vehicles}</Link>,
                <Link to="/my-requisitions">Requisitions and Orders</Link>,
              ]}
              current={orderModDetails?.orderModName}
            />
            <ContactBuyingPopover />
          </div>
          <div>
            <OrderModDetails orderModDetails={orderModDetails} />
          </div>
        </div>
      )}
    </>
  );
};

export default OrderModDetailsContainer;
