/* eslint-disable import/prefer-default-export */
// TODO refactor into shared-ui-utils
export const UserTypes = {
  CUSTOMER: '1',
  VENDOR: '2',
  GSA_EMPLOYEE: '3',
  PUBLIC_OR_GENERAL: '4',
};

export const UserRoles = {
  VEHICLE_SUPPLIER: 'Vehicle Supplier',
  CONTRACTING_OFFICER: 'Contracting Officer',
  VENDOR_MANAGER: 'Vendor Manager',
  CUSTOMER_PURCHASE_SUBMITTER: 'Customer Purchase Submitter',
  CUSTOMER_PURCHASE_APPROVER: 'Customer Purchase Approver',
  GSA_ADMIN: 'GSA Admin',
  ENGINEER: 'Engineer',
  SENIOR_ENGINEER: 'Senior Engineer',
  COE: 'COE',
  ZONAL_ACQUISITION_COORDINATOR: 'Zonal Acquisition Coordinator',
  FSR: 'FSR',
  FLEET_BASE_ROLE: 'Fleet Base Role',
  ORDERING_ADMIN: 'Ordering Admin',
  SITE_ADMIN: 'Site Administrator',
};
