export const setAgencySession = (id, agencies) => {
  const {
    label: agencyLabel,
    isDod: isAgencyDod,
    oldAgencyCode,
  } = agencies.find(({ value }) => value === id);
  sessionStorage.setItem('orderingAgency', agencyLabel);
  sessionStorage.setItem('isAgencyDod', isAgencyDod);
  sessionStorage.setItem('oldAgencyCode', oldAgencyCode);
  sessionStorage.setItem('agencyCode', id);
  sessionStorage.setItem('orderingBureau', '');
  sessionStorage.setItem('bureauCode', '');
  sessionStorage.setItem('orderingOffice', '');
  sessionStorage.setItem('officeCode', '');
};

export const setBureauSession = (id, bureaus) => {
  const selectedBureauInfo = bureaus.find((bureau) => bureau.value === id);
  sessionStorage.setItem('orderingBureau', selectedBureauInfo.label);
  sessionStorage.setItem('bureauCode', id);
  sessionStorage.setItem('orderingOffice', '');
  sessionStorage.setItem('officeCode', '');
};

export const setOfficeSession = (id, offices) => {
  const selectedOfficeInfo = offices.find((office) => office.value === id);
  sessionStorage.setItem('orderingOffice', selectedOfficeInfo.label);
  sessionStorage.setItem('officeCode', id);
};

export const addressErrors = (context) => {
  const errors = {
    visibilityRequisition: context
      .filter((entry) => entry.visibility)
      .reduce((acc, cur) => {
        return { ...acc, [cur.key]: 'error' };
      }, {}),
    feedbackTextRequisition: context
      .filter((entry) => entry.visibility)
      .reduce((acc, cur) => {
        return { ...acc, [cur.key]: cur.text };
      }, {}),
  };
  return errors;
};

export const addressChangeData = (
  selected,
  selectedType,
  uspsModalOptionsSelectedState,
) => {
  const data = uspsModalOptionsSelectedState;
  data.forEach((li) => {
    const item = li;
    if (selected === 'USPS' && selectedType === item.id && !item.isChecked) {
      item.isChecked = true;
      item.selected = true;
    } else if (selected === 'ENTERED' && selectedType === item.id) {
      item.isChecked = false;
      item.selected = true;
    }
  });

  return data;
};

export const errorTitle = (validatedAddressNotFound) => {
  if (!validatedAddressNotFound?.length) {
    return '';
  }
  // Set removes the duplicate error messages
  const errorSet = new Set();
  validatedAddressNotFound.forEach((error) => {
    errorSet.add(error);
  });
  const errors = Array.from(errorSet);

  // formatting
  let errorTxt = '';
  errors.forEach((error, index) => {
    let prefix = index < errors.length && index > 0 ? ', ' : '';
    if (index === errors.length - 1 && errors.length === 2) {
      prefix = ' and ';
    } else if (index === errors.length - 1 && errors.length > 2) {
      prefix = ', and ';
    }
    errorTxt += `${prefix}${error}`;
  });

  errorTxt = `${errorTxt} Address ${
    errors.length > 1 ? 'are' : 'is'
  } not found.`;
  errorTxt = `${errorTxt} Please enter the valid ${
    errors.length > 1 ? 'addresses' : 'address'
  } below.`;

  // Please enter a valid address below.

  return errorTxt;
};

export const defaultVehicleReq = [
  {
    name: 'Technical specification',
    testId: 'tech-spec',
    required: true,
    attachments: [],
    mainText: 'Please attach the Vehicle Specification Sheet, if applicable.',
    listArray: [],
    subText: '',
  },
  {
    name: 'Inter-agency agreement',
    testId: 'inter-agency',
    required: false,
    attachments: [],
    mainText: 'Please attach the signed Interagency Agreement.',
    subText: '',
  },
  {
    name: 'Request for Quote',
    testId: 'quote-req',
    required: true,
    attachments: [],
    mainText: '',
    listArray: [],
    subText: '',
  },
  {
    name: 'Quotes Received',
    testId: 'quote-received',
    required: true,
    attachments: [],
    mainText: '',
    listArray: [],
    subText: '',
  },
  {
    name: 'Funding documentation',
    testId: 'funding-req',
    required: true,
    attachments: [],
    mainText: 'Please attach your funding document (e.g. MIPR, GSA49).',
    subText: '',
  },
  {
    name: 'Other documentation',
    required: false,
    attachments: [],
    mainText: '',
    listArray: [],
    subText: '',
  },
];

export const validateAttachments = (attachments, attachmentTypes) => {
  const requiredAttachments = defaultVehicleReq
    .map((req, index) =>
      req.required ? { isValid: false, name: req.name, index } : null,
    )
    .filter((v) => v);

  if (attachments && attachments.length) {
    attachments.forEach((attachment) => {
      const type = attachmentTypes.find(
        (val) => val.value === attachment.attachmentTypeId,
      );
      const matchingAttachment = requiredAttachments.find(
        (att) => att.name === type.label,
      );
      if (matchingAttachment) {
        matchingAttachment.isValid = true;
      }
    });
  }

  return requiredAttachments;
};

export const findErrors = (attachments, attachmentTypes) => {
  const mappedValidation = validateAttachments(attachments, attachmentTypes);
  const errors = mappedValidation
    .filter((val) => !val.isValid)
    .map((v) => v.name)
    .join('_');

  if (errors && errors?.length) {
    return errors;
  }
  return false;
};

export const manageAttachmentTypes = (attachmentTypes) => {
  const requirementMapObj = {
    req_technical_specifications: 'Technical specification',
    req_inter_agency_agreement: 'Inter-agency agreement',
    req_request_for_quote: 'Request for Quote',
    req_quotes_received: 'Quotes Received',
    req_funding: 'Funding documentation',
    req_other: 'Other documentation',
  };

  const types = [
    {
      label: '-select-',
      value: -1,
    },
  ];

  if (!attachmentTypes || !attachmentTypes?.length) {
    return types;
  }

  types.push(
    ...attachmentTypes
      .map((type) => {
        if (!requirementMapObj[type.attachmentType]) return null;
        return {
          value: type.requisitionAttachmentTypeId,
          label: requirementMapObj[type.attachmentType],
        };
      })
      .filter((v) => v),
  );

  return types;
};
