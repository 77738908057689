import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';

export const convertNumToWors = (cnt) =>
  `${cnt} ${cnt === 1 ? 'error' : 'errors'}.`;

const SectionErrors = ({ errors, ...props }) => (
  <Alert type="error" slim {...props}>
    This section has <strong>{convertNumToWors(errors)}</strong> Please address
    all fields outlined in red before proceeding to the next step.
  </Alert>
);

SectionErrors.propTypes = {
  errors: PropTypes.number.isRequired,
};

export default SectionErrors;
