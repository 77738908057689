export const formatPhoneNumber = (number) => {
  if (!number) {
    return '';
  }

  const numbersOnly = String(number).replace(/\D/g, '');

  return `${numbersOnly.substring(0, 3)}-${numbersOnly.substring(
    3,
    6,
  )}-${numbersOnly.substring(6)}`;
};
