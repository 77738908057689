import React, { useEffect, useState } from 'react';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AgencyReferenceNumberInput from './AgencyReferenceNumberInput';
import { AdditionalReqForm } from './AdditionalReqForm/AdditionalReqForm';
import { useSessionState } from '../../hooks/useSessionState';
import DeleteRequirementModal from './DeleteRequirementModal/DeleteRequirementModal';
import MultipleAdditionalRequirementsWarningModal from './MultipleAdditionalRequirementsWarningModal';
import TotalReqAmount from './TotalReqAmount/TotalReqAmount';
import MultipleAdditonalRequirementsAreqInfo from './MultipleAdditonalRequirementsAreqInfo';

const MultipleAdditionalRequirementsTab = ({
  createdByInfo,
  vehicleType,
  agencyReferenceNumber,
  trigger,
}) => {
  const { formState } = useFormContext();
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [doNotShowAgainSession, setDoNotShowAgainSession] = useSessionState(
    'multipleAdditionalRequirementsWarningModalDoNotShow',
    false,
  );

  const multipleAdditionalRequirementsWarningModal = useModal();
  const displayDeleteRequirementModal = useModal();
  const DisplayDeleteRequirementModal = connectModal(DeleteRequirementModal);

  const DisplayMultipleAdditionalRequirementsWarningModal = connectModal(
    MultipleAdditionalRequirementsWarningModal,
  );

  const {
    fields: additionalReqs,
    append,
    remove,
  } = useFieldArray({
    control: formState.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'additionalRequirements', // unique name for your Field Array
  });

  function openWarningModal() {
    if (doNotShowAgainSession) {
      return;
    }

    multipleAdditionalRequirementsWarningModal.openModal();
  }

  useEffect(() => {
    setTimeout(() => {
      openWarningModal();
    }, 3000);
  }, []);

  const handleAddRequirement = async () => {
    const formIsValid = await trigger('additionalRequirements', {
      shouldFocus: true,
    });

    if (formIsValid === true) {
      append({
        description: '',
        priceEstimate: 0.0,
        attachments: [],
      });

      openWarningModal();
    }
  };

  return (
    <div className="areq-information-tab" role="tab" tabIndex="0">
      <div className="info-title">
        Provide information for additional requirements
      </div>
      <div className="info-description">
        Please supply all required information following the instructions in the
        panel on the left.
      </div>

      <AgencyReferenceNumberInput
        agencyReferenceNumber={agencyReferenceNumber}
      />

      <MultipleAdditonalRequirementsAreqInfo
        createdByInfo={createdByInfo}
        vehicleType={vehicleType}
      />

      <div className="list-additional-div">
        <div className="list-title">
          List Additional requirements and price estimate
        </div>
        <div className="description">
          Provide line items for each requirement (with optional supporting
          documentation). Please note that the vehicle will be built in
          accordance with the accepted contractor’s response to the AREQ, and
          not the customer’s AREQ. As such, it is the customer agency’s
          responsibility to ensure the contractor’s response meets the
          government’s needs at the time of selection.
        </div>
      </div>
      <div className="add-req-btn-container">
        <Button
          type="button"
          variant="primary"
          label="Add Requirement"
          leftIcon={{ name: 'add', className: 'plus-icon' }}
          onClick={handleAddRequirement}
        />
      </div>
      <div className="additional-reqs-container">
        {additionalReqs.map((additionalReq, index) => {
          return (
            <AdditionalReqForm
              index={index}
              setItemToDelete={setItemToDelete}
              displayDeleteRequirementModal={displayDeleteRequirementModal}
              key={additionalReq.id}
              requisitionType="AREQ"
            />
          );
        })}
      </div>
      <TotalReqAmount />

      <DisplayDeleteRequirementModal
        isOpen={displayDeleteRequirementModal.isOpen}
        handleClose={displayDeleteRequirementModal.closeModal}
        handleDelete={remove}
        itemToDelete={itemToDelete}
      />

      <DisplayMultipleAdditionalRequirementsWarningModal
        isOpen={multipleAdditionalRequirementsWarningModal.isOpen}
        closeModal={multipleAdditionalRequirementsWarningModal.closeModal}
        doNotShowAgainSession={doNotShowAgainSession}
        setDoNotShowAgainSession={setDoNotShowAgainSession}
      />
    </div>
  );
};

MultipleAdditionalRequirementsTab.propTypes = {
  vehicleType: PropTypes.string.isRequired,
  createdByInfo: PropTypes.instanceOf(Object).isRequired,
  agencyReferenceNumber: PropTypes.string.isRequired,
  trigger: PropTypes.func,
};

MultipleAdditionalRequirementsTab.defaultProps = {
  trigger: () => true,
};

export default MultipleAdditionalRequirementsTab;
