export const STEPS = {
  REPORT_SELECTION: 'REPORT_SELECTION',
  SEARCH_METHOD: 'SEARCH_METHOD',
  REPORT_FILTERS: 'REPORT_FILTERS',
};

export const initialStoreReportsSteps = [
  {
    key: 'REPORT_SELECTION',
    label: 'Report selection',
    status: 'current',
    stepNumber: 1,
  },
  {
    key: 'SEARCH_METHOD',
    label: 'Search method',
    status: 'not completed',
    stepNumber: 2,
  },
  {
    key: 'REPORT_FILTERS',
    label: 'Report filters',
    status: 'not completed',
    stepNumber: 3,
  },
];

export const reportFormDefaultValues = {
  agencyCode: '',
  bureauCode: '',
  officeCode: '',
  reportType: '',
  orderNumber: '',
  requisitionNumber: '',
  reportBuildOption: '',
  startDate: '',
  endDate: '',
  requisitionType: '',
  transactionTypes: [],
  orderStatuses: [],
  requisitionStatuses: [],
};

export const allOrderStatuses = [
  {
    label: 'Awarded',
    value: 'AWARDED',
  },
  {
    label: 'In process',
    value: 'IN_PROCESS',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
];

export const TransactionTypes = [
  {
    label: 'Purchase',
    value: 'VEHICLE_SALE',
  },
  {
    label: 'Lease',
    value: 'VEHICLE_LEASE',
  },
];
