import { connectModal, Modal, Button } from '@gsa/afp-component-library';
import './vendor-clarification-modal.scss';

export default connectModal(function VendorClarificationModal({
    contract,
    closeModal,
}) {
    let sortedClarifications = []

    if (contract?.clarifications) {
        const clonedClarifications = Object.assign([], contract?.clarifications);
        sortedClarifications = clonedClarifications.sort(
            (a, b) => a.sequenceNumber - b.sequenceNumber,
        )
    }

    return (
        <div
            role="dialog"
            aria-modal="true"
            className="afp-modal-overlay modalContainer vendor-clarification-modal"
        >
        <Modal
            title={
                <div className="modal-title">
                    <span className="model-name">
                        {(contract.modelName || contract?.modelCode) &&
                            `${contract.modelName || contract.modelCode} :`} Vendor Clarifications
                    </span>
                </div>
            }
            onClose={() => {
                closeModal()
            }}
            className="main-modal"
            actions={
                <>
                    <Button
                        data-testid="veh-req-vendor-clrf-modal-close"
                        type="button"
                        className="modal-close-button"
                        onClick={() => {
                            closeModal()
                        }}
                        label="Close"
                    />
                </>
            }
        >
            {contract?.clarifications && (
                <div className="vendor-clarifications-list">
                <ul className="vendor-modal-list">
                    {sortedClarifications.map(({ clarification }, index) => (
                        <li
                            key={`contract_clarification_${Math.random()}`}
                            data-testid={`contract_clarification_${index}`}
                        >
                            {clarification}
                        </li>
                    ))}
                </ul>
                </div>
            )}
        </Modal>
        </div>
    )
})