import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Button } from '@gsa/afp-component-library';
import './CancelRequisitionButton.scss';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import CancelRequisitionModal from '../../../components/CancelRequisitionModal/CancelRequisitionModal';
import { canUpdateRequisitionCart } from '../../../utilities/authorization';

const CancelRequisitionButton = ({ requisitionId, forceDisplay }) => {
  const {
    state: { cancelRequisitionModal },
    dispatch,
  } = useContext(VehicleRequisitionContext);

  const ability = useAppAbility();

  const handleCancelRequisitionModal = async () => {
    dispatch({
      type: VehicleRequisitionContextActions.CANCEL_REQUISITION_MODAL,
      payload: true,
    });
  };

  if (!forceDisplay && !canUpdateRequisitionCart(ability)) {
    return null;
  }

  return (
    <>
      <Button
        className="cancel-requisition-btn"
        variant="secondary"
        type="button"
        data-testid="cancel-requisition-btn"
        onClick={handleCancelRequisitionModal}
        leftIcon={{ name: 'cancel' }}
        label="Cancel requisition"
      />

      {cancelRequisitionModal && (
        <CancelRequisitionModal requisitionId={requisitionId} />
      )}
    </>
  );
};

CancelRequisitionButton.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  forceDisplay: PropTypes.bool,
};

CancelRequisitionButton.defaultProps = {
  forceDisplay: false,
};

export default CancelRequisitionButton;
