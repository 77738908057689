import React, { createContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ORDER_MODS_FOR_USER } from '../../services/data-layer';

export const OrderModsContext = createContext({});

export default function OrderModsProvider({ children }) {
  const [orderModRows, setOrderModRows] = useState([]);
  const [orderModCount, setOrderModCount] = useState(0);
  const [orderModDirection, setOrderModDirection] = useState('DESC');
  const [perPage, setPerPage] = useState(10);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [searchFilters, setSearchFilters] = useState({
    limit: perPage,
    offset: (currentPage - 1) * perPage,
    order: [[orderBy, orderModDirection]],
    filters: { operator: 'AND', value: [] },
  });

  const [
    getOrderMods,
    { data: orderModResponseData, loading: onOrderModsLoading },
  ] = useLazyQuery(GET_ALL_ORDER_MODS_FOR_USER, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getOrderMods({
      variables: {
        query: {
          limit: paginationState.limit,
          offset: paginationState.offset,
          order: [[orderBy, orderModDirection]],
          filters: searchFilters.filters,
        },
      },
    });
  }, [paginationState, orderBy, orderModDirection, searchFilters]);

  useEffect(() => {
    if (!orderModResponseData) {
      return;
    }
    const { getAllOrderModsForUser: data } = orderModResponseData;
    setOrderModCount(data?.count);
    setOrderModRows(data?.rows);
  }, [orderModResponseData]);

  const value = {
    orderModRows,
    orderModCount,
    onOrderModsLoading,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderModDirection,
    setOrderModDirection,
    getOrderMods,
    searchFilters,
    setSearchFilters,
    paginationState,
    setPaginationState,
  };

  return (
    <OrderModsContext.Provider value={value}>
      {children}
    </OrderModsContext.Provider>
  );
}

OrderModsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
