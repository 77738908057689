import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';

export default function BulkUpdateButton({ onClick }) {
  return (
    <div>
      <Button
        label="Bulk Status Reporting and Templates"
        onClick={onClick}
        leftIcon={{
          name: 'file_upload',
          type: 'button',
        }}
      />
    </div>
  );
}

BulkUpdateButton.defaultProps = {
  onClick: () => {},
};

BulkUpdateButton.propTypes = {
  onClick: propTypes.func,
};
