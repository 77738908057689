import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert } from '@gsa/afp-component-library';

const GSAAdvModal = ({ gsaAdvModalCancel, gsaAdvModalConfirm }) => {
  return (
    <div className="afp-modal-overlay modalContainer mas-modal">
      <Modal
        className="mas-modal-main"
        data-testid="mas-nav-modal"
        onClose={gsaAdvModalCancel}
        actions={
          <>
            <Button
              data-testid="mas-nav-cancel"
              type="button"
              variant="unstyled"
              onClick={gsaAdvModalCancel}
              label="Cancel"
              className="mos-req-cancel"
            />
            <Button
              data-testid="mas-nav-confirm"
              type="button"
              variant="standard"
              onClick={gsaAdvModalConfirm}
              label="Proceed to GSA Advantage"
            />
          </>
        }
      >
        <Alert className="mas-alert" type="warning">
          <div className="mas-alert-title">You are now leaving Fleet.gov.</div>
          <div className="mas-alert-text">
            Click &apos;Proceed&apos; to be redirected to{' '}
            <b>GSA Advantage.gov</b>.
          </div>
        </Alert>
        <div className="mas-modal-content">
          To stay in Fleet.gov, select &apos;Cancel&apos;.
        </div>
      </Modal>
    </div>
  );
};

export default GSAAdvModal;

GSAAdvModal.propTypes = {
  gsaAdvModalCancel: PropTypes.func.isRequired,
  gsaAdvModalConfirm: PropTypes.func.isRequired,
};
