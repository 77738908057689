import React, { useEffect } from 'react';
import { TextInput, Spinner } from '@gsa/afp-component-library';
import './OrderModMessageLines.scss';
import useBulkOrderModsState from './useBulkOrderModsState';

const OrderModMessageLines = () => {
  const {
    message1,
    setMessage1,
    message2,
    setMessage2,
    message3,
    setMessage3,
    message4,
    setMessage4,
    message5,
    setMessage5,
    selectedOrdersForMod,
    sameRequisitionNumberMods,
    orderChanges,
    setOrderChanges,
    isLoaded,
  } = useBulkOrderModsState();

  const handleMessageUpdate = (key, setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
    setOrderChanges((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Prepopulate messages lines for multiple orders with the same requisition number
  useEffect(() => {
    if (selectedOrdersForMod && sameRequisitionNumberMods) {
          setMessage1(selectedOrdersForMod[0]?.message1);
          setMessage2(selectedOrdersForMod[0]?.message2);
          setMessage3(orderChanges?.message3 !== null ? orderChanges?.message3 : (selectedOrdersForMod[0]?.message3 || null));
          setMessage4(orderChanges?.message4 !== null ? orderChanges?.message4 : (selectedOrdersForMod[0]?.message4  || null));
          setMessage5(orderChanges?.message5 !== null ? orderChanges?.message5 : (selectedOrdersForMod[0]?.message5 || null));
    }
    if(!sameRequisitionNumberMods) {
        setMessage3(orderChanges?.message3 !== null ? orderChanges?.message3 : null);
        setMessage4(orderChanges?.message4 !== null ? orderChanges?.message4 : null);
        setMessage5(orderChanges?.message5 !== null ? orderChanges?.message5 : null);
    }

  }, [selectedOrdersForMod, sameRequisitionNumberMods, orderChanges]);


  return (
    <section
      id="order-mod-message-lines"
      role="tab"
      tabIndex="0"
      className="margin-bottom-2 margin-top-1"
    >
        {!isLoaded && (
             <Spinner size="medium" className="margin-y-8" />
         )}
        {isLoaded && selectedOrdersForMod.length > 0 && orderChanges && (
      <div>
        <div className="font-body-lg text-bold margin-bottom-2">
          Message lines
        </div>

        <div className="bg-primary-lightest padding-bottom-2 padding-top-05 padding-2">
          <div className="margin-bottom-2 grid-row">
            <div className="grid-col font-body-md text-bold">
              Add message lines and click &quot;Save and continue&quot; below to
              update
            </div>
          </div>

          <div>
            <div className="usa-label">Line 1</div>
            <div className="font-body-md" key={message1 ?? 'ml1'}>
              {sameRequisitionNumberMods || '[You cannot edit this line]'}
              {sameRequisitionNumberMods && (
                <>{message1 ?? <span>&ndash;</span>}</>
              )}
            </div>
          </div>
          <div>
            <div className="usa-label">Line 2</div>
            <div className="font-body-md" key={message2 ?? 'ml2'}>
              {sameRequisitionNumberMods || '[You cannot edit this line]'}
              {sameRequisitionNumberMods && (
                <>{message2 ?? <span>&ndash;</span>}</>
              )}
            </div>
          </div>

          <div>
            <TextInput
              characterLimit="35"
              type="textarea"
              label="Line 3"
              onChange={handleMessageUpdate('message3', setMessage3)}
              value={message3}
              name="field-message-line3"
              inputClass="line-text-box"
            />
          </div>
          <div>
            <TextInput
              characterLimit="35"
              type="textarea"
              label="Line 4"
              onChange={handleMessageUpdate('message4', setMessage4)}
              value={message4}
              name="field-message-line4"
              inputClass="line-text-box"
            />
          </div>
          <div>
            <TextInput
              characterLimit="35"
              type="textarea"
              label="Line 5"
              onChange={handleMessageUpdate('message5', setMessage5)}
              value={message5}
              name="field-message-line3"
              inputClass="line-text-box"
            />
          </div>
        </div>
      </div>
       )}

    </section>
  );
};

export default OrderModMessageLines;
