import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, RequiredFieldIndicator } from '@gsa/afp-component-library';

const AgencyReferenceNumberInput = () => {
  const { formState } = useFormContext();
  return (
    <div id="agency-reference-number">
      <Controller
        rules={{
          pattern: {
            value: /^[a-z0-9]{1,5}$/i,
            message:
              'Minimum of 1 and maximum of 5 alphanumeric characters required',
          },
          required: 'Agency reference number is required',
        }}
        name="agencyReferenceNumber"
        render={({ field: { value, onChange, ref } }) => (
          <TextInput
            label={
              <>
                Agency reference number <RequiredFieldIndicator />
              </>
            }
            onChange={onChange}
            value={value}
            inputRef={ref}
            name="agencyReferenceNumber"
            data-testid="agency-reference-number-input"
            errorMessage={formState?.errors?.agencyReferenceNumber?.message}
          />
        )}
      />
    </div>
  );
};

export default AgencyReferenceNumberInput;
