export const orderLineMapping = [
  ['Vehicle line number', 'accountingLineNumber', 'string'],
  ['VON', 'von', 'string'],
  ['VIN', 'vin', 'string'],
  ['Contract', 'contractNumber', 'string'],
  ['Case Number', 'caseNumber', 'string'],
  ['Name', 'contactName', 'string'],
  ['Address', 'line1', 'string'],
  ['City', 'city', 'string'],
  ['STATE', 'stateCode', 'string'],
  ['Zip', 'zipcode', 'string'],
  ['STATUS', 'statusCode', 'string'],
  ['Status Date', 'statusDate', 'string'],
  ['Contact Person', 'contactPerson', 'string'],
  ['Telephone Number', 'contactTelephone', 'string'],
  ['GVWR', 'gvwr', 'string'],
  ['Fuel Type', 'fuelType', 'string'],
  ['GPM', 'gpm', 'string'],
  ['MPG City', 'mpgCity', 'string'],
  ['MPG Highway', 'mpgHighway', 'string'],
  ['MPG Combined', 'mpgCombined', 'string'],
  ['Flex GPM(alpha numeric 4)', 'flexGpm', 'string'],
  ['Flex Fuel MPG City', 'flexFuelMpgCity', 'string'],
  ['Flex Fuel MPG Highway', 'flexFuelMpgHighway', 'string'],
  ['Flex Fuel MPG Combined', 'flexFuelMpgCombined', 'string'],
  ['Color', 'color', 'string'],
  ['Trim', 'trim', 'string'],
  ['Description', 'description', 'string'],
];
