/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@gsa/afp-component-library';
import './MultipleAdditionalRequirements.scss';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { advance } from './MultipleAdditionsRequirements.util';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import PageAlerts from '../PageAlert/PageAlert';
import { RequisitionStep } from '../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import MultipleAdditionalRequirementsTab from './MultipleAdditonalRequirementsTab';
import { useQueryParam } from '../../hooks/useQueryParam';
import { GET_REQUISITION_CART } from '../../services/data-layer';
import { setSessionStorageItems } from '../../utilities/commonUtils';

const MultipleAdditionalRequirements = ({ handleStepper, indicatorSteps }) => {
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const requisitionTypeParam = query.get('requisitionType');

  const { dispatch, state } = useContext(VehicleRequisitionContext);

  const setDraftPropertiesForAREQ = (draftData) => {
    const {
      clientData: { selectedOptions },
    } = draftData;

    const {
      finSignalCode: signalCode,
      finFundCode: fundCode,
      finServiceCode: serviceCode,
      boac,
      julianDate,
      serialNumber,
      finSupplementaryAddress: signalSupplementaryAddress,
      finAdditionalFundsPerUnit: additionalAreqFunds,
      // finAdditionalFinancialInformation: agencyFinancialData,
      customerAssignedNumber: agencyOrderNumber,
      markForInformation,
      transportationControlNumber,
      accountingClassificationReferenceNumber,
      nationalStockNumber,
      registrationNumber,
      requisitionerContact,
      requisitionerAddress,
      mailingAddress,
      mailingContact,
      deliveryAddress,
      deliveryContact,
      unitPriceToCustomer,
      agencyInfo,
      bureauInfo,
      officeInfo,
      finAdditionalInfo,
      agencyContact,
    } = draftData;

    const agencyInformationFromDraft = {
      agency: sessionStorage.getItem('orderingAgency'),
      bureau: sessionStorage.getItem('orderingBureau'),
      office: sessionStorage.getItem('orderingOffice'),
      bureauName: bureauInfo && bureauInfo.name ? bureauInfo.name : '',
      agencyName: agencyInfo && agencyInfo.name ? agencyInfo.name : '',
      groupAssignment:
        officeInfo && officeInfo.officeCode ? officeInfo.officeCode : '',
      signalCode,
      fundCode,
      serviceCode,
      requisitionBOAC: boac,
      requisitionJulian: julianDate,
      requisitionSerialNumber: serialNumber,
      signalSupplementaryAddress,
      markForInformation,
      transportationControlNumber,
      accountingClassificationReferenceNumber,
      nationalStockNumber,
      registrationNumber,
      finAdditionalFundsPerUnit: additionalAreqFunds
        ? additionalAreqFunds.toString()
        : '',
      additionalAreqFunds: additionalAreqFunds
        ? additionalAreqFunds.toString()
        : '',
      agencyFinancialData: finAdditionalInfo,
      agencyOrderNumber:
        requisitionTypeParam === 'clone' ? null : agencyOrderNumber,
      emailAddress: agencyContact.email,
      firstName: agencyContact.firstName,
      lastName: agencyContact.lastName,
      phoneCountryCallingCode: agencyContact.phoneCountryCode,
      phoneNumber: agencyContact.phoneNumber,
      phoneExtension: agencyContact.phoneExtension,
      faxCountryCallingCode: agencyContact.faxCallingCode,
      faxNumber: agencyContact.faxNumber,
      faxExtension: agencyContact.faxExtension,
      unitPrice: unitPriceToCustomer,
      unitPriceWithAreqs:
        unitPriceToCustomer && additionalAreqFunds
          ? (unitPriceToCustomer + additionalAreqFunds).toString()
          : '',
    };

    const requistionAddressAndContact = {
      ...requisitionerAddress,
      ...requisitionerContact,
    };
    const mailingAddressAndContact = { ...mailingAddress, ...mailingContact };
    const deliveryAddressAndContact = {
      ...deliveryAddress,
      ...deliveryContact,
    };

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_DRAFT_PROPERTIES,
      draftRequisition: draftData,
      isAreq: true,
      selectedOptionsForPrice: selectedOptions.selectedOptions || [],
      selectedContractAgencyInformation: agencyInformationFromDraft,
      requisitionStateContext: requistionAddressAndContact,
      mailingStateContext: mailingAddressAndContact,
      deliveryStateContext: deliveryAddressAndContact,
    });
  };

  const [getDraftData] = useLazyQuery(GET_REQUISITION_CART, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getRequisition }) => {
      setSessionStorageItems(getRequisition);
      setDraftPropertiesForAREQ(getRequisition);

      if (getRequisition?.clientData.clientState?.areq) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_AREQ_JUSTIFICATION,
          payload:
            getRequisition?.clientData?.clientState?.areq?.areqJustification,
        });
      }
    },
  });

  useEffect(() => {
    if (requisitionId) {
      getDraftData({ variables: { requisitionId } });
    }
  }, [requisitionId]);

  const {
    agencyReferenceNumber,
    additionalRequirements,
    currentStep,
    currentStandardItem,
    draftRequisition,
  } = state;

  const { createdByInfo } = draftRequisition || {};

  const formProps = useForm({
    defaultValues: {
      agencyReferenceNumber: agencyReferenceNumber || '',
      additionalRequirements: additionalRequirements?.length
        ? additionalRequirements
        : [
            {
              attachments: [],
              description: '',
              priceEstimate: 0,
            },
          ],
    },
    reValidateMode: 'onChange',
  });

  const { handleSubmit, getValues, trigger } = formProps;

  const onSubmit = (data) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_AGENCY_REF_NUMBER,
      payload: data.agencyReferenceNumber,
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
      payload: data.additionalRequirements.map((x) => ({
        attachments: x.attachments.map((attachment) => {
          const { id, ...rest } = attachment;
          return rest;
        }),
        description: x.description,
        priceEstimate: +x.priceEstimate,
      })),
    });

    advance(indicatorSteps, currentStep, handleStepper);
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <PageAlerts page={RequisitionStep.AREQInformation} />
          <MultipleAdditionalRequirementsTab
            vehicleType={currentStandardItem?.vehicleType}
            formProps={formProps}
            createdByInfo={createdByInfo}
            trigger={trigger}
          />

          <div className="page-navigation-buttons">
            <Button
              variant="outline"
              type="button"
              onClick={() => {
                dispatch({
                  type: VehicleRequisitionContextActions.UPDATE_AGENCY_REF_NUMBER,
                  payload: getValues().agencyReferenceNumber,
                });
                dispatch({
                  type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
                  payload: getValues().additionalRequirements.map((x) => ({
                    attachments: x.attachments,
                    description: x.description,
                    priceEstimate: parseFloat(x.priceEstimate || 0),
                  })),
                });

                advance(indicatorSteps, currentStep, handleStepper, 'back');
              }}
              label="Previous"
            />
            <Button variant="default" type="submit" label=" Next" />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

MultipleAdditionalRequirements.propTypes = {
  handleStepper: PropTypes.func.isRequired,
  indicatorSteps: PropTypes.array.isRequired,
};

export default MultipleAdditionalRequirements;
