import z from 'zod';

export const pointOfContactShema = z.object({
  firstName: z.coerce
    .string()
    .min(1, {
      message: 'Please fill out the first name',
    })
    .regex(/^[A-Za-z]+$/, {
      message: 'Invalid first name',
    }),
  lastName: z.coerce
    .string()
    .min(1, {
      message: 'Please fill out the last name',
    })
    .regex(/^[A-Za-z]+$/, {
      message: 'Invalid last name',
    }),
  email: z
    .string()
    .min(1, {
      message: 'Please fill out the email',
    })
    // https://github.com/GSA/afp-vms-web/blob/d6161a74b642f4057cd53f28bdfb90cebc64fdc4/src/utilities/validation.js#L42
    .regex(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: 'Email must be a valid email address',
      },
    ),
  phone: z
    .object({
      countryCode: z.coerce
        .string()
        .min(1, {
          message: 'Please fill out the country code',
        })
        .refine(
          (data) => data !== 'undefined',
          'Please fill out the country code',
        ),
      number: z.coerce
        .string()
        .min(8, {
          message: 'Please fill out the phone number',
        })
        .max(15, {
          message: 'Please fill out the phone number',
        }),
      extension: z.coerce.string(),
    })
    .superRefine((val, ctx) => {
      // check if selected country is US
      if (val.countryCode === '1') {
        if (
          !new RegExp(/^[(]?[0-9]{3}[)-\s]?[0-9]{3}[-\s]?[0-9]{4}$/).test(
            val.number,
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid phone number',
            path: ['number'],
            fatal: true,
          });
        }
      }
    }),
  fax: z.object({
    countryCode: z.coerce.string(),
    number: z.coerce.string(),
    extension: z.coerce.string(),
  }),
});
