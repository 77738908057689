import { useContext } from 'react';
import { MyOrdersContext } from './MyOrdersProvider';

export default function useMyOrdersState() {
  const context = useContext(MyOrdersContext);
  if (!context) {
    throw new Error(
      'useMyOrdersState can only be used within MyOrdersProvider',
    );
  }
  return context;
}
