import React, { useState } from 'react';
import { Alert, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import ShipmentInfoModal from './ShimpmentInfoModal';

const ShipmentInfoAlert = ({
  agency,
  bureau,
  office,
  state,
  needsLink,
  dealerOnly,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const handleLinkClick = () => {
    setIsModalOpen(true);
  };
  const handleOnClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      {showAlert && (
        <div className="margin-top-4">
          <Alert
            data-testid="shipment-info-alert"
            type="info"
            showClose
            onClose={handleOnClose}
          >
            This order is for{' '}
            <strong>
              {agency}
              {bureau ? `/${bureau}` : ''}
              {office ? `/${office}` : ''}
            </strong>{' '}
            to be shipped to <strong>{state}</strong>.{' '}
            {dealerOnly && (
              <>
                <br />
                Dealership delivery is standard for this vehicle. Other delivery
                options maybe available under the Delivery option section.
                <br />
              </>
            )}
            {needsLink && (
              <Button
                variant="unstyled"
                label="Change shipment information"
                data-testid="shipment-info-link"
                onClick={handleLinkClick}
              />
            )}
          </Alert>
          {needsLink && isModalOpen && (
            <ShipmentInfoModal onClose={() => setIsModalOpen(false)} />
          )}
        </div>
      )}
    </>
  );
};

export default ShipmentInfoAlert;
ShipmentInfoAlert.defaultProps = {
  agency: '',
  bureau: '',
  office: '',
  state: '',
  needsLink: false,
  dealerOnly: false,
};
ShipmentInfoAlert.propTypes = {
  agency: PropTypes.string,
  bureau: PropTypes.string,
  office: PropTypes.string,
  state: PropTypes.string,
  needsLink: PropTypes.bool,
  dealerOnly: PropTypes.bool,
};
