import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';

const LogoutPage = () => {
  const navigate = useNavigate();
  const { logout } = useCurrentUser();
  React.useLayoutEffect(() => {
    logout();
    navigate('/auth');
  });

  return (
    <div>
      <h6>Logging out..</h6>
    </div>
  );
};

export default LogoutPage;
