import { combineReducers } from 'redux';
import purchaseReducer from './purchase';
import orderReducer from './order';
import dependencyReducer from './dependency';
import leasingReducer from './leasing';
import orderingGuideReducer from './ordering-guide';


const rootReducer = combineReducers({
  purchaseReducer,
  orderReducer,
  dependencyReducer,
  leasingReducer,
  orderingGuideReducer
});

export default rootReducer;
