import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import SubmitterRoundTwoApproveButton from '../SubmitterRoundTwoApproveButton/SubmitterRoundTwoApproveButton';
import './SubmittedRoundTwoActionButtons.scss';
import PlaceOrderModal from '../../pages/ReviewDetails/PlaceOrderModal';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import CancelRequisitionButton from '../../pages/ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';
import {UserRoles} from '../../constants/user-constants';

const SubmitterRoundTwoActionButtons = ({
  requisitionId,
  requisitionCartState,
  reference,
  requisitionCartValidations,
  canSubmitOrder,
}) => {
  const { currentUser } = useCurrentUser();

  const ability = useAppAbility();
  const isCustomer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );
  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const sameAsRequisitionCreator = useMemo(
    () => currentUser?.id === requisitionCartState?.createdByInfo?.id,
    [currentUser, requisitionCartState],
  );
  const isOrderingAdmin = currentUser?.roles?.some(
        (role) => role.name === UserRoles.ORDERING_ADMIN,
  );
  const isAdmin = currentUser?.roles?.some(
        (role) => role.name === UserRoles.SITE_ADMIN,
  );
  const { selectedVendorQuote } = useContext(VehicleRequisitionContext);

  const { requisitionStatus } = requisitionCartState;

  const customerInPendingCustomerStatus =
    (isCustomer || isApprover || isOrderingAdmin || isAdmin) &&
    requisitionStatus === RequisitionStatus.PENDING_CUSTOMER_RESPONSE;

  if (!customerInPendingCustomerStatus || (!sameAsRequisitionCreator && !isOrderingAdmin && !isAdmin)) {
    return null;
  }

  return (
    <>
      <CancelRequisitionButton requisitionId={requisitionId} />
      {selectedVendorQuote && (
        <>
          {(isApprover || isOrderingAdmin || isAdmin) && (
            <PlaceOrderModal
              reference={reference}
              requisitionStatus={requisitionStatus}
              requisitionId={requisitionId}
              requisitionName={requisitionCartState.friendlyName}
              requisitionCartValidations={requisitionCartValidations}
              canSubmitOrder={canSubmitOrder}
            />
          )}
          { isCustomer  && !isApprover  && (
            <SubmitterRoundTwoApproveButton
              reference={reference}
              requisitionId={requisitionId}
              requisitionName={requisitionCartState.friendlyName}
            />
          )}
        </>
      )}
    </>
  );
};

SubmitterRoundTwoActionButtons.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
};

export default SubmitterRoundTwoActionButtons;
