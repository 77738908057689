import React, { useState, useEffect } from 'react';
import {
  Accordion,
  DatePicker,
  TextInput, Label,
  Alert,
  Textbox,
  RadioButton,
  useModal,
  connectModal
} from '@gsa/afp-component-library';
import useBulkOrderModsState from '../useBulkOrderModsState';
import FieldFeedback from '../../../components/FieldFeedback/FieldFeedback';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
// eslint-disable-next-line import/no-cycle
import OrderModQuantityChangeAlertModal from './OrderModQuantityChangeAlertModal';
import { orderChangesFeedback } from '../UpdateOrderModHelpers';
import './changeSets.scss';

const feedback = orderChangesFeedback;
export default function OrderChanges() {
  const {
    selectedOrdersForMod,
    orderModHasError,
    setOrderModHasError,
    orderChanges,
    setOrderChanges,
    changeSetOrderWithPO
  } = useBulkOrderModsState();
  const alertModal = useModal();
  const DisplayQtyChangeAlertModal = connectModal(
    OrderModQuantityChangeAlertModal,
  );
  const [allOrdersForTermination, setAllOrdersForTermination] = useState(false);
  const [ordersAffected, setOrdersAffected] = useState([]);
  const [changeSetInfoAlert, setChangeSetInfoAlert] = useState('');
  const quantityChangeInfo =
    'Pegasys can only process one modification at a time. ' +
    'If you want to perform both  price AND quantity change, you will need to create a separate modification for each change.';
  const quantityActionInfo =
    'Vehicle quantities may only decrease.  If a customer wants to increase quantity, they will need to create a new requisition.  Total vehicle quantities cannot be equal to zero.  ' +
    'If you want to terminate an order, please select the "terminate these orders" action above.';

  const perOrderOptionQtyChangeAlert = 'You have reduced the vehicle quantity on an order(s) that contain order level options, e.g. PSM, PSME.  ' +
      'You will need to issue a second mod after the evening cycle has successfully run so that the system can update the pricing.  ' +
      'The second mod can be any non-contractual change, e.g. updating a message line.';
  const updateOrderModHasError = (key, visibilityValue, textValue) => {
    const updatedErrors = orderModHasError.map((item) => {
      if (item.key === key) {
        return {
          key,
          visibility: visibilityValue,
          text: textValue,
        };
      }
      return item;
    });
    setOrderModHasError(updatedErrors);
  };
  const validateValue = (key, rawValue, focused = null, context = {}) => {
    const errors = [];

    if (rawValue === undefined || rawValue === null) {
      return {
        errors: [{ key, visibility: 'error', text: 'Invalid value' }],
      };
    }
    // eslint-disable-next-line no-shadow
    const selectedOrders = context?.selectedOrdersForMod;
    const value = rawValue.toString().trim();

    switch (key) {
      case 'quantityChangePerOrder':
        // eslint-disable-next-line no-case-declarations
        const selectedQuantity = parseInt(value, 10);
        // eslint-disable-next-line no-case-declarations
        const absQuantity = Math.abs(selectedQuantity);
        // eslint-disable-next-line no-case-declarations
        const orderQtyBlwZero = selectedOrders?.some(
          (order) => order.quantity + selectedQuantity < 0,
        );
        if (
          (value === '' || orderQtyBlwZero || selectedQuantity > 0) &&
          !focused
        ) {
          if (selectedQuantity > 0) {
            errors.push({
              key,
              visibility: 'error',
              text: feedback[key][1],
            });
          } else if (orderQtyBlwZero) {
            errors.push({
              key,
              visibility: 'error',
              text: feedback[key][2],
            });
          } else {
            errors.push({
              key,
              visibility: 'error',
              text: feedback[key][0],
            });
          }
        } else if (value !== '' && parseInt(value, 10) < 0) {
          const orderForTerm = selectedOrders?.filter(
            (item) => parseInt(item.quantity, 10) === absQuantity,
          );
          if (orderForTerm?.length > 0) {
            setOrdersAffected(orderForTerm);
            if (orderForTerm?.length === selectedOrders?.length) {
              setAllOrdersForTermination(true);
            }
            alertModal.openModal();
            updateOrderModHasError(key, false, false);
          } else {
            updateOrderModHasError(key, false, false);
            errors.push({ key, visibility: false, text: false });
          }
        } else {
          updateOrderModHasError(key, false, false);
          errors.push({ key, visibility: false, text: false });
        }
        break;
      case 'priceChangePerOrder':
        // eslint-disable-next-line no-case-declarations
        let inValidPrice = false;
        /**
         *  if the total new price to customer is less than 0, it is invalid price.
         */
        if (selectedOrders?.length > 0) {
          selectedOrders.forEach((order) => {
            const unitPriceToCustomer = parseInt(
              order?.unitPriceToCustomer ?? '0',
              10,
            );
            const newPrice = unitPriceToCustomer + parseInt(value, 10);
            if (newPrice < 0) {
              inValidPrice = true;
            }
          });
        }

        if (value !== '' && inValidPrice && !focused) {
          errors.push({
            key,
            visibility: 'error',
            text: feedback[key][0],
          });
        } else {
          updateOrderModHasError(key, false, false);
          errors.push({ key, visibility: false, text: false });
        }
        break;
      default:
        break;
    }

    return { errors };
  };

  const onQtyChangeModalClose = () => {
    validateValue(
      'quantityChangePerOrder',
      orderChanges.quantityChangePerOrder,
    );
    alertModal.closeModal();
  };
  const orderChangeOptions = [
    {
      label: 'Quantity change',
      value: 'quantity',
      default: false,
    },
    {
      label: 'Price change',
      value: 'price',
      default: false,
    },
  ];

  const [visibility, setVisibility] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackText, setFeedbackText] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [focus, setFocus] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  function setError(key, visibilityValue, textValue) {
    setVisibility({ ...visibility, [key]: visibilityValue });
    setFeedbackText({ ...feedbackText, [key]: textValue });
  }

  function setAllErrors(errors) {
    setVisibility({
      ...Object.keys(feedback).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibility,
    });
    setFeedbackText({
      ...Object.keys(feedback).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackText,
    });
  }

  useEffect(() => {
    if (orderModHasError && orderModHasError?.length > 0) {
      const errors = {
        visibility: orderModHasError?.reduce((acc, cur) => {
          if (cur?.visibility === 'error') {
            return { ...acc, [cur.key]: 'error' };
          }
          return null;
        }, {}),
        feedbackText: orderModHasError?.reduce((acc, cur) => {
          if (cur.feedbackText) {
            return { ...acc, [cur.key]: cur.text };
          }
          return null;
        }, {}),
      };

      setAllErrors(errors);
    }
  }, [orderModHasError]);

  const handleOnOrderChangeType = (changeType) => {
      setChangeSetInfoAlert('');
      if (changeType === 'price') {
      setOrderChanges((prevState) => {
        const newState = { ...prevState };
        newState.quantityChangePerOrder = '0';
        newState.orderChangeType = changeType;
        return newState;
      });
      setError('quantityChangePerOrder', false, false);
      updateOrderModHasError('quantityChangePerOrder', false, false);
    }
    if (changeType === 'quantity') {
      setOrderChanges((prevState) => {
        const newState = { ...prevState };
        newState.priceChangePerOrder = '0';
        newState.orderChangeType = changeType;
        return newState;
      });
      setError('priceChangePerOrder', false, false);
      updateOrderModHasError('priceChangePerOrder', false, false);
    }
    if (changeType === 'none') {
      setOrderChanges((prevState) => {
        const newState = { ...prevState };
        newState.priceChangePerOrder = '0';
        newState.quantityChangePerOrder = '0';
        newState.orderChangeType = '';
        return newState;
      });
      setError('priceChangePerOrder', false, false);
      updateOrderModHasError('priceChangePerOrder', false, false);
      setError('quantityChangePerOrder', false, false);
      updateOrderModHasError('quantityChangePerOrder', false, false);
    }
  };
  const handleRadioButtonChecked = (selectedOption) => {
    if (orderChanges.orderChangeType === selectedOption) {
      return true;
    }
    return false;
  };
  function updateValue(e, element, focused = null) {
    setChangeSetInfoAlert('');
    if (!e || !e.target) return false;
    const isFocused = focused === null ? focus[element] : focused;
    setFocus({ [element]: isFocused });
    const numberInputs = ['surcharge'];
    const newValues = {
      ...orderChanges,
      // eslint-disable-next-line radix
      [element]: numberInputs.includes(element)
        ? parseInt(e.target.value, 10)
        : e.target.value,
    };

    let errors = [];

    // Validations for requisition number Node 1
      if (element === 'quantityChangePerOrder' && !isFocused) {
      const quantityError = validateValue(
        'quantityChangePerOrder',
        e.target.value || orderChanges?.quantityChangePerOrder,
        focused,
        { selectedOrdersForMod },
      )?.errors;
      orderModHasError.push(...quantityError);
      errors = [...errors, ...quantityError];
      if(e.target.value < 0 && changeSetOrderWithPO){
        setChangeSetInfoAlert(perOrderOptionQtyChangeAlert)
      }
    }

    if (element === 'priceChangePerOrder' && !isFocused) {
      const unitPriceError = validateValue(
        'priceChangePerOrder',
        e.target.value,
        focused,
        { selectedOrdersForMod },
      )?.errors;
      orderModHasError.push(...unitPriceError);
      errors = [...errors, ...unitPriceError];
    }

    errors.forEach((err) => setError(err.key, err.visibility, err.text));
    setOrderChanges((prev) => ({
      ...prev,
      ...newValues,
    }));
    return true;
  }

  function handleOnBlur() {}

  const formattedDate = orderChanges?.shipmentDate?.replace(
    /(\d\d)\/(\d\d)\/(\d{4})/,
    '$3-$1-$2',
  );

  const getOrderChangeContent = () => {
    return [
      {
        id: 'orderChange',
        title: (
          <div
            className="add-options-accordion-title"
            data-testid="order-change-title"
          >
            Order changes
          </div>
        ),
        content: (
          <div className="ordermod-accordion-container">
            <div className="info-header">
              <label className="info-label" htmlFor="agencyInfo">
                Order changes
              </label>
            </div>
            <div>
              <span className="text-bold">
                Apply price change per vehicle (or) quantity change per order:
                <InfoTooltip
                  label="Quantity change per order"
                  title=""
                  text={quantityChangeInfo}
                />
              </span>
              {changeSetInfoAlert && (
                <Alert type="warning" focused className="margin-bottom-2" data-testid="order-mod-qty-change-info-warning-banner">
                  {changeSetInfoAlert}
                </Alert>
                )}
            </div>
            <div className="grid-row grid-gap-1">
              {orderChangeOptions.map((option) => (
                <RadioButton
                  key={`order-mod-${option.value}`}
                  className="inline-radio"
                  name={`sop-${option.value}`}
                  data-testid={`order-mod-${option.value}`}
                  onClick={(e) => {
                    if (orderChanges.orderChangeType === option.value) {
                      // setOrderChangeType('');
                      handleOnOrderChangeType('none');
                    } else {
                      // setOrderChangeType(e.target.value);
                      handleOnOrderChangeType(e.target.value);
                    }
                  }}
                  checked={handleRadioButtonChecked(option.value)}
                  {...option}
                />
              ))}
            </div>
            <div>
              {orderChanges.orderChangeType === 'quantity' && (
                <div className="grid-col-6 order-mod-change-type-block">
                  <Label htmlFor="quantity-change" className="text-bold">
                    Quantity change per order
                    <InfoTooltip
                      label="Quantity change action"
                      title=""
                      text={quantityActionInfo}
                    />
                  </Label>
                  <FieldFeedback
                    text={feedbackText.quantityChangePerOrder}
                    visible={visibility.quantityChangePerOrder}
                    id="quantityChangePerOrderFeedback"
                    applyTo={(child) => child.props.type === 'text'}
                  >
                    <Textbox
                      id="quantityChangePerOrder"
                      required
                      value={
                        orderChanges.orderChangeType === 'quantity'
                          ? orderChanges.quantityChangePerOrder
                          : 0
                      }
                      data-testid="quantity-change-per-order"
                      key="quantityChangePerOrder"
                      name="quantityChangePerOrder"
                      type="number"
                      className="field-commom-width"
                      onChange={(e) =>
                        updateValue(e, 'quantityChangePerOrder', true)
                      }
                      onFocus={(e) =>
                        updateValue(e, 'quantityChangePerOrder', true)
                      }
                      onBlur={(e) => {
                        updateValue(e, 'quantityChangePerOrder', false);
                        handleOnBlur();
                      }}
                    />
                  </FieldFeedback>
                </div>
              )}
              {orderChanges.orderChangeType === 'price' && (
                <div className="grid-col-6 order-mod-change-type-block">
                  <FieldFeedback
                    text={feedbackText.priceChangePerOrder}
                    visible={visibility.priceChangePerOrder}
                    id="priceChangePerOrderFeedback"
                    applyTo={(child) => child.props.type === 'text'}
                  >
                    <TextInput
                      id="priceChangePerOrder"
                      required
                      value={
                        orderChanges.orderChangeType === 'price'
                          ? orderChanges.priceChangePerOrder
                          : 0
                      }
                      data-testid="price-change-per-order"
                      key="priceChangePerOrder"
                      name="priceChangePerOrder"
                      prefix="$"
                      type="number"
                      label="Price change per vehicle"
                      className="field-commom-width"
                      onChange={(e) =>
                        updateValue(e, 'priceChangePerOrder', true)
                      }
                      onFocus={(e) =>
                        updateValue(e, 'priceChangePerOrder', true)
                      }
                      onBlur={(e) => {
                        updateValue(e, 'priceChangePerOrder', false);
                        handleOnBlur();
                      }}
                    />
                  </FieldFeedback>
                </div>
              )}
            </div>
            
            <div className="grid-row grid-gap-1">
              <div className="grid-col-6">
                <DatePicker
                  id="shipmentDate"
                  name="shipmentDate"
                  key={formattedDate}
                  data-testid="shipmentDate"
                  hint="mm/dd/yyyy"
                  label="Shipment date"
                  defaultValue={formattedDate || ''}
                  onChange={(e) => {
                    updateValue({ target: { value: e } }, 'shipmentDate');
                  }}
                />
              </div>
            </div>
            <DisplayQtyChangeAlertModal
              isOpen={alertModal.isOpen}
              onClose={() => onQtyChangeModalClose()}
              allForTermination={allOrdersForTermination}
              ordersForTermination={ordersAffected}
            />
          </div>
        ),
        expanded: false,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getOrderChangeContent()}
        className="add-options-accordion"
      />
    </>
  );
}
