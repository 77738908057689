import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const SubmitToEngineerSuccessMessage = ({ reqName }) => {
  const navigate = useNavigate();
  const [enableMessage, setEnableMessage] = useState(true);

  // Display for 10 seconds
  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
      navigate('/my-requisitions');
    }, 10000);
  }, []);

  return (
    <>
      {enableMessage && (
        <Alert type="success" slim>
          <span data-testid="submit-to-engineer-success-message">
            You have successfully submitted <strong>{reqName}</strong> for
            engineer review.
          </span>
        </Alert>
      )}
    </>
  );
};

SubmitToEngineerSuccessMessage.propTypes = {
  reqName: PropTypes.string.isRequired,
};

export default SubmitToEngineerSuccessMessage;
