import React from "react";
import {
    ComboBox,
    Label,
    RadioButton,
    Spinner
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from "react-redux";
import {
    setDestinationType,
    setStates,
    setCountries,
    setShipmentCountry,
    setShipmentState,
    setShipmentLocation,
    setShowAvailableVehicles,
    setAvailableVehicleTypes,
    setVehicleTypeFilters
} from '../../../../../reducers/leasing';
import { useGetStates, useGetCountries, useGetVehicleTypes } from '../../../../../requests/leasing';
import FieldFeedback from '../../../../../components/FieldFeedback/FieldFeedback.jsx';
import { VEHICLE_GROUP_TAG_STORE } from "../../../../../constants/constants.jsx";
import { orderBy } from "lodash";
import { placeholderOption } from "../../../../vehicle-listings/filter-item-constants.jsx";

const getLabelValueFromItem = (item) => {
    return {
        label: item.description,
        value: item.code,
    };
};

const stateVariables = Object.freeze({
    SHIPMENT_STATE: 'shipmentState',
    SHIPMENT_COUNTRY: 'shipmentCountry'
});

const ShipmentOptions = () => {
    const dispatch = useDispatch();
    const { loading: loadingStates } = useGetStates({
        variables: { category: 'ISOStateCode-US' },
        onCompleted: (data) => {
            dispatch(setStates(getComboboxData(data.getCatalogsForCategory, 'state')));
        }
    });

    const { loading: loadingCountries } = useGetCountries({
        variables: { category: 'ISOCountryCode2' },
        onCompleted: (data) => {
            dispatch(setCountries(getComboboxData(data.getCatalogsForCategory, 'country')));
        }
    })

    const { destinationType, states, countries, shipmentCountry, shipmentState } = useSelector(state => state.leasingReducer);

    const { loading: vehicleTypesLoading } = useGetVehicleTypes({
        variables: {
            childTag: VEHICLE_GROUP_TAG_STORE,
        },
        onError: (e) => {
            console.error('error getting vehicle types', e);
        },
        onCompleted: ({ getActiveVehicleGroups: activeVehicleTypes }) => {
            dispatch(setAvailableVehicleTypes(activeVehicleTypes));
            const items = orderBy(
                activeVehicleTypes.map((x) => ({
                    label: x.title,
                    value: x.code,
                    key: x.code,
                })),
                ['label']);

            dispatch(setVehicleTypeFilters([...[placeholderOption], ...items]))
        }
    });

    const getComboboxData = (data, type) => {
        const modifiedData = [...data.map(getLabelValueFromItem)].sort((a, b) => a.label.localeCompare(b.label));
        modifiedData.unshift({
            label: `Select ${type}`,
            value: `Select ${type}`
        });
        return modifiedData;
    }

    const ShipmentOptionValues = {
        usTerritory: 'us-territory',
        foreignCountry: 'foreign-county',
    };

    const options = [
        {
            label: 'US State/Territory',
            value: ShipmentOptionValues.usTerritory,
        },
        {
            label: 'Foreign Country',
            value: ShipmentOptionValues.foreignCountry,
        },
    ];

    const changeDestinationType = (e) => {
        dispatch(setDestinationType(e.target.value));

    }

    const setInState = (e, type) => {
        if (type === stateVariables.SHIPMENT_STATE && shipmentCountry !== 'Select country') {
            dispatch(setShipmentCountry('US'));
        }
        
        if (type === stateVariables.SHIPMENT_STATE && e !== shipmentState) {
            dispatch(setShipmentState(e || 'Select state'));
            dispatch(setShipmentLocation(states?.find(state => state.value === e)?.label));
            dispatch(setShipmentCountry("US"));
        }

        if (type === stateVariables.SHIPMENT_COUNTRY) {
            if (e !== shipmentCountry) {
                dispatch(setShipmentLocation(null));
                dispatch(setShipmentCountry(e || 'Select country'));
            }

            if (e === 'US' && shipmentState === 'Select state') {
                dispatch(setShowAvailableVehicles(false));
                return;
            }
        }

        if ((e && !e.startsWith('Select'))) {
            dispatch(setShowAvailableVehicles(true));
        } else {
            dispatch(setShowAvailableVehicles(false));
        }
    }

    return (
        <div data-testid="lease-shipment-options">
            <div className='destination-type'>
                <Label htmlFor='destination-location' required>Please select final delivery destination</Label>
                <div className="inline-radio-group">
                    {options.map((option) => (
                        <RadioButton
                            key={`shipment-option-radio-${option.value}`}
                            className="inline-radio"
                            name="destination-location"
                            data-testid={`shipment-option-${option.value}`}
                            checked={destinationType === option.value}
                            onChange={(e) => {
                                changeDestinationType(e)
                            }}
                            {...option}
                        />
                    ))}
                </div>
            </div>
            {
                (loadingStates || loadingCountries || vehicleTypesLoading) ? (
                    <Spinner aria-busy="true" size="large" />
                ) :
                    destinationType && (
                        <div className="shipment-option-filter filter-container">
                            {destinationType === ShipmentOptionValues.usTerritory && (
                                <>
                                    <Label htmlFor='shipment-us-state' required>
                                        US state or territory
                                    </Label>
                                    <ComboBox
                                        data-testid="shipment-option-select-state"
                                        id="shipment-us-state"
                                        key={`${states?.find(state => state.value === shipmentState)?.label}-key`}
                                        name="shipment-option-states"
                                        options={states || []}
                                        defaultValue={shipmentState}
                                        value={shipmentState}
                                        onChange={(e) => {
                                            setInState(e, stateVariables.SHIPMENT_STATE)
                                        }}
                                    />
                                </>
                            )}
                            {destinationType === ShipmentOptionValues.foreignCountry && (
                                <>
                                    <Label htmlFor='shipment-us-country' required>
                                        Foreign Country
                                    </Label>
                                    <FieldFeedback
                                        visible={false}
                                        id="stateFeedback"
                                        applyTo={(child) => 'containerClassName' in child.props}
                                    >
                                        <ComboBox
                                            data-testid="shipment-option-select-country"
                                            id="shipment-us-country"
                                            name="shipment-option-countries"
                                            options={countries}
                                            defaultValue={shipmentCountry}
                                            onChange={(e) => {
                                                setInState(e, stateVariables.SHIPMENT_COUNTRY)
                                            }}
                                        />
                                    </FieldFeedback>
                                </>
                            )}
                        </div>
                    )
            }
        </div>
    );

}

export default ShipmentOptions;