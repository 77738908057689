import {
    GET_ORDER_DETAILS,
    GET_ORDER_WITH_LEASING_DETAILS,
    GET_ORDERS_FOR_USER,
    GET_CONTRACT_LINE_BY_ID,
    GET_REQUISITION_ACTIVITIES,
    GET_ORDER_ACTIVITIES,
    GET_ORDER_ATTACHMENTS,
    GET_REQUISITION_ATTACHMENTS,
    GET_OPTIONAL_REQ,
    GET_OPTIONS_BY_CONTRACT_NUMBER,
    CREATE_ORDER_COMMENT,
    GET_ORDER_ATTACHMENT_TYPES,
    FETCH_SIGNED_URL_ORDERS,
    CREATE_ORDER_ATTACHMENT,
    GET_ORDER_LINES_FOR_ORDER, GET_ORDER_ATTACHMENTS_BY_CASE_NUMBER
} from '../services/data-layer';
import {
    orderInProgress,
    orderSuccess,
    orderFailure,
    getOrdersForUser,
    getOrderDetails,
    orderDetailsInProgress,
    orderDetailsFailure,
    getContractLineIdData,
    setOrderActivitiesData,
    setRequisitionActivitiesData,
    setOrderAttachments,
    getRequisitionAttachmentsData,
    getContractLineOptionsData,
    paintAndGraphicOptionsData,
    inReqTaggedOptionsData,
    engineerTaggedOptionsData,
    optionalReqData,
    orderAttachmentsInProgress,
    setOrderAttachmentTypesData,
    setOrderVehicles,
    setOrderVehiclesLoading
} from '../reducers/order';
import {IN_REQ_TAG, PAINT_OPT, ENGINEER_TAG} from '../constants/constants.jsx';
import axios from 'axios';


const { appURLs } = window.AFP_CONFIG;

export function getOrdersForUserRequest(query = {}) {
  return (dispatch, getState, { client }) => {
    dispatch(orderInProgress(true));
    client
      .query({
        query: GET_ORDERS_FOR_USER,
        variables: {
          query,
        },
        fetchPolicy: 'no-cache'
      })
      .then((response) => {
        dispatch(getOrdersForUser(response.data.getAllOrdersForUserWithLeasing));
        dispatch(orderSuccess());
      })
      .catch((error) => {
        dispatch(orderFailure(error));
      })
      .finally(() => {
        dispatch(orderInProgress(false));
      });
  };
}

export function getContractByLineId(contractLineId) {
  if (!contractLineId) {
    return {};
  }
  return async (dispatch, getState, { client }) => {
    client
      .query({
        query: GET_CONTRACT_LINE_BY_ID,
        variables: { contractLineId },
      })
      .then((response) => {
        dispatch(getContractLineIdData(response?.data?.storeGetContractLineById));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getSelectedOrderDetails(orderId) {
  if (!orderId) {
    return null;
  }
  return async (dispatch, getState, { client }) => {
    dispatch(orderDetailsInProgress(true));
    client
      .query({
        query: GET_ORDER_WITH_LEASING_DETAILS,
        variables: { orderId },
      })
      .then((response) => {
        dispatch(getOrderDetails({...response?.data?.getOrderWithLeasingById?.leasing, ...response?.data?.getOrderWithLeasingById?.order}));
        dispatch(getContractByLineId(response?.data?.getOrderWithLeasingById?.order?.contractLineId));
        dispatch(getOrderAttachments(response?.data?.getOrderWithLeasingById?.order?.caseNumber));

      })
      .catch((error) => {
        dispatch(orderDetailsFailure(error));
      })
      .finally(() => {
        dispatch(orderDetailsInProgress(false));
      });
  };
}
export function getRequisitionAttachments(requisitionId) {
    if (!requisitionId) {
        return null;
    }
    return async (dispatch, getState, {client}) => {
        client
            .query({
                query: GET_REQUISITION_ATTACHMENTS,
                variables: {requisitionId}
            })
            .then((response) => {
                dispatch(getRequisitionAttachmentsData(response?.data?.getAllRequisitionAttachments))
            })
            .catch((error) => {
                console.error(error);
            });
    }
}

export function getOrderAttachments(caseNumber) {
  if (!caseNumber) {
    return null;
  }
  return async (dispatch, getState, { client }) => {
    dispatch(orderAttachmentsInProgress(true));
    client
      .query({
        query: GET_ORDER_ATTACHMENTS_BY_CASE_NUMBER,
        variables: {
            caseNumber,
          type: '',
        },
        fetchPolicy: 'no-cache'
      })
      .then((response) => {
        dispatch(setOrderAttachments(response?.data?.getAllOrderAttachmentsByCaseNumber));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(orderAttachmentsInProgress(false));
      });
  };
}

export function getOrderActivityDetails(orderId) {
  if (!orderId) {
    return null;
  }
  return async (dispatch, getState, { client }) => {
    client
      .query({
        query: GET_ORDER_ACTIVITIES,
        variables: {
          orderId,
          order: [['createdAt', 'DESC']],
        },
        fetchPolicy: 'no-cache'
      })
      .then((response) => {
        dispatch(setOrderActivitiesData(response?.data?.getOrderActivities));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getRequisitionActivityDetails(requisitionId) {
  if (!requisitionId) {
    return null;
  }
  return async (dispatch, getState, { client }) => {
    client
      .query({
        query: GET_REQUISITION_ACTIVITIES,
        variables: {
          requisitionId,
          offset: 0,
          order: [['createdAt', 'DESC']],
        },
      })
      .then((response) => {
        dispatch(setRequisitionActivitiesData(response?.data?.getRequisitionActivities));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getContractLineOptionsForOrder(query = {}) {
    return (dispatch, getState, {client}) => {
        client
            .query({
                query: GET_OPTIONS_BY_CONTRACT_NUMBER,
                variables: query
            })
            .then((response) => {
                if(response?.data?.storeGetContractLineEquipment){
                    const options = response?.data?.storeGetContractLineEquipment;
                    dispatch(getContractLineOptionsData(options));
                    const pgOpts = [];
                    const inReqTags = [];
                    const eTaggedOpts = [];
                    if(options?.length > 0 ){
                        options?.forEach((option) => {
                            const optionTags =
                                option?.equipment.tags?.value ||
                                [];
                            if (optionTags.includes(PAINT_OPT)) {
                                pgOpts.push(option);
                            } else if (optionTags.includes(IN_REQ_TAG)) {
                                inReqTags.push(option);
                            } else if (optionTags.includes(ENGINEER_TAG)) {
                                eTaggedOpts.push(option);
                            }
                        });
                    }
                    dispatch(paintAndGraphicOptionsData(pgOpts));
                    dispatch(inReqTaggedOptionsData(inReqTags));
                    dispatch(engineerTaggedOptionsData(eTaggedOpts));
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }
}

export function getOptionalReqForOrder(standardItemId)  {
    if (!standardItemId) {
        return null;
    }
    return (dispatch, getState, {client}) => {
        client
            .query({
                query: GET_OPTIONAL_REQ,
                variables: {
                    standardItemId
                }
            })
            .then((response) => {
                if(response?.data){
                    dispatch(optionalReqData(response?.data));
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }
}

export function orderCommentsMutation(query) {
  if (!query) {
    return null;
  }
  return async (dispatch, getState, {client}) => {
    client
      .mutate({
        mutation: CREATE_ORDER_COMMENT,
        variables: {...query},
      })
      .then((response) => {
        if(response?.data){
          dispatch(getOrderActivityDetails(query?.orderId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getOrderAttachmentTypes() {
  return async (dispatch, getState, { client }) => {
    client
      .query({
        query: GET_ORDER_ATTACHMENT_TYPES
      })
      .then((response) => {
        dispatch(setOrderAttachmentTypesData(response?.data?.getAllOrderAttachmentTypes));
      })
      .catch((error) => {
        console.error(error);
      })
  };
}


export function generateSignedUrlForOrders(data) {
  if (!data) { return null}

  const toBeRounded = (bytes) => {
    const converted = bytes / (1024 * 1024);
    const size = converted.toFixed(2);
    return Number(size);
  };

  return async (dispatch, getState, { client }) => {
    client
      .mutate({
        mutation: FETCH_SIGNED_URL_ORDERS,
        variables: {
          input: {
            orderId: data?.orderDetails?.orderId,
            name: data?.values?.file?.name,
            status: 1,
            type: data?.values?.fileType,
            docStoreUri: appURLs.store,
            size: toBeRounded(data?.values?.file?.size),
            fileMimeType: data?.values?.file?.type,
            description: data?.values?.description,
          },
        },
      })
      .then(async (response) => {
        await axios.put(
          response?.data?.generateSignedUrlForOrders?.signedUrl,
          data?.values?.file,
          {
            headers: {
              'Content-Type': data?.values.file.type,
            },
          },
        );

        client
          .mutate({
            mutation: CREATE_ORDER_ATTACHMENT,
            variables: {
              input: {
                orderId: data?.orderDetails?.orderId,
                name: data?.values?.file?.name,
                status: 1,
                metadataId:
                  response?.data?.generateSignedUrlForOrders?.metadataId,
                orderAttachmentTypeId: data?.values?.fileType,
                docStoreUri: appURLs.store,
                size: toBeRounded(data?.values?.file?.size),
                fileMimeType: data?.values?.file?.type,
                description: data?.values?.description,
                signedUrl:
                  response?.data?.generateSignedUrlForOrders?.signedUrl,
                equipmentCode: data?.values?.fileType,
                isInternal: data?.values.isInternalAttachment,
              },
            },
          })
          .then((response) => {
            if(response?.data){
              dispatch(getOrderAttachments(data?.orderDetails?.caseNumber));
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getOrderLines(queryVariables) {
  return async (dispatch, getState, { client }) => {
    dispatch(setOrderVehiclesLoading(true));
    client
      .query({
        query: GET_ORDER_LINES_FOR_ORDER,
        variables: {...queryVariables},
        fetchPolicy: 'no-cache'
      })
      .then((response) => {
        dispatch(setOrderVehicles(response?.data?.getOrderVehiclesForOrder));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setOrderVehiclesLoading(false));
      });
  };
}