import React from 'react';
import PropTypes from 'prop-types';
import CheckedIcon from '../../assets/images/checked-icon';

const EngineerApprovalApproveButton = ({ reference }) => {
  return (
    <>
      <button
        data-testid="engineer-approval-approve-button"
        type="button"
        className="co-approve-button"
        onClick={() => reference.current.submitVendorAcceptanceForm()}
      >
        <CheckedIcon />
        <span className="button-text">
          Approve and submit to contracting officer
        </span>
      </button>
    </>
  );
};

EngineerApprovalApproveButton.propTypes = {
  reference: PropTypes.func.isRequired,
};

export default EngineerApprovalApproveButton;
