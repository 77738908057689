import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderVehicleFilters } from '../../../reducers/order';
import {
  OrderVehicleStatus,
  formatVehicleStatusName,
} from '../constants/order-vehicle-constants';
import { getOrderLines } from '../../../requests/order';

const OrderVehicleFilter = () => {
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.orderReducer);

  const filterStructure = [
    {
      title: 'Vehicle status',
      key: 'statusCode',
      id: 'filter-order-vehicle-statuses',
      type: 'multiselect',
      value: [],
      options: Object.keys(OrderVehicleStatus).map((status) => ({
        value: status,
        label: formatVehicleStatusName(status),
      })),
      permanent: false,
      operator: '$in',
      hideClear: true,
    },
  ];

  return (
    <div data-testid="vehicle-order-filters">
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => {
          const fill = JSON.parse(JSON.stringify(filters));
          dispatch(setOrderVehicleFilters(fill));
          if(!fill) null;
            dispatch(getOrderLines({
                orderId: orderDetails?.orderId,
                limit: 10,
                offset: 0,
                currentPage: 1,
                filters: {
                  conditions: fill,
                  operator: '$and',
                },
            }))
        }}
        clearButtonLabel="Reset All"
        showClearIcon
      />
    </div>
  );
};

export default OrderVehicleFilter;
