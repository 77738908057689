import { useMemo } from "react";

export function generateReturnRequisitionForm({
    requisitionName
} = {
    requisitionName: ""
}) {
    const defaultValues = useMemo(() => ({
        requisitionName,
        comment: '',
    }), []);

    const form = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        sections: [
            {
                fields: [
                    {
                        id: "comment",
                        type: "textarea",
                        label: "Provide reason for return",
                        required: true,
                        help: {
                            after: "500 characters allowed"
                        },
                        element: {
                            control: {
                                maxLength: 500,
                            }
                        }
                    },
                ]
            }
        ]
    }

    return [form, defaultValues];
}