import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Textbox,
  Label,
  RadioButton,
  Fieldset,
  ErrorMessage,
} from '@gsa/afp-component-library';
import {
  useStoreReportsContext,
  SearchByFilters,
  SearchByOrderNumber,
  SearchByRequisitionNumber,
} from '../useStoreReportsContext';

export const PreferredMethodFieldName = 'reportBuildOption';

const SearchMethod = () => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext();

  const {
    searchMethod,
    setSearchMethod,
    isVendor,
    isOrderReport,
    isRequisitionReport,
  } = useStoreReportsContext();

  const handleOnChange = (value) => {
    resetField('orderNumber');
    resetField('requisitionNumber');
    setSearchMethod(value);
  };

  function canSearchByOrderNumber() {
    return isOrderReport();
  }

  function canSearchByRequisitionNumber() {
    return !isVendor();
  }

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-5">
          <Fieldset name={PreferredMethodFieldName}>
            <Label required className="text-bold" htmlFor="by-filters-radio">
              How would you like to build your report?
            </Label>
            {errors?.reportBuildOption && (
              <ErrorMessage id="vehicle-ownership-error">
                {errors?.reportBuildOption.type === 'required' &&
                  'This is a required field'}
              </ErrorMessage>
            )}
            <Controller
              name={PreferredMethodFieldName}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <>
                    {isRequisitionReport() && (
                      <RadioButton
                        id="by-requisition-filters-radio"
                        data-testid="by-filters-radio-btn"
                        name={name}
                        required
                        variant="tile"
                        label="Search for requisitions using filters"
                        description="I want to report on multiple requisitions by type or date"
                        value={SearchByFilters}
                        checked={value === SearchByFilters}
                        onChange={(e) => {
                          onChange(e);
                          handleOnChange(SearchByFilters);
                        }}
                        {...(!value && { required: true })}
                      />
                    )}
                    {isOrderReport() && (
                      <RadioButton
                        id="by-order-filters-radio"
                        data-testid="by-order-filters-radio-btn"
                        name={name}
                        required
                        variant="tile"
                        label="Search for orders using filters"
                        description="I want to report on multiple orders by date or status"
                        value={SearchByFilters}
                        checked={value === SearchByFilters}
                        onChange={(e) => {
                          onChange(e);
                          handleOnChange(SearchByFilters);
                        }}
                        {...(!value && { required: true })}
                      />
                    )}
                    {canSearchByOrderNumber() && (
                      <RadioButton
                        id="by-order-number-radio"
                        data-testid="by-order-number-radio-btn"
                        name={name}
                        required
                        variant="tile"
                        label="Enter an order by number"
                        description="I want to report on a specific order"
                        value={SearchByOrderNumber}
                        checked={value === SearchByOrderNumber}
                        onChange={(e) => {
                          onChange(e);
                          handleOnChange(SearchByOrderNumber);
                        }}
                        {...(!value && { required: true })}
                      />
                    )}
                    {canSearchByRequisitionNumber() && (
                      <RadioButton
                        id="by-requisition-number-radio"
                        data-testid="by-requisition-number-radio-btn"
                        name={name}
                        required
                        variant="tile"
                        label="Enter a requisition by number"
                        description="I want to report on orders from a specific requisition"
                        value={SearchByRequisitionNumber}
                        checked={value === SearchByRequisitionNumber}
                        onChange={(e) => {
                          onChange(e);
                          handleOnChange(SearchByRequisitionNumber);
                        }}
                      />
                    )}
                  </>
                );
              }}
            />
          </Fieldset>
        </div>
      </div>
      {searchMethod === SearchByOrderNumber && (
        <>
          <div className="grid-row">
            <div className="grid-col-5">
              <header className="display-flex-column flex-align-start flex-justify  flex-direction-column">
                <h2 className="margin-0 margin-top-5">Add order</h2>
                <p className="margin-0">Enter the order number below.</p>
              </header>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-5">
              <Controller
                name="orderNumber"
                control={control}
                defaultValue=""
                rules={{ required: 'This is a required field' }}
                render={({ field: { name, onBlur, onChange, value } }) => {
                  return (
                    <div
                      className={
                        errors?.orderNumber ? 'usa-form-group--error' : ''
                      }
                    >
                      <Label required htmlFor="orderNumber">
                        Order number
                      </Label>
                      {errors?.orderNumber && (
                        <ErrorMessage id="order-number-error">
                          {errors?.orderNumber?.message}
                        </ErrorMessage>
                      )}
                      <Textbox
                        className={
                          errors?.orderNumber
                            ? 'outline-05 outline-secondary-dark'
                            : ''
                        }
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        name={name}
                        id="orderNumber"
                        data-testid="order-number"
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
      {searchMethod === SearchByRequisitionNumber && (
        <>
          <div className="grid-row">
            <div className="grid-col-5">
              <header className="display-flex-column flex-align-start flex-justify flex-direction-column">
                <h2 className="margin-0 margin-top-5">Add requisition</h2>
                <p className="margin-0">Enter the requisition number below.</p>
              </header>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-5">
              <Controller
                name="requisitionNumber"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { name, onBlur, onChange, value } }) => {
                  return (
                    <div
                      className={
                        errors?.requisitionNumber ? 'usa-form-group--error' : ''
                      }
                    >
                      <Label required htmlFor="requisitionNumber">
                        Requisition number
                      </Label>
                      {errors?.requisitionNumber && (
                        <ErrorMessage id="requisition-number-error">
                          {errors?.requisitionNumber?.type === 'required' &&
                            'This is a required field'}
                          {errors?.requisitionNumber?.type === 'not_found' &&
                            errors?.requisitionNumber?.message}
                        </ErrorMessage>
                      )}
                      <Textbox
                        className={
                          errors?.requisitionNumber
                            ? 'outline-05 outline-secondary-dark'
                            : ''
                        }
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        name={name}
                        id="requisitionNumber"
                        data-testid="requisition-number"
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchMethod;
