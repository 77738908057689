import React from 'react';
import PropTypes from 'prop-types';
import { STEPS } from '../constants';
import { useStoreReportsContext } from '../useStoreReportsContext';
import ReportsSelection from '../widgets/ReportsSelection';
import SearchMethod from '../widgets/SearchMethod';
import PageNavigation from '../helper/page-navigation';
import ReportFilters from '../widgets/ReportFilters';
import OverlaySpinner from '../widgets/overlay-spinner';

const SelectWidget = ({ step }) => {
  switch (step) {
    case STEPS.REPORT_SELECTION:
      return (
        <div className="margin-y-7">
          <ReportsSelection />
        </div>
      );
    case STEPS.SEARCH_METHOD:
      return (
        <div className="margin-y-7">
          <SearchMethod />
        </div>
      );
    case STEPS.REPORT_FILTERS:
      return (
        <div className="margin-y-7">
          <ReportFilters />
        </div>
      );
    default:
      return null;
  }
};

SelectWidget.propTypes = {
  step: PropTypes.string.isRequired,
};

const StepDetailsForm = () => {
  const {
    storeReportStepCurrent,
    generatingReport,
    emailingReport,
    checkingRequisitionNumber,
    checkingOrderNumber,
  } = useStoreReportsContext();
  const step = storeReportStepCurrent.key;

  function loading() {
    return (
      generatingReport ||
      checkingRequisitionNumber ||
      checkingOrderNumber ||
      emailingReport
    );
  }

  return (
    <>
      <SelectWidget step={step} />
      <PageNavigation />
      {loading() && <OverlaySpinner />}
    </>
  );
};

export default StepDetailsForm;
