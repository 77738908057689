import React from 'react';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../../services/data-layer';
import { pointOfContactShema } from '../DeliveryAddress/address-component/point-of-contact-schema';
import { generateAgencyContactForm } from './agency-contact-form';
import useBulkOrderModsState from '../../useBulkOrderModsState';
import TelephoneControl from '../../../../components/FormComponents/telephone-input';
import generatePointOfContactChangeHandler from './agency-contact-change';

export default function AgencyContactForm() {
  const { setOrderChanges, orderChanges, formGeneratorRef } =
    useBulkOrderModsState();

  const countryCodes = useQuery(GET_CATALOGS_FOR_CATEGORY, {
    variables: { category: 'CountryCallingCode' },
  });

  const [content, defaultValues] = generateAgencyContactForm({
    agencyContact: orderChanges.agencyContact,
    countryCodes: (countryCodes.data?.getCatalogsForCategory || [])
      .map(({ description, code }) => ({
        label: description,
        value: `${code}:${description}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  });

  const handleChange = generatePointOfContactChangeHandler({
    setOrderChanges,
    formGeneratorRef: formGeneratorRef.agencyContact,
  });

  if (countryCodes.loading) {
    return <Spinner size="large" />;
  }

  return (
    <FormGenerator
      ref={formGeneratorRef.agencyContact}
      schema={pointOfContactShema}
      content={content}
      defaultValues={defaultValues}
      useFormElement={false}
      onChange={handleChange}
      controls={{
        phone: TelephoneControl,
      }}
    />
  );
}
