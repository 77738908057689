import PropTypes from 'prop-types';
import React from 'react';
import { Accordion } from '@gsa/afp-component-library';

const ChangeSetOrders = ({ selectedOrders }) => {
  const getOrderContent = () => {
    console.log('selectedOrders', selectedOrders);
    const renderOrders = () => {
      return selectedOrders.map((item) => (
        <div className="change-set-order-list-item">
          {item?.order?.caseNumber}
        </div>
      ));
    };
    return [
      {
        id: 'selectedOrders',
        title: (
          <div className="add-options-accordion-title">
            {' '}
            {selectedOrders?.length} selected orders
          </div>
        ),
        content: (
          <div className="order-details-acc-body">
            <div className="change-set-order-list">{renderOrders()}</div>
          </div>
        ),
        expanded: true,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getOrderContent()}
        className="add-options-accordion"
      />
    </>
  );
};

ChangeSetOrders.propTypes = {
  selectedOrders: PropTypes.arrayOf(Object).isRequired,
};
export default ChangeSetOrders;
