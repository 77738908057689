/* eslint-disable react/jsx-curly-newline */
import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { Alert } from '@gsa/afp-component-library';
import ErrorContext from '../../context/ErrorContext/ErrorContext';

const getErrorkKey = (form, key) => {
  if (key) return key;
  if (form) return form;
  return null;
};

/**
 *
 * overall: is this a generic alert for the entire page? (if so, a generic message could apply)
 * pageScope: array of pages to look for errors in to determine whether or not to show the `overall` alert | NOTE: this is only for the `overall` alert
 * page: the page to look for errors in
 * form: the form to look for errors in (within the page) NOTE: this is interchangeable with `key` (ideally useful for form errors)
 * errorKey: the key to look for errors in (within the page) NOTE: this is interchangeable with `form` (ideally useful for API errors)
 * heading: custom alert heading for the `overall` alert
 * content: custom alert content for the `overall` alert
 * ...alertOptions: additional options to pass to the `Alert` component
 */
const PageAlerts = ({
  overall,
  pageScope,
  page,
  form,
  errorKey,
  heading,
  content,
  ...alertOptions
}) => {
  let errContent = content;
  let errHeading = heading;
  const { state: errorState } = useContext(ErrorContext);

  if (overall) {
    let overallScope = [];
    if (page) overallScope = [page];
    else if (pageScope) overallScope = pageScope;

    let hasErrors = false;
    if (Object.keys(errorState).length > 0 && overallScope.length > 0) {
      overallScope.forEach((scopedPage) => {
        if (scopedPage && errorState[scopedPage]) {
          // if there are pages in the error state
          if (Object.keys(errorState[scopedPage]).length > 0) {
            const pageErrorKey = getErrorkKey(form, errorKey);
            // if there are any errors in the specified form
            if (
              errorState[scopedPage][pageErrorKey] &&
              errorState[scopedPage][pageErrorKey].size > 0
            ) {
              hasErrors = true;
            } else {
              // loop through all of the forms in the page to find any errors at all
              Object.keys(errorState[scopedPage]).forEach((pageForm) => {
                if (errorState[scopedPage][pageForm].size > 0) {
                  const [err] = Array.from(errorState[scopedPage][pageForm]);
                  if (pageForm === 'NO_COLORS')
                    errHeading =
                      'There are no colors available for this vehicle';
                  if (!_.isEmpty(err)) {
                    if (typeof err === 'string') {
                      const [msg] = err.split('#');
                      errContent = msg;
                    } else {
                      errContent = err;
                    }
                  }
                  hasErrors = true;
                }
              });
            }
          }
        }
      });
    }

    return (
      (hasErrors && (
        <Alert
          type="error"
          heading={errHeading || 'This page contains errors'}
          {...alertOptions}
        >
          {errContent ||
            'Please address all fields outlined in red before proceeding to the next step.'}
        </Alert>
      )) ||
      null
    );
  }

  const pageErrorKey = getErrorkKey(form, errorKey);
  const showAlert =
    errorState[page] &&
    errorState[page][pageErrorKey] &&
    errorState[page][pageErrorKey].size > 0;
  let errorMsg = '';

  if (showAlert) {
    const [err] = Array.from(errorState[page][pageErrorKey]);
    const [msg] = err.split('#');
    errorMsg = msg;
  }

  return (
    (showAlert && (
      <>
        <Alert slim type="error" key={v4()} {...alertOptions}>
          {`This section has ${errorState[page][pageErrorKey].size} error, `}
          {errorMsg}
        </Alert>
      </>
    )) ||
    null
  );
};

PageAlerts.propTypes = {
  overall: PropTypes.bool,
  page: PropTypes.string,
  form: PropTypes.string,
  pageScope: PropTypes.objectOf(Object),
  errorKey: PropTypes.string,
  heading: PropTypes.objectOf(Object),
  content: PropTypes.objectOf(Object),
};

PageAlerts.defaultProps = {
  overall: false,
  page: '',
  form: '',
  pageScope: null,
  errorKey: '',
  heading: null,
  content: null,
};

export default PageAlerts;
