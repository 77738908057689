import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { Label, SelectDropdown } from '@gsa/afp-component-library';

import {
  GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE_CITY,
  GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE,
  GET_STATES,
} from '../../../services/data-layer';

import './DealershipDeliverySelector.scss';

const DealershipDeliverySelector = ({
  make,
  isEV,
  handleDealershipDeliveryAddressChange,
  deliveryDealership,
  section,
}) => {
  const [optionDealerData, setOptionDealerData] = useState([]);
  const [optionCityData, setOptionCityData] = useState([]);
  const [optionStateData, setOptionStateData] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [dealerData, setDealerData] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedDealerId, setSelectedDealerId] = useState();
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedStateCode, setSelectedStateCode] = useState(null);

  const [getStates, { data: getStatesData }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'no-cache',
  });

  const [getDealershipCitiesByState] = useLazyQuery(
    GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getDealershipsByCityStateMake] = useLazyQuery(
    GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE_CITY,
  );

  const OCONUSStateCodes = [
    'AA',
    'AE',
    'AP',
    'FM',
    'GE',
    'IT',
    'MH',
    'NU',
    'PW',
    'AK',
    'AS',
    'GU',
    'HI',
    'MP',
    'PR',
    'UM',
    'VI',
  ];

  const getDealershipCities = async (stateId) => {
    await getDealershipCitiesByState({
      variables: {
        stateId: parseInt(stateId, 10),
        make: parseInt(make, 10),
        // eslint-disable-next-line
        acceptsEv: isEV ? isEV : undefined,
      },
      onCompleted: (data) => {
        const cities = data?.getDeliveryDealerships?.reduce(
          (uniqueCities, dealer) => {
            if (!uniqueCities.has(dealer.city)) {
              uniqueCities.add(dealer.city);
            }
            return uniqueCities;
          },
          new Set(),
        );
        const citiesData = Array.from(cities).map((city) => ({
          label: city,
          value: city,
        }));
        citiesData.unshift({
          label: 'Select City',
          value: '',
          defaultValue: true,
        });
        setOptionCityData(citiesData);
        setOptionDealerData([
          {
            label: 'Select Dealership ',
            value: '',
            defaultValue: true,
          },
        ]);
      },
    });
  };
  const loadDealershipAndStateData = async () => {
    await getStates({
      onCompleted: (data) => {
        const statesList = data?.getStates?.filter(
          (state) =>
            !OCONUSStateCodes.includes(state.stateCode) &&
            state.isoCountryCode2 === 'US',
        );
        const states = statesList.map((state) => ({
          label: state.stateName,
          value: state.id,
        }));
        states.unshift({
          label: 'Select State',
          value: '',
        });
        setOptionStateData(states);

        const defaultState = data?.getStates?.find((state) =>
          deliveryDealership?.stateId
            ? parseInt(state?.id, 10) ===
              parseInt(deliveryDealership?.stateId, 10)
            : state.stateName === sessionStorage.getItem('shipmentLocation'),
        );
        const defaultStateId = defaultState ? parseInt(defaultState.id, 10) : 0;
        setSelectedStateId(defaultStateId);
        setSelectedStateCode(defaultState.stateCode);
        getDealershipCities(defaultStateId);
      },
    });
  };

  useEffect(() => {
    (() => {
      loadDealershipAndStateData();
    })();
  }, [make]); // run this on component load

  const getDealerData = async () => {
    await getDealershipsByCityStateMake({
      fetchPolicy: 'no-cache',
      variables: {
        state: parseInt(selectedStateId, 10),
        city: selectedCity,
        make: parseInt(make, 10),
        // eslint-disable-next-line
        acceptsEv: isEV ? isEV : undefined,
      },
      onCompleted: (data) => {
        const optionData = data?.getDeliveryDealerships.map((dealer) => {
          return { label: dealer.dealerName, value: dealer.id };
        });
        setDealerData(data?.getDeliveryDealerships);
        optionData.unshift({
          label: 'Select Dealership ',
          value: '',
          defaultValue: true,
        });
        setOptionDealerData(optionData);
      },
    });
  };

  useEffect(() => {
    if (selectedCity && selectedStateId) {
      getDealerData();
    }
  }, [selectedCity, selectedStateId]);

  useEffect(() => {
    if (dealerData && selectedDealerId) {
      const dealer = dealerData.find((x) => {
        return x.id === parseInt(selectedDealerId, 10);
      });

      if (dealer) {
        setSelectedDealer(dealer);
      }
    }
  }, [dealerData]);

  useEffect(() => {
    if (deliveryDealership?.dealershipId) {
      setSelectedStateId(deliveryDealership?.stateId);
      setSelectedStateCode(deliveryDealership?.state?.stateCode);
      setSelectedCity(deliveryDealership?.city);
      setSelectedDealerId(deliveryDealership?.dealershipId);
    }
  }, [deliveryDealership]); // run this on component load

  const handleCitySelectDealership = async (e) => {
    setSelectedCity(e);
    setSelectedDealer(null);
    if (e && e !== '') {
      await getDealershipsByCityStateMake({
        fetchPolicy: 'no-cache',
        variables: {
          state: parseInt(selectedStateId, 10),
          city: e,
          make: parseInt(make, 10),
          // eslint-disable-next-line
          acceptsEv: isEV ? isEV : undefined,
        },
        onCompleted: (data) => {
          const optionData = data?.getDeliveryDealerships.map((dealer) => {
            return { label: dealer.dealerName, value: dealer.id };
          });
          setDealerData(data?.getDeliveryDealerships);
          optionData.unshift({
            label: 'Select Dealership ',
            value: '',
            defaultValue: true,
          });
          setOptionDealerData(optionData);
        },
      });
    } else {
      setOptionDealerData([
        {
          label: 'Select Dealership ',
          value: '',
          defaultValue: true,
        },
      ]);
    }
  };

  const handleStateSelectDealership = async (stateId) => {
    setSelectedStateId(stateId);
    setSelectedCity(null);
    setSelectedDealer(null);
    if (stateId && stateId !== '') {
      getDealershipCities(stateId);
      setOptionDealerData([
        {
          label: 'Select Dealership ',
          value: '',
          defaultValue: true,
        },
      ]);
    }
  };

  const getStateAbbrev = (stateId) => {
    return getStatesData?.getStates
      ? getStatesData.getStates.find(
          (x) => parseInt(x.id, 10) === parseInt(stateId, 10),
        )?.stateCode
      : '';
  };

  const handleSelectDealership = (dealershipId) => {
    setSelectedDealerId(dealershipId);
    const dealer = dealerData.find((x) => {
      return x.id === parseInt(dealershipId, 10);
    });
    handleDealershipDeliveryAddressChange(dealer, section);
    setSelectedDealer(dealer);
  };

  return (
    <div className="dealership-delivery-selector">
      <div className="display-flex flex-align-end">
        {!OCONUSStateCodes.includes(selectedStateCode) && (
          <>
            <Label
              htmlFor="dealership_delivery_state_select"
              className="address-label"
            >
              State
            </Label>
            <div>
              <SelectDropdown
                data-testid="select-dealership-delivery-state"
                name="dealership_delivery_state_select"
                value={selectedStateId}
                onChange={(e) => {
                  handleStateSelectDealership(e.target.value);
                }}
                options={optionStateData}
              />
            </div>
          </>
        )}
        <Label
          htmlFor="dealership_delivery_city_select"
          className="address-label"
        >
          City
        </Label>
        <div>
          <SelectDropdown
            data-testid="select-dealership-delivery-city"
            name="dealership_delivery_city_select"
            value={selectedCity}
            onChange={(e) => {
              handleCitySelectDealership(e.target.value);
            }}
            options={optionCityData}
          />
        </div>
        <Label htmlFor="dealership_delivery_select" className="address-label">
          Dealership
        </Label>
        <div>
          <SelectDropdown
            data-testid="select-dealership-delivery"
            name="dealership_delivery_select"
            value={selectedDealerId}
            onChange={(e) => {
              handleSelectDealership(e.target.value);
            }}
            options={optionDealerData}
          />
        </div>

        {selectedDealer && (
          <div className="selected-dealership">
            <div>
              <strong>{selectedDealer?.dealerName}</strong>
            </div>
            <div>{selectedDealer?.address1}</div>
            {selectedDealer?.address2 && <div>{selectedDealer?.address2}</div>}
            <div>
              {selectedDealer?.city}, {getStateAbbrev(selectedDealer?.stateId)},{' '}
              {selectedDealer?.postalCode}
            </div>
            <div>
              <strong>Phone:</strong> {selectedDealer.phone}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DealershipDeliverySelector.propTypes = {
  make: PropTypes.number.isRequired,
  deliveryDealership: PropTypes.instanceOf(Object),
  handleDealershipDeliveryAddressChange: () => {},
  section: PropTypes.instanceOf(Object),
  isEV: PropTypes.bool,
};

DealershipDeliverySelector.defaultProps = {
  handleDealershipDeliveryAddressChange: () => {},
  deliveryDealership: {},
  section: {},
  isEV: false,
};

export default DealershipDeliverySelector;
