import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Icon, Button } from '@gsa/afp-component-library';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import './InfoTooltip.scss';

const InfoTooltip = ({ text, title, type, position, open, onHover, label }) => {
  let positionClass = {
    top: 'c-tooltip-top',
    right: 'c-tooltip-right',
    bottom: 'c-tooltip-bottom',
    left: 'c-tooltip-left',
  }[position];

  const vehicleReqContext = useContext(VehicleRequisitionContext);
  let state = {
    isReqNumberModalOpen: false,
  };
  let dispatch = () => {};

  if (vehicleReqContext) {
    state = vehicleReqContext.state;
    dispatch = vehicleReqContext.dispatch;
  }
  const { isReqNumberModalOpen } = state;

  if (!positionClass) positionClass = 'c-tooltip-top';

  return (
    <span
      className={`c-tooltip ${onHover ? 'c-tooltip-hoverable' : ''} ${
        open ? 'c-tooltip-open' : ''
      }`}
    >
      {type === 'default' && (
        <span className="c-info-icon">
          <Icon
            iconName="info"
            role="img"
            aria-label={text}
            className="usa-icon--size-1"
          />
        </span>
      )}
      {type === 'tag' && (
        <a
          href="/"
          data-testid="type-tag-link"
          onClick={(e) => e.preventDefault()}
          aria-label={label}
          className="c-info-icon"
        >
          <Icon
            iconName="info"
            role="img"
            aria-label={text}
            className="usa-icon--size-1"
          />
        </a>
      )}
      <div
        className={`c-tooltip-info ${positionClass}`}
        data-testid="tooltipInfoDiv"
      >
        {title !== '' && (
          <div className="c-tooltip-info-title">
            <span className="c-tooltip-info-icon">
              <Icon iconName="info" className="usa-icon--size-1" />
            </span>
            <span data-testid="tooltipTitleSpan">{title}</span>
          </div>
        )}
        <div className="c-tooltip-info-text">
          <span data-testid="tooltipTextSpan"> {text} </span>
        </div>
        {title === 'Requisition number' && (
          <Button
            variant="unstyled"
            label="View format details"
            data-testid="view-format-details"
            onClick={() => {
              dispatch({
                type: VehicleRequisitionContextActions.SET_REQ_NUMBER_VALIDATIONS_MODAL_OPEN,
                payload: !isReqNumberModalOpen,
              });
            }}
          />
        )}
      </div>
    </span>
  );
};

export default InfoTooltip;

InfoTooltip.propTypes = {
  position: PropTypes.string,
  open: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onHover: PropTypes.bool,
  label: PropTypes.string,
};

InfoTooltip.defaultProps = {
  position: 'top',
  open: false,
  text: '',
  title: '',
  type: 'default',
  onHover: true,
  label: '',
};
