import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import { emDashUnicode } from '../../../../../constants/constants';
import { feedback } from '../../../utils/VehicleRequisitionUtils';
import validateBOAC from './validate-boac';
import validateFSS19 from './validate-fss19';

export default async function handleCorL({
  validateBoacInWalletForAddressCode,
  getBoacForSignalCodeCOrL,
  agencyInfoFormValues,
  agencyCode,
  bureauCode,
  setValue,
  setError,
  clearErrors,
  isDoD,
  changedFieldId,
}) {
  const errors = {};
  const previousTreasuryAccountSymbol =
    changedFieldId === 'signalCode'
      ? emDashUnicode
      : agencyInfoFormValues?.treasuryAccountSymbol;
  let treasuryAccountSymbol =
    changedFieldId === 'signalCode'
      ? emDashUnicode
      : agencyInfoFormValues?.treasuryAccountSymbol;

  const signalCode = agencyInfoFormValues?.signalCode;
  let supplementaryAddressCode =
    changedFieldId === 'signalCode'
      ? ''
      : agencyInfoFormValues?.supplementaryAddressCode;

  if (
    changedFieldId === null ||
    ['serviceCode', 'fundCode', 'signalCode'].includes(changedFieldId)
  ) {
    let formattedAgencyCode = agencyCode;
    if (Number.isNaN(agencyCode)) {
      formattedAgencyCode = agencyCode;
    }

    formattedAgencyCode = formattedAgencyCode.toString().padStart(2, '0');

    if (
      agencyInfoFormValues?.fundCode.length > 0 &&
      agencyInfoFormValues?.serviceCode.length > 0
    ) {
      const fss19 = await validateFSS19({
        getBoacForSignalCodeCOrL,
        fundCode: agencyInfoFormValues?.fundCode,
        serviceCode: agencyInfoFormValues?.serviceCode,
        isDoD: true, // always use service code + fund code combination for FSS19
        agencyCode: formattedAgencyCode,
        force: changedFieldId === null,
      });

      if (fss19 !== undefined) {
        if (fss19.error) {
          errors.supplementaryAddressCode = {
            message: feedback.signalSupplementaryAddress[3],
          };
          supplementaryAddressCode = '';
        } else if (!fss19.boac) {
          errors.supplementaryAddressCode = {
            message: isDoD
              ? feedback.signalSupplementaryAddress[4]
              : feedback.signalSupplementaryAddress[7],
          };
          supplementaryAddressCode = '';
        } else {
          supplementaryAddressCode = fss19.boac;
          clearErrors('supplementaryAddressCode');
        }
      } else {
        if (
          changedFieldId === null ||
          (agencyInfoFormValues?.fundCode.length > 0 &&
            agencyInfoFormValues?.serviceCode.length > 0)
        ) {
          errors.supplementaryAddressCode = {
            message: isDoD
              ? feedback.signalSupplementaryAddress[4]
              : feedback.signalSupplementaryAddress[7],
          };
        }
        supplementaryAddressCode = '';
      }
    } else if (changedFieldId === null) {
      errors.supplementaryAddressCode = {
        message: isDoD
          ? feedback.signalSupplementaryAddress[4]
          : feedback.signalSupplementaryAddress[7],
      };
    }
  }

  if (
    supplementaryAddressCode !== agencyInfoFormValues?.supplementaryAddressCode
  ) {
    setValue('supplementaryAddressCode', supplementaryAddressCode);
  }

  if (supplementaryAddressCode.length > 0) {
    // undefined = OK, but not valid | null = ERROR
    const suppAddressBoac = await validateBOAC({
      endpoint: validateBoacInWalletForAddressCode,
      boac: supplementaryAddressCode,
      signalCode,
      agencyCode,
      bureauCode,
      force: changedFieldId === null,
    });

    if (suppAddressBoac !== undefined && suppAddressBoac !== null) {
      if (suppAddressBoac.tas && !suppAddressBoac.tas.includes('null')) {
        treasuryAccountSymbol = suppAddressBoac.tas;
      } else {
        treasuryAccountSymbol = emDashUnicode;
      }
    } else {
      treasuryAccountSymbol = emDashUnicode;
    }

    setValue('treasuryAccountSymbol', treasuryAccountSymbol);

    if (suppAddressBoac === undefined) {
      treasuryAccountSymbol = emDashUnicode;
      if (supplementaryAddressCode.length !== 6) {
        errors.supplementaryAddressCode = {
          message: feedback.signalSupplementaryAddress[1],
        };
      }
    } else if (suppAddressBoac === null) {
      errors.supplementaryAddressCode = {
        message: feedback.signalSupplementaryAddress[1],
      };
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!suppAddressBoac.tiedToAgency) {
        errors.supplementaryAddressCode = {
          message: feedback.signalSupplementaryAddress[2],
        };
      } else if (!suppAddressBoac.isValid) {
        errors.supplementaryAddressCode = {
          message: feedback.signalSupplementaryAddress[11],
        };
      } else if (!suppAddressBoac.tas || suppAddressBoac.tas.includes('null')) {
        errors.treasuryAccountSymbol = {
          message: feedback.tas[0],
        };
      } else {
        clearErrors('supplementaryAddressCode');
        clearErrors('treasuryAccountSymbol');
      }
    }

    if (previousTreasuryAccountSymbol !== treasuryAccountSymbol) {
      setValue('treasuryAccountSymbol', treasuryAccountSymbol);
    }
  } else {
    setValue('treasuryAccountSymbol', emDashUnicode);
    clearErrors('supplementaryAddressCode');
    clearErrors('treasuryAccountSymbol');
  }

  if (Object.keys(errors).length > 0) {
    applyFormErrorsRecursively(
      'requisition.boac',
      errors,
      setError,
      clearErrors,
    );
    applyFormErrorsRecursively(
      'supplementaryAddressCode',
      errors,
      setError,
      clearErrors,
    );
    applyFormErrorsRecursively(
      'treasuryAccountSymbol',
      errors,
      setError,
      clearErrors,
    );
  }

  return errors;
}
