import React, { useMemo } from 'react';
import { Button } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../../constants/constants';
import { generateSignalCodeField } from './signal-code-form';
import { getJulian } from '../../../../utilities/commonUtils';

function getPhoneNumber(data, keyPrefix) {
  if (!data) return { countryCode: '', number: '', extension: '' };

  return {
    countryCode: data[`${keyPrefix}CountryCallingCode`] || '',
    number: data[`${keyPrefix}Number`] || '',
    extension: data[`${keyPrefix}Extension`] || '',
  };
}

function generateAreqFields() {
  return {
    fields: [
      {
        id: 'additionalAreqFunds',
        label: 'Additional funds per unit for AREQs',
        tooltip: 'Additional funds per unit for AREQs',
        type: 'currency',
      },
    ],
  };
}

function generateDoDForm() {
  return {
    sections: [
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            label: 'Transportation control number',
            id: 'dod.transportationControlNumber',
            type: 'text',
            element: {
              control: {
                maxLength: 30,
              },
            },
          },
          {
            label: 'Mark for information',
            id: 'dod.markForInformation',
            type: 'text',
            element: {
              control: {
                maxLength: 30,
              },
            },
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            id: 'dod.nationalStockNumber',
            label: 'National stock number',
            type: 'text',
            element: {
              control: {
                maxLength: 16,
              },
            },
          },
          {
            id: 'dod.registrationNumber',
            label: 'Registration number',
            type: 'text',
            element: {
              control: {
                maxLength: 20,
              },
            },
          },
        ],
      },
      {
        fields: [
          {
            id: 'dod.accountingClassificationReferenceNumber',
            label: 'Accounting classification reference number',
            type: 'text',
            columnWidth: 'col-8-12',
            element: {
              control: {
                maxLength: 2,
              },
            },
          },
        ],
      },
    ],
  };
}

export function generateAgencyInformationFormContent(
  {
    handlePageNavigation,
    isAreq,
    isDoD,
    countryCodes,
    agencyInformation,
    costBreakdown,
    treasuryAccountSymbol,
    openModal,
    currentUserCreatedRequisition,
  } = {
    handlePageNavigation: () => {},
    isAreq: false,
    isDoD: false,
    reqType: 'STANDARD',
    countryCodes: [],
    agencyInformation: {},
    costBreakdown: {},
    treasuryAccountSymbol: emDashUnicode,
    openModal: () => {},
    currentUserCreatedRequisition: false,
  },
) {
  const defaultValues = useMemo(
    () => ({
      agency: agencyInformation?.agency || '',
      bureau: agencyInformation?.bureau || '',
      office: agencyInformation?.office || '',
      receivingAgency: agencyInformation?.receivingAgency,
      receivingBureau: agencyInformation?.receivingBureau,
      receivingOffice: agencyInformation?.receivingOffice,
      groupAssignment: '',

      agencyOrderNumber: agencyInformation?.agencyOrderNumber || '',
      requisition: {
        boac: agencyInformation?.requisitionBOAC || '',
        julian: agencyInformation?.requisitionJulian || getJulian(),
        serialNumber: agencyInformation?.requisitionSerialNumber || '',
      },

      signalCode: agencyInformation?.signalCode || '-1',
      serviceCode: agencyInformation?.serviceCode || '',
      fundCode: agencyInformation?.fundCode || '00',
      supplementaryAddressCode:
        agencyInformation?.signalSupplementaryAddress || '',
      treasuryAccountSymbol:
        treasuryAccountSymbol ||
        agencyInformation?.treasuryAccountSymbol ||
        emDashUnicode,

      additionalAreqFunds: agencyInformation.additionalAreqFunds || 0,
      finAdditionalAreqFunds: agencyInformation.additionalAreqFunds || 0,

      agencyFinancialData: agencyInformation?.agencyFinancialData || '',

      contact: {
        firstName: agencyInformation?.firstName || '',
        lastName: agencyInformation?.lastName || '',
        email: agencyInformation?.email || '',
        phone: getPhoneNumber(agencyInformation, 'phone'),
        fax: getPhoneNumber(agencyInformation, 'fax'),
      },

      unitPrice:
        agencyInformation.unitPrice || costBreakdown?.baseAndOptionsPrice || 0,
      unitPriceWithAreqs:
        agencyInformation.unitPriceWithAreqs ||
        (agencyInformation.unitPriceWithAreqs ||
          costBreakdown?.baseAndOptionsPrice ||
          0) + (agencyInformation.additionalAreqFunds || 0),

      dod: {
        transportationControlNumber:
          agencyInformation?.transportationControlNumber || '',
        markForInformation: agencyInformation?.markForInformation || '',
        nationalStockNumber: agencyInformation?.nationalStockNumber || '',
        registrationNumber: agencyInformation?.registrationNumber || '',
        accountingClassificationReferenceNumber:
          agencyInformation?.accountingClassificationReferenceNumber || '',
      },
    }),
    [],
  );

  const content = {
    buttonControls: currentUserCreatedRequisition
      ? {
          submit: {
            label: 'Next',
            rightIcon: { name: 'arrow_forward' },
          },
          cancel: {
            label: 'Previous',
            leftIcon: { name: 'arrow_back' },
            onClick: () => {
              handlePageNavigation('previous');
            },
          },
        }
      : {
          submit: false,
          cancel: false,
        },
    sections: [
      {
        fieldLayout: 'horizontal',
        header:
          agencyInformation?.receivingAgency && 'Agency Receiving Vehicles',
        tooltip: agencyInformation?.receivingAgency && {
          header:
            'The Agency Receiving Vehicles is the agency who will be taking delivery of and operating the vehicle. In other words, who am I ordering this vehicle for?',
        },
        sections: agencyInformation?.receivingAgency && [
          {
            fieldLayout: 'horizontal',
            className: 'agency-receiving-vehicles',
            fields: [
              {
                id: 'receivingAgency',
                label: 'Agency',
                type: 'readonly',
              },
              {
                id: 'receivingBureau',
                label: 'Bureau',
                type: 'readonly',
              },
              {
                id: 'receivingOffice',
                label: 'Office/Group',
                type: 'readonly',
              },
              {
                component: () => {
                  return (
                    <Button
                      label="Change this information"
                      data-testid="attachment-add-cancel-button"
                      type="button"
                      variant="unstyled"
                      onClick={() => {
                        openModal();
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      {
        header: 'Ordering agency',
        sections: [
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'agency',
                label: 'Agency',
                type: 'readonly',
              },
              {
                id: 'bureau',
                label: 'Bureau',
                type: 'readonly',
              },
              {
                id: 'office',
                label: 'Office/Group',
                type: 'readonly',
              },
              {
                component: () => {
                  return (
                    <Button
                      label="Change this information"
                      data-testid="attachment-add-cancel-button"
                      type="button"
                      variant="unstyled"
                      onClick={() => {
                        openModal();
                      }}
                    />
                  );
                },
              },
            ],
          },
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'agencyOrderNumber',
                label: 'Agency order number',
                type: 'text',
                required: true,
                columnWidth: 'col-5-12',
                tooltip: `This is the number used internally by your agency to identify this requisition.
                                        The agency order number is 15 characters and can be a combination of alphanumeric characters.
                                        No special characters are accepted except for hyphens and underscore.
                                        Some agencies fill in their purchase order number.`,
                element: {
                  control: {
                    maxLength: 15,
                  },
                },
              },
              {
                label: 'Requisition number',
                id: 'requisition',
                required: true,
                tooltip:
                  'AAC, DoDAAC, or BOAC, Julian date, and 4 digit serial no.',
                columnWidth: 'col-4-12',
                columnOffset: 'col-offset-1-12',
                fieldLayout: 'horizontal',
                fields: [
                  {
                    id: 'requisition.boac',
                    type: 'text',
                    ariaLabel: 'requisition boac number',
                    suppressErrors: {
                      control: true,
                      field: true,
                      label: true,
                      message: true,
                    },
                    element: {
                      control: {
                        maxLength: 6,
                      },
                    },
                  },
                  {
                    id: 'requisition.julian',
                    type: 'text',
                    ariaLabel: 'requisition julian number',
                    suppressErrors: {
                      control: true,
                      field: true,
                      label: true,
                      message: true,
                    },
                    element: {
                      control: {
                        maxLength: 4,
                      },
                    },
                  },
                  {
                    id: 'requisition.serialNumber',
                    type: 'text',
                    ariaLabel: 'requisition serial number',
                    suppressErrors: {
                      control: true,
                      field: true,
                      label: true,
                      message: true,
                    },
                    element: {
                      control: {
                        maxLength: 4,
                        registerOptions: {
                          setValueAs: (value) => value.toUpperCase(),
                          onBlur: (event) => {
                            // eslint-disable-next-line
                            event.target.value =
                              event.target.value.toUpperCase();
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          isDoD ? generateDoDForm() : null,
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'signalCode',
                label: 'Signal code',
                tooltip:
                  'This one position code directs the billing to the AAC/DoDAAC listed in either the Requisition Number, Supplementary Address or Fund Code.',
                ariaLabel: 'signal code',
                required: true,
                columnWidth: 'col-3-12',
                type: 'select',
                options: [
                  { value: '-1', label: '-select-' },
                  { value: 'A', label: 'A' },
                  { value: 'B', label: 'B' },
                  { value: 'C', label: 'C' },
                  { value: 'J', label: 'J' },
                  { value: 'K', label: 'K' },
                  { value: 'L', label: 'L' },
                ],
              },
              {
                id: 'treasuryAccountSymbol',
                label: 'Treasury account symbol',
                type: 'text',
                columnWidth: 'col-5-12',
                columnOffset: 'col-offset-3-12',
                disabled: true,
              },
            ],
          },
          generateSignalCodeField('A', isDoD),
          generateSignalCodeField('B', isDoD),
          generateSignalCodeField('C', isDoD),
          generateSignalCodeField('J', isDoD),
          generateSignalCodeField('K', isDoD),
          generateSignalCodeField('L', isDoD),
          isAreq ? generateAreqFields() : null,
        ],
      },
      {
        header: 'Agency point of contact',
        id: 'contact',
        sections: [
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'contact.firstName',
                label: 'First name',
                type: 'text',
                required: true,
              },
              {
                id: 'contact.lastName',
                label: 'Last name',
                type: 'text',
                required: true,
              },
            ],
          },
          {
            fields: [
              {
                id: 'contact.email',
                label: 'Email',
                type: 'text',
                required: true,
              },
            ],
          },
          {
            fields: [
              {
                id: 'contact.phone',
                type: 'phone',
                required: true,
                suppressErrors: {
                  field: true,
                  message: true,
                },
                element: {
                  control: {
                    countryCodes,
                  },
                },
              },
              {
                id: 'contact.fax',
                type: 'phone',
                suppressErrors: {
                  field: true,
                  message: true,
                },
                element: {
                  control: {
                    countryCodes,
                    type: 'fax',
                  },
                },
              },
            ],
          },
          {
            subheader: (
              <>
                <strong>Agency financial data</strong>
                <br />
                <br />
                Provide internal agency financial information here. Information
                will be passed to GSA Finance but will not be passed to GSA
                Automotive.
              </>
            ),
            fields: [
              {
                id: 'agencyFinancialData',
                type: 'text',
                ariaLabel: 'agency financial data',
              },
            ],
          },
        ],
      },
    ],
  };

  return [content, defaultValues];
}
