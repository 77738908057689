import React from 'react';
import PropTypes from 'prop-types';
import { VehicleCard } from '../VehicleCard/VehicleCard';
import QuantityInput from '../QuantityInput/QuantityInput';
import './VehiclesHeader.scss';

const VehiclesHeader = ({
  quantity,
  setQuantity,
  setQuantityHasError,
  displayingContractsData,
  lowestBidData,
  readOnly = false,
}) => {
  return (
    <>
      {displayingContractsData && (
        <>
          <QuantityInput
            quantity={quantity}
            setQuantity={setQuantity}
            setQuantityHasError={setQuantityHasError}
            readOnly={readOnly}
          />
            {displayingContractsData.map((contract) => (
              <VehicleCard
                imageData={contract.attachments}
                makeName={contract.makeName}
                modelName={contract.modelName}
                vendorName={contract.vendorName}
                contractLineId={contract.contractLineId}
                isActive={
                  contract?.contractLineId === lowestBidData?.contractLineId
                }
                key={`${contract?.contractLineId}-${contract?.modelName}`}
              />
            ))}
        </>
      )}
    </>
  );
};

VehiclesHeader.propTypes = {
  quantity: PropTypes.string.isRequired,
  setQuantity: PropTypes.func.isRequired,
  setQuantityHasError: PropTypes.func.isRequired,
  displayingContractsData: PropTypes.arrayOf(Object).isRequired,
  lowestBidData: PropTypes.shape({
    modelName: PropTypes.string,
    vendorName: PropTypes.string,
    contractLineId: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
};

VehiclesHeader.defaultProps = {
  readOnly: false,
};

export default VehiclesHeader;
