import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const OrderVehicleFilterContext = createContext({});
const useOrderVehicleFilter = () => useContext(OrderVehicleFilterContext);

const initialState = {
  error: '',
  filters: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const orderVehicleFilterReducer = (state, { action, payload }) => {
  const { setFilters, setError } = actions;
  const { error } = initialState;

  switch (action) {
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid order vehicle filter action');
  }
};

const OrderVehicleFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    orderVehicleFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const setFilters = (value) => dispatch(actions.setFilters, value);

  return (
    <OrderVehicleFilterContext.Provider
      value={{
        ...state,
        ...props,
        setFilters,
      }}
    >
      {children}
    </OrderVehicleFilterContext.Provider>
  );
};

OrderVehicleFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrderVehicleFilterProvider as default, useOrderVehicleFilter };
