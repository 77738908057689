import React from 'react';
import withDraftRequisition from '../../hocs/withDraftRequistion/withDraftRequisition';
import UrgentRequisition from '../../pages/non-standard-purchase/urgent-requisition/UrgentRequisition';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';

const UrgentRequirementContainer = () => {
  return withDraftRequisition(UrgentRequisition, true);
};

const UrgentRequirementContainerWithContext = () => {
  return (
    <VehicleRequisitionContextProvider>
      <UrgentRequirementContainer />
    </VehicleRequisitionContextProvider>
  );
};

export default UrgentRequirementContainerWithContext;
