import React from 'react';
import './ReviewSummaryCustomerTasksHeader.scss';

const ReviewSummaryCustomerTasksHeader = () => {
  const tasks = [
    'Please review all information below established by all parties',
    'Review vendor quotes details. Select one vendor.',
    'Provide funding confirmation',
    'Submit to contracting for final approval',
  ];

  return (
    <div
      data-testid="review-summary-customer-tasks-header"
      className="review-summary-customer-tasks-header"
    >
      <div className="title">Customer&apos;s tasks:</div>
      <ol>
        {tasks.map((task) => (
          <li key={task} className="task">
            {task}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ReviewSummaryCustomerTasksHeader;
