import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';

export default function generatePointOfContactChangeHandler({
  formGeneratorRef,
  setOrderChanges,
}) {
  return async function handleChange(context, changedFieldId) {
    const { setError, clearErrors } = context;
    const values = context.getValues();

    const { errors } = await formGeneratorRef.current.validate(
      values,
      {},
      {},
      true,
    );

    const hasErrors = applyFormErrorsRecursively(
      changedFieldId,
      errors,
      setError,
      clearErrors,
    );
    if (hasErrors) return;

    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState.agencyContact) newState.agencyContact = {};

      newState.agencyContact = { ...values };

      return newState;
    });
  };
}
