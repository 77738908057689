import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useRequisitionReview from '../../../utilities/useRequisitionReview';
import CheckedIcon from '../../../assets/images/checked-icon';
import {
  ENGINEER_APPROVE,
  SUBMIT_FOR_CONTRACTING_REVIEW,
} from '../../../services/data-layer';
import './EngineerApproveButton.scss';
import { RequisitionStatus } from '../RequisitionDetailsUtils';

export const ENGINEER_APPROVED = 'engineer-approved';

const EngineerApproveButton = ({
  requisitionId,
  requisitionName,
  requisitionCartState,
}) => {
  const navigate = useNavigate();
  const { shouldGoToCO } = useRequisitionReview(requisitionCartState);

  const [engineerApproveMutation] = useMutation(ENGINEER_APPROVE, {
    onError: (error) => {
      // TODO
      // not covered in user story yet
      // eslint-disable-next-line no-console
      console.log(
        'Engineer Approved errored out: ',
        error?.graphQLErrors[0].message,
      );
    },
    onCompleted: () => {
      navigate(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${ENGINEER_APPROVED}`,
      );
    },
  });

  const [submitForContractingReviewMutation] = useMutation(
    SUBMIT_FOR_CONTRACTING_REVIEW,
    {
      onError: (error) => {
        // TODO
        // not covered in user story yet
        // eslint-disable-next-line no-console
        console.log(
          'Submit For Contracting Review errored out: ',
          error?.graphQLErrors[0].message,
        );
      },
      onCompleted: () => {
        navigate(
          `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.CONTRACTING_REVIEW}`,
        );
      },
    },
  );

  const handleSubmit = () => {
    if (shouldGoToCO) {
      submitForContractingReviewMutation({
        variables: {
          requisitionId,
        },
      });
    } else {
      engineerApproveMutation({
        variables: {
          requisitionId,
        },
      });
    }
  };

  return (
    <>
      <button
        data-testid="engineer-approve-button"
        type="button"
        className="engineer-approve-button"
        onClick={handleSubmit}
      >
        <CheckedIcon />
        <span className="button-text">
          {shouldGoToCO ? 'Submit to contracting officer' : 'Approve'}
        </span>
      </button>
    </>
  );
};

EngineerApproveButton.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
};

export default EngineerApproveButton;
