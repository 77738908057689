import React, { useState, useContext, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  useModal,
  EmptyState,
  AFPTableRowAction,
  AFPTable,
  Checkbox,
} from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
// import fileDownload from 'js-file-download';
import './FilesUpload.scss';
import {
  // GET_DOC_METADATA,
  // GENERATE_READ_SIGNED_URL,
  GET_REQUISITION_ATTACHMENTS,
} from '../../../../services/data-layer';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import AttachmentDeleteModal from '../../../ReviewDetails/Attachment/AttachmentDeleteModal/AttachmentDeleteModal';
import AttachmentUploadEditModal from '../../../ReviewDetails/Attachment/AttachmentEditModal/AttachmentEditModal';
import OptionDetailFileUploadModal from './OptionDetailFileUpload';

const FilesUpload = ({ optionCode, page }) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);

  const { draftRequisition, requisitionAttachmentTypes } = state;
  const [totalFiles, setTotalFiles] = useState([]);
  const [uploadModalInfo, setUploadModalInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState({});
  // const [docMimeType] = useState('');
  const [showDocs, setShowDocs] = useState(false);

  const deleteFileModal = useModal();
  const updateFileModal = useModal();

  const handleSelectedRow = (event, row) => {
    setSelectedFile({
      id: row.original.requisitionAttachmentId,
      description: row?.original?.description,
    });
    if (event === 'Delete file') {
      deleteFileModal.openModal();
    } else if (event === 'Edit') {
      updateFileModal.openModal();
    }
  };

  let foundAttachmentType = {};

  if (page === 'OPTION_DETAILS') {
    foundAttachmentType = requisitionAttachmentTypes.find(
      (a) => a.attachmentType === 'req_purchaseline_equipment',
    );
  } else {
    foundAttachmentType = requisitionAttachmentTypes.find(
      (a) => a.attachmentType === 'req_color_options',
    );
  }

  // const [getGeneratedSignedUrl] = useLazyQuery(GENERATE_READ_SIGNED_URL, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted: async (data) => {
  //     const fileURL = data.generateReadSignedURL;
  //     const type = docMimeType;
  //     const response = await fetch(fileURL, {
  //       method: 'GET',
  //       headers: { 'Content-Type': type },
  //     });
  //     const blob = await response.blob();
  //     fileDownload(blob, selectedFile.file.name);
  //   },
  // });

  const { data, loading, refetch } = useQuery(GET_REQUISITION_ATTACHMENTS, {
    skip: !draftRequisition?.requisitionId,
    variables: {
      requisitionId: draftRequisition?.requisitionId,
    },
  });

  const refetchCall = () => {
    refetch();
  };

  useEffect(() => {
    if (data?.getAllRequisitionAttachments.length > 0) {
      const filteredFiles = data?.getAllRequisitionAttachments
        .filter(
          (f) =>
            f.attachmentTypeId ===
              foundAttachmentType?.requisitionAttachmentTypeId &&
            f.equipmentCode === optionCode,
        )
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

      if (filteredFiles?.length > 0) {
        setShowDocs(true);
      }

      setTotalFiles(filteredFiles);
    } else {
      setTotalFiles([]);
    }
  }, [data]);

  useEffect(() => {
    if (uploadModalInfo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [uploadModalInfo]);

  const handleUploadFile = (selectedOption) => {
    setUploadModalInfo({ selectedOption });
  };

  const modalCloseHandler = () => {
    setUploadModalInfo(null);
  };

  const download = () => {
    return '';
  };

  const toggleShowDocs = () => {
    setShowDocs(!showDocs);
  };

  const actionList = [
    {
      icon: 'edit',
      label: 'Edit',
    },
    {
      icon: 'delete',
      label: 'Delete file',
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              // eslint-disable-next-line react/prop-types
              name={original?.name}
              // eslint-disable-next-line react/prop-types
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Document',
        accessor: 'document',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          return original?.attachmentType?.attachmentType;
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          return original?.description;
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        // eslint-disable-next-line react/prop-types
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={actionList}
              onSelectAction={(evt) => handleSelectedRow(evt, row)}
              {...props}
            />
          );
        },
      },
    ],
    [totalFiles],
  );

  return (
    <>
      <div>
        <Checkbox
          className="supporting-docs-checkbox"
          checked={showDocs}
          onChange={toggleShowDocs}
          label="Supporting documents"
        />

        {showDocs && (
          <>
            <div className="file-upload-section">
              <span>Supporting documentation</span>
              <Button
                data-testid="upload-file-button"
                variant="outline"
                onClick={() => {
                  if (!draftRequisition) {
                    dispatch({
                      type: VehicleRequisitionContextActions.UPDATE_FILE_UPLOAD_SECTION,
                      payload: true,
                    });
                  } else {
                    handleUploadFile(optionCode);
                  }
                }}
                leftIcon={{ name: 'add' }}
                label="Upload File"
              />
            </div>
            <div className="attachments-table-section">
              {totalFiles.length > 0 && (
                <AFPTable
                  testId="additional-requirements-files-table"
                  columns={columns}
                  data={totalFiles}
                />
              )}

              {!loading && !totalFiles?.length && (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No attachments found for this requisition"
                />
              )}
            </div>
          </>
        )}
      </div>

      <OptionDetailFileUploadModal
        onClose={modalCloseHandler}
        modalInfo={uploadModalInfo}
        download={download}
        refetch={refetchCall}
        page={page}
      />

      <AttachmentDeleteModal
        isOpen={deleteFileModal.isOpen}
        handleClose={deleteFileModal.closeModal}
        fileToDelete={selectedFile}
        onCompleted={refetchCall}
      />

      <AttachmentUploadEditModal
        isOpen={updateFileModal.isOpen}
        handleClose={updateFileModal.closeModal}
        selectedAttachment={selectedFile}
        onCompleted={refetchCall}
      />
    </>
  );
};

FilesUpload.propTypes = {
  optionCode: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default FilesUpload;
