import React from "react";
import { Modal, Button } from "@gsa/afp-component-library";

export default function CalculatePriceModal({handleClose, handleCalculatePrice}) {
    return <div className="afp-modal-overlay modalContainer low-bid-justification">
        <Modal
            title={<h1>Updated Prices Available</h1>}
            onClose={() => {
                handleClose()
            }}
            className="justification-modal"
            actions={
                <>
                    <Button
                        type="button"
                        className="modal-cancel-button"
                        onClick={() => {
                            handleClose();
                        }}
                        label="Cancel"
                    />
                    <Button
                        type="button"
                        className="modal-close-button"
                        onClick={() => {
                            handleCalculatePrice();
                         //   setShowCalculatePriceModal(false);
                        }}
                        label="Calculate Price"
                    />
                </>
            }
        >
            <p>
                Please select <strong>Calculate Price</strong> below to see
                updated prices prior to selecting a model
            </p>
        </Modal>
    </div>

}