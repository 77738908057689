import React from 'react';

export const InfoIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3037 3.92551C11.756 2.98715 11.013 2.24417 10.0747 1.69648C9.13613 1.1488 8.11153 0.875 7.0002 0.875C5.88896 0.875 4.86407 1.1488 3.9257 1.69648C2.98724 2.24407 2.24427 2.98705 1.69658 3.92551C1.14879 4.86397 0.875 5.88886 0.875 7C0.875 8.11123 1.14889 9.13593 1.69648 10.0745C2.24417 11.0128 2.98715 11.7558 3.92561 12.3035C4.86407 12.8512 5.88886 13.125 7.0001 13.125C8.11133 13.125 9.13623 12.8512 10.0746 12.3035C11.013 11.7559 11.7559 11.0129 12.3036 10.0745C12.8512 9.13603 13.125 8.11114 13.125 7C13.125 5.88877 12.8512 4.86387 12.3037 3.92551ZM5.97931 2.40618C5.97931 2.33168 6.0032 2.2706 6.05107 2.22273C6.09893 2.17496 6.16011 2.15098 6.23451 2.15098H7.76588C7.84028 2.15098 7.90146 2.17496 7.94913 2.22273C7.9969 2.2706 8.02098 2.33168 8.02098 2.40618V3.68225C8.02098 3.75665 7.9969 3.81783 7.94913 3.8656C7.90146 3.91347 7.84028 3.93735 7.76588 3.93735H6.23451C6.16011 3.93735 6.09893 3.91347 6.05107 3.8656C6.0032 3.81783 5.97931 3.75665 5.97931 3.68225V2.40618ZM9.04176 10.8281C9.04176 10.9025 9.01768 10.9637 8.97001 11.0114C8.92234 11.0593 8.86116 11.0831 8.78657 11.0831H5.21363C5.13913 11.0831 5.07805 11.0593 5.03018 11.0114C4.98232 10.9636 4.95843 10.9024 4.95843 10.828V9.55196C4.95843 9.47737 4.98232 9.41638 5.03018 9.36852C5.07805 9.32075 5.13923 9.29676 5.21363 9.29676H5.97931V6.74471H5.21363C5.13913 6.74471 5.07805 6.72082 5.03018 6.67295C4.98232 6.62518 4.95843 6.564 4.95843 6.48951V5.21353C4.95843 5.13904 4.98232 5.07795 5.03018 5.03009C5.07805 4.98222 5.13923 4.95833 5.21363 4.95833H7.76588C7.84028 4.95833 7.90146 4.98222 7.94913 5.03009C7.9969 5.07795 8.02098 5.13904 8.02098 5.21353V9.29686H8.78647C8.86096 9.29686 8.92214 9.32085 8.96991 9.36862C9.01759 9.41638 9.04167 9.47756 9.04167 9.55206V10.8281H9.04176Z"
        fill="#075C97"
      />
    </svg>
  );
};

export default InfoIcon;
