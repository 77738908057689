import { useContext } from 'react';
import VehicleRequisitionContext from '../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { isTaggedSin } from './requisitionStandardItemUtils';
import {
  REQUISITION_TYPE,
  isAreqType,
} from '../pages/non-standard-purchase/constants';

const useRequisitionReview = (requisitionCartState = {}) => {
  const { state } = useContext(VehicleRequisitionContext);
  const { currentStandardItem, engineerTaggedOptions } = state;

  const isAreq =
    state?.isAreq ||
    isAreqType(
      requisitionCartState?.requisitionType || REQUISITION_TYPE.STANDARD_ORDER,
    );

  const isEngineerReviewRequired =
    engineerTaggedOptions?.length > 0 ||
    isTaggedSin(currentStandardItem) ||
    isAreq;

  const shouldGoToCO = isAreq;

  let isVendorDirect = true;
  let vendorDirectLoaded = false;
  if (!isAreq || !isEngineerReviewRequired) {
    if (state?.selectedContract?.isVendorDirect === 1) {
      // this is the new location for isVendorDirect. If this in null 
      // or false then safe to check other locations
      vendorDirectLoaded = true;
    } else if (state?.selectedContract?.contractModification) {
      // in draft status this is in state
      isVendorDirect =
        state?.selectedContract?.contractModification?.contract
          ?.isVendorDirect === 1;
      vendorDirectLoaded = true;
    } else if (state?.selectedContract?.contractMod) {
      // In review summary this is state
      isVendorDirect =
        state?.selectedContract?.contractMod?.contract
          ?.isVendorDirect === 1;
      vendorDirectLoaded = true;
    }
  } else {
    vendorDirectLoaded = true;
  }
  return {
    isEngineerReviewRequired,
    shouldGoToCO,
    isVendorDirect,
    vendorDirectLoaded,
  };
};

export default useRequisitionReview;
