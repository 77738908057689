import React from 'react';
import { Alert, Button, Modal } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import useBulkOrderModsState from '../useBulkOrderModsState';
import { ChangeSetType } from '../BulkOrderModTableHelper';

const OrderModQuantityChangeAlertModal = ({
  onClose,
  allForTermination,
  ordersForTermination,
}) => {
  const {
    selectedOrdersForMod,
    setSelectedOrdersForMod,
    setOptedForTermination,
    terminateOrderModificationMutation,
    setCurrentChangeSetType,
    updateOrderModChangeSetOrders,
    updateOrderModChangeSetMutation,
  } = useBulkOrderModsState();

  const onSubmit = async () => {
    if (allForTermination) {
      setOptedForTermination(true);
      setCurrentChangeSetType(ChangeSetType.TERMINATE);
      terminateOrderModificationMutation();
    } else {
      const newSelectedOrdersForChangeSet = selectedOrdersForMod?.filter(
        (order) =>
          !ordersForTermination.some(
            (item) => item.caseNumber === order.caseNumber,
          ),
      );
      console.log({ newSelectedOrdersForChangeSet });
      setSelectedOrdersForMod(newSelectedOrdersForChangeSet);
      const orderIds = newSelectedOrdersForChangeSet.map((o) => o.orderId);
      await updateOrderModChangeSetOrders(orderIds);
      await updateOrderModChangeSetMutation(orderIds);
    }
    onClose();
  };
  const getAlertMessage = () => {
    if (allForTermination) {
      return (
        <Alert type="warning" slim>
          The quantity change entered leads to terminate all the selected
          orders. Would you like to proceed with terminating the following
          orders?
        </Alert>
      );
    }
    return (
      <Alert type="warning" slim>
        The quantity change entered leads to terminate some of the selected
        orders. If you would like to terminate the orders, please create a new
        change set. Click continue to remove the following orders from the
        current change set.
      </Alert>
    );
  };
  const renderOrders = () => {
    // eslint-disable-next-line react/prop-types
    return ordersForTermination?.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="change-set-order-list-item">
        {item?.caseNumber}
      </div>
    ));
  };
  return (
    <div className="afp-modal-overlay modalContainer non-sop-submit-modal">
      <Modal
        title={
          <h1 className="modal-title">
            Quantity change leads to order termination
          </h1>
        }
        className="save-draft-modal"
        onClose={onClose}
        showAlert
        actions={
          <div className="save-draft-requisition-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="order-mod-modal-cancel-button"
              onClick={onClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="default"
              className="save-draft-requisition-action-button"
              data-testid="order-qty-change-modal-button"
              onClick={() => onSubmit()}
              leftIcon={{ name: 'check' }}
              label="Continue"
            />
          </div>
        }
        alert={getAlertMessage()}
      >
        <div className="sub-component">
          <div>
            <h4>Affecting orders:</h4>
          </div>
          <div className="change-set-table-order-list">{renderOrders()}</div>
        </div>
      </Modal>
    </div>
  );
};
OrderModQuantityChangeAlertModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  allForTermination: PropTypes.bool,
  ordersForTermination: PropTypes.instanceOf(Array),
};

OrderModQuantityChangeAlertModal.defaultProps = {
  allForTermination: false,
  ordersForTermination: [],
};

export default OrderModQuantityChangeAlertModal;
