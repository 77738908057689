import { useNavigate } from "react-router-dom";
import { useReturnRequisition, useSubmitForOrderingAdminApproval, useSubmitForZonalApproval, useSubmitLeasingReqToGSA, useSubmitToFMCApproval } from "../requests/leasing";
import { useCurrentUser } from "@gsa/afp-shared-ui-utils";
import { useSelector ,useDispatch} from "react-redux";
import { RequisitionStatus } from "../pages/ReviewDetails/RequisitionDetailsUtils";
import { RequisitionViewMode } from "../pages/leasing/leasing-consts";
import {
setSubmitButtonDisabled
} from '../reducers/leasing';

export default function useSubmitLeasingRequisition({
    submitRequisitionModal,
} = {}) {
    const {
      requisitionId,
      requisitionStatus,
      requisitionNumber,
    } = useSelector((state) => state.leasingReducer);
    const dispatch = useDispatch();
    const submitToFMCApproval = useSubmitToFMCApproval();
    const submitForZonalApproval = useSubmitForZonalApproval();
    const submitLeasingReqToGSA = useSubmitLeasingReqToGSA();
    const returnRequisition = useReturnRequisition();
    const submitForOrderingAdminApproval = useSubmitForOrderingAdminApproval();
    const navigate = useNavigate();

    const { currentUser } = useCurrentUser();
    const admin = currentUser?.roles?.some((role) => role?.id === '100');
    const fsr = currentUser?.roles?.some((role) => role?.id === '1');
    const fmcManager = currentUser?.roles?.some((role) => role?.id === '2001');
    const orderingAdmin = currentUser?.roles?.some((role) => role?.id === '1503');
    const zonalAcquisitionCoordinator = currentUser?.roles?.some((role) => role?.id === '1504');
    const validLeasingUsers = admin || fsr || fmcManager || orderingAdmin || zonalAcquisitionCoordinator;

    /** BEGIN SUBMIT METHOD */
    let submitMethod = () => { };
    if (requisitionStatus === RequisitionStatus.DRAFT || requisitionStatus === RequisitionStatus.RETURNED) {
        if (fsr && !fmcManager) {
            submitMethod = submitToFMCApproval;
        } else if (fmcManager) {
            submitMethod = submitForZonalApproval;
        } else if (zonalAcquisitionCoordinator) {
            submitMethod = submitForOrderingAdminApproval;
        } else if (orderingAdmin || admin) {
            submitMethod = submitLeasingReqToGSA;
        }
    } else if(requisitionStatus === RequisitionStatus.FMC_APPROVAL && (fmcManager || fsr || admin)) {
        submitMethod = submitForZonalApproval
    } else if (requisitionStatus === RequisitionStatus.ZONAL_APPROVAL && (zonalAcquisitionCoordinator || admin)) {
        submitMethod = submitForOrderingAdminApproval;
    } else if (requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL && (orderingAdmin || admin)) {
        submitMethod = submitLeasingReqToGSA;
    }
    /** END SUBMIT METHOD */

    /** BEGIN RETURN METHOD */
    let returnMethod = () => { };
    if (
        (requisitionStatus === RequisitionStatus.FMC_APPROVAL && (fmcManager || fsr || admin)) ||
        (requisitionStatus === RequisitionStatus.ZONAL_APPROVAL && (zonalAcquisitionCoordinator || admin)) ||
        (requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL && (orderingAdmin || admin))
    ) {
        returnMethod = returnRequisition
    }
    /** END RETURN METHOD */


    /** BEGIN SUBMIT LABEL */
    let submitLabel;
    if (requisitionStatus === RequisitionStatus.DRAFT || requisitionStatus === RequisitionStatus.RETURNED) {
        if (fsr && !fmcManager) {
            submitLabel = 'Submit to FMC approval';
        } else if (fmcManager) {
            submitLabel = 'Submit to zonal approval';
        } else if (zonalAcquisitionCoordinator) {
            submitLabel = 'Submit to ordering management approver';
        } else if (orderingAdmin || admin) {
            submitLabel = 'Place order';
        }
    } else if (requisitionStatus === RequisitionStatus.FMC_APPROVAL && (fmcManager || fsr || admin)) {
        submitLabel = 'Submit to zonal approval';
    } else if (requisitionStatus === RequisitionStatus.ZONAL_APPROVAL && (zonalAcquisitionCoordinator || admin)) {
        submitLabel = 'Submit to ordering management approver';
    } else if (requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL && (orderingAdmin || admin)) {
        submitLabel = 'Place order';
    }
    /** END SUBMIT LABEL */

    
    const determineRequisitionViewMode = (requisition) => {
        if (requisition?.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA) {
            return RequisitionViewMode.READONLY;
        }

        if (requisition?.requisitionStatus === RequisitionStatus.FMC_APPROVAL) {
            if (fmcManager || fsr || admin) {
                return RequisitionViewMode.REVIEW;
            } else {
                return RequisitionViewMode.READONLY;
            }
        }

        if (requisition?.requisitionStatus === RequisitionStatus.ZONAL_APPROVAL) {
            if (zonalAcquisitionCoordinator || admin) {
                return RequisitionViewMode.REVIEW;
            } else {
                return RequisitionViewMode.READONLY;
            }
        }

        if (requisition?.requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL) {
            if (orderingAdmin || admin) {
                return RequisitionViewMode.REVIEW;
            } else {
                return RequisitionViewMode.READONLY;
            }
        }

        if (requisition?.requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL) {
            if (orderingAdmin || admin) {
                return RequisitionViewMode.REVIEW;
            } else {
                return RequisitionViewMode.READONLY;
            }
        }
        if (
          validLeasingUsers &&
          (
           !requisition || 
            requisition?.requisitionStatus === RequisitionStatus.DRAFT ||
            requisition?.requisitionStatus === RequisitionStatus.RETURNED)
        ) {
          return RequisitionViewMode.EDIT;
        } else {
          return RequisitionViewMode.READONLY;
        }
    }

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            if (
                (requisitionStatus === RequisitionStatus.FMC_APPROVAL && (fmcManager || fsr || admin)) ||
                (requisitionStatus === RequisitionStatus.ZONAL_APPROVAL && (zonalAcquisitionCoordinator || admin)) ||
                (requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL && (orderingAdmin || admin))
            ) {
                submitRequisitionModal.openModal();
                resolve();
            } else {
                dispatch(setSubmitButtonDisabled(true));
                const [submitRequisition] = submitMethod;

                submitRequisition({
                    variables: {
                        requisitionId,
                    },
                }).then(() => {
                    navigate(`/my-requisitions?filter-requisitionNumber=${encodeURIComponent(requisitionNumber)}`);
                    dispatch(setSubmitButtonDisabled(false));
                    resolve();
                }).catch((err) => {
                    dispatch(setSubmitButtonDisabled(false));
                    console.error(err);
                    reject(err);
                });
            }
        });
    };

    return {
        handleLeasingRequisitionSubmit: handleSubmit,
        leasingRequisitionSubmitMethod: submitMethod,
        leasingRequisitionReturnMethod: returnMethod,
        determineRequisitionViewMode,
        leasingRequisitionSubmitLabel: submitLabel,
    }
}
