import React, { useState } from "react";
import {
    Spinner,
    Button,
    Alert
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from "react-redux";
import SearchBySIN from "../../../../../components/SearchBySIN/SearchBySIN.jsx";
import SelectTypeCard from "./select-type-card.jsx";
import { setSelectedVehicleType, setDestinationType } from "../../../../../reducers/leasing.jsx";
import { VehicleListings } from "../vehicle-listings/vehicle-listings.jsx";
import { LeasingSteps } from "../../../leasing-consts.js";
import useLeasingNavigation from "../../../../../hooks/useLeasingNavigation.jsx";
import { setSelectedStandardItem, setSelectedOptions } from "../../../../../reducers/leasing.jsx";
import useUpdateDraft from "../../../components/update-draft/update-leasing-draft.js";

const AvailableVehicles = () => {
    const {
        availableVehicleTypes,
        selectedVehicleType,
        account,
        destinationType,
        destinationTypeLoadedFromDraft,
        shipmentCountry,
        shipmentState,
        states,
        countries,
        selectedStandardItem
    } = useSelector(state => state.leasingReducer);

    const [showVehicles, setShowVehicles] = useState(destinationType && shipmentState && destinationTypeLoadedFromDraft);
    const { handleLeasingNavigation } = useLeasingNavigation();
    const dispatch = useDispatch();
    const [updateDraft] = useUpdateDraft();

    const onSelectItem = (vehicleType) => {
        let input = {
            clientData: { clientState: { selectedStandardItem: vehicleType } },
        };
        if (vehicleType?.id !== selectedStandardItem?.id) {
            input = { clientData: { ...input.clientData, selectedOptions: { selectedOptions: [] } } }
            dispatch(setSelectedOptions([]));
        };
        updateDraft(input, LeasingSteps.COMPARE_AND_SELECT).then((data) => {
            if (data) {
                dispatch(setSelectedStandardItem(vehicleType));
                dispatch(setDestinationType({ type: destinationType, loadedFromDraft: true }));
                handleLeasingNavigation(LeasingSteps.COMPARE_AND_SELECT);
            }
        });
    };

    const onVehicleTypeClick = (vehicleType) => {
        dispatch(setSelectedVehicleType(vehicleType.code));
    };

    const getShipmentAlertDescription = () => {
        const agencyBureauOffice = [
            [account.customer?.customerAgency?.id, account.customer?.customerAgency?.name].filter(Boolean).join('-'),
            [account.customer?.customerBureau?.id, account.customer?.customerBureau?.name].filter(Boolean).join('-'),
            [account.customer?.customerOffice?.id, account.customer.customerOffice?.name].filter(Boolean).join('-')
        ];

        let stateName, countryName;

        if (!shipmentState?.startsWith('Select') && shipmentCountry === 'US') {
            stateName = states?.find(state => state.value === shipmentState)?.label;
        }
        if (!shipmentCountry?.startsWith('Select')) {
            countryName = countries?.find(country => country.value === shipmentCountry)?.label;
        }

        return (
            <div>
                This order is for <b>{agencyBureauOffice.join(', ')}</b> to be shipped to {stateName ? stateName : countryName}
            </div>
        )
    };

    return (
        <>
            <div>
                <div className='filter-container'>
                    <Button
                        className="shipment-option-submit"
                        onClick={() => setShowVehicles(true)}
                        data-testid="shipment-option-submit-button"
                        aria-label="View available vehicles and options"
                        label='View available vehicles and options'
                    />
                </div>
                {
                    showVehicles && (
                        <div>
                            <Alert type="info">
                                {getShipmentAlertDescription()}
                            </Alert>
                            {!selectedVehicleType && (
                                <div className="filter-container">
                                    <h4>Search Vehicles</h4>
                                    <SearchBySIN
                                        data-testid="sin-search"
                                        onSelectItem={onSelectItem}
                                        leasing
                                    />
                                </div>
                            )}
                            <div className="filter-container">
                                {!availableVehicleTypes ? <Spinner aria-busy="true" size="large" /> : (
                                    !selectedVehicleType ? (
                                        <>
                                            <h4>Search by Type</h4>
                                            <SelectTypeCard
                                                list={availableVehicleTypes}
                                                onVehicleTypeClick={onVehicleTypeClick}
                                            />
                                        </>
                                    ) : (
                                        <VehicleListings onSelectItem={onSelectItem} />
                                    )
                                )}
                            </div>
                        </div>

                    )
                }

            </div>
        </>
    );
}

export default AvailableVehicles;