import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { AVAILABLE_EDIT_STEPS } from '../../constants';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const VehicleSpecificationsReview = ({
  title,
  editPage,
  nonSopData,
  additionalRequirements,
  isEditable,
}) => {
  return (
    <div role="tab" tabIndex="0" className="vehicle-specification-section">
      <div className="section-main-title">
        {title}
        {isEditable && (
          <Button
            variant="outline"
            onClick={() => editPage(AVAILABLE_EDIT_STEPS[0])}
            data-testid="vehicle-spec-edit-section"
            leftIcon={{ name: 'edit' }}
            label="Edit"
            size="small"
          />
        )}
      </div>
      <div>
        <div className="section-row">
          <div className="row-column">
            <div className="title">Vehicle type</div>
            <div className="title-desc">{nonSopData?.vehicleType || '-'}</div>
          </div>
          <div className="row-column">
            <div className="title">Drive train</div>
            <div className="title-desc">{nonSopData?.driveTrain || '-'}</div>
          </div>
          <div className="row-column">
            <div className="title">Quantity</div>
            <div className="title-desc">{nonSopData?.quantity || '-'}</div>
          </div>
        </div>
        <div className="section-row">
          <div className="row-column">
            <div className="title">Color</div>
            <div className="title-desc">{nonSopData?.color || '-'}</div>
          </div>
          <div className="row-column">
            <div className="title">Fuel type</div>
            <div className="title-desc">{nonSopData?.fuelType || '-'}</div>
          </div>
          <div className="row-column">
            <div className="title">Gross vehicle weight rating (lbs)</div>
            <div className="title-desc">
              {nonSopData?.grossVehicleWeightRating || '-'}
            </div>
          </div>
        </div>
        <div className="section-row">
          <div className="row-column">
            <div className="title">Gross curb weight rating</div>
            <div className="title-desc">
              {nonSopData?.grossCombinedWeightRating || '-'}
            </div>
          </div>

          <div className="row-column">
            <div className="title">Payload capacity</div>
            <div className="title-desc">
              {nonSopData?.payloadCapacity || '-'}
            </div>
          </div>

          <div className="row-column">
            <div className="title">Towing capacity</div>
            <div className="title-desc">
              {nonSopData?.towingCapacity || '-'}
            </div>
          </div>
        </div>
        <div className="section-row">
          <div className="row-column">
            <div className="title">Towing type</div>
            <div className="title-desc">{nonSopData?.towingType || '-'}</div>
          </div>
        </div>

        <div className="other-requirement-section">
          <div className="main-title">Other requirements</div>
          <ol>
            {additionalRequirements?.map((li) => {
              return (
                <div className="other-requirements-list">
                  <li className="title-desc">{li.description}</li>
                  <div>
                    {li?.attachments?.map((file) => {
                      return (
                        <>
                          <AttachmentDownload
                            name={file?.name}
                            metadataId={file?.metadataId}
                          />
                          <div>{file.description}</div>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

VehicleSpecificationsReview.propTypes = {
  title: PropTypes.string.isRequired,
  editPage: PropTypes.func.isRequired,
  nonSopData: PropTypes.objectOf(PropTypes.string).isRequired,
  additionalRequirements: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ).isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default VehicleSpecificationsReview;
