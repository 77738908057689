let validatedRequisitionNumber = '';
let validatedResponse = null;

export default async function validateRequisitionNumber({
  validateRequisitionNumberEndpoint,
  requisitionDraftId,
  boac,
  julian,
  serialNumber,
  force,
}) {
  if (
    !boac ||
    !julian ||
    !serialNumber ||
    boac.length !== 6 ||
    serialNumber.length !== 4
  )
    return undefined;

  const requisitionNumber = [boac, julian, serialNumber].join('-');

  if (validatedRequisitionNumber !== requisitionNumber || force) {
    const { data } = await validateRequisitionNumberEndpoint({
      variables: {
        requisitionId: requisitionDraftId,
        requisitionNumber,
      },
    });

    validatedRequisitionNumber = requisitionNumber;
    validatedResponse = data?.validateRequisitionNumber;
  }

  return validatedResponse;
}
