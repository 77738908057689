import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert, Modal, Checkbox } from '@gsa/afp-component-library';

export default function MultipleAdditionalRequirementsWarningModal({
  closeModal,
  doNotShowAgainSession,
  setDoNotShowAgainSession,
}) {
  const Actions = (
    <>
      <Button
        data-testid="close-btn"
        type="button"
        variant="primary"
        label="Close"
        onClick={closeModal}
      />
    </>
  );

  return (
    <Modal title="" onClose={closeModal} variant="large" actions={Actions}>
      <Alert heading="Please be sure to..." type="warning">
        Type a complete description of your additional requirement and add
        supporting documents as needed.
        <ul>
          <li>
            <strong>DO NOT</strong> add supporting documents without entering a
            complete required description
          </li>
          <li>
            <strong>DO NOT</strong> type incomplete descriptions such as
            &quot;See attached&quot;
          </li>
        </ul>
      </Alert>

      <Checkbox
        id="do-not-show-again-btn"
        checked={doNotShowAgainSession}
        onChange={() => {
          setDoNotShowAgainSession(!doNotShowAgainSession);
        }}
        label="Don't show again"
      />
    </Modal>
  );
}

MultipleAdditionalRequirementsWarningModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  doNotShowAgainSession: PropTypes.bool.isRequired,
  setDoNotShowAgainSession: PropTypes.func.isRequired,
};
