import { Spinner, Alert } from '@gsa/afp-component-library';
import {
    useCurrentUser, useTitle
} from '@gsa/afp-shared-ui-utils';
import { useRouteError } from 'react-router-dom';

const LOGOUT_URL = '/auth';

export default function RouteError() {
    useTitle('Error');
    const { isLoading, currentUser, isLoggedIn } = useCurrentUser();

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { networkError, stack } = useRouteError();
    if (isLoading)
        return <Spinner data-testid="loading-spinner" className="padding-y-9" />;

    if (networkError === 'No current user' || !currentUser || !isLoggedIn)
        window.location.replace(LOGOUT_URL);

    return (
        <div className="margin-bottom-5">
            <section
                className="usa-header mobile-lg:grid-col-6"
                data-testid="access-denied-page-header"
            >
                <h1 className="font-sans-1xl">System Error</h1>
                <p className="font-body-lg">
                    Please try again later. If you continue to experience technical
                    difficulties with this page, please contact the GSA Fleet Technical
                    Support team at fleet.helpdesk@gsa.gov or 866-472-6711 from 8:00
                    a.m. - 7:00 p.m. ET, Monday-Friday.
                </p>
            </section>
            {window.AFP_CONFIG.showGraphqlErrors && (
                <Alert
                    type="error"
                    heading="Uncaught error"
                >
                    <pre><code>{stack}</code></pre>
                </Alert>
            )}
        </div>
    );
}