import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useCurrentUser, useAppAbility } from '@gsa/afp-shared-ui-utils';
import { GET_NAVIGATION_MENUS } from './data-layer';
import { getAvailableMenuItemsForUser } from './helpers';

const useNavigationMenuStore = (useCache = true) => {
  const { currentUser, isLoggedIn } = useCurrentUser();
  const [getMenu, { data }] = useLazyQuery(GET_NAVIGATION_MENUS, {
    fetchPolicy: 'no-cache',
    ...(useCache && { nextFetchPolicy: 'cache-first' }),
  });
  const [menu, setMenu] = useState([]);
  const ability = useAppAbility();

  useEffect(() => {
    if (currentUser && isLoggedIn) {
      getMenu();
    } else {
      setMenu([]);
    }
  }, [currentUser, isLoggedIn]);

  useEffect(() => {
    if (data && data.getNavigationMenus && ability) {
      setMenu(getAvailableMenuItemsForUser(data.getNavigationMenus, ability));
    }
  }, [data, ability]);

  return [
    ...menu,
    {
      title: 'Resource center',
      linkUrl: `${window.AFP_CONFIG.appURLs.home}/resource-center`,
      order: 70,
      isHidden: false,
      permission: null,
      childNavigationItems: [],
      __typename: 'NavigationMenu',
    },
    {
      title: 'Help',
      linkUrl: `${window.AFP_CONFIG.appURLs.home}/help`,
      order: 80,
      isHidden: false,
      permission: null,
      childNavigationItems: [],
      __typename: 'NavigationMenu',
    },
  ];
};

export default useNavigationMenuStore;
