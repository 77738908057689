import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Form,
  TextInput,
  connectModal,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';

import '../ReviewDetails/Attachment/AttachmentEditModal/AttachmentEditModal.scss';
import { UPDATE_ORDER_MOD_ATTACHMENT_DESCRIPTION } from '../../services/data-layer';

function ConnectModal({ handleClose, handleUpdate, attachmentDescription }) {
  const [description, setDescription] = useState(attachmentDescription);

  function handleUpdateWrapper() {
    handleUpdate({ description });
  }

  return (
    <Modal
      title={<h2>Edit file comment</h2>}
      onClose={handleClose}
      className="upload-attachment-modal"
      variant="large"
      actions={
        <div>
          <Button
            type="button"
            variant="unstyled"
            className="modal-cancel-button"
            data-testid="requisition-attachment-upload-cancel-button"
            onClick={handleClose}
            label="Cancel"
          />
          <Button
            type="button"
            className="modal-close-button"
            data-testid="requisition-attachment-upload-update-button"
            onClick={() => handleUpdateWrapper()}
            label="Update comment"
          />
        </div>
      }
    >
      <Form
        name="requisition-attachment-upload"
        className="requisition-attachments-upload-form"
      >
        <TextInput
          type="textarea"
          placeholder="Type comment here..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          data-testid="requisition-attachment-upload-description"
          maxLength={100}
          hint={`${100} characters allowed`}
        />
      </Form>
    </Modal>
  );
}

ConnectModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  attachmentDescription: PropTypes.string.isRequired,
};

export default function OrderModAttachmentUpdateModal({
  selectedAttachment,
  isOpen,
  handleClose,
  onCompleted,
}) {
  const EditFileModal = connectModal(ConnectModal);

  const [updateAttachmentDescription] = useMutation(
    UPDATE_ORDER_MOD_ATTACHMENT_DESCRIPTION,
    {
      onCompleted: () => {
        handleClose();
        onCompleted();
      },
    },
  );

  const handleUpdate = ({ description }) => {
    updateAttachmentDescription({
      variables: {
        attachmentId: selectedAttachment.orderModAttachmentId,
        description,
      },
    });
  };

  return (
    <EditFileModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleUpdate={handleUpdate}
      attachmentDescription={selectedAttachment.description}
    />
  );
}

OrderModAttachmentUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedAttachment: PropTypes.instanceOf(Object).isRequired,
  onCompleted: PropTypes.func,
};

OrderModAttachmentUpdateModal.defaultProps = {
  onCompleted: () => {},
};
