import { useState, useEffect } from 'react';

export const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }

  if (stored === 'NaN' || stored === 'undefined') {
    return defaultValue;
  }

  return JSON.parse(stored);
};

export const useSessionState = (key, defaultValue) => {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue),
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
