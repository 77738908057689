import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import {
  SUBMIT_FOR_CONTRACTING_REVIEW,
  CREATE_REQUISITION_COMMENT,
} from '../../services/data-layer';
import './PlaceOrderModal.scss';

const CONTRACTING_REVIEW_STATUS = 'contracting-review';
const SubmissionModal = ({ requisitionId, onClose, requisitionName }) => {
  const { appURLs } = window.AFP_CONFIG;
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [submitReqToContracting] = useMutation(SUBMIT_FOR_CONTRACTING_REVIEW, {
    onError: () => {
      onClose();
    },
    onCompleted: () => {
      navigate(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${CONTRACTING_REVIEW_STATUS}`,
      );
    },
  });
  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT);

  const onSubmit = async () => {
    await submitRequisitionComment({
      variables: {
        requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
    await submitReqToContracting({
      variables: {
        requisitionId,
      },
    });
  };

  return (
    <div className="afp-modal-overlay modalContainer non-sop-submit-modal">
      <Modal
        title={<h1 className="modal-title">Confirm submission</h1>}
        className="save-draft-modal"
        onClose={onClose}
        actions={
          <div className="save-draft-requisition-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="modal-req-cancel-button"
              onClick={onClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="default"
              className="save-draft-requisition-action-button"
              data-testid="modal-req-submit-button"
              onClick={() => onSubmit()}
              leftIcon={{ name: 'check' }}
              label="Submit requisition"
            />
          </div>
        }
      >
        <div className="title-desc">
          Are you sure you want ot submit this requisition?
        </div>
        <div className="comment-section">
          <div className="title border-bottom"> Add a Comment</div>
          <div className="title-desc">
            Your comment will be added to the Activity tracker upon submission
            of this requisition and viewable by all participating parties
          </div>
          <TextInput
            type="textarea"
            maxLength={500}
            data-testid="my-req-submit-comment"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            name="justification"
            inputClass="req-input-text"
          />
          <span className="comments-info"> 500 characters allowed</span>
        </div>
      </Modal>
    </div>
  );
};

SubmissionModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  requisitionName: PropTypes.string.isRequired,
};

export default SubmissionModal;
