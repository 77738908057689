import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from './widgets/Breadcrumbs';
import ReportsStepIndicator from './widgets/ReportsStepIndicator';
import { StoreReportsProvider } from './useStoreReportsContext';
import StepDetailsForm from './step-details/StepDetailsForm';
import SelectedReport from './widgets/SelectedReport';
import './StoreReports.scss';

const StoreReports = () => {
  return (
    <StoreReportsProvider>
      <div className="requisitions-and-orders">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Breadcrumbs />
        </div>
        <PageTitle data-testid="store-reports" title="Store reports" />
        <p className="subheader-description font-sans-lg">
          Use this tool to export requisition or order data to your desktop.
        </p>
        <p className="font-body-2xs margin-top-neg-5 margin-bottom-4">
          Required fields are marked with an asterisk (
          <span className="afp-required-field">*</span>).
        </p>
        <div className="border-bottom-2px border-base-lighter margin-bottom-4" />
        <SelectedReport />
        <ReportsStepIndicator />
        <StepDetailsForm />
      </div>
    </StoreReportsProvider>
  );
};

export default StoreReports;
