import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_BUREAUS_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import { AGENCY_FILTER } from './filter-item-constants';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const BureauFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([]);
  const { mergedFilters, clearOneFilter } = useFilterPanel();
  const agencyCode = mergedFilters?.find(
    (filt) => filt?.key === AGENCY_FILTER,
  )?.value;
  const [getBureaus, { data: bureauOptions, loading: bureauOptionsLoading }] =
    useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);

    if (!agencyCode) {
      return;
    }

    getBureaus({
      variables: {
        agencyCode,
        operation: 'view',
        subject: StoreSubjects.Requisition,
      },
    });
  }, [agencyCode]);

  useEffect(() => {
    if (!bureauOptions?.getBureausByPermission) {
      return;
    }

    const formattedBureauOptions = sortBy(
      bureauOptions.getBureausByPermission,
      'id',
    ).map((bureauDetails) => ({
      value: bureauDetails.id,
      label: bureauDetails.name,
    }));
    setOptions(formattedBureauOptions);
  }, [bureauOptions]);

  if (bureauOptionsLoading) {
    return <Spinner />;
  }

  return (
    <div id="bureau-filter-item" className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
        }}
      />
    </div>
  );
};

BureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BureauFilterItem;
