import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

const FormButtons = ({ handlePageNavigation }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const { urgentRequirementCurrentStep } = state;
  const isLastStep = urgentRequirementCurrentStep.current === 5;

  return (
    <div className="non-sop-page-nav">
      <Button
        variant="outline"
        type="button"
        data-testid="vehicle-requisition_navigation_previous"
        onClick={() => {
          handlePageNavigation('previous');
        }}
        leftIcon={{ name: 'arrow_back' }}
        label="Previous"
      />
      <Button
        variant="default"
        data-testid="vehicle-requisition_navigation_continue"
        disabled={isLastStep}
        type="submit"
        rightIcon={{ name: 'arrow_forward' }}
        label={isLastStep ? 'Submit to approver' : 'Next'}
      />
    </div>
  );
};

FormButtons.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
};

export default FormButtons;
