import React from 'react';
import PropTypes from 'prop-types';

const OrderMessageLinesDisplay = ({ messageLines }) => {
  return (
    <div className="margin-bottom-2">
      <div className="flex-column">
        <div
          className="flex-row font-body-md"
          key={messageLines?.message1 ?? 'rml1'}
        >
          {messageLines?.message1 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row" key={messageLines?.message2 ?? 'rml2'}>
          {messageLines?.message2 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row" key={messageLines?.message3 ?? 'rml3'}>
          {messageLines?.message3 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row" key={messageLines?.message4 ?? 'rml4'}>
          {messageLines?.message4 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row" key={messageLines?.message5 ?? 'rml5'}>
          {messageLines?.message5 ?? <span>&ndash;</span>}
        </div>
      </div>
    </div>
  );
};

OrderMessageLinesDisplay.propTypes = {
  messageLines: PropTypes.shape({
    message1: PropTypes.string,
    message2: PropTypes.string,
    message3: PropTypes.string,
    message4: PropTypes.string,
    message5: PropTypes.string,
  }),
};

OrderMessageLinesDisplay.defaultProps = {
  messageLines: {
    message1: null,
    message2: null,
    message3: null,
    message4: null,
    message5: null,
  },
};
export default OrderMessageLinesDisplay;
