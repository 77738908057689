import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import {useNavigate} from "react-router-dom";

export default function CreateOrderModButton() {
    const navigate = useNavigate();

    return (
    <div>
      <Button
        label="Create a new modification"
        onClick={() => navigate('/create-order-modifications')}
        leftIcon={{
          name: 'add',
          type: 'button',
        }}
      />
    </div>
  );
}


