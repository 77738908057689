import _get from 'lodash/get';
import XLSX from 'xlsx';

export const VAL_TABLE_COLUMN_HEADERS = [
  {
    Header: 'Vehicle type',
    accessor: 'vehicleType', // accessor is the "key" in the data
  },
  {
    Header: 'Standard Item',
    accessor: 'standardItemCode',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Vendor',
    accessor: 'vendor',
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturer',
  },
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'Open date',
    accessor: 'openDate',
  },
  {
    Header: 'Closeout date',
    accessor: 'closeoutDate',
  },
];

const dateFormat = new Intl.DateTimeFormat('en', {
  dateStyle: 'short',
});

export const getTableColumns = (row) => ({
  vehicleType: _get(row, 'vehicleTypeTitle'),
  description: _get(row, 'standardItemTitle'),
  standardItemCode: _get(row, 'standardItem'),
  vendor: _get(row, 'vendor'),
  manufacturer: _get(row, 'make'),
  model: _get(row, 'model'),
  openDate: dateFormat.format(new Date(_get(row, 'openDate'))),
  closeoutDate: dateFormat.format(new Date(_get(row, 'closedDate'))),
});

export const generateFilterQuery = (filterBy, key, value) => {
  if (!filterBy || !key || !value || !value.length) {
    return [];
  }
  return [
    {
      operator: '$or',
      filterBy,
      conditions: [{ operator: '$in', key, value }],
    },
  ];
};

export const OrderByMap = {
  standardItemCode: 'standardItem',
  vehicleType: 'vehicleTypeTitle',
  description: 'standardItemTitle',
  vendor: 'vendor',
  manufacturer: 'make',
  model: 'model',
  openDate: 'openDate',
  closeoutDate: 'closedDate',
};

export const fitToColumn = (data, keys) => {
  if (!data || !keys) return [];
  // get maximum character of each column
  return keys.map((key) => ({
    wch: Math.max(
      key.length,
      ...data.map((item) => (item[key] ? item[key].toString().length + 2 : 0)),
    ),
  }));
};

export const VAL_KEYS_DATA = [
  'vehicleType',
  'standardItemCode',
  'description',
  'vendor',
  'manufacturer',
  'model',
  'openDate',
  'closeoutDate',
];

export const VAL_SPREADSHEET_COLS = [
  {
    A: 'Vehicle Type',
    B: 'Standard Item',
    C: 'Description',
    D: 'Vendor',
    E: 'Manufacturer',
    F: 'Model',
    G: 'Open Date',
    H: 'Closeout Date',
  },
];

export const exportFile = (format, listings) => {
  if (!Array.isArray(listings) || !listings.length || !format) return;
  const todayFormatted = new Intl.DateTimeFormat('en-US').format(new Date());
  const sheetHeader = `Vehicle Availability Listing (VAL) ${todayFormatted}`;
  const data = listings.map((row) => getTableColumns(row));
  const wb = XLSX.utils.book_new();

  /* Initial row */
  const ws =
    XLSX.utils.json_to_sheet([{ A: sheetHeader }], {
      skipHeader: true,
    }) || {};
  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];
  ws['!merges'] = merge;
  ws['!cols'] = fitToColumn(data, VAL_KEYS_DATA);
  XLSX.utils.sheet_add_json(ws, VAL_SPREADSHEET_COLS, {
    skipHeader: true,
    origin: 'A2',
  });

  /* Write data starting at row 3 (after the heading and column names) */
  XLSX.utils.sheet_add_json(ws, data, {
    header: VAL_KEYS_DATA,
    origin: 'A3',
    skipHeader: true,
  });
  if (format === 'csv') {
    XLSX.utils.sheet_to_csv(ws);
  }
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.write(wb, { bookType: format, type: 'binary' });
  XLSX.writeFile(wb, `vehicleAvailabilityListing.${format}`);
};
