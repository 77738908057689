import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import '../MasRequisition.scss';

const MasAdvModal = ({ masAdvModalCancel, masAdvModalConfirm }) => {
  return (
    <div className="afp-modal-overlay modalContainer mas-req-modal">
      <Modal
        className="mas-modal-req-main"
        data-testid="mas-nav-modal"
        onClose={masAdvModalCancel}
        title={<h1>Multiple Awards Scheduling (MAS)</h1>}
        actions={
          <>
            <Button
              data-testid="mas-nav-cancel"
              type="button"
              variant="unstyled"
              onClick={masAdvModalCancel}
              label="Cancel"
              className="mos-req-cancel"
            />
            <Button
              data-testid="mas-nav-confirm"
              type="button"
              variant="standard"
              onClick={masAdvModalConfirm}
              label="Continue MAS Requisition"
            />
          </>
        }
      >
        <div>
          <div className="mas-modal-text">
            MAS Transportation and Logistics Services - Motor Vehicles
            (non-combat){' ('}
            <br />
            <a href="https://www.gsaelibrary.gsa.gov/">gsaelibrary.gsa.gov</a>
            {') '}
            offers a wide variety of specialty vehicles and accessories:
            <br />
            <br />
            Fire Fighting Apparatus and Attachments
            <br />
            Law Enforcement Vehicles and Attachments
            <br />
            Special Vocational Vehicles and Attachments
            <br />
            Construction Equipment and Attachments
            <br />
            Snow Maintenance Equipment
            <br />
            Aircraft Ground Support Vehicles and Equipment
            <br />
            Unfitting Services
            <br />
            Low Speed Vehicles
            <br />
            Trailers and Attachments
            <br />
            Tires
            <br />
            Leased Heavy Duty Vehicle and Accessories
            <br />
            Automotive Body and Repair Services
            <br />
            Export Documentation Service (1611) is not available under all
            contracts. It is the customer&apos;s responsibility to verify 1611
            availability from each vendor and ensure all fees for Export
            Documentation Service are included in vendor quotes. Note that the
            Export Documentation Service fee only includes the cost of providing
            the documentation necessary for obtaining shipping instructions. The
            Export documentation services fee does not include the actual cost
            of shipping outside the continental US.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MasAdvModal;

MasAdvModal.propTypes = {
  masAdvModalCancel: PropTypes.func.isRequired,
  masAdvModalConfirm: PropTypes.func.isRequired,
};
