import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useTitle } from '@gsa/afp-shared-ui-utils';
import {
  FilterTableFrame,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import { read, utils, writeFile } from 'xlsx';
import moment from 'moment';
import MyOrdersProvider from './MyOrdersProvider';
import MyOrdersTable from './OrdersTable';
import './MyOrders.scss';
import OrdersFilters from './Filters/OrdersFilters';
import OrderFilterProvider from './providers/OrderFilterProvider';
import BulkUpdateButton from '../../components/BulkUpdateStatusVin/BulkUpdateButton';
import BulkUpdateModal from '../../components/BulkUpdateStatusVin/BulkUpdateModal';
import { canUpdateStatusOnOrderVehicle } from '../../utilities/authorization';
import { alertMap } from '../../components/BulkUpdateStatusVin/model/schema/variable-maps';
import { coercionString } from '../../utilities/commonUtils';
import { reportsConfig } from '../../components/BulkUpdateStatusVin/config';
import { useImport } from '../../components/BulkUpdateStatusVin/state/hook';
import ProcessImport from '../../components/BulkUpdateStatusVin/process-import';
import useAlertManager from '../../utilities/alert-manager';

const MyOrders = ({ setTab }) => {
  setTab('orders');
  const [UploadAlert, alertUtils] = useAlertManager(true, true, true);
  const [fileState, setFileState] = useState();
  const [fileImportErrorState, setFileImportErrorState] = useState('');
  const [currentDownloadStrategyState] = useState(reportsConfig.ORDERLINE);
  const {
    importedData,
    setMappedData,
    setProcessedResults,
    processedResponse,
    reset,
    setData,
  } = useImport();

  // useEffect(() => setTab('orders'), [setTab]);
  useTitle('Requisitions and Orders-Orders');
  const ability = useAppAbility();
  const createErrorFile = async () => {
    const reportConfig = reportsConfig.ORDERLINE;
    const fileName = `${reportConfig.errorFileName}_${moment()
      .utcOffset('-06:00')
      .format('MMDDYYYY_HHMMSS')}.xlsx`;
    const sortedResponse = [...processedResponse]?.sort((a, b) => a.id - b.id);
    const worksheet = utils.json_to_sheet(sortedResponse);
    worksheet['!cols'] = reportConfig.errorSheetColumnWidths;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, reportConfig.errorSheetName);
    utils.sheet_add_aoa(worksheet, [reportConfig.errorSheetHeaders]);
    writeFile(workbook, fileName);
  };

  useEffect(() => {
    if (processedResponse?.length > 0) {
      setMappedData([]);
      setProcessedResults([]);
      if (processedResponse?.some((resp) => resp?.status === 'Error')) {
        const errorCount = processedResponse?.filter(
          (resp) => resp?.status === 'Error',
        )?.length;
        const totalCount = processedResponse?.length;
        const processedRecords =
          totalCount > 1 ? `${totalCount} records` : `${totalCount} record`;
        const errorRecords =
          errorCount > 1 ? `${errorCount} records` : `${errorCount} record`;
        alertUtils.showErrorAlert(
          alertMap.validationError.getContent(
            createErrorFile,
            processedRecords,
            errorRecords,
          ),
          alertMap.validationError.heading,
        );
      } else {
        const report = reportsConfig.ORDERLINE;
        alertUtils.showSuccessAlert(
          alertMap.success.getContent(`${report?.label} (${report?.value})`),
        );
      }
    }
    return () => reset();
  }, [processedResponse]);

  const { isOpen, openModal, closeModal } = useModal();
  const VendorDownloadModal = connectModal(BulkUpdateModal);
  const openBulkUpdateModal = () => {
    openModal();
  };

  const viewVendorOrders = canUpdateStatusOnOrderVehicle(ability);

  const AFewButtons = () =>
    viewVendorOrders && (
      <div className="text-right">
        <BulkUpdateButton
          className="margin-right-2"
          onClick={openBulkUpdateModal}
        />
      </div>
    );

  const MyOrdersFrame = useMemo(
    () => FilterTableFrame(null, AFewButtons, OrdersFilters, MyOrdersTable),
    [],
  );

  const handleUpload = async () => {
    if (!fileState) {
      setFileImportErrorState('This is a required field');
      return;
    }
    if (fileImportErrorState) {
      return;
    }

    const configHeaders = currentDownloadStrategyState.mapping.map((m) => m[0]);
    // Read the meta
    const data = await fileState.arrayBuffer();
    const wb = read(data, { cellDates: true, dateNF: 'mm/dd/yyyy' });

    const rawJson = utils.sheet_to_json(
      wb.Sheets[currentDownloadStrategyState?.dataSheet],
      { raw: false, header: configHeaders },
    );

    // validate headers
    const headerObject = rawJson?.[0];
    const mismatchedHeader =
      headerObject &&
      Object.keys(headerObject).find(
        (k) =>
          headerObject[k].trim().replace('*', '') !== k.trim().replace('*', ''),
      );
    if (mismatchedHeader || !headerObject) {
      alertUtils.showErrorAlert(
        alertMap.templateError.getContent(currentDownloadStrategyState?.label),
      );
      setFileState(null);
      closeModal();
      return;
    }

    let processError = false;

    const processedData = rawJson.slice(1).map((rowJson) => {
      const row = {};
      // map the data to the expected format
      try {
        currentDownloadStrategyState.mapping.forEach((value) => {
          const [colLabel, colProp, colType] = value;
          let rawVal = rowJson[colLabel];
          if (rawVal) {
            rawVal = rawVal.trim();
          } else {
            rawVal = '';
          }
          switch (colType) {
            case 'string':
              row[colProp] = coercionString(rawVal);
              break;
            case 'date':
              if (rawVal.length > 0) row[colProp] = new Date(rawVal);
              else row[colProp] = undefined;
              break;
            default:
              row[colProp] = rawVal;
          }
        });
      } catch (err) {
        closeModal();
        processError = true;
      }
      return row;
    });

    if (!processedData || !processedData.length || processError) {
      alertUtils.showErrorAlert(
        alertMap.templateError.getContent(currentDownloadStrategyState?.label),
      );
      closeModal();
    }
    // set the processed data to the state
    if (!processError) {
      closeModal();
      setData(processedData);
      setFileState(null);
    }
  };

  return (
    <MyOrdersProvider>
      {isOpen && (
        <VendorDownloadModal
          isOpen={isOpen}
          onClose={closeModal}
          cancel={closeModal}
          handleUpload={handleUpload}
          fileState={fileState}
          currentDownloadStrategyState={currentDownloadStrategyState}
          setFileState={setFileState}
          setFileImportErrorState={setFileImportErrorState}
          fileImportErrorState={fileImportErrorState}
        />
      )}
      <div className="my-orders">
        <h2 className="usa-h2">Orders</h2>

        <OrderFilterProvider>
          <UploadAlert />
          {importedData?.length > 0 && (
            <ProcessImport
              useDataProcessor={reportsConfig.ORDERLINE.processorHook}
            />
          )}
          <MyOrdersFrame filterToggle upRightProps={<AFewButtons />} />
        </OrderFilterProvider>
      </div>
    </MyOrdersProvider>
  );
};

MyOrders.propTypes = {
  setTab: PropTypes.func.isRequired,
};

export default MyOrders;
