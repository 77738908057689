export const getUserScopeLevel = (userAbilities, operation, subject) => {
  let userScope = 'NONE';
  if (userAbilities && userAbilities.length === 1) {
    const currentUserAbility = userAbilities[0];
    if (currentUserAbility[0] === 'manage' && currentUserAbility[1] === 'All') {
      userScope = 'ROOT';
    }
  }
  if (userAbilities && userAbilities.length) {
    const filteredAbilities = userAbilities.filter(
      (ability) => ability[0] === operation && ability[1] === subject,
    );
    const officeLevelAbility = filteredAbilities.find(
      (ability) => ability[2]?.serviceObjectScopes?.$elemMatch?.scopeLevel3,
    );
    const bureauLevelAbility = filteredAbilities.find(
      (ability) => ability[2]?.serviceObjectScopes?.$elemMatch?.scopeLevel2,
    );
    const agencyLevelAbility = filteredAbilities.find(
      (ability) => ability[2]?.serviceObjectScopes?.$elemMatch?.scopeLevel1,
    );
    if (agencyLevelAbility && !bureauLevelAbility && !officeLevelAbility) {
      userScope = 'AGENCY';
    }
    if (agencyLevelAbility && bureauLevelAbility && !officeLevelAbility) {
      userScope = 'BUREAU';
    }
    if (agencyLevelAbility && bureauLevelAbility && officeLevelAbility) {
      userScope = 'OFFICE';
    }
  }
  return userScope;
};

export default getUserScopeLevel;
