import React from 'react';
import PropTypes from 'prop-types';
import WarningSvg from '../../../../assets/images/warning-icon.svg';
import { STEPS, NON_SOP_MAS_DESC } from '../constants';

import VehicleRequirements from '../vehicle-requirements/VehicleRequirements';
import RequisitionAgencyInfo from '../../shared/agencyInfo/ReqAgencyInfo';
import RequisitionDeliveryAddress from '../../shared/deliveryAddress/ReqDeliveryAddress';
import MasRequisitionReviewPage from '../reviewAndSubmit/MasRequisitionReviewPage';

const VehicleInformation = () => {
  return (
    <>
      <div className="non-sop-left-panel">
        <div className="panel-container">
          <div className="card-title">
            <img src={WarningSvg} alt="warning icon" />
            <b>This is a Multiple Award Schedules (MAS) Vehicle Requirement</b>
          </div>
          {NON_SOP_MAS_DESC} <br />
        </div>
      </div>
    </>
  );
};

const StepDetailsComponent = ({ step, handlePageNavigation }) => {
  switch (step) {
    case STEPS.MAS_VEHICLE_REQUIREMENTS:
      return (
        <div
          className="mas-req-step-container"
          data-testId="mas-vehicle-req-step"
        >
          <VehicleInformation />
          <div className="mas-req-right-container">
            <VehicleRequirements handlePageNavigation={handlePageNavigation} />
          </div>
        </div>
      );
    case STEPS.AGENCY_INFORMATION:
      return (
        <div
          className="mas-req-step-container"
          data-testId="mas-agency-req-step"
        >
          <VehicleInformation />
          <div className="mas-req-right-container">
            <RequisitionAgencyInfo
              handlePageNavigation={handlePageNavigation}
            />
          </div>
        </div>
      );

    case STEPS.DELIVERY_ADDRESS:
      return (
        <div
          className="mas-req-step-container"
          data-testId="mas-address-req-step"
        >
          <VehicleInformation />
          <div className="mas-req-right-container">
            <RequisitionDeliveryAddress
              handlePageNavigation={handlePageNavigation}
              isMas
            />
          </div>
        </div>
      );

    case STEPS.REVIEW_SUBMIT:
      return (
        <div
          className="mas-req-step-container"
          data-testId="mas-review-req-step"
        >
          <VehicleInformation />
          <div className="mas-secondary-req-right-container">
            <MasRequisitionReviewPage
              handlePageNavigation={handlePageNavigation}
            />
          </div>
        </div>
      );

    default:
      return null;
  }
};

StepDetailsComponent.propTypes = {
  step: PropTypes.string.isRequired,
  handlePageNavigation: PropTypes.func.isRequired,
};

export default StepDetailsComponent;
