import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { StepIndicator, Alert, TabSet } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';
import {
  STEPS,
  URGENT_REQ_VEH_DESC,
  AVAILABLE_EDIT_STEPS,
  REQUISITION_TYPE,
} from '../constants';
import WarningSvg from '../../../assets/images/warning-icon.svg';
import VehicleSpecification from './vehicleSpec/VehicleSpecifications';
import '../NonStandardOrdering.scss';
import RequirementJustification from './justification/RequirementJustification';
import UrgentRequisitionAgencyInfo from '../shared/agencyInfo/ReqAgencyInfo';
import UrgentRequisitionDeliveryAddress from '../shared/deliveryAddress/ReqDeliveryAddress';
import UrgentRequisitionReviewPage from './reviewAndSubmit/UrgentReqReviewPage';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import RequisitionActivitiesTab from '../../ReviewDetails/tabs/RequisitionActivitiesTab';
import AttachmentTable from '../../ReviewDetails/Attachment/AttachmentTable/AttachmentTable';
import { useQueryParam } from '../../../hooks/useQueryParam';
import RequisitionStatusBadge from '../../../utilities/RequisitionStatusBadge';

const VehicleInformation = () => {
  return (
    <div className="non-sop-left-panel">
      <div className="panel-container">
        <div className="card-title">
          <img src={WarningSvg} alt="warning icon" />
          <b> This is an Urgent Requirement</b>
        </div>
        {URGENT_REQ_VEH_DESC} <br />
      </div>
    </div>
  );
};

export const StepDetailsComponent = ({ step, handlePageNavigation }) => {
  switch (step) {
    case STEPS.VEHICLE_SPECIFICATIONS:
      return (
        <VehicleSpecification handlePageNavigation={handlePageNavigation} />
      );
    case STEPS.URGENT_REQUIREMENT_JUSTIFICATION:
      return (
        <RequirementJustification handlePageNavigation={handlePageNavigation} />
      );
    case STEPS.AGENCY_INFORMATION:
      return (
        <UrgentRequisitionAgencyInfo
          handlePageNavigation={handlePageNavigation}
        />
      );
    case STEPS.DELIVERY_ADDRESS:
      return (
        <UrgentRequisitionDeliveryAddress
          handlePageNavigation={handlePageNavigation}
        />
      );
    case STEPS.REVIEW_SUBMIT:
      return (
        <UrgentRequisitionReviewPage
          handlePageNavigation={handlePageNavigation}
        />
      );
    default:
      return null;
  }
};

StepDetailsComponent.propTypes = {
  step: PropTypes.string.isRequired,
  handlePageNavigation: PropTypes.func.isRequired,
};

let updatedStep = {};

const UrgentRequisitionSteps = () => {
  const { dispatch, state, updateDraftRequisition } = useContext(
    VehicleRequisitionContext,
  );
  const {
    urgentReqErrorBanner,
    urgentRequirementCurrentStep,
    draftRequisition,
    urgentReqStepsProcessIndicator,
  } = state;
  const navigate = useNavigate();
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const currentTab = 'Review summary';
  const [tab, setTab] = useState('summary');

  const handleStepper = (nextStep) => {
    const isIndex = Number.isInteger(nextStep); // can pass step key or index to handler
    const nextStepIndex = isIndex
      ? nextStep
      : urgentReqStepsProcessIndicator.findIndex(({ key }) => key === nextStep); // sets nextStepIndex
    const newStep = isIndex
      ? urgentReqStepsProcessIndicator[nextStep]
      : urgentReqStepsProcessIndicator.find(({ key }) => key === nextStep); // sets newStep object
    updatedStep = {
      key: newStep?.key,
      current: newStep?.stepNumber,
      text: newStep?.label,
    };

    const updatedIndicatorSteps = urgentReqStepsProcessIndicator;
    for (let i = 0; i < updatedIndicatorSteps.length; i++) {
      if (i < nextStepIndex) {
        updatedIndicatorSteps[i].status = 'completed';
      } else if (i === nextStepIndex) {
        updatedIndicatorSteps[i].status = 'current';
      } else {
        updatedIndicatorSteps[i].status = 'not completed';
      }
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_CURRENT_STEP,
      payload: updatedStep,
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_STEPS_INDICATOR,
      payload: updatedIndicatorSteps,
    });

    updateDraftRequisition(undefined, undefined, { urgent: updatedStep });
  };

  const handlePageNavigation = (direction) => {
    if (direction === 'continue') {
      handleStepper(urgentRequirementCurrentStep.current);
    } else if (
      direction === AVAILABLE_EDIT_STEPS.find((step) => step === direction)
    ) {
      // Edit from Review and submit page
      const step = direction;
      handleStepper(step - 1);
    } else {
      const currentStepIndex = urgentReqStepsProcessIndicator.findIndex(
        (step) => step.stepNumber === urgentRequirementCurrentStep.current,
      );
      const nextStepIndex = currentStepIndex - 1;
      if (nextStepIndex >= 0) {
        handleStepper(nextStepIndex);
      } else {
        navigate('/urgent-requisition');
        window.location.reload(false);
      }
    }
  };

  const key = useMemo(() => {
    return urgentRequirementCurrentStep?.key;
  }, [urgentRequirementCurrentStep?.key]);

  const renderWithTabs = () => {
    const checkRenderComponents =
      draftRequisition?.requisitionStatus === 'RETURNED'
        ? [
            {
              heading: 'Review summary',
              tabSelectedWhenOpen:
                tab === 'summary' && currentTab !== 'activities',
              content: (
                <div className="urgent-req-steps">
                  <VehicleInformation />
                  <div className="non-sop-right-panel">
                    <StepDetailsComponent
                      step={`${key}`}
                      handlePageNavigation={handlePageNavigation}
                    />
                  </div>
                </div>
              ),
            },
            {
              heading: 'Activity',
              tabSelectedWhenOpen:
                currentTab === 'activities' || tab === 'activities',
              content: (
                <RequisitionActivitiesTab requisitionId={requisitionId} />
              ),
            },
            {
              heading: 'Attachments',
              tabSelectedWhenOpen: tab === 'attachments',
              content: (
                <AttachmentTable
                  requisitionId={requisitionId}
                  setTab={setTab}
                  uploadAttachment={false}
                  reqType={REQUISITION_TYPE.URGENT_REQUIREMENT}
                />
              ),
            },
          ]
        : [
            {
              heading: 'Review summary',
              content: (
                <div className="urgent-req-steps">
                  <VehicleInformation />
                  <div className="non-sop-right-panel">
                    <StepDetailsComponent
                      step={`${key}`}
                      handlePageNavigation={handlePageNavigation}
                    />
                  </div>
                </div>
              ),
            },
            {
              heading: 'Attachments',
              content: (
                <AttachmentTable
                  requisitionId={requisitionId}
                  setTab={setTab}
                  uploadAttachment={false}
                  reqType={REQUISITION_TYPE.URGENT_REQUIREMENT}
                />
              ),
            },
          ];
    return (
      <>
        <div className="requisition-details-tabs">
          <TabSet tabs={checkRenderComponents} />
        </div>
      </>
    );
  };

  return (
    <>
      {urgentReqErrorBanner && (
        <Alert slim type="error">
          <div className="error-banner-title">This Page contains errors</div>
          Please address all fields outlined in red before proceeding to the
          next step.
        </Alert>
      )}
      {draftRequisition?.requisitionId && (
        <div className="draft-section">
          <div className="urg-req-draft-title-section">
            <span className="draft-title">{draftRequisition.friendlyName}</span>
            <RequisitionStatusBadge
              value={draftRequisition?.requisitionStatus}
            />
          </div>

          <div>
            Your edits are saved on clicking next button on each step
            {/* Your latest edits are */}
            <span className="save-draft-requisition-post-save">
              <GiCheckMark />
            </span>
          </div>
        </div>
      )}
      <div>
        <StepIndicator
          className="non-sop-step-indicator"
          counterSize="big"
          heading={urgentRequirementCurrentStep}
          onClick={() => {}}
          steps={urgentReqStepsProcessIndicator}
        />
        {urgentRequirementCurrentStep?.current === 5 ? (
          renderWithTabs()
        ) : (
          <div className="urgent-req-steps">
            <VehicleInformation />
            <div className="non-sop-right-panel">
              <StepDetailsComponent
                step={`${key}`}
                handlePageNavigation={handlePageNavigation}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UrgentRequisitionSteps;
