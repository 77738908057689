import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Link as GoToLink,
  Spinner,
} from '@gsa/afp-component-library';
import Moment from 'moment';

import OrderAttachmentUpload from './OrderAttachmentUpload';
import './OrderAttachments.scss';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import {useQuery} from "@apollo/client";
import {useDispatch, useSelector} from "react-redux";
import { setOrdersTab} from "../../../reducers/order.jsx";
import {getOrderAttachmentTypes} from "../../../requests/order.jsx";

const OrderAttachments = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOrdersTab('orderAttachments'));
    dispatch(getOrderAttachmentTypes());

  }, []);
  //const [orderAttachmentsData, setOrderAttachmentsData] = useState([]);
  const [sort, setSort] = useState([['createdAt', 'ASC']]);
 // const [orderAttachmentTypes, setOrderAttachmentTypes] = useState([]);
 const { orderAttachmentsInProgress , orderAttachments} = useSelector((state) => state.orderReducer);

  const orderAttachmentsData = useMemo(() => {
      if(orderAttachments && orderAttachments?.orderAttachmentsData){
          return orderAttachments?.orderAttachmentsData;
      }

  }, [orderAttachments])

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Files',
        accessor: 'type',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.fileMetadata?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Viewable by',
        accessor: 'isInternal',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) =>
          // eslint-disable-next-line react/prop-types
          original?.isInternal ? 'GSA only' : 'All',
      },
      {
        Header: 'Uploaded by',
        accessor: 'createdUserInfo',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            // eslint-disable-next-line react/prop-types
            <GoToLink href={`mailto:${original.createdUserInfo?.email}`}>
              {/* eslint-disable-next-line react/prop-types */}
              {original.createdUserInfo?.fullName}
            </GoToLink>
          );
        },
      },
      {
        Header: 'Date / Time',
        accessor: 'createdAt',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <>
              {/* eslint-disable-next-line react/prop-types */}
              {Moment(original.createdAt).format('MM/DD/YYYY')} &#8226;{' '}
              {/* eslint-disable-next-line react/prop-types */}
              {Moment(original.createdAt).format('hh:mm A')} ET
            </>
          );
        },
      },
      {
        Header: 'File category',
        accessor: 'attachmentType',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.attachmentType?.description,
      },
      {
        Header: 'Comments',
        accessor: 'description',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.description,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        // eslint-disable-next-line react/prop-types
        Cell: (props) => <AFPTableRowAction actions={[]} {...props} />,
      },
    ],
    [],
  );

  const setTableSort = (newSort) => {
    setSort([newSort.split(' ')]);
  };


  const OrderAttachmentsTable = () => (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={orderAttachmentsData || []}
        onSort={setTableSort}
        defaultSort={sort}
      />
      {orderAttachmentsInProgress && <Spinner aria-busy="true" size="medium" />}
      {!orderAttachmentsInProgress && !orderAttachmentsData?.length && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No attachments found for this order"
        />
      )}
    </>
  );

  return (
    <div className="afp-order-attachments-container">
      <div className="afp-order-attachments-container-header">
        <div className="afp-order-attachments-container-header-text">
          Below are all files associated with this order. You may see who
          uploaded the attachment, when, what type of order event category the
          attachment falls within, and optional details.
        </div>
        <OrderAttachmentUpload />
      </div>
      <OrderAttachmentsTable />
    </div>
  );
};

export default OrderAttachments;
