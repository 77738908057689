import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {useAppAbility, useCurrentUser} from '@gsa/afp-shared-ui-utils';
import { STEPS } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import COApproveButton from './COApproveButton/COApproveButton';
import ReturnOrderModal from './ReturnOrderModal';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import {UserRoles} from '../../constants/user-constants';

const CoActionButtons = ({
  requisitionId,
  requisitionCartState,
  canSubmitOrder,
  reference,
  isVendorDirect,
  currentStandardItem,
}) => {
  const ability = useAppAbility();
  const isContractingOfficer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Solicitation),
    [ability],
  );
  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );
  const { requisitionStatus: status } = requisitionCartState;
  const finalApproval =
        isContractingOfficer && status === RequisitionStatus.FINAL_APPROVAL;
  const coReviewing =
      (isContractingOfficer || isOrderingAdmin) && status === RequisitionStatus.CONTRACTING_REVIEW;
  const coApproval =
      (isContractingOfficer || isOrderingAdmin)  && status === RequisitionStatus.CONTRACTING_APPROVAL;
  const isReqInFinalApproval = (status === RequisitionStatus.FINAL_APPROVAL);
  const { state: errorState } = useContext(ErrorContext);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);
    }
  }, [errorState]);

  if (!(coReviewing || coApproval || finalApproval)) {
    return null;
  }

  if(isReqInFinalApproval && isContractingOfficer){
     return null;
  }
  return (
    <>
      <ReturnOrderModal
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
        requisitionCartValidations={null}
        canSubmitOrder={canSubmitOrder}
        visible
        status={status}
        requisitionCartState={requisitionCartState}
        currentStandardItem={currentStandardItem}
        isVendorDirect={isVendorDirect}
      />

      {errorList.length === 0 && (
        <>
          <COApproveButton
            reference={reference}
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
            status={status}
            requisitionCartState={requisitionCartState}
          />
        </>
      )}
    </>
  );
};

CoActionButtons.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
  isVendorDirect: PropTypes.bool.isRequired,
  currentStandardItem: PropTypes.instanceOf(Object),
};
CoActionButtons.defaultProps = {
    currentStandardItem: undefined,
}
export default CoActionButtons;
