import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Alert } from '@gsa/afp-component-library';
import './TechnicalEvaluationReadOnly.scss';

const TechnicalEvaluationReadOnly = ({ vendorQuote }) => {
  const { isAcceptable, engineerComments } = vendorQuote;

  return (
    <div className="technical-evaluation-read-only">
      {isAcceptable || (
        <div className="bottom-spacer">
          <Alert type="error" slim width="700">
            Vendor bids marked &quot;unacceptable&quot;{' '}
            <span className="not-included">will not be included</span> for
            review/ selection by the customer.
          </Alert>
        </div>
      )}

      <div className="title">Engineer&apos;s technical acceptance</div>

      <fieldset disabled>
        <RadioButton label="Acceptable" checked={isAcceptable} />
        <RadioButton label="Unacceptable" checked={!isAcceptable} />
      </fieldset>

      <div className="comments-title">
        {isAcceptable ? 'Comments' : 'Reason'}
      </div>
      <div className="comments-body">{engineerComments}</div>
    </div>
  );
};

TechnicalEvaluationReadOnly.propTypes = {
  vendorQuote: PropTypes.instanceOf(Object).isRequired,
};

export default TechnicalEvaluationReadOnly;
