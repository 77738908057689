import { useState } from 'react';
import { TabSet, PageTitle } from '@gsa/afp-component-library';
import OptionSelection from "./option-selection";

const VEHICLE_REQUEST_TAB = {
  SELECTION_DETAILS: 'selectionDetails',
  ACTIVITY: 'activity',
};

export default function VehicleTabs() {
  const [tab, setTab] = useState(VEHICLE_REQUEST_TAB.SELECTION_DETAILS);
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <TabSet
      tabs={[
        {
          heading: 'Selection details',
          tabSelectedWhenOpen: tab === VEHICLE_REQUEST_TAB.SELECTION_DETAILS,
          content: <OptionSelection selectedOption={selectedOption} setSelectedOption={setSelectedOption} />,
        },
        {
          heading: 'Activity and comments',
          tabSelectedWhenOpen: tab === VEHICLE_REQUEST_TAB.ACTIVITY,
          content: <PageTitle title="Activity comments" />,
        },
      ]}
    />
  );
}
