import React from 'react';
import { Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import './VendorQuoteApproved.scss';

const VendorQuoteApproved = ({ vendorQuote }) => {
  const { engineerComments = '' } = vendorQuote;

  return (
    <div className="vendor-quote-approved">
      <div className="comments-body">
        <div className="quote-approved">
          <Icon iconName="check" className="usa-icon--size-4 text-primary" />
          <div>Vendor quote approved by GSA Engineering</div>
        </div>
        {engineerComments && (
          <>
            <div className="comments-title">Comments</div>
            <div className="comments-content">{engineerComments}</div>
          </>
        )}
      </div>
    </div>
  );
};

VendorQuoteApproved.propTypes = {
  vendorQuote: PropTypes.instanceOf(Object).isRequired,
};

export default VendorQuoteApproved;
