import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

import { Link } from 'react-router-dom';

const Breadcrumbs = () => (
  <DSBreadcrumbs
    trail={[
      <Link key="store_home" to="/">
        Home
      </Link>,
      <Link key="vehicle_reports_manager" to="/my-requisitions">
        Vehicle Reports Manager
      </Link>,
    ]}
    current="Vehicle Reports"
  />
);
export default Breadcrumbs;
