import './requisition-layout.scss';

export default function RequisitionLayout({ left, right }) {
    return (
        <div className="requisition-layout">
            {left && (
                <div className="requisition-layout-left">
                    {left}
                </div>
            )}
            <div className="requisition-layout-right">
                {right}
            </div>
        </div>
    )
}