import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useModal,
  connectModal,
  EmptyState,
  AFPTableRowAction,
  AFPTable,
} from '@gsa/afp-component-library';
import UploadNewFileModal from './UploadNewFileModal/UploadNewFileModal';
import DeleteAdditionalReqFileModal from '../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import EditAttachmentModal from './EditAttachmentModal';

const ModificationFileUploadTable = ({
  reqFiles,
  append,
  remove,
  update,
  reqType,
}) => {
  const [deleteRowIndex, setDeleteRowIndex] = useState(-1);
  const [rowToUpdate, setRowToUpdate] = useState(null);

  const uploadNewFileModal = useModal();
  const DisplayUploadFileModal = connectModal(UploadNewFileModal);

  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const updateFileModal = useModal();
  const DisplayUpdateFileModal = connectModal(EditAttachmentModal);

  const actionList = [
    {
      icon: 'edit',
      label: 'Edit file documentation',
    },
    {
      icon: 'delete',
      label: 'Delete file',
    },
  ];

  const handleDelete = () => {
    remove(deleteRowIndex);
    deleteFileModal.closeModal();
  };

  const handleSelectedRow = (event, row) => {
    setRowToUpdate(row);
    if (event === 'Delete file') {
      setDeleteRowIndex(row.original.orderModAttachmentId);
      deleteFileModal.openModal();
    } else if (event === 'Edit file documentation') {
      updateFileModal.openModal();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.fileMetadata?.name;
        },
      },
      {
        Header: 'Document',
        accessor: 'document',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.attachmentType?.type;
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.description;
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        // eslint-disable-next-line react/prop-types
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={actionList}
              onSelectAction={(evt) => handleSelectedRow(evt, row)}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <div className="attachments-container">
        <div className="file-attachments-top">
          <Button
            onClick={() => uploadNewFileModal.openModal()}
            type="button"
            variant="outline"
            leftIcon={{ name: 'add', className: 'plus-icon' }}
            label="Upload file"
          />
        </div>

        <AFPTable
          testId="additional-requirements-files-table"
          columns={columns}
          data={reqFiles || []}
        />

        {reqFiles && reqFiles?.length === 0 && (
          <EmptyState
            hasBackground
            containerStyles="margin-top-neg-2 padding-y-10"
            topText="No files uploaded"
          />
        )}
      </div>

      <DisplayUploadFileModal
        isOpen={uploadNewFileModal.isOpen}
        handleClose={uploadNewFileModal.closeModal}
        append={append}
        reqType={reqType}
      />

      <DisplayDeleteFileModal
        isOpen={deleteFileModal.isOpen}
        handleClose={deleteFileModal.closeModal}
        handleDelete={handleDelete}
        filename={rowToUpdate?.original?.name}
      />

      <DisplayUpdateFileModal
        isOpen={updateFileModal.isOpen}
        handleClose={updateFileModal.closeModal}
        rowToEdit={rowToUpdate}
        update={update}
      />
    </>
  );
};

ModificationFileUploadTable.propTypes = {
  reqFiles: PropTypes.instanceOf(Array).isRequired,
  append: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  row: PropTypes.instanceOf(Object),
  reqType: PropTypes.string,
};

ModificationFileUploadTable.defaultProps = {
  reqType: '',
  row: {
    original: {
      signedUrl: '',
      name: '',
    },
  },
};

export default ModificationFileUploadTable;
