import React, { useEffect, useState } from 'react';
import { Checkbox } from '@gsa/afp-component-library';
import useBulkOrderModsState from './useBulkOrderModsState';

const DisabledOrderStatus = 'CANCELLED';
const DisabledOrderModStatus = ['ATTACH_TO_MOD', 'DRAFT_MOD'];

export default function SelectAllOrderMods() {
  const { selectedOrdersForMod, setSelectedOrdersForMod, orderModRows } =
    useBulkOrderModsState();
  const selectableOrders = orderModRows.filter(
    (order) =>
      order.status !== DisabledOrderStatus &&
      !DisabledOrderModStatus.includes(order.modificationStatus),
  );
  const [checked, setChecked] = useState(
    selectedOrdersForMod?.length === selectableOrders?.length,
  );
  useEffect(() => {
    if (
      selectableOrders?.length > 0 &&
      selectedOrdersForMod?.length === selectableOrders?.length
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedOrdersForMod, orderModRows]);

  const updateSelectedOrders = (value) => {
    if (value) {
      setSelectedOrdersForMod([...selectableOrders]);
    } else {
      setSelectedOrdersForMod([]);
    }
    setChecked(value);
  };

  return (
    <Checkbox
      className="select-orders-checkbox"
      name="test"
      checked={checked}
      aria-label="select all checkbox"
      onChange={(e) => updateSelectedOrders(e.target.checked)}
    />
  );
}
