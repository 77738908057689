import {
    ApolloClient,
    createHttpLink,
    from,
    gql,
    InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {getToken} from '@gsa/afp-shared-ui-utils';
import {onError} from '@apollo/client/link/error';

const httpLink = createHttpLink({
    uri: window.AFP_CONFIG.api_url,
});

const authLink = setContext(async (_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = await getToken();
    if (process.env.NODE_ENV !== 'test')
        // console.log('token', token);
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',
            },
        };
    return {};
});

const dataStore = ({setSystemAlert}) => {
    const errorLink = onError(({graphQLErrors, networkError, ...rest}) => {
        // Added Check to avoid "Something went wrong" banner, when getAllOrdersForUser error return invalid date
        if (
            graphQLErrors &&
            (graphQLErrors[0]?.message === "Incorrect DATE value: 'Invalid date'" ||
                graphQLErrors[0]?.message === 'UserInputError: Address Not Found.' ||
                graphQLErrors[0]?.message ===
                'The order modification name you entered already exists. Please try another.')
        ) {
            return;
        }

        const IGNORE_ERROR_TYPES = [
          'SubmitRequisitionFundingError'
        ];

        if (graphQLErrors || networkError) {
          // ignore certain types of thrown errors coming from the backend so that we can
          // handle the errors in a more localized scope instead of catching them globally
          // and showing the generic error message
          const hasIgnoredErrorType = graphQLErrors?.some((error) =>
            IGNORE_ERROR_TYPES.includes(error?.extensions?.exception?.name),
          );
          if (hasIgnoredErrorType) return;

          setSystemAlert({
              type: 'error',
              heading: 'Something went wrong',

              // we can expand on the errors to help us out when we set the showGraphqlErrors "env" variable.
              // when we have the  otherwise this will just show the generic error message
              errorHint: window.AFP_CONFIG.showGraphqlErrors
                  ? [
                  ...new Set(
                      graphQLErrors?.map((item) => item?.message).filter(Boolean),
                  ),
              ] || networkError?.message
                  : null,
          });
        }
    });

    return new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache({addTypename: false}),
        connectToDevTools: window.AFP_CONFIG.connectToDevTools || false,
    });
};

export const CURRENT_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

export const VEHICLE_LISTING = gql`
  query SearchStandardItems(
    $standardItemSubCategoryCode: [String!]!
    $standardItemType: [StandardItemFilteredField!]!
    $offset: Int
    $limit: Int
    $order: OrderBy!
  ) {
    searchStandardItems(
      limit: $limit
      offset: $offset
      order: $order
      filters: [
        {
          operator: "$or"
          filterBy: "subCategory"
          conditions: [
            {
              operator: "$exact"
              key: "standardItemSubCategoryCode"
              value: $standardItemSubCategoryCode
            }
          ]
        }
        {
          operator: "$or"
          filterBy: "standardItemType"
          conditions: $standardItemType
        }
      ]
    ) {
      count
      filterValues {
        standardItemSubCategories {
          standardItemSubCategoryCode
          standardItemSubCategoryName
        }
        standardItemTypes {
          standardItemTypeCode
          standardItemTypeName
        }
      }
      rows {
        standardItemCode
        standardItemName
        activeContracts
        maxOrderStartDate
        standardItemTypeCode
        standardItemType {
          standardItemTypeName
          standardItemSubCategoryCode
          standardItemSubCategory {
            standardItemSubCategoryCode
            standardItemSubCategoryName
          }
        }
      }
      hasMore
    }
  }
`;

export const VEHICLE_LISTING_FILTERS_ONLY = gql`
  query SearchStandardItems(
    $standardItemSubCategoryCode: [String!]!
    $standardItemType: [StandardItemFilteredField!]!
    $offset: Int
    $limit: Int
    $order: OrderBy!
  ) {
    searchStandardItems(
      limit: $limit
      offset: $offset
      order: $order
      filters: [
        {
          operator: "$or"
          filterBy: "subCategory"
          conditions: [
            {
              operator: "$exact"
              key: "standardItemSubCategoryCode"
              value: $standardItemSubCategoryCode
            }
          ]
        }
        {
          operator: "$or"
          filterBy: "standardItemType"
          conditions: $standardItemType
        }
      ]
    ) {
      filterValues {
        standardItemSubCategories {
          standardItemSubCategoryCode
          standardItemSubCategoryName
        }
        standardItemTypes {
          standardItemTypeCode
          standardItemTypeName
        }
      }
    }
  }
`;

export const GET_STANDARD_ITEM = gql`
  query GetStandardItem($standardItemNumber: String!) {
    getStandardItem(standardItemNumber: $standardItemNumber) {
      standardItemName
      standardItemCodeFASTDescription
      standardItemCode
      fedStandardCode
      standardItemTypeCode
      standardItemType {
        standardItemSubCategoryCode
      }
      content {
        content
      }
    }
  }
`;

export const GET_MIN_REQ = gql`
  query GetMinReq($standardItemId: Float!) {
    getMinRequiredOptions(standardItemId: $standardItemId) {
      equipmentCode {
        code
        category
        categoryCode {
          code
          title
          additionalProps
        }
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
        title
        unit
        quantityRequired
      }

      equipmentCodeId
      unitCode
      comments {
        comment
      }
      associationTypeCode
      associationText
    }
  }
`;

export const GET_OPTIONAL_REQ = gql`
  query GetOptionalReq($standardItemId: Float!) {
    getOptionalOptions(standardItemId: $standardItemId) {
      equipmentCode {
        code
        category
        tags
        categoryCode {
          code
          title
          additionalProps
        }
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
        title
        unit
        quantityRequired
      }
      equipmentCodeId
      unitCode
      comments {
        comment
      }
      associationTypeCode
    }
  }
`;

export const GET_FEATURE_OPTIONS = gql`
  query GetFeatureOptions($standardItemId: Float!) {
    getFeatureOptions(standardItemId: $standardItemId) {
      equipmentCode {
        code
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
        title
        unit
        quantityRequired
      }
      equipmentCodeId
      unitCode
      comments {
        comment
      }
      associationTypeCode
    }
  }
`;

export const GET_ACTIVE_CONTRACTS = gql`
  query StoreGetActiveSinContracts($standardItemId: Int!, $transactionType: String) {
    storeGetActiveSinContracts(standardItemId: $standardItemId, transactionType: $transactionType) {
      id
      scheduleLine
      scheduleLineVersion
      shipmentDays
      orderStartDate
      orderEndDate
      originCity
      originStateId
      originStateCode
      originStateName
      modelYear
      makeCode
      makeName
      modelCode
      modelName
      chassisMakeCode
      chassisMakeName
      chassisModelCode
      chassisModelName
      fob
      isVendorDirect
      standardItem {
        id
        standardItemNumber
        title
        vehicleTypeTitle
      }
      contractMod {
        id
        contractVersion
        contract {
          id
          contractNumber
          contractUpiid
          awardedDate
          estimatedUnits
          solicitationNumber
          customerSurchargeRate
          contractStartDate
          contractEndDate
          contractingOfficerName
          contractingOfficerEmail
          allowCustomerOrdering
          vendor {
            id
            vendorName
            fleetVendorNumber
            smallBusinessFlag
          }
        }
      }
      financial {
        id
        vendorUnitPrice
        purchaseRate
        unitPrice
        msrp
        invoicePrice
        afvBase
      }
      engine {
        engineCode
        cylinders
        engineLitres
        horsepower
        fuelCode
        fuelType
        convMpgCity
        convMpgHighway
        convMpgCombined
        convGpm
        altMpgCity
        altMpgHighway
        altMpgCombined
        altGpm
        elecMpgCity
        elecMpgCighway
        elecMpgCombined
        range
        rangeElectric
      }
      equipment {
        id
        equipmentCode
        title
        vendorUnitPrice
        unitPrice
        afvByEquipment
        additionalShippingDays
        value
        inputType
        optionType
        siecId
        content
        relatedEquipment {
          id
          equipmentCode
          relatedEquipmentCode
          relatedTitle
          relationshipCode
          sequenceNumber
          siecId
          content
          isRequiredOptionCascadeRelationShip
        }
        clarifications {
          type
          clarification
        }
      }
      clarifications {
        type
        clarification
        sequenceNumber
      }
       attachments {
         id
         name
         description
         attachmentType
         documentMetadataId
         sequenceNumber
         documentMetadata {
           name
           description
           docStoreUri
           fileLocation
           fileMimeType
         }
       }
    }
  }
`;

export const GET_PURCHASE_COLLISION_FOR_ALL_VENDOR = gql`
  query Purchase(
    $standardItemId: Float!
    $selectedOptions: [SelectedOptionArg!]
    $affectedOption: SelectedOptionArg
    $transactionType: String!
  ) {
    getPurchaseCollisionForAllVendor(
      standardItemId: $standardItemId
      selectedOptions: $selectedOptions
      affectedOption: $affectedOption
      transactionType: $transactionType
    ) {
      vendorCollisionsPerOption {
        contractLineId
        affectedOptionCode
        includedOptions {
          optionCode
          optionDescription
        }
        excludedOptions {
          optionCode
          optionDescription
        }
        affectedIncludedOptions {
          optionCode
          optionDescription
          collisionResult
          isRequiredOptionCascadeRelationShip
        }
        affectedExcludedOptions {
          optionCode
          optionDescription
          collisionResult
          isAnUnselectOption
          isRequiredOptionCascadeRelationShip
        }
        requiredOptions {
          optionCode
          optionDescription
        }
        unResolvedOptions {
          optionCode
          optionDescription
          requiredOptions {
            collidedOptions {
              optionCode
              optionDescription
            }
            requiredOptionPrice
            index
          }
          includedOptions {
            optionCode
            optionDescription
          }
          excludedOptions {
            optionCode
            optionDescription
          }
        }
        affectedRequiredOptions {
          index
          collidedOptions {
            optionCode
            optionDescription
          }
          requiredOptionPrice
          includedCollidedOptions {
            optionCode
            optionDescription
          }
          excludedCollidedOptions {
            optionCode
            optionDescription
          }
        }
        vendorName
        modelName
      }
      unselectOptions
      selectOptions {
        included
        required
      }
    }
  }
`;

export const GET_PURCHASE_COLLISION_FOR_ALL_VENDOR_ON_YES = gql`
  query Purchase(
    $standardItemId: Float!
    $selectedOptions: [SelectedOptionArg!]
  ) {
    getPurchaseCollisionForAllVendor(
      standardItemId: $standardItemId
      selectedOptions: $selectedOptions
    ) {
      vendorCollisionsPerOption {
        contractLineId
        affectedOptionCode
        includedOptions {
          optionCode
          optionDescription
        }
        excludedOptions {
          optionCode
          optionDescription
        }
        requiredOptions {
          optionCode
          optionDescription
        }
        unResolvedOptions {
          optionCode
          optionDescription
          requiredOptions {
            collidedOptions {
              optionCode
              optionDescription
            }
            requiredOptionPrice
            index
            includedCollidedOptions {
              optionCode
              optionDescription
            }
            excludedCollidedOptions {
              optionCode
              optionDescription
            }
          }
          includedOptions {
            optionCode
            optionDescription
          }
          excludedOptions {
            optionCode
            optionDescription
          }
        }
        affectedIncludedOptions {
          optionCode
          optionDescription
          collisionResult
          isRequiredOptionCascadeRelationShip
        }
        affectedExcludedOptions {
          optionCode
          optionDescription
          collisionResult
          isRequiredOptionCascadeRelationShip
        }
        affectedRequiredOptions {
          index
          collidedOptions {
            optionCode
            optionDescription
          }
          requiredOptionPrice
          includedCollidedOptions {
            optionCode
            optionDescription
          }
          excludedCollidedOptions {
            optionCode
            optionDescription
          }
        }
        vendorName
        modelName
      }
    }
  }
`;

export const SEARCH_BY_SIN = gql`
  query SearchBySIN($offset: Int, $limit: Int, $value: [String!]!) {
    searchStandardItems(
      offset: $offset
      limit: $limit
      order: "standardItemCode"
      filters: [
        {
          operator: "$or"
          filterBy: "standardItem"
          conditions: [
            { operator: "$startsWith", key: "standardItemCode", value: $value }
          ]
        }
      ]
    ) {
      count
      hasMore
      rows {
        standardItemCode
        standardItemName
        activeContracts
        maxOrderStartDate
      }
    }
  }
`;
export const GET_CONTRACT_LINE_MODEL_COLORS = gql`
  query StoreGetContractLineColors($contractLineId: Int!, $transactionType: String) {
    storeGetContractLineColors(contractLineId: $contractLineId, transactionType: $transactionType) {
      id
      colorCode
      description
      standardPremiumCode
      vendorPrice
      price
    }
  }
`;

export const GET_CALCULATED_PRICE = gql`
  query CalculatePrice(
    $standardItemId: Float!
    $quantity: Float!
    $selectedOptions: [SelectedOptionArg!]
    $colorPriceInfo: ColorPriceInfo
    $transactionType: String!
    $fmcId: Float
    $standardItemNumber: String
  ) {
    calculatePurchaseReqPrice(
      standardItemId: $standardItemId
      quantity: $quantity
      selectedOptions: $selectedOptions
      colorPriceInfo: $colorPriceInfo
      transactionType: $transactionType
      fmcId: $fmcId
      standardItemNumber: $standardItemNumber
    ) {
      modelCostBreakDown {
        contractLineId
        contractVersion
        scheduleLine
        makeCode
        modelCode
        modelYear
        quantity
        isUnBuildable
        perVehicleOptions {
          optionCode
          optionDescription
          unitPrice
          quantity
          optionTotalPrice
          optionType
          optionQuantityRequired
        }
        perVehicleRequiredOptions {
          optionCode
          optionDescription
          unitPrice
          quantity
          optionTotalPrice
          optionType
          optionQuantityRequired
        }
        perOrderOptions {
          optionCode
          optionDescription
          unitPrice
          quantity
          optionTotalPrice
          optionType
          optionQuantityRequired
        }
        basePrice
        perVehicleOptionsPrice
        perVehicleColorPrice
        perVehicleColorPriceVendor
        baseAndOptionsPrice
        totalVehicleAndOptionsPrice
        perOrderOptionsPrice
        totalSellingPrice
        totalShippingDays
        baseShippingDays
        purchaseRate
        additionalShippingDays {
          additionalShippingDays
          optionCode
          optionDescription
        }
        afvTotal
        leaseRates {
          monthlyLeaseRate
          mileageRate
          optionalEquipmentRate
        }
      }
    }
  }
`;

export const GET_REQUISITION_CART = gql`
  query GetRequisition($requisitionId: String!) {
    getRequisition(requisitionId: $requisitionId) {
      requisitionId
      version
      requisitionType
      requisitionNumber
      friendlyName
      createdByInfo {
        id
        firstName
        lastName
        email
        fullName
        email
        phoneNumber
        phoneNumberExt
      }
      agencyCode
      purchasingForAgencyCode
      purchasingForBureauCode
      purchasingForOfficeCode
      purchasingForAgencyInfo {
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      purchasingForBureauInfo {
        shortName
        name
      }
      purchasingForOfficeInfo {
        officeName
        officeCode
      }
      agencyInfo {
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      agencyContact {
        firstName
        lastName
        email
        phoneCountryCode
        phoneNumber
        phoneExtension
        faxCountryCode
        faxNumber
        faxExtension
      }
      bureauCode
      bureauInfo {
        shortName
        name
        agencyIndicatorCode
      }
      officeCode
      officeInfo {
        officeName
        bureauCode
        officeCode
        address1
        address2
        address3
        city
        stateCode
        postalCode
      }
      requisitionStatus
      totalSellingPrice
      clientData {
        selectedOptions
        activeContracts
        clientState
      }
      vendorQuotes {
        requisitionVendorQuoteId
        isAcceptable
        isSelected
        engineerComments
        contractingOfficerComments
        contractLineId
        extendedShippingDays
        makeCode
        modelCode
        makeModel
        totalPrice
        isSelected
        requisitionId
        vendorName
        quoteAttachment {
          requisitionAttachmentId
          description
          metadataId
          name
          signedUrl
        }
      }
      vehicles {
        requisitionId
        unitPriceToCustomer
        unitPriceToGsa
        makeColorCode
        makeColorName
        makeColorPriceToCustomer
        makeColorPriceToGsa
        unitPriceVehicleOptionsToCustomer
        unitPriceOrderOptionsToCustomer
        vehicleEquipments {
          equipmentCode
          quantity
          quantityType
          totalOptionPrice
          unitPriceToCustomer
        }
      }
      standardItemId
      contractLineId
      contractNo
      scheduleLine
      makeCode
      modelCode
      quantity
      totalSellingPrice
      purchaseRate
      agencyReferenceNumber
      isLowPrice
      justification
      boac
      isOverseasDeliveryOptionSelected
      overseasDeliveryEquipmentCode
      baseShipmentDays
      additionalShipmentDays
      additionalShipmentEquipmentCode
      finAdditionalInfo
      finSignalCode
      finFundCode
      finServiceCode
      finAdditionalInfo
      finAdditionalFundsPerUnit
      requisitionerAddressId
      mailingAddressId
      deliveryAddressId
      isDealerDelivery
      isConsigneeDelivery
      deliveryDealershipCode
      markForInformation
      accountingClassificationReferenceNumber
      registrationNumber
      nationalStockNumber
      transportationControlNumber
      purchaseRate
      customerAssignedNumber
      julianDate
      serialNumber
      finSupplementaryAddress
      finAdditionalFundsPerUnit
      transactionType
      requisitionerContact {
        firstName
        lastName
        phoneNumber
        email
        phoneNumber
        phoneExtension
        faxNumber
        faxExtension
        phoneCountryCode
        faxCountryCode
      }
      requisitionerAddress {
        addressLine1
        addressLine2
        city
        stateCode
        countryCode
        zipcode
        zipcodePlusFour
        isUspsVerified
        entityName
        isMilitary
        militaryRankFullName
        militaryPostOffice
        militaryAddress
      }

      mailingContact {
        firstName
        lastName
        phoneNumber
        email
        phoneNumber
        phoneExtension
        faxNumber
        faxExtension
        phoneCountryCode
        faxCountryCode
      }
      mailingAddress {
        addressLine1
        addressLine2
        entityName
        city
        stateCode
        countryCode
        zipcode
        zipcodePlusFour
        isUspsVerified
        isMilitary
        militaryRankFullName
        militaryPostOffice
        militaryAddress
      }
      deliveryContact {
        firstName
        lastName
        phoneNumber
        email
        phoneNumber
        phoneExtension
        faxNumber
        faxExtension
        phoneCountryCode
        faxCountryCode
      }
      deliveryAddress {
        addressLine1
        addressLine2
        city
        stateCode
        countryCode
        zipcode
        zipcodePlusFour
        isUspsVerified
        entityName
        deliveryDealership {
          dealershipDeliveryCode
          id
          dealershipId
          dealershipDeliveryCode
          makeCode
          email
          phone
          address1
          address2
          city
          stateId
          countryId
          postalCode
          dealerName
        }
      }
      additionalRequirements {
        requisitionAdditionalRequirementId
        description
        priceEstimate
        attachments {
          name
          description
          signedUrl
          metadataId
          attachmentTypeId
        }
      }
      specialDeliveryInstructions
      deliveryDate
      nonSopData {
        justification
        color
        driveTrain
        fuelType
        grossCombinedWeightRating
        grossVehicleWeightRating
        payloadCapacity
        quantity
        towingCapacity
        towingType
        vehicleType
        vehicleTypeOther
      }
      validations {
        vehicleAvailability {
          isContractExpired
          makeName
          modelName
        }
        purchaseFeeChange {
          oldPurchaseRate
          newPurchaseRate
          difference
        }
        lowPriceChange {
          oldVendor
          newVendor
          oldLowPrice
          newLowPrice
        }
        modelYearChange {
          oldModelYear
          newModelYear
        }
        systemAvailability {
          isSINexpired
          makeName
          modelName
        }
        optionsUnavailable {
          optionCode
          optionDescription
        }
        optionPricesChanges {
          newPrice
          oldPrice
          optionCode
          vendorName
          difference
        }
        optionCollisionChanges {
          newRelation
          oldRelation
          optionCode
          vendorName
        }
        validationErrors
      }
      assignedEngineer {
        id
      }
    }
  }
`;

export const GET_REQ_ATTACHMENT_TYPES = gql`
  query GetRequisitionAttachmentTypes {
    getRequisitionAttachmentTypes {
      attachmentType
      requisitionAttachmentTypeId
    }
  }
`;

export const GET_CONTRACT_BY_CONTRACT_NUMBER = gql`
  query GetContractDetailsByContractNumber(
    $contractNumber: String!
    $scheduleLine: String!
    $requisitionId: String!
    $updatedQuantity: Float
  ) {
    getContractDetailsByContractNumber(
      contractNumber: $contractNumber
      scheduleLine: $scheduleLine
      requisitionId: $requisitionId
      updatedQuantity: $updatedQuantity
    ) {
      contractUpiid
      units
      contractNumber
      scheduleLine
      contractUnitPrice
      contractTotalPrice
      surcharge
      originCity
      originStateId
      originStateName
      sellingUnitPrice
      sellingTotalPrice
      solicitationNumber
      standardItemNumber
      standardItemDescription
      awardedDate
      vendorName
      shippingDays
      quantity
    }
  }
`;

export const GET_CONTRACT_LINES_BY_CONTRACT_NUMBER = gql`
  query StoreGetContractByContractNumber($contractNumber: String!) {
    storeGetContractByContractNumber(contractNumber: $contractNumber) {
      contractNumber
      contractLines {
        scheduleLine
        financial {
          id
        }
      }
    }
  }
`;

export const UPDATE_LEASING_REQUISITION_CART = gql`
  mutation UpdateLeasingRequisitionCart(
    $requisitionId: String!
    $updateInput: RequisitionLeasingUpdatePropsInput!
  ) {
    updateLeasingRequisitionCart(
      requisitionId: $requisitionId
      updateInput: $updateInput
    ) {
      requisitionId
    }
  }
`;

export const GET_AGENCIES = gql`
  query GetAgencies {
    getAgencies {
      id
      name
    }
  }
`;

export const GET_USER_BUREAUS = gql`
  query GetUserBureaus($agencyCode: String) {
    getBureaus(agencyCode: $agencyCode) {
      id
      name
      shortName
    }
  }
`;

export const GET_USER_OFFICES = gql`
  query GetUserOffices($bureauCode: String, $agencyCode: String) {
    getOfficesNext(bureauCode: $bureauCode, agencyCode: $agencyCode) {
      officeCode
      officeName
    }
  }
`;

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission($operation: String!, $subject: String!) {
    getAgenciesByPermission(operation: $operation, subject: $subject) {
      id
      name: prefixedName
      isDodInt: isDOD
      oldAgencyCode
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
    ) {
      id
      name: prefixedName
    }
  }
`;

export const GET_OFFICES_BY_PERMISSION = gql`
  query GetOfficesByPermission(
    $agencyCode: String!
    $bureauCode: String!
    $subject: String!
    $operation: String!
  ) {
    getOfficesByPermission(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      subject: $subject
      operation: $operation
    ) {
      agencyCode
      officeCode
      officeName: prefixedName
    }
  }
`;

export const GET_CATALOGS_FOR_CATEGORY = gql`
  query GetCatalogsForCategory($category: String!) {
    getCatalogsForCategory(category: $category) {
      code
      category
      displayText
      description
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
    getStates {
      id
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const SEARCH_REQUISTION_ADDRESS = gql`
  query SearchRequisitionAddress(
    $countryCode: String
    $bureauCode: String
    $partLine1: String
  ) {
    searchRequisitionAddress(
      countryCode: $countryCode
      bureauCode: $bureauCode
      partLine1: $partLine1
    ) {
      agencyCode
      bureauCode
      entityName
      line1
      line2
      line3
      city
      stateCode
      countryCode
      zipcode
      zipcodePlusFour
      firstName
      lastName
      email
      faxCountryCallingCode
      faxNumber
      faxExtension
      phoneCountryCallingCode
      phoneNumber
      phoneExtension
      isUSPSVerified
    }
  }
`;

export const VALIDATE_ADDRESS = gql`
  query ValidateAddress(
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    validateAddress(
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
    ) {
      address
      address2
      city
      zip
      state
      updates
      additionalInfo
    }
  }
`;

export const VALIDATE_BOAC = gql`
  query ValidateBOAC(
    $fiscalYear: Float!
    $salesGroup: String!
    $boac: String!
    $agencyCode: String!
    $bureauCode: String!
  ) {
    validateBOAC(
      fiscalYear: $fiscalYear
      salesGroup: $salesGroup
      boac: $boac
      agencyCode: $agencyCode
      bureauCode: $bureauCode
    ) {
      boac
      isValid
      tiedToAgency
      tas
      agencyCode
      bureauCode
    }
  }
`;

export const GET_BOAC_SIGNAL_CODE_CORL = gql`
  query GetBOACForSignalCodeCorL($boacRequest: GetBOACRequest!) {
    getBOACForSignalCodeCorL(boacRequest: $boacRequest) {
      boac
      error
    }
  }
`;

export const GET_BOAC_SIGNAL_CODE_FMC = gql`
  query GetBoacForFundAgencyCode($boacRequest: GetBOACRequest!) {
    getBoacForFundAgencyCode(boacRequest: $boacRequest) {
      boac
      error
    }
  }
`;

export const SUBMIT_PURCHASE_LINE_REQUISITION = gql`
  mutation SubmitPurchaseLineRequisition(
    $requisitionType: Float!
    $friendlyName: String!
    $agencyCode: String
    $standardItemCode: String!
    $contractNumber: String
    $contractModification: String
    $scheduleLine: String
    $makeCode: String
    $quantity: Float
    $selectedOptions: JSONObject
    $subCategoryCode: String!
    $modelCode: String
    $selectedColors: [SelectedColor!]
    $additionalRequirements: [AdditionalRequirement!]
    $agencyReferenceNumber: String
    $justification: String
    $clientState: JSONObject
    $agencyInformation: AgencyInformation
    $requisitionAddress: AddressInput
    $mailingAddress: AddressInput
    $deliveryAddress: AddressInput
    $isMailingSameAsRequisition: Boolean
    $isDeliverySameAsRequisition: Boolean
    $submitComment: String
    $specialDeliveryInstructions: String
    $deliveryDate: String
    $urgentRequirementJustification: String
    $nonSopData: JSONObject
  ) {
    submitPurchaseLineRequisition(
      requisitionType: $requisitionType
      friendlyName: $friendlyName
      agency_code: $agencyCode
      standardItemCode: $standardItemCode
      contractNumber: $contractNumber
      contractModification: $contractModification
      scheduleLine: $scheduleLine
      makeCode: $makeCode
      modelCode: $modelCode
      quantity: $quantity
      selectedOptions: $selectedOptions
      subCategoryCode: $subCategoryCode
      selectedColors: $selectedColors
      additionalRequirements: $additionalRequirements
      agencyReferenceNumber: $agencyReferenceNumber
      justification: $justification
      clientState: $clientState
      agencyInformation: $agencyInformation
      requisitionAddress: $requisitionAddress
      mailingAddress: $mailingAddress
      deliveryAddress: $deliveryAddress
      isMailingSameAsRequisition: $isMailingSameAsRequisition
      isDeliverySameAsRequisition: $isDeliverySameAsRequisition
      submitComment: $submitComment
      specialDeliveryInstructions: $specialDeliveryInstructions
      deliveryDate: $deliveryDate
      urgentRequirementJustification: $urgentRequirementJustification
      nonSopData: $nonSopData
    ) {
      id
      requisitionType
      friendlyName
      agencyInfo {
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      bureauInfo {
        shortName
        name
        agencyIndicatorCode
      }
      officeInfo {
        officeName
        address1
        address2
        address3
        city
        stateCode
        postalCode
      }
      requisitionTypeCode
      status
      totalSellingPrice
      clientState
      requisitionCartPurchaseLine {
        id
        requisitionId
        standardItemCode
        contractNumber
        contractModification
        scheduleLine
        makeCode
        model_code
        quantity
        unitPriceToGsa
        unitPriceToCustomer
        totalSellingPrice
        isAreq
        agencyReferenceNumber
        isLowPrice
        activeContracts
        justification
        isOverseasDeliveryOptionSelected
        overseasDeliveryEquipmentCode
        baseShipmentDays
        isAdditionalShipmentDays
        additionalShipmentDays
        additionalShipmentEquipmentCode
        finSignalCode
        finFundCode
        finServiceCode
        finAdditionalFinancialInformation
        requisitionAddressId
        mailingAddressId
        deliveryAddressId
        isDealerDelivery
        isConsigneeDelivery
        deliveryDealershipCode
        contact
        selectedOptions
        requisitionCartPurchaseLineColor {
          make_color_code
          make_color_name
          quantity
        }
        customerAssignedNumber
        finSignalCode
        finFundCode
        finServiceCode
        reqActivityAddress
        reqJulianDate
        reqSerialNumber
        finSupplementaryAddress
        finAdditionalFundsPerUnit
        requisitionContact {
          firstName
          lastName
          phoneNumber
          email
        }
        requisitionAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCallingCode
          faxNumber
          faxExtension
          phoneCountryCallingCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
        }
        mailingAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCallingCode
          faxNumber
          faxExtension
          phoneCountryCallingCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
        }
        deliveryAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCode
          faxNumber
          faxExtension
          phoneCountryCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
        }
        specialDeliveryInstructions
        deliveryDate
        urgentRequirementJustification
        nonSopData
      }
    }
  }
`;

export const SUBMIT_REQUISITION = gql`
  mutation SubmitRequisition(
    $requisitionId: String!
    $submitComment: String!
    $isVendorDirect: Boolean
  ) {
    submitRequisition(
      requisitionId: $requisitionId
      submitComment: $submitComment
      isVendorDirect: $isVendorDirect
    ) {
      resubmittedStatus
      requisitionCartValidations {
        vehicleAvailability {
          isContractExpired
          makeName
          modelName
        }
        purchaseFeeChange {
          hasPurchaseFeeChanged
        }
        lowPriceChange {
          hasLowPriceChanged
        }
        modelYearChange {
          oldModelYear
          newModelYear
        }
        systemAvailability {
          isSINexpired
          makeName
          modelName
        }
        optionsUnavailable {
          optionCode
          optionDescription
        }
        optionPricesChanges {
          newPrice
          oldPrice
          optionCode
          vendorName
          difference
        }
        optionCollisionChanges {
          newRelation
          oldRelation
          optionCode
          vendorName
        }
        validationErrors
      }
    }
  }
`;

export const SUBMIT_CONTRACTING_REVIEW = gql`
  mutation SubmitContractingReview(
    $requisitionId: String!
    $requisitionQuotes: [RequisitionVendorQuoteInput!]!
  ) {
    submitContractingReview(
      requisitionId: $requisitionId
      requisitionQuotes: $requisitionQuotes
    )
  }
`;

export const SUBMIT_ENGINEER_APPROVAL = gql`
  mutation SubmitEngineerApproval(
    $requisitionId: String!
    $vendorQuotes: [VendorQuoteApprovalInput!]!
  ) {
    submitEngineerApproval(
      requisitionId: $requisitionId
      vendorQuotes: $vendorQuotes
    )
  }
`;

export const SUBMIT_CONTRACTING_REVIEW_FROM_CO_TO_ENGINEER = gql`
  mutation SubmitContractingReviewFromCoToEngineer(
    $requisitionId: String!
    $requisitionQuotes: [RequisitionVendorQuoteInput!]!
  ) {
    submitContractingReviewFromCoToEngineer(
      requisitionId: $requisitionId
      requisitionQuotes: $requisitionQuotes
    )
  }
`;

export const SUBMIT_CUSTOMER_RESPONSE = gql`
  mutation SubmitCustomerResponse(
    $requisitionId: String!
    $vendorQuoteId: Float!
    $justification: String
    $contractLineId: Float
    $fundingDocuments: [RequisitionVendorFileInput!]!
    $color: ColorInput
  ) {
    submitCustomerResponse(
      requisitionId: $requisitionId
      vendorQuoteId: $vendorQuoteId
      justification: $justification
      contractLineId: $contractLineId
      fundingDocuments: $fundingDocuments
      color: $color
    )
  }
`;

export const RETURN_REQUISITION = gql`
  mutation ReturnRequisition($requisitionId: String!, $submitComment: String!) {
    returnRequisition(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const GET_LEASING_FUNDING_AVAILABILITY = gql`
  query Query($agencyCode: String!, $agencyIndicator: String!) {
    getFundingValidationForLeasing(
    agencyCode: $agencyCode
    agencyIndicator: $agencyIndicator
    ) {
      totalFunds
      inPendingFunds
      currentAvailableFunds
      currentAvailableAFVFunds
    }
  }
`;

export const RETURN_REQUISITION_TO_ENGINEERING_REVIEW = gql`
  mutation ReturnRequisitionToEngineer(
    $requisitionId: String!
    $submitComment: String!
  ) {
    returnRequisitionToEngineer(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const RETURN_REQUISITION_TO_CO_REVIEW = gql`
  mutation ReturnRequisitionToCOReview(
    $requisitionId: String!
    $submitComment: String!
  ) {
    returnRequisitionToCOReview(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const RETURN_REQUISITION_TO_ENGINEER_APPROVAL = gql`
  mutation ReturnRequisitionToEngineerApproval(
    $requisitionId: String!
    $submitComment: String!
  ) {
    returnRequisitionToEngineerApproval(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const RETURN_TO_ZONAL_APPROVAL = gql`
  mutation ReturnRequisitionToZonalApproval(
    $submitComment: String!
    $requisitionId: String!
  ) {
    returnRequisitionToZonalApproval(
      submitComment: $submitComment
      requisitionId: $requisitionId
    )
  }
`;

export const SUBMIT_TO_GSA = gql`
  mutation SubmitToGSA(
    $requisitionId: String!
    $approvalComment: String
    $contractNumber: String
    $scheduleLine: String
    $attachments: RequisitionVendorApprovalInput
    $nonSopQuantity: Float
    $nonSopColor: String
  ) {
    submitToGSA(
      requisitionId: $requisitionId
      approvalComment: $approvalComment
      contractNumber: $contractNumber
      scheduleLine: $scheduleLine
      attachments: $attachments
      nonSopQuantity: $nonSopQuantity
      nonSopColor: $nonSopColor
    ) {
      operationStatus
      requisitionCartValidations {
        vehicleAvailability {
          isContractExpired
          makeName
          modelName
        }
        purchaseFeeChange {
          hasPurchaseFeeChanged
        }
        lowPriceChange {
          hasLowPriceChanged
        }
        modelYearChange {
          oldModelYear
          newModelYear
        }
        systemAvailability {
          isSINexpired
          makeName
          modelName
        }
        optionsUnavailable {
          optionCode
          optionDescription
        }
        optionPricesChanges {
          newPrice
          oldPrice
          optionCode
          vendorName
          difference
        }
        optionCollisionChanges {
          newRelation
          oldRelation
          optionCode
          vendorName
        }
        validationErrors
      }
    }
  }
`;

export const GET_ENABLED_FEATURES = gql`
  query getEnabledFeatures {
    getEnabledFeatures
  }
`;

export const GET_ORDER_DETAILS = gql`
  query getOrder($orderId: String!) {
    getOrderById(orderId: $orderId) {
      orderId
      caseNumber
      boac
      friendlyName
      finSignalCode
      finServiceCode
      finFundCode
      finSupplementaryAddress
      finAdditionalInfo
      customerAssignedNumber
      version
      requisitionId
      requisitionNumber
      requisitionType
      treasuryAccountSymbol
      transportationControlNumber
      markForInformation
      nationalStockNumber
      registrationNumber
      rpnNumber
      isDealerDelivery
      upiidNumber
      julianDate
      serialNumber
      purchaseRate
      unitPriceToGsa
      unitPriceToCustomer
      contractNumber
      scheduleLine
      contractLineId
      orderStatus
      standardItemCode
      standardItemId
      standardItemDescription
      quantity
      justification
      totalSellingPrice
      createdAt
      submittedToVendorAt
      updatedAt
      baseShipmentDays
      additionalShipmentDays
      shipmentDate
      shipmentDate
      createdBy
      createdUserInfo {
        id
        email
        firstName
        lastName
      }
      approvedBy
      approvedByUserInfo {
        id
        email
        firstName
        lastName
      }
      agencyCode
      bureauCode
      officeCode
      agencyInfo {
        id
        name
        isDOD
      }
      bureauInfo {
        id
        name
      }
      officeInfo {
        officeCode
        officeName
      }
      purchasingForAgencyCode
      purchasingForBureauCode
      purchasingForOfficeCode
      purchasingForAgencyInfo {
        id
        name
      }
      purchasingForBureauInfo {
        id
        name
      }
      purchasingForOfficeInfo {
        officeCode
        officeName
      }
      modelYear
      makeCode
      modelCode
      makeInfo {
        makeName
      }
      modelInfo {
        modelName
      }
      deliveryContact {
        firstName
        lastName
        phoneNumber
        email
      }
      bureauInfo {
        name
      }
      orderVehicles {
        makeColorCode
        makeColorName
        makeColorPriceToCustomer
        makeColorPriceToGsa
        basePriceToGsa
        unitPriceToCustomer
        accountingLineNumber
        orderVehicleEquipments {
          quantity
          quantityType
          unitPriceToGsa
          unitPriceToCustomer
          unitPriceToGsa
          totalOptionPrice
          equipmentCode
          requiredBy
        }
      }
      deliveryAddressId
      deliveryDealershipCode
      requisitionerAddressId
      mailingAddressId
      deliveryAddress {
        isMilitary
        isDomestic
        entityName
        addressLine1
        addressLine2
        city
        stateCode
        countryCode
        zipcode
      }
      mailingAddress {
        isMilitary
        isDomestic
        entityName
        addressLine1
        addressLine2
        city
        stateCode
        countryCode
        zipcode
      }
      mailingContact {
        email
        firstName
        lastName
        phoneNumber
      }
      requisitionerAddress {
        isMilitary
        isDomestic
        entityName
        addressLine1
        addressLine2
        city
        stateCode
        countryCode
        zipcode
      }
      requisitionerContact {
        email
        firstName
        lastName
        phoneNumber
      }
      mvdoAttachment {
        metadataId
        fileMetadata
      }
      requisition {
        requisitionId
        createdByInfo {
          id
          email
          firstName
          lastName
        }
        clientData {
          selectedOptions
          activeContracts
          clientState
        }
        createdAt
      }
      message1
      message2
      message3
      message4
      message5
    }
  }
`;

export const GET_ORDER_MOD_HISTORY = gql`
  query getOrderModHistory($caseNumber: String!, $version: Float!) {
    getOrderModsByCaseNumber(caseNumber: $caseNumber, version: $version) {
      orderModificationId
      orderModName
      updatedAt
    }
  }
`;
export const GET_ORDER_WITH_LEASING_DETAILS = gql`
  query getOrder($orderId: String!) {
    getOrderWithLeasingById(orderId: $orderId) {
      leasing {
        fmcName
        leasingAgencyName
        leasingBureauName
        leasingOfficeName
        leasingCustomerBOAC
        leasingCustomerNumber
        leasingAccountName
        zone
        fmcName
        legacyCustomerNumber
        customerNumber
        fmcId
        oldLicencePlate
        agencyOrderNumber
        fsrFirstName
        fsrLastName
        first_name
        last_name
        email_address
        address1
        address2
        city
        postal_code
        state_code
        international_state_name
        phoneNumber
        phoneCountryCode
        monthlyRate
        mileageRate
        optionalEquipMarkup
        afvTotal
      }
      order {
        orderId
        caseNumber
        transactionType
        boac
        friendlyName
        finSignalCode
        finServiceCode
        finFundCode
        finSupplementaryAddress
        finAdditionalInfo
        customerAssignedNumber
        version
        requisitionId
        requisitionNumber
        requisitionType
        treasuryAccountSymbol
        transportationControlNumber
        markForInformation
        nationalStockNumber
        registrationNumber
        shipmentDate
        rpnNumber
        isDealerDelivery
        upiidNumber
        julianDate
        serialNumber
        isExport
        purchaseRate
        unitPriceToGsa
        unitPriceToCustomer
        contractNumber
        scheduleLine
        contractLineId
        orderStatus
        standardItemCode
        standardItemId
        standardItemDescription
        quantity
        justification
        totalSellingPrice
        createdAt
        submittedToVendorAt
        updatedAt
        baseShipmentDays
        additionalShipmentDays
        createdBy
        createdUserInfo {
          id
          email
          firstName
          lastName
        }
        approvedBy
        approvedByUserInfo {
          id
          email
          firstName
          lastName
        }
        agencyCode
        bureauCode
        officeCode
        agencyInfo {
          id
          name
          isDOD
        }
        bureauInfo {
          id
          name
        }
        officeInfo {
          officeCode
          officeName
        }
        purchasingForAgencyCode
        purchasingForBureauCode
        purchasingForOfficeCode
        purchasingForAgencyInfo {
          id
          name
        }
        purchasingForBureauInfo {
          id
          name
        }
        purchasingForOfficeInfo {
          officeCode
          officeName
        }
        modelYear
        makeCode
        modelCode
        makeInfo {
          makeName
        }
        modelInfo {
          modelName
        }
        deliveryContact {
          firstName
          lastName
          phoneNumber
          email
          phoneCountryCode
        }
        licensePlateDeliveryContact {
          firstName
          lastName
          phoneNumber
          email
          phoneCountryCode
        }
        licensePlateDeliveryAddress {
          isMilitary
          isDomestic
          entityName
          addressLine1
          addressLine2
          city
          stateCode
          countryCode
          zipcode
        }
        orderVehicles {
          makeColorCode
          makeColorName
          makeColorPriceToCustomer
          makeColorPriceToGsa
          basePriceToGsa
          unitPriceToCustomer
          accountingLineNumber
          orderVehicleEquipments {
            quantity
            quantityType
            unitPriceToCustomer
            unitPriceToGsa
            totalOptionPrice
            equipmentCode
            requiredBy
          }
        }
        deliveryAddressId
        requisitionerAddressId
        mailingAddressId
        deliveryAddress {
          isMilitary
          isDomestic
          entityName
          addressLine1
          addressLine2
          city
          stateCode
          countryCode
          zipcode
        }
        mailingAddress {
          isMilitary
          isDomestic
          entityName
          addressLine1
          addressLine2
          city
          stateCode
          countryCode
          zipcode
        }
        mailingContact {
          email
          firstName
          lastName
          phoneNumber
        }
        requisitionerAddress {
          isMilitary
          isDomestic
          entityName
          addressLine1
          addressLine2
          city
          stateCode
          countryCode
          zipcode
        }
        requisitionerContact {
          email
          firstName
          lastName
          phoneNumber
        }
        mvdoAttachment {
          metadataId
          fileMetadata
        }
        requisition {
          requisitionId
          createdByInfo {
            id
            email
            firstName
            lastName
          }
          clientData {
            selectedOptions
            activeContracts
            clientState
          }
          createdAt
        }
        message1
        message2
        message3
        message4
        message5
        telematics
      } 
    }
  }
`;

export const GET_REQUISITION_ACTIVITIES = gql`
  query GetReqActivities(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $requisitionId: String!
  ) {
    getRequisitionActivities(
      offset: $offset
      limit: $limit
      order: $order
      requisitionId: $requisitionId
    ) {
      count
      hasMore
      rows {
        requisitionActivityId
        createdAt
        type {
          name
        }
        comment
        parentActivity {
          requisitionActivityId
        }
        createdUserInfo {
          id
          email
          firstName
          fullName
        }
      }
    }
  }
`;

export const GET_USERS_BY_ROLE = gql`
  query GetUsersByRole(
    $filters: [Filter!]!
    $roleId: [Int!]
    $order: OrderBy
    $offset: Float
    $limit: Float
  ) {
    getUsersByRole(
      filters: $filters
      roleId: $roleId
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        email
      }
    }
  }
`;

export const GET_REQUISITION_ACTIVITIES_BY_TYPE = gql`
  query GetReqActivitiesByType(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $requisitionId: String!
    $type: String!
  ) {
    getRequisitionActivitiesByType(
      offset: $offset
      limit: $limit
      order: $order
      requisitionId: $requisitionId
      type: $type
    ) {
      count
      hasMore
      rows {
        requisitionActivityId
        createdAt
        type {
          name
        }
        comment
        parentActivity {
          requisitionActivityId
        }
        createdUserInfo {
          id
          email
          fullName
        }
        requisition {
          agencyInfo {
            id
            name
          }
        }
      }
    }
  }
`;

export const IS_REQ_PREVIOUSLY_RETURNED = gql`
  query isReqPreviouslyReturned($requisitionId: String!) {
    isReqPreviouslyReturned(requisitionId: $requisitionId)
  }
`;

export const GENERATE_ORDER_STATUS_SHEET = gql`
  query GenerateXLSXResponse($query: OrderSearchQuery!) {
    generateOrderStatusSheet(query: $query) {
      succeed
      message
      signedUrl
    }
  }
`;

export const GET_PDF_URL = gql`
  query GeneratePDFResponse(
    $contentModel: String!
    $recordId: String
    $friendlyName: String
    $sections: String!
    $vehicleContract: String!
    $requisitionStandardItem: String!
    $calculatedPriceInfoState: String!
    $selectedContractAgencyInformation: String!
    $agencyInfo: String!
    $bureauInfo: String!
    $firstActivity: String
    $lastActivity: String
    $perVehicleOptions: String!
    $selectedColor: String!
    $paintAndGraphicsOptions: String!
    $taggedOptions: String
    $requisitionAddress: String!
    $mailingAddress: String!
    $deliveryAddress: String!
    $nonLowBidJustification: String
    $areqList: String
  ) {
    generatePDF(
      contentModel: $contentModel
      recordId: $recordId
      friendlyName: $friendlyName
      sections: $sections
      vehicleContract: $vehicleContract
      requisitionStandardItem: $requisitionStandardItem
      calculatedPriceInfoState: $calculatedPriceInfoState
      selectedContractAgencyInformation: $selectedContractAgencyInformation
      agencyInfo: $agencyInfo
      bureauInfo: $bureauInfo
      firstActivity: $firstActivity
      lastActivity: $lastActivity
      perVehicleOptions: $perVehicleOptions
      selectedColor: $selectedColor
      paintAndGraphicsOptions: $paintAndGraphicsOptions
      taggedOptions: $taggedOptions
      requisitionAddress: $requisitionAddress
      mailingAddress: $mailingAddress
      deliveryAddress: $deliveryAddress
      nonLowBidJustification: $nonLowBidJustification
      areqList: $areqList
    ) {
      succeed
      message
      signedUrl
    }
  }
`;

export const CREATE_REQUISITION_COMMENT = gql`
  mutation CreateRequisitionComment(
    $requisitionId: String!
    $comment: String!
    $baseUrl: String!
  ) {
    createRequisitionComment(
      requisitionId: $requisitionId
      comment: $comment
      baseUrl: $baseUrl
    ) {
      requisitionActivityId
    }
  }
`;

export const GET_ORDER_LINES_FOR_ORDER = gql`
  query getOrderLinesForOrder(
    $orderId: String!
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getOrderVehiclesForOrder(
      orderId: $orderId
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        accountingLineNumber
        orderVehicleId
        vehicleOrderNumber
        serialNumberVin
        shipmentDate
        deliveryDate
        statusCode
      }
      count
    }
  }
`;

export const GET_ORDERS_FOR_USER = gql`
  query getOrderLines($query: OrderSearchQuery!) {
    getAllOrdersForUserWithLeasing(query: $query) {
      rows {
        orderId
        requisitionId
        transactionType
        caseNumber
        requisitionNumber
        customerAssignedNumber
        rpnNumber
        upiidNumber
        friendlyName
        createdAt
        bureauCode
        agencyCode
        orderStatus
        modelCode
        vehicleTypeCode
        requisitionType
        deliveryAddressEntityName
        deliveryAddressCity
        deliveryAddressStateCode
        deliveryAddressZipcode
        quantity
        totalSellingPrice
        standardItemCode
        baseShipmentDays
        additionalShipmentDays
        createdUserInfoFirstName
        createdUserInfoLastName
        createdUserInfoEmail
        approvedByUserInfoFirstName
        approvedByUserInfoLastName
        approvedByUserInfoEmail
        bureauInfoName
        agencyInfoName
        officeInfoName
        mvdoAttachmentMetadataId
        mvdoAttachmentFileMetadata
        submittedToVendorAt
        shipmentDate
      }
      count
    }
  }
`;

export const GET_ORDER_VEHICLES_FOR_USER = gql`
  query GetOrderVehicles($query: OrderSearchQuery!) {
    getAllOrderVehicles(query: $query) {
      rows {
        orderId
        caseNumber
        requisitionNumber
        rpnNumber
        upiidNumber
        friendlyName
        createdAt
        bureauCode
        agencyCode
        vehicleTypeCode
        deliveryAddress {
          entityName
          city
          zipcode
          addressLine1
          addressLine2
          stateCode
        }
        deliveryContact {
          firstName
          lastName
          phoneCountryCode
          phoneNumber
          phoneExtension
          faxCountryCode
          email
        }
        agencyContact {
          firstName
          lastName
          phoneCountryCode
          phoneNumber
          phoneExtension
          faxCountryCode
          email
        }
        quantity
        totalSellingPrice
        standardItemCode
        baseShipmentDays
        additionalShipmentDays
        bureauInfo {
          name
        }
        officeInfo {
          officeName
        }
        agencyInfo {
          name
        }
        orderVehicles {
          orderVehicleId
          serialNumberVin
          vehicleOrderNumber
          statusCode
          shipmentDate
          deliveryDate
          receivedAt
        }
      }
      count
    }
  }
`;

export const UPDATE_ORDER_VEHICLE_RECEIVED = gql`
  mutation UpdateOrderVehicleReceived($input: UpdateVehicleReceivedInput!) {
    updateOrderVehicleReceived(input: $input)
  }
`;

export const UPDATE_ORDER_LINE_VEHICLE = gql`
  mutation updateOrderVehicle(
    $orderVehicleId: String!
    $data: UpdateOrderVehicleData!
  ) {
    updateOrderVehicle(orderVehicleId: $orderVehicleId, data: $data) {
      orderVehicleId
      serialNumberVin
      vehicleOrderNumber
      shipmentDate
      deliveryDate
      statusCode
    }
  }
`;

export const FETCH_SELECTED_PAINT_AND_GRAPHIC_OPTIONS = gql`
  query GetEquipmentCodes(
    $limit: Int
    $offset: Int
    $order: OrderBy
    $filters: FilterType
  ) {
    getEquipmentCodes(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      count
      rows {
        id
        code
        title
        category
        categoryCode {
          code
          title
        }
        tags
      }
    }
  }
`;

export const FETCH_SIGNED_URL = gql`
  mutation GenerateSignedUrl($input: GenerateSignedUrlInput!) {
    generateSignedUrl(input: $input) {
      signedUrl
      metadataId
    }
  }
`;

export const FETCH_SIGNED_URL_ORDERS = gql`
  mutation GenerateSignedUrlForOrders($input: GenerateOrdersSignedUrlInput!) {
    generateSignedUrlForOrders(input: $input) {
      signedUrl
      metadataId
    }
  }
`;

export const FETCH_SIGNED_URL_REQUISITIONS = gql`
  mutation GenerateSignedUrlForRequisitions($input: GenerateSignedUrlInput!) {
    generateSignedUrl(input: $input) {
      signedUrl
      metadataId
    }
  }
`;

export const CREATE_REQUISITION_ATTACHMENT = gql`
  mutation CreateRequisitionAttachment($input: AttachmentInput!) {
    createRequisitionAttachment(input: $input) {
      requisitionId
      name
      metadataId
      status
      createdAt
      updatedAt
      deletedAt
      requisitionAttachmentId
      signedUrl
      attachmentTypeId
      attachmentSubtypeId
    }
  }
`;

export const DELETE_REQUISITION_ATTACHMENT = gql`
  mutation deleteAttachment($attachmentId: String!) {
    deleteRequisitionAttachment(attachmentId: $attachmentId) {
      requisitionAttachmentId
    }
  }
`;

export const UPDATE_ATTACHMENT_DESCRIPTION = gql`
  mutation updateRequisitionAttachmentDescription(
    $attachmentId: String!
    $description: String!
  ) {
    updateRequisitionAttachmentDescription(
      attachmentId: $attachmentId
      description: $description
    ) {
      requisitionAttachmentId
    }
  }
`;

export const GET_DOC_METADATA = gql`
  query GetDocMetadata($id: String!) {
    getDocMetadata(id: $id) {
      name
      fileLocation
      fileMimeType
    }
  }
`;

export const GET_MVDO_ATTACHMENT = gql`
  query GetMVDOAttachment($orderId: String!) {
    getMVDOAttachment(orderId: $orderId)
  }
`;
export const REPRINT_MVDO_ATTACHMENT = gql`
  query ReprintMVDOAttachment($orderId: String!) {
    reprintMVDOAttachment(orderId: $orderId)
  }
`;

export const GENERATE_READ_SIGNED_URL = gql`
  query generateReadSignedURL($fileType: String!, $fileKey: String!) {
    generateReadSignedURL(fileType: $fileType, fileKey: $fileKey)
  }
`;

// TODO add the following fields after Catalog implement
//  the baseEngine and baseEngineRating models
/*
baseEngine {
    engineLiters
    engineCylinders
}
baseEngineRating {
    fuelCode
    altFuelCode
    mPGCity
    mPGHighway
    mPGCombined
    altMPGCity
    altMPGHighway
    altMPGCombined
    gramsPerMile
    altGramsPerMile
    fuel {
      fuelDescription
    }
    altFuel {
      fuelDescription
    }
}
* */
export const GET_CONTRACT_LINE_BY_ID = gql`
  query GetActivePurchaseReqContractModLine($contractLineId: Int!) {
    storeGetContractLineById(id: $contractLineId) {
      makeCode
      modelCode
      makeName
      modelName
      modelYear
      originCity
      originStateId
      originStateCode
      originStateName

      contractMod {
        contractVersion
        contract {
          solicitationNumber
          solicitationUpiid
          vendor {
            vendorName
          }

          awardedDate
          contractUpiid
        }
      }
    }
  }
`;

export const GET_REQUISITION_ATTACHMENTS = gql`
  query GetAllRequisitionAttachments($requisitionId: String!) {
    getAllRequisitionAttachments(requisitionId: $requisitionId) {
      requisitionAttachmentId
      requisitionId
      attachmentTypeId
      attachmentType {
        requisitionAttachmentTypeId
        attachmentType
        createdAt
        updatedAt
      }
      equipmentCode
      metadata {
        id
      }
      name
      metadataId
      signedUrl
      status
      attachmentSubtype {
        attachmentType
        requisitionAttachmentTypeId
      }
      additionalRequirementId
      attachmentSubtypeId
      description
      createdUserInfo {
        id
        fullName
      }
      createdAt
    }
  }
`;

export const GET_REQUISITION_ADDITIONAL_REQUIREMENTS = gql`
  query GetRequisitionAdditionalRequirements($requisitionId: String!) {
    getRequisitionAdditionalRequirements(requisitionId: $requisitionId) {
      requisitionAdditionalRequirementId
      requisitionId
      description
    }
  }
`;
export const GET_REQUISITION_ATTACHMENT_TYPES = gql`
  query GetAllRequisitionAttachmentTypes {
    getAllRequisitionAttachmentTypes {
      requisitionAttachmentTypeId
      attachmentType
    }
  }
`;

export const GET_ORDER_ATTACHMENTS = gql`
  query GetOrderAttachments($orderId: String!, $type: String!) {
    getAllOrderAttachments(orderId: $orderId, type: $type) {
      orderAttachmentId
      orderId
      metadataId
      status
      signedUrl
      description
      equipmentCode
      isInternal
      createdUserInfo {
        id
        email
        firstName
        lastName
        fullName
      }
      createdAt
      attachmentType {
        orderAttachmentTypeId
        description
      }
      fileMetadata
    }
  }
`;
export const GET_ORDER_ATTACHMENTS_BY_CASE_NUMBER = gql`
  query GetOrderAttachmentsByCaseNumber($caseNumber: String!, $type: String!) {
    getAllOrderAttachmentsByCaseNumber(caseNumber: $caseNumber, type: $type) {
      orderAttachmentId
      orderId
      metadataId
      status
      signedUrl
      description
      equipmentCode
      isInternal
      createdUserInfo {
        id
        email
        firstName
        lastName
        fullName
      }
      createdAt
      attachmentType {
        orderAttachmentTypeId
        description
      }
      fileMetadata
    }
  }
`;

export const GET_ORDER_ATTACHMENT_TYPES = gql`
  query GetAllOrderAttachmentTypes {
    getAllOrderAttachmentTypes {
      orderAttachmentTypeId
      description
    }
  }
`;

export const CREATE_ORDER_ATTACHMENT = gql`
  mutation CreateOrderAttachment($input: OrderAttachmentInput!) {
    createOrderAttachment(input: $input) {
      orderAttachmentId
      orderId
      metadataId
      description
      status
      createdUserInfo {
        id
        email
        firstName
        lastName
        fullName
      }
      updatedUserInfo {
        id
        email
        firstName
        lastName
        fullName
      }
      createdAt
      updatedAt
      deletedAt
      isInternal
      signedUrl
    }
  }
`;

export const GET_ORDER_ACTIVITIES = gql`
  query GetOrderActivities(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $orderId: String!
  ) {
    getOrderActivities(
      offset: $offset
      limit: $limit
      order: $order
      orderId: $orderId
    ) {
      count
      hasMore
      rows {
        orderActivityId
        createdAt
        type {
          name
        }
        comment
        parentActivity {
          orderActivityId
        }
        createdUserInfo {
          id
          email
          firstName
          fullName
        }
      }
    }
  }
`;

export const CREATE_ORDER_COMMENT = gql`
  mutation CreateOrderComment($orderId: String!, $comment: String!) {
    createOrderComment(orderId: $orderId, comment: $comment) {
      orderActivityId
    }
  }
`;

export const SUBMIT_FOR_ENGINEERING_REVIEW = gql`
  mutation SubmitForEngineerReview($requisitionId: String!) {
    submitForEngineerReview(requisitionId: $requisitionId)
  }
`;

export const SUBMIT_FOR_CONTRACTING_REVIEW = gql`
  mutation SubmitForContractingReview($requisitionId: String!) {
    submitForContractingReview(requisitionId: $requisitionId)
  }
`;

export const ENGINEER_APPROVE = gql`
  mutation EngineerApprove($requisitionId: String!) {
    engineerApprove(requisitionId: $requisitionId)
  }
`;

export const CO_APPROVE = gql`
  mutation COApprove($requisitionId: String!) {
    coApprove(requisitionId: $requisitionId)
  }
`;

export const SUBMIT_AREQ_FOR_PENDING_CUSTOMER = gql`
  mutation SubmitAreqForPendingCustomer($requisitionId: String!) {
    submitAreqForPendingCustomer(requisitionId: $requisitionId)
  }
`;

export const GET_DEALERSHIP_DELIVERY_BY_MAKE_QUERY = gql`
  query GetDeliveryDealershipsByMake($make: Float!) {
    getDeliveryDealerships(dealershipInput: { makeCode: $make }) {
      id
      dealershipDeliveryCode
      makeCode
      email
      phone
      address1
      address2
      city
      stateId
      countryId
      postalCode
      createdBy
      createdAt
      updatedBy
      updatedAt
      dealerName
    }
  }
`;

export const PROCESS_BULK_ORDERLINE = gql`
  mutation updateOrderVehicleStatus(
    $data: [UpdateOrderVehicleStatusLogData!]!
    $startingIndex: Int!
  ) {
    updateOrderVehicleStatus(data: $data, startingIndex: $startingIndex) {
      __typename
      ... on BulkUpdateVehicleError {
        errorResponse {
          id
          caseNumber
          accountingLineNumber
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
        }
      }
      __typename
      ... on BulkUpdateVehicleResponse {
        data {
          vin
          von
          caseNumber
          contractNumber
          status
          accountingLineNumber
          id
        }
      }
    }
  }
`;

export const GET_STANDARD_CODES = gql`
  query GetStandardCodes($filters: FilterType!) {
    getStandardCodes(filters: $filters) {
      rows {
        id
        code
        title
        parentCode
        standardCodeMetadataId
        metadata {
          id
          description
        }
        standardsVehicleGroupPocs {
          standardVgPocId
          standardCodeId
          pocType
          name
          email
        }
      }
    }
  }
`;

export const GET_FUEL_CODES = gql`
  query GetStandardCodes($filters: FilterType!) {
    getStandardCodes(filters: $filters) {
      rows {
        code
        title
      }
    }
  }
`;
export const IS_USR_FOR_ON_BEHALF_ORDERING = gql`
  query isUserForOnBehalfOrdering($operation: String!, $subject: String!) {
    isUserForOnBehalfOrdering(operation: $operation, subject: $subject)
  }
`;
export const GET_ORDERING_AGENCIES_BY_PERMISSION = gql`
  query getOnBehalfOrderingAgencies($operation: String!, $subject: String!) {
    getOnBehalfOrderingAgenciesByPermission(
      operation: $operation
      subject: $subject
    ) {
      id
      name: prefixedName
      isDodInt: isDOD
      oldAgencyCode
    }
  }
`;
export const GET_ORDERING_BUREAUS_BY_PERMISSION = gql`
  query getOnBehalfOrderingBureaus(
    $operation: String!
    $subject: String!
    $orderingAgencyCode: String!
  ) {
    getOnBehalfOrderingBureaus(
      operation: $operation
      subject: $subject
      agencyCode: $orderingAgencyCode
    ) {
      id
      name: prefixedName
    }
  }
`;

export const GET_RECEIVING_AGENCIES = gql`
  query getOnBehalfReceivingAgencies(
    $operation: String!
    $subject: String!
    $orderingAgency: String!
    $orderingBureau: String!
  ) {
    getOnBehalfReceivingAgencies(
      operation: $operation
      subject: $subject
      orderingAgency: $orderingAgency
      orderingBureau: $orderingBureau
    ) {
      id
      name: prefixedName
      isDodInt: isDOD
      oldAgencyCode
    }
  }
`;

export const GET_RECEIVING_BUREAUS = gql`
  query getOnBehalfReceivingBureaus(
    $operation: String!
    $subject: String!
    $orderingAgency: String!
    $orderingBureau: String!
    $purchasingAgency: String!
  ) {
    getOnBehalfReceivingBureaus(
      operation: $operation
      subject: $subject
      orderingAgency: $orderingAgency
      orderingBureau: $orderingBureau
      purchasingAgency: $purchasingAgency
    ) {
      id
      name: prefixedName
    }
  }
`;

export const GET_ALL_ORDER_MODS_FOR_USER = gql`
  query GetAllOrderModsForUser($query: OrderModificationSearchQuery!) {
    getAllOrderModsForUser(query: $query) {
      count
      rows {
        orderModificationId
        orderModName
        orderModStatus
        createdAt
        allowedActions
        changeSetOrderCount
      }
    }
  }
`;

export const CREATE_ORDER_MOD = gql`
  mutation CreateOrderModification(
    $modificationName: String!
    $contractNumber: String!
  ) {
    createOrderModification(
      modificationName: $modificationName
      contractNumber: $contractNumber
    ) {
      orderModificationId
      orderModName
      orderModStatus
    }
  }
`;

export const GET_ORDER_MOD_BY_PARTIAL_NAME = gql`
  query getOrderModsByPartialName($partialName: String!) {
    getOrderModsByPartialName(partialName: $partialName) {
      orderModificationId
      orderModName
      orderModStatus
      createdAt
      createdAt
    }
  }
`;

export const CLONE_REQUISITION = gql`
  mutation CloneRequistion($RequisitionId: String!, $NewName: String!) {
    cloneRequisition(requisitionId: $RequisitionId, newName: $NewName) {
      requisitionId
      requisitionType
    }
  }
`;

export const PLACE_REQUISITION_ON_HOLD = gql`
  mutation PlaceRequisitionOnHold($requisitionId: String!, $comment: String!) {
    setRequisitionCartOnHold(
      requisitionId: $requisitionId
      onHold: true
      comment: $comment
    ) {
      requisitionId
      requisitionStatus
    }
  }
`;

export const RESUME_ENGINEERING_REVIEW = gql`
  mutation ResumeEngineerReview($requisitionId: String!) {
    setRequisitionCartOnHold(
      requisitionId: $requisitionId
      onHold: false
      comment: ""
    ) {
      requisitionId
      requisitionStatus
    }
  }
`;

export const SEARCH_ORDER_MOD = gql`
  query SearchOrderMods($query: OrderSearchQuery!, $contractNumber: String!) {
    searchOrdersByContract(query: $query, contractNumber: $contractNumber) {
      count
      rows {
        orderId
        modificationStatus
        orderStatus
        caseNumber
        scheduleLine
        quantity
        unitPriceToCustomer
        requisitionNumber
        requisitionType
        contractLineId
        standardItemId
        orderVehicles {
          orderVehicleId
          accountingLineNumber
          orderVehicleEquipments {
            orderVehicleEquipmentId
            equipmentCode
            unitPriceToCustomer
            quantity
            equipmentValue
          }
        }
        requisitionerAddress {
          countryCode
          addressLine1
          addressLine2
          entityName
          stateCode
          zipcode
          city
        }
        requisitionerContact {
          firstName
          lastName
          email
          phoneCountryCode
          phoneNumber
          phoneExtension
          faxCountryCode
          faxNumber
          faxExtension
        }
        mailingAddress {
          countryCode
          addressLine1
          addressLine2
          entityName
          stateCode
          zipcode
          city
        }
        mailingContact {
          firstName
          lastName
          email
          phoneCountryCode
          phoneNumber
          phoneExtension
          faxCountryCode
          faxNumber
          faxExtension
        }
        deliveryAddress {
          countryCode
          addressLine1
          addressLine2
          entityName
          stateCode
          zipcode
          city
        }
        deliveryContact {
          firstName
          lastName
          email
          phoneCountryCode
          phoneNumber
          phoneExtension
          faxCountryCode
          faxNumber
          faxExtension
        }
      }
    }
  }
`;

export const SEARCH_CONTRACT = gql`
  query StoreGetContractByContractNumber($contractNumber: String!) {
    storeGetContractByContractNumber(contractNumber: $contractNumber) {
      contractNumber
    }
  }
`;

export const UPDATE_ORDER_MODIFICATION = gql`
  mutation UpdateOrderModification(
    $updatedOrderModificationRequest: UpdateOrderModificationRequest!
  ) {
    updateOrderModification(
      updatedOrderModificationRequest: $updatedOrderModificationRequest
    ) {
      orderModName
      additionalComments
      submissionComments
      orderModStatus
      orderModificationId
      changeSets {
        orderChangeSetModId
        type
        modifications
        changeSetOrders {
          orderId
          orderChangeSetId
          changeSetId
          order {
            message1
            message2
            message3
            message4
            message5
            caseNumber
            contractLineId
            standardItemId
            orderStatus
            quantity
            unitPriceToCustomer
            requisitionType
            requisitionNumber
            orderVehicles {
              makeColorCode
              makeColorName
              makeColorPriceToCustomer
              makeColorPriceToGsa
              accountingLineNumber
              orderVehicleEquipments {
                quantity
                quantityType
                unitPriceToCustomer
                equipmentCode
                requiredBy
              }
            }
          }
        }
      }
      contractNumber
    }
  }
`;

export const SUBMIT_ORDER_MOD = gql`
  mutation SubmitOrderModification(
    $updatedOrderModificationRequest: UpdateOrderModificationRequest!
  ) {
    submitOrderModification(
      updatedOrderModificationRequest: $updatedOrderModificationRequest
    ) {
      modificationId
      processOrderModificationResponses {
        changeSetId
        changeSetType
        hasError
        orderModificationErrorLog {
          error
          orderId
        }
        orderModificatioSuccessLog {
          newOrderId
          newOrderVersion
          oldVersion
          previousOrderId
        }
      }
    }
  }
`;
export const GET_ASSIGNED_ENGINEERS = gql`
  query {
    getStandardsVehicleGroupPocs {
      rows {
        standardVgPocId
        standardCodeId
        pocType
        name
        email
        standardsCode {
          id
        }
      }
    }
  }
`;

export const GET_OPTIONS_BY_CONTRACT_NUMBER = gql`
  query StoreGetContractLineEquipment($contractLineId: Int!) {
    storeGetContractLineEquipment(contractLineId: $contractLineId) {
      equipment {
        id
        equipmentCode
        title
        tags
        quantityRequired
        additionalShippingDays
        unitPrice
        inputType
        optionType
      }
    }
  }
`;

// export const GET_OPTIONS_BY_CONTRACT_NUMBER = gql`
//   query getContractLineOptionsForMod(
//     $contractLineId: Float!
//     $selectedOrders: [String!]!
//   ) {
//     getContractLineOptionsForMod(
//       contractLineId: $contractLineId
//       selectedOrders: $selectedOrders
//     ) {
//       standardItemEquipmentCode {
//         equipmentCode {
//           code
//           title
//           tags {
//             value
//           }
//           quantityRequired
//         }
//       }
//       additionalShippingDays
//       unitPrice
//       inputType
//       id
//     }
//   }
// `;

export const GET_ORDER_MOD_ATTACHMENT_TYPES = gql`
  query GetAllOrderModAttachmentTypes {
    getAllOrderModAttachmentTypes {
      id
      type
      description
    }
  }
`;

export const FETCH_SIGNED_URL_FOR_ORDER_MODS = gql`
  mutation GenerateSignedUrlForOrderMods(
    $input: GenerateOrderModSignedUrlInput!
  ) {
    generateSignedUrlForOrderMods(input: $input) {
      signedUrl
      metadataId
    }
  }
`;
export const GET_CHANGE_SET_BY_ID = gql`
  query GetChangeSetById($changeSetId: String!) {
    getChangeSetById(changeSetId: $changeSetId) {
      orderChangeSetModId
      orderModificationId
      modifications
      status
      type
      additionalComments
      changeSetOrders {
        orderId
        orderChangeSetId
        changeSetId
        order {
          message1
          message2
          message3
          message4
          message5
          orderId
          caseNumber
          quantity
          unitPriceToCustomer
          orderStatus
          scheduleLine
          contractLineId
          requisitionType
          requisitionNumber
          standardItemId
          bureauInfo {
            name
          }
          orderVehicles {
            makeColorCode
            makeColorName
            makeColorPriceToCustomer
            makeColorPriceToGsa
            accountingLineNumber
            orderVehicleEquipments {
              quantity
              quantityType
              unitPriceToCustomer
              equipmentCode
              requiredBy
            }
          }
          requisitionerAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          requisitionerContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          mailingAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          mailingContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          deliveryAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          deliveryContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
        }
      }
    }
  }
`;
export const VALIDATE_CHANGE_SET_ORDERS = gql`
    query validateChangeSetOrders($orderIds: [String!]!, $changeSetId: String!) {
        validateChangeSetOrders(orderIds: $orderIds, changeSetId: $changeSetId) {
            invalidCaseNumbers
        }
    }
`
export const CREATE_CHANGE_SET = gql`
  mutation CreateOrderModChangeSet(
    $changeSet: ChangeSet!
    $modificationId: String!
  ) {
    createOrderModChangeSet(
      changeSet: $changeSet
      modificationId: $modificationId
    ) {
      orderChangeSetModId
      orderModificationId
      modifications
      status
      additionalComments
      changeSetOrders {
        orderId
        orderChangeSetId
        changeSetId
        order {
          message1
          message2
          message3
          message4
          message5
          orderId
          caseNumber
          contractLineId
          quantity
          unitPriceToCustomer
          orderStatus
          requisitionType
          requisitionNumber
          standardItemId
          bureauInfo {
            name
          }
          orderVehicles {
            makeColorCode
            makeColorName
            makeColorPriceToCustomer
            makeColorPriceToGsa
            accountingLineNumber
            orderVehicleEquipments {
              quantity
              quantityType
              unitPriceToCustomer
              equipmentCode
              requiredBy
            }
          }
          requisitionerAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          requisitionerContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          mailingAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          mailingContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          deliveryAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          deliveryContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
        }
      }
    }
  }
`;

export const UPDATE_CHANGE_SET = gql`
  mutation updateOrderModChangeSet($changeSet: ChangeSet!) {
    updateOrderModChangeSet(changeSet: $changeSet) {
      orderChangeSetModId
      orderModificationId
      modifications
      status
      type
      additionalComments
      changeSetOrders {
        orderId
        orderChangeSetId
        changeSetId
        order {
          message1
          message2
          message3
          message4
          message5
          orderId
          caseNumber
          quantity
          contractLineId
          unitPriceToCustomer
          orderStatus
          requisitionType
          requisitionNumber
          standardItemId
          bureauInfo {
            name
          }
          orderVehicles {
            makeColorCode
            makeColorName
            makeColorPriceToCustomer
            makeColorPriceToGsa
            accountingLineNumber
            orderVehicleEquipments {
              quantity
              quantityType
              unitPriceToCustomer
              equipmentCode
              requiredBy
            }
          }
          requisitionerAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          requisitionerContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          mailingAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          mailingContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          deliveryAddress {
            countryCode
            addressLine1
            addressLine2
            entityName
            stateCode
            zipcode
            city
          }
          deliveryContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
        }
      }
    }
  }
`;

export const UPDATE_CHANGE_SET_ORDERS = gql`
  mutation updateOrderModChangeSetOrders($changeSetOrders: ChangeSetOrders!) {
    updateOrderModChangeSetOrders(changeSetOrders: $changeSetOrders) {
      orderChangeSetModId
      orderModificationId
      modifications
      status
      type
      additionalComments
      changeSetOrders {
        orderId
        orderChangeSetId
        changeSetId
        order {
          message1
          message2
          message3
          message4
          message5
          orderId
          caseNumber
          quantity
          contractLineId
          unitPriceToCustomer
          orderStatus
          requisitionType
          requisitionNumber
          standardItemId
          bureauInfo {
            name
          }
          orderVehicles {
            makeColorCode
            makeColorName
            makeColorPriceToCustomer
            makeColorPriceToGsa
            accountingLineNumber
            orderVehicleEquipments {
              quantity
              quantityType
              unitPriceToCustomer
              equipmentCode
              requiredBy
            }
          }
          requisitionerAddress {
            countryCode
            addressLine1
            addressLine2
            stateCode
            zipcode
            entityName
            city
          }
          requisitionerContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          mailingAddress {
            countryCode
            addressLine1
            addressLine2
            stateCode
            entityName
            zipcode
            city
          }
          mailingContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
          deliveryAddress {
            countryCode
            addressLine1
            addressLine2
            stateCode
            entityName
            zipcode
            city
          }
          deliveryContact {
            firstName
            lastName
            email
            phoneCountryCode
            phoneNumber
            phoneExtension
            faxCountryCode
            faxNumber
            faxExtension
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER_MOD_ATTACHMENT = gql`
  mutation CreateOrderModAttachment($input: OrderModAttachmentInput!) {
    createOrderModAttachment(input: $input) {
      orderModAttachmentId
      changeSetId
      metadataId
      description
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      deletedAt
      signedUrl
    }
  }
`;

export const UPDATE_ORDER_MOD_ATTACHMENT_DESCRIPTION = gql`
  mutation updateOrderModAttachmentDescription(
    $attachmentId: String!
    $description: String!
  ) {
    updateOrderModAttachmentDescription(
      attachmentId: $attachmentId
      description: $description
    ) {
      orderModAttachmentId
    }
  }
`;

export const DELETE_ORDER_MOD_ATTACHMENT = gql`
  mutation deleteOrderModAttachment($attachmentId: String!) {
    deleteOrderModAttachment(attachmentId: $attachmentId) {
      orderModAttachmentId
    }
  }
`;

export const GET_ORDER_MOD_ATTACHMENTS = gql`
  query GetAllOrderModAttachments($orderModificationId: String!) {
    getAllOrderModAttachments(orderModificationId: $orderModificationId) {
      orderModAttachmentId
      changeSetId
      orderModificationId
      status
      metadataId
      signedUrl
      description
      createdUserInfo {
        id
        email
        firstName
        lastName
        fullName
      }
      createdAt
      attachmentType {
        type
        description
      }
      fileMetadata
    }
  }
`;
export const GET_PROGRAM_NAME_BY_STD_CODE = gql`
  query Query($fedStdCode: String!) {
    getProgramName(fedStdCode: $fedStdCode)
  }
`;

export const GET_ORDER_MODIFICATION_BY_ID = gql`
  query GetOrderModificationById($modificationId: String!) {
    getOrderModificationById(modificationId: $modificationId) {
      orderModificationId
      orderModName
      additionalComments
      submissionComments
      orderModStatus
      contractNumber
      changeSets {
        orderChangeSetModId
        type
        additionalComments
        modifications
        changeSetOrders {
          orderId
          orderChangeSetId
          changeSetId
          order {
            message1
            message2
            message3
            message4
            message5
            caseNumber
            contractLineId
            quantity
            unitPriceToCustomer
            orderStatus
            requisitionType
            requisitionNumber
            standardItemId
            bureauInfo {
              name
            }
            orderVehicles {
              makeColorCode
              makeColorName
              makeColorPriceToCustomer
              makeColorPriceToGsa
              accountingLineNumber
              orderVehicleEquipments {
                quantity
                quantityType
                unitPriceToCustomer
                equipmentCode
                requiredBy
              }
            }
            requisitionerAddress {
              countryCode
              addressLine1
              addressLine2
              entityName
              stateCode
              zipcode
              city
            }
            requisitionerContact {
              firstName
              lastName
              email
              phoneCountryCode
              phoneNumber
              phoneExtension
              faxCountryCode
              faxNumber
              faxExtension
            }
            mailingAddress {
              countryCode
              addressLine1
              addressLine2
              entityName
              stateCode
              zipcode
              city
            }
            mailingContact {
              firstName
              lastName
              email
              phoneCountryCode
              phoneNumber
              phoneExtension
              faxCountryCode
              faxNumber
              faxExtension
            }
            deliveryAddress {
              countryCode
              addressLine1
              addressLine2
              entityName
              stateCode
              zipcode
              city
            }
            deliveryContact {
              firstName
              lastName
              email
              phoneCountryCode
              phoneNumber
              phoneExtension
              faxCountryCode
              faxNumber
              faxExtension
            }
          }
        }
      }
    }
  }
`;
export const GET_ORDER_MODIFICATION_DETAILS = gql`
  query GetOrderModificationDetails($modificationId: String!) {
    getOrderModificationDetails(modificationId: $modificationId) {
      orderModificationId
      orderModName
      additionalComments
      submissionComments
      orderModStatus
      contractNumber
      changeSets {
        orderChangeSetModId
        type
        additionalComments
        modifications
        changeSetOrders {
          orderId
          orderChangeSetId
          changeSetId
          order {
            message1
            message2
            message3
            message4
            message5
            caseNumber
            contractLineId
            quantity
            unitPriceToCustomer
            orderStatus
            requisitionType
            requisitionNumber
            standardItemId
            bureauInfo {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_MOD_CHANGESETS = gql`
  query GetOrderModificationById($modificationId: String!) {
    getOrderModChangeSets(modificationId: $modificationId) {
      orderChangeSetModId
      status
      type
      additionalComments
      orderModificationId
      changeSetOrders {
        changeSetId
        orderId
        order {
          message1
          message2
          message3
          message4
          message5
          orderId
          quantity
          contractLineId
          unitPriceToCustomer
          orderStatus
          requisitionType
          standardItemId
        }
      }
    }
  }
`;

export const CANCEL_ORDER_MODIFICATION = gql`
  mutation CancelOrderMod($orderModId: String!) {
    cancelOrderMod(orderModId: $orderModId)
  }
`;

export const CANCEL_ORDER_MOD_CHANGESET = gql`
  mutation CancelOrderModChangeSet($changeSetId: String!) {
    cancelOrderModChangeSet(changeSetId: $changeSetId)
  }
`;

export const GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE = gql`
  query GerDealershipCitiesByStateAndMake(
    $stateId: Float!
    $make: Float!
    $acceptsEv: Boolean
  ) {
    getDeliveryDealerships(
      dealershipInput: {
        stateId: $stateId
        makeCode: $make
        acceptsEv: $acceptsEv
      }
    ) {
      city
    }
  }
`;

export const GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE_CITY = gql`
  query GetDeliveryDealershipsByStateAndCityAndMake(
    $state: Float!
    $make: Float!
    $city: String!
    $acceptsEv: Boolean
  ) {
    getDeliveryDealerships(
      dealershipInput: {
        stateId: $state
        makeCode: $make
        city: $city
        acceptsEv: $acceptsEv
      }
    ) {
      id
      city
      email
      phone
      address1
      address2
      city
      stateId
      countryId
      dealerName
      dealershipDeliveryCode
      postalCode
      state {
        id
        stateName
        stateCode
      }
      country {
        countryName
        id
      }
    }
  }
`;

export const GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE = gql`
  query getDeliveryDealershipByDealershipCode($dealershipCode: String!) {
    getDeliveryDealershipByDealershipCode(dealershipCode: $dealershipCode) {
      id
      city
      email
      phone
      address1
      address2
      city
      stateId
      countryId
      dealerName
      dealershipDeliveryCode
      postalCode
      state {
        id
        stateName
        stateCode
      }
      country {
        countryName
        id
      }
    }
  }
`;

export const VALIDATE_REQUISITION_NUMBER = gql`
  query ValidateRequisitionNumber(
    $requisitionId: String
    $requisitionNumber: String!
  ) {
    validateRequisitionNumber(
      requisitionId: $requisitionId
      requisitionNumber: $requisitionNumber
    )
  }
`;

export const UPDATE_REQUISITION_CLIENT_STATE = gql`
  mutation UpdateRequisitionClientState(
    $clientState: JSONObject!
    $requisitionId: String!
  ) {
    updateRequisitionClientState(
      clientState: $clientState
      requisitionId: $requisitionId
    ) {
      resubmittedStatus
    }
  }
`;

export const VALIDATE_REQUISITION = gql`
  query ValidateRequisition($requisitionId: String!) {
    validateRequisition(requisitionId: $requisitionId) {
      requisitionCartValidations {
        validationErrors
      }
    }
  }
`;

export const GET_REQUISITION_MESSAGE_LINES = gql`
  query GetRequisitionMessageLines($requisitionId: String!) {
    getRequisitionMessageLinesById(requisitionId: $requisitionId) {
      requisitionId
      requisitionMessageLinesId
      messageLine1
      messageLine2
      messageLine3
      messageLine4
      messageLine5
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_REQUISITION_MESSAGE_LINE = gql`
  mutation UpdateRequisitionMessageLines($data: ReqMessageLinesInput!) {
    updateRequisitionMessageLines(data: $data)
  }
`;

export const GET_ORDER_MESSAGE_DETAILS = gql`
  query getOrder($orderId: String!) {
    getOrderById(orderId: $orderId) {
      orderId
      message1
      message2
      message3
      message4
      message5
      updatedBy
      updatedAt
    }
  }
`;

export const UPDATE_ORDER_MESSAGES = gql`
  mutation UpdateOrderMessages(
    $orderId: String!
    $messages: UpdateOrderMessagesDataInput!
  ) {
    updateOrderMessages(orderId: $orderId, messages: $messages) {
      orderId
      message1
      message2
      message3
      message4
      message5
      updatedBy
      updatedAt
    }
  }
`;

export const GET_STORE_REPORTS = gql`
  query GetStoreReportList {
    getStoreReportList {
      id
      name
      subject
    }
  }
`;

export const GENERATE_STORE_REPORT = gql`
  query GenerateStoreReport($input: StoreReportInput!) {
    generateStoreReport(input: $input)
  }
`;

export const GET_VEHICLE_BY_FILTER = gql`
  query GetVehicleByFilter($filters: [Filter!]!) {
    getVehicleByFilter(filters: $filters) {
      id
      tagNumber
      vin
      fmcId
      isTelematicsExempt
      standardItem {
        standardItemNumber
      }
      fleetManagementCenter {
        id
        address1
        address2
        address3
        name
        city
        stateCode
        zipCode
        countryCode
        zoneId
      }
      fsrUserEmail
      fsrUserDetails {
        phoneNumber
        email
        firstName
        lastName
      }
      customer {
        customerId
        regionId
        legacyCustomerNumber
        accountName
        boac
        address1
        address2
        city
        postalCode
        customerState {
          stateCode
        }
        fsrUserEmail
        fsrUser {
          fullName
          firstName
          lastName
          phoneNumber
          phoneNumberExt
          email
        }
        customerAgency {
          id
          name
        }
        customerBureau {
          id
          name
          agencyIndicatorCode
        }
        customerPhysicalOffice {
          officeCode
          officeName
        }
      }
      primaryPoC {
        fullName
        email
        primaryPhone
        secondaryPhone
        primaryPhoneExt
      }
    }
  }
`;

export const CHECK_REQUISITION_NUMBER = gql`
  query CheckRequisitionNumber($requisitionNumber: String!) {
    checkRequisitionNumber(requisitionNumber: $requisitionNumber) {
      found
    }
  }
`;

export const GET_ZONES = gql`
  query GetZones {
    getZones {
      id
      name
    }
  }
`;
export const CHECK_ORDER_NUMBER = gql`
  query CheckOrderNumber($orderNumber: String!) {
    checkOrderNumber(orderNumber: $orderNumber) {
      found
    }
  }
`;

export const GET_CONTRACT_RENEWAL_YEAR = gql`
  query GetContractRenewalYears {
    getContractRenewalYears {
      renewalYear
    }
  }
`;

export const GET_REQUISITION_SINS_TYPEAHEAD_FOR_YEAR = gql`
  query GetRequisitionSinsTypeahead($contractYear: Float!) {
    getRequisitionSinsTypeaheadForYear(contractYear: $contractYear) {
      fedStandardCode
      id
      orderStartDate
      standardItemNumber
      status
      tags
      title
      vehicleType
      year
    }
  }
`;

export const GET_ORDER_GUIDE_FOR_SIN_AND_YEAR = gql`
  query GetContractForSINandYear(
    $offset: Int
    $limit: Int
    $sin: String!
    $contractYear: Float!
    $orderBy: String
    $order: String
  ) {
    getContractForSINandYear(
      offset: $offset
      limit: $limit
      sin: $sin
      contractYear: $contractYear
      orderBy: $orderBy
      order: $order
    ) {
      count
      rows {
        modelCode
        modelYear
        msrp
        contractModId
        contractLineId
        scheduleLine
        standardItemNumber
        unitPrice
        vendorId
        vendorName
        afvBase
        isLeaseAvailable
      }
    }
  }
`;
export const GET_CONTRACT_EC_FOR_GUIDE = gql`
  query Query(
    $contractLineId: Float!
    $ecCode: String
    $orderBy: String
    $orderDirection: String
  ) {
    getContractLineECsForGuide(
      contractLineId: $contractLineId
      ecCode: $ecCode
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      contractLineECId
      ecCode
      ecTitle
      unitPrice
      isLeaseAvailable
      afvByEquipment
    }
  }
`;

export const UPDATE_LEASING_AVAILABILITY = gql`
  mutation StoreUpdateLeasingAvailability($contractLineId: Int!, $isLeaseAvailable: Int!) {
    storeUpdateLeasingAvailability(contractLineId: $contractLineId, isLeaseAvailable: $isLeaseAvailable) {
      id
    }
  }
`;

export const UPDATE_AFV_BASE = gql`
  mutation StoreUpdateContractLineAFV($afvBase: Float!, $contractLineId: Int!) {
    storeUpdateContractLineAFV(afvBase: $afvBase, contractLineId: $contractLineId) {
      id
    }
  } 
`;

export const UPDATE_LEASING_AVAILABILITY_EC = gql`
  mutation UpdateLeasingAvailabilityForEC(
    $contractLineEcArgs: [ContractLineECArgs!]!
  ) {
    updateLeasingAvailabilityForEC(contractLineECArgs: $contractLineEcArgs)
  }
`;

export const VEHICLE_EXISTS_IN_REQUISITION = gql`
  query VehicleExistsInRequisition(
    $assetId: String!
    $serialNumberVin: String
  ) {
    vehicleExistsInRequisition(
      assetId: $assetId
      serialNumberVin: $serialNumberVin
    ) {
      requisition {
        friendlyName
      }
    }
  }
`;

export const CREATE_LEASING_REQUISITION_DRAFT = gql`
  mutation CreateLeasingRequisitionDraft(
    $createLeasingCustomerAccountInput: CreateRequisitionLeasingCustomerAccountData!
    $createInput: RequisitionCreatePropsInput!
  ) {
    createLeasingRequisitionDraft(
      createLeasingCustomerAccountInput: $createLeasingCustomerAccountInput
      createInput: $createInput
    ) {
      requisitionId
      requisitionLeasingCustomerAccount {
        customerAccountId
        agencyCode
        bureauCode
        officeCode
        fsrContact {
          firstName
          lastName
          email
          phoneNumber
          phoneExtension
        }
        vehicleReplacements {
          requisitionLeasingVehicleReplacementId
          assetId
          serialNumberVin
          tagNumber
        }
      }
    }
  }
`;

export const GET_LEASING_REQUISITION = gql`
  query GetLeasingRequisition($requisitionId: String!) {
    getRequisition(requisitionId: $requisitionId) {
      requisitionId
      createdBy
      version
      requisitionType
      friendlyName
      agencyCode
      bureauCode
      officeCode
      leasingAgencyInfo {
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      leasingBureauInfo {
        shortName
        name
        agencyIndicatorCode
      }
      agencyInfo {
        name
      }
      bureauInfo {
        shortName
        name
        agencyIndicatorCode
      }
      officeInfo {
        officeName
      }
      leasingOfficeInfo {
        officeName
        address1
        address2
        address3
        city
        stateCode
        postalCode
      }
      requisitionNumber
      agencyOrderNumber
      finSignalCode
      finFundCode
      requisitionStatus
      totalSellingPrice
      clientData {
        selectedOptions
        activeContracts
        clientState
      }
      vehicles {
        requisitionId
        makeColorCode
        makeColorName
        makeColorPriceToCustomer
        makeColorPriceToGsa
      }
      requisitionLeasingCustomerAccount {
        customerAccountId
        agencyCode
        bureauCode
        officeCode
        fsrContact {
          firstName
          lastName
          email
          phoneNumber
          phoneExtension
        }
        vehicleReplacements {
          requisitionLeasingVehicleReplacementId
          assetId
          serialNumberVin
          tagNumber
        }
      }
      standardItemId
      contractLineId
      quantity
      totalSellingPrice
      purchaseRate
      transactionType
      contractLine {
        id
        orderStartDate
        orderEndDate
      }
    }
  }
`;

export const GET_LEASING_REQUISITION_TABLE_DETAILS = gql`
  query GetLeasingRequisitionTableDetails($requisitionId: String!) {
    getLeasingRequisitionTableDetails(requisitionId: $requisitionId) {
      contractLine {
        id
        orderStartDate
        orderEndDate
      }
      contractLineId
      agencyCode
      bureauCode
      officeCode

      leasingAgencyInfo {
        id
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      leasingBureauInfo {
        id
        shortName
        name
        agencyIndicatorCode
      }
      leasingOfficeInfo {
        officeCode
        officeName
        address1
        address2
        address3
        city
        stateCode
        postalCode
      }
      requisitionLeasingCustomerAccount {
        vehicleReplacements {
          assetId
        }
      }
    }
  }
`;

export const CREATE_LEASING_REPLACEMENT_VEHICLE = gql`
  mutation CreateRequisitionLeasingVehicleReplacement(
    $data: CreateRequisitionLeasingVehicleReplacementData!
  ) {
    createRequisitionLeasingVehicleReplacement(data: $data) {
      assetId
    }
  }
`;

export const DELETE_LEASING_REPLACEMENT_VEHICLE = gql`
  mutation DeleteRequisitionLeasingVehicleReplacement(
    $requisitionLeasingVehicleReplacementId: String!
  ) {
    deleteRequisitionLeasingVehicleReplacement(
      requisitionLeasingVehicleReplacementId: $requisitionLeasingVehicleReplacementId
    )
  }
`;

export const GET_LEASING_REPLACEMENT_VEHICLES = gql`
  query GetReplacementVehiclesForRequisition(
    $requisitionId: String!
    $order: OrderBy!
    $offset: Int
    $limit: Int
  ) {
    getReplacementVehiclesForRequisition(
      requisitionId: $requisitionId
      order: $order
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        requisitionLeasingVehicleReplacementId
        assetId
        tagNumber
        standardItemNumber
        serialNumberVin
      }
      hasMore
    }
  }
`;

export const CANCEL_REQUISITION = gql`
  mutation CancelRequisition($requisitionId: String!) {
    cancelRequisition(requisitionId: $requisitionId)
  }
`;

export const GET_ALL_VENDOR_MARSHALLER_LOCATIONS = gql`
  query GetAllVendorMarshallerLocations {
    getAllVendorMarshallerLocations {
      vendor_marshaller_id
      fmc_poc_contact_phone
      fmc_poc_contact_phone_extension
      fmc_poc_email_address
      fmc_poc_first_name
      fmc_poc_last_name
      fmc_poc_phone_country_code
      management_center_id
      is_telematics_installer
      accept_zev
      vendor_id
      vendor_contact_id
      state_code
      city
      address_1
      address_2
      address_3
      postal_code
      first_name
      last_name
      email_address
      contact_phone
      contact_phone_country_code
      contact_phone_extension
      iso_country_code_2
      vendor_name
      zone_id
      vendor_marshaller_location_id
      fax_number
      fax_number_country_code
      fax_number_extension
    }
  }
`;

export const GET_FLEET_MANAGEMENT_CENTERS = gql`
  query GetFleetManagementCenters {
    getFleetManagementCenters {
      id
      address1
      address2
      address3
      city
      stateCode
      zipCode
      name
      zoneId
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      firstName
      lastName
      email
      phoneNumberCountryCode
      phoneNumber
      phoneNumberExt
    }
  }
`;
export const SUBMIT_FMC_APPROVAL = gql`
  mutation SubmitForFmcApproval($requisitionId: String!) {
    submitForFmcApproval(requisitionId: $requisitionId)
  }
`;

export const SUBMIT_ZONAL_APPROVAL = gql`
  mutation SubmitForZonalApproval(
    $requisitionId: String!
    $submitComment: String
  ) {
    submitForZonalApproval(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const SUBMIT_ORDERING_ADMIN_APPROVAL = gql`
  mutation SubmitForOrderingAdminApproval(
    $requisitionId: String!
    $submitComment: String
  ) {
    submitForOrderingAdminApproval(
      requisitionId: $requisitionId
      submitComment: $submitComment
    )
  }
`;

export const SUBMIT_LEASING_REQ_TO_GSA = gql`
  mutation SubmitLeasingReqToGSA(
    $requisitionId: String!
    $approvalComment: String
  ) {
    submitLeasingReqToGsa(
      requisitionId: $requisitionId
      approvalComment: $approvalComment
    ) {
      operationStatus
    }
  }
`;

export default dataStore;
