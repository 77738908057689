import React from 'react';
import { formatPhoneNumber } from '../../../../components/ReviewComponents/AgencyFinancial/agencyFinancialUtils';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';

const ReviewOrderingInfo = ({ account }) => {
  const customerData = account?.customer;
  const primaryPOC = account?.primaryPoC;
  const orderPOC = account?.customer?.fsrUser;
  
  return (
    <>
      <div className="title-section">Leasing customer agency</div>
      <div role="region" tabIndex="0" className="ordering-info">
        <div className="leasing-customer-agency">
          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Agency</div>
              <div className="title-desc">
                {[customerData?.customerAgency?.id, customerData?.customerAgency?.name].filter(Boolean).join(' - ') || emDashUnicode}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Bureau</div>
              <div className="title-desc">
                {[customerData?.customerBureau?.id, customerData?.customerBureau?.name].filter(Boolean).join(' - ') || emDashUnicode}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Office</div>
              <div className="title-desc title-number">
                {[customerData?.customerPhysicalOffice?.officeCode, customerData?.customerPhysicalOffice?.officeName].filter(Boolean).join(' - ') || emDashUnicode}
              </div>
            </div>
          </div>

          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Customer account</div>
              <div className="title-desc">{customerData?.accountName}</div>
              <div>{customerData?.address1}</div>
              <div>{customerData?.address2}</div>
              <div>{customerData?.city}, {customerData?.postalCode}</div>
            </div>

            <div className="row-column">
              <div className="row-title">Primary point of contact</div>
              <div className="title-desc">
                <div>{primaryPOC?.fullName}</div>
                <div>{primaryPOC?.email}</div>
                {primaryPOC?.primaryPhone && (
                  <div>
                    {formatPhoneNumber(parseInt(primaryPOC?.primaryPhone))} ext.
                    {primaryPOC?.primaryPhoneExt}
                  </div>
                )}
                <div>
                  {formatPhoneNumber(parseInt(primaryPOC?.secondaryPhone))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="title-section top-padding">Billing information</div>
      <div role="region" tabIndex="0" className="ordering-info">
        <div className="billing-information">
          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Agency</div>
              <div className="title-desc">{account?.agency?.agencyCode}-{account?.agency?.agencyName}</div>
            </div>
            <div className="row-column">
              <div className="row-title">Bureau</div>
              <div className="title-desc">{account?.bureau?.bureauCode}-{account?.bureau?.bureauName}</div>
            </div>
            <div className="row-column">
              <div className="row-title">Office</div>
              <div className="title-desc">{account?.office?.officeCode}-{account?.office?.officeName}</div>
            </div>
          </div>

          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Agency order number</div>
              <div className="title-desc">
                {account?.agencyOrderNumber || '-'}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Requisition number</div>
              <div className="title-desc">
                {account?.requisitionNumber || '-'}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Signal code</div>
              <div className="title-desc title-number">
                {account?.finSignalCode || '-'}
              </div>
            </div>
          </div>

          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Fund code</div>
              <div className="title-desc">{account?.finFundCode || '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <div role="region" tabIndex="0" className="ordering-info">
        <div className='order-point-of-contact'> 
          <div className="title-section top-padding">Order point of contact</div>
          <div className="title-desc">
            <div>
              {orderPOC?.fullName || emDashUnicode}
            </div>
            <div>{orderPOC?.email}</div>
            <div>{formatPhoneNumber(parseInt(orderPOC?.phoneNumber))}</div>
            <div>{orderPOC?.secondaryPhone}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewOrderingInfo;
