import { PageTitle } from '@gsa/afp-component-library';
import VehicleRequestTabs from './components/vehicle-selection/vehicle-tabs';
import Breadcrumbs from '../leasing/components/Breadcrumbs';
import ContactBuyingPopover from "../../components/ContactBuyingPopover/ContactBuyingPopover";

export default function VehicleReferral() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
        <ContactBuyingPopover />
      </div>
      <div>
        <PageTitle title="Leased vehicle request details" />
        <div>
          Use this feature to view vehicles reffered for replacement, request a
          replacement, and review and approve pending requests
        </div>
      </div>
      {/* referred vehicle section placeholder */}
      <VehicleRequestTabs />
    </>
  );
}
