import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import './FieldFeedback.scss';

const StyledChildren = ({ children, applyTo, visible, useKey }) => {
  const newChildren = [];
  React.Children.forEach(children, (child, i) => {
    const newProps = { id: `${useKey}-${i + 1}`, key: `${useKey}-${i + 1}` };

    if (
      (typeof applyTo === 'function' && applyTo(child)) ||
      applyTo === undefined
    ) {
      if (!visible) {
        if (child.props.className) {
          newProps.className =
            child.props.className.replace('usa-input--error');
        }
      } else if (!child?.props?.skipfeedback) {
        newProps.className = `${child.props.className || ''} usa-input--error`;
      }
    }

    let clone = null;
    if (child.props && child.props.children) {
      clone = React.cloneElement(
        child,
        { ...child.props, ...newProps },
        <StyledChildren
          key={`${useKey}-${i + 1}`}
          useKey={`${useKey}-${i + 1}`}
          applyTo={applyTo}
          visible={visible}
        >
          {child.props.children}
        </StyledChildren>,
      );
    } else if (child.props) {
      clone = React.cloneElement(child, { ...child.props, ...newProps });
    } else {
      // pure text element
      clone = React.createElement('span', { ...newProps }, child);
    }

    newChildren.push(clone);
  });

  return newChildren;
};

const FieldFeedback = ({
  position,
  visible,
  text,
  children,
  applyTo,
  id,
  isVehicleContext,
}) => {
  const context = useContext(VehicleRequisitionContext);
  const useKeyRandom = '0'; // String.fromCharCode(65 + Math.floor(Math.random() * 25));
  let positionClass = {
    top: 'c-tooltip-top',
    right: 'c-tooltip-right',
    bottom: 'c-tooltip-bottom',
    left: 'c-tooltip-left',
  }[position];
  if (!positionClass) positionClass = 'c-tooltip-top';
  const styledChildren = (
    <StyledChildren
      key={`${id}-${useKeyRandom}`}
      useKey={`${id}-${useKeyRandom}`}
      visible={visible === 'error'}
      applyTo={applyTo}
    >
      {children}
    </StyledChildren>
  );

  return (
    <div key={`${id}-feedback-wrapper-0`} className="feedback-wrapper">
      <div
        key={`${id}-tooltip-0`}
        className={`c-tooltip-info ${positionClass}`}
      >
        <div key={`${id}-tooltip-icon-0`} className="c-tooltip-icon">
          {/* <WarnIcon width={15} height={15} /> */}
        </div>
        <div key={`${id}-tooltip-text-0`} className="c-tooltip-text">
          {text}
        </div>
      </div>

      {visible === 'error' ? (
        <div key={`${id}-wrap-0`} className="usa-form-group--error">
          {text ? <span className="usa-error-message">{text}</span> : null}
          {id === 'requisitionNumberFeedback' && isVehicleContext && (
            <Button
              data-testid="requisitionNumberFeedback"
              variant="unstyled"
              label="View format details"
              onClick={() => {
                context?.dispatch({
                  type: VehicleRequisitionContextActions.SET_REQ_NUMBER_VALIDATIONS_MODAL_OPEN,
                  payload: !context?.state?.isReqNumberModalOpen,
                });
              }}
            />
          )}
          {styledChildren}
        </div>
      ) : (
        <div key={`${id}-wrap-0`}>{styledChildren}</div>
      )}
    </div>
  );
};

export default FieldFeedback;

FieldFeedback.propTypes = {
  position: PropTypes.string,
  visible: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  applyTo: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  isVehicleContext: PropTypes.bool,
};

FieldFeedback.defaultProps = {
  position: 'top',
  visible: false,
  text: '',
  children: [],
  applyTo: null,
  isVehicleContext: true,
};
