import { useMutation } from '@apollo/client';
import { PROCESS_BULK_ORDERLINE } from '../../../../services/data-layer';

export const processDataForFile = (response) => {
  const uploadResponse = [];
  if (response?.length > 0) {
    response.forEach((resp) => {
      // eslint-disable-next-line no-underscore-dangle
      if (resp?.__typename === 'BulkUpdateVehicleResponse') {
        uploadResponse.push({
          id: resp.data?.id,
          accountingLineNumber: resp.data?.accountingLineNumber,
          caseNumber: resp.data?.caseNumber,
          status: 'Success',
          errors: '',
        });
      } else {
        let errorMessage = '';
        resp?.errorResponse?.validationErrors?.errors?.forEach((error) => {
          errorMessage += `${error.message}, `;
        });
        uploadResponse.push({
          id: resp.errorResponse?.id,
          accountingLineNumber: resp.errorResponse?.accountingLineNumber,
          caseNumber: resp.errorResponse?.caseNumber,
          status: 'Error',
          errors: errorMessage.substring(0, errorMessage.length - 2),
        });
      }
    });
  }
  return uploadResponse;
};

const useOrderLineDataProcessor = (successCallBack, errorCallBack) => {
  const [process, { loading }] = useMutation(PROCESS_BULK_ORDERLINE, {
    onCompleted: (data) => {
      successCallBack(data.updateOrderVehicleStatus);
    },
    onError: (error) => {
      errorCallBack(error);
    },
  });

  const handleSystemErrors = (batchData, startingIndex) => {
    const data = [];
    batchData.forEach((item, index) => {
      const error = {
        errorResponse: {
          id: startingIndex + index,
          accountingLineNumber: item?.accountingLineNumber,
          caseNumber: item?.caseNumber,
          validationErrors: {
            errors: [
              {
                message:
                  'System Error. Please try to upload again.  If you continue to experience technical difficulties with this page, please contact the GSA Fleet Technical Support team at fleet.helpdesk@gsa.gov or 866-472-6711 from 8:00 a.m. - 7:00 p.m. ET, Monday-Friday.',
              },
            ],
          },
        },
      };
      data.push(error);
    });
    return data;
  };

  return { process, loading, processDataForFile, handleSystemErrors };
};

export default useOrderLineDataProcessor;
