import {
    AFPTable, EmptyState,
    Pagination,
    Spinner
} from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';
import { emDashUnicode } from '../../../../../constants/constants';
import { getTableColumns } from './table-helpers';
import RemoveReplacementVehicleButton from './remove-replacement-vehicle-button';
import { useDispatch } from 'react-redux';
import {
    setReplacementVehicles,
    setReplacementVehiclesTableState,
    setVehicleQuantity
} from '../../../../../reducers/leasing';
import { useGetLeasingReplacementVehicles } from '../../../../../requests/leasing';

const columns = [
    {
        Header: 'License Plate',
        accessor: 'tagNumber',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
            return value ?? emDashUnicode;
        },
    },
    {
        Header: 'VIN',
        accessor: 'serialNumberVin',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
            return value ?? emDashUnicode;
        },
    },
    {
        Header: 'Old SIN',
        accessor: 'standardItemNumber',
        sortable: false,
        Cell: ({ value }) => {
            return value ?? emDashUnicode;
        },
    },
    {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        Cell: RemoveReplacementVehicleButton,
    },
];

const ReplacementVehicleTable = () => {
    const { selectedVehicles, replacementVehiclesTableState, requisitionId } = useSelector((state) => state.leasingReducer);
    const dispatch = useDispatch();
    const [getReplacementVehicles, { loading }] = useGetLeasingReplacementVehicles();

    return (
        <>
            <AFPTable
                columns={columns}
                data={!loading ? getTableColumns(selectedVehicles) : []}
            />

            {selectedVehicles.length === 0 && (
                <EmptyState
                    hasBackground
                    containerStyles="margin-top-neg-2 padding-y-10"
                    topText="Add vehicles to the table"
                />
            )}

            {loading && (
                <Spinner size='medium' className='margin-bottom-3' />
            )}

            <Pagination
                variant="advanced"
                itemsCount={replacementVehiclesTableState.total}
                itemsPerPage={replacementVehiclesTableState.limit}
                currentPage={replacementVehiclesTableState.currentPage}
                isReset={replacementVehiclesTableState.isReset}
                onPageChange={(page, limit) => {
                    if (requisitionId) {
                        getReplacementVehicles({
                            variables: {
                                requisitionId,
                                order: replacementVehiclesTableState.order,
                                limit: limit,
                                offset: (page - 1) * limit,
                            }
                        }).then(({ data }) => {
                            const replacementVehicles = data.rows;
                            if(replacementVehicles?.length > 0){
                                dispatch(setReplacementVehicles(replacementVehicles))
                                dispatch(setReplacementVehiclesTableState({
                                    currentPage: page,
                                    limit,
                                    isReset: false,
                                }))
                                dispatch(setVehicleQuantity(replacementVehicles.length));
                            }

                        });
                    }
                }}
            />
        </>
    )
}

export default ReplacementVehicleTable;