import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import './CommentModal.scss';

const CommentModal = ({
  isOpen,
  onSubmit,
  onCancel,
  defaultValue,
  title,
  body,
  maxLength,
  inputProps,
  isRequired,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState(defaultValue);
  const [showError, setShowError] = useState(null);
  const testId = props['data-testid'] || 'add-comment';

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleCommentChange = (event) => {
    const { value } = event?.target;
    setComment(value.substring(0, maxLength));
  };

  const handleSubmit = () => {
    if (!isRequired || comment.length) {
      setShowModal(false);
      if (onSubmit && typeof onSubmit === 'function') {
        onSubmit(comment);
        setComment('');
      }
    } else {
      setShowError('Please enter comments to post');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
      setComment('');
    }
  };

  return (
    <>
      {showModal && (
        <div className="afp-modal-overlay modalContainer" data-testid={testId}>
          <Modal
            title={title}
            className="add-comment-modal"
            onClose={handleCancel}
            actions={
              <div className="add-comment-button-row">
                <Button
                  type="button"
                  variant="unstyled"
                  data-testid={`${testId}-cancel-button`}
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Button
                  type="button"
                  variant="default"
                  className="margin-left-3"
                  data-testid={`${testId}-submit-button`}
                  onClick={handleSubmit}
                  label="Submit"
                />
              </div>
            }
          >
            <p>{body}</p>
            <form className="submit-comment-form">
              <TextInput
                type="textarea"
                placeholder="Type comment here..."
                value={comment}
                onChange={handleCommentChange}
                data-testid={`${testId}-input`}
                maxLength={maxLength}
                hint={`${maxLength} character${
                  maxLength === 1 ? '' : 's'
                } allowed`}
                errorMessage={showError}
                {...inputProps}
              />
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

CommentModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  'data-testid': PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
};

CommentModal.defaultProps = {
  isOpen: false,
  onSubmit: null,
  onCancel: null,
  isRequired: false,
  defaultValue: '',
  title: 'Add a new comment',
  body: 'Your comment will be added to this timeline and be viewable by involved parties.',
  maxLength: 500,
  'data-testid': null,
  inputProps: {},
};

export default CommentModal;
