export const extractVendorInfoFromActiveContracts = (
  activeContracts,
  selectedOptions,
) => {
  if (activeContracts.length === 0) {
    return { vendors: [], contractInfo: [] };
  }

  const contractInfo = activeContracts.reduce((accum, contractLine) => {
    const selectedContractOptions = contractLine.equipment.filter((option) =>
      selectedOptions.map((x) => x.optionCode).includes(option.equipmentCode),
    );
    return [
      ...accum,
      {
        vendorId: contractLine.contractMod.contract.vendor.id,
        vendorName: contractLine.contractMod.contract.vendor.vendorName,
        contractLineId: contractLine.id,
        baseShipmentDays: contractLine.shipmentDays,
        additionalShippingDays: Math.max(
          selectedContractOptions.map((x) => x.additionalShippingDays),
        ),
        makeCode: contractLine.makeCode,
        modelCode: contractLine.modelCode,
        makeModel: `${contractLine.makeCode}/${contractLine.modelCode}`,
        // options: contract.equipment,
      },
    ];
  }, []);

  const vendors = activeContracts
    .map((contract) => {
      const { id: value, vendorName: label } =
        contract?.contractMod?.contract?.vendor;
      return {
        value,
        label,
      };
    })
    .filter((vendor, index, originalArray) => {
      return (
        originalArray.findIndex(({ value }) => value === vendor.value) === index
      );
    });

  return { vendors, contractInfo };
};

// NOTE: There are two different forms of active contract. One with make and model as an
// object and the other with make and model as a string.
// Also, one with contractModification and the other with contractMod
export const groupContractsByVendorName = (activeContracts) => {
  if (!activeContracts?.length) return {};

  const contractsWithMakeModel = activeContracts.map((contract) => {
    const make = contract.makeCode;
    const model = contract.modelCode;
    return {
      makeModel: `${make}/${model}`,
      ...contract,
    };
  });

  if (!contractsWithMakeModel.length) return {};

  return Object.groupBy(contractsWithMakeModel, (contract) => {
    if (contract.contractModification) {
      return contract.contractModification.contract.vendor.vendorName;
    }

    if (contract.contractMod) {
      return contract.contractMod.contract.vendor.vendorName;
    }

    return 'Invalid vendor name';
  });
};

export const filterBuildableContracts = ({
  allActiveContracts,
  allCostBreakdown,
  contractCostBreakDowns,
}) => {
  const costBreakdown = contractCostBreakDowns || allCostBreakdown;

  const unbuildableModels =
    costBreakdown?.filter((cb) => cb.isUnBuildable) || [];

  const buildableContracts = allActiveContracts?.filter((contractLine) => {
    const foundCostBreakdown = unbuildableModels?.find(
      (model) => model.contractLineId === contractLine.contractLineId,
    );
    return !foundCostBreakdown;
  });

  return buildableContracts;
};

export const filterUnbuildableContracts = ({
  allActiveContracts,
  allCostBreakdown,
  contractCostBreakDowns,
}) => {
  const costBreakdown = contractCostBreakDowns || allCostBreakdown;

  if (!allActiveContracts) return [];
  const unbuildableModels =
    costBreakdown?.filter((cb) => cb.isUnBuildable) || [];

  const unbuildableContracts = allActiveContracts?.filter((contract) => {
    const foundCostBreakdown = unbuildableModels?.find(
      (model) => model.contractLineId === contract.contractLineId,
    );
    return !!foundCostBreakdown;
  });

  return unbuildableContracts;
};
