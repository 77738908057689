import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';
import {
  PageTitle,
  Spinner,
  Alert,
  SelectDropdown,
  Button,
  Modal,
  RadioButton,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import './PurchasePreFilter.scss';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import SearchBySIN from '../../components/SearchBySIN/SearchBySIN';
import Breadcrumbs from './widgets/Breadcrumbs';
import ShipmentOptions from './widgets/ShippmentOptions';
import useLocalState from '../../hooks/useLocalState';
import { useSessionState } from '../../hooks/useSessionState';
import ImageViewer from '../../utilities/ImageViewer';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
  GET_ORDERING_AGENCIES_BY_PERMISSION,
  GET_ORDERING_BUREAUS_BY_PERMISSION,
  GET_RECEIVING_AGENCIES,
  GET_RECEIVING_BUREAUS,
  GET_PROGRAM_NAME_BY_STD_CODE,
} from '../../services/data-layer';
import JustBrowsingAlert from '../../components/JustBrowsingAlert/JustBrowsingAlert';
import ShipmentInfoAlert from '../../components/ShipmentInfoAlert/ShipmentInfoAlert';
import {
  canCreateRequisition,
  canViewRequisition,
  canOrderOnBehalfRequisition,
} from './authorization';
import {
  GET_ACTIVE_VEHICLE_GROUPS,
  GET_STANDARD_ITEM_BY_ID,
} from '../../services/data-layer/standards';
import {
  StoreSubjects,
  VEHICLE_GROUP_TAG_STORE,
} from '../../constants/constants';
import FieldFeedback from '../../components/FieldFeedback/FieldFeedback';
import { useQueryParam } from '../../hooks/useQueryParam';
import { Purchase_Vehicles } from '../constants';

const { appURLs } = window.AFP_CONFIG;

const ReqType = {
  self: 'self',
  onBehalf: 'on-behalf',
};

const PurchasePreFilter = () => {
  const queryParams = useQueryParam();
  const { state } = useContext(VehicleRequisitionContext);
  const { isDomesticShipment } = state;
  const navigate = useNavigate();
  const [, updateVehicleListingFilter] = useLocalState('vehicleListingFilter');
  const [iptAckSession, setIptAckSession] = useSessionState(
    'nonSopAcknowledgment',
    false,
  );

  const { data, loading: loadingData } = useQuery(GET_ACTIVE_VEHICLE_GROUPS, {
    fetchPolicy: 'c',
    variables: {
      childTag: VEHICLE_GROUP_TAG_STORE,
    },
  });

  const vehicleTypeData = (data && data.getActiveVehicleGroups) || [];
  const dropdownInitialStates = [
    {
      value: '',
      label: '-select-',
      defaultValue: true,
    },
  ];

  const ability = useAppAbility();

  const modeBrowsing =
    queryParams.get('mode') === 'browsing' || !canCreateRequisition(ability);

  const [agencies, setAgencies] = useState(dropdownInitialStates);
  const [bureaus, setBureaus] = useState(dropdownInitialStates);
  const [offices, setOffices] = useState(dropdownInitialStates);
  const [showTypes, setShowTypes] = useState(modeBrowsing);
  const [justBrowsing, setJustBrowsing] = useState(modeBrowsing);
  const [showShipmentInfo, setShipmentInfo] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedBureau, setSelectedBureau] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [isStandardOrderProcess, setStandardOrderProcess] = useState(false);
  const [nonSopModal, setNonSopModal] = useState(false);
  const [isNonSop, setIsNonSop] = useState(false);
  //  const [canOrderOnBehalf, setCanOrderOnBehalf] = useState(false);
  const [requisitionType, setRequisitionType] = useState(ReqType.self);
  const [orderingAgencies, setOrderingAgencies] = useState(
    dropdownInitialStates,
  );
  const [orderingBureaus, setOrderingBureaus] = useState(dropdownInitialStates);
  const [orderingOffices, setOrderingOffices] = useState(dropdownInitialStates);
  const [receivingOffices, setReceivingOffices] = useState(
    dropdownInitialStates,
  );

  const [receivingAgencies, setReceivingAgencies] = useState(
    dropdownInitialStates,
  );
  const [receivingBureaus, setReceivingBureaus] = useState(
    dropdownInitialStates,
  );

  const canViewOrCreateRequisition = () => {
    return canViewRequisition(ability) || canCreateRequisition(ability);
  };

  const canOrderOnBehalf = canOrderOnBehalfRequisition(ability);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      agencyCode: '',
      bureauCode: '',
      officeCode: '',
      orderingAgencyCode: '',
      orderingBureauCode: '',
      orderingOfficeCode: '',
      receivingAgencyCode: '',
      receivingBureauCode: '',
      receivingOfficeCode: '',
      widget: '',
    },
  });

  const onSubmit = handleSubmit((submitData) => {
    if (!Object.keys(errors).length) {
      if (requisitionType === ReqType.self) {
        const agency = agencies.find(
          ({ value }) => value === submitData.agencyCode,
        );
        sessionStorage.setItem('agencyCode', submitData.agencyCode);
        sessionStorage.setItem('orderingAgency', agency?.label || '');
        sessionStorage.setItem('isAgencyDod', agency?.isDod || '');
        sessionStorage.setItem('oldAgencyCode', agency?.oldAgencyCode || '');
        setSelectedAgency(agency?.label || '');

        const bureau = bureaus.find(
          ({ value }) => value === submitData.bureauCode,
        );
        sessionStorage.setItem('bureauCode', submitData.bureauCode);
        sessionStorage.setItem('orderingBureau', bureau?.label || '');
        setSelectedBureau(bureau?.label || '');

        const office = submitData.officeCode.length
          ? offices.find(({ value }) => value === submitData.officeCode)
          : null;
        sessionStorage.setItem('officeCode', submitData.officeCode);
        sessionStorage.setItem('orderingOffice', office?.label || '');
        setSelectedOffice(office?.label || '');
      } else {
        const orderingAgency = orderingAgencies.find(
          ({ value }) => value === submitData.orderingAgencyCode,
        );
        sessionStorage.setItem('agencyCode', submitData.orderingAgencyCode);
        sessionStorage.setItem('orderingAgency', orderingAgency?.label || '');
        sessionStorage.setItem('isAgencyDod', orderingAgency?.isDod || '');
        sessionStorage.setItem(
          'oldAgencyCode',
          orderingAgency?.oldAgencyCode || '',
        );

        const orderingBureau = orderingBureaus.find(
          (bureau) => bureau.value === submitData.orderingBureauCode,
        );
        sessionStorage.setItem('bureauCode', submitData.orderingBureauCode);
        sessionStorage.setItem('orderingBureau', orderingBureau?.label || '');

        const orderingOffice = submitData.orderingOfficeCode.length
          ? orderingOffices.find(
              (office) => office.value === submitData.orderingOfficeCode,
            )
          : null;
        sessionStorage.setItem('officeCode', submitData.orderingOfficeCode);
        sessionStorage.setItem('orderingOffice', orderingOffice?.label || '');
        setSelectedOffice(orderingOffice?.label || '');

        const receivingAgency = receivingAgencies.find(
          ({ value }) => value === submitData.receivingAgencyCode,
        );
        sessionStorage.setItem(
          'receivingAgencyCode',
          submitData.receivingAgencyCode,
        );
        sessionStorage.setItem('receivingAgency', receivingAgency?.label || '');
        sessionStorage.setItem('isAgencyDod', receivingAgency?.isDod || '');
        sessionStorage.setItem(
          'oldAgencyCode',
          receivingAgency?.oldAgencyCode || '',
        );
        setSelectedAgency(receivingAgency?.label || '');

        const receivingBureau = receivingBureaus.find(
          (bureau) => bureau.value === submitData.receivingBureauCode,
        );
        sessionStorage.setItem(
          'recevingBureauCode',
          submitData.receivingBureauCode,
        );
        sessionStorage.setItem('receivingBureau', receivingBureau?.label || '');
        setSelectedBureau(receivingBureau?.label || '');

        const receivingOffice = submitData.receivingOfficeCode.length
          ? receivingOffices.find(
              (office) => office.value === submitData.receivingOfficeCode,
            )
          : null;
        sessionStorage.setItem(
          'receivingOfficeCode',
          submitData.receivingOfficeCode,
        );
        sessionStorage.setItem('receivingOffice', receivingOffice?.label || '');
        setSelectedOffice(receivingOffice?.label || '');
      }
      setSelectedState(sessionStorage.getItem('shipmentLocation'));
      setShowTypes(true);
      setShipmentInfo(true);
      setJustBrowsing(false);
    }
  });

  const requisitionOptions = [
    {
      label: 'I’m ordering for my organization',
      value: ReqType.self,
      default: true,
    },
    {
      label: 'I’m ordering on behalf of another organization',
      value: ReqType.onBehalf,
      default: false,
    },
  ];

  const [_getUserAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getAgenciesByPermission) {
          const formattedAgencies = sortBy(
            info.getAgenciesByPermission,
            'id',
          ).map(({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
            label,
            value,
            isDod: Boolean(isDodInt),
            oldAgencyCode,
          }));
          setAgencies([...dropdownInitialStates, ...formattedAgencies]);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'c',
    },
  );
  const [_getOrderingAgencies, { loading: getOrderingAgenciesLoading }] =
    useLazyQuery(GET_ORDERING_AGENCIES_BY_PERMISSION, {
      onCompleted: (info) => {
        if (info?.getOnBehalfOrderingAgenciesByPermission) {
          const formattedAgencies =
            info.getOnBehalfOrderingAgenciesByPermission.map(
              ({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
                label,
                value,
                isDod: Boolean(isDodInt),
                oldAgencyCode,
              }),
            );

          setOrderingAgencies([...dropdownInitialStates, ...formattedAgencies]);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const [_getReceivingAgencies, { loading: getReceivingAgenciesLoading }] =
    useLazyQuery(GET_RECEIVING_AGENCIES, {
      onCompleted: (info) => {
        if (info?.getOnBehalfReceivingAgencies) {
          const formattedAgencies = info.getOnBehalfReceivingAgencies.map(
            ({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
              label,
              value,
              isDod: Boolean(isDodInt),
              oldAgencyCode,
            }),
          );
          setReceivingAgencies([
            ...dropdownInitialStates,
            ...formattedAgencies,
          ]);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const getUserAgencies = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserAgencies(args);
    }
  };
  const getOrderingAgencies = async (args) => {
    if (canOrderOnBehalf) {
      await _getOrderingAgencies(args);
    }
  };
  const getReceivingAgencies = async (args) => {
    if (canOrderOnBehalf) {
      await _getReceivingAgencies(args);
    }
  };
  const [_getUserBureaus, { loading: getBureausLoading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getBureausByPermission) {
          const updateList = [...dropdownInitialStates];
          sortBy(info.getBureausByPermission, 'id').forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setBureaus(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    },
  );
  const [_getOrderingBureaus, { loading: getOrderingBureausLoading }] =
    useLazyQuery(GET_ORDERING_BUREAUS_BY_PERMISSION, {
      onCompleted: (info) => {
        if (info?.getOnBehalfOrderingBureaus) {
          const updateList = [...dropdownInitialStates];
          info.getOnBehalfOrderingBureaus.forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setOrderingBureaus(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const [_getReceivingBureaus, { loading: getReceivingBureausLoading }] =
    useLazyQuery(GET_RECEIVING_BUREAUS, {
      onCompleted: (info) => {
        if (info?.getOnBehalfReceivingBureaus) {
          const updateList = [...dropdownInitialStates];
          info.getOnBehalfReceivingBureaus.forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setReceivingBureaus(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const getUserBureaus = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserBureaus(args);
    }
  };
  const getOrderingBureaus = async (args) => {
    if (canOrderOnBehalf) {
      await _getOrderingBureaus(args);
    }
  };
  const getReceivingBureaus = async (args) => {
    if (canOrderOnBehalf) {
      await _getReceivingBureaus(args);
    }
  };
  const [_getUserOffices, { loading: getOfficesLoading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownInitialStates];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setOffices(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    },
  );
  const getUserOffices = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserOffices(args);
    }
  };
  const [_getOrderingOffices, { loading: getOrderingOfficesLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownInitialStates];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setOrderingOffices(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const [_getReceivingOffices, { loading: getReceivingOfficesLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownInitialStates];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setReceivingOffices(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    });
  const getOrderingOffices = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getOrderingOffices(args);
    }
  };
  const getReceivingOffices = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getReceivingOffices(args);
    }
  };
  useEffect(() => {
    if (canCreateRequisition(ability)) {
      const initialData = async () => {
        await getUserAgencies({
          variables: {
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
        // await _checkUserEligibilityForOnBehalf({
        //   variables: {
        //     subject: StoreSubjects.Requisition,
        //     operation: 'create',
        //   },
        // });
        await getOrderingAgencies({
          variables: {
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
      };
      initialData();
      sessionStorage.clear();
    } else if (!modeBrowsing) {
      navigate('/');
    }
  }, []);

  const iptModalCancel = () => {
    setNonSopModal(false);
    setIptAckSession(false);
    setIsNonSop(false);
  };

  const iptModalConfirm = () => {
    setNonSopModal(false);
    navigate('/non-standard-ordering');
  };

  const onVehicleTypeClick = (vechicleData) => {
    updateVehicleListingFilter('');
    sessionStorage.setItem('selectedVehicleType', vechicleData?.code);
    if (justBrowsing) {
      navigate('/vehicle-listings?mode=browsing', {
        state: { data: vechicleData },
      });
    } else {
      navigate(
        `/vehicle-listings?mode=requisition&domestic=${isDomesticShipment}`,
        { state: { data: vechicleData } },
      );
    }
  };

  const [getStandardItemData] = useLazyQuery(GET_STANDARD_ITEM_BY_ID, {
    fetchPolicy: 'c',
  });

  const [getProgramData] = useLazyQuery(GET_PROGRAM_NAME_BY_STD_CODE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      sessionStorage.setItem('program', result.getProgramName);
    },
  });

  const onSelectItemBySIN = async (standardItem) => {
    if (standardItem?.id) {
      const stdData = await getStandardItemData({
        variables: {
          filter: {
            operator: 'EQ',
            key: 'id',
            value: standardItem.id,
          },
        },
      });
      if (stdData?.data?.getStandardItem) {
        sessionStorage.setItem(
          'standardItem',
          JSON.stringify(stdData.data.getStandardItem),
        );
      } else {
        sessionStorage.setItem('standardItem', JSON.stringify(standardItem));
      }
    }

    const pgData = await getProgramData({
      variables: { fedStdCode: standardItem.fedStandardCode },
    });
    sessionStorage.setItem('program', pgData.data.getProgramName);

    navigate(
      `/vehicle-requisition?sin=${standardItem.standardItemNumber}&mode=${
        justBrowsing ? 'browsing' : 'requisition'
      }&domestic=${isDomesticShipment}`,
    );
  };

  const startJustBrowsingSession = () => {
    setJustBrowsing(true);
    setShowTypes(true);
  };

  const handleAgencyChange = (id) => {
    setValue('bureauCode', '');
    setValue('officeCode', '');
    setBureaus(dropdownInitialStates);
    setOffices(dropdownInitialStates);
    if (id.length)
      getUserBureaus({
        variables: {
          agencyCode: id,
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
  };

  const handleBureauChange = (id) => {
    setValue('officeCode', '');
    setOffices(dropdownInitialStates);
    if (id.length) {
      const values = getValues();
      getUserOffices({
        variables: {
          agencyCode: values.agencyCode,
          bureauCode: id,
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
    }
  };

  const handleOrderingAgencyChange = (id) => {
    setValue('orderingBureauCode', '');
    setValue('orderingOfficeCode', '');
    setValue('receivingAgencyCode', '');
    setValue('receivingBureauCode', '');
    setValue('receivingOfficeCode', '');
    setOrderingBureaus(dropdownInitialStates);
    setOrderingOffices(dropdownInitialStates);
    setReceivingAgencies(dropdownInitialStates);
    setReceivingBureaus(dropdownInitialStates);
    setReceivingOffices(dropdownInitialStates);
    if (id.length)
      getOrderingBureaus({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'create',
          orderingAgencyCode: id,
        },
      });
  };

  const handleOrderingBureauChange = async (id) => {
    setValue('orderingOfficeCode', '');
    setValue('receivingAgencyCode', '');
    setValue('receivingBureauCode', '');
    setValue('receivingOfficeCode', '');
    setOrderingOffices(dropdownInitialStates);
    setReceivingAgencies(dropdownInitialStates);
    setReceivingBureaus(dropdownInitialStates);
    setReceivingOffices(dropdownInitialStates);
    if (id.length) {
      const values = getValues();
      await getOrderingOffices({
        variables: {
          agencyCode: values.orderingAgencyCode,
          bureauCode: id,
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
      await getReceivingAgencies({
        variables: {
          orderingAgency: values.orderingAgencyCode,
          orderingBureau: id,
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
    }
  };

  const handleReceivingAgencyChange = (id) => {
    setValue('receivingBureauCode', '');
    setValue('receivingOfficeCode', '');
    setReceivingBureaus(dropdownInitialStates);
    setReceivingOffices(dropdownInitialStates);
    if (id.length) {
      const values = getValues();
      getReceivingBureaus({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'create',
          orderingAgency: values.orderingAgencyCode,
          orderingBureau: values.orderingBureauCode,
          purchasingAgency: id,
        },
      });
    }
  };

  const handleReceivingBureauChange = async (id) => {
    setValue('receivingOfficeCode', '');
    setReceivingOffices(dropdownInitialStates);
    if (id.length) {
      const values = getValues();
      getReceivingOffices({
        variables: {
          agencyCode: values.receivingAgencyCode,
          bureauCode: id,
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
    }
  };

  const getFeedbackContent = (fieldId, error) => {
    return {
      text: error,
      visible: error ? 'error' : true,
      id: fieldId,
      applyTo: (child) => child.props.type === 'text',
    };
  };

  const handleOnChangeRequisitionType = async (reqType) => {
    setValue('agencyCode', '');
    setValue('bureauCode', '');
    setValue('officeCode', '');
    setValue('orderingAgencyCode', '');
    setValue('orderingBureauCode', '');
    setValue('orderingOfficeCode', '');
    setValue('receivingAgencyCode', '');
    setValue('receivingBureauCode', '');
    setValue('receivingOfficeCode', '');
    sessionStorage.setItem('requisitionType', reqType);

    if (reqType === ReqType.self) {
      await getUserAgencies({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
    }
    if (reqType === ReqType.onBehalf) {
      await getOrderingAgencies({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'create',
        },
      });
    }
  };

  const getPurchaseAssociationInfo = () => {
    if (!canViewOrCreateRequisition()) {
      return (
        <Alert
          slim
          type="warning"
          className="margin-right-2em"
          data-testid="purchase-association-info-alert"
        >
          You do not currently have a role allowing you to create or view
          requisitions. Please use the <b>just browsing</b> functionality to the
          right. If you believe you should have a role relating to requisitions
          please contact your organization&#39;s administrator for GSAFleet.gov
        </Alert>
      );
    }

    return (
      <>
        {requisitionType === ReqType.self ? (
          <div>
            {(getAgenciesLoading || getBureausLoading || getOfficesLoading) && (
              <Spinner aria-busy="true" size="medium" />
            )}
            <div className="error-label">
              <FieldFeedback
                {...getFeedbackContent(
                  'purchaseAgency',
                  errors?.agencyCode?.message ? errors.agencyCode.message : '',
                )}
              >
                <Controller
                  name="agencyCode"
                  control={control}
                  rules={{ required: 'Please select an agency' }}
                  render={({ field: { value, onChange, ref } }) => (
                    <SelectDropdown
                      label="Agency"
                      required
                      id="purchaseAgency"
                      name="select-agency"
                      options={agencies}
                      onChange={(e) => {
                        onChange(e);
                        handleAgencyChange(e.target.value);
                      }}
                      inputRef={ref}
                      value={value}
                      data-testid="select-agency"
                    />
                  )}
                />
              </FieldFeedback>
            </div>
            <div className="error-label">
              <FieldFeedback
                {...getFeedbackContent(
                  'purchaseBureau',
                  errors?.bureauCode?.message ? errors.bureauCode.message : '',
                )}
              >
                <Controller
                  name="bureauCode"
                  control={control}
                  rules={{ required: 'Please select a bureau' }}
                  render={({ field: { value, onChange, ref } }) => (
                    <SelectDropdown
                      label="Bureau"
                      required
                      data-testid="select-bureau"
                      id="purchaseBureau"
                      name="select-bureau"
                      options={bureaus}
                      onChange={(e) => {
                        onChange(e);
                        handleBureauChange(e.target.value);
                      }}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </FieldFeedback>
            </div>
            <div className="error-label">
              <FieldFeedback
                {...getFeedbackContent(
                  'purchaseBureau',
                  errors?.officeCode?.message ? errors.officeCode.message : '',
                )}
              >
                <Controller
                  name="officeCode"
                  control={control}
                  render={({ field: { value, onChange, ref } }) => (
                    <SelectDropdown
                      label="Office / Group"
                      data-testid="select-office"
                      id="purchaseOffice"
                      name="select-office"
                      options={offices}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </FieldFeedback>
            </div>
          </div>
        ) : (
          <div>
            {(getOrderingAgenciesLoading ||
              getOrderingBureausLoading ||
              getOfficesLoading ||
              getReceivingAgenciesLoading ||
              getReceivingBureausLoading ||
              getOrderingOfficesLoading ||
              getReceivingOfficesLoading) && (
              <Spinner aria-busy="true" size="medium" />
            )}
            <div className="dropdown-container">
              <div className="dropdown-section">
                <p className="sop-dropdown-title">Ordering Agency:</p>
                <div className="error-label-ordering">
                  <FieldFeedback
                    {...getFeedbackContent(
                      'orderingAgency',
                      errors?.orderingAgencyCode?.message
                        ? errors.orderingAgencyCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="orderingAgencyCode"
                      control={control}
                      rules={{ required: 'Please select an agency' }}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Agency"
                          required
                          id="orderingAgency"
                          name="select-ord-agency"
                          options={orderingAgencies}
                          onChange={(e) => {
                            onChange(e);
                            handleOrderingAgencyChange(e.target.value);
                          }}
                          inputRef={ref}
                          value={value}
                          data-testid="select-ord-agency"
                        />
                      )}
                    />
                  </FieldFeedback>
                  <FieldFeedback
                    {...getFeedbackContent(
                      'orderingBureau',
                      errors?.orderingBureauCode?.message
                        ? errors.orderingBureauCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="orderingBureauCode"
                      control={control}
                      rules={{ required: 'Please select a bureau' }}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Bureau"
                          required
                          data-testid="select-ord-bureau"
                          id="orderingBureau"
                          name="select-ord-bureau"
                          options={orderingBureaus}
                          onChange={(e) => {
                            onChange(e);
                            handleOrderingBureauChange(e.target.value);
                          }}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  </FieldFeedback>
                  <FieldFeedback
                    {...getFeedbackContent(
                      'orderingOffice',
                      errors?.orderingOfficeCode?.message
                        ? errors.orderingOfficeCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="orderingOfficeCode"
                      control={control}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Office / Group"
                          data-testid="select-ord-office"
                          id="orderingOffice"
                          name="select-ord-office"
                          options={orderingOffices}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  </FieldFeedback>
                </div>
              </div>
              <div className="middle-bar" />
              <div className="dropdown-section">
                <p className="sop-dropdown-title">Receiving Agency:</p>
                <div className="error-label-ordering">
                  <FieldFeedback
                    {...getFeedbackContent(
                      'receivingAgency',
                      errors?.receivingAgencyCode?.message
                        ? errors.receivingAgencyCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="receivingAgencyCode"
                      control={control}
                      rules={{ required: 'Please select an agency' }}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Agency"
                          required
                          id="receivingAgency"
                          name="select-rcv-agency"
                          options={receivingAgencies}
                          onChange={(e) => {
                            onChange(e);
                            handleReceivingAgencyChange(e.target.value);
                          }}
                          inputRef={ref}
                          value={value}
                          data-testid="select-rcv-agency"
                        />
                      )}
                    />
                  </FieldFeedback>
                  <FieldFeedback
                    {...getFeedbackContent(
                      'receivingBureau',
                      errors?.receivingBureauCode?.message
                        ? errors.receivingBureauCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="receivingBureauCode"
                      control={control}
                      rules={{ required: 'Please select a bureau' }}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Bureau"
                          required
                          data-testid="select-rcv-bureau"
                          id="receivingBureau"
                          name="select-rcv-bureau"
                          options={receivingBureaus}
                          onChange={(e) => {
                            onChange(e);
                            handleReceivingBureauChange(e.target.value);
                          }}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  </FieldFeedback>
                  <FieldFeedback
                    {...getFeedbackContent(
                      'receivingOffice',
                      errors?.receivingOfficeCode?.message
                        ? errors.receivingOfficeCode.message
                        : '',
                    )}
                  >
                    <Controller
                      name="receivingOfficeCode"
                      control={control}
                      render={({ field: { value, onChange, ref } }) => (
                        <SelectDropdown
                          label="Office / Group"
                          data-testid="select-rcv-office"
                          id="receivingOffice"
                          name="select-rcv-office"
                          options={receivingOffices}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  </FieldFeedback>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getShipmentOptions = () => {
    if (!canViewOrCreateRequisition()) {
      return null;
    }
    return (
      <ShipmentOptions
        onShipmentSelection={() => {
          setJustBrowsing(false);
          setShipmentInfo(false);
          setShowTypes(false);
        }}
        onLocationSelected={() => {
          setJustBrowsing(false);
          setShipmentInfo(false);
          setShowTypes(false);
        }}
        onFilterSubmit={onSubmit}
        isUrgentReq={false}
      />
    );
  };

  React.useEffect(() => {
    if (modeBrowsing) return;
    if (!isDirty || !isValid) {
      setShipmentInfo(false);
      setShowTypes(false);
    }
  }, [isValid, isDirty, modeBrowsing]);

  const handleNonStandardProcess = () => {
    if (iptAckSession) {
      iptModalConfirm();
    } else {
      setNonSopModal(true);
      setIsNonSop(true);
    }
  };

  const PurchaseLandingPage = () => {
    return (
      <>
        <PageTitle
          className="purchasing-left-section"
          title={Purchase_Vehicles}
        />
        <div className="purchasing-main-section">
          <div className="purchasing-left-section">
            <div className="purchasing-title">Standard Order Process</div>
            <p className="sin-title-desc">
              The Fleet Standard Vehicle Ordering Program is GSA Fleet’s most
              popular method for vehicle purchasing and provides an easy to use
              online vehicle ordering tool that helps you choose the right
              vehicles for your agency. You can use the Standard Vehicle
              Ordering Program to configure vehicles, choose equipment and color
              options, and view side-by-side comparisons of vehicle models. You
              can also check your order status, find fuel ratings, choose
              dealerships, and run reports. Vehicles you purchase through our
              online vehicle ordering tool are built-to-order with a delivery
              time frame based on vehicle type, equipment and color choices, and
              delivery location.
            </p>

            <Button
              label="Start here"
              data-testid="sop-button"
              type="button"
              variant="standard"
              rightIcon={{ name: 'arrow_forward' }}
              onClick={() => {
                setStandardOrderProcess(true);
                setJustBrowsing(false);
                setShowTypes(false);
              }}
            />
          </div>
          <div className="purchasing-right-section">
            <div className="non-sop-module">
              <div className="purchasing-right-title">
                Can&apos;t find what you&apos;re looking for?
              </div>
              <p className="sin-title-desc">
                If you&apos;re unable to find the right vehicle for your agency
                using our Standard Vehicle Ordering Program, consult with one of
                our Offering Support Professionals. Working with a team of Fleet
                Engineers they can assist with meeting your vehicle requirements
                using the following methods:
              </p>
              <ul className="non-sop-list">
                <li>Multiple Award Schedules</li>
                <li>Non-Standard Vehicles</li>
                <li>Urgent Vehicle Order</li>
              </ul>
              <Button
                label="Start here"
                data-testid="non-sop-button"
                type="button"
                variant="outline"
                rightIcon={{ name: 'arrow_forward' }}
                onClick={handleNonStandardProcess}
              />
            </div>
            <div className="browsing-module">
              <div className="purchasing-right-title">Just Browsing</div>
              <p className="sin-title-desc">
                View vehicles and options to see what is currently available
                without placing an order.
              </p>
              <Button
                label="Browse vehicles and options"
                data-testid="browse-start-button"
                type="button"
                variant="outline"
                rightIcon={{ name: 'arrow_forward' }}
                onClick={startJustBrowsingSession}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const IptAckModal = () => {
    return (
      <div className="afp-modal-overlay modalContainer non-sop-modal">
        <Modal
          className="non-sop-modal-main"
          data-testid="ipt-ack-modal"
          onClose={iptModalCancel}
          actions={
            <>
              <Button
                data-testid="ipt-ack-cancel-button"
                type="button"
                variant="unstyled"
                onClick={iptModalCancel}
                label="Cancel"
              />
              <Button
                data-testid="ipt-ack-confirm-button"
                type="button"
                disabled={!iptAckSession}
                variant="standard"
                onClick={iptModalConfirm}
                label="Continue Non-SOP Ordering"
              />
            </>
          }
        >
          <Alert type="warning">
            <b>Please confirm/acknowledge before continuing...</b>
          </Alert>
          <div className="ipt-modal-content">
            I acknowledge that by continuing the Non-Standard Order Process that
            I have consulted with GSA&apos;s Integrated Project Team (IPT). This
            consultation will help to ensure the best possible source method for
            obtaining your Non-SOP vehicle purchase. Please note, depending on
            the type of Non-SOP ordering method, you may incur a fee for
            services provided in the technical requirements development to end
            user delivery.
            <br />
            <br />
            If you have not consulted with an IPT Member, please email your
            Non-SOP vehicle request to{' '}
            <a href="mailto:fleetspecialordering@gsa.gov">
              fleetspecialordering@gsa.gov
            </a>
            , with the subject line of “Non-SOP Ordering” and select cancel
            below.
            <br />
            <br />
            <div className="acknowledgment-section">
              <input
                data-testid="ipt-ack-checkbox"
                className="ipt-input-checkbox"
                type="checkbox"
                onChange={() => {
                  setIptAckSession(!iptAckSession);
                }}
                checked={iptAckSession}
                id="acknowledge-checkbox"
              />
              <span className="confirm-ipt">Confirm acknowledgment</span>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <div className="main-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
        <ContactBuyingPopover />
      </div>

      <div className="main-title">
        {!modeBrowsing && (
          <>
            {!isStandardOrderProcess && <PurchaseLandingPage />}
            {isStandardOrderProcess && (
              <div className="purchase-pre-filter-content-container purchasing-left-section">
                <div className="purchase-pre-filter-content-container__filters">
                  <>
                    <div>
                      <p className="sop-title">Standard Order Process</p>
                      <p className="sop-description">
                        Before proceeding with your purchase requisition, please
                        provide the agency or organization for which this order
                        will be associated, and the general location where you’d
                        like your vehicle(s) to be shipped. This will determine
                        availability of vehicles types, their options, and costs
                        provided by vendors. For items currently not available,
                        please email our Customer Care team at{' '}
                        <a href="mailto: customercare@gsa.gov">
                          customercare@gsa.gov
                        </a>{' '}
                        for assistance.
                      </p>
                      <h3 className="sop-sub-title">
                        Which organization should this order be associated with?
                      </h3>
                      <div className="inline-radio-group">
                        {requisitionOptions.map(
                          (option) =>
                            (option.default ||
                              (!option.default && canOrderOnBehalf)) && (
                              <RadioButton
                                key={`sop-order-process-${option.value}`}
                                className="inline-radio"
                                name={`sop-${option.value}`}
                                data-testid={`sop-order-process-${option.value}`}
                                onChange={(e) => {
                                  setRequisitionType(e.target.value);
                                  handleOnChangeRequisitionType(e.target.value);
                                }}
                                checked={requisitionType === option.value}
                                {...option}
                              />
                            ),
                        )}
                      </div>

                      {getPurchaseAssociationInfo()}
                    </div>
                    {getShipmentOptions()}
                  </>
                </div>
              </div>
            )}
          </>
        )}
        {justBrowsing && !isNonSop && <JustBrowsingAlert />}
        {showShipmentInfo && (
          <ShipmentInfoAlert
            agency={selectedAgency}
            bureau={selectedBureau}
            office={selectedOffice}
            state={selectedState}
          />
        )}
        {showTypes && !isNonSop && (
          <>
            <div className="filter-container">
              <h4>Search Vehicles</h4>
              <SearchBySIN
                data-testid="sin-search"
                onSelectItem={onSelectItemBySIN}
              />
            </div>
            <div className="filter-container">
              <h4>Search by Type</h4>
              {loadingData && <Spinner aria-busy="true" size="large" />}
              {!loadingData && !!vehicleTypeData.length && (
                <SelectTypeCard
                  list={vehicleTypeData}
                  onVehicleTypeClick={onVehicleTypeClick}
                />
              )}
            </div>
            <div className="filter-container">
              {/* <h4>Select by Alternate Fuel Vehicle (AFV)</h4>
            <SelectByType data-testid="afv-select" types={MOCK_AFP_TYPE_LIST} /> */}
            </div>
          </>
        )}
      </div>
      <div className="spacer">&nbsp;</div>
      {nonSopModal && <IptAckModal />}
    </div>
  );
};

const SelectTypeCard = ({ list, onVehicleTypeClick }) => {
  const rows = [];
  const rowItems = list.map((item) => {
    const imageURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${item.code}.svg`;

    return (
      <div
        key={item.id}
        data-testid={`on-vehicle-type-click-${item.code}`}
        className="sin-type-card"
        onClick={() => onVehicleTypeClick(item)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onVehicleTypeClick(item);
        }}
        role="button"
        tabIndex="0"
      >
        <div className="details-container">
          <div className="image-container">
            <ImageViewer
              imgUrl={imageURL}
              defaultImgUrl={`${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`}
            />
          </div>
          <div className="underline-section" />
          <div className="image-label">{item.title}</div>
        </div>
      </div>
    );
  });

  for (let index = 0; index < rowItems.length; index += 5) {
    rows.push(
      <div className="sin-listing-row" key={uuidv4()}>
        {rowItems.slice(index, index + 5)}
      </div>,
    );
  }

  return <>{rows}</>;
};

SelectTypeCard.propTypes = {
  list: PropTypes.instanceOf(Array),
  onVehicleTypeClick: PropTypes.func.isRequired,
};

SelectTypeCard.defaultProps = {
  list: [],
};

const PurchasePreFilterContainerWithContext = () => {
  return (
    <VehicleRequisitionContextProvider>
      <PurchasePreFilter />
    </VehicleRequisitionContextProvider>
  );
};

export default PurchasePreFilterContainerWithContext;
