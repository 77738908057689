import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button, Alert } from '@gsa/afp-component-library';
import FileUpload from './FileUpload';
import DownloadFileTemplate from './DownloadFileTemplate';

export default function BulkUpdateModal({
  handleUpload,
  onClose,
  cancel,
  fileState,
  currentDownloadStrategyState,
  setFileState,
  setFileImportErrorState,
  fileImportErrorState,
}) {
  const BulkUpdateActions = (
    <>
      <Button
        type="button"
        variant="unstyled"
        label="Cancel"
        onClick={cancel}
      />
      <Button
        type="button"
        variant="primary"
        label="Upload"
        onClick={handleUpload}
      />
    </>
  );
  return (
    <div
      style={{
        position: 'relative',
        top: '10%',
      }}
    >
      <Modal
        title={<h1>Bulk update status or VIN updates</h1>}
        variant="large"
        actions={BulkUpdateActions}
        onClose={onClose}
        className="upload-modal"
      >
        <div className="grid-container margin-bottom-2 padding-left-0">
          <div className="grid-row">
            <div className="grid-col-12">
              Please use this page to perform a bulk update to vehicle
              information such as statuses and VINs, across multiple orders.
            </div>
          </div>
        </div>
        <div className="grid-container margin-bottom-2 padding-left-0">
          <div className="grid-row">
            <div className="grid-col-12">
              Required fields are marked with an asterisk (
              <span className="text-red text-bold">*</span>).
            </div>
          </div>
        </div>
        <div className="grid-container padding-left-0">
          <div className="grid-row">
            <div className="grid-col-6 padding-right-15">
              <h3
                color="primary-dark"
                className="margin-bottom-1 text-primary-dark text-heavy"
                style={{ textTransform: 'uppercase' }}
              >
                Download Order status report
              </h3>
              Click “Download Report” to generate a report of your vehicle
              orders to simplify the vehicle status reporting process. Follow
              the instructions provided with the template to avoid any errors.
              <div className="margin-y-2">
                <DownloadFileTemplate />
              </div>
            </div>
            <div className="grid-col-6 padding-left-10">
              <h3
                color="primary-dark"
                className="margin-bottom-1 text-primary-dark text-heavy"
                style={{ textTransform: 'uppercase' }}
              >
                Download empty report template
              </h3>
              Click “Download Empty Template” to access the template required to
              provide bulk vehicle status reporting. Follow the instructions
              provided with the template to avoid any errors.
              <div className="margin-y-2">
                <DownloadFileTemplate emptyTemplate />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container margin-bottom-2 padding-left-0">
          <div className="grid-row">
            <div className="grid-col-12">
              <h3
                color="primary-dark"
                className="margin-bottom-1 text-primary-dark text-heavy"
                style={{ textTransform: 'uppercase' }}
              >
                Upload Order status report to update
              </h3>
              Please carefully follow instructions below to avoid a file upload
              failure.
            </div>
          </div>
        </div>
        <div className="grid-container margin-bottom-4 padding-left-0">
          <div className="grid-row">
            <div className="grid-col-12">
              <Alert type="info" slim>
                <h3 className="usa-alert__heading">
                  Status report instructions
                </h3>
                <ul>
                  <li>
                    Do not add, remove, or edit any columns from the original
                    template. The entire update will fail if they do not match
                    exactly.
                  </li>
                  <li>
                    Invalid data requiring corrections will be displayed by row.
                    Please make necessary corrections and upload the corrected
                    file.
                  </li>
                </ul>
              </Alert>
            </div>
          </div>
        </div>
        <div className="grid-container margin-bottom-4 padding-left-0">
          <div className="grid-row">
            <div className="grid-col-12 margin-y-2 file-select-zone">
              <FileUpload
                fileState={fileState}
                currentDownloadStrategyState={currentDownloadStrategyState}
                setFileState={setFileState}
                setFileImportErrorState={setFileImportErrorState}
                fileImportErrorState={fileImportErrorState}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

BulkUpdateModal.defaultProps = {
  handleUpload: () => {},
  onClose: () => {},
  cancel: () => {},
  fileState: null,
  currentDownloadStrategyState: {},
  setFileState: () => {},
  setFileImportErrorState: () => {},
  fileImportErrorState: {},
};

BulkUpdateModal.propTypes = {
  handleUpload: propTypes.func,
  onClose: propTypes.func,
  cancel: propTypes.func,
  fileState: propTypes.instanceOf(Object),
  currentDownloadStrategyState: propTypes.instanceOf(Object),
  setFileState: propTypes.func,
  setFileImportErrorState: propTypes.func,
  fileImportErrorState: propTypes.instanceOf(Object),
};
