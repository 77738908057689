import React from 'react';

export const DateIcon = (props) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 12.4286C0 13.1384 0.575893 13.7143 1.28571 13.7143H10.7143C11.4241 13.7143 12 13.1384 12 12.4286V5.14286H0V12.4286ZM1.71429 7.28571C1.71429 7.05 1.90714 6.85714 2.14286 6.85714H4.71429C4.95 6.85714 5.14286 7.05 5.14286 7.28571V9.85714C5.14286 10.0929 4.95 10.2857 4.71429 10.2857H2.14286C1.90714 10.2857 1.71429 10.0929 1.71429 9.85714V7.28571ZM10.7143 1.71429H9.42857V0.428571C9.42857 0.192857 9.23571 0 9 0H8.14286C7.90714 0 7.71429 0.192857 7.71429 0.428571V1.71429H4.28571V0.428571C4.28571 0.192857 4.09286 0 3.85714 0H3C2.76429 0 2.57143 0.192857 2.57143 0.428571V1.71429H1.28571C0.575893 1.71429 0 2.29018 0 3V4.28571H12V3C12 2.29018 11.4241 1.71429 10.7143 1.71429Z"
        fill="#374C5C"
      />
    </svg>
  );
};

export default DateIcon;
