export const STEPS = {
  COMPARE_SELECT: 'COMPARE_SELECT',
  AREQ_INFORMATION: 'AREQ_INFORMATION',
  PAINT_GRAPHICS: 'PAINT_GRAPHICS',
  OPTION_DETAILS: 'OPTION_DETAILS',
  REVIEW_VEHICLE_BUILD: 'REVIEW_VEHICLE_BUILD',
  AGENCY_INFORMATION: 'AGENCY_INFORMATION',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  REVIEW_SUBMIT: 'REVIEW_SUBMIT',
  NON_LOW_BID_REASON: 'NON_LOW_BID_REASON',
};

export const noCPTOptions = (paintAndGraphicsOptions) =>
  !(paintAndGraphicsOptions && paintAndGraphicsOptions.length > 0);

export const NonLowBidRequisitionSteps = (
  hasTaggedOptions,
  isAreq,
  paintAndGraphicsOptions,
) => {
  let steppers = hasTaggedOptions
    ? [
        {
          key: STEPS.COMPARE_SELECT,
          label: 'Compare and select',
          status: 'completed',
        },
        {
          key: STEPS.PAINT_GRAPHICS,
          label: 'Paint and graphics',
          status: 'not completed',
        },
        {
          key: STEPS.OPTION_DETAILS,
          label: 'Option details',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_VEHICLE_BUILD,
          label: 'Review vehicle build',
          status: 'not completed',
        },
        {
          key: STEPS.NON_LOW_BID_REASON,
          label: 'Non-low price justification',
          status: 'not completed',
        },
        {
          key: STEPS.AGENCY_INFORMATION,
          label: 'Agency information',
          status: 'not completed',
        },
        {
          key: STEPS.DELIVERY_ADDRESS,
          label: 'Delivery address',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_SUBMIT,
          label: 'Review and Submit',
          status: 'not completed',
        },
      ]
    : [
        {
          key: STEPS.COMPARE_SELECT,
          label: 'Compare and select',
          status: 'completed',
        },

        {
          key: STEPS.PAINT_GRAPHICS,
          label: 'Paint and graphics',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_VEHICLE_BUILD,
          label: 'Review vehicle build',
          status: 'not completed',
        },
        {
          key: STEPS.NON_LOW_BID_REASON,
          label: 'Non-low price justification',
          status: 'not completed',
        },
        {
          key: STEPS.AGENCY_INFORMATION,
          label: 'Agency information',
          status: 'not completed',
        },
        {
          key: STEPS.DELIVERY_ADDRESS,
          label: 'Delivery address',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_SUBMIT,
          label: 'Review and Submit',
          status: 'not completed',
        },
      ];

  if (isAreq) {
    steppers.splice(1, 0, {
      key: STEPS.AREQ_INFORMATION,
      label: 'Additional Requirements',
      status: 'not completed',
    });

    // Remove paint and graphics step in AREQ, unless there are CPT options
    if (noCPTOptions(paintAndGraphicsOptions)) {
      steppers = steppers.filter(({ key }) => key !== STEPS.PAINT_GRAPHICS);
    }
  }

  return steppers.map(({ key, label, status }, index) => ({
    key,
    label,
    status,
    stepNumber: index + 1,
  }));
};

export const LowBidRequisitionSteps = (
  hasTaggedOptions,
  isAreq,
  paintAndGraphicsOptions,
) => {
  let steppers = hasTaggedOptions
    ? [
        {
          key: STEPS.COMPARE_SELECT,
          label: 'Compare and select',
          status: 'completed',
        },
        {
          key: STEPS.PAINT_GRAPHICS,
          label: 'Paint and graphics',
          status: 'not completed',
        },
        {
          key: STEPS.OPTION_DETAILS,
          label: 'Option details',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_VEHICLE_BUILD,
          label: 'Review vehicle build',
          status: 'not completed',
        },
        {
          key: STEPS.AGENCY_INFORMATION,
          label: 'Agency information',
          status: 'not completed',
        },
        {
          key: STEPS.DELIVERY_ADDRESS,
          label: 'Delivery address',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_SUBMIT,
          label: 'Review and submit',
          status: 'not completed',
        },
      ]
    : [
        {
          key: STEPS.COMPARE_SELECT,
          label: 'Compare and select',
          status: 'completed',
        },
        {
          key: STEPS.PAINT_GRAPHICS,
          label: 'Paint and graphics',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_VEHICLE_BUILD,
          label: 'Review vehicle build',
          status: 'not completed',
        },
        {
          key: STEPS.AGENCY_INFORMATION,
          label: 'Agency information',
          status: 'not completed',
        },
        {
          key: STEPS.DELIVERY_ADDRESS,
          label: 'Delivery address',
          status: 'not completed',
        },
        {
          key: STEPS.REVIEW_SUBMIT,
          label: 'Review and submit',
          status: 'not completed',
        },
      ];

  if (isAreq) {
    steppers.splice(1, 0, {
      key: STEPS.AREQ_INFORMATION,
      label: 'Additional Requirements',
      status: 'not completed',
    });

    // Remove paint and graphics step in AREQ, unless there are CPT options
    if (noCPTOptions(paintAndGraphicsOptions)) {
      steppers = steppers.filter(({ key }) => key !== STEPS.PAINT_GRAPHICS);
    }
  }

  return steppers.map(({ key, label, status }, index) => ({
    key,
    label,
    status,
    stepNumber: index + 1,
  }));
};
export const VALIDATION_ERRORS_SECTIONS = {
  COLORS: 'COLORS-SELECTION',
  COMMENTS: 'COLORS-SECTION-COMMENTS',
  REVIEW: 'REVIEW-VEHICLE-BUILD',
  JUSTIFICATION: 'NON-LOW-BID-JUSTIFICATION',
  AGENCY: 'AGENCY-INFORMATION',
  ADDRESS: 'ADDRESS',
  SUBMIT: 'REVIEW-SUBMIT',
};

// step index is the key, step name is the value OR
// step name is the key, value is the error ID
export const RequisitionStep = {
  2: 'COLOR_SELECTION',
  3: 'COMMENTS_SECTION',
  6: 'DELIVERY_ADDRESS',
  COLOR_SELECTION: 'colorSelection',
  COMMENTS_SECTION: 'commentSection',
  DELIVERY_ADDRESS: 'deliveryAddress',
};

export const SUBMIT_TO_APPROVER = 'Submit to approver';
export const PLACE_ORDER = 'Place order';
export const SUBMIT_TO_ENGINEER = 'Submit to Engineer';
export const SUBMIT_TO_CO = 'Submit to Contracting Officer';
