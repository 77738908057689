import _ from 'lodash';
import { useUpdateLeasingDraft } from '@/requests/leasing';
import { useSelector } from 'react-redux';
import { getStep } from '../../leasing-steps';
import { LeasingSteps, RequisitionViewMode } from '../../leasing-consts';
import { getAfvIncremental } from '../../../../utilities/TotalSellingPriceUtils';

export default function useUpdateDraft() {
  const [updateLeasingDraft, hooks] = useUpdateLeasingDraft();
  const {
    requisitionId,
    selectedStandardItem,
    selectedVehicleType,
    selectedVehicleSubType,
    compareSelect,
    destinationType,
    shipmentState,
    shipmentLocation,
    shipmentCountry,
    leasingSteps,
    vehicleQuantity,
    paintAndGraphics,
    viewMode,
    account,
  } = useSelector((state) => state.leasingReducer);

  const { paintAndGraphicsDescription } = paintAndGraphics;
  const stdId = parseInt(selectedStandardItem?.id, 10);
  return [
    (data, step) => {
      let newStep = getStep(leasingSteps, step);
      if (viewMode === RequisitionViewMode.REVIEW_EDIT) {
        newStep = getStep(leasingSteps, LeasingSteps.REVIEW_SUBMIT);
      }
      const stateObj = _.cloneDeep({
        extendedProps: {
          standardItemId: stdId,
          standardItemCode: selectedStandardItem?.standardItemNumber,
          contractLineId: parseFloat(
            compareSelect?.selectedContract?.contractLineId,
          ),
          quantity: vehicleQuantity,
          isLowPrice: false,
          boac: '4764EA',
          finSupplementaryAddress: null,
          specialDeliveryInstructions: null,
          deliveryDate: null,
          requisitionType: 1,
          totalSellingPrice: compareSelect?.selectedContract?.totalSellingPrice,
          vehicleTypeCode:
            selectedVehicleType || selectedStandardItem?.vehicleType,
          afvIncremental: getAfvIncremental({
            afvTotal: compareSelect?.selectedContract?.afvTotal,
            quantity: compareSelect?.selectedContract?.quantity,
          }),
        },
        vehicle: {},
        additionalRequirements: [],
        clientData: {
          selectedOptions: {
            selectedOptions: compareSelect.selectedOptions,
          },
          clientState: {
            submitComment: '',
            currentStep: newStep,
            lowestBidContract: null,
            isDomesticShipment: true,
            paintAndGraphicsDescription,
            destinationType: destinationType,
            shipmentLocation: shipmentLocation,
            shipmentCountry: shipmentCountry,
            selectedState: shipmentState,
            selectedVehicleType: selectedVehicleType,
            selectedVehicleSubType: selectedVehicleSubType,
            selectedStandardItem: selectedStandardItem,
            selectedContract: compareSelect.selectedContract,
            regionId: account?.customer?.regionId || null,
          },
        },
      });
      const payload = _.merge(stateObj, data);
      return updateLeasingDraft({
        fetchPolicy: 'network-only',
        variables: {
          updateInput: payload,
          requisitionId: requisitionId,
        },
      });
    },
    hooks,
  ];
}
