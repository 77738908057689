import React from 'react';
import { Alert } from '@gsa/afp-component-library';

const JustBrowsingAlert = () => {
  return (
    <div className="margin-top-2">
      <Alert type="info" slim>
        <strong>You are just browsing.</strong> If you would like to create a
        requisition, you must initiate a purchasing or leasing requisition.
      </Alert>
    </div>
  );
};

export default JustBrowsingAlert;

JustBrowsingAlert.propTypes = {};

JustBrowsingAlert.defaultProps = {};
