import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,
  Button,
  Spinner,
  useModal,
  TextInput,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import ReturnIcon from '../../assets/images/return-icon';
import {
  RETURN_REQUISITION,
  RETURN_REQUISITION_TO_CO_REVIEW,
  RETURN_REQUISITION_TO_ENGINEERING_REVIEW,
  RETURN_REQUISITION_TO_ENGINEER_APPROVAL,
} from '../../services/data-layer';
import useLocalState from '../../hooks/useLocalState';
import { useQueryParam } from '../../hooks/useQueryParam';
import './PlaceOrderModal.scss';
import { ENGINEERING_REVIEW_STATUS } from './SubmitToEngineerButton/SubmitToEngineerButton';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import './ReturnOrderModal.scss';
import { isTaggedSin } from '../../utilities/requisitionStandardItemUtils';
import { isAreqType } from '../non-standard-purchase/constants';

const ReturnOrderModal = ({
  requisitionId,
  requisitionName,
  requisitionCartValidations,
  standardItemCode,
  canSubmitOrder,
  visible,
  isFromReqAction,
  status,
  requisitionCartState,
  currentStandardItem,
  isVendorDirect,
  isAreq = false,
}) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [, setRequsitionAction] = useLocalState('requsitionAction');
  const query = useQueryParam();
  const allowToCancel = query.get('allowToCancel');
  const allowToEdit = query.get('allowToEdit');
  const navigate = useNavigate();
  const [returnRequisitionCommentError, setReturnRequisitionCommentError] =
    useState('');
  const isAreqOrIsAreqType = useMemo(() => {
    return isAreq || isAreqType(requisitionCartState?.requisitionType);
  }, [isAreq, requisitionCartState]);

  const returnOrderModal = () => {
    const [returnComment, setReturnComment] = useState('');
    const systemChanges = requisitionCartValidations || null;

    const returnRequisitionMutationGql = () => {
      let gql = RETURN_REQUISITION;

      if (
          (status === RequisitionStatus.CONTRACTING_REVIEW && isAreqOrIsAreqType) ||
          (status === RequisitionStatus.FINAL_APPROVAL && isTaggedSin(currentStandardItem))
      ) {
        gql = RETURN_REQUISITION_TO_ENGINEERING_REVIEW;
      } else if (
        status === RequisitionStatus.ENGINEERING_APPROVAL ||
        (status === RequisitionStatus.FINAL_APPROVAL && !isVendorDirect)
      ) {
        gql = RETURN_REQUISITION_TO_CO_REVIEW;
      } else if (status === RequisitionStatus.CONTRACTING_APPROVAL) {
        gql = RETURN_REQUISITION_TO_ENGINEER_APPROVAL;
      }

      return gql;
    };

    const [returnRequisition, { loading: loadingReturnReq }] = useMutation(
      returnRequisitionMutationGql(),
      {
        onError: () => {
          closeModal();
        },
        onCompleted: () => {
          let destination = `/my-requisitions?draftName=${requisitionName}&returned=true`;

          if (
              (status === RequisitionStatus.CONTRACTING_REVIEW && isAreqOrIsAreqType) ||
                (status === RequisitionStatus.FINAL_APPROVAL && isTaggedSin(currentStandardItem))
          ) {
            destination = `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${ENGINEERING_REVIEW_STATUS}&returned=true`;
          } else if (status === RequisitionStatus.ENGINEERING_APPROVAL) {
            destination = `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.CONTRACTING_REVIEW}&returned=true`;
          } else if (status === RequisitionStatus.CONTRACTING_APPROVAL) {
            destination = `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.ENGINEERING_APPROVAL}&returned=true`;
          } else if (
            status === RequisitionStatus.FINAL_APPROVAL &&
            !isVendorDirect
          ) {
            destination = `/my-requisitions?draftName=${requisitionName}&returned=true&fromApproverToCo=true`;
          }
          return navigate(destination);
        },
      },
    );

    let modalTitle =
      'Are you sure you want to return the following requisition?';
    let modalSubTitle = requisitionName;
    let showErrors = false;
    let onSaveBtnLabel = 'Return requisition';
    let useOnCancelClick = false;

    if (canSubmitOrder && requisitionCartValidations) {
      modalTitle =
        "We're sorry, there have been changes to your selected vehicle";
      modalSubTitle =
        'Your agency will need to select new options from the Compare and Select page and resubmit this requisition.';
      showErrors = true;

      if (requisitionCartValidations.systemAvailability?.isSINexpired) {
        modalTitle =
          "We're sorry, the Standard Item Number for this vehicle has become unavailable";
        modalSubTitle =
          'Since the Standard Item Number for this vehicle has become unavailable, you will have to cancel this requisition to continue. To select another Standard Item Number, you will have to start a new requisition. ';
        onSaveBtnLabel = 'Cancel requisition';
        useOnCancelClick = true;
      }

      if (allowToCancel) {
        onSaveBtnLabel = 'Cancel requisition';
        useOnCancelClick = true;
      }
    }

    const Actions = ({ onCancel, onSave, onClickReview, saveBtnLabel }) => {
      return (
        <footer data-testid="return-order-modal">
          <div className="grid-row flex-row flex-align-center grid-gap-lg">
            {!showErrors && (
              <div>
                <Button
                  type="button"
                  variant="unstyled"
                  onClick={onCancel}
                  data-testid="place-req-cancel-btn"
                  label="Cancel"
                />
              </div>
            )}
            {allowToEdit && (
              <div>
                <Button
                  type="button"
                  onClick={onClickReview}
                  label="Edit requisition"
                  variant="outline"
                />
              </div>
            )}
            <div>
              <Button
                variant="Secondary"
                type="button"
                onClick={onSave}
                data-testid="return-req-btn"
                label={saveBtnLabel}
              />
            </div>
          </div>
        </footer>
      );
    };

    Actions.propTypes = {
      onCancel: PropTypes.func.isRequired,
      onSave: PropTypes.func.isRequired,
      onClickReview: PropTypes.func.isRequired,
      saveBtnLabel: PropTypes.func.isRequired,
    };

    const onChangeComment = (event) => {
      const { value } = event?.target;
      setReturnComment(value);
    };

    const onReturn = () => {
      if (returnComment.trim().length < 1) {
        setReturnRequisitionCommentError('This field is required');
      } else {
        setReturnRequisitionCommentError(null);
        returnRequisition({
          variables: {
            requisitionId,
            submitComment: returnComment.trim(),
            isVendorDirect,
          },
        });
      }
    };

    const onCancelClick = () => {
      if (requisitionId) {
        setRequsitionAction({
          requisition: {
            requisitionId,
            name: requisitionName,
          },
          action: 'cancelRequisition',
          comment: 'SIN is no longer available',
        });
        navigate('my-requisitions');
      }
    };

    const onReview = () => {
      const isSINexpired =
        requisitionCartValidations?.systemAvailability?.isSINexpired;
      let target = 'vehicle-listings';
      if (!isSINexpired) {
        if (requisitionId) {
          target = `vehicle-requisition?requisitionId=${requisitionId}`;
        } else if (standardItemCode) {
          target = `vehicle-requisition?sin=${standardItemCode}`;
        }
      }
      navigate(target, { replace: true });
    };

    if (loadingReturnReq) {
      return <Spinner aria-busy="true" size="large" />;
    }

    const ValidationErrorMessageListing = () => (
      <>
        <div className="box">
          {systemChanges.systemAvailability &&
            systemChanges.systemAvailability.isSINexpired && (
              <div className="warning-message">
                <span>Selected make/model is now unavailable</span>
              </div>
            )}

          {systemChanges.validationErrors &&
            systemChanges.validationErrors.map((item) => (
              <div className="warning-message">
                <span>{item}</span>
              </div>
            ))}
        </div>
      </>
    );

    return (
      <Modal
        title={<h2>{modalTitle}</h2>}
        variant="large"
        onClose={closeModal}
        actions={
          <Actions
            onCancel={closeModal}
            onSave={!useOnCancelClick ? onReturn : onCancelClick}
            onClickReview={onReview}
            saveBtnLabel={onSaveBtnLabel}
          />
        }
      >
        {(allowToCancel || allowToEdit) && useOnCancelClick && (
          <div className="place-order-comment-section">
            <ValidationErrorMessageListing />
          </div>
        )}
        {!useOnCancelClick && (
          <div className="place-order-comment-section">
            {showErrors && <ValidationErrorMessageListing />}
            <p className="subtitle-requisition-name">{modalSubTitle}</p>
            <b>
              Provide reason for return<span className="red">*</span>
            </b>
            <TextInput
              id="place-order-modal-comment"
              type="textarea"
              maxLength={500}
              errorMessage={returnRequisitionCommentError}
              placeholder="Type comment here..."
              value={returnComment}
              onChange={onChangeComment}
              data-testid="place-order-modal-comment"
              characterLimit={500}
            />
          </div>
        )}
        {useOnCancelClick && (
          <div className="place-order-comment-section">
            <p>{modalSubTitle}</p>
          </div>
        )}
      </Modal>
    );
  };

  const ConnectedReturnOrderModal = connectModal(returnOrderModal);

  useEffect(() => {
    // current user can submit the order
    // and there is something wrong with this requisition
    if (
      (canSubmitOrder && requisitionCartValidations) ||
      ((allowToCancel || allowToEdit) && requisitionCartValidations) ||
      isFromReqAction
    ) {
      openModal();
    }
  }, [requisitionCartValidations, canSubmitOrder, isFromReqAction]);

  const returnLabel = () => {
    switch (status) {
      case RequisitionStatus.CONTRACTING_REVIEW:
        if (isAreqOrIsAreqType) {
          return 'Return to engineering review';
        }
        if (!isVendorDirect) {
          return 'Return to customer';
        }
        break;
      case RequisitionStatus.CONTRACTING_APPROVAL:
        return 'Return to engineer';
      case RequisitionStatus.ENGINEERING_APPROVAL:
        return 'Reject and return to contracting officer';
      case RequisitionStatus.FINAL_APPROVAL:
        if (
          requisitionCartState?.clientData?.selectedOptions?.customerInputs
            ?.engineerTaggedOptions?.length > 0 ||
          isTaggedSin(currentStandardItem)
        ) {
          return 'Return to Engineer';
        }
        if (!isVendorDirect) {
          return 'Return to Contracting Officer';
        }
        break;
      default:
    }

    return 'Return to submitter';

    //   let label = 'Return to submitter';

    //   if (
    //     (status === RequisitionStatus.CONTRACTING_REVIEW && isAreqOrIsAreqType) ||
    //     status === RequisitionStatus.CONTRACTING_APPROVAL
    //   ) {
    //     label = 'Return to engineer';
    //   } else if (status === RequisitionStatus.ENGINEERING_APPROVAL) {
    //     label = 'Reject and return to contracting officer';
    //   } else if (status === RequisitionStatus.FINAL_APPROVAL) {
    //     if (
    //       requisitionCartState?.clientData?.selectedOptions?.customerInputs
    //         ?.engineerTaggedOptions?.length > 0 ||
    //       isTaggedSin(currentStandardItem)
    //     )
    //       label = 'Return to Engineer';
    //     else if (!isVendorDirect) {
    //       label = 'Return to Contracting Officer';
    //     } else {
    //       label = 'Return to Submitter';
    //     }
    //   }
    //   if (status === RequisitionStatus.CONTRACTING_REVIEW && !isVendorDirect) {
    //     label = 'Return to Customer';
    //   }
    //   return label;
  };

  return (
    <>
      {visible && (
        <Button
          type="button"
          variant="secondary"
          data-testid="return-to-submitter-button"
          onClick={openModal}
          label={
            <>
              <ReturnIcon /> {returnLabel()}
            </>
          }
        />
      )}
      <ConnectedReturnOrderModal
        data-testid="place-order-modal"
        isOpen={isOpen}
      />
    </>
  );
};

ReturnOrderModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  requisitionCartValidations: PropTypes.arrayOf(Object),
  standardItemCode: PropTypes.string,
  canSubmitOrder: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  isFromReqAction: PropTypes.bool,
  status: PropTypes.string,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  currentStandardItem: PropTypes.instanceOf(Object),
  isAreq: PropTypes.bool,
  isVendorDirect: PropTypes.bool,
};

ReturnOrderModal.defaultProps = {
  standardItemCode: null,
  requisitionCartValidations: null,
  isFromReqAction: false,
  status: '',
  currentStandardItem: undefined,
  isVendorDirect: false,
  isAreq: false,
};

export default ReturnOrderModal;
