import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import ReviewDetails from '../../../ReviewComponents/ReviewDetails/ReviewDetails';

export default function AreqReviewVehicleBuildDetail({ printPreview = false }) {
  const { state } = useContext(VehicleRequisitionContext);
  const {
    optionalReqData,
    isAreq,
    vehicleColors,
    taggedOptions,
    vehicleOptions,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    contractAgency,
    getStandardItem,
    nonLowBidJustification,
    selectedContractAgencyInformation,
    selectedContract,
    currentStandardItem,
    agencyReferenceNumber,
    additionalRequirements,
    selectedOptionsForPrice,
  } = state;

  const isEditable = !printPreview;

  return (
    <>
      <ReviewDetails
        vehicleColors={vehicleColors}
        taggedOptions={taggedOptions}
        selectedContractCostBreakdown={{
          perVehicleOptions: selectedOptionsForPrice,
        }}
        vehicleOptions={vehicleOptions}
        requisitionAddress={requisitionStateContext}
        mailingAddress={mailingStateContext}
        deliveryAddress={deliveryStateContext}
        contractAgency={contractAgency}
        getStandardItem={getStandardItem}
        nonLowBidJustification={nonLowBidJustification}
        optionalReqData={optionalReqData}
        selectedContractAgencyInformation={selectedContractAgencyInformation}
        calculatedPriceData={{
          perVehicleOptions: selectedOptionsForPrice,
        }}
        selectedContract={selectedContract}
        isAreq={isAreq}
        currentStandardItem={currentStandardItem}
        agencyReferenceNumber={agencyReferenceNumber}
        additionalRequirements={additionalRequirements}
        isEditable={isEditable}
        expanded
        printPreview={printPreview}
      />
    </>
  );
}

AreqReviewVehicleBuildDetail.propTypes = {
  printPreview: PropTypes.bool,
};
AreqReviewVehicleBuildDetail.defaultProps = {
  printPreview: false,
};
