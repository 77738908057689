import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_STANDARD_CODES } from '../../services/data-layer';

import './MultipleAdditonalRequirementsAreqInfo.scss';
import { concatStrings } from '../../utilities/formatUtil';
import { formatPhoneNumber } from '../ReviewComponents/AgencyFinancial/agencyFinancialUtils';

const MultipleAdditonalRequirementsAreqInfo = ({
  createdByInfo,
  vehicleType,
}) => {
  const [standardCodes, setStandardCodes] = useState({
    primary: null,
    secondary: null,
  });

  const [getStandardCodes, { loading }] = useLazyQuery(GET_STANDARD_CODES, {
    fetchPolicy: 'c',
  });

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    getStandardCodes({
      variables: {
        filters: {
          operator: 'EQ',
          key: 'code',
          value: vehicleType,
        },
      },
      onCompleted: (data) => {
        if (!data?.getStandardCodes?.rows?.length) {
          return;
        }

        const standardCodePocs = data.getStandardCodes?.rows.reduce(
          (list, code) => {
            return list.concat(code.standardsVehicleGroupPocs || []);
          },
          [],
        );

        setStandardCodes({
          primary:
            standardCodePocs.find((c) => c.pocType === 'PocPrimaryEngineer') ||
            null,
          secondary:
            standardCodePocs.find(
              (c) => c.pocType === 'PocSecondaryEngineer',
            ) || null,
        });
      },
    });
  }, [vehicleType]);

  return (
    <div className="area-contact">
      {loading ? (
        <Spinner
          aria-busy="true"
          size="small"
          className="row-spinner-address"
        />
      ) : (
        <>
          <div className="responsible-for-areq">
            <h4>AREQ preparer</h4>
            <strong>
              {concatStrings(createdByInfo?.firstName, createdByInfo?.lastName)}
            </strong>
            <br />
            <span className="email-text">{createdByInfo?.email}</span>
            <br />
            {formatPhoneNumber(createdByInfo?.phoneNumber)}{' '}
            {createdByInfo?.phoneNumberExt}
          </div>
          {standardCodes.primary && (
            <div className="responsible-for-areq">
              <h4>Primary engineer</h4>
              <strong>{standardCodes.primary.name}</strong>
              <br />
              <span className="email-text">{standardCodes.primary.email}</span>
              <br />
              {formatPhoneNumber(standardCodes.primary.phoneNumber)}
            </div>
          )}
          {standardCodes.secondary && (
            <div className="responsible-for-areq">
              <h4>Secondary engineer contact</h4>
              <strong>{standardCodes.secondary.name}</strong>
              <br />
              <span className="email-text">
                {standardCodes.secondary.email}
              </span>
              <br />
              {formatPhoneNumber(standardCodes.secondary.phoneNumber)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

MultipleAdditonalRequirementsAreqInfo.propTypes = {
  createdByInfo: PropTypes.instanceOf(Object).isRequired,
  vehicleType: PropTypes.string.isRequired,
};

export default MultipleAdditonalRequirementsAreqInfo;
