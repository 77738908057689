import { gql } from '@apollo/client';

export const CREATE_REQUISITION_DRAFT_NEW = gql`
  mutation CreateRequisitionDraft(
    $createInput: RequisitionCreatePropsInput!
    $updateInput: RequisitionUpdatePropsInput
  ) {
    createRequisitionDraft(
      createInput: $createInput
      updateInput: $updateInput
    ) {
      requisitionStatus
      requisitionId
      friendlyName
      requisitionStatus
      contractLineId
      standardItemId
      accountingClassificationReferenceNumber
      additionalRequirements {
        priceEstimate
      }
      createdByInfo {
        id
        firstName
        lastName
        email
        phoneNumber
        phoneNumberExt
      }
      agencyCode
      agencyReferenceNumber
      boac
      bureauCode
      clientData {
        clientState
        selectedOptions
        activeContracts
      }
      agencyInfo {
        name
      }
      mailingAddress {
        addressLine1
        addressLine2
        city
        countryCode
        zipcode
      }
      deliveryAddress {
        addressLine1
        addressLine2
        city
        countryCode
        zipcode
        deliveryDealership {
          dealershipDeliveryCode
          id
          dealershipId
          dealershipDeliveryCode
          makeCode
          email
          phone
          address1
          address2
          city
          stateId
          countryId
          postalCode
          dealerName
        }
      }
    }
  }
`;

export const UPDATE_REQUISITION_DRAFT_NEW = gql`
  mutation UpdateRequisitionDraft(
    $updateInput: RequisitionUpdatePropsInput!
    $requisitionId: String!
  ) {
    updateRequisitionDraft(
      updateInput: $updateInput
      requisitionId: $requisitionId
    )
  }
`;

export const CREATE_REQUISITION_DRAFT_OlD = gql`
  mutation CreatePurchaseLineRequisition(
    $requisitionType: Float!
    $friendlyName: String!
    $agencyCode: String
    $bureauCode: String
    $officeCode: String
    $purchasingForAgencyCode: String
    $purchasingForBureauCode: String
    $purchasingForOfficeCode: String
    $standardItemCode: String
    $contractLineId: Float
    $contractNumber: String
    $contractVersion: Float
    $standardItemId: Float
    $scheduleLine: String
    $makeCode: String
    $quantity: Float
    $selectedOptions: JSONObject
    $subCategoryCode: String
    $modelCode: String
    $selectedColors: [SelectedColor!]
    $additionalRequirements: [AdditionalRequirement!]
    $justification: String
    $clientState: JSONObject
    $agencyInformation: AgencyInformation
    $requisitionAddress: AddressInput
    $mailingAddress: AddressInput
    $deliveryAddress: AddressInput
    $isMailingSameAsRequisition: Boolean
    $isDeliverySameAsRequisition: Boolean
    $isLowPrice: Boolean
    $isAreq: Boolean
    $agencyReferenceNumber: String
    $purchaseRate: Float
    $modelYear: String
    $baseShipmentDays: Float
    $additionalShipmentDays: Float
    $specialDeliveryInstructions: String
    $deliveryDate: String
    $urgentRequirementJustification: String
    $nonSopData: JSONObject
    $isUrgentRequirement: Boolean
  ) {
    createPurchaseLineRequisition(
      requisitionType: $requisitionType
      friendlyName: $friendlyName
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
      purchasingForAgencyCode: $purchasingForAgencyCode
      purchasingForBureauCode: $purchasingForBureauCode
      purchasingForOfficeCode: $purchasingForOfficeCode
      standardItemCode: $standardItemCode
      standardItemId: $standardItemId
      contractLineId: $contractLineId
      contractNumber: $contractNumber
      contractVersion: $contractVersion
      scheduleLine: $scheduleLine
      makeCode: $makeCode
      modelCode: $modelCode
      quantity: $quantity
      selectedOptions: $selectedOptions
      subCategoryCode: $subCategoryCode
      selectedColors: $selectedColors
      additionalRequirements: $additionalRequirements
      justification: $justification
      clientState: $clientState
      agencyInformation: $agencyInformation
      requisitionAddress: $requisitionAddress
      mailingAddress: $mailingAddress
      deliveryAddress: $deliveryAddress
      isMailingSameAsRequisition: $isMailingSameAsRequisition
      isDeliverySameAsRequisition: $isDeliverySameAsRequisition
      isLowPrice: $isLowPrice
      isAreq: $isAreq
      agencyReferenceNumber: $agencyReferenceNumber
      purchaseRate: $purchaseRate
      modelYear: $modelYear
      baseShipmentDays: $baseShipmentDays
      additionalShipmentDays: $additionalShipmentDays
      specialDeliveryInstructions: $specialDeliveryInstructions
      deliveryDate: $deliveryDate
      urgentRequirementJustification: $urgentRequirementJustification
      nonSopData: $nonSopData
      isUrgentRequirement: $isUrgentRequirement
    ) {
      id
      reqId
      requisitionType
      friendlyName
      agencyInfo {
        shortName
        name
        isDOD
        isCfoActAgency
        oldAgencyCode
      }
      bureauInfo {
        shortName
        name
        agencyIndicatorCode
      }
      officeInfo {
        officeName
        address1
        address2
        address3
        city
        stateCode
        postalCode
      }
      requisitionTypeCode
      status
      totalSellingPrice
      clientState
      requisitionCartValidations {
        vehicleAvailability {
          isContractExpired
        }
        lowPriceChange {
          oldLowPrice
          newLowPrice
          hasLowPriceChanged
        }
        purchaseFeeChange {
          difference
        }
        systemAvailability {
          isSINexpired
        }
        modelYearChange {
          oldModelYear
          newModelYear
        }
        optionsUnavailable {
          optionCode
          optionDescription
        }
        optionPricesChanges {
          newPrice
          oldPrice
          optionCode
          vendorName
          difference
        }
        optionCollisionChanges {
          newRelation
          oldRelation
          optionCode
          vendorName
        }
        validationErrors
      }
      purchasingType
      requisitionCartPurchaseLine {
        id
        requisitionCartId
        contractLineId
        standardItemCode
        contractNumber
        contractModification
        scheduleLine
        makeCode
        model_code
        quantity
        unitPriceToGsa
        unitPriceToCustomer
        totalSellingPrice
        isAreq
        agencyReferenceNumber
        isLowPrice
        activeContracts
        justification
        isOverseasDeliveryOptionSelected
        overseasDeliveryEquipmentCode
        baseShipmentDays
        isAdditionalShipmentDays
        additionalShipmentDays
        additionalShipmentEquipmentCode
        finSignalCode
        finFundCode
        finServiceCode
        finAdditionalFinancialInformation
        requisitionAddressId
        mailingAddressId
        deliveryAddressId
        isDealerDelivery
        isConsigneeDelivery
        deliveryDealershipCode
        contact
        selectedOptions
        requisitionCartPurchaseLineColor {
          make_color_code
          make_color_name
          quantity
        }
        additionalRequirements {
          description
          priceEstimate
          attachments {
            name
            description
            signedUrl
            metadataId
            attachmentTypeId
          }
        }
        customerAssignedNumber
        finSignalCode
        finFundCode
        finServiceCode
        reqActivityAddress
        reqJulianDate
        reqSerialNumber
        finSupplementaryAddress
        finAdditionalFundsPerUnit
        requisitionContact {
          firstName
          lastName
          phoneNumber
          email
        }
        requisitionerAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCallingCode
          faxNumber
          faxExtension
          phoneCountryCallingCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
          isMilitary
        }
        mailingAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCallingCode
          faxNumber
          faxExtension
          phoneCountryCallingCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
          isMilitary
        }
        deliveryAddress {
          agencyCode
          bureauCode
          entityName
          line1
          line2
          line3
          city
          stateCode
          countryCode
          zipcode
          zipcodePlusFour
          email
          faxCountryCallingCode
          faxNumber
          faxExtension
          phoneCountryCallingCode
          phoneNumber
          phoneExtension
          isUSPSVerified
          firstName
          lastName
          isMilitary
          isDealershipDelivery
          dealerId
        }
        specialDeliveryInstructions
        deliveryDate
        urgentRequirementJustification
        nonSopData
      }
    }
  }
`;

/* 

Data in each stepper page :

Header {
    agencyCode:
    bureauCode:

}

1. Compare and Select  
     {
        standardItemId: 
        contractLineId:
        clientState.selectedOptions:
        makeCode: 
        modelCode: 
        modelYear: 
        baseShipmentDays:
        additionalShipmentDays:
        collisions: 
        contract data: 
        deliveryDays:
        calculatedPrice
     }
2. Paint and Color
     {
       vehilce color
       colorOptions ?
     }
3. Review and Build  {
        options
        prices

}     
4.  Agency Information : {
    boac:
    aac:
    agencyPoc: 
    agencyOrderNumber: 
    tcn:
    nationalstocknumber: NSN
    requisitionNumber:
    registrationNumber:
    finFundCode?: string;
    finServiceCode?: string;
    finSignalCode?: string;
    finSupplementaryAddress?: string;
    MarkForInformation:
    mailingAddress
    DeliveryAddress

}    

*/
