import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './AccordionUtilsComponent.scss';

import { Accordion } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../context/VehicleRequisitionContext/VehicleRequisitionContext';

export const AccordionUtilComponent = ({
  label,
  content,
  expanded,
  printPreview = false,
  isMas,
  isUrgReq,
}) => {
  const {
    state: {
      areqPrintPreview,
      masPrintPreview,
      urgReqPrintPreview,
      sopPrintPreview,
      isAreq,
    },
  } = useContext(VehicleRequisitionContext);

  const items = [
    {
      title: (
        <div
          className="add-options-accordion-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {label}
        </div>
      ),
      content,
      expanded,
    },
  ];

  if (isMas) {
    if (printPreview && !masPrintPreview[label]) {
      return null;
    }
  } else if (isUrgReq) {
    if (printPreview && !urgReqPrintPreview[label]) {
      return null;
    }
  } else if (isAreq) {
    if (printPreview && !areqPrintPreview[label]) {
      return null;
    }
  } else if (printPreview && !sopPrintPreview[label]) {
    return null;
  }

  return (
    <div className="review-detail">
      <Accordion
        className="accordion-util"
        multiselectable={false}
        bordered="true"
        items={items}
      />
    </div>
  );
};

AccordionUtilComponent.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  expanded: PropTypes.bool,
  printPreview: PropTypes.bool,
  isMas: PropTypes.bool,
  isUrgReq: PropTypes.bool,
};

AccordionUtilComponent.defaultProps = {
  expanded: true,
  printPreview: false,
  isMas: false,
  isUrgReq: false,
};
