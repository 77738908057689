import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import './HorizontalPagination.scss';

export const HorizontalPagination = ({
  id,
  currentPage,
  offset,
  totalNumberOfResults,
  handlePageChange,
}) => {
  const handleClick = (direction) => {
    if (direction === 'prev') {
      handlePageChange(currentPage - 1);
    }
    if (direction === 'next') {
      handlePageChange(currentPage + 1);
    }
  };

  const isFirstPage = currentPage === 0;
  const isLastPage = (currentPage + 1) * offset >= totalNumberOfResults;
  const displayingNumbers = () => {
    const startNumber = currentPage * offset + 1;
    const endNumber = isLastPage
      ? totalNumberOfResults
      : (currentPage + 1) * offset;
    return `${startNumber} - ${endNumber}`;
  };

  return (
    <div className="horizontal-pagination-container">
      <div className="grid-row flex-row grid-gap-2">
        {!isFirstPage && (
          <Button
            variant="unstyled"
            className="text-no-underline"
            onClick={() => handleClick('prev')}
            data-testid={`${id}-view-previous`}
            aria-label="Previous"
            leftIcon={{
              name: 'custom_navigate_far_left',
              className: 'text-primary',
              type: 'custom',
            }}
            label="Previous"
          />
        )}
        <span
          data-testid={`${id}-pagination-results`}
          className="compare-page-pagination"
        >
          Displaying <strong>{displayingNumbers()}</strong> of{' '}
          <strong>{totalNumberOfResults} items </strong>
        </span>
        {!isLastPage && (
          <div className="flex-align-self-end">
            <Button
              variant="unstyled"
              className="text-no-underline"
              onClick={() => handleClick('next')}
              data-testid={`${id}-view-next`}
              aria-label="Next"
              rightIcon={{
                name: 'custom_navigate_far_right',
                className: 'text-primary',
                type: 'custom',
              }}
              label="View Next"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalPagination;
