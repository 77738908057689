import React from 'react';
import { Breadcrumbs as DSBreadcrumbs, Button } from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { OrderingGuideSteps } from '../constants';
import { navigateToContractDetails, setUpdatedContractLineECs } from '../../../reducers/ordering-guide';

const BreadcrumbsEC = () => {

  const dispatch = useDispatch();
  const goToECDetails = () => {
    dispatch(
      navigateToContractDetails({
        currentStep: OrderingGuideSteps.CONTRACT_LIST,
        selectedContractLine: null,
      }),
    );
    
    dispatch(setUpdatedContractLineECs({
      updatedContractECs: []
    }))
  };

  return <DSBreadcrumbs
    trail={[
      <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
        Home
      </a>,
      <Button
        label="Ordering Guide"
        data-testid="reset-filter"
        type="button"
        variant="unstyled"
        className="width-auto"
        onClick={goToECDetails}
      />,
    ]}
    current="Equipment codes"
  />
};
export default BreadcrumbsEC;
