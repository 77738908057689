import React from 'react';
import PropTypes from 'prop-types';
import './OrderModMessageLinesDisplay.scss';

const OrderModMessageLinesDisplay = ({ messageLines }) => {
  return (
    <section
      id="order-mod-message-lines"
      role="tab"
      tabIndex="0"
      className="margin-bottom-2 margin-top-1"
    >
      <div className="font-body-lg text-bold margin-bottom-2">
        Message lines
      </div>
      <div className="bg-primary-lightest blue-container">
        <div className="message-lines-content">
          <div>
            <div className="usa-label">Line 1</div>
            <div className="font-body-md" key={messageLines?.message1 ?? 'ml1'}>
              {messageLines?.message1 ?? <span>&ndash;</span>}
            </div>
          </div>
          <div>
            <div className="usa-label">Line 2</div>
            <div className="font-body-md" key={messageLines?.message2 ?? 'ml2'}>
              {messageLines?.message2 ?? <span>&ndash;</span>}
            </div>
          </div>
          <div>
            <div className="usa-label">Line 3</div>
            <div className="font-body-md" key={messageLines?.message3 ?? 'ml3'}>
              {messageLines?.message3 ?? <span>&ndash;</span>}
            </div>
          </div>
          <div>
            <div className="usa-label">Line 4</div>
            <div className="font-body-md" key={messageLines?.message4 ?? 'ml4'}>
              {messageLines?.message4 ?? <span>&ndash;</span>}
            </div>
          </div>
          <div>
            <div className="usa-label">Line 5</div>
            <div className="font-body-md" key={messageLines?.message5 ?? 'ml5'}>
              {messageLines?.message5 ?? <span>&ndash;</span>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OrderModMessageLinesDisplay.propTypes = {
  messageLines: PropTypes.shape({
    message1: PropTypes.string,
    message2: PropTypes.string,
    message3: PropTypes.string,
    message4: PropTypes.string,
    message5: PropTypes.string,
  }),
};

OrderModMessageLinesDisplay.defaultProps = {
  messageLines: {
    message1: null,
    message2: null,
    message3: null,
    message4: null,
    message5: null,
  },
};
export default OrderModMessageLinesDisplay;
