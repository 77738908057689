/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useWatch } from 'react-hook-form';
import { formatCurrency } from '../../../utilities/CompareVehicleUtils';
import './TotalReqAmount.scss';

const TotalReqAmount = () => {
  const additionalReqs = useWatch({
    name: 'additionalRequirements',
  });

  const sum = additionalReqs?.reduce(
    (accum, curr) => accum + (Number.parseFloat(curr.priceEstimate, 10) || 0),
    0,
  );

  return (
    <div className="total-req-amount-div">
      <div className="total-req-description">
        Total aggregate price estimate of AREQ from Independent Government Cost
        Estimate (IGCE)
      </div>
      <div className="total-req-amount">{formatCurrency(sum)}</div>
    </div>
  );
};

export default TotalReqAmount;
