export const humanize = (rawString) => {
  if (!rawString) {
    return '';
  }

  return rawString
    .trim()
    .split(/\s+/)
    .map((term) => {
      return term.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
    })
    .join(' ')
    .toLowerCase()
    .replace(/^./, (m) => {
      return m.toUpperCase();
    });
};
export const getUrlVar = (key, location) => {
  const expression = '=([^&]*)';
  const result = new RegExp(key + expression, 'i').exec(location.search);
  return (result && unescape(result[1])) || '';
};

export const withoutAREQOption = (options) => {
  if (!options) {
    return [];
  }

  return options.filter(({ optionCode }) => optionCode !== 'AREQ');
};

export const noDuplicateOptions = (options) => {
  if (!options) {
    return [];
  }

  const optionCodeMap = {};

  const newOptions = options.filter(({ optionCode }) => {
    const inThere = optionCodeMap[optionCode];
    if (!inThere) {
      optionCodeMap[optionCode] = true;
      return true;
    }

    return false;
  });

  return newOptions;
};

export default humanize;
