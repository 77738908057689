import React, { useRef } from 'react';
import { ComboBox } from '@gsa/afp-component-library';
import './combobox.scss';

function ComboBoxControl(_, config, useFormContext) {
  const { setValue, getValues } = useFormContext();
  const { id, config: configDefaultValue, element, options } = config;

  let { current: defaultValue } = useRef();

  if (!defaultValue) {
    defaultValue = configDefaultValue;
  }

  const updateValue = (value) => {
    const currentValue = getValues(id);
    const clearing = currentValue !== undefined && value === undefined;

    if (id && clearing) {
      if (currentValue) {
        setValue(id, '', { shouldDirty: true, shouldTouch: true });
      } else {
        setValue(id, '');
      }
    } else if (id && value !== undefined && value !== currentValue) {
      setValue(id, value || '', {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <ComboBox
      {...element?.control}
      options={options}
      onChange={updateValue}
      defaultValue={getValues(id)}
    />
  );
}

export default ComboBoxControl;
