import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';

export const PublicRoute = ({ title }) => {
  useTitle(title);

  return <Outlet />;
};
PublicRoute.defaultProps = {
  title: '',
};
PublicRoute.propTypes = {
  title: PropTypes.string,
};
export default PublicRoute;
