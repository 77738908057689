import React from 'react';
import PropTypes from 'prop-types';
import useRequisitionReview from '../../../../../utilities/useRequisitionReview';
import './ReviewSummaryContractingHeader.scss';

const ReviewSummaryContractingHeader = ({ contractorApproval }) => {
  const { isVendorDirect, vendorDirectLoaded } = useRequisitionReview();
  let tasks = [
    'Review all information below drafted by customer.',
    'Provide vendor bids',
    'Send requisition with vendor bids to customer for review (or if this requisition contains issues, return with explanation)',
  ];
  if (contractorApproval) {
    tasks = [
      'Please review all information below established by the customer',
      'Review vendor bids and engineer evaluation',
      <>
        Submit requisition for <strong>customer&apos;s response</strong>
      </>,
    ];
  } else if (!isVendorDirect && vendorDirectLoaded) {
    tasks = ['Review all information below drafted by customer.'];
  }

  return (
    <div
      data-testid="review-summary-co-header"
      className="review-summary-co-header"
    >
      <div className="title">Contracting officer&apos;s tasks:</div>
      <ol>
        {vendorDirectLoaded &&
          tasks.map((task) => (
            <li key={task} className="task">
              {task}
            </li>
          ))}
      </ol>
    </div>
  );
};

ReviewSummaryContractingHeader.propTypes = {
  contractorApproval: PropTypes.bool,
};

ReviewSummaryContractingHeader.defaultProps = {
  contractorApproval: false,
};

export default ReviewSummaryContractingHeader;
