import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@apollo/client';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import { CREATE_REQUISITION_COMMENT } from '../../../../services/data-layer';
import { resetFocusToFirstElement } from '../../../../utilities/commons';
import { utcDateToMMDDYYYY } from '../../../../utilities/commonUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { AccordionUtilComponent } from '../../../../utilities/AccordionUtilComponent';
import VehicleSpecificationsReview from '../../review-details/vehicle-specifications/VehicleSpecificationsReview';
import JustificationReview from '../../review-details/justification-review/JustificationReview';
import AgencyInformationReview from '../../review-details/agency-information/AgencyInformationReview';
import DeliveryInformationReview from '../../review-details/delivery-information/DeliveryInformationReview';
import RequisitionSubmitModal from '../utils/RequisitionSubmissionModal';
import RequisitionActions from '../../../../components/RequisitionActions/RequisitionActions';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import MasPrintPreviewModal from '../../../../components/MasReqPrintPreviewModal/MasReqPrintPreviewModal';
import ReqReturnedBanner from '../../../../components/ReqReturnedBanner/ReqReturnedBanner';
import { RequisitionStatus } from '../../../ReviewDetails/RequisitionDetailsUtils';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';
import CancelRequisitionButton from '../../../ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';
import { UserRoles } from '../../../../constants/user-constants';

const UrgentRequisitionReviewPage = ({ handlePageNavigation }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const {
    draftRequisition,
    nonSopData,
    additionalRequirements,
    urgentRequirementJustification,
    urgentReqJustification,
    selectedContractAgencyInformation,
    requisitionStateContext,
    deliveryStateContext,
    mailingStateContext,
    deliveryDate,
    specialDeliveryInstructions,
  } = state;
  const ability = useAppAbility();
  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedClass, setExpandedClass] = useState('expand_more');
  const [showMenu, toggle] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);

  const [isPrintPreview, setIsPrintPreview] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const printRef = useRef(null);

  const printReqModal = useModal();
  const DisplayMasReqPrintModal = connectModal(MasPrintPreviewModal);

  const {
    currentUser: { id: currentUserId, roles },
  } = useCurrentUser();

  let currentUserCreatedRequisition = false;
  if (draftRequisition && draftRequisition.createdByInfo) {
    currentUserCreatedRequisition =
      currentUserId === draftRequisition.createdByInfo.id;
  } else {
    currentUserCreatedRequisition = true;
  }
  const isSubmitter = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );

  const isContractingOfficer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Solicitation),
    [ability],
  );

  const canEdit = !(
    draftRequisition?.requisitionStatus === RequisitionStatus.RETURNED &&
    !currentUserCreatedRequisition
  );

  const isSiteAdmin = useMemo(
    () => roles?.some((role) => role.name === UserRoles.SITE_ADMIN),
    [roles],
  );
  const isOrderingAdmin = useMemo(
    () =>
      roles?.some((role) => role.name === UserRoles.ORDERING_ADMIN),
    [roles],
    );

  const toggleMenu = (showMenuState) => {
    toggle(!showMenuState);
  };

  useEffect(() => {
    setExpandedClass(showMenu ? 'expand_less' : 'expand_more');
  }, [showMenu]);

  useEffect(() => {
    resetFocusToFirstElement();
  }, []);

  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT, {
    onCompleted: () => {
      setShowAddCommentModal(false);
    },
  });

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    const { appURLs } = window.AFP_CONFIG;
    submitRequisitionComment({
      variables: {
        requisitionId: draftRequisition?.requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  const handleClosePrintModal = () => {
    printReqModal.closeModal();
  };

  const pageStyle = `
    @media print {
      footer {
        position: fixed;
        bottom: 0;
      }
    }
  `;

  const printSummary = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
    onAfterPrint: () => setIsPrintPreview(false),
  });

  useEffect(() => {
    if (isPrintPreview) {
      printSummary();
    }
  }, [isPrintPreview]);

  const handlePrint = () => {
    setIsPrintPreview(true);
  };

  const handlePrintSummary = () => {
    setIsExpanded(true);
    printReqModal.openModal();
  };

  const returnTitle = isApprover
    ? 'This requisition has been returned by GSA Contracting'
    : 'This requisition has been returned by the agency approver';

  return (
    <div className="urgent-req-review" ref={printRef}>
      <div>
        <div className="title">Review requisition summary and submit</div>
        <div className="title-desc">
          Please review all entered information below prior to submitting your
          requisition. If you would like to make any edits, you may select the
          “Edit” link on the right of each section or navigate back within the
          requisition.{' '}
        </div>
      </div>
      <div className="vehicle-spec-review">
        <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
          <Button
            data-testid="urgent-req-actions-btn"
            type="button"
            variant="outline"
            label="Requisition actions"
            rightIcon={{ name: expandedClass }}
            className="requisition-action-button"
            onClick={() => {
              toggleMenu(showMenu);
            }}
          />
          <RequisitionActions
            show={showMenu}
            toggleMenu={toggleMenu}
            menuList={['Print summary', 'Post comment']}
            postComment={() => setShowAddCommentModal(true)}
            printSummary={handlePrintSummary}
          />
        </div>
        {draftRequisition?.requisitionStatus === RequisitionStatus.RETURNED && (
          <ReqReturnedBanner
            requisitionId={draftRequisition?.requisitionId}
            title={returnTitle}
          />
        )}
        <AccordionUtilComponent
          label="Vehicle Specifications and other requirements"
          printPreview={isPrintPreview}
          isUrgReq
          content={
            <VehicleSpecificationsReview
              title="Vehicle Specifications"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              nonSopData={
                nonSopData || requisitionStateContext?.urgentRequirement
              }
              additionalRequirements={additionalRequirements}
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>

      <div className="justification-review">
        <AccordionUtilComponent
          label="Urgent requirement justifications"
          printPreview={isPrintPreview}
          isUrgReq
          content={
            <JustificationReview
              title="Urgent requirement justifications"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              urgentRequirementJustification={
                urgentRequirementJustification ||
                requisitionStateContext?.nonSopData?.justification
              }
              urgentReqJustification={urgentReqJustification}
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>

      <div className="agency-information-review">
        <AccordionUtilComponent
          label="Agency information"
          printPreview={isPrintPreview}
          isUrgReq
          content={
            <AgencyInformationReview
              title="Agency information"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              selectedContractAgencyInformation={
                selectedContractAgencyInformation
              }
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>
      <div className="delivery-info-review">
        <AccordionUtilComponent
          label="Delivery information"
          printPreview={isPrintPreview}
          isUrgReq
          content={
            <DeliveryInformationReview
              title="Delivery information"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              requisitionAddress={requisitionStateContext}
              mailingAddress={mailingStateContext}
              deliveryAddress={deliveryStateContext}
              deliveryDate={utcDateToMMDDYYYY(deliveryDate)}
              specialDeliveryInstructions={specialDeliveryInstructions}
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>
      {((isSiteAdmin || isOrderingAdmin) || (((isSubmitter && currentUserCreatedRequisition) ||
        (isApprover && currentUserCreatedRequisition)) &&
        (!isContractingOfficer))) && (
          <div className="button-section">
            <Button
              variant="outline"
              type="button"
              data-testid="review-submit-prev-btn"
              onClick={() => {
                handlePageNavigation('previous');
              }}
              leftIcon={{ name: 'arrow_back' }}
              label="Previous"
            />
            <CancelRequisitionButton
              requisitionId={draftRequisition?.requisitionId}
            />
            <Button
              variant="default"
              data-testid="review-submit-action-req-btn"
              type="button"
              onClick={() => {
                setIsModalOpen(true);
              }}
              leftIcon={{ name: 'check' }}
              label={
                isApprover || isSiteAdmin || isOrderingAdmin
                  ? 'Submit to contracting'
                  : 'Submit to approver'
              }
            />
          </div>
        )}
      {isModalOpen && (
        <RequisitionSubmitModal
          requisitionId={draftRequisition?.requisitionId}
          onClose={() => setIsModalOpen(false)}
          draftName={draftRequisition?.friendlyName}
        />
      )}

      {showAddCommentModal && (
        <CommentModal
          data-testid="add-comment-modal-urg-req"
          title="Post a comment"
          body="Your comment will be added to the activity tracker and viewable by all participating parties"
          isOpen={showAddCommentModal}
          onSubmit={handleAddComment}
          onCancel={handleCancelComment}
          isRequired
        />
      )}

      <DisplayMasReqPrintModal
        isOpen={printReqModal.isOpen}
        handleClose={handleClosePrintModal}
        handlePrint={handlePrint}
        isUrgReq
        status={draftRequisition?.requisitionStatus}
      />
    </div>
  );
};

UrgentRequisitionReviewPage.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  currentStep: PropTypes.shape({
    text: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default UrgentRequisitionReviewPage;
