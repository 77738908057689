import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './CloseoutDate.scss';
import Moment from 'moment';
import { DateIcon } from '../../assets/images/date-icon';

const CloseoutDate = ({ contracts, lowestBidData }) => {
  return (
    <div className="afp-store-closeout-date">
      <table className="usa-table full-width-table" role="presentation">
        <tbody>
          <tr>
            <td data-testid="closeout-date-cell" className="closeout-date-cell">
              <DateIcon role="img" aria-label="Closeout Dates" />
              <span className="closeout-date-title">Closeout Dates</span>
            </td>
            {contracts.map((contract) => {
              const activateLowestBid =
                contract.contractLineId === lowestBidData.contractLineId
                  ? 'lowest-bid'
                  : '';

              return (
                <React.Fragment key={`closeout-date-${uuidv4()}`}>
                  <td
                    data-testid={`${contract.vendorName}-${contract.modelName}-closeout-date`}
                    className={`contract-cell ${activateLowestBid}`}
                  >
                    <label>
                      {Moment(contract.orderEndDate).format('MM/DD/YYYY')}
                    </label>
                  </td>
                </React.Fragment>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CloseoutDate.propTypes = {
  contracts: PropTypes.instanceOf(Array),
  lowestBidData: PropTypes.instanceOf(Object),
};

CloseoutDate.defaultProps = {
  contracts: [],
  lowestBidData: {},
};

export default CloseoutDate;
