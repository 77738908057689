import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from '../../RequisitionDetailsUtils';
import ReviewSummaryContractingHeader from './ReviewSummaryContractingHeader/ReviewSummaryContractingHeader';
import ReviewSummaryCustomerTasksHeader from './ReviewSummaryCustomerTasksHeader/ReviewSummaryCustomerTasksHeader';
import ReviewSummaryEngineerApprovalHeader from './ReviewSummaryEngineerApprovalHeader/ReviewSummaryEngineerApprovalHeader';
import { OrderingAdminReviewTaskList } from './OrderingAdminReviewTaskList/OrderingAdminReviewTaskList';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';
import { OrderingAdminApprovalTaskList } from './OrderingAdminApprovalTaskList/OrderingAdminApprovalTaskList';
import { UserRoles } from '../../../../constants/user-constants';

export const ReviewSummaryTaskList = ({ status }) => {
  const ability = useAppAbility();
  const isContractingOfficer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Solicitation),
    [ability],
  );
  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const isSubmitter = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );
  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );
  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );

  const isCOandCoReview =
    isContractingOfficer && status === RequisitionStatus.CONTRACTING_REVIEW;
  const isCOandCoApproval =
    isContractingOfficer && status === RequisitionStatus.CONTRACTOR_APPROVAL;
  const inEngineerApprovalStatus =
    status === RequisitionStatus.ENGINEERING_APPROVAL;
  const inPendingCustomerResponseStatus =
    status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE;
  // ORDERING_ADMIN_REVIEW
  const isLeasingAreqOrderingAdminReview =
    status === RequisitionStatus.ORDERING_ADMIN_REVIEW;

  const isLeasingAreqOrderingAdminApproval =
    status === RequisitionStatus.ORDERING_ADMIN_APPROVAL;

  const isCoVisible = isCOandCoApproval || isCOandCoReview;
  const isEngineerVisible = isEngineer && inEngineerApprovalStatus;
  const isCustomerVisible =
    (isApprover || isSubmitter) && inPendingCustomerResponseStatus;
  const isOrderingAdminVisible =
    isOrderingAdmin && isLeasingAreqOrderingAdminReview;
  const isOrderingAdminApprovalVisible =
    isOrderingAdmin && isLeasingAreqOrderingAdminApproval;

  return (
    <div id="review-summary-task-list">
      {isCoVisible && (
        <ReviewSummaryContractingHeader
          contractorApproval={isCOandCoApproval}
        />
      )}
      {isEngineerVisible && <ReviewSummaryEngineerApprovalHeader />}
      {isCustomerVisible && <ReviewSummaryCustomerTasksHeader />}
      {isOrderingAdminVisible && <OrderingAdminReviewTaskList />}
      {isOrderingAdminApprovalVisible && <OrderingAdminApprovalTaskList />}
    </div>
  );
};

ReviewSummaryTaskList.propTypes = {
  status: PropTypes.string.isRequired,
};
