import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SEARCH_VEHICLE_AVAILABILITY = gql`
  query SearchVAL(
    $orderBy: String!
    $order: String!
    $vehicleTypeIds: [String!]
    $standardItemIds: [Int!]
    $offset: Int
    $limit: Int
  ) {
    searchVAL(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      order: $order
      vehicleTypeIds: $vehicleTypeIds
      standardItemIds: $standardItemIds
    ) {
      count
      rows {
        standardItem
        standardItemTitle
        make
        model
        vehicleTypeTitle
        vendor
        openDate
        closedDate
      }
    }
  }
`;
