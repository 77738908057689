import {
    Button,
    TextInput,
    useModal, connectModal, Modal
} from '@gsa/afp-component-library';
import './select-replacement-vehicles.scss';
import { setReplacementVehicles } from '@/reducers/leasing';
import React, { useRef, useState } from 'react';
import { useSystemAlert } from '@/services/system-alert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useCreateLeasingVehicleReplacement, useGetLeasingReplacementVehicles, useGetVehicleByFilterWithZones, useVehicleExistsInRequisition } from '../../../../requests/leasing';
import {addReplacementVehicle, setLeasingAccount, setVehicleQuantity} from '../../../../reducers/leasing';

const SearchField = Object.freeze({
    PLATE: 'tagNumber',
    VIN: 'vin'
})
const RequisitionErrorInfoModal = ({handleClose}) => {
    return (
        <Modal
            data-testid="go-to-previous-modal"
            title={<div className="modal-title">Restricted  action</div>}
            onClose={() => {
                handleClose();
            }}
            actions={
                <div className="areq-print-preview-button-row">
                    <Button
                        type="button"
                        variant="unstyled"
                        data-testid="go-to-previous-modal-cancel-button"
                        onClick={handleClose}
                        label="Cancel"
                    />
                </div>
            }
        >
            <div className="modal-instructions">
                Vehicle must be associated with the same customer account as the requisition.
                If you would like to add a vehicle from a different customer account, please create a new requisition.
            </div>
        </Modal>
    );
}

const VehicleReplacementSearch = () => {
    const { clearSystemAlert } = useSystemAlert();
    const dispatch = useDispatch();
    const { account, selectedVehicles, zones, requisitionId, replacementVehiclesTableState, requisitionCustomerAccount } = useSelector(state => state.leasingReducer);
    const [searchField, setSearchField] = useState(null);
    const plateRef = useRef();
    const vinRef = useRef();
    const [createReplacementVehicle] = useCreateLeasingVehicleReplacement();
    const [getReplacementVehicles] = useGetLeasingReplacementVehicles();

    const displayRequisitionErrorModal = useModal();
    const DisplayRequisitionErrorModal = connectModal(
        RequisitionErrorInfoModal,
    );

    const handleReqErrorModal = () => {
        displayRequisitionErrorModal.openModal();
    }
    const [searchReplacementVehicles, {
        loading: loadingReplacementVehicles,
        error: replacementVehicleError,
        setError: setReplacementVehicleError,
        clearError: clearReplacementVehicleError
    }] = useGetVehicleByFilterWithZones();

    const [checkVehicleExistsInRequisition] = useVehicleExistsInRequisition();

    const getSearchValue = () => {
        if (searchField === SearchField.PLATE) {
            return plateRef.current.value;
        }

        if (searchField === SearchField.VIN) {
            return vinRef.current.value;
        }

        return null;
    }

    const handleSearch = (field, value) => {
        clearSystemAlert();
        clearReplacementVehicleError();

        if (!value) {
            throw new Error('Please enter a search value');
        }

        return searchReplacementVehicles({
            apolloOptions: {
                fetchPolicy: 'no-cache'
            },
            variables: {
                filters: [
                    {
                        operator: "$and",
                        conditions: [
                            {
                                key: field,
                                operator: "$exact",
                                value,
                            }
                        ]
                    }
                ]
            }
        })
    }

    const handlePlateInput = (e) => {
        setSearchField(SearchField.PLATE);
        if (e.target.value !== '' || e.keyCode === 8) {
            vinRef.current.value = '';
            clearReplacementVehicleError();
        }
    }

    const handleVinInput = (e) => {
        setSearchField(SearchField.VIN);
        if (e.target.value !== '' || e.keyCode === 8) {
            plateRef.current.value = '';
            clearReplacementVehicleError();
        }
    }

    const handleBlur = (e) => {
        if (e.target.id === searchField && replacementVehicleError) {
            clearReplacementVehicleError();
        }
    }

    const validateReplacementVehicle = async (vehicle) => {
        // AC3e:  Vehicle already added to current requisition - duplicate vehicle
        if (selectedVehicles.find(v => v.assetId === vehicle.id) && selectedVehicles.length > 0) {
            throw new Error('Vehicle has already been added to the requisition.');
        }

        // AC3b: Vehicle/asset has already been added to a requisition/order
        // todo
        const vehicleExistsInRequisition = await checkVehicleExistsInRequisition({
            variables: {
                assetId: vehicle.id,
                serialNumberVin: vehicle.vin,
            }
        });

        if (vehicleExistsInRequisition.data) {
            throw new Error(`Replacement already initiated: ${vehicleExistsInRequisition.data.requisition.friendlyName}`)
        }
        
        if (account && selectedVehicles.length > 0) {
            // AC3c:  Customer account does NOT match
            if (account && vehicle.customer.customerId !== account.customer.customerId) {
                throw new Error('All vehicles must be associated with the same customer account. Verify vehicle information.');
            }

            // AC3d:  Telematics exemption status does NOT match
            if (vehicle.isTelematicsExempt !== account.isTelematicsExempt) {
                throw new Error('The telematics exemption status must match on all vehicles.');
            }
        }
        if(requisitionCustomerAccount && selectedVehicles.length === 0) {
            if(requisitionCustomerAccount && vehicle.customer.customerId !== requisitionCustomerAccount.customerAccountId) {
                displayRequisitionErrorModal.openModal();
                throw new Error('Vehicle must be associated with the same customer account as the requisition.');
            }
        }

        return true;
    }

    const handleAddReplacementVehicle = async (e) => {
        e.preventDefault();
        const searchValue = getSearchValue();
        try {
            const searchResults = await handleSearch(searchField, searchValue);

            const validated = await validateReplacementVehicle(searchResults.data);
        
            if (validated) {
                if (requisitionId) {
                    createReplacementVehicle({
                        variables: {
                            data: {
                                assetId: searchResults.data.id,
                                serialNumberVin: searchResults.data.vin,
                                tagNumber: searchResults.data.tagNumber,
                                standardItemNumber: searchResults.data.standardItem.standardItemNumber,
                                requisitionId
                            }
                        }
                    }).then(() => {
                        getReplacementVehicles({
                            variables: {
                              requisitionId,
                              order: replacementVehiclesTableState.order,
                              limit: replacementVehiclesTableState.limit,
                              offset: (replacementVehiclesTableState.currentPage - 1) * replacementVehiclesTableState.limit,
                            }
                          }).then(({ data }) => {
                            const replacementVehicles = data.rows;
                            if (replacementVehicles?.length === 1) {
                                dispatch(setLeasingAccount(searchResults.data));
                            }
                                dispatch(setReplacementVehicles(replacementVehicles));
                                dispatch(setVehicleQuantity(replacementVehicles.length));
                          });
                    })
                } else {
                    dispatch(addReplacementVehicle({
                        assetId: searchResults.data.id,
                        vin: searchResults.data.vin,
                        tagNumber: searchResults.data.tagNumber,
                        standardItemNumber: searchResults.data.standardItem.standardItemNumber,
                    }));

                    if (selectedVehicles.length === 0) {
                        dispatch(setLeasingAccount(searchResults.data));
                    }
                }

                vinRef.current.value = '';
                plateRef.current.value = '';
            }
        } catch (e) {
            setReplacementVehicleError(e.error || e);
        }
    }

    const plateHasError = replacementVehicleError && (searchField === SearchField.PLATE || !searchField);
    const vinHasError = replacementVehicleError && (searchField === SearchField.VIN || !searchField);

    return (
        <form className='replacement-search' onSubmit={handleAddReplacementVehicle}>
            <div className='replacement-search-inputs'>
                <div>
                    <TextInput
                        inputRef={plateRef}
                        data-testid='search-license-plate'
                        id={SearchField.PLATE}
                        label='License plate'
                        onBlur={handleBlur}
                        onKeyUp={handlePlateInput}
                        onChange={handlePlateInput}
                        errorMessage={plateHasError ? replacementVehicleError.message : null}
                        showLabelError={plateHasError}
                    />
                </div>
                <div className='search-or'>or</div>
                <div>
                    <TextInput
                        inputRef={vinRef}
                        data-testid='search-vin'
                        id={SearchField.VIN}
                        label='Vehicle identification number (VIN)'
                        onBlur={handleBlur}
                        onKeyUp={handleVinInput}
                        onChange={handleVinInput}
                        errorMessage={vinHasError ? replacementVehicleError.message : null}
                        showLabelError={vinHasError}
                        hint={searchField === SearchField.VIN && '17 characters allowed'}
                    />
                </div>
            </div>
            <div>
                <Button
                    variant='outline'
                    label='Add Vehicle'
                    leftIcon={{ name: 'add' }}
                    type='submit'
                    disabled={loadingReplacementVehicles}
                />
            </div>
            <DisplayRequisitionErrorModal
                isOpen={displayRequisitionErrorModal.isOpen}
                handleClose={displayRequisitionErrorModal.closeModal}
            />
        </form>
    )
}

export default VehicleReplacementSearch;