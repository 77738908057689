import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useMyRequisitionsState from '../useMyRequisitionsState';
import './PutOnHoldModal.scss';

export const ON_HOLD_STATUS = 'on-hold';

const PutOnHoldModal = ({ requisition }) => {
  const navigate = useNavigate();
  const formProps = useForm({
    defaultValues: {
      onHoldComment: '',
    },
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formProps;

  const { toggleShowPutOnHoldModal, putRequisitionOnHold } =
    useMyRequisitionsState();

  const onSubmit = async (data) => {
    await putRequisitionOnHold(requisition?.requisitionId, data.onHoldComment);
    navigate(
      `/my-requisitions?reqId=${requisition?.requisitionId}&reqName=${requisition?.name}&reqStatus=${ON_HOLD_STATUS}`,
    );
  };

  return (
    <div
      className="afp-modal-overlay modalContainer modal-center put-on-hold-modal"
      data-testid="requisition-reopen-modal"
    >
      <Modal
        title="Are you sure you want to hold the following requisition for review?"
        // className="reopen-requisition-modal"
        onClose={toggleShowPutOnHoldModal}
        actions={
          <>
            <Button
              type="button"
              className="cancel-button margin-right-3"
              variant="unstyled"
              data-testid="cancel-requisition-reopen"
              onClick={toggleShowPutOnHoldModal}
              label="Cancel"
            />
            <Button
              className="modal-place-on-hold-button"
              type="button"
              leftIcon={{ name: 'alarm' }}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              data-testid="save-requisition-reopen"
              label="Place on hold"
            />
          </>
        }
      >
        <div className="modal-body">
          <div className="modal-body-requisition">{requisition?.name}</div>

          <div className="modal-body-subtext">
            This requisition will remain on hold until you return it to active
            status. You can find all requisitions on hold from the requisition
            screen under the status of &quot;on hold&quot;. Below, please
            explain why this requisition should be placed on hold.
          </div>

          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container">
                <Controller
                  rules={{
                    required: 'This is a required field',
                  }}
                  // control={control}
                  name="onHoldComment"
                  render={({ field: { value, onChange, ref } }) => (
                    <TextInput
                      type="textarea"
                      label={
                        <>
                          Provide reason for hold
                          <RequiredFieldIndicator />
                        </>
                      }
                      data-testid="onHoldComment"
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      name="onHoldComment"
                      errorMessage={errors?.onHoldComment?.message}
                      characterLimit={500}
                      inputClass="comment-textarea"
                    />
                  )}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </div>
  );
};

PutOnHoldModal.propTypes = {
  requisition: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  'data-testid': PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
};

PutOnHoldModal.defaultProps = {
  isOpen: false,
  onSubmit: null,
  onCancel: null,
  isRequired: false,
  defaultValue: '',
  title: 'Add a new comment',
  body: 'Your comment will be added to this timeline and be viewable by involved parties.',
  maxLength: 500,
  'data-testid': null,
  inputProps: {},
};

export default PutOnHoldModal;
