import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';

import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_ZONES } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';

const placeholderOption = {
  value: '',
  label: '- Select -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const ZonesFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [
    fetchAgencyFilterOptions,
    { data: zoneOptions, loading: zoneOptionsLoading },
  ] = useLazyQuery(GET_ZONES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAgencyFilterOptions({
      variables: {
        operation: 'view',
        subject: StoreSubjects.Order,
      },
    });
  }, []);

  useEffect(() => {
    if (zoneOptions?.getZones) {
      const formattedZonesOptions = sortBy(
        zoneOptions.getZones,
        'id',
      ).map((zoneDetails) => ({
        value: zoneDetails.id,
        label: zoneDetails.name,
      }));
      setOptions(
        formattedZonesOptions
          ? [placeholderOption, ...formattedZonesOptions]
          : [placeholderOption],
      );
    }
  }, [zoneOptions]);

  if (zoneOptionsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

ZonesFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default ZonesFilterItem;