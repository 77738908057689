import ErrorActions from './ErrorActions';

const ErrorReducer = (state, action) => {
  switch (action.type) {
    case ErrorActions.ADD_ERROR:
      if (state[action.payload.page]) {
        if (
          state[action.payload.page][
            action.payload.form || action.payload.errorKey
          ]
        ) {
          return {
            ...state,
            [action.payload.page]: {
              ...state[action.payload.page],
              [action.payload.form || action.payload.errorKey]: new Set([
                ...state[action.payload.page][
                  action.payload.form || action.payload.errorKey
                ],
                action.payload.error,
              ]),
            },
          };
        }

        return {
          ...state,
          [action.payload.page]: {
            ...state[action.payload.page],
            [action.payload.form || action.payload.errorKey]: new Set([
              action.payload.error,
            ]),
          },
        };
      }
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          [action.payload.form || action.payload.errorKey]: new Set([
            action.payload.error,
          ]),
        },
      };

    case ErrorActions.REMOVE_ERROR: {
      const statePage =
        state[action.payload.page]?.[
          action.payload.form || action.payload.errorKey
        ] || [];
      const index = Array.from(statePage).indexOf(action.payload.error);
      const formErrors = Array.from(statePage);
      if (index > -1) {
        formErrors.splice(index, 1);
      }
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          [action.payload.form || action.payload.errorKey]: new Set(formErrors),
        },
      };
    }

    case ErrorActions.CLEAR_ERRORS:
      if (action.payload?.page) {
        if (action.payload.form || action.payload.errorKey) {
          return {
            ...state,
            [action.payload.page]: {
              ...state[action.payload.page],
              [action.payload.form || action.payload.errorKey]: new Set(),
            },
          };
        }
        return {
          ...state,
          [action.payload.page]: {},
        };
      }
      return {};

    default:
      return state;
  }
};

export default ErrorReducer;
