import React from 'react';

const commonErrorMessage =
  'Please address all fields outlined in red before proceeding to the next step.';

const VehicleRequisitionErrors = {
  'COLORS-SELECTION': commonErrorMessage,
  'COLORS-SECTION-COMMENTS': 'Please enter valid comments',
  ADDRESS: commonErrorMessage,
};

export const RequisitionBoacErrors = {
  heading:
    "We're sorry, there are issues tied to your BOAC within your Agency Information page",
  title: (
    <p>
      The following changes have occurred while this requisition was pending
      approval.
    </p>
  ),
  footer: (
    <p>
      Please resolve these issues within their <b>Agency Information</b> page
      and resubmit this requisition. Please return the requisition below.
    </p>
  ),
};

export default VehicleRequisitionErrors;
