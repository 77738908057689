import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  Link,
  Icon,
  Button,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import './home.scss';
import { getButtonListByPermission, linkButtons } from './utils/utils';

const HomePage = () => {
  const navigate = useNavigate();
  const ability = useAppAbility();
  useEffect(() => {
    document.title = 'AFP - Store landing';
  }, []);

  const handleRoute = (link) => {
    let pushLink = link;
    if (typeof link === 'function') {
      pushLink = link(ability);
    }
    if (pushLink === 'https://str.gsa.gov/') {
      window.open(pushLink, '_blank');
    } else {
      navigate(pushLink);
    }
  };
  const header = 'GSA Fleet Store';
  const offerButtons = getButtonListByPermission();

  const handleNav = (link) => {
    if (link === '/vehicle-availability') {
      handleRoute(link);
    } else {
      window.open(link, '_blank');
    }
  };

  return (
    <>
      <div
        className="app-home-header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs trail={[<Link to="/home">Home</Link>]} current="Store" />
      </div>
      <div className="header-container">
        <PageTitle title={header} />
        <span>
          We provide safe, reliable, low-cost vehicle solutions for federal
          agencies,
        </span>
        <a href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/eligibility-determinations">
          {' '}
          eligible entities
        </a>
        <span>&nbsp;and</span>
        <a
          className="pointer"
          href="https://www.gsa.gov/system/files/GSA%20Fleet%20FAQs%20for%20Tribes_March%2022%202023.pdf"
        >
          &nbsp;tribal nations
        </a>
        <span>
          . Our suite of offerings include vehicle purchasing, vehicle leasing
          and short-term rentals.
        </span>
      </div>
      <div className="app-home-root">
        <div className="left-container">
          <h2>What would you like to do today?</h2>
          <p>Select from one of the GSA Fleet Offerings to begin your task:</p>
          <div className="buttons-container">
            {offerButtons.map((button) => (
              <button
                data-testid={button.id}
                type="button"
                className="purchase-click-button"
                onClick={() => handleRoute(button.link)}
                disabled={!button.clickable}
              >
                <div className="button-titles">{button.title}</div>
                <span>{button.text}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="right-container">
          <div className="resource-container">
            <h3>Resources</h3>
            {linkButtons.map((link) =>
              link.title === 'Contact our Vehicle Buying Team' ? (
                <div className="custom-link">
                  <Icon className="icon" iconName="arrow_forward" />
                  <div className="link">
                    {' '}
                    <ContactBuyingPopover isLink />
                  </div>
                </div>
              ) : (
                <Button
                  className="custom-link"
                  onClick={() => handleNav(link.link)}
                  label={link.title}
                  type="button"
                  variant="unstyled"
                  leftIcon={{ name: 'arrow_forward' }}
                />
              ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
