import React from 'react';

const VehicleFilterContext = React.createContext({
  typeReset: false,
  vehicleSubtypes: [],
});

export const VehicleFilterProvider = VehicleFilterContext.Provider;

export default VehicleFilterContext;
