import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Alert } from '@gsa/afp-component-library';
import './RequisitionPrintPreviewModal.scss';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { AreqSections } from '../../context/VehicleRequisitionContext/VehicleRequisitionInitialState';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

export const RequisitionPrintPreviewModal = ({ handleClose, handlePrint }) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { areqPrintPreview, sopPrintPreview, isAreq } = state;

  const printPreview = isAreq ? areqPrintPreview : sopPrintPreview;

  const printPreviewSectionKeys = Object.keys(printPreview);

  const showError = useMemo(() => {
    const checkIfAllFalse = Object.values(printPreview).filter(
      (item) => item === true,
    );
    if (!checkIfAllFalse.length) {
      return true;
    }
    return false;
  }, [printPreview]);

  const handleCheckbox = (label) => {
    return (event) => {
      const { checked } = event.target;

      let newValues = {};
      if (label === AreqSections.AllSections) {
        newValues = Object.keys(printPreview).reduce(
          (accum, currKey) => ({ ...accum, [currKey]: checked }),
          {},
        );
      } else {
        newValues = { [label]: checked };
      }

      if (isAreq) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_AREQ_PRINT_PREVIEW,
          payload: newValues,
        });
      } else {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_SOP_PRINT_PREVIEW,
          payload: newValues,
        });
      }
    };
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleClose();
        handlePrint();
      }}
    >
      <Modal
        data-testid="print-requisition-modal"
        title={<div className="modal-title">Print requisition</div>}
        className="print-preview-modal"
        onClose={() => {
          handleClose();
        }}
        actions={
          <div className="areq-print-preview-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="save-draft-modal-cancel-button-delete-modal"
              onClick={() => handleClose()}
              label="Cancel"
            />
            <Button
              type="submit"
              leftIcon={{ name: 'print' }}
              variant="primary"
              label="Print requisition"
              disabled={showError}
            />
          </div>
        }
      >
        {showError && (
          <Alert type="error" className="show-error-alert">
            Please select at least one section to print
          </Alert>
        )}
        <div className="modal-instructions">
          Choose which sections of this summary you’d like to print
        </div>
        <Checkbox
          className="all-sections-checkbox print-preview-checkbox"
          name={`${AreqSections.AllSections}`}
          checked={Object.values(printPreview).every(Boolean)}
          onChange={handleCheckbox(AreqSections.AllSections)}
          label={`${AreqSections.AllSections}`}
        />

        {printPreviewSectionKeys.map((key) => (
          <Checkbox
            key={key}
            className="print-preview-checkbox"
            name={`${key}`}
            checked={printPreview[key]}
            onChange={handleCheckbox(key)}
            label={key}
          />
        ))}
      </Modal>
    </form>
  );
};

RequisitionPrintPreviewModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
};
