import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../services/data-layer';
import { getStateCodeOnly } from '../../../utilities/deliveryAddressUtils';
import {
  modifyAddressData,
  getCallingCode,
  formatPhoneNumber,
} from '../../../pages/ReviewDetails/RequisitionDetailsUtils';

import './AddressReview.scss';

const AddressReview = ({ title, addressData, displayPointOfContact }) => {
  const formattedAddressData = modifyAddressData(addressData);
  const phoneCountryCode = getCallingCode(formattedAddressData);
  const [displayCountry, displayCountryTo] = useState();

  const [getCountriesData, { data: fetchCountriesData }] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    displayCountryTo(
      fetchCountriesData?.getCatalogsForCategory?.find(
        (country) => country.code === addressData?.countryCode,
      )?.displayText,
    );
  }, [fetchCountriesData]);

  useEffect(() => {
    getCountriesData({ variables: { category: 'ISOCountryCode2' } });
  }, [addressData?.countryCode]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="tab"
      tabIndex="0"
      className="address-section"
      onClick={(e) => stopPropagation(e)}
    >
      <div className="address-review-section">
        <div className="address-title-section">
          <span>{title}</span>
        </div>

        <div className="address-review-first-title">
          {!formattedAddressData.isMilitary && (
            <div className="address-first-section">
              <div className="">{formattedAddressData.entityName}</div>
              <div className="">{formattedAddressData.addressLine1}</div>
              {formattedAddressData.addressLine2 && (
                <div className="">{formattedAddressData.addressLine2}</div>
              )}
              <div className="">
                {formattedAddressData.city},{' '}
                {getStateCodeOnly(formattedAddressData?.stateCode)}{' '}
                {formattedAddressData.zipcode}
              </div>
              {displayCountry && <div className="">{displayCountry}</div>}
              {formattedAddressData?.phone && (
                <div className="">{formattedAddressData?.phone}</div>
              )}
            </div>
          )}
          {formattedAddressData.isMilitary && (
            <div className="address-first-section">
              <div className="">
                {formattedAddressData?.rankAndFullName ||
                  formattedAddressData.entityName}
              </div>
              <div className="">
                {formattedAddressData.addressLine1 ||
                  formattedAddressData?.militaryOrDiplomaticAddress}
              </div>
              <div className="">
                {formattedAddressData.addressLine2 ||
                  formattedAddressData?.postOffice}
                , {formattedAddressData.zipcode}
              </div>
              {displayCountry && <div className="">{displayCountry}</div>}
              {formattedAddressData?.phone && (
                <div className="">{formattedAddressData?.phone}</div>
              )}
            </div>
          )}

          {displayPointOfContact && (
            <div className="address-review-second-title">
              <div>Point of contact</div>
              <div className="title-desc">
                {!formattedAddressData.isMilitary && (
                  <div>
                    {formattedAddressData.firstName}{' '}
                    {formattedAddressData.lastName}
                  </div>
                )}
                <div>{formattedAddressData.email}</div>
                <div>
                  {formatPhoneNumber(
                    phoneCountryCode,
                    formattedAddressData.phoneNumber,
                    formattedAddressData.phoneExtension,
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AddressReview.propTypes = {
  title: PropTypes.string.isRequired,
  addressData: PropTypes.instanceOf(Object).isRequired,
  displayPointOfContact: PropTypes.bool,
};

AddressReview.defaultProps = {
  displayPointOfContact: true,
};

export default AddressReview;
