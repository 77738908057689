import React from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const OrderMessageLinesForm = ({ messageLines }) => {
  const showFormField = (controlName) => {
    return (
      <Controller
        name={controlName}
        render={({ field: { value, onChange, ref } }) => (
          <TextInput
            characterLimit="35"
            type="textarea"
            onChange={onChange}
            defaultValue={messageLines[controlName] ?? null}
            value={value}
            inputRef={ref}
            name={`field_${controlName}`}
            inputClass="line-text-box"
          />
        )}
      />
    );
  };

  return (
    <>
      {showFormField('message1', 'Line 1')}
      {showFormField('message2', 'Line 2')}
      {showFormField('message3', 'Line 3')}
      {showFormField('message4', 'Line 4')}
      {showFormField('message5', 'Line 5')}
    </>
  );
};

OrderMessageLinesForm.propTypes = {
  messageLines: PropTypes.shape({
    message1: PropTypes.string,
    message2: PropTypes.string,
    message3: PropTypes.string,
    message4: PropTypes.string,
    message5: PropTypes.string,
  }),
};

OrderMessageLinesForm.defaultProps = {
  messageLines: {
    message1: null,
    message2: null,
    message3: null,
    message4: null,
    message5: null,
  },
};

export default OrderMessageLinesForm;
