import React from 'react';
import PropTypes from 'prop-types';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import './CustomerReviewAndConfirmPage.scss';

const CustomerReviewAndConfirmPage = ({
  vendorData,
  attachments,
  title = "Review customer's selected vendor",
  justification = '',
}) => {
  const priceFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getOnlyFundingAttachments =
    attachments?.getAllRequisitionAttachments?.filter(
      (li) => li.attachmentType.attachmentType === 'req_funding',
    );

  return (
    <div className="quote-content-container">
      {getOnlyFundingAttachments?.length > 0 && (
        <div className="co-final-review-title">{title}</div>
      )}
      <div className="quote-section-1">
        <div className="quote-section-row">
          <div className="row-column">
            <div className="title">Vendor</div>
            <div className="title-info">{vendorData?.vendorName}</div>
          </div>
          <div className="row-column">
            <div className="title">Make & model</div>
            <div className="title-info">
              {vendorData?.makeModel ||
                `${vendorData?.makeCode}/${vendorData?.modelCode}`}
            </div>
          </div>
          <div className="row-column">
            <div className="title">Price quote (with fees)</div>
            <div className="title-info">
              {priceFormat.format(vendorData?.totalPrice)}
            </div>
          </div>
        </div>
      </div>
      <div className="quote-section-2">
        <div className="quote-section-row">
          <div>
            <div className="title">Vendor Quote</div>
            <div className="title-desc">
              <AttachmentDownload
                name={vendorData?.quoteAttachment?.name}
                metadataId={vendorData?.quoteAttachment?.metadataId}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="quote-section-3">
        <div className="quote-section-row">
          <div>
            <div className="title">Contracting officer&apos;s comments</div>
            <div className="title-desc">
              {vendorData?.contractingOfficerComments}
            </div>
          </div>
        </div>
      </div>
      <div className="quote-section-4">
        <div className="title">Supporting documents</div>
        <div className="quote-doc-section">
          {vendorData?.supportingDocuments?.map((li) => {
            return (
              <div className="attachment-section">
                <AttachmentDownload
                  name={li?.requisitionAttachment?.name}
                  metadataId={li?.requisitionAttachment?.metadataId}
                />
                <div className="title-desc">
                  {li?.requisitionAttachment?.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {justification.length > 0 && (
        <div className="quote-section-4">
          <div className="title">Non low bid justification</div>
          <div className="title-desc">{justification}</div>
        </div>
      )}

      {getOnlyFundingAttachments?.map((li) => {
        return (
          <div className="quote-section-4">
            <div className="title">Funding Confirmation</div>
            <div className="title-desc">
              The customer has confirmed funding and provided documentation
              below.{' '}
            </div>
            <div className="quote-doc-section">
              <div className="attachment-section">
                <AttachmentDownload
                  name={li?.name}
                  metadataId={li?.metadataId}
                />
                <div className="title-desc">{li?.description}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CustomerReviewAndConfirmPage.propTypes = {
  vendorData: PropTypes.instanceOf(Object).isRequired,
  attachments: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  justification: PropTypes.string,
};

CustomerReviewAndConfirmPage.defaultProps = {
  attachments: { getAllRequisitionAttachments: [] },
  title: '',
  justification: '',
};

export default CustomerReviewAndConfirmPage;
