import React, { useEffect, useState, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Spinner,
  SelectDropdown,
  Breadcrumbs,
  RadioButton,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import ShipmentOptions from '../../purchase/widgets/ShippmentOptions';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from '../../../services/data-layer';
import { getUserScopeLevel } from '../../../services/auth-service';
import {
  canCreateRequisition,
  canViewRequisition,
} from '../../purchase/authorization';
import { StoreSubjects } from '../../../constants/constants';
import { NON_SOP_ORDERING_TYPES, REQUISITION_TYPE } from '../constants';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import UrgentRequisitionSteps from './UrgentRequisitionSteps';
import ContactBuyingPopover from '../../../components/ContactBuyingPopover/ContactBuyingPopover';
import SaveDraftRequisition from '../../../components/SaveDraftRequisition/SaveDraftRequisition';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { Purchase_Vehicles } from '../../constants';

const UrgentRequisitionProcess = () => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { draftRequisition } = state;
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownInitialStates = [
    {
      value: '',
      label: '-select-',
      defaultValue: true,
    },
  ];
  const [agencyCode, setAgencyCode] = useState('');
  const [bureauCode, setBureauCode] = useState('');
  const [officeCode, setOfficeCode] = useState('');
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [userScopeLevel, setUserScopeLevel] = useState('NONE');
  const [agencies, setAgencies] = useState(dropdownInitialStates);
  const [bureaus, setBureaus] = useState(dropdownInitialStates);
  const [offices, setOffices] = useState(dropdownInitialStates);
  const [isDraftSaved, setIsDraftSaved] = useState(false);
  const [processUrgReq, setProcessUrgReq] = useState(false);
  const ability = useAppAbility();

  const getCurrentUser = useCurrentUser();
  let currentUser = {};
  if (getCurrentUser) currentUser = getCurrentUser.currentUser;

  const canViewOrCreateRequisition = () => {
    return canViewRequisition(ability) || canCreateRequisition(ability);
  };

  useEffect(() => {
    if (draftRequisition?.requisitionId) {
      setIsDraftSaved(true);
      if (
        location.pathname === '/urgent-requisition' &&
        location.search !== `?requisitionId=${draftRequisition?.requisitionId}`
      ) {
        navigate(
          `/urgent-requisition?requisitionId=${draftRequisition?.requisitionId}`,
          { replace: true },
        );
      }
    } else {
      setIsDraftSaved(false);
      setProcessUrgReq(false);
    }
  }, [draftRequisition]);

  useEffect(() => {
    if (currentUser?.abilities) {
      const userScope = getUserScopeLevel(
        currentUser?.abilities,
        'create',
        StoreSubjects.Requisition,
      );
      setUserScopeLevel(userScope);
    }
  }, [currentUser?.abilities]);

  const [_getUserAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getAgenciesByPermission) {
          const formattedAgencies = sortBy(
            info.getAgenciesByPermission,
            'id',
          ).map(({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
            label,
            value,
            isDod: Boolean(isDodInt),
            oldAgencyCode,
          }));
          setAgencies([...dropdownInitialStates, ...formattedAgencies]);
        }
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [_getUserBureaus, { loading: getBureausLoading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getBureausByPermission) {
          const updateList = [...dropdownInitialStates];
          sortBy(info.getBureausByPermission, 'id').forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setBureaus(updateList);
        }
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [_getUserOffices, { loading: getOfficesLoading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownInitialStates];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setOffices(updateList);
        }
      },
      fetchPolicy: 'no-cache',
    },
  );

  const getUserAgencies = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserAgencies(args);
    }
  };

  const getUserBureaus = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserBureaus(args);
    }
  };

  const getUserOffices = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserOffices(args);
    }
  };

  useEffect(() => {
    const initialData = async () => {
      await getUserAgencies({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'view',
        },
      });
    };
    setTimeout(() => {
      dispatch({
        type: VehicleRequisitionContextActions.SET_REQUISITION_TYPE,
        payload: REQUISITION_TYPE.URGENT_REQUIREMENT,
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
        payload: {
          justification: '',
          certifyCheckBox: false,
          uploadedDocumentation: [],
        },
      });
    });
    initialData();
  }, []);

  const handleDropdownSelection = (id, dropdownType) => {
    if (dropdownType === 'agency') {
      setAgencyCode(id);
      setBureauCode('');
      setOfficeCode('');
      sessionStorage.setItem('agencyCode', id);
      sessionStorage.setItem('orderingBureau', '');
      sessionStorage.setItem('bureauCode', '');
      sessionStorage.setItem('orderingOffice', '');
      sessionStorage.setItem('officeCode', '');
      setBureaus(dropdownInitialStates);
      setOffices(dropdownInitialStates);

      if (id) {
        const {
          label: agencyLabel,
          isDod: isAgencyDod,
          oldAgencyCode,
        } = agencies.find(({ value }) => value === id);
        sessionStorage.setItem('orderingAgency', agencyLabel);
        sessionStorage.setItem('isAgencyDod', isAgencyDod);
        sessionStorage.setItem('oldAgencyCode', oldAgencyCode);
        getUserBureaus({
          variables: {
            agencyCode: id,
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
      }
    }

    if (dropdownType === 'bureau') {
      setBureauCode(id);
      setOfficeCode('');
      sessionStorage.setItem('bureauCode', id);
      sessionStorage.setItem('orderingOffice', '');
      sessionStorage.setItem('officeCode', '');
      setOffices(dropdownInitialStates);

      if (id) {
        const selectedBureauInfo = bureaus.find(
          (bureau) => bureau.value === id,
        );
        sessionStorage.setItem('orderingBureau', selectedBureauInfo.label);
        getUserOffices({
          variables: {
            agencyCode,
            bureauCode: id,
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_ENTITY_NAME,
      });
    }

    if (dropdownType === 'office') {
      setOfficeCode(id);
      sessionStorage.setItem('officeCode', id);
      if (id) {
        const selectedOfficeInfo = offices.find(
          (office) => office.value === id,
        );
        sessionStorage.setItem('orderingOffice', selectedOfficeInfo.label);
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_ENTITY_NAME,
      });
    }
  };

  const handleDraftSave = () => {
    setValidationTriggered(true);
    if (agencyCode && bureauCode) {
      setProcessUrgReq(true);
    }
  };

  const saveDraftSuccess = () => {
    return null;
  };

  const handleCancel = () => {
    setProcessUrgReq(false);
  };

  return (
    <div className="urg-req-organization-selection">
      <div className="bredcrumb-section">
        <Breadcrumbs
          trail={[
            <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
              Home
            </a>,
            <Link to="/Purchase">{Purchase_Vehicles}</Link>,
            <Link to="/non-standard-ordering">
              Non Standard Ordering Options
            </Link>,
          ]}
          current={isDraftSaved ? 'MyRequisition' : 'Urgent Requirement'}
        />
        <ContactBuyingPopover />
      </div>
      {processUrgReq && (
        <SaveDraftRequisition
          sin="210"
          onCancel={() => handleCancel()}
          saveDraftSuccess={saveDraftSuccess}
          forceSaveDraft
          setForceSaveDraft={setProcessUrgReq}
          isFromUrgReq
        />
      )}

      {isDraftSaved || (requisitionId && draftRequisition?.requisitionId) ? (
        <UrgentRequisitionSteps />
      ) : (
        <>
          <div className="req-association-selection">
            <h1>{NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT}</h1>
            <div>
              The Urgent Requirement Process orders vehicles for government
              agencies with urgent requirements. Agencies must justify the
              urgent and compelling reasons for using the Urgent Requirement
              Process instead of waiting for the normal procurement cycle and
              delivery time to take place.
              <br />
              <br />
              Prices will generally be higher than the established contract
              prices because the vehicles are purchased from a dealership and
              not directly from the manufacturer.
            </div>
            <h3 className="top-padding">
              Which organization should this order be associated with?
            </h3>
            <RadioButton
              id="orgOrdering"
              defaultChecked
              label="I'm ordering for my organization"
            />
            {(getAgenciesLoading || getBureausLoading || getOfficesLoading) && (
              <Spinner aria-busy="true" size="medium" />
            )}

            <SelectDropdown
              errorMessage={
                validationTriggered && agencyCode === ''
                  ? 'Please select an agency'
                  : ''
              }
              label="Agency"
              required
              id="purchaseAgency"
              name="select-agency"
              options={agencies}
              onChange={(e) =>
                handleDropdownSelection(e.target.value, 'agency')
              }
              value={agencyCode}
              data-testid="non-sop-select-agency"
            />

            <SelectDropdown
              errorMessage={
                validationTriggered && bureauCode === ''
                  ? 'Please select a bureau'
                  : ''
              }
              label="Bureau"
              required
              data-testid="non-sop-select-bureau"
              id="purchaseBureau"
              name="select-bureau"
              options={bureaus}
              onChange={(e) =>
                handleDropdownSelection(e.target.value, 'bureau')
              }
              value={bureauCode}
            />
            <SelectDropdown
              label="Office / Group"
              required={userScopeLevel === 'OFFICE'}
              data-testid="non-sop-select-office"
              id="purchaseOffice"
              name="select-office"
              options={offices}
              onChange={(e) =>
                handleDropdownSelection(e.target.value, 'office')
              }
              value={officeCode}
            />
          </div>
          <ShipmentOptions
            onShipmentSelection={() => {}}
            onFilterSubmit={handleDraftSave}
            requestType="urgentReq"
          />
        </>
      )}
    </div>
  );
};

const UrgentRequisition = () => {
  return <UrgentRequisitionProcess />;
};

export default UrgentRequisition;
