import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Spinner,
  useModal,
} from '@gsa/afp-component-library';
import './RequisitionsAndOrders.scss';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_ORDER_MOD_ATTACHMENTS } from '../../services/data-layer';
import UploadOrderModAttachmentModal from './UploadOrderModAttachmentModal';
import useBulkOrderModsState from './useBulkOrderModsState';
import { OrderModStatus } from './BulkOrderModTableHelper';
import OrderModAttachmentUpdateModal from './OrderModAttachmentUpdateModal';
import OrderModAttachmentDeleteModal from './OrderModAttachmentDeleteModal';
import AttachmentDownload from '../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const UploadOrderModAttachments = ({ attachmentTypes }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRefetched, setIsRefetched] = useState(false);
  const deleteFileModal = useModal();
  const editFileModal = useModal();
  const [sort, setSort] = useState([['document', 'ASC']]);
  const {
    modificationId,
    updatedDraft,
    orderModAttachments,
    setOrderModAttachments,
  } = useBulkOrderModsState();

  const { data, loadingOrderModAttachments, refetch } = useQuery(
    GET_ORDER_MOD_ATTACHMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orderModificationId: modificationId,
        type: '',
      },
    },
  );
  useEffect(() => {
    if (data && data?.getAllOrderModAttachments) {
      const orderModAttachmentsData = data?.getAllOrderModAttachments.filter(
        (attachment) => attachment.changeSetId === null,
      );
      setOrderModAttachments(orderModAttachmentsData);
    }
  }, [data, isRefetched]);

  const setTableSort = (newSort) => {
    setSort([newSort.split(' ')]);
  };
  const handleAttachmentUpload = () => {
    refetch({
      orderModificationId: modificationId,
      type: '',
    });
  };
  const handleSelectedRow = (event, row) => {
    console.log('row', row);
    if (event === 'Delete') {
      setSelectedFile(row.original);
      deleteFileModal.openModal();
    }
    if (event === 'Edit') {
      setSelectedFile(row.original);
      editFileModal.openModal();
    }
  };
  const actionList = [
    {
      icon: 'edit',
      label: 'Edit',
    },
    {
      icon: 'delete',
      label: 'Delete',
    },
  ];
  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.fileMetadata?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Document',
        accessor: 'document',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.attachmentType?.type;
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        cellClassName: 'attachment-note',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.description;
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,

        // eslint-disable-next-line react/prop-types
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={actionList}
              onSelectAction={(e) => {
                handleSelectedRow(e, row);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [attachmentTypes],
  );

  return (
    <>
      {updatedDraft?.orderModStatus === OrderModStatus.DRAFT_MOD && (
        <UploadOrderModAttachmentModal
          modificationId={modificationId}
          handleAttachmentUpload={handleAttachmentUpload}
          orderModAttachmentTypes={attachmentTypes}
        />
      )}
      {selectedFile && (
        <>
          <OrderModAttachmentDeleteModal
            isOpen={deleteFileModal.isOpen}
            handleClose={deleteFileModal.closeModal}
            fileToDelete={selectedFile}
            onCompleted={() => {
              refetch();
              setIsRefetched(true);
            }}
          />

          <OrderModAttachmentUpdateModal
            isOpen={editFileModal.isOpen}
            handleClose={editFileModal.closeModal}
            selectedAttachment={selectedFile}
            onCompleted={() => {
              refetch();
              setIsRefetched(true);
            }}
          />
        </>
      )}

      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={orderModAttachments || []}
        onSort={setTableSort}
        defaultSort={sort}
      />
      {loadingOrderModAttachments && <Spinner aria-busy="true" size="medium" />}
      {!loadingOrderModAttachments && !orderModAttachments?.length && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No attachments found for this order modification"
        />
      )}
    </>
  );
};

export default UploadOrderModAttachments;

UploadOrderModAttachments.propTypes = {
  attachmentTypes: PropTypes.instanceOf(Array).isRequired,
};
