import { useLazyQuery } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GET_ORDERS_FOR_USER,
  GET_ORDER_VEHICLES_FOR_USER,
} from '../../services/data-layer';

export const VehicleContext = createContext({});

export default function VehicleProvider({ children }) {
  const [orderRows, setOrderRows] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [allVehicleTypes] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDirection, setOrderDirection] = useState('DESC');
/*
  const [getOrders, { loading: onOrdersLoading }] = useLazyQuery(
    GET_ORDERS_FOR_USER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOrderRows(data?.getAllOrdersForUser?.rows || []);
        setOrderCount(data?.getAllOrdersForUser?.count || 0);
      },
    },
  );*/

  const [getVehicleOrders, { loading: onOrderVehiclesLoading }] = useLazyQuery(
    GET_ORDER_VEHICLES_FOR_USER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOrderRows(data?.getAllOrderVehicles?.rows || []);
        setOrderCount(data?.getAllOrderVehicles?.count || 0);
      },
    },
  );

  useEffect(() => {
    if (isReset) {
      setCurrentPage(1);
      setResetPagination(false);
    }
  }, [isReset]);

  const value = {
    orderRows,
    orderCount,
    allVehicleTypes,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    getVehicleOrders,
    onOrderVehiclesLoading,
  };

  return (
    <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>
  );
}

VehicleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
