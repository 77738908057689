import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { AddOptionsCard } from '../../../../components/AddOptions/AddOptions';
import VehiclesHeader from '../../../../components/VehiclesHeader/VehiclesHeader';
import {
  getDisplayableContracts,
  getDisplayableNetworkCostBreakDownData,
  getDisplayableOptionalData,
  getGroupedOptionalRequirementsData,
  getSimplifiedContractData,
} from '../../../../utilities/CompareVehicleUtils';
import BasePrice from '../../../../components/BasePrice/BasePrice';
import './SelectedOptions.scss';

const { appURLs } = window.AFP_CONFIG;

const SelectedOptions = ({
  activeContracts,
  requisitionCart,
  standardItemData,
  calculatedPriceData,
  optionalReqData,
  toggleViewSelectedOptions,
}) => {
  if (
    !calculatedPriceData ||
    !requisitionCart ||
    !optionalReqData ||
    !activeContracts ||
    !standardItemData
  ) {
    return null;
  }

  const {
    title: standardItemTitle,
    standardItemNumber,
    content: { content },
  } = standardItemData?.getStandardItem;

  const { quantity } = requisitionCart.requisitionCartPurchaseLine[0];
  const selectedOptionsOptionCodes =
    requisitionCart?.requisitionCartPurchaseLine[0]?.selectedOptions?.selectedOptions?.map(
      ({ optionCode }) => optionCode,
    ) || [];

  const lowestBidData = requisitionCart.clientState.lowestBidContract;
  const allContracts = getSimplifiedContractData(activeContracts);
  const displayableContracts = getDisplayableContracts(0, 5, allContracts);
  const allCostBreakdown =
    calculatedPriceData.calculatePurchaseReqPrice.modelCostBreakDown;
  const displayableCostBreakdown = getDisplayableNetworkCostBreakDownData(
    0,
    5,
    allCostBreakdown,
  );

  const { allAvailableOptionalReqData } = getGroupedOptionalRequirementsData(
    optionalReqData.getOptionalOptions,
    allContracts,
    sessionStorage.getItem('selectedState')
      ? sessionStorage.getItem('selectedState')
      : 'Export',
  );

  if (!allAvailableOptionalReqData) {
    return null;
  }

  const displayableOptionsData = getDisplayableOptionalData(
    0,
    5,
    allAvailableOptionalReqData,
  );

  const checkedDisplayableOptions = displayableOptionsData.reduce(
    (accum, curr) => {
      const { options, ...rest } = curr;
      const selectedOptions = [...options].filter(({ optionCode }) =>
        selectedOptionsOptionCodes.includes(optionCode),
      );

      // Category has no selected options - skip it
      if (selectedOptions.length === 0) {
        return accum;
      }

      return accum.concat({ ...rest, options: [...selectedOptions] });
    },
    [],
  );

  const standardItemSubCategoryCode =
    standardItemData?.getStandardItem?.vehicleTypeCode?.code;
  const svgCode = standardItemSubCategoryCode.concat('_black');
  const vehicleBodyIcon = `${appURLs.cdnUrl}/icons/sin-sub-category/${svgCode}.svg`;

  return (
    <>
      <h1 className="selected-options-page-title">Selected Options</h1>

      <div className="store-compare-vehicles-sub-header">
        <img
          data-testid="store-compare-sub-header-icon"
          className="store-compare-vehicles-sub-header-image"
          src={vehicleBodyIcon}
          alt="vehicle icon"
        />
        <h2 data-testid="selected-options-vehicles-sub-header-heading">
          STD Item: {standardItemNumber} - {standardItemTitle}
        </h2>
      </div>
      <div className="subheader-description">{content}</div>

      <Button
        onClick={toggleViewSelectedOptions}
        type="button"
        variant="outline"
        // className="margin-bottom-1"
        data-testid="return to review summary"
        aria-label="Return to review summary"
        leftIcon={{ name: 'arrow_back' }}
        label="Return to review summary"
        size="small"
      />

      <div style={{ display: 'flex' }}>
        <VehiclesHeader
          readOnly
          setQuantityHasError={() => {}}
          quantity={quantity}
          displayingContractsData={displayableContracts}
          lowestBidData={lowestBidData}
        />
      </div>

      <BasePrice
        contracts={displayableContracts}
        lowestBidData={lowestBidData}
        networkCostBreakDownData={displayableCostBreakdown}
      />

      <div className="selected-options-card">
        <AddOptionsCard
          lowestBidData={lowestBidData}
          addOptionsData={[...checkedDisplayableOptions]}
          displaySelectedOptions
        />
      </div>

      <Button
        onClick={toggleViewSelectedOptions}
        type="button"
        variant="outline"
        // className="margin-bottom-1"
        data-testid="return to review summary"
        aria-label="Return to review summary"
        leftIcon={{ name: 'arrow_back' }}
        size="small"
        label="Return to review summary"
      />
    </>
  );
};

SelectedOptions.propTypes = {
  activeContracts: PropTypes.instanceOf(Object).isRequired,
  requisitionCart: PropTypes.instanceOf(Object).isRequired,
  standardItemData: PropTypes.instanceOf(Object).isRequired,
  calculatedPriceData: PropTypes.instanceOf(Object).isRequired,
  optionalReqData: PropTypes.instanceOf(Object).isRequired,
  toggleViewSelectedOptions: PropTypes.func.isRequired,
};

export default SelectedOptions;
