import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button, Alert } from '@gsa/afp-component-library';

export default function EngineeringReviewWithInputRequiredModal({
  confirmContinueAction,
  closeModalFn,
}) {
  const EngineeringReviewActions = (
    <>
      <Button
        type="button"
        variant="unstyled"
        label="Cancel"
        onClick={closeModalFn}
      />
      <Button
        type="button"
        variant="primary"
        label="Yes"
        onClick={() => confirmContinueAction()}
      />
    </>
  );
  return (
    <div
      role="dialog"
      aria-modal="true"
      className="afp-modal-overlay modalContainer low-bid-justification"
    >
      <Modal
        variant="large"
        actions={EngineeringReviewActions}
        onClose={closeModalFn}
        showAlert
        alert={
          <Alert type="info" slim>
            You have selected options that require engineering review upon
            submission and you will be asked to provide supporting
            documentation. Please be detailed in your submission and assume the
            individual reviewing it has zero context to your request. Incomplete
            or insufficient documentation will result in delayed ordering.
          </Alert>
        }
      >
        <p>Do you still wish to proceed with your selection?</p>
      </Modal>
    </div>
  );
}

EngineeringReviewWithInputRequiredModal.propTypes = {
  confirmContinueAction: propTypes.func.isRequired,
  closeModalFn: propTypes.func.isRequired,
};
