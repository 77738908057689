import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown } from '@gsa/afp-component-library';
import OverlaySpinner from './overlay-spinner';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';

const dropdownInitialStates = (label) => [
  {
    value: '',
    label,
    defaultValue: true,
  },
];

const dropdownEmptyAgency = () => dropdownInitialStates('-Select agency-');
const dropdownEmptyBureau = () => dropdownInitialStates('-Select bureau-');
const dropdownEmptyOffice = () => dropdownInitialStates('-Select office-');

const AgencyScope = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [agencies, setAgencies] = useState(dropdownEmptyAgency());
  const [bureaus, setBureaus] = useState(dropdownEmptyBureau());
  const [offices, setOffices] = useState(dropdownEmptyOffice());

  const [_getUserAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getAgenciesByPermission) {
          const formattedAgencies = sortBy(
            info.getAgenciesByPermission,
            'id',
          ).map(({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
            label,
            value,
            isDod: Boolean(isDodInt),
            oldAgencyCode,
          }));
          setAgencies([...dropdownEmptyAgency(), ...formattedAgencies]);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'c',
    },
  );
  const [_getUserBureaus, { loading: getBureausLoading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getBureausByPermission) {
          const updateList = [...dropdownEmptyBureau()];
          sortBy(info.getBureausByPermission, 'id').forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setBureaus(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    },
  );
  const [_getUserOffices, { loading: getOfficesLoading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownEmptyOffice()];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setOffices(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'network-only',
    },
  );

  const getUserOffices = async (args) => {
    await _getUserOffices(args);
  };

  const getUserBureaus = async (args) => {
    await _getUserBureaus(args);
  };

  const getUserAgencies = async (args) => {
    await _getUserAgencies(args);
  };

  const handleAgencyChange = (agencyCode) => {
    setBureaus(dropdownEmptyBureau());
    setOffices(dropdownEmptyOffice());
    getUserBureaus({
      variables: {
        agencyCode,
        subject: StoreSubjects.Order,
        operation: 'view',
      },
    });
  };

  const handleBureauChange = (id) => {
    setOffices(dropdownEmptyOffice());
    if (id.length) {
      const values = getValues();
      getUserOffices({
        variables: {
          agencyCode: values.agencyCode,
          bureauCode: id,
          subject: StoreSubjects.Order,
          operation: 'view',
        },
      });
    }
  };

  useEffect(() => {
    const initialData = async () => {
      await getUserAgencies({
        variables: {
          subject: StoreSubjects.Order,
          operation: 'view',
        },
      });
    };
    initialData();
  }, []);

  return (
    <>
      {(getAgenciesLoading || getBureausLoading || getOfficesLoading) && (
        <OverlaySpinner />
      )}
      <div className="grid-row">
        <div className="grid-col-6">
          <header className="display-flex-column flex-align-start flex-justify flex-direction-column">
            <h2 className="margin-0">Agency selection</h2>
            <p className="margin-0">
              Records in the report will only include those that match the scope
              selected below.
            </p>
          </header>
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-5">
          <Controller
            name="agencyCode"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { name, onBlur, onChange, value, ref } }) => {
              return (
                <>
                  <SelectDropdown
                    label="Agency"
                    required
                    id="agencyCode"
                    name={name}
                    onBlur={onBlur}
                    options={agencies}
                    onChange={(e) => {
                      onChange(e);
                      handleAgencyChange(e.target.value);
                    }}
                    inputRef={ref}
                    value={value}
                    errorMessage={
                      errors?.agencyCode?.type === 'required'
                        ? 'This is a required field'
                        : ''
                    }
                    data-testid="select-agency"
                  />
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-5">
          <Controller
            name="bureauCode"
            control={control}
            defaultValue=""
            render={({ field: { name, onBlur, onChange, value, ref } }) => {
              return (
                <>
                  <SelectDropdown
                    label="Bureau"
                    id="bureauCode"
                    name={name}
                    onBlur={onBlur}
                    options={bureaus}
                    onChange={(e) => {
                      onChange(e);
                      handleBureauChange(e.target.value);
                    }}
                    inputRef={ref}
                    value={value}
                    data-testid="select-bureau"
                  />
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="grid-row margin-1px">
        <div className="grid-col-5">
          <Controller
            name="officeCode"
            control={control}
            defaultValue=""
            render={({ field: { name, onBlur, onChange, value, ref } }) => {
              return (
                <>
                  <SelectDropdown
                    label="Office"
                    id="officeCode"
                    name={name}
                    onBlur={onBlur}
                    options={offices}
                    onChange={onChange}
                    inputRef={ref}
                    value={value}
                    data-testid="select-office"
                  />
                </>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AgencyScope;
