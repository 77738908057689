import { REQUISITION_TYPE } from '../../non-standard-purchase/constants';

export const getBoacVar = (signalCode, supplementaryAddress, boac) =>
  ['A', 'J'].includes(signalCode) ? boac : supplementaryAddress;

export const getOrderRequisitionLink = (orderData) => {
  if (!orderData) return '';

  const { requisitionId, requisitionType, transactionType } = orderData;
  if (!requisitionId) return '';
  let hrefValue = `/requisition-details?requisitionId=${requisitionId}`;

  if (transactionType === 'VEHICLE_LEASE') {
    hrefValue = `/lease/${requisitionId}`;
  } else {
    if (requisitionType === REQUISITION_TYPE.URGENT_REQUIREMENT) {
      hrefValue = `/urgent-requisition?requisitionId=${requisitionId}`;
    } else if (requisitionType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) {
      hrefValue = `/mas-requisition?requisitionId=${requisitionId}`;
    }
  }
  return hrefValue;
};
