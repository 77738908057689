export const OrderModStatus = {
  DRAFT_MOD: 'DRAFT_MOD',
  ATTACH_TO_MOD: 'ATTACH_TO_MOD',
  CANCELLED_MOD: 'CANCELLED_MOD',
};

const FormatOrderModStatusName = (value) => {
  let orderModStatusName;
  switch (value) {
    case OrderModStatus.DRAFT_MOD:
      orderModStatusName = 'Draft Mod';
      break;
    case OrderModStatus.ATTACH_TO_MOD:
      orderModStatusName = 'Attach to Mod';
      break;
    case OrderModStatus.CANCELLED_MOD:
      orderModStatusName = 'Cancelled Mod';
      break;
    default:
      orderModStatusName = 'Unknown';
      break;
  }
  return orderModStatusName;
};

export { OrderModStatus as default, FormatOrderModStatusName };
