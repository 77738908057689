import React from 'react';
import PropTypes from 'prop-types';
import './Clarifications.scss';

const Clarifications = ({ clarifications, title }) => {
  const clarificationsExist = clarifications && clarifications.length > 0;

  if (!clarificationsExist) return null;

  return (
    <>
      <div className="clarification-title">
        <strong>{title}</strong>
      </div>
      <ul>
        {clarifications.map(({ clarification }) => (
          <li>{clarification}</li>
        ))}
      </ul>
    </>
  );
};

Clarifications.propTypes = {
  clarifications: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
};

Clarifications.defaultProps = {
  clarifications: [],
};

export default Clarifications;
