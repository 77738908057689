/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
export const OrderModStatus = {
  DRAFT_MOD: 'DRAFT_MOD',
  ATTACH_TO_MOD: 'ATTACH_TO_MOD',
  CANCELLED_MOD: 'CANCELLED_MOD',
};

export const ChangeSetType = {
  MODIFY: 'MODIFY',
  TERMINATE: 'TERMINATE',
  CANCELLED_MOD: 'CANCELLED_MOD',
};
export const OrderModStatusType = {
  [OrderModStatus.DRAFT_MOD]: {
    color: 'Warning-Gray',
    label: 'Draft mod',
  },
  [OrderModStatus.ATTACH_TO_MOD]: {
    color: 'Ready-Gray',
    label: 'Attach to mod',
  },
  [OrderModStatus.CANCELLED_MOD]: {
    color: 'Urgent-Gray',
    label: 'Cancelled mod',
  },
};

export const getTableColumns = (row) => {
  return {
    orderId: row.orderId,
    caseNumber: row.caseNumber,
    standardItemId: row.standardItemId,
    modificationStatus: row.modificationStatus,
    status: row.orderStatus,
    quantity: row.quantity,
    unitPriceToCustomer: row.unitPriceToCustomer,
    scheduleLine: row.scheduleLine,
    orderVehicles: row.orderVehicles,
    requisitionNumber: row.requisitionNumber,
    contractLineId: row.contractLineId,
    requisitionType: row.requisitionType,
    requisitionerAddress: row.requisitionerAddress,
    requisitionerContact: row.requisitionerContact,
    mailingAddress: row.mailingAddress,
    mailingContact: row.mailingContact,
    deliveryAddress: row.deliveryAddress,
    deliveryContact: row.deliveryContact,
  };
};
