import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import useMyRequisitionsState from '../useMyRequisitionsState';

const ConfirmationMessages = () => {
  const {
    updatedRequisition,
    showCancelRequisitionSuccessMessage,
    showRenameRequisitionSuccessMessage,
  } = useMyRequisitionsState();

  return (
    <>
      {showCancelRequisitionSuccessMessage && (
        <Alert type="success" slim>
          <span data-testid="cancel-draft-success-message">
            You have successfully cancelled{' '}
            <span className="alert-req-name"> {updatedRequisition.name}</span>
          </span>
        </Alert>
      )}
      {showRenameRequisitionSuccessMessage && (
        <Alert type="success" slim>
          <span data-testid="rename-draft-success-message">
            You have successfully renamed{' '}
            <span className="alert-req-name"> {updatedRequisition.name}</span>
          </span>
        </Alert>
      )}
    </>
  );
};

export default ConfirmationMessages;
