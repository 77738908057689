import React from 'react';
import { ChangeSetType } from '../../requisitions-and-orders/BulkOrderModTableHelper';

export const STATUS = {
  MODIFY: {
    LABEL: 'MODIFICATION',
    COLOR: 'Ready-Dark',
  },
  TERMINATE: {
    LABEL: 'TERMINATION',
    COLOR: 'Urgent-Dark',
  },
};

export const getTableColumns = (row) => {
  return {
    orderChangeSetModId: row.orderChangeSetModId,
    index: row.index,
    orderCount: row?.changeSetOrders?.length,
    type: row.type,
    additionalComments: row.additionalComments,
    changeSetOrders: row?.changeSetOrders,
    modifications: row?.modifications,
  };
};
// eslint-disable-next-line react/prop-types
export const ChangeSetSubComponent = ({ row: { original } }) => {
  // eslint-disable-next-line react/prop-types
  const { type, changeSetOrders } = original;
  // eslint-disable-next-line react/prop-types
  const orderCount = changeSetOrders?.length;
  const renderOrders = () => {
    // eslint-disable-next-line react/prop-types
    return changeSetOrders.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="change-set-order-list-item">
        {item?.order?.caseNumber}
      </div>
    ));
  };
  return (
    <>
      <div className="change-set-sub-header">
        {type === ChangeSetType.MODIFY && (
          <span className="change-set-sub-header-modify">
            {' '}
            Modifying {orderCount} orders{' '}
          </span>
        )}
        {type === ChangeSetType.TERMINATE && (
          <span className="change-set-sub-header-terminate">
            {' '}
            Terminating {orderCount} orders{' '}
          </span>
        )}
      </div>
      <div className="sub-component">
        <div className="change-set-table-order-list">{renderOrders()}</div>
      </div>
    </>
  );
};

export const ORDER_MOD_ATTACHMENT_TYPES = {
  SF30: 'SF30',
  OPTIONS_DOC: 'OPTIONS_DOC',
  VENDOR_ACCEPTANCE: 'VENDOR_ACCEPTANCE',
  PAINT_AND_GRAPHICS: 'PAINT_AND_GRAPHICS',
  AREQ: 'AREQ',
};
export const sampleData = {
  quantityChangePerOrder: 10,
  priceChangePerOrder: 100,
  surcharge: 5,
  inspectionPoint: 'Point A',
  fob: 'FOB Point B',
  originCity: 'City X',
  originState: 'State Y',
  buyerCode: 'Buyer123',
  shipmentDate: new Date('2022-01-01'),
  lineColors: [{ color: 'Red' }, { color: 'Blue' }],
  selectedOptions: [
    {
      isChecked: true,
      optionCode: 'Option 1',
      optionDescription: 'Option Description 1',
      currentUnitPrice: '500',
      optionQuantity: 1,
      optionTotalPrice: 500,
      action: 'add',
    },
    {
      isChecked: false,
      optionCode: 'Option 2',
      optionDescription: 'Option Description 2',
      currentUnitPrice: '700',
      optionQuantity: 1,
      optionTotalPrice: 500,
      action: 'remove',
    },
  ],
  additionalRequirements: {
    requirement1: 'Requirement 1',
    requirement2: 'Requirement 2',
  },
  requisitionAddress: {
    addressLine1: '123 Main St',
    city: 'City X',
    state: 'State Y',
  },
  mailingAddress: {
    addressLine1: '456 Elm St',
    city: 'City Z',
    state: 'State W',
  },
  deliveryAddress: {
    addressLine1: '789 Oak St',
    city: 'City A',
    state: 'State B',
  },
  contact: {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
  },
};
