import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import './funding-modal.scss';

export default function FundingModal({
  handleClose,
  isInsufficientAFV,
  remainingAFVFunding,
}) {
  return (
    <div className="afp-modal-overlay modalContainer conflict-modal">
      <Modal
        className=""
        onClose={handleClose}
        actions={
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="button"
              variant="unstyled"
              onClick={handleClose}
              label="Cancel"
            />
          </div>
        }
        title={
          isInsufficientAFV ? (
            <div className="funding-modal-title">Insufficient funding</div>
          ) : (
            <div className="funding-modal-title">Funding is not available</div>
          )
        }
      >
        <div className="funding-modal-desc">
          {isInsufficientAFV ? (
            <span className="funding-modal-desc-text">
              The required AFV funding amount is not available. The selected
              customer has ${remainingAFVFunding} of AFV funding remaining.
              Please modify your vehicle selection, or contact your Zonal
              Acquisition Coordinator for additional information.
            </span>
          ) : (
            <span>
              The required funding amount is not available. Please coordinate
              with your Zonal Acquisition Coordinator and the Ordering
              Management Branch to obtain additional information.
            </span>
          )}
        </div>
      </Modal>
    </div>
  );
}

FundingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
