import { RequisitionStatus } from "../ReviewDetails/RequisitionDetailsUtils";
import { RequisitionViewMode } from "./leasing-consts";

// Option Data for review details until conflicts implemented
export const selectedPVOptions  = (selectedOptions, selectedContract) => {
    return selectedOptions.map((option) => {
        const vendorData = option?.vendorValues?.find( vendor => vendor?.contractLineId === selectedContract?.contractLineId);
        if(vendorData) {
            return {
                ...option,
                optionQuantityRequired: option?.quantityRequiredCode,
                unitPrice: vendorData?.unitPrice ?? 0,
                quantity: option?.optionValue,
                optionType: vendorData?.unitPrice ? 'COST' : 'N/A',
                optionTotalPrice: parseFloat(vendorData?.unitPrice ?? 0) * parseInt(option?.optionValue ?? 1),
            }
        }else {
            return option;
        }
    });
}