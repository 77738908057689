import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import './VehicleListingsPage.scss';
import { useLazyQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import {
  placeholderOption,
  vehicleSubTypeFilterKey,
  vehicleTypeFilterKey,
} from './filter-item-constants';
import VehicleSubtypeFilterItem from './VehicleSubtypeFilterItem';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../services/data-layer/standards';
import { VEHICLE_GROUP_TAG_STORE } from '../../constants/constants';

const { FilterPanel: ComponentFilterPanel } = FilterPanel;

const Filters = ({
  resetButton,
  initVehicleType,
  vehicleTypeSelect,
  vehicleSubtypeSelect,
}) => {
  const [initQueryComplete, initQueryCompleteTo] = useState(false);
  const [vehicleTypeOptions, vehicleTypeOptionsTo] = useState([
    placeholderOption,
  ]);
  const [selectedVehicleType, selectedVehicleTypeTo] = useState('');
  const [selectedVehicleSubtype, selectedVehicleSubtypeTo] = useState('');

  const [getVehicleGroups, { data: vehicleTypes }] = useLazyQuery(
    GET_ACTIVE_VEHICLE_GROUPS,
    {
      fetchPolicy: 'c',
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    },
  );

  useEffect(() => {
    getVehicleGroups();
  }, []);

  useEffect(() => {
    if (vehicleTypes?.getActiveVehicleGroups?.length) {
      const items = orderBy(
        vehicleTypes?.getActiveVehicleGroups?.map((x) => ({
          label: x.title,
          value: x.code,
          key: x.code,
        })),
        ['label'],
      );
      vehicleTypeOptionsTo([...[placeholderOption], ...items]);
      initQueryCompleteTo(true);
    }
  }, [vehicleTypes]);

  const handleClearAll = () => {
    if (typeof resetButton === 'function') {
      resetButton();
    }
  };

  const handleFiltersChange = async (filters) => {
    const vehicleTypeFilter = filters.find(
      (filter) => filter.key === vehicleTypeFilterKey,
    );
    const vehicleType = vehicleTypeFilter?.value || '';
    const vehicleSubTypeFilter = filters.find(
      (filter) => filter.key === vehicleSubTypeFilterKey,
    );
    const vehicleSubType = vehicleSubTypeFilter?.value || '';

    if (!vehicleTypeFilter) {
      selectedVehicleTypeTo('');
      selectedVehicleSubtypeTo('');
      vehicleTypeSelect('');
    } else if (vehicleType !== selectedVehicleType) {
      selectedVehicleTypeTo(vehicleType);
      selectedVehicleSubtypeTo('');
      vehicleTypeSelect(vehicleType);
    } else if (vehicleSubType !== selectedVehicleSubtype) {
      selectedVehicleSubtypeTo(vehicleSubType);
      vehicleSubtypeSelect(vehicleSubType);
    }
  };

  return (
    <div data-testid="filters-test" className="table-filters-container">
      {initQueryComplete && (
        <ComponentFilterPanel
          clearButtonLabel="Reset All"
          key="filter-panel"
          filterStructure={[
            {
              key: vehicleTypeFilterKey,
              title: 'Vehicle Type',
              type: 'select',
              value: initVehicleType,
              options: vehicleTypeOptions,
              operator: '$exact',
              permanent: false,
              expanded: true,
            },
            {
              key: vehicleSubTypeFilterKey,
              title: 'Vehicle Sub-type',
              type: 'select',
              value: '',
              component: VehicleSubtypeFilterItem,
              operator: '$in',
              permanent: false,
              expanded: true,
            },
          ]}
          order={[]}
          setQueryFiltersState={handleFiltersChange}
          handleClearAll={handleClearAll}
        />
      )}
    </div>
  );
};

Filters.propTypes = {
  resetButton: PropTypes.func.isRequired,
  initVehicleType: PropTypes.string.isRequired,
  vehicleTypeSelect: PropTypes.func.isRequired,
  vehicleSubtypeSelect: PropTypes.func.isRequired,
};

Filters.defaultProps = {};

export default Filters;
