import React from 'react';
import DateRange from './DateRange';
import OrderStatus from './OrderStatus';
import TransactionType from './TransactionType';
import { useStoreReportsContext } from '../useStoreReportsContext';
import AgencyScope from './AgencyScope';
import RequisitionType from './RequisitionType';
import RequisitionStatus from './RequisitionStatus';

const ReportFilters = () => {
  const { isVendor, isInternalUser, isRequisitionReport, isOrderReport } =
    useStoreReportsContext();

  function canFilterByAgencyScope() {
    return !isVendor();
  }

  function canFilterByRequisitionTypes() {
    return isRequisitionReport() || isInternalUser();
  }

  function canFilterByRequisitionStatus() {
    return isRequisitionReport() && !isVendor();
  }

  function canFilterByOrderStatus() {
    return isOrderReport();
  }

  // TODO display TransactionType for all user types after implement Leasing
  return (
    <>
      {canFilterByAgencyScope() && <AgencyScope />}
      <DateRange />
      {isInternalUser() && <TransactionType />}
      {canFilterByRequisitionTypes() && <RequisitionType />}
      {canFilterByRequisitionStatus() && <RequisitionStatus />}
      {canFilterByOrderStatus() && <OrderStatus />}
    </>
  );
};

export default ReportFilters;
