import React from 'react';
import PropTypes from 'prop-types';
import ChangeSetOrders from './ChangeSetOrders';
import OrderChanges from './OrderChanges';
import SelectedOptions from './SelectedOptions';
import PaintAndGraphics from './PaintAndGraphics';
import OptionDetails from './OptionDetails';
import AreqDetails from './AreqDetails';
import DeliveryInformation from './DeliveryInformation';
import AgencyInformation from './AgencyInformation';
import VendorAcceptance from './VendorAcceptance';
import { ORDER_MOD_ATTACHMENT_TYPES } from './OrderModDetailsHelper';
import { ChangeSetType } from '../../requisitions-and-orders/BulkOrderModTableHelper';
import OrderModMessageLinesDisplay from './OrderModMessageLinesDisplay';

const OrderModChangeSetDetails = ({ changeSetData, attachments }) => {
  const changeSetType = changeSetData?.type;

  // const { changeSetData: changeSetDetails } = changeSetData ?? {};
  // const { attachments: changeSetAttachments } = attachments ?? {};
  const changeSetAttachments = attachments.filter(
    (attachment) =>
      attachment.changeSetId === changeSetData?.orderChangeSetModId,
  );
  const sf30Attachments = [];
  const optionsDocAttachments = [];
  const vendorAcceptanceAttachments = [];
  const paintAndGraphicsAttachments = [];
  const areqAttachments = [];
  if (changeSetAttachments.length > 0) {
    changeSetAttachments.forEach((attachment) => {
      // eslint-disable-next-line default-case
      switch (attachment.attachmentType.type) {
        case ORDER_MOD_ATTACHMENT_TYPES.SF30:
          sf30Attachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.OPTIONS_DOC:
          optionsDocAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.VENDOR_ACCEPTANCE:
          vendorAcceptanceAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.PAINT_AND_GRAPHICS:
          paintAndGraphicsAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.AREQ:
          areqAttachments.push(attachment);
          break;
      }
    });
  }
  const deliveryInfo = {
    deliveryAddress: changeSetData?.modifications?.deliveryAddress,
    mailingAddress: changeSetData?.modifications?.mailingAddress,
    requisitionAddress: changeSetData?.modifications?.requisitionAddress,
    deliveryPoc: changeSetData?.modifications?.deliveryContact,
    requisitionPoc: changeSetData?.modifications?.requisitionContact,
    mailingPoc: changeSetData?.modifications?.mailingContact,
  };

  return (
    <>
      <div>
        <h2>Change set {changeSetData.index + 1}:</h2>
      </div>
      <div className="accordion-group-custom">
        <ChangeSetOrders selectedOrders={changeSetData?.changeSetOrders} />
      </div>
      {changeSetType === ChangeSetType.TERMINATE && (
        <div className="terminate-order-container">
          <h3> {changeSetData?.changeSetOrders?.length} order(s) terminated</h3>
        </div>
      )}
      {changeSetType === ChangeSetType.MODIFY && (
        <div>
          <div className="change-set-modifications-section">
            <h2>Modifications</h2>
          </div>
          <div className="update-order-mod">
            <div className="accordion-group-custom">
              <OrderChanges modifications={changeSetData?.modifications} />
              <SelectedOptions
                selectedOptions={
                  changeSetData?.modifications?.selectedOptions || []
                }
              />
              <PaintAndGraphics
                paintAndGraphicsOptions={
                  changeSetData?.modifications?.lineColors || {}
                }
                attachments={paintAndGraphicsAttachments}
              />
              <OptionDetails optionDetails={optionsDocAttachments} />
              <AreqDetails areqDetails={areqAttachments} />
              <DeliveryInformation deliveryInfo={deliveryInfo} />
              <AgencyInformation
                agencyInfo={changeSetData?.modifications?.agencyContact || {}}
              />
              <VendorAcceptance
                vendorAcceptance={vendorAcceptanceAttachments}
              />
            </div>
          </div>
        </div>
      )}
      <div className="change-set-modifications-section">
        <h2>Additional comments</h2>
        <p>{changeSetData?.additionalComments}</p>
      </div>

      <OrderModMessageLinesDisplay
        messageLines={changeSetData?.modifications}
      />
    </>
  );
};
OrderModChangeSetDetails.propTypes = {
  changeSetData: PropTypes.objectOf(Object).isRequired,
  attachments: PropTypes.objectOf(Object).isRequired,
};
export default OrderModChangeSetDetails;
