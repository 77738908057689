import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useStoreReportsContext } from '../useStoreReportsContext';

export const PreferredMethodFieldName = 'vehicleOwnership';

const ReportsSelection = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    getStoreReports,
    storeReports,
    setReportName,
    setReportSubject,
    setSearchMethod,
  } = useStoreReportsContext();

  useEffect(() => {
    getStoreReports();
  }, []);

  function handlerChange(evt) {
    const i = storeReports.findIndex(
      (r) => r.id === parseInt(evt.target.value, 10),
    );
    const name = i < 0 ? '' : storeReports[i].name;
    const subject = i < 0 ? '' : storeReports[i].subject;
    setReportName(name);
    setReportSubject(subject);
    setSearchMethod('');
  }

  return (
    <Controller
      name="reportType"
      control={control}
      defaultValue=""
      rules={{ required: 'This is a required field' }}
      render={({ field: { name, onBlur, onChange, value } }) => {
        return (
          <SelectDropdown
            label="Choose from available reports"
            errorMessage={errors?.reportType?.message}
            onBlur={onBlur}
            onChange={(evt) => {
              onChange(evt);
              handlerChange(evt);
            }}
            required
            value={value}
            name={name}
            id="reportType"
            options={[{ id: '', name: '-Select-' }, ...storeReports]}
            optionLabel="name"
            optionValue="id"
            optionKey="id"
            data-testid="store-report-type"
          />
        );
      }}
    />
  );
};

export default ReportsSelection;
