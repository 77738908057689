import { get, each } from 'lodash';
import {hasNonEmptyData} from '../../../utilities/commonUtils';
import {ChangeSetType} from '../BulkOrderModProvider';

// Validate Price Change
const validatePriceChange = (changeSet) => {
  const message = {
    isValid: true,
    message: '',
  };
  if (changeSet.modifications.orderChangeType === 'price') {
    const { priceChangePerOrder } = changeSet.modifications;
    each(changeSet.changeSetOrders, (order) => {
      const unitPriceToCustomer = get(order, 'order.unitPriceToCustomer');
      if (
        parseFloat(unitPriceToCustomer, 10) +
          parseFloat(priceChangePerOrder, 10) <
        0
      ) {
        message.isValid = false;
        message.message = `Price change per unit in changeSet ${changeSet.changeSetIndex} is invalid`;
      }
    });
  }
  return message;
};

// Validate Quantity Change
const validateQuantityChange = (changeSet) => {
  const message = {
    isValid: true,
    message: '',
  };
  if (changeSet.modifications.orderChangeType === 'quantity') {
    const { quantityChangePerOrder } = changeSet.modifications;
    if (!quantityChangePerOrder || parseInt(quantityChangePerOrder, 10) > 0) {
      message.isValid = false;
      message.message = `Quantity change per order in changeSet ${changeSet.changeSetIndex} is invalid`;
    }
  }
  return message;
};

// Validate additional comments
const validateAdditionalComments = (changeSet) => {
  const message = {
    isValid: true,
    message: '',
  };
  if (!changeSet.additionalComments) {
    message.isValid = false;
    message.message = `Additional comments are required in changeSet ${changeSet.changeSetIndex}`;
  }
  return message;
};

export const validateChangeSet = (changeSet) => {
  const errors = {
    isValid: true,
    messages: [],
  };

  const isChangeSetHasModifications = hasNonEmptyData(changeSet.modifications) &&
      changeSet.type === ChangeSetType.MODIFY;
  if(!isChangeSetHasModifications) {
    errors.isValid = false;
    errors.messages.push(`No modifications are applied in ChangeSet ${changeSet.changeSetIndex} `);
   }
  if(changeSet.changeSetOrders?.length === 0){
    errors.isValid = false;
    errors.messages.push(`No orders have been selected in ChangeSet ${changeSet.changeSetIndex}`);
  }
  const priceChangeValidation = validatePriceChange(changeSet);
  if (!priceChangeValidation.isValid) {
    errors.isValid = false;
    errors.messages.push(priceChangeValidation.message);
  }

  const quantityChangeValidation = validateQuantityChange(changeSet);
  if (!quantityChangeValidation.isValid) {
    errors.isValid = false;
    errors.messages.push(quantityChangeValidation.message);
  }

  const additionalCommentsValidation = validateAdditionalComments(changeSet);
  if (!additionalCommentsValidation.isValid) {
    errors.isValid = false;
    errors.messages.push(additionalCommentsValidation.message);
  }

  return errors;
};

export const validateChangeSets = (changeSets) => {
  const errors = {
    isValid: true,
    messages: [],
  };
  changeSets.forEach((changeSet, index) => {
      if(changeSet.type !== 'TERMINATE') {
          const changeSetToValidate = {
              ...changeSet,
              changeSetIndex: (index + 1)
          }
          const changeSetErrors = validateChangeSet(changeSetToValidate);
          if (!changeSetErrors.isValid) {
              errors.isValid = false;
              errors.messages.push(changeSetErrors.messages);
          }
      }
  });
  return errors;
};

export default validateChangeSets;
