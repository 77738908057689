import React from 'react';
import { atom, useRecoilState } from 'recoil';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';

export const systemAlertAtom = atom({
  key: 'SystemAlert',
  default: {
    show: false,
    focused: true,
    showClose: false,
    type: 'danger',
    heading: 'Something went wrong',
    message: (
      <>
        There was a system error. Please try again later. If you continue to
        experience technical difficulties with this page,{' '}
        <FleetTechnicalSupportContact />
      </>
    ),
    errorHint: null,
  },
});

export function useSystemAlert() {
  const [systemAlert, setSystemAlertState] = useRecoilState(systemAlertAtom);

  const setSystemAlert = ({
    type,
    heading,
    message,
    errorHint,
    focused,
    showClose,
  }) => {
    let errorHintMessage = null;
    if (Array.isArray(errorHint)) {
      errorHintMessage = (
        <ul>
          {errorHint.map((hint) => (
            <li key={hint}>{hint}</li>
          ))}
        </ul>
      );
    } else if (errorHint) {
      errorHintMessage = (
        <ul>
          <li>{errorHint}</li>
        </ul>
      );
    }

    setSystemAlertState({
      show: true,
      focused: focused || systemAlert.focused,
      showClose: showClose || systemAlert.showClose,
      type: type || systemAlert.type,
      heading: heading || systemAlert.heading,
      message: message || systemAlert.message,
      errorHint: errorHintMessage,
    });
  };

  const clearSystemAlert = () => {
    setSystemAlertState({
      show: false,
      type: systemAlert.type,
      heading: systemAlert.heading,
      message: systemAlert.message,
      errorHint: null,
    });
  };

  return {
    systemAlert,
    setSystemAlert,
    clearSystemAlert,
  };
}
