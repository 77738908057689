import { useContext } from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import VehicleRequisitionContext from '../context/VehicleRequisitionContext/VehicleRequisitionContext';

export const useDraftRequisitionEdit = () => {
  const { state } = useContext(VehicleRequisitionContext) || {};

  const { createdByUser } = state?.draftRequisition || {};
  const {
    currentUser: { id: currentUserId },
  } = useCurrentUser();

  let canEdit = true;
  if (createdByUser) {
    canEdit = createdByUser === currentUserId;
  }

  return {
    canEdit,
  };
};

export default useDraftRequisitionEdit;
