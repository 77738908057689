import { getCurrentFiscalYear } from '../../../utils/VehicleRequisitionUtils';

let validatedBoacResponse = null;

export default async function validateBOAC({
  endpoint,
  boac,
  agencyCode,
  bureauCode,
}) {
  if (!boac || boac.length !== 6) return undefined;

  try {
    const { data, error } = await endpoint({
      variables: {
        boac,
        agencyCode,
        bureauCode,
        fiscalYear: getCurrentFiscalYear(),
        salesGroup: 'TM12',
      },
    });

    if (error) return null;
    validatedBoacResponse = data?.validateBOAC;
  } catch (error) {
    validatedBoacResponse = null;
  }

  return validatedBoacResponse;
}
