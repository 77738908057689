import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  adaptKeys,
  addressesMatch,
  getStateCodeOnly,
} from '../../../../utilities/deliveryAddressUtils';

const UspsAddressedComp = ({
  title,
  addressAsEntered,
  uspsVerifiedAddress,
  handleAddressedChange,
  isSameAsRequisition,
}) => {
  useEffect(() => {
    handleAddressedChange('USPS', addressAsEntered.id);
  }, []);

  const addressHasBeenValidated = () =>
    addressesMatch(adaptKeys(uspsVerifiedAddress), addressAsEntered);

  if (isSameAsRequisition || addressHasBeenValidated()) {
    return null;
  }
  return (
    <>
      <div>
        <div className="address-main-section">
          <div className="address-title">{title}</div>
        </div>
        <div
          className={`address-as-entered ${
            !uspsVerifiedAddress.isChecked && 'slected-adress-highlight'
          }`}
        >
          <div className="heading-section">
            <input
              data-testid={`verify-radio-button-1-${title}`}
              type="radio"
              aria-label={`entered ${title}`}
              className="usps-verified-checkbox"
              name={title}
              onClick={() => {
                handleAddressedChange('ENTERED', addressAsEntered.id);
              }}
              onKeyDown={() => {
                handleAddressedChange('ENTERED', addressAsEntered.id);
              }}
            />
            <span className="title-section"> Use address as was entered:</span>
          </div>

          <div>
            {addressAsEntered && (
              <>
                <div className="address-info-section">
                  {addressAsEntered.addressLine1}
                  <div>
                    {addressAsEntered.city} ,
                    {getStateCodeOnly(addressAsEntered.stateCode)} ,
                    {addressAsEntered.zipcode}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`usps-address ${
            uspsVerifiedAddress.isChecked && 'slected-adress-highlight'
          }`}
        >
          <div className="heading-section">
            <input
              data-testid={`verify-radio-button-2-${title}`}
              type="radio"
              aria-label={`usps verified ${title}`}
              className="usps-verified-checkbox"
              name={title}
              onChange={() => {
                handleAddressedChange('USPS', addressAsEntered.id);
              }}
              onKeyDown={() => {
                handleAddressedChange('USPS', addressAsEntered.id);
              }}
              checked={uspsVerifiedAddress.isChecked}
            />
            <span className="title-section">
              {' '}
              Use the USPS verified address:{' '}
            </span>
          </div>
          <div>
            {uspsVerifiedAddress && (
              <>
                <div className="address-info-section">
                  {uspsVerifiedAddress.address}
                  <div>
                    {uspsVerifiedAddress.city} ,{uspsVerifiedAddress.state} ,
                    {uspsVerifiedAddress.zip}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

UspsAddressedComp.defaultProps = {
  isSameAsRequisition: false,
};

UspsAddressedComp.propTypes = {
  title: PropTypes.string,
  addressAsEntered: PropTypes.instanceOf(Object),
  uspsVerifiedAddress: PropTypes.instanceOf(Object),
  handleAddressedChange: PropTypes.func.isRequired,
  isSameAsRequisition: PropTypes.bool,
};

UspsAddressedComp.defaultProps = {
  title: '',
  addressAsEntered: {},
  uspsVerifiedAddress: {},
};

export default UspsAddressedComp;
