import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Spinner } from '@gsa/afp-component-library';
import { SUBMIT_ORDER_MOD } from '../../services/data-layer';
import useBulkOrderModsState from './useBulkOrderModsState';

const SubmitOrderModModal = ({ onClose }) => {
  const {
    changeSets,
    updatedDraft,
    additionalComment,
    submitComment,
    setSubmitOrderModHasError,
    setSubmitOrderModErrorMessage,
  } = useBulkOrderModsState();

  const formatChangeSets = () => {
    return changeSets?.map((changeSet) => {
      return {
        changeSetId: changeSet?.orderChangeSetModId,
        type: 'MODIFY',
        modificationData: changeSet?.modifications,
        orderIds: changeSet?.changeSetOrders?.map((order) => order.orderId),
      };
    });
  };

  const navigate = useNavigate();
  const [submitOrderModMutation, { loading: loadingSubmitOrderMod }] = useMutation(SUBMIT_ORDER_MOD, {
    onError: (error) => {
      console.log(error);
      setSubmitOrderModErrorMessage('An error occurred while submitting the modification.');
      setSubmitOrderModHasError(true);
      onClose();
    },
    onCompleted: ({ submitOrderModification }) => {
      let orderModErrorMessage = '';

      const modResponses =
        submitOrderModification?.processOrderModificationResponses;
      if (modResponses?.length > 0) {
        modResponses.forEach((response) => {
          if (response.hasError) {
            orderModErrorMessage = response.orderModificationErrorLog[0].error;
          }
        });
      }

      setSubmitOrderModErrorMessage(orderModErrorMessage);
      setSubmitOrderModHasError(orderModErrorMessage !== '');
      onClose();

      if (orderModErrorMessage === '') {
        navigate(
          `/order-modifications?modName=${updatedDraft?.orderModName}&modStatus=success`,
        );
      }
    },
  });

  const onSubmit = async () => {
    await submitOrderModMutation({
      variables: {
        updatedOrderModificationRequest: {
          modificationId: updatedDraft.orderModificationId,
          additionalComments: additionalComment,
          submissionComments: submitComment,
          changeSets: formatChangeSets(),
        },
      },
    });
  };

  return (
    <Modal
      title={<h1 className="modal-title">Confirm submission</h1>}
      className="save-draft-modal"
      onClose={onClose}
      actions={
        <div className="save-draft-requisition-button-row">
          <Button
            type="button"
            variant="unstyled"
            className="save-draft-requisition-action-button"
            data-testid="order-mod-modal-cancel-button"
            onClick={onClose}
            label="Cancel"
          />
          <Button
            type="button"
            variant="default"
            className="save-draft-requisition-action-button"
            data-testid="order-mod-submit-modal-button"
            onClick={() => onSubmit()}
            disabled={loadingSubmitOrderMod}
            leftIcon={{ name: 'check' }}
            label="Submit modification"
          />
          {loadingSubmitOrderMod && (
            <div
              style={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Spinner size="small" />
            </div>
          )}
        </div>
      }
    >
      <div className="title-desc">
        Are you sure you want to submit this modification? Once submitted, it
        will be processed in the evening cycle and any changes will need to be
        in a new modification.
      </div>
    </Modal>
  );
};

SubmitOrderModModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SubmitOrderModModal;
