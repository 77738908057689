import React, { useState } from 'react';
import {
  MultiSelect,
  Checkbox,
  Label,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useFormContext, Controller } from 'react-hook-form';
import { allOrderStatuses } from '../constants';

const OrderStatus = () => {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const selectedOrderStatuses = watch('orderStatuses');
  const [selectAllOrderStatuses, setSelectAllOrderStatuses] = useState(false);
  const handleSelectAll = (value, checked) => {
    if (checked) {
      setSelectAllOrderStatuses(true);
      setValue(
        'orderStatuses',
        allOrderStatuses.map((v) => v.value),
      );
    } else {
      setValue('orderStatuses', []);
      setSelectAllOrderStatuses(false);
    }
  };

  const handleSelect = (value, checked) => {
    if (checked) {
      setValue('orderStatuses', selectedOrderStatuses.concat(value));
    } else {
      setSelectAllOrderStatuses(false);
      setValue(
        'orderStatuses',
        selectedOrderStatuses.filter((v) => v !== value),
      );
    }
  };
  return (
    <>
      <div className="grid-row margin-1px">
        <div className="grid-col-6">
          <header className="display-flex-column flex-align-start flex-justify margin-top-2 flex-direction-column">
            <h2 className="margin-0 margin-top-5">Order status</h2>
            <p className="margin-0">
              Choose the order statuses you would like to include in your
              report.
            </p>
          </header>
        </div>
      </div>
      <div className="grid-row margin-1px">
        <div className="grid-col-5">
          <Label required htmlFor="order_statuses">
            Order status
          </Label>
          <div className="usa-hint">Select all that apply</div>
          {errors?.orderStatuses?.type === 'required' && (
            <ErrorMessage id="order-statuses-error">
              {errors?.orderStatuses && 'This is a required field'}
            </ErrorMessage>
          )}
          <Checkbox
            id="all-order-statuses"
            data-testid="all-order-statuses-checkbox"
            label="All order statuses"
            name="All order statuses"
            disabled={false}
            onChange={({ target: { value, checked } }) =>
              handleSelectAll(value, checked)
            }
            onBlur={() => {
              trigger('orderStatuses');
            }}
            checked={selectAllOrderStatuses}
          />
          <Controller
            name="orderStatuses"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { name, value, onBlur } }) => {
              return (
                <MultiSelect
                  id="order_statuses"
                  name={name}
                  required
                  onChange={(a, checked) => handleSelect(a, checked)}
                  options={allOrderStatuses}
                  selectedValues={value}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OrderStatus;
