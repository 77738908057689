import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { FilterTableFrame } from '@gsa/afp-component-library';
import MyRequisitionsProvider from './MyRequisitionsProvider';
import MyRequisitionsFilters from './Filters/RequisitionsFilters';
import MyRequisitionsTable from './RequisitionsTable';
import './MyRequisitions.scss';
import ConfirmationMessages from './ConfirmationMessages/ConfirmationMessages';

const MyRequisitions = ({ setTab }) => {
  setTab('requisitions');
  useTitle('Requisitions and Orders-Requisitions');

  const MyRequisitionsFrame = useMemo(
    () =>
      FilterTableFrame(null, null, MyRequisitionsFilters, MyRequisitionsTable),
    [],
  );

  return (
    <MyRequisitionsProvider>
      <div className="my-requisitions">
        <h2 className="usa-h2">Requisitions</h2>
        <ConfirmationMessages />
        <MyRequisitionsFrame filterToggle />
      </div>
    </MyRequisitionsProvider>
  );
};

MyRequisitions.propTypes = {
  setTab: PropTypes.func.isRequired,
};

export default memo(MyRequisitions);
