import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  TextInput,
  ErrorMessage,
  Spinner,
} from '@gsa/afp-component-library';
import axios from 'axios';
import './UploadNewFileModal.scss';
import { useMutation } from '@apollo/client';
import useBulkOrderModsState from '../../useBulkOrderModsState';
import { FETCH_SIGNED_URL_FOR_ORDER_MODS } from '../../../../services/data-layer';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
} from '../../../non-standard-purchase/constants';

const toBeRounded = (bytes) => {
  const converted = bytes / (1024 * 1024);
  const size = converted.toFixed(2);
  return Number(size);
};

const UploadNewFileModal = ({
  handleClose,
  append,
  acceptableFiles,
  acceptableFilesLabel,
  reqType,
}) => {
  const { modificationId } = useBulkOrderModsState();
  const { returnSignedUrl } = useBulkOrderModsState();
  const [returnOrderModSignedUrl] = useMutation(
    FETCH_SIGNED_URL_FOR_ORDER_MODS,
  );
  const [fileAddErrors, setFileAddErrors] = useState(null);
  const [saving, setSaving] = useState(false);

  const [values, setValues] = useState({
    isInternalAttachment: false,
    fileType: -1,
    file: null,
    description: '',
  });

  const updateValue = (e, type) => {
    if (type === 'fileType' && fileAddErrors?.groupAssignment) {
      setFileAddErrors({
        ...fileAddErrors,
        groupAssignment: null,
      });
    }
    const updatedValues = { ...values };
    updatedValues[type] = e.target.value;
    setValues(updatedValues);
  };

  const handleFileChange = (e) => {
    if (fileAddErrors?.file) {
      setFileAddErrors({
        ...fileAddErrors,
        file: null,
      });
    }
    setValues({ ...values, file: e });
  };

  const handleSubmitAttachmentForm = async () => {
    setSaving(true);
    const { appURLs } = window.AFP_CONFIG;

    try {
      setFileAddErrors(null);

      let errorList = {};

      if (!values.file) {
        errorList = {
          ...errorList,
          file: 'Please add a Supporting file for upload',
        };
      }

      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
      }
      let returnSignedUrlData;

      if (
        [
          'PAINT_AND_GRAPHICS',
          'AREQ',
          'VENDOR_ACCEPTANCE',
          'OPTIONS_DOC',
        ].includes(reqType)
      ) {
        returnSignedUrlData = await returnOrderModSignedUrl({
          variables: {
            input: {
              orderModificationId: modificationId,
              name: values?.file?.name,
              status: 1,
              type: reqType,
              docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
            },
          },
        });
        await axios.put(
          returnSignedUrlData?.data?.generateSignedUrlForOrderMods?.signedUrl,
          values.file,
          {
            headers: {
              'Content-Type': values.file.type,
            },
          },
        );
      } else {
        const data = {
          variables: {
            input: {
              // requisitionId: draftRequisition?.requisitionId || draftId,
              name: values?.file?.name,
              status: 0,
              attachmentTypeId: values?.file?.type,
              docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
            },
          },
        };

        returnSignedUrlData = await returnSignedUrl(data);
        await axios.put(
          returnSignedUrlData?.data?.generateSignedUrlForOrderMods?.signedUrl,
          values?.file,
          {
            headers: {
              'Content-Type': values?.file.type,
            },
          },
        );
      }

      setFileAddErrors(null);
      append({
        description: values?.description,
        name: values?.file?.name,
        signedUrl:
          returnSignedUrlData?.data?.generateSignedUrlForOrderMods?.signedUrl,
        metadataId:
          returnSignedUrlData?.data?.generateSignedUrlForOrderMods?.metadataId,
        attachmentTypeId: values?.fileType,
      });
      handleClose();
    } catch (e) {
      console.log('ERROR', e);
      throw new Error(e);
    } finally {
      setSaving(false);
    }
  };

  if (saving) {
    return <Spinner size="large" className="margin-y-8" />;
  }

  return (
    <div className="upload-new-additional-req-file">
      <Modal
        title="Upload new file"
        onClose={handleClose}
        className="upload-attachment-modal"
        variant="large"
        actions={
          <div>
            <Button
              type="button"
              variant="unstyled"
              // className="modal-cancel-button"
              data-testid="order-attachment-upload-cancel-button"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              // className="modal-close-button"
              disabled={!values?.file?.name && values?.fileType}
              data-testid="order-attachment-upload-submit-button"
              onClick={handleSubmitAttachmentForm}
              label="Save"
            />
          </div>
        }
      >
        <FileUpload
          acceptableFiles={acceptableFiles || ACCEPT_FILE_TYPES}
          data-testid="order-attachment-upload-file"
          acceptableFilesLabel={acceptableFilesLabel || ACCEPT_FILE_LABELS}
          onChange={handleFileChange}
          fileSizeLimit={50}
        />
        {fileAddErrors?.file && (
          <ErrorMessage id="file-error">{fileAddErrors?.file}</ErrorMessage>
        )}
        <Alert type="warning" className="alert-msg">
          Help prevent a privacy incident by ensuring that any supporting
          document uploaded here does not contain personally identifiable
          information (PII).
        </Alert>
        {reqType !== 'URGENT_REQ_VENDOR_QUOTES' && (
          <>
            <div className="note">Note</div>
            <TextInput
              type="textarea"
              aria-label="note textarea"
              value={values.description}
              onChange={(e) => updateValue(e, 'description')}
              data-testid="additional-req-attachment-upload-description"
              help="A few words to help identify this document"
              characterLimit={100}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default UploadNewFileModal;

UploadNewFileModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  acceptableFiles: PropTypes.instanceOf(Object),
  acceptableFilesLabel: PropTypes.string,
  reqType: PropTypes.string,
};

UploadNewFileModal.defaultProps = {
  acceptableFiles: null,
  acceptableFilesLabel: null,
  reqType: null,
};
