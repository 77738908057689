import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { VehicleStatus } from '../constants/vehicle-constants';

const cellStatus = (value) => {
  let vehicleStatus;
  // eslint-disable-next-line default-case
  switch (value) {
    case VehicleStatus.CANCELED:
      vehicleStatus = {
        label: 'Cancelled',
        status: 'Inactive-Gray',
      };
      break;
    case VehicleStatus.ORDERED:
      vehicleStatus = {
        label: 'Ordered',
        status: 'Ready-Gray',
      };
      break;
    case VehicleStatus.SP:
      vehicleStatus = {
        label: 'Ordered',
        status: 'Ready-Gray',
      };
      break;
    case VehicleStatus.DD:
      vehicleStatus = {
        label: 'Delivered',
        status: 'Inactive-Gray',
      };
      break;
    case VehicleStatus.DELIVERED:
      vehicleStatus = {
        label: 'Delivered',
        status: 'Inactive-Gray',
      };
      break;
    case VehicleStatus.SHIPPED:
      vehicleStatus = {
        label: 'Shipped',
        status: 'Info-Gray',
      };
      break;
    case VehicleStatus.CC:
      vehicleStatus = {
        label: 'Shipped',
        status: 'Info-Gray',
      };
      break;
    case VehicleStatus.IN_PRODUCTION:
      vehicleStatus = {
        label: 'In production',
        status: 'Info-Gray',
      };
      break;
    case VehicleStatus.IN:
      vehicleStatus = {
        label: 'In production',
        status: 'Info-Gray',
      };
      break;
    case VehicleStatus.AR:
      vehicleStatus = {
        label: 'Agency received',
        status: 'Ready-Gray',
      };
      break;
    default:
      vehicleStatus = {
        label: 'N/A',
        status: 'Ready-Gray',
      };
  }

  return vehicleStatus;
};

const VehicleStatusTag = ({ value }) => {
  const { status, label } = cellStatus(value);
  return (
    <StatusBadge data-testid="order-vehicle-status-tag" variant={status}>
      {label}
    </StatusBadge>
  );
};

VehicleStatusTag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default VehicleStatusTag;
