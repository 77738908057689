import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Label,
  RadioButton,
  Fieldset,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { REQUISITION_TYPE } from '../../non-standard-purchase/constants';

export const PreferredMethodFieldName = 'requisitionType';

const RequisitionType = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="grid-row">
        <div className="grid-col-6">
          <header className="display-flex-column flex-align-start flex-justify flex-direction-column">
            <h2 className="margin-0 margin-top-5">Requisition types</h2>
            <p className="margin-0">
              Choose the types of requisitions you would like to include in your
              report.
            </p>
          </header>
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-5">
          <Fieldset name={PreferredMethodFieldName}>
            <Label
              required
              className="text-bold"
              htmlFor="all-requisition-type-radio"
            >
              Requisition type
            </Label>
            {errors?.requisitionType && (
              <ErrorMessage id="vehicle-ownership-error">
                {errors?.requisitionType.type === 'required' &&
                  'This is a required field'}
              </ErrorMessage>
            )}
            <Controller
              name={PreferredMethodFieldName}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <>
                    <RadioButton
                      id="all-requisition-type-radio"
                      data-testid="all-requisition-type-radio-btn"
                      name={name}
                      label="All requisitions"
                      value="ALL"
                      checked={value === 'ALL'}
                      onChange={onChange}
                      {...(!value && { required: true })}
                    />
                    <RadioButton
                      id="standard-requisition-type-radio"
                      data-testid="standard-requisition-type-radio-btn"
                      name={name}
                      label="Standard order"
                      value={`${REQUISITION_TYPE.STANDARD_ORDER}`}
                      checked={value === `${REQUISITION_TYPE.STANDARD_ORDER}`}
                      onChange={onChange}
                    />
                    <RadioButton
                      id="areq-requisition-type-radio"
                      data-testid="areq-requisition-type-radio-btn"
                      name={name}
                      label="Additional requirements (AREQ)"
                      value={`${REQUISITION_TYPE.AREQ}`}
                      checked={value === `${REQUISITION_TYPE.AREQ}`}
                      onChange={onChange}
                    />
                    <RadioButton
                      id="msa-requisition-type-radio"
                      data-testid="msa-requisition-type-radio-btn"
                      name={name}
                      label="Multiple Award Schedule (MAS)"
                      value={`${REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES}`}
                      checked={
                        value === `${REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES}`
                      }
                      onChange={onChange}
                    />
                    <RadioButton
                      id="ns-requisition-type-radio"
                      data-testid="ns-requisition-type-radio-btn"
                      name={name}
                      label="Non-standard vehicle"
                      value={`${REQUISITION_TYPE.NON_STANDARD_VEHICLE}`}
                      checked={
                        value === `${REQUISITION_TYPE.NON_STANDARD_VEHICLE}`
                      }
                      onChange={onChange}
                      {...(!value && { required: true })}
                    />
                    <RadioButton
                      id="ur-requisition-type-radio"
                      data-testid="ur-requisition-type-radio-btn"
                      name={name}
                      label="Urgent requirement"
                      value={`${REQUISITION_TYPE.URGENT_REQUIREMENT}`}
                      checked={
                        value === `${REQUISITION_TYPE.URGENT_REQUIREMENT}`
                      }
                      onChange={onChange}
                    />
                  </>
                );
              }}
            />
          </Fieldset>
        </div>
      </div>
    </>
  );
};

export default RequisitionType;
