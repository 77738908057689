import {
  AFPTable,
  Button,
  Checkbox,
  EmptyState,
  StatusBadge,
  Tag,
} from '@gsa/afp-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { OrderModStatusType } from '../../order-modifications/OrderModTableHelpers';
import OrderModAttachments from './OrderModAttachments';
import OrderModChangeSetDetails from './OrderModChangeSetDetails';
import {
  ChangeSetSubComponent,
  getTableColumns,
  STATUS,
} from './OrderModDetailsHelper';
import { GET_ORDER_MOD_ATTACHMENTS } from '../../../services/data-layer';

export const OrderModDetailsView = {
  ORDER_MOD_DETAILS: 1,
  CHANGE_SET_DETAILS: 2,
};
const OrderModDetails = ({ orderModDetails }) => {
  const orderModData = orderModDetails;
  const changeSets = orderModData?.changeSets;
  const [changeSetData, setChangeSetData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [attachments, setAttachments] = useState([]);
  const [sort, setSort] = useState([['document', 'ASC']]);

  const changeSetOrders = useMemo(
    () =>
      changeSets?.map((changeSet, index) => {
        return { ...changeSet, index };
      }),
    [changeSets],
  );
  const data = useMemo(() => {
    if (!changeSetOrders || !changeSetOrders.length) {
      return [];
    }
    return changeSetOrders?.map((row) => getTableColumns(row));
  }, [changeSetOrders]);
  const setTableSort = (newSort) => {
    setSort([newSort.split(' ')]);
  };

  const { color, label } = OrderModStatusType[orderModData?.orderModStatus] || {
    color: 'Warning-Gray',
    label: `INVALID STATUS (${orderModData?.orderModStatus})`,
  };
  const onClickViewChangeSetDetails = (original) => {
    console.log('original', original);
    setChangeSetData(original);
    setCurrentStep(2);
  };
  const [getAllAttachments] = useLazyQuery(GET_ORDER_MOD_ATTACHMENTS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    (async () => {
      const allAttachments = await getAllAttachments({
        variables: {
          orderModificationId: orderModData?.orderModificationId,
          type: '',
        },
      });

      setAttachments(allAttachments?.data?.getAllOrderModAttachments);
    })();
  }, [orderModData]);

  const CHANGE_SET_COLUMNS = useMemo(
    () => [
      {
        Header: 'Change set',
        accessor: 'index',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            <span className="change-set-name">change set {value + 1}</span>
          );
        },
      },
      {
        Header: 'Number of orders',
        accessor: 'orderCount',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            // eslint-disable-next-line react/prop-types
            value || (
              <span className="change-set-no-items">no orders selected</span>
            )
          );
        },
      },
      {
        Header: 'Modification',
        accessor: 'type',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            <Tag variant={STATUS[value]?.COLOR}>
              {value ? (
                STATUS[value].LABEL
              ) : (
                <span className="change-set-no-items">(no mods applied)</span>
              )}
            </Tag>
          );
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Button
                label="View Details"
                variant="outline"
                onClick={() => {
                  onClickViewChangeSetDetails(original);
                }}
                leftIcon={{
                  name: 'view',
                  type: 'button',
                }}
              />
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      {currentStep === OrderModDetailsView.ORDER_MOD_DETAILS &&
        orderModData?.orderModName && (
          <div className="order-mod-detials-container">
            <div className="create-order-mod-title">
              <div className="order-mod-title-section">
                <span className="order-mod-title">
                  {orderModData?.orderModName}
                </span>
                <span className="status-badge">
                  <StatusBadge variant={color}>{label}</StatusBadge>
                </span>
              </div>
            </div>
            <div className="review-modifications-section-container">
              <h2 data-testid="review-mod-title">Review modifications</h2>
              <AFPTable
                testId="change-set-detail-table"
                columns={CHANGE_SET_COLUMNS}
                data={data || []}
                onSort={setTableSort}
                defaultSort={sort}
                expandable
                renderRowSubComponent={ChangeSetSubComponent}
              />
              {!data.length && (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No change sets found for this order modification"
                />
              )}
            </div>
            <div className="review-modifications-section-container">
              <h2 data-testid="review-mod-ack">
                Modification request acknowledgement and signatures
              </h2>
              <OrderModAttachments attachments={attachments} />
              <Checkbox
                className="ack-checkbox"
                data-testid="ack-alert-checkbox"
                checked
                disabled
                label="I hereby certify that I have uploaded all documents required for this order modification."
              />
            </div>
            <div className="review-modifications-section-container">
              <h2 data-testid="review-mod-submitted">Modification submitted</h2>
              <h4>Modifier&apos;s comments</h4>
              {/*
            <div className="order-mod-submit-checklist-container">
*/}
              <p>{orderModData?.submissionComments}</p>
              {/*
            </div>
*/}
            </div>
          </div>
        )}
      {currentStep === OrderModDetailsView.CHANGE_SET_DETAILS && changeSetData && (
        <>
          <div className="order-mod-detials-container">
            <div className="create-order-mod-title">
              <div className="order-mod-title-section">
                <span className="order-mod-title">
                  {orderModData?.orderModName}
                </span>
                <span className="status-badge">
                  <StatusBadge variant={color}>{label}</StatusBadge>
                </span>
              </div>
            </div>
          </div>
          <div>
            <OrderModChangeSetDetails
              changeSetData={changeSetData}
              attachments={attachments}
            />
            <Button
              label="Return to modification"
              variant="outline"
              onClick={() => {
                setCurrentStep(1);
              }}
              leftIcon={{
                name: 'back',
                type: 'button',
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
OrderModDetails.propTypes = {
  orderModDetails: PropTypes.objectOf(Object).isRequired,
};
export default OrderModDetails;
