import { useState } from 'react';

export default function useLocalState(key) {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(key)) || null,
  );

  const updateValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, updateValue];
}
