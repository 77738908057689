import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  RequiredFieldIndicator,
  TextInput,
  SelectDropdown,
  Spinner,
  Alert,
} from '@gsa/afp-component-library';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  GET_CONTRACT_BY_CONTRACT_NUMBER,
  GET_CONTRACT_LINES_BY_CONTRACT_NUMBER,
  SUBMIT_TO_GSA,
  GET_STANDARD_CODES,
} from '../../../services/data-layer';
import ReturnOrderModal from '../../ReviewDetails/ReturnOrderModal';
import { ContractDetails } from '../urgent-requisition/utils/UrgReqCommonUtils';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './MasContractingPlaceOrder.scss';
import RequisitionMessageLines from '../../ReviewDetails/tabs/ReqMessageLines/RequisitionMessageLines';

const BLANK = { label: '-select-', value: '' };

let checkForInvalidData = false;
const MasContractingPlaceOrder = ({ requisitionCartData }) => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: { masPlaceOrderInitialState },
  } = useContext(VehicleRequisitionContext);
  const draftId = requisitionCartData?.requisitionId;

  const [submitToGSA, { loading: submitLoading }] = useMutation(SUBMIT_TO_GSA);

  const formProps = useForm({
    defaultValues: {
      ...masPlaceOrderInitialState,
      quantity:
        masPlaceOrderInitialState.quantity ||
        requisitionCartData?.nonSopData?.quantity,
      color:
        masPlaceOrderInitialState.color ||
        requisitionCartData?.nonSopData?.color,
    },
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
    clearErrors,
  } = formProps;

  const [colors, setColors] = useState([]);
  const [scheduleLines, setScheduleLines] = useState([BLANK]);
  const [contractInfo, setContractInfo] = useState(null);

  const isContractDataExist =
    contractInfo && Object.keys(contractInfo).length > 0;
  checkForInvalidData =
    !contractInfo?.quantity ||
    !contractInfo?.contractTotalPrice ||
    !contractInfo?.contractUnitPrice;
  // !contractInfo?.units;

  const setContractNumberError = () => {
    return setError('contractNumber', {
      type: 'required',
      message: 'Please enter a valid contract number',
    });
  };

  const setNoScheduleLinesError = () => {
    return setError('contractNumber', {
      type: 'required',
      message: 'This contract has no valid schedule lines',
    });
  };

  const setScheduleLineError = () => {
    return setError('scheduleLine', {
      type: 'required',
      message: 'Please enter a valid schedule line',
    });
  };

  const onSubmit = async () => {
    const formValues = getValues();
    const payload = {
      requisitionId: draftId,
      approvalComment: '',
      contractNumber: formValues.contractNumber,
      scheduleLine: formValues.scheduleLine,
      nonSopColor: formValues.color,
      nonSopQuantity: +formValues.quantity,
    };
    await submitToGSA({ variables: payload });

    navigate(
      `/my-requisitions?requisitionId=${draftId}&draftName=${requisitionCartData?.friendlyName}`,
    );

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_MAS_PLACE_ORDER_STATE,
      payload: formValues,
    });
  };

  const transformRespToOptions = (data, field) => {
    const list = [];
    data.forEach((i) => {
      list.push({
        label: i[field],
        value: i[field],
      });
    });
    return list;
  };

  useQuery(GET_STANDARD_CODES, {
    variables: {
      filters: {
        operator: 'EQ',
        key: 'code_metadata_id',
        value: 1,
      },
    },
    onCompleted: (data) => {
      const colorsData = data?.getStandardCodes?.rows;
      if (colorsData?.length) {
        setColors(
          transformRespToOptions(
            [{ id: '-1', title: '<No preference>' }, ...colorsData],
            'title',
          ),
        );
      }
    },
  });

  const [getContractInfo, { loading: contractInfoDataLoading }] = useLazyQuery(
    GET_CONTRACT_BY_CONTRACT_NUMBER,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getScheduleLines, { loading: scheduleLinesDataLoading }] =
    useLazyQuery(GET_CONTRACT_LINES_BY_CONTRACT_NUMBER, {
      fetchPolicy: 'no-cache',
    });

  const clearResultsData = () => {
    setValue('scheduleLine', '');
    setScheduleLines([BLANK]);
    setContractInfo(null);
  };

  const loadScheduleLines = async () => {
    const contractNumber = getValues().contractNumber?.trim();
    if (contractNumber) {
      setValue('scheduleLine', '');
      setScheduleLines([BLANK]);
      setContractInfo(null);
      const ret = await getScheduleLines({
        variables: { contractNumber },
      });
      if (ret?.data?.storeGetContractByContractNumber) {
        setValue(
          'contractNumber',
          ret?.data.storeGetContractByContractNumber?.contractNumber,
        );
        const contractLines =
          ret?.data.storeGetContractByContractNumber?.contractLines
            .filter((x) => x.financial !== null)
            .map((x) => ({ value: x.scheduleLine, label: x.scheduleLine }));
        if (!contractLines.length) {
          setNoScheduleLinesError();
        } else {
          setScheduleLines([BLANK, ...contractLines]);
          clearErrors('contractNumber');
        }
      } else setContractNumberError();
    } else setContractNumberError();
  };

  const handleGetContractInfo = async (value) => {
    if (!errors?.quantity?.size) {
      const { contractNumber, quantity } = getValues();
      setContractInfo(null);
      const qryResult = await getContractInfo({
        variables: {
          requisitionId: draftId,
          contractNumber,
          scheduleLine: value,
          updatedQuantity: +quantity,
        },
      });
      if (qryResult?.data) {
        const info = qryResult.data.getContractDetailsByContractNumber;
        setContractInfo(info);
      } else setScheduleLineError();
    }
  };

  const validateNumber = (value) => {
    return (
      (value >= 1 && value <= 999) || 'Quantity can only be between 1 and 999'
    );
  };

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="co-final-review-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {submitLoading && (
          <div className="afp-modal-overlay draft-requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}
        <div className="vendor-review-info">
          <div className="top-padding-50">
            <div className="title">Verify selected quantity and color</div>
          </div>

          <div
            className={`${
              errors?.quantity?.size > 0 &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <Controller
              rules={{
                required: 'This is a required field',
                validate: validateNumber,
              }}
              name="quantity"
              render={({ field: { value, onChange, ref } }) => (
                <div className="contract-no-section">
                  <TextInput
                    data-testid="contract-no-section-field"
                    label={
                      <>
                        Quantity <RequiredFieldIndicator />
                      </>
                    }
                    onChange={(e) => {
                      onChange(e);
                      setContractInfo(null);
                      setValue('scheduleLine', '');
                    }}
                    value={value}
                    inputRef={ref}
                    className="input-contract-line"
                    name="quantity"
                    errorMessage={errors?.quantity?.message}
                  />
                </div>
              )}
            />
          </div>

          {colors.length && (
            <div
              className={`${
                errors?.color?.size > 0 &&
                'usa-form-group usa-form-group--error'
              }`}
            >
              <Controller
                rules={{
                  required: 'This is a required field',
                }}
                name="color"
                render={({ field: { value, onChange, ref } }) => (
                  <div className="color-section">
                    <SelectDropdown
                      data-testid="color-section-field"
                      name="color"
                      label={
                        <>
                          Color <RequiredFieldIndicator />
                        </>
                      }
                      id="color"
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      options={colors}
                      errorMessage={errors?.color?.message}
                    />
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div className="vendor-review-info">
          <div className="top-padding-50">
            <div className="title">
              {' '}
              Provide contract details
              <RequiredFieldIndicator />{' '}
            </div>
          </div>
          {contractInfo && checkForInvalidData && (
            <div className="contract-line-error-alert">
              <Alert type="error" slim>
                The entered contract contains invalid data. Please review this
                contract information in ROADS.
              </Alert>
            </div>
          )}
          <div
            className={`${
              errors?.contractNumber?.size > 0 &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <Controller
              rules={{
                required: 'This is a required field',
              }}
              name="contractNumber"
              render={({ field: { value, onChange, ref } }) => (
                <div className="contract-no-section">
                  <TextInput
                    data-testid="contract-no-section-field"
                    label={
                      <>
                        Enter contract number <RequiredFieldIndicator />
                      </>
                    }
                    onChange={(e) => {
                      clearResultsData();
                      onChange(e);
                    }}
                    value={value}
                    inputRef={ref}
                    className="input-contract-line"
                    name="contractNumber"
                    errorMessage={errors?.contractNumber?.message}
                  />

                  <Button
                    data-testid="load-schedule-lines-btn"
                    type="button"
                    variant="outline"
                    label="Load Schedule Lines"
                    className="load-schedule-lines-btn"
                    onClick={() => {
                      loadScheduleLines(value);
                    }}
                  />
                </div>
              )}
            />
          </div>

          {scheduleLines.length > 1 && (
            <div
              className={`${
                errors?.scheduleLine?.size > 0 &&
                'usa-form-group usa-form-group--error'
              }`}
            >
              <Controller
                rules={{
                  required: 'This is a required field',
                }}
                name="scheduleLine"
                render={({ field: { value, onChange, ref } }) => (
                  <div className="schedule-line-section">
                    <SelectDropdown
                      data-testid="schedule-line-section-field"
                      name="scheduleLine"
                      label={
                        <>
                          Select a schedule line <RequiredFieldIndicator />
                        </>
                      }
                      id="scheduleLine"
                      className="select-schedule-line"
                      onChange={(e) => {
                        onChange(e);
                        handleGetContractInfo(e.target.value);
                      }}
                      value={value}
                      ref={ref}
                      options={scheduleLines}
                      errorMessage={errors?.scheduleLine?.message}
                    />
                  </div>
                )}
              />
            </div>
          )}

          {(contractInfoDataLoading || scheduleLinesDataLoading) && (
            <Spinner size="large" className="margin-y-9" />
          )}

          {isContractDataExist && (
            <ContractDetails contractLine={contractInfo} />
          )}
        </div>

        <div className="mas-message-lines">
          <RequisitionMessageLines requisitionId={draftId} isEditAllowed />
        </div>

        <div className="cutomer-place-order-btn-section">
          <ReturnOrderModal
            requisitionId={draftId}
            requisitionName={requisitionCartData?.friendlyName}
            requisitionCartValidations={null}
            canSubmitOrder
            visible
            isFromReqAction={false}
          />
          <Button
            data-testid="select-vendor-btn"
            type="submit"
            variant="primary"
            disabled={!isContractDataExist || checkForInvalidData}
            label="Place order"
            leftIcon={{ name: 'check' }}
          />
        </div>
      </form>
    </FormProvider>
  );
};

MasContractingPlaceOrder.propTypes = {
  requisitionCartData: PropTypes.instanceOf(Object).isRequired,
};

export default MasContractingPlaceOrder;
