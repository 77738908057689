import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, connectModal } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { generateReceiptStatusModalContent } from './receipt-status-modal-form';
import { receiptStatusModalSchema } from './receipt-status-modal-schema';
import useVehicleState from '../../useVehicleState';
import { useVehicleFilter } from '../../vehicle-filter-provider';
import { useSystemAlert } from '../../../../services/system-alert';

function ReceiptStatusModal({
  onClose,
  updateOrderVehicleReceived,
  orderVehicleId,
  getOrders,
}) {
  const [receiptStatusModalFormContent, defaultValues] =
    generateReceiptStatusModalContent();

  const { setSystemAlert, clearSystemAlert } = useSystemAlert();
  const { perPage, currentPage, orderBy, orderDirection } = useVehicleState();

  const { filters } = useVehicleFilter();

  const handleSubmit = (data) => {
    clearSystemAlert();
    updateOrderVehicleReceived[0]({
      variables: {
        input: {
          orderVehicleId,
          receivedDate: data.receivedDate,
        },
      },
      onCompleted: () => {
        getOrders({
          variables: {
            query: {
              offset: (currentPage - 1) * perPage,
              limit: perPage,
              orderBy,
              orderDirection,
              filters,
            },
          },
        });
        onClose();
      },
      onError: (err) => {
        onClose();
        setSystemAlert({
          type: 'error',
          heading: 'Something went wrong',
          errorHint: err.message,
        });
      },
    });
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      variant="small"
      onClose={closeModal}
      title={
        <h1 className="font-body-lg title-md-bold">
          Enter agency received date
        </h1>
      }
      actions={
        <div>
          <Button variant="unstyled" onClick={closeModal} label="Cancel" />
          <Button
            variant="primary"
            label="Save"
            type="submit"
            form="receipt-status-modal-form"
            disabled={updateOrderVehicleReceived[1].loading}
          />
        </div>
      }
    >
      <p>
        Enter the date that you physically took possession of the vehicle.
        Please note that once entered,{' '}
        <strong>this date cannot be changed</strong>. The agency received date
        will trigger the billing process in GSAFleet.gov.
      </p>
      <FormGenerator
        id="receipt-status-modal-form"
        schema={receiptStatusModalSchema}
        content={receiptStatusModalFormContent}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}

ReceiptStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateOrderVehicleReceived: PropTypes.arrayOf(PropTypes.func).isRequired,
  orderVehicleId: PropTypes.string.isRequired,
  getOrders: PropTypes.func.isRequired,
};

export default connectModal(ReceiptStatusModal);
