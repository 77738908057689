import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

const Breadcrumbs = () => (
  <DSBreadcrumbs
    trail={[
      <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
        Home
      </a>,
    ]}
    current="Ordering guide"
  />
);
export default Breadcrumbs;
