import React from 'react';
import PropTypes from 'prop-types';
import '../EngineeringReviewModal/style.scss';
import { Modal, Button, Alert } from '@gsa/afp-component-library';
import { IN_REQ_TAG } from '../../constants/constants';

const InputRequiredOptionsModal = ({
  selectedOptionsFn,
  closeModalFn,
  confirmContinueAction,
}) => {
  return (
    <>
      <div
        role="dialog"
        aria-modal="true"
        className="afp-modal-overlay modalContainer low-bid-justification"
      >
        <Modal
          onClose={() => {
            closeModalFn(false);
          }}
          // className="justification-modal"
          actions={
            <>
              <Button
                type="button"
                data-testid="cancel-button"
                className="usa-button usa-button--unstyled usa-button--medium padding-right-1"
                onClick={() => {
                  closeModalFn(false);
                }}
                label="Cancel"
              />
              <Button
                type="button"
                className="usa-button usa-button--primary usa-button--medium"
                data-testid="proceed-button"
                onClick={() => {
                  confirmContinueAction();
                }}
                label="Yes"
              />
            </>
          }
        >
          <Alert data-testid="engineering-review-info-alert" type="info">
            You will be asked to provide detailed descriptions and (optional)
            documentation to support your following selected options.
          </Alert>

          <div className="tagged-options">
            <div className="inner-content">
              {selectedOptionsFn &&
                selectedOptionsFn(IN_REQ_TAG).map(
                  ({
                    optionCategoryCode,
                    optionCategoryName,
                    optionDescription,
                  }) => {
                    return (
                      <li
                        data-testid={`test-${optionCategoryCode}-${optionCategoryName}`}
                        key={`${optionCategoryCode}-${optionCategoryName}`}
                      >
                        {optionCategoryCode}/{optionCategoryName}:{' '}
                        {optionDescription}
                      </li>
                    );
                  },
                )}
            </div>
          </div>

          <div className="conflict-explanation-msg">
            Do you still wish to proceed with these options?
          </div>
        </Modal>
      </div>
    </>
  );
};

InputRequiredOptionsModal.propTypes = {
  selectedOptionsFn: PropTypes.instanceOf(Function),
  closeModalFn: PropTypes.instanceOf(Function),
  confirmContinueAction: PropTypes.instanceOf(Function),
};

InputRequiredOptionsModal.defaultProps = {
  selectedOptionsFn: null,
  closeModalFn: null,
  confirmContinueAction: null,
};

export default InputRequiredOptionsModal;
