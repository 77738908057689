import { useContext } from 'react';
import { VehicleAvailabilityContext } from './VehicleAvailabilityProvider';

export default function useVehicleAvailabilityState() {
  const context = useContext(VehicleAvailabilityContext);
  if (!context) {
    throw new Error(
      'useVehicleAvailabilityState can only be used within VehicleAvailabilityProvider',
    );
  }
  return context;
}
