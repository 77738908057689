import React from 'react';
import PropTypes from 'prop-types';
import './BasePrice.scss';
import { formatCurrency } from '../../utilities/CompareVehicleUtils';

const BasePrice = ({ lowestBidData, networkCostBreakDownData }) => {
  return (
    <div className="afp-store-base-price">
      <table className="usa-table full-width-table" role="presentation">
        <tbody>
          <tr>
            <td
              data-testid="base-price-title-cell"
              className="base-price-title-cell"
            >
              Base Price (per vehicle)
            </td>
            {networkCostBreakDownData.map((contract) => {
              const activateLowestBid =
                contract.contractLineId === lowestBidData.contractLineId
                  ? 'lowest-bid'
                  : '';
              return (
                <td
                  data-testid={`${contract.contractLineId}-base-price`}
                  key={`${contract.contractLineId}-base-price`}
                  className={`contract-cell ${activateLowestBid}`}
                >
                  {formatCurrency(contract.basePrice)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BasePrice;
