export const REQUISITION_TYPE = {
  STANDARD_ORDER: 1,
  AREQ: 2,
  URGENT_REQUIREMENT: 4,
  NON_STANDARD_VEHICLE: 8,
  MULTIPLE_AWARD_SCHEDULES: 16,
  ORDER_ON_BEHALF: 32,
};

export const isAreqType = (requisitionType) =>
  requisitionType === REQUISITION_TYPE.AREQ;

export const requisitionTypeDescriptor = {
  1: 'STANDARD_ORDER',
  2: 'AREQ',
  4: 'URGENT_REQUIREMENT',
  8: 'NON_STANDARD_VEHICLE',
  16: 'MULTIPLE_AWARD_SCHEDULES',
  32: 'ORDER_ON_BEHALF',
};

export const requisitionTypeValues = (requisitionType) => {
  const result = [];
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.STANDARD_ORDER) > 0)
    result.push(REQUISITION_TYPE.STANDARD_ORDER);
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.AREQ) > 0)
    result.push(REQUISITION_TYPE.AREQ);
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.URGENT_REQUIREMENT) > 0)
    result.push(REQUISITION_TYPE.URGENT_REQUIREMENT);
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.NON_STANDARD_VEHICLE) > 0)
    result.push(REQUISITION_TYPE.NON_STANDARD_VEHICLE);
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) > 0)
    result.push(REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES);
  // eslint-disable-next-line no-bitwise
  if ((requisitionType & REQUISITION_TYPE.ORDER_ON_BEHALF) > 0)
    result.push(REQUISITION_TYPE.ORDER_ON_BEHALF);

  return result;
};

export const NON_SOP_ORDERING_TYPES = {
  URGENT_REQUIREMENT: 'Urgent Requirement',
  MAS: 'Multiple Award Schedules (MAS)',
  NON_STANDARD_VEHICLE: 'Non-Standard Vehicle',
};

export const STEPS = {
  VEHICLE_SPECIFICATIONS: 'VEHICLE_SPECIFICATIONS',
  URGENT_REQUIREMENT_JUSTIFICATION: 'URGENT_REQUIREMENT_JUSTIFICATION',
  AGENCY_INFORMATION: 'AGENCY_INFORMATION',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  REVIEW_SUBMIT: 'REVIEW_SUBMIT',
};

export const URGENT_REQUISITION_STEPS = [
  {
    key: STEPS.VEHICLE_SPECIFICATIONS,
    label: 'Vehicle specifications',
    status: 'current',
    stepNumber: 1,
  },
  {
    key: STEPS.URGENT_REQUIREMENT_JUSTIFICATION,
    label: 'Urgent requirement justification',
    status: 'not completed',
    stepNumber: 2,
  },
  {
    key: STEPS.AGENCY_INFORMATION,
    label: 'Agency information',
    status: 'not completed',
    stepNumber: 3,
  },
  {
    key: STEPS.DELIVERY_ADDRESS,
    label: 'Delivery address',
    status: 'not completed',
    stepNumber: 4,
  },
  {
    key: STEPS.REVIEW_SUBMIT,
    label: 'Review and submit',
    status: 'not completed',
    stepNumber: 5,
  },
];

export const ORDERING_DESC = {
  URGENT_REQUIREMENT_DESC:
    'Choose this option if you have an unusual and compelling urgency that requires you to obtain a vehicle(s) that can typically be fulfilled within 30 days.',
  MAS_DESC: `Vehicle requests for requirements that can be fulfilled utilizing GSA Multiple Award Schedule contracts. 
    These orders can be completed by customer agencies directly through GSA Advantage or with GSA’s assisted acquisition professionals.`,
  NON_STANDARD_VEHICLE_DESC:
    'Requests for customized or unique non-tactical vehicle requirements. This program provides a turnkey solution to agency customers with GSA Vehicle Engineering support, Project Management support, and Contracting Officer support.',
};

export const NON_SOP_MAIN_TITLE_DESC = `In the event your Agency is unable to find the right vehicle using our Standard Vehicle Ordering Program, 
GSA Fleet is able to provide several offering options to find the vehicle that meets your Agency's mission. Please consult with one of our Offering Service Professionals so that we can ensure your Agency is selecting the best offering option. You can initiate a consultation with one of our Offering Service Professionals by emailing your requirements to `;
export const NON_SOP_MAIN_EMAIL = 'fleetspecialordering@gsa.gov';
export const URGENT_REQ_MODAL_CONENT = `
Urgent Requirement
The Urgent Requirement processes vehicle orders for government agencies with urgent requirements. Agencies must justify the urgent and compelling reasons for using the Urgent Requirement instead of waiting for the normal procurement cycle and delivery time to take place.
Prices will generally be higher than the established contract prices because the vehicles are purchased from a dealership and not directly from the manufacturer. Rules and guidelines governing the Urgent Requirement:
GSA is the mandatory source for non-tactical vehicles.
GSA by regulation MUST seek three sources of competition for the procurement.
GSA encourages customers to recommend the names of dealers within the delivery area to be solicited by GSA. Please advise GSA of the names, addresses, phone numbers and dealer contacts with the requisition. There is no guarantee that a suggested source will receive a contract award.
Customers should NOT negotiate terms and conditions with a potential contractor prior to sending in a requisition to GSA.
After being advised by GSA about the price and any exceptions or clarifications covering the vehicle(s) solicited, agencies must expedite final decisions. Dealers will generally not hold vehicle(s) more than 48 hours.
The government's payment terms are always NET 30 days upon receipt of a proper invoice.
The GSA surcharge for Urgent Requirement orders is two percent, except during August through September, when the surcharge is five percent.

You will be required to provide a justification. Are you sure you want to continue?
`;
export const URGENT_REQ_VEH_DESC = `The Urgent Requirement processes vehicle orders for government agencies with urgent requirements.
Agencies must justify the urgent and compelling reasons for using the Urgent Requirement instead of waiting for the normal
procurement cycle and delivery time to take place.`;

export const MAS_REQ_VEH_DESC =
  'Vehicle requests for requirements that can be fulfilled utilizing GSA Multiple Award Schedule contracts. These orders can be completed by customer agencies directly through GSA Advantage or with GSA’s assisted acquisition professionals. ';

export const JUSTIFICATION_TITLE =
  'Provide justification and documentation for your urgent requirement';
export const JUSTIFICATION_TITLE_DESC =
  'Agencies must justify the urgent and compelling reasons for using the Urgent Requirement Process instead of waiting for the normal procurement cycle and delivery time to take place. Do not include any sensitive/classified information.  Additional documents may be attached below.';
export const JUSTIFICATION_COMPELLING =
  'Justification for Urgent and Compelling';
export const JUSTIFICATION_COMPELLING_DESC = '';
export const JUSTIFICATION_DOCUMENTATION_DESC =
  'You may load multiple files; however, the combined size of all files cannot exceed 30 MB. File name will be converted to acceptable system files. All special characters will be removed. File names should be alpha numeric, and can also include underscores, commas periods and blank spaces. File types supported include Word, Excel, text, PDF, JPEG, etc.';

export const ROW_ACTIONS = [
  {
    icon: 'edit',
    label: 'Edit',
    canShowIndicator: 'canEdit',
  },
  {
    icon: 'delete',
    label: 'Delete',
    canShowIndicator: 'canDelete',
  },
];

export const ACCEPT_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'image/jpg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/zip',
  'image/png',
  'image/jpeg',
  'message/rfc822',
];

export const ACCEPT_FILE_LABELS =
  'Accepts .pdf, .doc, .docx, .xls, .jpg, .png or .zip files';

export const ACCEPT_FILE_SHEETS_LABELS = 'Accepts .xls or .xlsx files';

export const ACCEPT_FILE_SHEETS_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const DOCUMENTATION_OPTIONS = [
  { value: null, label: '-select-' },
  { value: 'Source list', label: 'Source list' },
  { value: 'Brand name justification', label: 'Brand name justification' },
  { value: 'Sole source justification', label: 'Sole source justification' },
  { value: 'Funding documentation', label: 'Funding documentation' },
  { value: 'Other documentation', label: 'Other documentation' },
  {
    value: 'Justification for Urgent and Compelling',
    label: 'Justification for Urgent and Compelling',
  },
];

export const TOWING_TYPES = [
  { value: '-select-', label: '-select-' },
  { value: 'N/A', label: 'N/A' },
  { value: '5th Wheel', label: '5th Wheel' },
  { value: 'Bumper', label: 'Bumper' },
];

export const AVAILABLE_EDIT_STEPS = [1, 2, 3, 4];
export const NON_SOP_REQ_TYPES = [
  REQUISITION_TYPE.URGENT_REQUIREMENT,
  REQUISITION_TYPE.NON_STANDARD_VEHICLE,
  REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES,
];
