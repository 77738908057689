import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import './TaggedOptionDetails.scss';
import { STEPS } from '@/pages/VehicleRequisition/constants/VehicleRequisitionConstants.jsx';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import { GET_REQUISITION_ATTACHMENTS } from '@/services/data-layer.jsx';
import { useQueryParam } from '@/hooks/useQueryParam.jsx';
import AttachmentDownload from '../../../pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

let totalFiles = [];
const TaggedOptionDetails = ({
  taggedOptions,
  draftRequisition,
  isEditable,
  requisitionAttachmentTypes,
  onEdit
}) => {

  const query = useQueryParam();
  const requisitionId =
    query.get('requisitionId') || draftRequisition?.requisitionId;

  const { data } = useQuery(GET_REQUISITION_ATTACHMENTS, {
    skip: !requisitionId,
    variables: { requisitionId },
  });

  const foundAttachmentType = requisitionAttachmentTypes?.find(
    (a) => a.attachmentType === 'req_purchaseline_equipment',
  );
  useEffect(() => {
    if (data?.getAllRequisitionAttachments.length > 0) {
      totalFiles = data?.getAllRequisitionAttachments
        .filter(
          (f) =>
            f.attachmentTypeId ===
            foundAttachmentType?.requisitionAttachmentTypeId,
        )
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
    }
  }, [data]);

  const renderOptionsWithAttachments = (options, allFiles) => {
    if (!options) {
      return [];
    }

    return options?.map((item) => (
      <React.Fragment key={item.optionCode}>
        <div className="options">
          <span>
            {item.optionCode} {' - '} {item.optionDescription}{' '}
            {!!item.optionQuantity && <>&#215; {item.optionQuantity}</>}
          </span>
          <span>${item.unitPrice}</span>
        </div>
        <div className="customer-input-section">{item.customerInput}</div>

        {allFiles.length > 0 && (
          <div>
            {allFiles?.map((list) => {
              return (
                <div className="attachment-section">
                  <AttachmentDownload
                    name={list?.name}
                    metadataId={list?.metadataId}
                  />
                  <div className="customer-input-section">
                    {list.description}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      {Boolean(taggedOptions?.length) && (
        <div className="option-details-section">
          <div className="title-section">
            <span className="selected-options-header">Option details</span>
            {isEditable && (
              <SectionEditButton
                showButton
                id="required-options-edit"
                step={STEPS.OPTION_DETAILS}
                editPage={onEdit}
              />
            )}
          </div>

          <div className="option-details-section">
            {renderOptionsWithAttachments(taggedOptions, totalFiles)}
          </div>
        </div>
      )}
    </>
  );
};

TaggedOptionDetails.propTypes = {
  taggedOptions: PropTypes.instanceOf(Array).isRequired,
  draftRequisition: PropTypes.instanceOf(Object).isRequired,
  requisitionAttachmentTypes: PropTypes.instanceOf(Array).isRequired,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
};

TaggedOptionDetails.defaultProps = {
  isEditable: false,
};

export default TaggedOptionDetails;
