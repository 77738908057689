import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useNavigate } from 'react-router-dom';
import ContractList from './ContractList';
import ContractECDetails from './ContractECDetails';
import ContractECFilterProvider from './ContractECFilterProvider';
import OrderGuideProvider from './OrderGuideProvider';
import OrderGuideECProvider from './OrderGuideECProvider';

import './OrderingGuideFilter.scss';
import { OrderingGuideSteps } from './constants';
import { UserRoles, UserTypes } from '../../constants';

const OrderingGuideFilter = () => {
  const { currentStep } = useSelector((state) => state.orderingGuideReducer);
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const isSiteAdmin = currentUser.roles?.some((role) => role.name === UserRoles.SITE_ADMIN);
  
  useEffect(() => {
    if (currentUser?.userType?.id !== UserTypes.GSA_EMPLOYEE && !isSiteAdmin) {
      navigate('/');
    }
  }, [currentUser]);

  return (
    <>
      {currentStep === OrderingGuideSteps.CONTRACT_LIST && (
        <OrderGuideProvider>
          <ContractList />
        </OrderGuideProvider>
      )}
      {currentStep === OrderingGuideSteps.CONTRACT_ECS && (
        <OrderGuideECProvider>
          <ContractECFilterProvider>
            <ContractECDetails />
          </ContractECFilterProvider>
        </OrderGuideECProvider>
      )}
    </>
  );
};

export default OrderingGuideFilter;
