export const getSortedClarifications = (clarifications) => {
  const sortedClarifications = clarifications
    .filter(({ type }) => type !== 'E')
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const sortedExceptions = clarifications
    .filter(({ type }) => type === 'E')
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  return [...sortedClarifications, ...sortedExceptions];
};

export const getGroupedClarifications = (clarifications) => {
  const sortedClarifications = clarifications
    .filter(({ type }) => type !== 'E')
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const sortedExceptions = clarifications
    .filter(({ type }) => type === 'E')
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  return { sortedClarifications, sortedExceptions };
};

export const generateEcClarification = (ecClarifications) => {
  const clarificationClarifications = ecClarifications?.filter(
    ({ type }) => type !== 'E',
  );
  const clarificationExceptions = ecClarifications?.filter(
    ({ type }) => type === 'E',
  );

  const clarificationsExist =
    ecClarifications &&
    (clarificationClarifications.length > 0 ||
      clarificationExceptions.length > 0);

  return {
    clarificationClarifications,
    clarificationExceptions,
    clarificationsExist,
  };
};
