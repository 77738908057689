import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import MultipleAdditionalRequirementsTab from '../../../../components/MultipleAdditionalRequirements/MultipleAdditonalRequirementsTab';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { UPDATE_LEASING_REQUISITION_CART } from '../../../../services/data-layer';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './ReviewSummaryMultipleAdditionalRequirements.scss';
import { UserRoles } from '../../../../constants/user-constants';

export const ReviewSummaryMultipleAdditionalRequirements = ({
  formRef,
  createdByInfo,
  vehicleType,
  additionalRequirements,
  agencyReferenceNumber,
  requisitionCartState,
  setRequisitionCartState,
}) => {
  const { requisitionDraftId, dispatch } = useContext(
    VehicleRequisitionContext,
  );

  const onError = (errors) => {
    console.log({ errors });
  };
  const [updateLeasingRequisitionCart] = useMutation(
    UPDATE_LEASING_REQUISITION_CART,
  );

  const onSubmit = async (data) => {
    const formattedAdditionalRequirements = data.additionalRequirements.map(
      (additionalRequirement) => {
        return {
          ...additionalRequirement,
          priceEstimate: Number(additionalRequirement.priceEstimate),
        };
      },
    );

    await updateLeasingRequisitionCart({
      variables: {
        requisitionId: requisitionDraftId,
        updateInput: {
          agencyReferenceNumber: data.agencyReferenceNumber,
          additionalRequirements: formattedAdditionalRequirements,
        },
      },
    });

    setRequisitionCartState({
      ...requisitionCartState,
      additionalRequirements: formattedAdditionalRequirements,
      agencyReferenceNumber: data.agencyReferenceNumber,
    });

    dispatch({
      type: VehicleRequisitionContextActions.SET_IS_LEASING_ADDITIONAL_REQUIREMENTS_PREVIEW,
      payload: true,
    });
  };

  const defaultAdditionalRequirements = [
    {
      attachments: [],
      description: '',
      priceEstimate: 0,
    },
  ];

  const formProps = useForm({
    defaultValues: {
      agencyReferenceNumber: '',
      additionalRequirements: defaultAdditionalRequirements,
    },
    reValidateMode: 'onChange',
  });

  const { reset, handleSubmit, trigger } = formProps;

  // The state is updated multiple times, so we need to reset the form each time the state is updated
  useEffect(() => {
    reset({
      agencyReferenceNumber,
      additionalRequirements: additionalRequirements.length
        ? additionalRequirements
        : defaultAdditionalRequirements,
    });
  }, [agencyReferenceNumber, additionalRequirements, reset]);

  useImperativeHandle(formRef, () => ({
    ...formRef.current,
    handleReview() {
      handleSubmit(onSubmit, onError)();
    },
  }));

  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );

  if (!isOrderingAdmin) {
    return null;
  }

  // check role
  return (
    <div id="review-summary-multiple-additional-requirements">
      <div className="margin-bottom-40">
        <FormProvider {...formProps}>
          <form
            // onSubmit={handleSubmit(onSubmit)}
            data-testid="review-summary-multiple-additional-requirements-form"
          >
            <MultipleAdditionalRequirementsTab
              createdByInfo={createdByInfo}
              vehicleType={vehicleType}
              agencyReferenceNumber={agencyReferenceNumber}
              trigger={trigger}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

ReviewSummaryMultipleAdditionalRequirements.propTypes = {
  createdByInfo: PropTypes.instanceOf(Object).isRequired,
  vehicleType: PropTypes.string.isRequired,
  additionalRequirements: PropTypes.instanceOf(Array).isRequired,
  formRef: PropTypes.instanceOf(Object).isRequired,
  agencyReferenceNumber: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  setRequisitionCartState: PropTypes.func.isRequired,
};
