import PropTypes from 'prop-types';
import {
  Accordion,
  AFPTable,
  EmptyState,
  Tag,
} from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import SelectedIcon from '../../../assets/images/selected-icon.svg';

const SelectedOptions = ({ selectedOptions }) => {
  console.log('selectedOptions', selectedOptions);
  const OPTION_ACTION = {
    add: {
      LABEL: 'ADDED',
      COLOR: 'Ready-Dark',
    },
    remove: {
      LABEL: 'REMOVED',
      COLOR: 'Urgent-Dark',
    },
    updateQty: {
      LABEL: 'UPDATED QUANTITY',
      COLOR: 'Info-Light',
    },
    noChange: {},
  };
  const MODIFY_OPTIONS_TABLE_COLUMNS = [
    {
      Header: 'Selected',
      accessor: 'isChecked',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        if (value) {
          return (
            <img
              className="selected-icon"
              src={SelectedIcon}
              alt="option selected icon"
            />
          );
        }
        return null;
      },
    },
    {
      Header: 'Option code',
      accessor: 'optionCode',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const { optionCode, optionDescription } = row.original;
        return (
          <div>
            <span className="option-span">{optionCode}</span>
            <br />
            <span className="option-desc-span">{optionDescription}</span>
          </div>
        );
      },
    },
    {
      Header: 'Option Description',
      accessor: 'optionDescription',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return (
          <div>
            <span className="option-span">{value}</span>
          </div>
        );
      },
    },
    {
      Header: 'Current unit price',
      accessor: 'currentUnitPrice',
    },
    {
      Header: 'Quantity',
      accessor: 'optionQuantity',
    },
    {
      Header: 'Total price',
      accessor: 'optionTotalPrice',
    },
    {
      Header: 'Actions',
      accessor: 'action',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        if (value && value !== 'noChange') {
          return (
            <Tag variant={OPTION_ACTION[value]?.COLOR}>
              {value ? (
                OPTION_ACTION[value].LABEL
              ) : (
                <span className="change-set-no-items">(no action)</span>
              )}
            </Tag>
          );
        }
        return <div />;
      },
    },
  ];
  const columns = useMemo(() => MODIFY_OPTIONS_TABLE_COLUMNS, []);
  const getSelectedOptions = () => {
    console.log('selectedOptions', selectedOptions);

    return [
      {
        id: 'selectedOrders',
        title: <div className="add-options-accordion-title">Options</div>,
        content: (
          <div className="order-details-acc-body">
            <p className="order-mod-acc-sub-title">Modified Options</p>
            <div className="table-main-container">
              {selectedOptions && (
                <AFPTable
                  testId="ordermod-options-table"
                  columns={columns}
                  hiddenColumns={['optionDescription']}
                  data={selectedOptions || []}
                />
              )}
              {selectedOptions.length < 1 && (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No options modified"
                />
              )}
            </div>
          </div>
        ),
        expanded: false,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getSelectedOptions()}
        className="add-options-accordion"
      />
    </>
  );
};
SelectedOptions.propTypes = {
  selectedOptions: PropTypes.arrayOf(Object).isRequired,
};
export default SelectedOptions;
