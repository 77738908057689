import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { Button, Spinner } from '@gsa/afp-component-library';

import { exportFile } from '../../../../utilities/commonUtils';

import { REPRINT_MVDO_ATTACHMENT } from '../../../../services/data-layer';

export default function MVDOAttachmentReprint({ displayName, orderId }) {
  const [isLoading, setIsLoading] = useState(false);

  const decodeBase64Data = (content) => {
    const binaryString = window.atob(content);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };
  const [reprintMVDOAttachment] = useLazyQuery(REPRINT_MVDO_ATTACHMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      if (data) {
        const { content, contentType, fileName } = data.reprintMVDOAttachment;
        exportFile(decodeBase64Data(content), contentType, fileName);
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('An error occurred:', error);
    },
  });

  const download = async () => {
    setIsLoading(true);
    await reprintMVDOAttachment({
      variables: {
        orderId,
      },
    });
  };

  if (!orderId) {
    return <>Invalid Order!</>;
  }

  return (
    <>
      {isLoading && <Spinner size="small" className="margin-y-8" />}
      {!isLoading && (
        <Button
          data-testid="download-button"
          variant="unstyled"
          onClick={() => download()}
          label={displayName}
        />
      )}
    </>
  );
}

MVDOAttachmentReprint.propTypes = {
  displayName: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};
