export const getUrlFromLink = (linkUrl) => {
  const { appURLs } = window.AFP_CONFIG || { appURLs: {} };
  if (!linkUrl || linkUrl === 'null' || linkUrl === '#not_implemented') {
    return '#';
  }

  // TODO: find an efficient way to convert string to template literals
  // 01-07-2022 removes /(?<=}).*/g as safari doesn't support lookbehind regex
  // https://caniuse.com/js-regexp-lookbehind
  const appUrlRegex = /[^{.}]+(?=})/g;

  const appUrl = appUrlRegex.exec(linkUrl)[0];

  return linkUrl.replace(`\${appURLs.${appUrl}}`, appURLs[appUrl]);
};

const updateLinkUrl = (menuItem) => ({
  ...menuItem,
  linkUrl: getUrlFromLink(menuItem.linkUrl),
});

export const getAvailableMenuItemsForUser = (menu, ability) => {
  const filteredMenu = menu.filter(
    (item) =>
      !item?.isHidden &&
      (ability.can(
        item?.permission?.operation?.name,
        item?.permission?.subject?.name,
      ) ||
        !item?.permission),
  );

  return filteredMenu
    .map(({ childNavigationItems, linkUrl, ...menuItem }) => {
      const availableChildNavigationItems = [];

      if (childNavigationItems?.length > 0) {
        childNavigationItems.map((childMenuItem) => {
          if (childMenuItem.permission) {
            if (
              ability.can(
                childMenuItem.permission?.operation.name,
                childMenuItem.permission?.subject.name,
              )
            ) {
              availableChildNavigationItems.push(updateLinkUrl(childMenuItem));
            }
          } else {
            availableChildNavigationItems.push(updateLinkUrl(childMenuItem));
          }
          return availableChildNavigationItems;
        });
      }
      return {
        ...menuItem,
        linkUrl: getUrlFromLink(linkUrl),
        childNavigationItems: availableChildNavigationItems,
      };
    })
    .filter(
      (item) =>
        !item.hasSubMenu ||
        (item.hasSubMenu && item.childNavigationItems.length > 0),
    );
};

export default {
  getAvailableMenuItemsForUser,
  getUrlFromLink,
};
