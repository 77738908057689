import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import cx from 'classnames';

import { WarnIcon } from '../../../../assets/images/warn-icon';
import { OPTION_QUANTITY_REQUIRED_CODE } from '../../../../utilities/CompareVehicleUtils';

const NumInput = ({ onChange, option, onFocusChange, min = 0, max = 999 }) => {
  let { optionValue } = option;
  const inputId = uuidv4();
  let label = '';
  const hasNumInput =
    option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE || option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;
  if (option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE) {
    label = 'per Vehicle';
  } else if (option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER) {
    label = 'per Order';
  }

  if (!option.isChecked && hasNumInput) {
    optionValue = '';
  }

  const minValue = min;
  const maxValue = max;

  const onInputChange = (e) => {
    if (!option.isChecked) {
      return;
    }

    const targetVal = e.target.value;
    const val = targetVal === '' ? '' : parseInt(targetVal, 10);

    if (typeof onChange === 'function') {
      onChange(val, option.optionCode);
    }
  };

  const onInputFocusChange = (focused) => {
    if (typeof onFocusChange === 'function') {
      onFocusChange(focused, option.optionCode);
    }
  };

  return (
    <>
      <span
        className={`per-vehicle-order ${
          option.hasError && !option.focused
            ? 'usa-form-group--error margin-top-0'
            : ''
        }`}
      >
        <div className="option-input">
          <div
            data-testid={`num-input-${option.quantityRequiredCode}-tooltip`}
            className={`tooltip top ${
              (option.focused && optionValue === '') ||
              (option.focused && option.hasError)
                ? 'open'
                : ''
            }`}
          >
            <div className="icon">
              <WarnIcon width={15} height={15} />
            </div>
            <div className="text">Please enter a quantity {label}</div>
          </div>
          <input
            data-testid={`num-input-${option.quantityRequiredCode}`}
            type="number"
            min={minValue}
            max={maxValue}
            className={cx('enter-option-box', {
              'usa-input--error': option.hasError && !option.focused,
              disabled: !option.isChecked,
            })}
            onBlur={() => onInputFocusChange(false)}
            onChange={onInputChange}
            aria-disabled={!option.isChecked}
            value={optionValue}
            id={inputId}
          />
        </div>
        <label
          htmlFor={inputId}
          className={`option-label ${
            option.hasError && !option.focused ? 'usa-label--error' : ''
          }`}
        >
          {label}
        </label>
      </span>
    </>
  );
};

export default NumInput;