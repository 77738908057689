import { STEPS } from '../constants/VehicleRequisitionConstants';

export const getStepTitle = (step) =>
  ({
    [STEPS.PAINT_GRAPHICS]: 'Create a Requisition-Paint and graphics',
    [STEPS.OPTION_DETAILS]: 'Create a Requisition',
    [STEPS.REVIEW_VEHICLE_BUILD]: 'Create a Requisition-Review vehicle build',
    [STEPS.NON_LOW_BID_REASON]: 'Create a Requisition',
    [STEPS.AGENCY_INFORMATION]: 'Create a Requisition-Agency information',
    [STEPS.DELIVERY_ADDRESS]: 'Create a Requisition-Delivery address',
    [STEPS.REVIEW_SUBMIT]: 'Create a Requisition-Review and submit',
  }[step] || '');

