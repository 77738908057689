import React from 'react';
import PropTypes from 'prop-types';

const OrderAddress = ({
  title,
  entityName,
  line1,
  line2,
  city,
  stateCode,
  countryCode,
  zipcode,
  firstName,
  lastName,
  email,
  phoneNumber,
  phoneCountryCode,
  testId,
  displayPointOfContact,
}) => {
  const stateCodeFormatted = () => {
    const statePart = stateCode?.split('-')[1];
    if (statePart) {
      return `${statePart},`;
    }
    return stateCode ? `${stateCode},` : '';
  };
  return (
    <>
      <div className="order-details__overview-section__sub-section-container">
        <h4 className="font-sans-xs text-bold order-details__overview-section__sub-section-title">
          {title}
        </h4>
      </div>
      <div className="order-details__overview-section__content-container">
        <div className="order-details__overview-section__content">
          <div className="order-details__overview-section__content__data">
            <div className="order-details__overview-section__content__data__value">
              <p className="text-normal" data-testid={`${testId}-entityName`}>
                {entityName}
              </p>
              <p className="text-normal" data-testid={`${testId}-line-1`}>
                {line1}
              </p>
              {line2 && (
                <p className="text-normal" data-testid={`${testId}-line-2`}>
                  {line2}
                </p>
              )}
              <p
                className="text-normal"
                data-testid={`${testId}-city-state-zip`}
              >
                {city ? `${city},` : ''} {stateCodeFormatted()} {countryCode}{' '}
                {zipcode}
              </p>
              {displayPointOfContact || (
                <p
                  className="text-normal"
                  data-testid={`${testId}-dealership-phone`}
                >
                  {phoneCountryCode && `${phoneCountryCode}-`}{phoneNumber}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="order-details__overview-section__content">
          <div className="order-details__overview-section__content__data">
            {displayPointOfContact && (
              <>
                <div className="order-details__overview-section__content__data__title text-bold">
                  Point of contact
                </div>
                <div className="order-details__overview-section__content__data__value">
                  <p className="text-normal" data-testid={`${testId}-poc-name`}>
                    {firstName} {lastName}
                  </p>
                  <p
                    className="text-normal"
                    data-testid={`${testId}-poc-email`}
                  >
                    {email}
                  </p>
                  <p
                    className="text-normal"
                    data-testid={`${testId}-poc-phone`}
                  >
                    {phoneCountryCode && `${phoneCountryCode}-`}{phoneNumber}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

OrderAddress.propTypes = {
  title: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
  city: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  displayPointOfContact: PropTypes.bool,
};

OrderAddress.defaultProps = {
  line2: '',
  displayPointOfContact: true,
};

export default OrderAddress;
