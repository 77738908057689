import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../../services/data-layer';
import AddressComponent from './AddressComponent';
import UspsAddressedComp from './UspsModalComponent';
import {
  updatecurrentState,
  validateSelection,
} from '../../../../utilities/deliveryAddressUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './DeliveryAddress.scss';
import { ADDRESS_FEEDBACK } from '../../utils/VehicleRequisitionUtils';

export const requisitionSection = {
  id: 'Requisition Address',
  isChecked: false,
  title: 'REQUISITIONING ADDRESS',
  accessibilityId: 'requisitioningAddress',
  subTitle:
    'Enter the complete address of the office requisitioning the vehicle.',
};

export const mailingSection = {
  id: 'Mailing Address',
  isChecked: false,
  title: 'MAILING ADDRESS',
  accessibilityId: 'mailingAddress',
  subTitle:
    "Certificates of Origin are made out to the agency and address provided in the mailing address. Parts and service manuals are also sent to the mailing address. Do not provide a person's name in the mailing address.",
};

export const deliverySection = {
  id: 'Delivery address',
  accessibilityId: 'deliveryAddress',
  isChecked: false,
  title: 'DELIVERY ADDRESS',
  subTitle: '',
};

const DeliveryAddress = ({
  handlePageNavigation,
  requisitionDraftId,
  handleStepper,
}) => {
  const {
    state,
    dispatch,
    updateDraftRequisition,
    showUspsModal,
    setShowUspsModal,
    loadingValidatedRequisitionAddress,
    loadingValidatedMailingAddress,
    loadingValidatedDeliveryAddress,
  } = useContext(VehicleRequisitionContext);

  const {
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    statesRequisitionFetchedFrmContext,
    statesMailingFetchedFrmContext,
    statesDeliveryFetchedFrmContext,
    requisitionAddressErrorContext,
    mailingAddressErrorContext,
    deliveryAddressErrorContext,
    requisitionHasError,
    reqValidatedAddress,
    mailingValidatedAddress,
    deliveryValidatedAddress,
    validatedAddressNotFound,
    uspsModalOptionsSelectedState,
    currentStandardItem,
    deliveryOptions,
    selectedContract,
  } = state;

  const standardItemCodeFASTDescription =
    currentStandardItem?.standardItemCodeFASTDescription;
  const deliveryAddressOption = deliveryOptions
    .flatMap((option) => option.options)
    .find((c) => c.isChecked === true);
  const [reqCountrySelected, setReqCountrySelected] = useState('');
  const [mailingCountrySelected, setMailingCountrySelected] = useState('');
  const [deliveryCountrySelected, setDeliveryCountrySelected] = useState('');
  const [showConfirmationModal, showConfirmationModalTo] = useState(false);
  const [notFoundAddresses, notFoundAddressesTo] = useState('');

  const [requisitionStates, setRequisitionStates] = useState(
    statesRequisitionFetchedFrmContext,
  );
  const [mailingStates, setMailingStates] = useState(
    statesMailingFetchedFrmContext,
  );
  const [deliveryStates, setDeliveryStates] = useState(
    statesDeliveryFetchedFrmContext,
  );

  // DOC get countries
  const [getCountriesData, { data: fetchCountriesData }] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  // DOC phone country code
  const [getCountriesCallingCode, { data: fetchCountriesCallingCode }] =
    useLazyQuery(GET_CATALOGS_FOR_CATEGORY, {
      fetchPolicy: 'network-only',
    });

  // DOC dynamic states based on country
  const [getCountryRelatedStatesData] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  // DOC startup
  useEffect(() => {
    getCountriesData({
      variables: { category: 'ISOCountryCode2' },
    });
    getCountriesCallingCode({
      variables: { category: 'CountryCallingCode' },
    });
  }, []);

  // DOC gets state when requisition country is selected
  useEffect(() => {
    if (reqCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${reqCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setRequisitionStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [reqCountrySelected]);

  // DOC gets state when mailing country is selected
  useEffect(() => {
    if (mailingCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${mailingCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setMailingStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [mailingCountrySelected]);

  // DOC gets state when delivery country is selected
  useEffect(() => {
    if (deliveryCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${deliveryCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setDeliveryStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [deliveryCountrySelected]);

  // DOC sets context when country selected
  useEffect(() => {
    if (requisitionStateContext && requisitionStateContext.countryCode) {
      setReqCountrySelected(requisitionStateContext.countryCode);
    }
    if (mailingStateContext && mailingStateContext.countryCode) {
      setMailingCountrySelected(mailingStateContext.countryCode);
    }
    if (deliveryStateContext && deliveryStateContext.countryCode) {
      setDeliveryCountrySelected(deliveryStateContext.countryCode);
    }
  }, [
    requisitionStateContext.countryCode,
    mailingStateContext.countryCode,
    deliveryStateContext.countryCode,
  ]);

  // DOC handles same as checkbox
  const handleAddressCheckbox = (section) => {
    if (
      section === 'Mailing Address' &&
      !mailingStateContext.isMailingSameAsRequisition
    ) {
      const updatedState = {
        ...requisitionStateContext,
        id: 'Mailing Address',
        isMailingSameAsRequisition: true,
        isMilitary: !!requisitionStateContext.isMilitary,
      };
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: updatedState,
      });
    } else if (
      section === 'Delivery address' &&
      !deliveryStateContext.isDeliverySameAsRequisition
    ) {
      const updatedState = {
        ...requisitionStateContext,
        id: 'Delivery address',
        isDeliverySameAsRequisition: true,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: updatedState,
      });
    } else if (
      section === 'Mailing Address' &&
      mailingStateContext.isMailingSameAsRequisition
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_MAILING_STATE,
      });
    } else if (
      section === 'Delivery address' &&
      deliveryStateContext.isDeliverySameAsRequisition
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_DELIVERY_STATE,
      });
    }
  };

  // DOC any field change
  const handleFieldChange = (enteredText, selectedField, sectionId) => {
    if (typeof enteredText !== 'undefined') {
      if (sectionId === 'Requisition Address') {
        const requisitionAddressData = updatecurrentState(
          requisitionStateContext,
          updateDraftRequisition,
          setReqCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
          payload: { id: 'Requisition Address', ...requisitionAddressData },
        });

        if (mailingStateContext.isMailingSameAsRequisition) {
          const updatedState = {
            ...requisitionAddressData,
            id: 'Mailing Address',
            isMailingSameAsRequisition: true,
          };
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
            payload: updatedState,
          });
        }

        if (deliveryStateContext.isDeliverySameAsRequisition) {
          const updatedState = {
            ...requisitionAddressData,
            id: 'Delivery address',
            isDeliverySameAsRequisition: true,
          };

          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
            payload: updatedState,
          });
        }
      } else if (sectionId === 'Mailing Address') {
        const mailingAddressData = updatecurrentState(
          mailingStateContext,
          updateDraftRequisition,
          setMailingCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: { ...mailingAddressData, id: 'Mailing Address' },
        });
      } else if (sectionId === 'Delivery address') {
        const deliveryAddressData = updatecurrentState(
          deliveryStateContext,
          updateDraftRequisition,
          setDeliveryCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: { ...deliveryAddressData, id: 'Delivery address' },
        });
      }
    }
  };

  const handleDealershipDeliveryAddressChange = (dealership) => {
    if (dealership) {
      const { id, ...modifiedDealership } = dealership;
      modifiedDealership.dealershipId = id;
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DEALERSHIP_DELIVERY,
        payload: modifiedDealership,
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: {
          ...deliveryStateContext,
          id: 'Delivery address',
          deliveryDealership: modifiedDealership,
        },
      });
    } else {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DEALERSHIP_DELIVERY,
        payload: {},
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: {
          ...deliveryStateContext,
          id: 'Delivery address',
          deliveryDealership: {},
        },
      });
    }
  };

  // DOC stubbed
  const handleOnBlur = () => {
    if (requisitionDraftId) {
      // updateDraftRequisition(requisitionDraftId);
    }
  };

  const [visibilityRequisition, setVisibilityRequisition] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackTextRequisition, setFeedbackTextRequisition] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const handleRequisitionErrors = (errors) => {
    setVisibilityRequisition({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityRequisition,
    });
    setFeedbackTextRequisition({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextRequisition,
    });
  };

  useEffect(() => {
    if (
      requisitionAddressErrorContext &&
      requisitionAddressErrorContext.length > 0
    ) {
      const errors = {
        visibilityRequisition: requisitionAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextRequisition: requisitionAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };

      handleRequisitionErrors(errors);
    } else {
      handleRequisitionErrors([]);
    }
  }, [requisitionAddressErrorContext]);

  const [visibilityMailing, setVisibilityMailing] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackTextMailing, setFeedbackTextMailing] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const handleMailingErrors = (errors) => {
    setVisibilityMailing({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityMailing,
    });
    setFeedbackTextMailing({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextMailing,
    });
  };

  useEffect(() => {
    if (mailingAddressErrorContext && mailingAddressErrorContext.length > 0) {
      const errors = {
        visibilityMailing: mailingAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextMailing: mailingAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };

      handleMailingErrors(errors);
    } else {
      handleMailingErrors([]);
    }
  }, [mailingAddressErrorContext]);

  const [visibilityDelivery, setVisibilityDelivery] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackTextDelivery, setFeedbackTextDelivery] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const [focus, setFocus] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const handleDeliveryErrors = (errors) => {
    setVisibilityDelivery({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityDelivery,
    });
    setFeedbackTextDelivery({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextDelivery,
    });
  };

  useEffect(() => {
    if (deliveryAddressErrorContext && deliveryAddressErrorContext.length > 0) {
      const errors = {
        visibilityDelivery: deliveryAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextDelivery: deliveryAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };
      handleDeliveryErrors(errors);
    } else {
      handleDeliveryErrors([]);
    }
  }, [deliveryAddressErrorContext]);

  const handleInputValidations = (
    e,
    element,
    focused = null,
    sectionId = '',
  ) => {
    const elementValue = e && e.target ? e.target.value : e;

    const isFocused = focused === null ? focus[element] : focused;

    setFocus({ [element]: isFocused });

    if (sectionId === 'Requisition Address') {
      const updatedReqState = {
        ...requisitionStateContext,
        [element]: elementValue,
      };

      if (element === 'rankAndFullName') {
        updatedReqState.entityName = elementValue;
      }

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
        payload: updatedReqState,
      });

      if (mailingStateContext?.isMailingSameAsRequisition) {
        const updatedState = {
          ...requisitionStateContext,
          [element]: elementValue,
          id: 'Mailing Address',
          isMailingSameAsRequisition: true,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: updatedState,
        });
      }

      if (deliveryStateContext.isDeliverySameAsRequisition) {
        const updatedState = {
          ...requisitionStateContext,
          [element]: elementValue,
          id: 'Delivery address',
          isDeliverySameAsRequisition: true,
        };

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: updatedState,
        });
      }
    } else if (sectionId === 'Mailing Address') {
      const updatedMailingState = {
        ...mailingStateContext,
        [element]: elementValue,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: updatedMailingState,
      });
    } else if (sectionId === 'Delivery address') {
      const updatedDeliveryState = {
        ...deliveryStateContext,
        [element]: elementValue,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: updatedDeliveryState,
      });
    }
  };

  const handleAddressedChange = (selected, selectedType) => {
    const data = uspsModalOptionsSelectedState;
    data.forEach((li) => {
      const item = li;
      if (selected === 'USPS' && selectedType === item.id && !item.isChecked) {
        item.isChecked = true;
        item.selected = true;
      } else if (selected === 'ENTERED' && selectedType === item.id) {
        item.isChecked = false;
        item.selected = true;
      }
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USPS_MODAL_OPTIONS_SELECTED_STATE,
      payload: data,
    });

    if (
      selectedType === 'Requisition Address' &&
      !requisitionStateContext.isMilitary
    ) {
      const returned = validateSelection(selected, reqValidatedAddress);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS,
        payload: returned,
      });
    }

    if (selectedType === 'Mailing Address' && !mailingStateContext.isMilitary) {
      const returned = validateSelection(selected, mailingValidatedAddress);
      if (returned?.address?.length > 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_VALIDATED_ADDRESS,
          payload: returned,
        });
      }
    }

    if (selectedType === 'Delivery address') {
      const returned = validateSelection(selected, deliveryValidatedAddress);
      if (returned?.address?.length > 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_VALIDATED_ADDRESS,
          payload: returned,
        });
      }
    }
  };

  const handleSaveClick = (
    actionType,
    isFromModal,
    preceedWithoutValidation = false,
  ) => {
    if (validatedAddressNotFound.length && !preceedWithoutValidation) {
      notFoundAddressesTo(validatedAddressNotFound?.join(', '));
      setShowUspsModal(false);
      return showConfirmationModalTo(true);
    }

    uspsModalOptionsSelectedState.forEach((item) => {
      if (item.id === 'Requisition Address' && item.isChecked) {
        const updatedState = {
          ...requisitionStateContext,
          addressLine1: requisitionStateContext.isMilitary
            ? requisitionStateContext.militaryOrDiplomaticAddress
            : reqValidatedAddress.address,
          city: reqValidatedAddress.city,
          stateCode: reqValidatedAddress.state
            ? `US-${reqValidatedAddress?.state}`
            : '',
          zipcode: requisitionStateContext.isMilitary
            ? requisitionStateContext.zipcode
            : reqValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
          payload: updatedState,
        });
        if (mailingStateContext.isMailingSameAsRequisition) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
            payload: updatedState,
          });
        }
        if (deliveryStateContext.isDeliverySameAsRequisition) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
            payload: {
              ...updatedState,
              deliveryDealership: deliveryStateContext?.deliveryDealership,
            },
          });
        }
      } else if (item.id === 'Mailing Address' && item.isChecked) {
        const updatedState = {
          ...mailingStateContext,
          addressLine1: mailingStateContext.isMilitary
            ? mailingStateContext.militaryOrDiplomaticAddress
            : mailingValidatedAddress.address,
          city: mailingValidatedAddress.city,
          stateCode: mailingValidatedAddress.state
            ? `US-${mailingValidatedAddress.state}`
            : '',
          zipcode: mailingStateContext.isMilitary
            ? mailingStateContext.zipcode
            : mailingValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: updatedState,
        });
      } else if (item.id === 'Delivery address' && item.isChecked) {
        const updatedState = {
          ...deliveryStateContext,
          addressLine1: deliveryValidatedAddress.address,
          city: deliveryValidatedAddress.city,
          stateCode: deliveryValidatedAddress.state
            ? `US-${deliveryValidatedAddress.state}`
            : '',
          zipcode: deliveryValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: updatedState,
        });
      }
    });

    // eslint-disable-next-line no-unused-expressions
    // !uspsModalOptionsSelectedState.some(({ selected }) => !selected) &&
    return handlePageNavigation(actionType, isFromModal);
  };

  const closeModal = () => {
    setShowUspsModal(false);
  };

  const closeConfirmationModal = () => {
    showConfirmationModalTo(false);
  };

  useEffect(() => {
    showConfirmationModalTo(
      Array.isArray(validatedAddressNotFound) &&
        validatedAddressNotFound.length === 3,
    );
    notFoundAddressesTo(validatedAddressNotFound?.join(', '));
  }, [validatedAddressNotFound]);

  const isDeliveryAddressValidated =
    deliveryValidatedAddress?.address?.length > 0;
  const isReqAddressValidated = reqValidatedAddress?.address?.length > 0;
  const isMailingAddressValidated =
    mailingValidatedAddress?.address?.length > 0;

  return (
    <>
      <div className="delivery-address">
        <div role="tab" tabIndex="0">
          <h2 className="usa-h2">
            Provide Delivery Address and Dealership Information
          </h2>
          <p>
            Please provide information for the following addresses. Previously
            entered address suggestions will appear as you start to type below.
          </p>
        </div>

        {fetchCountriesData && fetchCountriesCallingCode && (
          <>
            <AddressComponent
              section={requisitionSection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={requisitionStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={requisitionStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityRequisition}
              feedbackText={feedbackTextRequisition}
              handleOnBlur={handleOnBlur}
            />

            <AddressComponent
              section={mailingSection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={mailingStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={mailingStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityMailing}
              feedbackText={feedbackTextMailing}
              handleOnBlur={handleOnBlur}
            />

            <AddressComponent
              section={deliverySection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={deliveryStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={deliveryStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityDelivery}
              feedbackText={feedbackTextDelivery}
              handleOnBlur={handleOnBlur}
              deliveryAddressOption={deliveryAddressOption}
              standardItemCodeFASTDescription={standardItemCodeFASTDescription}
              selectedContract={selectedContract}
              handleStepper={handleStepper}
              handleDealershipDeliveryAddressChange={
                handleDealershipDeliveryAddressChange
              }
            />
          </>
        )}

        {(loadingValidatedRequisitionAddress ||
          loadingValidatedDeliveryAddress ||
          loadingValidatedMailingAddress ||
          !fetchCountriesData ||
          !fetchCountriesCallingCode) && (
          <div className="afp-modal-overlay requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}

        {showUspsModal &&
          (!_.isEmpty(reqValidatedAddress) ||
            !_.isEmpty(mailingValidatedAddress) ||
            !_.isEmpty(deliveryValidatedAddress)) && (
            <>
              <div
                role="dialog"
                aria-modal="true"
                className="afp-modal-overlay modalContainer"
              >
                <Modal
                  title={
                    <>
                      <h1>Suggested addresses</h1>
                      <span>
                        You&apos;ve entered new addresse on this page. Please
                        confirm desired address formats <br />
                        before continuing*
                      </span>
                    </>
                  }
                  className="usps-validation-modal"
                  onClose={closeModal}
                  actions={
                    <>
                      <Button
                        type="button"
                        className="cancel-button"
                        onClick={closeModal}
                        label="Cancel"
                      />
                      <Button
                        id="modal-save-button"
                        type="button"
                        data-testid="modal-save-button"
                        onClick={() => {
                          handleSaveClick('continue', 'true');
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13)
                            handleSaveClick('continue', 'true');
                        }}
                        label="Save and continue"
                      />
                    </>
                  }
                >
                  {!requisitionStateContext.isMilitary &&
                    isReqAddressValidated && (
                      <UspsAddressedComp
                        title="REQUISITION ADDRESS"
                        addressAsEntered={requisitionStateContext}
                        uspsVerifiedAddress={reqValidatedAddress}
                        handleAddressedChange={handleAddressedChange}
                      />
                    )}
                  {!mailingStateContext.isMilitary &&
                    isMailingAddressValidated && (
                      <UspsAddressedComp
                        title="MAILING ADDRESS"
                        addressAsEntered={mailingStateContext}
                        uspsVerifiedAddress={mailingValidatedAddress}
                        handleAddressedChange={handleAddressedChange}
                        isSameAsRequisition={
                          mailingStateContext?.isMailingSameAsRequisition
                        }
                      />
                    )}
                  {isDeliveryAddressValidated && (
                    <UspsAddressedComp
                      title="DELIVERY ADDRESS"
                      addressAsEntered={deliveryStateContext}
                      uspsVerifiedAddress={deliveryValidatedAddress}
                      handleAddressedChange={handleAddressedChange}
                      isSameAsRequisition={
                        deliveryStateContext?.isDeliverySameAsRequisition
                      }
                    />
                  )}
                </Modal>
              </div>
            </>
          )}

        {showConfirmationModal && (
          <>
            <div className="afp-modal-overlay modalContainer">
              <Modal
                title={
                  <>
                    <h1>Address Not Found</h1>
                  </>
                }
                onClose={closeConfirmationModal}
                actions={
                  <>
                    <Button
                      type="button"
                      className="cancel-button"
                      onClick={closeConfirmationModal}
                      label="Cancel"
                    />
                    <Button
                      id="modal-save-button"
                      type="button"
                      data-testid="modal-save-button"
                      onClick={() => {
                        handleSaveClick('continue', 'true', true);
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13)
                          handleSaveClick('continue', 'true', true);
                      }}
                      label="Save and continue"
                    />
                  </>
                }
              >
                <span>
                  {`${notFoundAddresses} Address not verified in the USPS database. By selecting to
                  continue with this address, you may incur additional time and
                  effort in receiving your vehicle or documents.`}
                </span>
              </Modal>
            </div>
          </>
        )}
      </div>
    </>
  );
};

DeliveryAddress.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  requisitionDraftId: PropTypes.string,
  handleStepper: PropTypes.func,
};

DeliveryAddress.defaultProps = {
  requisitionDraftId: null,
  handleStepper: () => {},
};

export default DeliveryAddress;
