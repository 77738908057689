import React from 'react';
import './ReviewSummaryEngineerApprovalHeader.scss';

const ReviewSummaryEngineerApprovalHeader = () => (
  <div
    data-testid="review-summary-engineer-approval-header"
    className="review-summary-engineer-approval-header"
  >
    <div className="title">Engineer&apos;s tasks:</div>
    <ol>
      <li className="task">
        Please review all information below established by the customer
      </li>
      <li className="task">
        Review vendor bids provided by the contracting officer, and provide
        technical acceptance
      </li>
      <li className="task">
        Submit requisition to GSA contracting review, or return this requisition
        to GSA contracting if it contains issues{' '}
      </li>
    </ol>
  </div>
);

export default ReviewSummaryEngineerApprovalHeader;
