import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tag } from '@gsa/afp-component-library';
import { ReceiptStatus } from '../constants/vehicle-constants';
import { emDashUnicode } from '../../../constants/constants';

const ReceiptStatusTag = ({ original }) => {
  const { deliveryDate, statusCode } = original;

  const getStatus = () => {
    const daysDifference = moment(moment()).diff(deliveryDate, 'days');
    return daysDifference >= 10
      ? ReceiptStatus.DELINQUENT
      : ReceiptStatus.PENDING;
  };

  if (original?.receivedAt) return null;

  return deliveryDate && statusCode === 'DD' ? (
    <Tag variant={getStatus().COLOR}>{getStatus().LABEL}</Tag>
  ) : (
    <span>{emDashUnicode}</span>
  );
};

ReceiptStatusTag.propTypes = {
  original: PropTypes.shape(Object).isRequired,
};

export default ReceiptStatusTag;
