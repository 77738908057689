import React from 'react';
import PropTypes from 'prop-types';

const DisplayRequisitionMessageLines = ({ messageLines }) => {
  return (
    <div className="margin-bottom-2">
      <div className="flex-column">
        <div className="flex-row usa-label">Line 1</div>
        <div
          className="flex-row font-body-md"
          key={messageLines?.messageLine1 ?? 'rml1'}
        >
          {messageLines?.messageLine1 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row usa-label">Line 2</div>
        <div
          className="flex-row font-body-md"
          key={messageLines?.messageLine2 ?? 'rml2'}
        >
          {messageLines?.messageLine2 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row usa-label">Line 3</div>
        <div
          className="flex-row font-body-md"
          key={messageLines?.messageLine3 ?? 'rml3'}
        >
          {messageLines?.messageLine3 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row usa-label">Line 4</div>
        <div
          className="flex-row font-body-md"
          key={messageLines?.messageLine4 ?? 'rml4'}
        >
          {messageLines?.messageLine4 ?? <span>&ndash;</span>}
        </div>
      </div>

      <div className="flex-column">
        <div className="flex-row usa-label">Line 5</div>
        <div
          className="flex-row font-body-md"
          key={messageLines?.messageLine5 ?? 'rml5'}
        >
          {messageLines?.messageLine5 ?? <span>&ndash;</span>}
        </div>
      </div>
    </div>
  );
};

DisplayRequisitionMessageLines.propTypes = {
  messageLines: PropTypes.shape({
    messageLine1: PropTypes.string,
    messageLine2: PropTypes.string,
    messageLine3: PropTypes.string,
    messageLine4: PropTypes.string,
    messageLine5: PropTypes.string,
  }),
};

DisplayRequisitionMessageLines.defaultProps = {
  messageLines: {
    messageLine1: null,
    messageLine2: null,
    messageLine3: null,
    messageLine4: null,
    messageLine5: null,
  },
};
export default DisplayRequisitionMessageLines;
