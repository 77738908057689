import React, { useState, useMemo } from 'react';
import { Accordion, ComboBox, Label, Alert } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import useBulkOrderModsState from '../useBulkOrderModsState';
import { orderChangesFeedback } from '../UpdateOrderModHelpers';
import {
  GET_CONTRACT_LINE_MODEL_COLORS,
  GET_STANDARD_CODES,
} from '../../../services/data-layer';
import { REQUISITION_TYPE } from '../../non-standard-purchase/constants';
import './changeSets.scss';
import {TRANSACTION_TYPES} from "../../../utilities/CompareVehicleUtils.jsx";

const transformRespToOptions = (data, type) => {
  const list = [];
  if (type === 'non-sop') {
    data.forEach((i) => {
      list.push({
        label: i.title,
        value: i.code,
      });
    });
  } else {
    data.forEach((i) => {
      list.push({
        label: i.description,
        value: i.colorCode,
      });
    });
  }
  return list;
};

const feedback = orderChangesFeedback;
export default function ColorChanges() {
  const { selectedOrdersForMod, orderChanges, setOrderChanges } =
    useBulkOrderModsState();
  const [availableColors, setAvailableColors] = useState([]);
  const NON_STANDARD_REQ_TYPES = [
    REQUISITION_TYPE.NON_STANDARD_VEHICLE,
    REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES,
    REQUISITION_TYPE.URGENT_REQUIREMENT,
  ];

  const STANDARD_REQ_TYPES = [
    REQUISITION_TYPE.STANDARD_ORDER,
    REQUISITION_TYPE.AREQ,
  ];

  const [getNonStandardGenericColors] = useLazyQuery(GET_STANDARD_CODES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const colors = data?.getStandardCodes?.rows;
      if (colors.length > 0) {
        const availableColorsData = transformRespToOptions(colors, 'non-sop');
        setAvailableColors(availableColorsData);
      }
    },
  });

  const [getModelStandardItemColors] = useLazyQuery(
    GET_CONTRACT_LINE_MODEL_COLORS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const colors = data?.storeGetContractLineColors;
        if (colors.length > 0) {
          const availableColorsData = transformRespToOptions(colors);
          setAvailableColors(availableColorsData);
        }
      },
    },
  );

  const [focus, setFocus] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const sameContractLine = useMemo(() => {
    let ordersBelongsToSameContract = true;
    let ordersOfSameRequisitionType = true;

    if (selectedOrdersForMod?.length > 0) {
      const firstContractLineId = selectedOrdersForMod[0]?.contractLineId;
      const firstRequisitionType = selectedOrdersForMod[0]?.requisitionType;
      selectedOrdersForMod.forEach((order) => {
        if (order?.contractLineId !== firstContractLineId) {
          ordersBelongsToSameContract = false;
        }
        if (order?.requisitionType !== firstRequisitionType) {
          ordersOfSameRequisitionType = false;
        }
      });

      if (ordersBelongsToSameContract && firstContractLineId) {
        if (
          STANDARD_REQ_TYPES.some((value) => firstRequisitionType === value) &&
          ordersOfSameRequisitionType
        ) {
          getModelStandardItemColors({
            variables: {
              contractLineId: parseInt(firstContractLineId, 10),
              transactionType: TRANSACTION_TYPES.PURCHASING,
            },
          });
        }
        if (
          NON_STANDARD_REQ_TYPES.some((value) => firstRequisitionType === value)
        ) {
          getNonStandardGenericColors({
            variables: {
              filters: {
                operator: 'EQ',
                key: 'code_metadata_id',
                value: 1,
              },
            },
          });
        }
      } else {
        ordersBelongsToSameContract = false;
        setAvailableColors([]);
      }
    }

    return ordersBelongsToSameContract;
  }, [selectedOrdersForMod]);

  function updateValue(e, element, focused = null) {
    if (!e || !e.target) return false;
    const isFocused = focused === null ? focus[element] : focused;
    setFocus({ [element]: isFocused });
    const numberInputs = [];
    let newValues = {
      ...orderChanges,
      // eslint-disable-next-line radix
      [element]: numberInputs.includes(element)
        ? parseInt(e.target.value, 10)
        : e.target.value,
    };

    if (element === 'makeColorCode') {
      const selectedColor = availableColors.filter(
        (color) => color.value === e.target.value,
      );
      const makeColorName = selectedColor ? selectedColor[0]?.label : '';
      newValues = {
        ...newValues,
        [element]: e.target.value ?? '',
        makeColorName,
      };
    }
    setOrderChanges((prev) => ({
      ...prev,
      ...newValues,
    }));
    return true;
  }

  const getOrderChangeContent = () => {
    return [
      {
        id: 'colorChange',
        title: (
          <div
            className="add-options-accordion-title"
            data-testid="order-change-title"
          >
            Color
          </div>
        ),
        content: (
          <div className="ordermod-accordion-container">
            <div className="info-header">
              <label className="info-label" htmlFor="agencyInfo">
                Color changes
              </label>
            </div>

            {!sameContractLine ? (
              <Alert type="warning">
                Orders from different contract lines are selected. Color changes
                can only occur when one contract line is selected. Please change
                order selection from the same contract line.
              </Alert>
            ) : (
              <div className="grid-row grid-gap-1">
                <div className="grid-col-6">
                  <Label htmlFor="address-label" className="margin-top-0">
                    Select color
                  </Label>
                  {availableColors.length > 0 ? (
                    <ComboBox
                      data-testid="order-mod-select-color"
                      key={orderChanges?.makeColorCode}
                      id="color-selection-dropdown"
                      name="color-selection-dropdown"
                      options={availableColors}
                      defaultValue={orderChanges?.makeColorCode ?? ''}
                      onChange={(e) => {
                        updateValue(
                          { target: { value: e } },
                          'makeColorCode',
                          true,
                        );
                      }}
                    />
                  ) : (
                    <ComboBox
                      data-testid="order-mod-select-color-disabled"
                      key="combo-box-color-disabled"
                      options={[]}
                      defaultValue=""
                      onChange={() => {}}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ),
        expanded: false,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getOrderChangeContent()}
        className="add-options-accordion"
      />
    </>
  );
}
