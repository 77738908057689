import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_ORDER_MOD_BY_PARTIAL_NAME } from '../../../services/data-layer';

const OrderModFilterContext = createContext({});
const useOrderModFilter = () => useContext(OrderModFilterContext);

const initialState = {
  error: '',
  filters: [],
  orderModsByPartialName: [],
  typeaheadData: { field: '', values: [] },
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setTypeaheadData: 'SET_TYPEAHEAD_DATA',
  setOrderModsByPartialName: 'SET_ORDER_MODS_BY_PARTIAL_NAME',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const mapTypeaheadData = (field, values) => {
  return {
    field,
    values,
  };
};
const orderModFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setFilters: {
      return mergeState(
        {
          operator: '$and',
          value: payload || [],
        },
        'filters',
      );
    }
    case actions.setError: {
      return mergeState(extractErrorMessage(payload), 'error');
    }
    case actions.setOrderModsByPartialName: {
      return mergeState({
        orderModsByPartialName: payload,
        typeaheadData: mapTypeaheadData(
          'orderModName',
          payload.map((c) => c.orderModName),
        ),
      });
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const OrderModFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    orderModFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });
  const dispatchError = (error) => dispatch(actions.setError, error);

  const dispatchFilters = (value) => dispatch(actions.setFilters, value);

  const [getAllOrderModByNamesQuery] = useLazyQuery(
    GET_ORDER_MOD_BY_PARTIAL_NAME,
    {
      onError: dispatchError,
      onCompleted: (response) => {
        dispatch(actions.setOrderModsByPartialName, {
          orderModNames:
            response?.getDistinctOrderModNames?.orderModNames || [],
        });
      },
    },
  );

  const getOrderModsByPartialName = (partialModName) => {
    getAllOrderModByNamesQuery({
      variables: { partialModName },
    });
  };
  return (
    <OrderModFilterContext.Provider
      value={{
        ...state,
        ...props,
        setFilters: dispatchFilters,
        getOrderModsByPartialName,
      }}
    >
      {children}
    </OrderModFilterContext.Provider>
  );
};

OrderModFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrderModFilterProvider as default, useOrderModFilter };
