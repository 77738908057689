import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import './OrderAdminApprovalActionButtons.scss';
import PlaceOrderModal from '../../pages/ReviewDetails/PlaceOrderModal';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import CancelRequisitionButton from '../../pages/ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';
import { UserRoles } from '../../constants/user-constants';

const OrderAdminApprovalActionButtons = ({
  requisitionId,
  requisitionCartState,
  reference,
  requisitionCartValidations,
  canSubmitOrder,
}) => {
  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );

  const { selectedVendorQuote } = useContext(VehicleRequisitionContext);

  const { requisitionStatus } = requisitionCartState;
  const inOrderAdminApprovalStatus =
    requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL;

  const orderingAdminApprovalStatus =
    isOrderingAdmin && inOrderAdminApprovalStatus;

  if (!orderingAdminApprovalStatus) {
    return null;
  }

  return (
    <>
      <CancelRequisitionButton requisitionId={requisitionId} />
      {selectedVendorQuote && (
        <>
          {isOrderingAdmin && (
            <>
              <PlaceOrderModal
                reference={reference}
                requisitionStatus={requisitionStatus}
                requisitionId={requisitionId}
                requisitionName={requisitionCartState.friendlyName}
                requisitionCartValidations={requisitionCartValidations}
                canSubmitOrder={canSubmitOrder}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

OrderAdminApprovalActionButtons.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
};

export default OrderAdminApprovalActionButtons;
