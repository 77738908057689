import React, { useMemo, useState } from 'react';
import { Alert, Button } from '@gsa/afp-component-library';

const SUCCESS_PROPS = {
  type: 'success',
  slim: true,
};

const ERROR_PROPS = {
  type: 'error',
  slim: true,
};

const WARN_PROPS = {
  type: 'warning',
  slim: true,
};

export default function alertManager(
  closeBtn = true,
  focus = false,
  newCloseButtonStyle = false,
  classes = 'show-import-alert',
) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({});
  const [alertContent, setAlertContent] = useState(null);

  const closeAlert = () => {
    setAlertProps({});
    setAlertContent(null);
    setShowAlert(false);
  };

  const showSuccessAlert = (content, heading) => {
    setAlertProps({ ...SUCCESS_PROPS, heading });
    setAlertContent(content);
    setShowAlert(true);
  };

  const showErrorAlert = (content, heading) => {
    setAlertProps({ ...ERROR_PROPS, heading });
    setAlertContent(content);
    setShowAlert(true);
  };

  const showWarningAlert = (content, heading) => {
    setAlertProps({ ...WARN_PROPS, heading });
    setAlertContent(content);
    setShowAlert(true);
  };

  const AlertCmp = useMemo(
    () => () => {
      const closeAction = () => {
        if (closeBtn) {
          if (newCloseButtonStyle) {
            return alertProps?.type === 'error' ? null : (
              <div className="clearfix">
                <Button
                  className="usa-button--unstyled"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 8,
                    color: 'black',
                  }}
                  onClick={closeAlert}
                  rightIcon={{
                    name: 'close',
                  }}
                />
              </div>
            );
          }
          return (
            <Button
              variant="unstyled"
              className="margin-x-1"
              aria-label="close"
              style={{ color: 'black' }}
              onClick={closeAlert}
              rightIcon={{ name: 'close', className: 'usa-icon--size-4' }}
            />
          );
        }
        return null;
      };
      return showAlert ? (
        <Alert
          focused={focus}
          actions={closeAction()}
          className={classes}
          {...alertProps}
        >
          {alertContent}
        </Alert>
      ) : null;
    },
    [showAlert, alertContent],
  );

  return [
    AlertCmp,
    { showSuccessAlert, showErrorAlert, showWarningAlert, closeAlert },
  ];
}
