import React, { useEffect, useState, useContext } from 'react';
import { Alert, StepIndicator, TabSet } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';

import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import StepDetailsComponent from './stepDetails/stepDetails';
import './MasRequisition.scss';
import { AVAILABLE_EDIT_STEPS, REQUISITION_TYPE } from '../constants';
import { useQueryParam } from '../../../hooks/useQueryParam';
import RequisitionActivitiesTab from '../../ReviewDetails/tabs/RequisitionActivitiesTab';
import AttachmentTable from '../../ReviewDetails/Attachment/AttachmentTable/AttachmentTable';
import RequisitionStatusBadge from '../../../utilities/RequisitionStatusBadge';
import { RequisitionStatus } from '../../ReviewDetails/RequisitionDetailsUtils';

const UrgentRequisitionSteps = () => {
  const {
    dispatch,
    state,
    updateRequisitionClientState,
    updateDraftRequisition,
  } = useContext(VehicleRequisitionContext);
  const {
    masRequirementCurrentStep,
    masReqStepsProcessIndicator,
    masReqErrorBanner,
    draftRequisition,
  } = state;
  const navigate = useNavigate();
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const currentTab = 'Review summary';
  const [tab, setTab] = useState('summary');
  const isRequisitionCanceled =
    draftRequisition?.requisitionStatus === RequisitionStatus.CANCELLED;

  const handleStepper = (nextStep, indicatorSteps) => {
    const isIndex = Number.isInteger(nextStep);
    const currentStep =
      masRequirementCurrentStep?.stepNumber ||
      masRequirementCurrentStep.current;
    const nextStepIndex = isIndex
      ? nextStep
      : indicatorSteps.findIndex(({ key }) => key === nextStep);
    const newStep = isIndex
      ? indicatorSteps[nextStepIndex]
      : indicatorSteps.find(({ key }) => key === nextStep);

    const currentIsIndex = Number.isInteger(currentStep);
    const currentStepIndex = currentIsIndex
      ? currentStep
      : indicatorSteps.findIndex(
          ({ stepNumber }) => stepNumber === currentStep.stepNumber,
        );
    const goingBack = nextStepIndex < currentStepIndex;

    if (newStep.stepNumber) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_CURRENT_STEP,
        payload: {
          key: newStep?.key,
          current: newStep?.stepNumber,
          stepNumber: newStep?.stepNumber,
          text: newStep?.label,
        },
      });
    }

    const updatedIndicatorSteps = indicatorSteps;
    for (let i = 0; i < updatedIndicatorSteps.length; i++) {
      if (i < nextStepIndex) {
        updatedIndicatorSteps[i].status = 'completed';
      } else if (i === nextStepIndex) {
        updatedIndicatorSteps[i].status = 'current';
      } else {
        updatedIndicatorSteps[i].status = 'not completed';
      }
    }

    const nonEditSteps = ['REVIEW_SUBMIT'];
    if (
      draftRequisition?.requisitionId &&
      !nonEditSteps.includes(currentStep?.key) &&
      !goingBack &&
      currentStep.stepNumber !== newStep.stepNumber
    ) {
      updateDraftRequisition(undefined, undefined, { mas: newStep });
    } else if (
      newStep.stepNumber !== currentStep.stepNumber &&
      draftRequisition
    ) {
      const clientState = { ...draftRequisition.clientData.clientState };
      clientState.masRequirement.masRequirementCurrentStep = newStep;

      updateRequisitionClientState({
        variables: {
          clientState,
          requisitionId: draftRequisition.requisitionId,
        },
      });
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_STEPS_INDICATOR,
      payload: updatedIndicatorSteps,
    });
  };

  const handlePageNavigation = (direction) => {
    if (direction === 'continue') {
      const currentStepIndex = masReqStepsProcessIndicator.findIndex(
        ({ stepNumber }) =>
          stepNumber ===
          (masRequirementCurrentStep?.stepNumber ||
            masRequirementCurrentStep?.current ||
            0),
      );

      handleStepper(currentStepIndex + 1, masReqStepsProcessIndicator);
    } else if (
      direction === AVAILABLE_EDIT_STEPS.find((step) => step === direction)
    ) {
      const step = direction;
      handleStepper(step - 1, masReqStepsProcessIndicator);
    } else {
      const currentStepIndex = masReqStepsProcessIndicator.findIndex(
        (step) =>
          step.stepNumber ===
          (masRequirementCurrentStep?.stepNumber ||
            masRequirementCurrentStep.current),
      );
      const nextStepIndex = currentStepIndex - 1;
      if (nextStepIndex >= 0) {
        handleStepper(nextStepIndex, masReqStepsProcessIndicator);
      } else {
        navigate('/mas-requisition');
        window.location.reload(false);
      }
    }
  };

  const [key, setKey] = useState();
  useEffect(() => {
    setKey(masRequirementCurrentStep?.key);
  }, [masRequirementCurrentStep?.key]);

  const renderWithTabs = () => {
    const checkRenderComponents =
      draftRequisition?.requisitionStatus === 'RETURNED'
        ? [
            {
              heading: 'Review summary',
              tabSelectedWhenOpen:
                tab === 'summary' && currentTab !== 'activities',
              content: (
                <div className="urgent-req-steps">
                  <div className="mas-right-panel">
                    <StepDetailsComponent
                      step={`${key}`}
                      handlePageNavigation={handlePageNavigation}
                    />
                  </div>
                </div>
              ),
            },
            {
              heading: 'Activity',
              tabSelectedWhenOpen:
                currentTab === 'activities' || tab === 'activities',
              content: (
                <RequisitionActivitiesTab requisitionId={requisitionId} />
              ),
            },
            {
              heading: 'Attachments',
              tabSelectedWhenOpen: tab === 'attachments',
              content: (
                <AttachmentTable
                  requisitionId={requisitionId}
                  setTab={setTab}
                  uploadAttachment
                  reqType={REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES}
                />
              ),
            },
          ]
        : [
            {
              heading: 'Review summary',
              content: (
                <div className="urgent-req-steps">
                  <div className="mas-right-panel">
                    <StepDetailsComponent
                      step={`${key}`}
                      handlePageNavigation={handlePageNavigation}
                    />
                  </div>
                </div>
              ),
            },
            {
              heading: 'Attachments',
              content: (
                <AttachmentTable
                  requisitionId={requisitionId}
                  setTab={setTab}
                  uploadAttachment
                  reqType={REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES}
                />
              ),
            },
          ];
    return (
      <>
        <div className="requisition-details-tabs">
          <TabSet tabs={checkRenderComponents} />
        </div>
      </>
    );
  };

  return (
    <div>
      {masReqErrorBanner && (
        <Alert slim type="error">
          <div className="error-banner-title">This Page contains errors</div>
          Please address all fields outlined in red before proceeding to the
          next step.
        </Alert>
      )}
      {isRequisitionCanceled && (
        <Alert slim type="error">
          <div className="error-banner-title">
            We&apos;re sorry, this requisition has been canceled
          </div>
          The required deadline to re-submit this requisition to GSA contracting
          has expired.
        </Alert>
      )}
      {draftRequisition?.requisitionId && (
        <div className="draft-section">
          <div className="urg-req-draft-title-section">
            <span className="draft-title">{draftRequisition.friendlyName}</span>
            <RequisitionStatusBadge
              value={draftRequisition?.requisitionStatus}
            />
          </div>

          <div>
            Your edits are saved on clicking next button on each step
            {/* Your latest edits are */}
            <span className="save-draft-requisition-post-save">
              <GiCheckMark />
            </span>
          </div>
        </div>
      )}
      <StepIndicator
        className="non-sop-step-indicator"
        counterSize="big"
        heading={{
          ...masRequirementCurrentStep,
          current: masRequirementCurrentStep?.stepNumber,
        }}
        onClick={() => {}}
        steps={masReqStepsProcessIndicator}
      />
      {masRequirementCurrentStep?.stepNumber === 4 ? (
        renderWithTabs()
      ) : (
        <div className="urgent-req-steps">
          <div className="mas-right-panel">
            <StepDetailsComponent
              step={`${key}`}
              handlePageNavigation={handlePageNavigation}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UrgentRequisitionSteps;
