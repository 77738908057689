import { RequisitionStatus } from '@/pages/ReviewDetails/RequisitionDetailsUtils.jsx';
import { OPTION_QUANTITY_REQUIRED_CODE } from '../../utilities/CompareVehicleUtils';

export const LeasingSteps = Object.freeze({
  BEGIN: 'lease',
  REPLACEMENT_VEHICLES: 'select-replacement-vehicles',
  DESTINATION_AND_VEHICLE_TYPE: 'destination-and-vehicle-type',
  COMPARE_AND_SELECT: 'compare-and-select',
  PAINT_AND_GRAPHICS: 'paint-and-graphics',
  REVIEW_VEHICLE_BUILD: 'review-vehicle-build',
  ORDERING_INFORMATION: 'ordering-information',
  DELIVERY_ADDRESS: 'delivery-address',
  REVIEW_SUBMIT: 'review-submit',
  VEHICLE_REFERRAL: 'vehicle-referral',
});

export const InitialLeasingSteps = Object.freeze([
  {
    stepNumber: 1,
    key: LeasingSteps.REPLACEMENT_VEHICLES,
    label: 'Select replacement vehicles',
    status: 'not completed',
  },
  {
    stepNumber: 2,
    key: LeasingSteps.DESTINATION_AND_VEHICLE_TYPE,
    label: 'Destination and vehicle type',
    status: 'not completed',
  },
  {
    stepNumber: 3,
    key: LeasingSteps.COMPARE_AND_SELECT,
    label: 'Compare and select',
    status: 'not completed',
  },
  {
    stepNumber: 4,
    key: LeasingSteps.PAINT_AND_GRAPHICS,
    label: 'Paint and graphics',
    status: 'not completed',
  },
  {
    stepNumber: 5,
    key: LeasingSteps.REVIEW_VEHICLE_BUILD,
    label: 'Review vehicle build',
    status: 'not completed',
  },
  {
    stepNumber: 6,
    key: LeasingSteps.DELIVERY_ADDRESS,
    label: 'Delivery address',
    status: 'not completed',
  },
  {
    stepNumber: 7,
    key: LeasingSteps.REVIEW_SUBMIT,
    label: 'Review and submit',
    status: 'not completed',
  },
]);

export const options = [
  {
    optionCategoryCode: 'CAT1',
    optionCategoryDescription: 'Category 1',
    options: [
      {
        optionCode: 'OPT1',
        optionDescription: 'Option 1',
        optionType: 'OPTION',
        optionQuantity: 1,
        optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
        optionTotalPrice: 100,
      },
      {
        optionCode: 'OPT2',
        optionDescription: 'Option 2',
        optionType: 'OPTION',
        optionQuantity: 2,
        optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
        optionTotalPrice: 200,
      },
    ],
  },
  {
    optionCategoryCode: 'CAT2',
    optionCategoryDescription: 'Category 2',
    options: [
      {
        optionCode: 'OPT3',
        optionDescription: 'Option 3',
        optionType: 'OPTION',
        optionQuantity: 3,
        optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
        optionTotalPrice: 300,
      },
      {
        optionCode: 'OPT4',
        optionDescription: 'Option 4',
        optionType: 'OPTION',
        optionQuantity: 4,
        optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
        optionTotalPrice: 400,
      },
    ],
  },
];
export const leasingCostSummary = {
  baseMonthlyLeaseRate: 200.0,
  baseMileageRate: 0.136,
  optionalEquipmentRate: 100.0,
  afvIncrementalCost: 0.0,
};
export const selectedContractCostBreakdown = {
  contractModLineIdentifier: 'KA032-000000-210.4  ',
  contractNumber: 'KA032',
  contractLineId: 'c123',
  isUnBuildable: true,
  contractModification: '000000',
  scheduleLine: '210.4  ',
  makeCode: '100035',
  modelCode: 'F450Amb',
  modelYear: 2021,
  quantity: 1,
  perVehicleOptions: [
    {
      optionCode: 'CC',
      optionDescription: 'CREW-CAB, 4 DOOR',
      unitPrice: 4975.56,
      quantity: 1,
      optionTotalPrice: 4975.56,
      optionType: 'COST',
      optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.NONE,
      __typename: 'OptionDetail',
    },
    {
      optionCode: 'CE',
      optionDescription: 'CAB, EXTENDED SYTLE',
      unitPrice: 2977.38,
      quantity: 1,
      optionTotalPrice: 2977.38,
      optionType: 'INCLUDED',
      optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.NONE,
      __typename: 'OptionDetail',
    },
  ],
  perOrderOptions: [
    {
      optionCode: 'PSME',
      optionDescription: 'PARTS AND SERVICE MANUALS -- ELECTRONIC',
      unitPrice: null,
      quantity: null,
      optionTotalPrice: 0,
      optionType: 'NA',
      optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER,
      __typename: 'OptionDetail',
    },
  ],
  perVehicleRequiredOptions: [
    {
      __typename: 'OptionDetail',
      optionCode: 'DAOH',
      optionDescription: 'AIR HORNS, DUAL',
      unitPrice: 2831.52,
      quantity: 1,
      optionTotalPrice: 2831.52,
      optionType: 'INCLUDED',
      optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.NONE,
    },
    {
      __typename: 'OptionDetail',
      optionCode: 'DCAM',
      optionDescription: 'DRIVING CAMERA',
      unitPrice: 2401.08,
      quantity: 1,
      optionTotalPrice: 2401.08,
      optionType: 'COST',
      optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.NONE,
    },
  ],
  basePrice: 155505.12,
  perVehicleOptionsPrice: 7952.9400000000005,
  baseAndOptionsPrice: 163458.06,
  totalVehicleAndOptionsPrice: 163458.06,
  perOrderOptionsPrice: 0,
  totalSellingPrice: 163458.06,
  totalShippingDays: 315,
  baseShippingDays: 315,
  additionalShippingDays: {
    additionalShippingDays: 0,
    optionCode: null,
    optionDescription: null,
    __typename: 'AdditionalShippingDays',
  },
  __typename: 'ModelCostBreakDown',
};
export const toggleViewSelectedOptions = () =>
  console.log('Toggle view selected options');

export const vehicleColors = [
  {
    color: {
      label: 'Red',
      value: 'RED',
    },
    quantity: 1,
  },
  {
    color: {
      label: 'Blue',
      value: 'BLUE',
    },
    quantity: 2,
  },
];

export const paintAndGraphicsOptions = [
  {
    optionCode: 'OPT1',
    optionDescription: 'Option 1',
    optionType: 'OPTION',
    optionQuantity: 1,
    optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
    optionTotalPrice: 100,
  },
  {
    optionCode: 'OPT2',
    optionDescription: 'Option 2',
    optionType: 'OPTION',
    optionQuantity: 2,
    optionQuantityRequired: OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
    optionTotalPrice: 200,
  },
];

export const requisition = {
  requisitionType: 'SOP',
  quantity: 3,
  requisitionStatus: RequisitionStatus.DRAFT,
  unitPriceToCustomer: 5000,
  totalOptionPrice: 1500,
  totalSellingPrice: 17000,
  vehicles: [
    {
      unitPriceToCustomer: 5000,
      totalOptionPrice: 1500,
      totalSellingPrice: 17000,
      vehicleEquipments: [
        {
          totalOptionPrice: 500,
        },
        {
          totalOptionPrice: 300,
        },
      ],
    },
  ],
};

export const requisitionAttachmentTypes = [
  {
    attachmentType: 'req_color_options',
    requisitionAttachmentTypeId: 'COLOR_OPTIONS',
  },
  {
    attachmentType: 'req_purchaseline_equipment',
    requisitionAttachmentTypeId: 1,
  },
];

export const taggedOptions = [];

// Dummy value for isEditable
export const isEditable = true;

export const getAllRequisitionAttachments = [
  {
    name: 'Attachment 1',
    metadataId: 'meta-1',
    description: 'Attachment description 1',
    createdAt: '2024-07-12T08:00:00Z',
    attachmentTypeId: 1,
  },
  {
    name: 'Attachment 2',
    metadataId: 'meta-2',
    description: 'Attachment description 2',
    createdAt: '2024-07-11T08:00:00Z',
    attachmentTypeId: 1,
  },
];

export const calculatedPriceData = {
  purchaseRate: 1.05, // Example purchase rate
  perVehicleOptionsPrice: 500,
  basePrice: 10000,
  totalSellingPrice: 15000,
  perOrderOptionsPrice: 100,
  baseAndOptionsPrice: 10500,
};

export const selectedVendorQuote = {
  totalSellingPrice: 16000,
  areqPriceWithFee: 200,
};

export const contract = {
  vendorName: 'Vendor Inc.',
  modelYear: 2023,
  modelCode: 'XYZ123',
  shipmentDays: 10,
  fuelInfo: {
    convMpgCity: 25,
    convMpgHighway: 30,
    convMpgCombined: 27,
    convGpm: 250,
  },
  attachments: [
    {
      documentMetadata: {
        docStoreUri: 'https://example.com/images',
        name: 'image1.jpg',
      },
    },
    {
      documentMetadata: {
        docStoreUri: 'https://example.com/images',
        name: 'image2.jpg',
      },
    },
  ],
  clarifications: [
    { clarification: 'Clarification 1', sequenceNumber: 1 },
    { clarification: 'Clarification 2', sequenceNumber: 2 },
  ],
};

export const standardItem = {
  id: '2446',
  standardItemNumber: '210',
  title: 'AMBULANCE, TYPE I-AD, CONV. CAB-CHASIS, ADDITIONAL DUTY',
  vehicleType: '51',
  vehicleTypeCode: {
    code: '51',
    title: 'Ambulances',
  },
  tags: {
    value: [],
  },
  contractsInfo: {
    activeContractsCount: 2,
    maxContractDate: '2017-06-01T00:00:00.000Z',
  },
};

export const OCONUSStateCodes = [
  'AA',
  'AE',
  'AP',
  'FM',
  'GE',
  'IT',
  'MH',
  'NU',
  'PW',
  'AK',
  'AS',
  'GU',
  'HI',
  'MP',
  'PR',
  'UM',
  'VI',
];

export const RequisitionViewMode = Object.freeze({
  /**
   * no steppers, no nothing. read. only.
   */
  READONLY: 'readonly',

  /**
   * review/submit page only - no steppers
   */
  REVIEW: 'review',

  /**
   * review/submit page only - no steppers
   */
  RETURNED: 'returned',
  /**
   * review/submit, no steppers, editable sections
   */
  REVIEW_EDIT: 'review-edit',

  /**
   * default
   */
  EDIT: 'edit',
});
