import {
    DetailsTable,
    Icon
} from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';

const AccountDetailsPanel = () => {
    const { account } = useSelector((state) => state.leasingReducer);

    return (
        <div className='account-details'>
            <div className='account-details-header'>
                <Icon iconName='directions_car' size={3} />
                <h4>Account Details</h4>
            </div>
            <DetailsTable
                bordered
                data={[
                    ['Agency', [account.customer?.customerAgency?.id, account.customer?.customerAgency?.name].filter(Boolean).join('-')],
                    ['Bureau', [account.customer?.customerBureau?.id, account.customer?.customerBureau?.name].filter(Boolean).join('-')],
                    ['Office', [account.customer?.customerPhysicalOffice?.officeCode, account.customer?.customerPhysicalOffice?.officeName].filter(Boolean).join('-')],
                    ['Telematics exempt', account.telematicsExempt ? 'Yes' : 'No'],
                    ['Zone', account.fleetManagementCenter?.zone?.name],
                    ['Legacy customer number', account.customer?.legacyCustomerNumber],
                    ['FMC', account.fleetManagementCenter?.name],
                    ['FMC Address',  <div style={{whiteSpace: 'pre-line'}}>
                            {[
                                account.fleetManagementCenter?.address1,
                                account.fleetManagementCenter?.address2,
                                account.fleetManagementCenter?.address3,
                                [account.fleetManagementCenter?.city, account.fleetManagementCenter?.stateCode, account.fleetManagementCenter?.zipCode].filter(Boolean).join(' '),
                                account.fleetManagementCenter?.countryCode,
                            ].filter(Boolean).join('\n')}
                        </div>
                    ],
                    ['Customer account name', account.customer?.accountName],
                    ['Customer account address', <div style={{whiteSpace: 'pre-line'}}>
                            {[
                                account.customer?.address1,
                                account.customer?.address2,
                                [account.customer?.city, account.customer?.postalCode].filter(Boolean).join(' '),
                            ].filter(Boolean).join('\n')}
                        </div>
                    ],
                    ['Primary point of contact', <div style={{whiteSpace: 'pre-line'}}>
                            {[
                                account.primaryPoC?.fullName,
                                account.primaryPoC?.email,
                                [account.primaryPoC?.primaryPhone, account.primaryPoC?.primaryPhoneExt ? `ext.${account.primaryPoC?.primaryPhoneExt}` : null].filter(Boolean).join(' '),
                                account.primaryPoC?.secondaryPhone,
                            ].filter(Boolean).join('\n')}
                        </div>
                    ],
                ]}
            />
        </div>
    )
}

export default AccountDetailsPanel;