import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import React from 'react';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const PaintAndGraphics = ({ paintAndGraphicsOptions, attachments }) => {
  const getAttachments = () => {
    return attachments.map((item) => (
      <>
        <h4> {item?.attachmentType?.description}</h4>
        <AttachmentDownload
          name={item?.fileMetadata?.name}
          metadataId={item?.metadataId}
        />
        <p>Note: {item?.description}</p>
      </>
    ));
  };
  const getPaintAndGraphicsDetails = () => {
    if (
      Object.keys(paintAndGraphicsOptions).length > 0 ||
      attachments.length > 0
    ) {
      return [
        {
          id: 'paintAndGraphics',
          title: (
            <div className="add-options-accordion-title">
              Paint And Graphics
            </div>
          ),
          content: (
            <div className="order-details-acc-body">
              <p className="order-mod-acc-sub-title">Paint And Graphics</p>
              <div className="order-mod-row-titles">
                <div className="order-mod-row-column">
                  <div className="order-mod-row-title">Uploaded Documents</div>
                  {getAttachments()}
                </div>
              </div>
            </div>
          ),
          expanded: false,
        },
      ];
    }
    return [
      {
        id: 'noPaintAndGraphics',
        title: (
          <div className="add-options-accordion-title">Paint And Graphics</div>
        ),
        content: (
          <div className="order-details-acc-body">
            (No modifications has been made in this section)
          </div>
        ),
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getPaintAndGraphicsDetails()}
        className="add-options-accordion"
      />
    </>
  );
};
PaintAndGraphics.propTypes = {
  paintAndGraphicsOptions: PropTypes.objectOf(Object).isRequired,
  attachments: PropTypes.arrayOf(Object).isRequired,
};
export default PaintAndGraphics;
