export const getVehicleListingInfo = (vehicleRequisitionState) => {
  const {
    isDomesticShipment,
    // currentStandardItem: { vehicleType },
  } = vehicleRequisitionState;

  const vehicleType = vehicleRequisitionState.currenStandardItem
    ? vehicleRequisitionState.currenStandardItem.vehicleType
    : sessionStorage.getItem('selectedVehicleType');
  const vehicleData = {
    data: {
      code: vehicleType,
    },
  };

  return { vehicleData, isDomesticShipment };
};
