import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import React from 'react';
import { formatPhoneNumber } from '../../../components/ReviewComponents/AgencyFinancial/agencyFinancialUtils';

const DeliveryInformation = ({ deliveryInfo }) => {
  const stateCodeFormatted = (stateCode) => {
    const statePart = stateCode?.split('-')[1];
    if (statePart) {
      return `${statePart},`;
    }
    return stateCode ? `${stateCode},` : '';
  };
  const getAddressCountryCode = (addressInfo) => {
    if (
      addressInfo?.isMilitary ||
      addressInfo?.isDomestic ||
      addressInfo?.countryCode === 'US'
    ) {
      return null;
    }
    return addressInfo?.countryCode;
  };
  const getDeliveryAddress = () => {
    if (deliveryInfo?.deliveryAddress) {
      const deliveryAddress = deliveryInfo?.deliveryAddress;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">Delivery Address</div>
          <div className="title-desc">
            {deliveryAddress?.isMilitary && (
              <>
                <div>{deliveryAddress?.rankAndFullName}</div>
                <div>{deliveryAddress?.postOffice}</div>
              </>
            )}
            <div>
              {deliveryAddress?.entityName}
            </div>
            <div>
              {deliveryAddress?.addressLine1}
              {', '}
              {deliveryAddress?.addressLine2}
            </div>
            <div>
              {deliveryAddress?.city}
              {', '} {stateCodeFormatted(deliveryAddress?.stateCode)}{' '}
              {deliveryAddress?.zipcode}
            </div>
            <div>{getAddressCountryCode(deliveryAddress)}</div>
          </div>
        </div>
      );
    }
    return <div>(No modifications has been made in this section)</div>;
  };
  const getDeliveryPoc = () => {
    if (deliveryInfo?.deliveryPoc) {
      const deliveryPoc = deliveryInfo?.deliveryPoc;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">Delivery point of contact</div>
          <div className="title-desc">
            <div>
              {deliveryPoc?.firstName} {deliveryPoc?.lastName}
            </div>
            <div>{deliveryPoc?.email}</div>
            <div>
              {deliveryPoc?.phone?.countryCode &&
                `Phone: ${deliveryPoc?.phone?.countryCode}`}
              {deliveryPoc?.phone?.number &&
                `-${formatPhoneNumber(deliveryPoc?.phone?.number)}`}
              {deliveryPoc?.phone?.extension &&
                ` Ext. ${deliveryPoc.phone.extension}`}
            </div>
            <div>
              {deliveryPoc?.fax?.countryCode &&
                `Fax: ${deliveryPoc?.fax?.countryCode}`}
              {deliveryPoc?.fax?.number && `-${deliveryPoc?.fax?.number}`}
              {deliveryPoc?.fax?.extension &&
                ` Ext. ${deliveryPoc.fax.extension}`}
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const getRequisitionAddress = () => {
    if (deliveryInfo?.requisitionAddress) {
      const requisitionAddress = deliveryInfo?.requisitionAddress;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">Requisition Address</div>
          <div className="title-desc">
            {requisitionAddress?.isMilitary && (
              <>
                <div>{requisitionAddress?.rankAndFullName}</div>
                <div>{requisitionAddress?.postOffice}</div>
              </>
            )}
            <div>
              {requisitionAddress?.entityName}
            </div>
            <div>
              {requisitionAddress?.addressLine1}
              {', '}
              {requisitionAddress?.addressLine2}
            </div>
            <div>
              {requisitionAddress?.city}
              {', '} {stateCodeFormatted(requisitionAddress?.stateCode)}{' '}
              {requisitionAddress?.zipcode}
            </div>
            <div>{getAddressCountryCode(requisitionAddress)}</div>
          </div>
        </div>
      );
    }
    return <div>(No modifications has been made in this section)</div>;
  };
  const getRequisitionPoc = () => {
    if (deliveryInfo?.requisitionPoc) {
      const requisitionPoc = deliveryInfo?.requisitionPoc;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">
            Requisitioning point of contact
          </div>
          <div className="title-desc">
            <div className="title-desc">
              <div>
                {requisitionPoc?.firstName} {requisitionPoc?.lastName}
              </div>
              <div>{requisitionPoc?.email}</div>
              <div>
                {requisitionPoc?.phone?.countryCode &&
                  `Phone: ${requisitionPoc?.phone?.countryCode}`}
                {requisitionPoc?.phone?.number &&
                  `-${formatPhoneNumber(requisitionPoc?.phone?.number)}`}
                {requisitionPoc?.phone?.extension &&
                  ` Ext. ${requisitionPoc.phone.extension}`}
              </div>
              <div>
                {requisitionPoc?.fax?.countryCode &&
                  `Fax: ${requisitionPoc?.fax?.countryCode}`}
                {requisitionPoc?.fax?.number &&
                  `-${requisitionPoc?.fax?.number}`}
                {requisitionPoc?.fax?.extension &&
                  ` Ext. ${requisitionPoc.fax.extension}`}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const getMailingAddress = () => {
    if (deliveryInfo?.mailingAddress) {
      const mailingAddress = deliveryInfo?.mailingAddress;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">Mailing Address</div>
          <div className="title-desc">
            {mailingAddress?.isMilitary && (
              <>
                <div>{mailingAddress?.rankAndFullName}</div>
                <div>{mailingAddress?.postOffice}</div>
              </>
            )}
            <div>
              {mailingAddress?.entityName}
            </div>
            <div>
              {mailingAddress?.addressLine1}
              {', '}
              {mailingAddress?.addressLine2}
            </div>
            <div>
              {mailingAddress?.city}
              {', '} {stateCodeFormatted(mailingAddress?.stateCode)}{' '}
              {mailingAddress?.zipcode} {mailingAddress?.zipcodePlusfour}
            </div>
            <div>{getAddressCountryCode(mailingAddress)}</div>
          </div>
        </div>
      );
    }
    return <div>(No modifications has been made in this section)</div>;
  };
  const getMailingPoc = () => {
    if (deliveryInfo?.mailingPoc) {
      const mailingPoc = deliveryInfo?.mailingPoc;
      return (
        <div className="order-mod-delivery-info-column">
          <div className="order-mod-row-title">Mailing point of contact</div>
          <div className="title-desc">
            <div className="title-desc">
              <div>
                {mailingPoc?.firstName} {mailingPoc?.lastName}
              </div>
              <div>{mailingPoc?.email}</div>
              <div>
                {mailingPoc?.phone?.countryCode &&
                  `Phone: ${mailingPoc?.phone?.countryCode}`}
                {mailingPoc?.phone?.number &&
                  `-${formatPhoneNumber(mailingPoc?.phone?.number)}`}
                {mailingPoc?.phone?.extension &&
                  ` Ext. ${mailingPoc.phone.extension}`}
              </div>
              <div>
                {mailingPoc?.fax?.countryCode &&
                  `Fax: ${mailingPoc?.fax?.countryCode}`}
                {mailingPoc?.fax?.number && `-${mailingPoc?.fax?.number}`}
                {mailingPoc?.fax?.extension &&
                  ` Ext. ${mailingPoc.fax.extension}`}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };
  const getDeliveryInfo = () => {
    console.log('modifications', deliveryInfo);
    if (Object.keys(deliveryInfo).length > 0) {
      return [
        {
          id: 'selectedOrders',
          title: (
            <div className="add-options-accordion-title">
              Delivery information
            </div>
          ),
          content: (
            <div className="order-details-acc-body">
              <p className="order-mod-acc-sub-title">Delivery Address</p>
              <div className="order-mod-row-titles">
                {getDeliveryAddress()}
                {getDeliveryPoc()}
              </div>
              <div>
                <p className="order-mod-acc-sub-title">Requisition Address</p>
              </div>
              <div className="order-mod-row-titles">
                {getRequisitionAddress()}
                {getRequisitionPoc()}
              </div>
              <div>
                <p className="order-mod-acc-sub-title">Mailing Address</p>
              </div>
              <div className="order-mod-row-titles">
                {getMailingAddress()}
                {getMailingPoc()}
              </div>
            </div>
          ),
          expanded: false,
        },
      ];
    }
    return [
      {
        id: 'noDeliveryInfo',
        title: (
          <div className="add-options-accordion-title">
            Delivery information
          </div>
        ),
        content: (
          <div className="order-details-acc-body">
            (No modifications has been made in this section)
          </div>
        ),
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getDeliveryInfo()}
        className="add-options-accordion"
      />
    </>
  );
};
DeliveryInformation.propTypes = {
  deliveryInfo: PropTypes.objectOf(Object),
};

DeliveryInformation.defaultProps = {
  deliveryInfo: {},
};
export default DeliveryInformation;
