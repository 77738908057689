import React from 'react';

import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const DeleteAdditionalReqFileModal = ({
  handleClose,
  handleDelete,
  filename,
}) => {
  return (
    <Modal
      data-testid="delete-modal"
      title={<h2>Delete file</h2>}
      className="delete-file-modal"
      onClose={() => {
        handleClose();
      }}
      actions={
        <div className="save-draft-requisition-button-row">
          <Button
            type="button"
            variant="unstyled"
            className="save-draft-requisition-action-button"
            data-testid="save-draft-modal-cancel-button-delete-modal"
            onClick={() => handleClose()}
            label="Cancel"
          />
          <Button
            type="button"
            variant="secondary"
            className="save-draft-requisition-action-button"
            data-testid="submit-requisition-modal-save-button-delete-modal"
            onClick={() => handleDelete()}
            label="Delete file"
          />
        </div>
      }
    >
      <div>
        Are you sure you want to delete <strong>{filename}</strong>? This action
        cannot be undone.
      </div>
    </Modal>
  );
};

export default DeleteAdditionalReqFileModal;

DeleteAdditionalReqFileModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  filename: PropTypes.string,
};

DeleteAdditionalReqFileModal.defaultProps = {
  filename: '',
};
