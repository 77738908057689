import React, { useState } from 'react';
import {
  TextInput,
  Button,
  SelectDropdown,
  Chip,
  Alert,
} from '@gsa/afp-component-library';
import useBulkOrderModsState from './useBulkOrderModsState';
import OrderModificationHeader from './OrderModificationHeader';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
};

const FilterTypes = [
  { label: '-Select-', value: '' },
  { label: 'Requisition Number', value: 'requisitionNumber' },
  { label: 'Order Number', value: 'caseNumber' },
  { label: 'Order range', value: 'orderRange' },
  { label: 'Option code', value: 'optionCode' },
];

const BulkOrderModFilter = () => {
  const [filterType, setFilterType] = useState(null);
  const [searchPlaceHolderName, setSearchPlaceHolderName] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [hasFilterErrors, setHasFilterErrors] = useState(false);
  const [filterErrorMessage, setFilterErrorMessage] = useState('');
  const {
    setSelectedFilters,
    selectedFilters,
    currentStep,
    selectedContract,
    noContractFound,
    changeSets,
    currentChangeSet,
    setPaginationState,
  } = useBulkOrderModsState();

  const updateFilterType = (e) => {
    const filterValue = e.target.value;
    setFilterType(filterValue);
    setSearchPlaceHolderName(
      filterValue
        ? `Enter ${
            FilterTypes.find((filter) => filter.value === filterValue)?.label
          }`
        : null,
    );
  };

  const setFilters = () => {
    setPaginationState({
      limit: 10,
      offset: 0,
      currentPage: 1,
      isReset: false,
    });
    if (filterType && (searchValue || (start && end))) {
      setSelectedFilters([
        ...selectedFilters,
        {
          filterType: FilterTypes.find((type) => type.value === filterType)
            ?.label,
          key: filterType,
          value: filterType === 'orderRange' ? [start, end] : searchValue,
        },
      ]);
      setSearchPlaceHolderName(null);
      setFilterType('');
      setSearchValue(null);
      setFilterErrorMessage('');
      setHasFilterErrors(false);
      setStart(null);
      setEnd(null);
    } else {
      setHasFilterErrors(true);
      setFilterErrorMessage(
        'You must select an identifier and enter corresponding search to view results',
      );
    }
  };

  const removeFilter = (value) => {
    setFilterErrorMessage('');
    setHasFilterErrors(false);
    const updateFilter = selectedFilters?.filter(
      (selFilter) => selFilter !== value,
    );
    setSelectedFilters(updateFilter);
  };

  return currentStep === OrderModSteps.SELECT_ORDER_MOD ? (
    <>
      <OrderModificationHeader />
      <h2 className="contract-details-title">Contract: {selectedContract}</h2>
      <h2>
        Change set{' '}
        {currentChangeSet?.changeSetNumber || changeSets?.length + 1 || 1}:
      </h2>
      <strong>Find and select orders to modify</strong>
      <p className="requisitions-and-orders-header-desc">
        Use the filters below to find your orders. Add or remove filters as
        needed to update viewing results.
      </p>
      <div className="ordermod-filter-container">
        <div
          className={`ordermod-filter-flex usa-form-group${
            hasFilterErrors ? '--error' : ''
          }`}
        >
          {hasFilterErrors && (
            <span className="usa-error-message">{filterErrorMessage}</span>
          )}
          {!!noContractFound && <Alert type="error">{noContractFound}</Alert>}
          <div data-testid="oder-mod-filter-section" className="grid-col-3">
            <SelectDropdown
              id="requirementDropdown"
              aria-label="requirement dropdown"
              onChange={(e) => updateFilterType(e)}
              value={filterType}
              data-testid="selectfilter-type"
              options={FilterTypes}
            />
          </div>
          {searchPlaceHolderName && filterType !== 'orderRange' && (
            <div data-testid="oder-mod-filter-section" className="grid-col-4">
              <TextInput
                data-testid="extension"
                key="extension"
                name="extension"
                id="extension"
                value={searchValue}
                className={`usa-input${
                  hasFilterErrors && !searchPlaceHolderName ? '--error' : ''
                }`}
                placeholder={searchPlaceHolderName}
                onChange={(e) => setSearchValue(e.target.value)}
                variant="unstyled"
              />
            </div>
          )}
          {filterType === 'orderRange' && (
            <>
              <div data-testid="oder-mod-filter-section" className="grid-col-2">
                <TextInput
                  data-testid="extension"
                  key="extension"
                  name="extension"
                  id="extension-start"
                  value={start}
                  className={`usa-input${
                    hasFilterErrors && !searchPlaceHolderName ? '--error' : ''
                  }`}
                  placeholder={searchPlaceHolderName}
                  onChange={(e) => setStart(e.target.value)}
                  variant="unstyled"
                />
              </div>
              to
              <div data-testid="oder-mod-filter-section" className="grid-col-2">
                <TextInput
                  data-testid="extension"
                  key="extension"
                  name="extension"
                  id="extension-end"
                  value={end}
                  className={`usa-input${
                    hasFilterErrors && !searchPlaceHolderName ? '--error' : ''
                  }`}
                  placeholder={searchPlaceHolderName}
                  onChange={(e) => setEnd(e.target.value)}
                  variant="unstyled"
                />
              </div>
            </>
          )}
          <div data-testid="oder-mod-filter-section" className="grid-col-3">
            <Button
              label="View Orders"
              variant="outline"
              data-testid="set-filters"
              className="view-orders"
              onClick={setFilters}
            />
          </div>
        </div>
        <div data-testid="oder-mod-filter-section" className="grid-col-12">
          <div className="grid-col-12 search-filter-wrapper">
            {selectedFilters?.map((filter) => (
              <Chip
                key={filter.value}
                label={`${filter.filterType}: ${filter.value}`}
                onClose={() => removeFilter(filter)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default BulkOrderModFilter;
