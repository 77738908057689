import React, { useMemo } from 'react';
// import { useLazyQuery } from '@apollo/client';
import { PageTitle } from '@gsa/afp-component-library';
import useVehicleAvailabilityState from '../useVehicleAvailabilityState';
// import { SEARCH_VEHICLE_AVAILABILITY } from '../queries';
import {
  getTableColumns,
  // getValFilters,
  // OrderByMap,
  VAL_TABLE_COLUMN_HEADERS,
} from '../helpers';
import BannerHeader from './BannerHeader';
import FleetNavbar from './FleetNavbar';
import './VehicleAvailabilityPrintContent.scss';
import VALPrintContentTable from './VALPrintContentTable';

const VehicleAvailabilityPrintContent = React.forwardRef((props, ref) => {
  const { valPrintRows } = useVehicleAvailabilityState();

  const data = useMemo(() => {
    if (!valPrintRows?.length) {
      return [];
    }
    return valPrintRows.map((row) => getTableColumns(row));
  }, [valPrintRows]);

  const columns = useMemo(() => VAL_TABLE_COLUMN_HEADERS, []);
  const todayFormatted = new Intl.DateTimeFormat('en-US').format(new Date());

  return (
    <div className="vehicle-availability-print-content" ref={ref}>
      <BannerHeader />
      <FleetNavbar />
      <div className="val-print-main-content">
        <PageTitle
          title={`Vehicle Availability Listing (VAL) ${todayFormatted}`}
        />
        <p className="vehicle-availability-header-desc">
          All vehicle orders must be submitted to GSA Fleet no later than 3:00
          PM EST on the closeout date indicated below.
        </p>
        <VALPrintContentTable columns={columns} data={data} />
      </div>
    </div>
  );
});

export default VehicleAvailabilityPrintContent;
