import { createSlice } from '@reduxjs/toolkit';
import { OrderingGuideSteps } from '../pages/ordering/constants';

const orderingguideSlice = createSlice({
  name: 'orderingguide',
  initialState: {
    contractRenewalYear: [],
    sinListforYear: [],
    orderGuideLoading: false,
    contractECLoading: false,
    contractList: { rows: [], count: 0 },
    contractLineECs: [],
    selectedContractLine: null,
    currentStep: OrderingGuideSteps.CONTRACT_LIST,
    selecteContractYear: null,
    selectedSINValue: '',
    selectedSINTitle: '',
    updatedContractECs:[],
    errorUpdating:false,
    showSuccessMessage: false
  },
  reducers: {
    // example of a reducer
    addContractRenewalYear: (state, action) => {
      return { ...state, contractRenewalYear: action.payload };
    },
    setSinListforYear: (state, action) => {
      return { ...state, sinListforYear: action.payload };
    },
    setContractsforSinAndYear: (state, action) => {
      return { ...state, contractList: action.payload };
    },
    setOrderGuideLoading: (state, action) => {
      return { ...state, orderGuideLoading: action.payload };
    },
    setContractECs: (state, action) => {
      return { ...state, contractLineECs: action.payload };
    },
    setContractECLoading: (state, action) => {
      return { ...state, contractECLoading: action.payload };
    },
    navigateToContractDetails: (state, action) => {
      return {
        ...state,
        currentStep: action.payload.currentStep,
        selectedContractLine: action.payload.selectedContractLine,
      };
    },
    setUpdatedContractLineECs: (state, action) => {
      return {
        ...state,
        updatedContractECs: action.payload.updatedContractECs,
      };
    },
    resetToContractList: (state, action) => {
      return {
        ...state,
        currentStep: action.payload.currentStep,
        selectedContractLine: orderingguideSlice?.getInitialState().selectedContractLine,
        selectedSINValue: orderingguideSlice?.getInitialState().selectedSINValue,
        selecteContractYear: orderingguideSlice?.getInitialState().selecteContractYear,
        contractList: orderingguideSlice?.getInitialState().contractList,
        updatedContractECs: orderingguideSlice?.getInitialState().updatedContractECs
      };
    },
    setContractSelectionDetails: (state, action) => {
      return {
        ...state,
        selecteContractYear: action.payload.selecteContractYear,
        selectedSINValue: action.payload.selectedSINValue,
        selectedSINTitle: action.payload.selectedSINTitle,
      };
    },
    setErrorUpdating: (state, action) => {
      return {
        ...state,
        errorUpdating: action.payload.errorUpdating,
      };
    },
    setShowSuccessMessage: (state, action) => {
      return {
        ...state,
        showSuccessMessage: action.payload,
      };
    },
  },
});

export const {
  addContractRenewalYear,
  setSinListforYear,
  setContractsforSinAndYear,
  setOrderGuideLoading,
  setContractECs,
  setContractECLoading,
  navigateToContractDetails,
  setUpdatedContractLineECs,
  resetToContractList,
  setContractSelectionDetails,
  setErrorUpdating,
  setShowSuccessMessage
} = orderingguideSlice.actions;
export default orderingguideSlice.reducer;
