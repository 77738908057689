import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connectModal, Modal, Button } from '@gsa/afp-component-library';

const AgencyModalForm = ({ isOpen, onClose }) => {
  const ReadModal = useMemo(() => () => (
    <Modal
      variant="small"
      onClose={onClose}
      title="Changing agency information"
      className="agency-info-change-modal"
      actions={
        <div>
          <Button variant="primary" onClick={onClose} label="Cancel" />
        </div>
      }
    >
      <div>
        Changing the Agency information will require you to initiate a new
        requisition. You can either continue, save or cancel the current
        requisition.
      </div>
    </Modal>
  ));

  const ConnectedModal = connectModal(ReadModal);

  return <ConnectedModal isOpen={isOpen} onClose={onClose} />;
};

AgencyModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AgencyModalForm;
