import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import {
  placeholderOption,
  vehicleSubTypeFilterKey,
  vehicleTypeFilterKey,
} from './filter-item-constants';
import VehicleFilterContext from './FilterContext';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const VehicleSubtypeFilterItem = ({ filter }) => {
  const { vehicleSubtypes, typeReset } = useContext(VehicleFilterContext);
  const [vehicleTypeCode, vehicleTypeCodeTo] = useState();
  const [vehicleSubtypeCode, vehicleSubtypeCodeTo] = useState();
  const [options, setOptions] = useState([placeholderOption]);
  const { setFilters } = useFilterPanel();

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  useEffect(() => {
    const items = vehicleSubtypes.map((x) => ({
      label: x.title,
      value: x.code,
      key: x.code,
    }));
    setOptions([...[placeholderOption], ...items]);
  }, [vehicleSubtypes]);

  useEffect(() => {
    const vehicleTypeFilter = getFilterFromState(vehicleTypeFilterKey) || {};
    vehicleTypeCodeTo(vehicleTypeFilter.value);
    const vehicleSubtypeFilter =
      getFilterFromState(vehicleSubTypeFilterKey) || {};
    vehicleSubtypeCodeTo(vehicleSubtypeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([placeholderOption]);
  }, [vehicleTypeCode]);

  useEffect(() => {
    if (typeReset) {
      setFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: {
          key: vehicleTypeFilterKey,
          title: 'Vehicle Type',
          type: 'select',
          value: '',
          options: [],
          operator: '$exact',
          permanent: false,
          expanded: true,
        },
      });
    }
  }, [typeReset]);

  return (
    <div id="vehicle-subtype-filter-item">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: vehicleSubtypeCode || '',
        }}
      />
    </div>
  );
};

VehicleSubtypeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehicleSubtypeFilterItem;
