import { SelectControl } from '@gsa/afp-shared-form-utils';
import React, { useMemo, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import generatePointOfContactForm from './point-of-contact-form';

function generateMilitaryAddressForm() {
  return {
    dependentOn: 'isMilitary',
    dependentValue: false,
    gap: '1rem',
    sections: [
      {
        component: () => {
          return (
            <Alert type="info">
              Each shipment to an APO/FPO needs the recipient&apos;s rank, name,
              unit, and the APO/FPO/DPO address with the 9-digit ZIP code. Mail
              and shipments must be addressed to a specific person; addressing
              to &quot;Any Service Member&quot; is no longer permitted. To
              prevent mail from entering foreign mail networks, do not include
              city or country names in APO/FPO shipping addresses.
            </Alert>
          );
        },
      },
      {
        fields: [
          {
            id: 'militaryAddress.rankAndFullName',
            label: 'Rank and full name',
            type: 'text',
            required: true,
          },
          {
            id: 'militaryAddress.militaryOrDiplomaticAddress',
            label: 'APO, FPO or DPO address',
            type: 'text',
            required: true,
          },
          {
            id: 'militaryAddress.postOffice',
            label: 'Post office',
            type: 'combobox',
            options: [
              { label: '-select-', value: '-1' },
              { label: 'Army/Air Post Office (APO AE)', value: 'APO AE' },
              { label: 'Fleet Post Office (FPO AP)', value: 'FPO AP' },
              { label: 'Diplomatic Post Office (DPO)', value: 'DPO' },
            ],
            required: true,
          },
        ],
      },
    ],
  };
}

function generateNonMilitaryAddressForm({
  getStates,
  defaultStates,
  countries,
  country,
  sectionId,
}) {
  return {
    dependentOn: 'isMilitary',
    dependentValue: (value) => value === false,
    fields: [
      {
        id: 'address.countryCode',
        label: 'Country',
        type: 'cmbobox',
        required: true,
        options: countries,
      },
      {
        id: 'address.entityName',
        label: 'Agency/Organization name',
        ariaLabel: `${sectionId} agency/organization name`,
        required: true,
        type: 'text',
      },
      {
        id: 'address.addressLine1',
        label: 'Address line 1',
        ariaLabel: `${sectionId} address line 1`,
        type: 'text',
        required: true,
      },
      {
        id: 'address.addressLine2',
        label: 'Address line 2',
        ariaLabel: `${sectionId} address line 2`,
        type: 'text',
      },
      {
        id: 'address.city',
        label: 'City',
        ariaLabel: `${sectionId} city`,
        type: 'text',
        required: true,
      },
      {
        id: 'address.stateCode',
        label: 'State',
        ariaLabel: `${sectionId} state`,
        type: 'select',
        required: true,
        component: (props, useFormContext) => {
          const { watch, setError, setValue } = useFormContext();
          const [states, setStates] = useState(
            defaultStates && defaultStates.length > 0
              ? [{ label: '-select state-', value: '-1' }, ...defaultStates]
              : [{ label: '-select country first-', value: '-1' }],
          );

          const watchCountry = watch('address.countryCode');

          useMemo(async () => {
            if (!watchCountry) {
              return;
            }
            await getStates({
              variables: { category: `ISOStateCode-${watchCountry}` },
            })
              .then(({ data }) => {
                if (data && data.getCatalogsForCategory) {
                  setStates([
                    { label: '-select state-', value: '-1' },
                    ...data.getCatalogsForCategory
                      .map(({ description, code }) => ({
                        label: description,
                        value: code.split('-')[1],
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label)),
                  ]);
                  setValue(
                    'address.stateCode',
                    country === watchCountry ? props.defaultValue : '-1',
                  );
                }
              })
              .catch((e) => {
                console.error(e);
                setError('address.state', {
                  type: 'manual',
                  message: 'Error fetching states',
                });
              });
          }, [watchCountry]);

          return SelectControl(
            {
              ...props,
              className: [],
            },
            {
              id: props.id,
              options: states,
            },
          );
        },
      },
    ],
  };
}

export function generateAddressComponentForm({
  section,
  getStates,
  defaultStates,
  countries,
  countryCodes,
  sameAsRequisition,
  address,
  contact,
}) {
  let shouldHideInputs = false;
  if (section.id !== 'requisitionAddress') {
    shouldHideInputs = sameAsRequisition || address?.isSameAsRequisition;
  }

  const defaultValues = useMemo(() => {
    let stateCode = '-1';
    if (address?.stateCode) {
      if (address.stateCode.includes('-')) {
        stateCode = address.stateCode.split('-');
        stateCode =  stateCode[stateCode.length - 1];
      } else {
        stateCode = address.stateCode;
      }
    }

    return {
      [`${section.id}-sameAsRequisitioningAddress`]: shouldHideInputs,
      isMilitary: address?.isMilitary || false,
      militaryAddress: {
        rankAndFullName: address?.rankAndFullName || '',
        militaryOrDiplomaticAddress: address?.militaryOrDiplomaticAddress || '',
        postOffice: address?.postOffice || '-1',
      },
      address: {
        countryCode: address?.countryCode || '',
        addressLine1: address?.addressLine1 || '',
        addressLine2: address?.addressLine2 || '',
        city: address?.city || '',
        stateCode,
        entityName: address?.entityName || '',
      },
      zipcode: address?.zipcode || '',
      contact: {
        firstName: contact?.firstName || '',
        lastName: contact?.lastName || '',
        email: contact?.email || '',
        phone: {
          countryCode: contact?.phone?.countryCode || '',
          number: contact?.phone?.number || '',
          extension: contact?.phone?.extension || '',
        },
        fax: {
          countryCode: contact?.fax?.countryCode || '',
          number: contact?.fax?.number || '',
          extension: contact?.fax?.extension || '',
        },
      },
    };
  }, []);

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: shouldHideInputs
      ? [
          {
            header: section.title,
            subheader: section.subTitle,
            element: {
              header: {
                style: {
                  borderBottom: 'none',
                  fontSize: '1rem',
                  color: '#005ea2',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  marginBottom: section.subTitle ? '1rem' : '0px',
                  paddingBottom: !section.subTitle ? '1rem' : '0px',
                  fontWeight: '900',
                },
              },
              subheader: {
                style: {
                  marginBottom: '1rem',
                },
              },
              section: {
                style: {
                  marginTop: '1rem',
                },
              },
            },
            fields: [
              section.canBeSameAsRequisition
                ? {
                    id: `${section.id}-sameAsRequisitioningAddress`,
                    label: 'Same as requisitioning address',
                    type: 'checkbox',
                  }
                : null,
            ],
          },
        ]
      : [
          {
            gap: '1rem',
            sections: [
              {
                header: section.title,
                subheader: section.subTitle,
                element: {
                  header: {
                    style: {
                      borderBottom: 'none',
                      fontSize: '1rem',
                      color: '#005ea2',
                      textTransform: 'uppercase',
                      letterSpacing: '1px',
                      marginBottom: section.subTitle ? '1rem' : '0px',
                      paddingBottom: !section.subTitle ? '1rem' : '0px',
                      fontWeight: '900',
                    },
                  },
                  subheader: {
                    style: {
                      marginBottom: '1rem',
                    },
                  },
                  section: {
                    style: {
                      marginTop: '1rem',
                    },
                  },
                },
                fields: [
                  section.canBeSameAsRequisition
                    ? {
                        id: `${section.id}-sameAsRequisitioningAddress`,
                        label: 'Same as requisitioning address',
                        type: 'checkbox',
                      }
                    : null,
                  {
                    id: 'isMilitary',
                    label: 'This is a military or diplomatic address',
                    type: 'checkbox',
                  },
                ],
              },
              generateMilitaryAddressForm(),
              generateNonMilitaryAddressForm({
                getStates,
                defaultStates,
                countries,
                country: defaultValues.address.countryCode,
                sectionId: section.id,
              }),
              {
                fields: [
                  {
                    id: 'zipcode',
                    label: 'Zip',
                    ariaLabel: `${section.id} zipcode`,
                    type: 'text',
                    required: true,
                    columnWidth: 'col-3-12',
                  },
                ],
              },
            ],
          },
          generatePointOfContactForm({
            title: 'Point of contact',
            countryCodes,
            subSection: true,
          }),
        ],
  };

  return [content, defaultValues];
}
