import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  TextInput,
  EmptyState,
  AFPTableRowAction,
  AFPTable,
  Checkbox,
  Button,
  useModal,
  connectModal,
  Alert,
  Modal,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import {
  JUSTIFICATION_COMPELLING,
  JUSTIFICATION_DOCUMENTATION_DESC,
  JUSTIFICATION_TITLE,
  JUSTIFICATION_TITLE_DESC,
  ROW_ACTIONS,
} from '../../constants';
import FormButtons from '../utils/FormButtonNav';
import UrgentReqUploadModal from '../utils/UrgentReqFileUploadModal';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import {
  returnFundingDocFile,
  returnJustificationDocFile,
  getAttachmentTypeId,
  toBeRounded,
} from '../utils/UrgReqCommonUtils';
import {
  CREATE_REQUISITION_ATTACHMENT,
  DELETE_REQUISITION_ATTACHMENT,
} from '../../../../services/data-layer';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import DeleteAdditionalReqFileModal from '../../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import { resetFocusToFirstElement } from '../../../../utilities/commons';

const RequirementJustification = ({ handlePageNavigation }) => {
  const { dispatch, state, updateDraftRequisition } = useContext(
    VehicleRequisitionContext,
  );
  const {
    draftRequisition,
    urgentReqJustification,
    filesUploadedList,
    requisitionAttachmentTypes,
  } = state;
  const uploadNewFileModal = useModal();
  const deleteFileModal = useModal();
  const [rowToDelete, setRowToDelete] = useState({});
  const DisplayUploadFileModal = connectModal(UrgentReqUploadModal);
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);
  const [viewFundingDocModal, setViewFundingDocModal] = useState(false);
  const uploadedFiles = urgentReqJustification?.uploadedDocumentation;
  const [selectedFile, setSelectedFile] = useState({});
  const justificationStateFrmContext = urgentReqJustification;
  const formProps = useForm({
    defaultValues: justificationStateFrmContext,
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
  } = formProps;
  const returnedFundingDoc = returnFundingDocFile(uploadedFiles);
  const returnedJustificationDoc = returnJustificationDocFile(uploadedFiles);
  const watchCheckBox = watch('certifyCheckBox', false);

  useEffect(() => {
    if (returnedFundingDoc && returnedJustificationDoc) {
      clearErrors('uploadedDocumentation');
      if (Object.keys(errors).length === 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER,
          payload: false,
        });
      }
    }
  }, [returnedFundingDoc, returnedJustificationDoc]);

  useEffect(() => {
    if (justificationStateFrmContext?.justification) {
      setValue('justification', justificationStateFrmContext?.justification);
      setValue(
        'certifyCheckBox',
        justificationStateFrmContext?.certifyCheckBox,
      );
    }
  }, [justificationStateFrmContext?.justification]);

  useEffect(() => {
    if (returnedFundingDoc && returnedJustificationDoc) {
      clearErrors('uploadedDocumentation');
    }
  }, [watchCheckBox]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER,
        payload: false,
      });
    }
  }, [errors]);

  useEffect(() => {
    resetFocusToFirstElement();
  }, []);

  const [deleteAttachment] = useMutation(DELETE_REQUISITION_ATTACHMENT);
  const [createAttachment] = useMutation(CREATE_REQUISITION_ATTACHMENT, {
    onCompleted: (data) => {
      const justificationState = urgentReqJustification;
      const arr = [...uploadedFiles];
      const listOfAttachments = arr.map((li) => {
        const obj = li;
        if (obj.metadataId === data?.createRequisitionAttachment?.metadataId) {
          obj.attachmentId =
            data?.createRequisitionAttachment?.requisitionAttachmentId;
          return li;
        }
        return obj;
      });

      justificationState.uploadedDocumentation = listOfAttachments;
      justificationState.certifyCheckBox = getValues().certifyCheckBox;
      justificationState.justification = getValues().justification;

      if (listOfAttachments?.length) {
        if (justificationState) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
            payload: justificationState,
          });
        }
      }
    },
  });

  const setDocTypeError = (checkDoc, filteredFiles = []) => {
    const checkDocExistInFilteredFiles = returnFundingDocFile(filteredFiles);
    const checkJustificationDocExists =
      returnJustificationDocFile(filteredFiles);
    if (!checkDoc) {
      const joinedErrors = [];
      if (!checkDocExistInFilteredFiles) {
        joinedErrors.push('Funding documentation');
      }

      if (!checkJustificationDocExists) {
        joinedErrors.push('Justification for Urgent and Compelling');
      }
      if (joinedErrors.length) {
        setError('uploadedDocumentation', {
          type: 'required',
          message: joinedErrors.join('_'),
        });
      }
    }
  };

  const handleFileDelete = (data) => {
    // Added metadataId for files which was not uploaded yet
    const filteredFiles =
      justificationStateFrmContext?.uploadedDocumentation?.filter(
        (li) => li.metadataId !== data.metadataId,
      );
    justificationStateFrmContext.uploadedDocumentation = filteredFiles;

    if (data?.attachmentId) {
      deleteAttachment({
        variables: { attachmentId: data?.attachmentId },
      });
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
      payload: {
        ...justificationStateFrmContext,
        uploadedDocumentation: filteredFiles,
      },
    });

    updateDraftRequisition(draftRequisition?.requisitionId);
    setDocTypeError(returnedFundingDoc, filteredFiles);
  };

  const handleDelete = () => {
    handleFileDelete(rowToDelete);
    deleteFileModal.closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        /* eslint-disable react/prop-types */
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.filename}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Document',
        accessor: 'document',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.document,
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.note,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        /* eslint-disable react/prop-types */
        Cell: (props) => {
          const {
            row: { original },
          } = props;
          return (
            <AFPTableRowAction
              actions={ROW_ACTIONS}
              onSelectAction={(evt) => {
                if (evt === 'Edit') {
                  setSelectedFile(original);
                  uploadNewFileModal.openModal();
                } else {
                  setSelectedFile(original);
                  setRowToDelete(original);
                  deleteFileModal.openModal();
                }
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const onSubmit = (data) => {
    const { appURLs } = window.AFP_CONFIG;
    const filledData = data;
    if (!returnedFundingDoc || !returnedJustificationDoc) {
      setDocTypeError(
        returnedFundingDoc && returnedJustificationDoc,
        justificationStateFrmContext?.uploadedDocumentation,
      );
      return;
    }

    filledData.uploadedDocumentation =
      urgentReqJustification.uploadedDocumentation;

    try {
      const uploaded = [];
      // make sure files are exist in draft
      const filesExistFromDraft =
        draftRequisition?.clientData?.clientState?.urgentRequirement
          ?.urgentReqJustification?.uploadedDocumentation;

      filledData.uploadedDocumentation.forEach(async (li) => {
        // check with current and already uploaded attachments
        const checkUploadedCurrAndDraft = filesExistFromDraft.find(
          (l) => l?.attachmentId && l?.attachmentId === li?.attachmentId,
        );
        let isAllowedToCreate = !filesUploadedList?.includes(li?.attachmentId);

        if (
          filesUploadedList?.length === 0 &&
          checkUploadedCurrAndDraft?.attachmentId === li?.attachmentId &&
          typeof checkUploadedCurrAndDraft !== 'undefined'
        ) {
          // not allowed to create (which means already created)
          isAllowedToCreate = false;
        }

        if (isAllowedToCreate) {
          const foundAttachmentType = requisitionAttachmentTypes.find(
            (a) => a.attachmentType === getAttachmentTypeId(li.document),
          );
          await createAttachment({
            variables: {
              input: {
                requisitionId: draftRequisition?.requisitionId,
                metadataId: li.metadataId,
                name: li.filename,
                status: 0,
                description: li.note,
                docStoreUri: appURLs.store,
                size: toBeRounded(li?.file?.size) || 0,
                fileMimeType: li?.file?.type || '',
                signedUrl: li?.signedUrl || '',
                attachmentTypeId:
                  foundAttachmentType?.requisitionAttachmentTypeId,
              },
            },
          });
        }

        uploaded.push(li.attachmentId);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_FILES_UPLOADED_LIST,
          payload: uploaded,
        });
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!errors?.uploadedDocumentation?.message) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_JUSTIFICATION,
        payload: data.justification,
      });
      if (filledData) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
          payload: filledData,
        });
      }
      handlePageNavigation('continue');
    }

    setDocTypeError(returnedFundingDoc, filledData?.uploadedDocumentation);

    if (draftRequisition?.requisitionId) {
      updateDraftRequisition(draftRequisition?.requisitionId);
    }
  };

  const onError = () => {
    setDocTypeError(
      returnedFundingDoc && returnedJustificationDoc,
      urgentReqJustification?.uploadedDocumentation,
    );
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER,
      payload: true,
    });
  };

  const ViewDetailsModal = () => {
    return (
      <div className="afp-modal-overlay modalContainer non-sop-modal">
        <Modal
          className="non-sop-modal-main"
          data-testid="urg-req-modal-view-details"
          title={
            <div className="funding-doc-modal-title">Funding documentation</div>
          }
          onClose={() => {
            setViewFundingDocModal(false);
          }}
          actions={
            <Button
              data-testid="close-btn-view-details"
              type="button"
              variant="standard"
              onClick={() => {
                setViewFundingDocModal(false);
              }}
              label="Close"
            />
          }
        >
          <div className="non-sop-modal-content">
            Please attach your funding document (e.g. MIPR, GSA49).
            <ol className="funding-doc-list">
              <li>
                This document must be signed by an authorized funds
                representative and provide the appropriate funds
                citation/information.
              </li>
            </ol>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="urg-req-justification-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div role="tab" tabIndex="0">
          <div className="urgent-req-title-1 top-padding-20">
            {JUSTIFICATION_TITLE}
          </div>
          <div className="top-padding-10">{JUSTIFICATION_TITLE_DESC}</div>
          <div className="req-details">
            <div className="urgent-req-title-1">Requirements Checklist</div>
            <ul>
              <li>
                Justification for Urgent and Compelling{' '}
                <b className="required">(required)</b>
              </li>
              <li>
                Source list <b>(if applicable)</b>
              </li>
              <li>
                Brand name justification <b>(if applicable)</b>
              </li>
              <li>
                Sole source justification <b>(if applicable)</b>
              </li>
              <li>
                Funding documentation <b className="required">(required) </b>
                <Button
                  data-testid="urg-req-view-details-btn"
                  aria-label="Funding documentation view details button"
                  onClick={() => {
                    setViewFundingDocModal(true);
                  }}
                  type="button"
                  variant="unstyled"
                  label="view details"
                />
              </li>
              <li>Other documentation</li>
            </ul>
          </div>
          <div className="req-documentation padding-bottom">
            <div className="urgent-req-title-2">
              {JUSTIFICATION_COMPELLING}
              <RequiredFieldIndicator />
            </div>
            <Controller
              rules={{ required: 'This is a required field' }}
              name="justification"
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  type="textarea"
                  aria-label={JUSTIFICATION_COMPELLING}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  name="justification"
                  inputClass="urg-req-input-text"
                  errorMessage={errors?.justification?.message}
                />
              )}
            />
          </div>
          <div className="req-documentation top-padding">
            <div className="urgent-req-title-2">Documentation</div>
            {errors?.uploadedDocumentation?.message && (
              <Alert type="error">
                <div>
                  This section is missing the following required file documents.{' '}
                  <br /> <br />
                  {errors?.uploadedDocumentation?.message
                    .split('_')
                    .map((req) => (
                      <li className="error-text-bold">{req} </li>
                    ))}
                  <br />
                  In order to continue, please upload all required file
                  documents, and be sure to select the corresponding document
                  category for each.
                </div>
              </Alert>
            )}
            <div className="top-padding-10">
              {JUSTIFICATION_DOCUMENTATION_DESC}
            </div>
            <div className="upload-btn">
              <Button
                data-testid="urg-req-upload-btn"
                onClick={() => {
                  uploadNewFileModal.openModal();
                  setSelectedFile({});
                }}
                type="button"
                variant="outline"
                leftIcon={{ name: 'add', className: 'plus-icon' }}
                label="Upload file"
              />
            </div>
            <div>
              {justificationStateFrmContext?.uploadedDocumentation && (
                <AFPTable
                  testId="additional-requirements-files-table"
                  columns={columns}
                  data={justificationStateFrmContext?.uploadedDocumentation}
                />
              )}
              {!uploadedFiles?.length && (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No files uploaded"
                />
              )}
            </div>
          </div>
          <div
            className={`${
              errors?.certifyCheckBox?.message &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <div className="error-text-desc">
              {errors?.certifyCheckBox?.message}
            </div>
            <Controller
              rules={{
                required:
                  'Please certify that you have uploaded all required documents ',
              }}
              name="certifyCheckBox"
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    className="supporting-docs-checkbox"
                    name="certifyCheckbox"
                    disabled={!returnedFundingDoc}
                    checked={value}
                    onChange={onChange}
                    label="I hereby certify that I have uploaded all required documents for Urgent Requirement."
                  />
                );
              }}
            />
          </div>
          <FormButtons handlePageNavigation={handlePageNavigation} />
          <DisplayUploadFileModal
            isOpen={uploadNewFileModal.isOpen}
            handleClose={uploadNewFileModal.closeModal}
            selectedFile={selectedFile}
          />
          <DisplayDeleteFileModal
            isOpen={deleteFileModal.isOpen}
            handleClose={deleteFileModal.closeModal}
            handleDelete={handleDelete}
            filename={selectedFile?.filename}
          />
          {viewFundingDocModal && <ViewDetailsModal />}
        </div>
      </form>
    </FormProvider>
  );
};

RequirementJustification.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  currentStep: PropTypes.shape({
    text: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default RequirementJustification;
