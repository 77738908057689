import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_OFFICES_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import { AGENCY_FILTER, BUREAU_FILTER } from './filter-item-constants';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const OfficeFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([]);

  const { mergedFilters, clearOneFilter } = useFilterPanel();

  const [getOffices, { data: officeOptions, loading: officeOptionsLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  const agencyCode = mergedFilters?.find(
    (filt) => filt?.key === AGENCY_FILTER,
  )?.value;
  const bureauCode = mergedFilters?.find(
    (filt) => filt?.key === BUREAU_FILTER,
  )?.value;

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);

    if (!agencyCode || bureauCode?.length !== 1) {
      return;
    }

    getOffices({
      variables: {
        agencyCode,
        bureauCode: bureauCode[0],
        operation: 'view',
        subject: StoreSubjects.Requisition,
      },
    });
  }, [bureauCode]);

  useEffect(() => {
    if (!officeOptions?.getOfficesByPermission) {
      return;
    }

    setOptions([
      ...officeOptions?.getOfficesByPermission.map(
        ({ officeCode, officeName }) => ({
          value: officeCode,
          label: officeName,
        }),
      ),
    ]);
  }, [officeOptions]);

  if (officeOptionsLoading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return <p>Cannot filter by office when multiple bureaus are selected</p>;
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
        }}
      />
    </div>
  );
};

OfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default OfficeFilterItem;
