import React, { useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
} from '@gsa/afp-component-library';
import './VehicleAvailabilityToolbar.scss';
import useVehicleAvailabilityState from './useVehicleAvailabilityState';
import VehicleAvailabilityPrintContent from './VehicleAvailabilityPrintContent/VehicleAvailabilityPrintContent';

const VehicleAvailabilityToolbar = () => {
  const { setExportData, setPrintData, printData, valPrintRows } =
    useVehicleAvailabilityState();
  const printRef = useRef(null);

  const pageStyle = `
    @media print {
      footer {
        position: fixed;
        bottom: 0;
      }
    }
  `;

  const print = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
  });

  useEffect(() => {
    if (printData) {
      print();
      setPrintData(false);
    }
  }, [valPrintRows]);

  return (
    <div className="table-control-toolbars">
      <div className="print-only" aria-hidden>
        <VehicleAvailabilityPrintContent ref={printRef} />
      </div>
      <Button
        data-testid="vehicle-availability-print"
        variant="outline"
        type="button"
        onClick={setPrintData}
        leftIcon={{ name: 'print', className: 'usa-icon--size-2' }}
        label="Print"
      />
      <ButtonDropdown
        label="Export"
        variant="outline"
        iconType="custom"
        iconName="export_file"
      >
        <ButtonDropdownItem
          label="Excel"
          iconName="excel_icon"
          iconType="custom"
          onClick={() => setExportData('xlsx')}
        />
        <ButtonDropdownItem
          label="CSV"
          iconName="csv_icon"
          iconType="custom"
          onClick={() => setExportData('csv')}
        />
      </ButtonDropdown>
    </div>
  );
};

// VehicleAvailabilityToolbar.propTypes = {
//   print: PropTypes.func.isRequired,
// };

export default VehicleAvailabilityToolbar;
