import moment from 'moment';
import { emDashUnicode } from '../../../constants/constants';

const getContractShipmentDate = (row) => {
  const daysOffset =
    (row.baseShipmentDays ? row.baseShipmentDays : 0) +
    (row.additionalShipmentDays ? row.additionalShipmentDays : 0);
  return moment(row.createdAt).add(daysOffset, 'days').format('MM/DD/YYYY');
};

export const getTableColumns = (rows) => {
  const simplifiedRows = rows.flatMap((item) =>
    item.orderVehicles?.map((orderVehicle) => ({
      id: orderVehicle?.orderVehicleId,
      vin: orderVehicle?.serialNumberVin,
      von: orderVehicle?.vehicleOrderNUmber,
      orderId: item?.orderId,
      statusCode: orderVehicle?.statusCode,
      shipmentDate: orderVehicle?.shipmentDate,
      receivedAt: orderVehicle?.receivedAt,
      deliveryDate: orderVehicle?.deliveryDate,
      rpnNumber: item?.rpnNumber,
      deliveryContact: item?.deliveryContact,
      agencyContact: item?.agencyContact,
      contact: item.contact,
      deliveryAddress: item.deliveryAddress,
      caseNumber: item.caseNumber || emDashUnicode,
      standardItemId: item.standardItemId || emDashUnicode,
      friendlyName: item.friendlyName || emDashUnicode,
      requisitionNumber: item.requisitionNumber || emDashUnicode,
      status: item.status,
      createdDateTime: moment(item.createdAt).format('MM/DD/YYYY'),
      contractShipmentDate: getContractShipmentDate(item),
      vehicleTypeCode: item?.vehicleTypeCode,
      bureauCode: item.bureauCode || emDashUnicode,
      agencyCode: item.agencyCode || emDashUnicode,
      modelCode: item?.modelCode || emDashUnicode,
      quantity: item.quantity,
      totalSellingPrice: item.totalSellingPrice || emDashUnicode,
      standardItemCode: item.standardItemCode || emDashUnicode,
      createdByUser: item.createdUserInfo
        ? `${item.createdUserInfo?.firstName} ${item.createdUserInfo?.lastName}`
        : emDashUnicode,
      createdByUserEmail: item.createdUserInfo
        ? item.createdUserInfo?.email
        : emDashUnicode,
      bureau: item.bureauInfo?.name || emDashUnicode,
      office: item.officeInfo?.officeName || emDashUnicode,
      upiidNumber: item.upiidNumber || emDashUnicode,
      model: item.modelInfo?.modelName || emDashUnicode,
      approvedByUser: item.approvedByUserInfo
        ? `${item.approvedByUserInfo.firstName} ${item.approvedByUserInfo.lastName}`
        : emDashUnicode,
      approvedByUserEmail: item.approvedByUserInfo
        ? item.approvedByUserInfo.email
        : emDashUnicode,
      agencyInfoName: item.agencyInfo?.name || emDashUnicode,
    })),
  );

  return simplifiedRows;
};
