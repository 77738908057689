import React, { useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useContractECFilter } from '../ContractECFilterProvider';

const ContractECFilters = () => {

  const initialFilterStructure = [
    {
      title: 'Equipment code',
      key: 'ecCode',
      type: 'text',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 2,
    },
  ];
  const { setFilters } = useContractECFilter();
  const [filterStructure] = useState(
    initialFilterStructure,
  );
  const handleFilters = (filters) => {
    const filtersToApply = [];
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.filter?.key;
      filtersToApply.push({
        key: selectedKey,
        operator: valueObj.filter.operator,
        value: valueObj.filter.value,
      });
    });

    setFilters(filtersToApply.length > 0 ? filtersToApply : []);
  };

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={() => {}}
        clearButtonLabel="Reset All"
        showClearIcon
        showSearchIcon
        updateAppliedFilters={(filters) => {
          handleFilters(filters);
        }}
      />
    );
  }
  return null;
};

export default ContractECFilters;
