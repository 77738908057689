import { Icon } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { GET_REQUISITION_ACTIVITIES_BY_TYPE } from '../../../../services/data-layer';

const ReviewRequisitionMessage = ({ requisitionId }) => {
  const [requisitionActivities, setRequisitionActivities] = useState([]);
  const { currentUser } = useCurrentUser();
  const [
    getRequisitionActivitiesData,
    {
      data: requisitionActivitiesData,
      loading: requisitionActivitiesDataLoading,
    },
  ] = useLazyQuery(GET_REQUISITION_ACTIVITIES_BY_TYPE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getRequisitionActivitiesData({
      variables: {
        requisitionId,
        offset: 0,
        limit: 1,
        order: [['createdAt', 'DESC']],
        type: 'REQUISITION_RETURNED',
      },
    });
  }, [requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivitiesByType) {
      const { rows } = requisitionActivitiesData.getRequisitionActivitiesByType;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  const RequisitionActivityMessage = ({ requisitionActivity }) => {
    const { comment, createdUserInfo } = requisitionActivity;
    const { fullName: userName, email, id: userId } = createdUserInfo;
    return (
      <>
        <div className="return-comment-body" data-testid="return-comment-body">
          <div className="fa-svg_user w-embed">
            <Icon iconName="account_circle" className="usa-icon--size-1" />
          </div>
          <span className="return-comment-body-title">
            {currentUser.id !== userId ? (
              <a className="user-a-tag" href={`mailto:${email}`}>
                {userName}
              </a>
            ) : (
              userName
            )}{' '}
            has commented:
          </span>
          <div
            className="return-comment-body-message"
            data-testid="return-comment-message"
          >
            <span>{comment}</span>
          </div>
        </div>
      </>
    );
  };
  RequisitionActivityMessage.propTypes = {
    requisitionActivity: PropTypes.instanceOf(Array).isRequired,
  };
  return (
    <>
      {!requisitionActivitiesDataLoading && (
        <div className="return-comment-section">
          <div className="return-comment">
            <span
              className="return-comment-title"
              data-testid="return-comment-title"
            >
              {' '}
              Requisition returned{' '}
            </span>
            {requisitionActivities &&
              requisitionActivities.map((requisitionActivity) => (
                <RequisitionActivityMessage
                  requisitionActivity={requisitionActivity}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

ReviewRequisitionMessage.propTypes = {
  requisitionId: PropTypes.string.isRequired,
};

export default ReviewRequisitionMessage;
