import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Modal,
  TextInput,
  Spinner,
  Link,
} from '@gsa/afp-component-library';
import './EditAttachmentModal.scss';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_MOD_ATTACHMENT_DESCRIPTION } from '../../../services/data-layer';

const EditAttachmentModal = ({ handleClose, rowToEdit, update }) => {
  const [saving, setSaving] = useState(false);
  const [description, setDescription] = useState(
    rowToEdit?.original?.description || '',
  );
  const [updateAttachmentDescription] = useMutation(
    UPDATE_ORDER_MOD_ATTACHMENT_DESCRIPTION,
  );

  const handleSubmitAttachmentForm = async () => {
    setSaving(true);
    await updateAttachmentDescription({
      variables: {
        attachmentId: rowToEdit?.original?.orderModAttachmentId,
        description,
      },
    });
    update(rowToEdit?.original?.orderModAttachmentId, description);
    handleClose();
    setSaving(false);
  };

  const handleDescriptionChange = (e) => setDescription(e.target.value);

  if (saving) {
    return (
      <Spinner
        data-testid="edit-file-desc-spinner"
        size="large"
        className="margin-y-8"
      />
    );
  }

  return (
    <div className="afp-modal-overlay modal-overlay edit-file-description">
      <Modal
        title="Edit file documentation"
        onClose={handleClose}
        className="edit-file-documentation-modal"
        variant="large"
        actions={
          <div>
            <Button
              type="button"
              variant="unstyled"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              onClick={handleSubmitAttachmentForm}
              label="Save"
            />
          </div>
        }
      >
        <div className="note">Uploaded file</div>

        <div>
          {' '}
          <Link href={rowToEdit?.original?.signedUrl} target="_blank">
            {rowToEdit?.original?.name}
          </Link>
        </div>

        <Alert type="warning" className="alert-msg">
          Help prevent a privacy incident by ensuring that any supporting
          document uploaded here does not contain personally identifiable
          information (PII).
        </Alert>

        <div className="note">Note</div>
        <TextInput
          type="textarea"
          aria-label="note textarea"
          value={description}
          onChange={handleDescriptionChange}
          data-testid="additional-req-attachment-edit-description"
          help="A few words to help identify this document"
          characterLimit={100}
        />
      </Modal>
    </div>
  );
};

export default EditAttachmentModal;

EditAttachmentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  rowToEdit: PropTypes.instanceOf(Object).isRequired,
};
