import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';

import { FilterPanel, Checkbox } from '@gsa/afp-component-library';
import { GET_ASSIGNED_ENGINEERS } from '../queries';
import { selectedAssigneeAtom } from '../storeHelper';
import { GET_USERS_BY_ROLE } from '../../../services/data-layer';

const { useFilterPanel } = FilterPanel;

const AssigneeFilterItem = () => {
  const [filterList, setFilterList] = useRecoilState(selectedAssigneeAtom);
  const [assigned, setAssigned] = useState(() =>
    filterList.includes('Assigned'),
  );
  const [unAssigned, setUnAssigned] = useState(() =>
    filterList.includes('null'),
  );
  const [filterItems, setFilterItems] = useState([]);
  const [assignedEngineersWithUserIds, setAssignedEngineersWithUserIds] =
    useState([]);

  const [fetchAssignedEngineersList, { data: assignedEngineersData = [] }] =
    useLazyQuery(GET_ASSIGNED_ENGINEERS, {
      fetchPolicy: 'cache-first',
    });

  const [fetchEngineerUsers, { data: engineerUsersData = [] }] = useLazyQuery(
    GET_USERS_BY_ROLE,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    fetchAssignedEngineersList();

    fetchEngineerUsers({
      variables: {
        filters: [],
        roleId: [501, 502],
      },
    });
  }, []);

  useEffect(() => {
    if (!assignedEngineersData?.getStandardsVehicleGroupPocs?.rows?.length) {
      return;
    }

    if (!engineerUsersData?.getUsersByRole?.rows?.length) {
      return;
    }

    const uniqueAssignedEngineers =
      assignedEngineersData.getStandardsVehicleGroupPocs.rows.reduce(
        (acc, curr) => {
          if (!acc.find((poc) => poc.email === curr.email)) {
            acc.push(curr);
          }
          return acc;
        },
        [],
      ) || [];

    const engineerUsers = engineerUsersData.getUsersByRole.rows || [];

    const mergedEngineers = uniqueAssignedEngineers.map((engineer, index) => {
      const userEmail = engineerUsers.find(
        (user) => user.email === engineer.email,
      );
      return {
        ...engineer,
        ...userEmail,
        // POC users may not have a user account on lower environments.
        // In that case, we will use the index as the id.
        // Since assigned engineer is a UUID, index will not return any records.
        id: String(userEmail?.id || index),
      };
    });

    setAssignedEngineersWithUserIds(mergedEngineers);
  }, [assignedEngineersData, engineerUsersData]);

  const { getFilterFromState, mergedFilters, setFilters } = useFilterPanel();

  useEffect(() => {
    const customfilters = [];
    if (unAssigned) {
      customfilters.push({ key: 'null', label: 'Unassigned' });
    }
    if (assigned) {
      customfilters.push({ key: 'Assigned', label: 'Assigned' });
    }
    if (
      filterItems.length &&
      filterItems.length !== assignedEngineersData.length
    ) {
      filterItems?.forEach((filter) => {
        customfilters.push(filter);
      });
    }

    setFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: {
        ...getFilterFromState('assigneeTypeFilter'),
        value: customfilters,
      },
    });
  }, [filterItems, assigned, unAssigned]);

  useEffect(() => {
    const assigneeTypeFilter = mergedFilters.find(
      (mf) => mf?.key === 'assigneeTypeFilter',
    );
    const filterCleared = assigneeTypeFilter?.value?.length === 0;
    if (filterCleared && filterItems.length) {
      setFilterItems([]);
      setAssigned(false);
      setUnAssigned(false);
      setFilterList([]);
    } else {
      let fi = [];
      assigneeTypeFilter?.value?.forEach((fil) => {
        if (fil.key === 'Assigned') {
          fi = [
            ...fi,
            ...assignedEngineersWithUserIds?.map((poc) => poc.id),
            fil.key,
          ];
        } else {
          fi.push(fil.key);
        }
      });
      setFilterList(fi);
      setAssigned(fi.includes('Assigned'));
      setUnAssigned(fi.includes('null'));
    }
  }, [mergedFilters]);

  const handleFilter = (key, name, value) => {
    const filtersFromState = getFilterFromState('assigneeTypeFilter');
    const isAssignedFilterExists = filtersFromState?.value?.find(
      (fi) => fi.key === 'Assigned',
    );
    let assigneefilterItems =
      filtersFromState?.value?.filter(
        (fi) => !['null', 'Assigned'].includes(fi?.key),
      ) || [];
    if (isAssignedFilterExists) {
      assigneefilterItems = [
        ...assigneefilterItems,
        ...assignedEngineersWithUserIds?.map((poc) => {
          return { key: poc?.id, label: poc?.name };
        }),
      ];
    }

    if (value) {
      if (
        assigneefilterItems?.length ===
        assignedEngineersWithUserIds?.length - 1
      ) {
        setAssigned(true);
      }
      setFilterItems([...assigneefilterItems, { key, label: name }]);
    } else {
      setAssigned(false);
      const updatedFilters = assigneefilterItems.filter((fi) => fi.key !== key);
      setFilterItems(updatedFilters);
    }
  };

  const handleAssignedCheckbox = (checked) => {
    setAssigned(checked);
    if (checked) {
      setFilterItems(() =>
        assignedEngineersWithUserIds?.map((poc) => {
          return { key: poc?.id, label: poc?.name };
        }),
      );
    } else {
      setFilterItems([]);
    }
  };
  return (
    <div>
      <Checkbox
        checked={unAssigned}
        onChange={(event) => {
          setUnAssigned(event.target.checked);
        }}
        data-testid="unAssignedcheckbox"
        label="Unassigned"
      />
      <Checkbox
        checked={assigned}
        onChange={(event) => {
          handleAssignedCheckbox(event.target.checked);
        }}
        label="Assigned"
        data-testid="assignedcheckbox"
      />
      <div className="assignee-filter">
        {assignedEngineersWithUserIds.map((poc, index) => {
          if (!poc) {
            return null;
          }

          return (
            <Checkbox
              key={poc.id}
              className=""
              data-testid={`assigned-engineer-${index + 1}`}
              name={poc.id}
              checked={filterList.includes(poc.id)}
              onChange={(event) =>
                handleFilter(poc.id, poc.name, event.target.checked)
              }
              label={poc.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AssigneeFilterItem;
