import React, { useContext } from 'react';
import './AreqButton.scss';
import { Button } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';

const AreqButton = ({
  draftRequisition,
  setForceSaveDraft,
  hasUnbuildableModels,
}) => {
  const { dispatch } = useContext(VehicleRequisitionContext);
  const handleClick = () => {
    if (draftRequisition?.requisitionId) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP,
        payload: {
          key: STEPS.AREQ_INFORMATION,
          current: 2,
          text: 'Additional requirements',
        },
      });

      return;
    }

    setForceSaveDraft(true);
  };
  return (
    <div className="areq-block">
      <Button
        onClick={handleClick}
        type="button"
        variant="primary"
        label="Continue with AREQ"
        disabled={hasUnbuildableModels}
      />
    </div>
  );
};

export default AreqButton;
