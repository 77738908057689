import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { EmptyState, TextInput, SelectDropdown } from '@gsa/afp-component-library';
import { cloneDeep } from 'lodash';
import { InfoIcon } from '@/assets/images/info-icon';
import './color-selection.scss';
import ErrorActions from '@/context/ErrorContext/ErrorActions';
import FilesUpload from '../file-upload/file-upload';
import LeasingNavigation from '../../../components/navigation';
import useLeasingNavigation from '@/hooks/useLeasingNavigation';
import { LeasingSteps } from '../../../leasing-consts';
import { useGetColors, useGetCalculatedPrice } from '@/requests/leasing';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedColors, setSelectedContract } from '@/reducers/leasing';
import VehicleRequisitionErrors from '../../../../VehicleRequisition/constants/VehicleRequisitionErrors';
import { VALIDATION_ERRORS_SECTIONS } from '../../../../VehicleRequisition/constants/VehicleRequisitionConstants';
import { REQUISITION_TYPE } from '../../../../non-standard-purchase/constants';
import { PAINT_OPT } from '../../../../../constants/constants';
import { setPaintAndGraphicsDescription } from '../../../../../reducers/leasing';
import useUpdateDraft from "../../../components/update-draft/update-leasing-draft";
import { getSelectedPayload, TRANSACTION_TYPES } from '../../../../../utilities/CompareVehicleUtils';

export default function ColorSelection({ contract }) {
    const requisitionType = REQUISITION_TYPE.STANDARD_ORDER;
    const { handleLeasingNavigation, handleReturnLeasingNavigation } = useLeasingNavigation();
    const [getCalculatedPrice] =
    useGetCalculatedPrice();
    const dispatch = useDispatch();
    const [updateDraft] = useUpdateDraft();
    const {
        selectedStandardItem, 
        compareSelect: {
            addOptions,
            selectedContract,
            selectedOptions,
        },
        paintAndGraphics: {
            selectedColors,
            paintAndGraphicsDescription
        },
        selectedVehicles,
        account,
    } = useSelector((state) => state.leasingReducer);
    const vehicleQuantity = selectedVehicles.length || 1;
    const [error, setError] = useState([]);

    const addError = (error) => {
        setError((prev) => {
            return [
                ...prev,
                error,
            ];
        })
    }

    const removeError = (error) => {
        setError((prev) => {
            return prev.filter((err) => err.error !== error);
        })
    }

    const reg = /^[0-9\b]+$/;

    // TODO: This is a temporary solution to check if the requisition is an AREQ
    const isAreqRequisition = requisitionType === REQUISITION_TYPE.AREQ;

    let isRequisitionCPT = false;
    const exPaint = addOptions.find((option) => {
        return (
            option.equipmentCode.category === 'ExPaint' &&
            option.equipmentCode.code === 'CPT' &&
            selectedOptions.find(opt => opt.optionCode === 'CPT')
        );
    });

    if (addOptions.length === 0 || exPaint) {
        const draftCPT =
            selectedOptions?.find(
                (option) => option.optionCode === 'CPT' && option.isChecked,
            );

        isRequisitionCPT = draftCPT ? true : !!exPaint;
    }

    const isRequisitionCPL = !!paintAndGraphicsDescription?.some(
        (paintAndGraphicsOption) => {
            return paintAndGraphicsOption.optionCode === 'CPL'
        },
    );

    const [availableColors, setAvailableColors] = useState([]);
    useGetColors({
        contractLineId: selectedContract?.contractLineId
    }).then(({ data }) => {
        console.log('got colors', {data, selectedColors});
        let tempAvailableColors = data.map(x => {
            return {
                label: x.description,
                value: x.colorCode,
                price: x.price,
                vendorPrice: x.vendorPrice,
            }
        })

        setAvailableColors(tempAvailableColors);

        const newSelectedColorsState = [];
        newSelectedColorsState.push({
            color: tempAvailableColors[0],
            quantity: vehicleQuantity,
        });

        if (!isRequisitionCPT) {
            if (!(selectedColors && selectedColors.length > 0)) {
                const colorsFromDraft = false
                // draftRequisition?.vehicles
                //     ? draftRequisition.vehicles[0]
                //     : false;

                console.log('here');
                if (colorsFromDraft) {
                    const listOfColors = [];
                    listOfColors.push({
                        color: {
                            label: colorsFromDraft.makeColorName,
                            value: colorsFromDraft.makeColorCode,
                            priceToCustomer: colorsFromDraft.makeColorPriceToCustomer,
                            priceToGsa: colorsFromDraft.makeColorPriceToGsa,
                        },
                        quantity: vehicleQuantity || 1,
                    });

                    dispatch(setSelectedColors(listOfColors));
                } else {
                    dispatch(setSelectedColors(newSelectedColorsState));
                }
            } else dispatch(setSelectedColors(newSelectedColorsState));
        } else {
            console.log('setting selected colors', newSelectedColorsState);
            dispatch(setSelectedColors(newSelectedColorsState));
        }
    })

    useEffect(() => {
        // add the selected options to the paint and graphics description
        let paintAndGraphicsOptions = structuredClone(paintAndGraphicsDescription) || [];
        for (const option of selectedOptions) {
            const inPaintAndGraphics = paintAndGraphicsOptions.find(
                (paintAndGraphicsOption) => {
                    return paintAndGraphicsOption.optionCode === option.optionCode
                },
            );

            if (!inPaintAndGraphics) {
                const temp = {
                    optionCode: option.optionCode,
                    optionDescription: option.optionDescription,
                };

                if (option.optionCode === 'CPL' || option.optionCode === 'CPT' || option.tags.value.includes(PAINT_OPT)) {
                    temp.customerInputExist = true;
                    temp.customerInput = '';

                    if (option.optionCode === 'CPL') {
                        temp.customerCPLInput = '';
                    }

                    paintAndGraphicsOptions.push(temp);
                }
            }
        }

        let selectedOptionCodes = selectedOptions.map((option) => option.optionCode);

        // delete any of the paint and graphics options that are not in the selected options
        for (let i = 0; i < paintAndGraphicsOptions.length; i++) {
            if (!selectedOptionCodes.includes(paintAndGraphicsOptions[i].optionCode)) {
                paintAndGraphicsOptions.splice(i, 1);
                i--;
            }
        }

        dispatch(setPaintAndGraphicsDescription(paintAndGraphicsOptions));

        if (isRequisitionCPT) {
            dispatch(setSelectedColors([]));
        }
    }, []);

    const errorForColorSelection = (errorAction) => {

    };

    const onChangeQuantity = ({ target }, index) => {
        const { value } = target;
        if (value !== '' && !reg.test(value)) {
            return;
        }
        const newSelectedColorState = [...selectedColors];
        newSelectedColorState[index].quantity = value;
        dispatch(setSelectedColors(newSelectedColorState));
    };

    const selectColor = (event, index) => {
        const selectedColorObject = availableColors.find(
            (color) => color.value === event?.target?.value,
        );

        const newSelectedColorState = structuredClone(selectedColors);
        newSelectedColorState[index] = selectedColorObject || {
            label: '',
            value: '',
            price: 0,
            vendorPrice: 0,
        };

        console.log('newSelectedColorState', newSelectedColorState);
        //  NOTE: If a color is selected and it is not a CPT, force all colors to be the same
        if (!isRequisitionCPT) {
            const payload = [
                { color: selectedColorObject, quantity: vehicleQuantity },
            ];

            dispatch(setSelectedColors(payload));
            return;
        }

        if (newSelectedColorState.length > 1) {
            newSelectedColorState.forEach((color, ind) => {
                if (ind !== index && color.color.value === selectedColorObject?.value) {
                    /* eslint no-param-reassign: "error" */
                    color.color = {
                        label: '',
                        value: '',
                        price: 0,
                        vendorPrice: 0,
                    };
                }
            });
        }
        dispatch(setSelectedColors(newSelectedColorState));
    };

    const deleteCurrentColor = (event, index) => {
        const newColorsState = [...selectedColors];
        newColorsState.splice(index, 1);
        dispatch(setSelectedColors(newColorsState));
    };

    const commentsErrorMsg = (errorMessage, asCPL = false) => {
        if (!hasError('comment', errorMessage)) {
            return null;
        }

        return (
            <span
                className="usa-error-message"
                data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
            >
                {asCPL
                    ? 'Please provide up to 24 characters of custom lettering'
                    : 'Must enter description with at least 6 characters'}
            </span>
        );
    };

    const getErrorTextIfAny = () => {
        if (
            hasError(
                'colors',
                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
            )
        ) {
            if (availableColors.length === 1) {
                return (
                    <span
                        className="usa-error-message"
                        data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
                    >
                        Must match total quantity
                    </span>
                );
            }
            return (
                <span
                    className="usa-error-message"
                    data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
                >
                    Please check quantity
                </span>
            );
        }
        return null;
    };

    const hasError = (form, errorMessage) => {
        if (error.length > 0) {
            return error.some((err) => err.form === form && err.error === errorMessage);
        }

        return false;
    }

    const getErrorForColor = () => {
        const totalSelectedVehicles = selectedColors.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.quantity),
            0,
        );
        const areAllColorsValid =
            totalSelectedVehicles === Number(vehicleQuantity) &&
            selectedColors?.filter((i) => !!i.color?.label).length ===
            selectedColors?.length;

        if (
            hasError(
                'colors',
                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
            )
        ) {
            if (!selectedColors?.length || !areAllColorsValid) {
                return (
                    <span
                        className="usa-error-message"
                        data-testid="vehicle-requisition-color-selection__required-error"
                    >
                        Please select a color
                    </span>
                );
            }
        }
        return null;
    };

    const handleValidations = () => {
        const newSelectedColorState = [...selectedColors];
        const totalQuantitySelected = newSelectedColorState.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.quantity),
            0,
        );

        if (
            totalQuantitySelected === Number(vehicleQuantity)
            // &&
            // errorState?.colorSelection?.colors
        ) {
            errorForColorSelection(ErrorActions.REMOVE_ERROR);
        }

        validateAndUpdateDraftRequisition();
    };

    const handleOnBlurHandler = (inputValue, optionCode, asCPL = false) => {
        const clonedPaintAndGraphicsDescription = cloneDeep(paintAndGraphicsDescription);

        const newArr = clonedPaintAndGraphicsDescription.map((item) => {
            if (item.optionCode === optionCode) {
                if (asCPL) {
                    item.customerCPLInput = inputValue;
                } else {
                    item.customerInput = inputValue;
                }

                if (
                    !asCPL &&
                    item?.customerInput?.length === 0
                ) {
                    addError({
                        form: 'comment',
                        error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                            }#${optionCode}`,
                    })
                } else {
                    removeError(`${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                        }#${optionCode}`);
                }

                if (
                    asCPL &&
                    item?.customerCPLInput?.length > 0 &&
                    item?.customerCPLInput?.length > 24
                ) {
                    addError({
                        form: 'comment',
                        error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                            }#${optionCode}#CPL`,
                    })
                } else {
                    removeError(`${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                        }#${optionCode}#CPL`);
                }
            }

            return item;
        });

        dispatch(setPaintAndGraphicsDescription(newArr));
    };

    const handleTextArea = (inputValue, optionCode, asCPL = false) => {
        const clonedpaintAndGraphicsDescription = structuredClone(paintAndGraphicsDescription);

        const updatedArr = clonedpaintAndGraphicsDescription.map((item) => {
            if (item.optionCode === optionCode) {
                if (asCPL) {
                    item.customerCPLInput = inputValue;
                } else {
                    item.customerInput = inputValue;
                }
            }
            return item;
        });

        dispatch(setPaintAndGraphicsDescription(updatedArr));
    };

    const validateColors = () => {
        const newSelectedColorState = [...selectedColors];
        const totalQuantitySelected = newSelectedColorState.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.quantity),
            0,
        );

        if (totalQuantitySelected !== Number(vehicleQuantity)) {
            errorForColorSelection(ErrorActions.ADD_ERROR);
        }
    }

    const handleSubmit = async () => {
        setError([]);
        let errors = [];

        if (!isRequisitionCPT) {
            const allColorsHaveSelections = selectedColors.every((color) => color.color !== undefined);
            if (!allColorsHaveSelections) {
                errors.push({
                    form: 'colors',
                    error: VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
                });
            }
        }

        if (isRequisitionCPT) {
            const optionWithNoInput = paintAndGraphicsDescription.find((description) => {
                return description.optionCode === 'CPT' && description.customerInputExist && (!description.customerInput || description.customerInput.length === 0);
            });

            if (optionWithNoInput) {
                errors.push({
                    form: 'comment',
                    error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]}#${optionWithNoInput.optionCode}`,
                })
            }
        }

        if (isRequisitionCPL) {
            const cplOptionWithNoInput = paintAndGraphicsDescription.find((description) => {
                return description.optionCode === 'CPL' && description.customerInputExist && (!description.customerCPLInput || description.customerCPLInput.length > 24);
            });

            if (cplOptionWithNoInput) {
                errors.push({
                    form: 'comment',
                    error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]}#${cplOptionWithNoInput.optionCode}#CPL`,
                })
            }

            const optionWithNoInput = paintAndGraphicsDescription.find((description) => {
                return description.optionCode === 'CPL' && description.customerInputExist && (!description.customerInput || description.customerInput.length === 0);
            });

            if (optionWithNoInput) {
                errors.push({
                    form: 'comment',
                    error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]}#${optionWithNoInput.optionCode}`,
                })
            }
        }

        const optionsWithNoInput = paintAndGraphicsDescription.filter((description) => {
            return description.customerInputExist && !description.customerInput && description.customerInput.length === 0 && description.optionCode !== 'CPL' && description.optionCode !== 'CPT';
        });

        if (optionsWithNoInput.length > 0) {
            optionsWithNoInput.forEach((optionWithNoInput) => {
                errors.push({
                    form: 'comment',
                    error: `${VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]}#${optionWithNoInput.optionCode}`,
                })
            });
        }

        setError(errors);

        if (errors.length > 0) {
            return;
        }

        const costBreakdowns = await getCalculatedPrice({
            variables: {
              standardItemId: parseFloat(selectedStandardItem?.id),
              quantity: vehicleQuantity,
              selectedOptions: selectedOptions?.length > 0 ? getSelectedPayload(selectedOptions) : [],
              transactionType: TRANSACTION_TYPES.LEASING,
              fmcId: parseInt(account?.fmcId),
              standardItemNumber: selectedStandardItem?.standardItemNumber,
              colorPriceInfo: {
                contractLineId: selectedContract.contractLineId,
                priceToCustomer: selectedColors[0].color.price || 0,
                priceToGsa: selectedColors[0].color.vendorPrice || 0,
              },
            },
        });
        const foundCostBreakDown = costBreakdowns?.data?.modelCostBreakDown.find(
            (cb) => cb.contractLineId === selectedContract.contractLineId,
        );
        if (foundCostBreakDown)
            dispatch(setSelectedContract({
                ...selectedContract,
                baseAndOptionsPrice: foundCostBreakDown.baseAndOptionsPrice,
                perVehicleColorPrice: foundCostBreakDown.perVehicleColorPrice,
                perVehicleColorPriceVendor: foundCostBreakDown.perVehicleColorPriceVendor,
                totalVehicleAndOptionsPrice: foundCostBreakDown.totalVehicleAndOptionsPrice,
                totalSellingPrice: foundCostBreakDown.totalSellingPrice,
            }));

        const input = {
            clientData: {
                clientState: {
                    paintAndGraphicsDescription,
                    ...(foundCostBreakDown && {selectedContract: {
                        ...selectedContract,
                        baseAndOptionsPrice: foundCostBreakDown.baseAndOptionsPrice,
                        perVehicleColorPrice: foundCostBreakDown.perVehicleColorPrice,
                        perVehicleColorPriceVendor: foundCostBreakDown.perVehicleColorPriceVendor,
                        totalVehicleAndOptionsPrice: foundCostBreakDown.totalVehicleAndOptionsPrice,
                        totalSellingPrice: foundCostBreakDown.totalSellingPrice,
                    }}),
                }
            },
            vehicle: {
                makeColorCode: selectedColors[0]?.color?.value,
                makeColorName: selectedColors[0]?.color?.label,
                makeColorPriceToCustomer: selectedColors[0]?.color?.price,
                makeColorPriceToGsa: selectedColors[0]?.color?.vendorPrice,
                quantity: selectedColors[0]?.quantity,
            }
        }

        updateDraft(input, LeasingSteps.REVIEW_VEHICLE_BUILD).then((data) => {
            if (data) {
                handleLeasingNavigation(LeasingSteps.REVIEW_VEHICLE_BUILD);
            }
        });
    }

    const handlePrevious = () => {
        handleLeasingNavigation(LeasingSteps.COMPARE_AND_SELECT);
    }

    const paintAndGraphicsOpt = () => {
        if (!paintAndGraphicsDescription?.length) {
            return null;
        }

        return (
            <>
                <div className="selected-paint-list-section">
                    <div className="selected-paint-heading-section">
                        SELECTED PAINT AND GRAPHIC OPTIONS
                    </div>
                    {/* <PageAlerts page={RequisitionStep.COMMENTS_SECTION} form="comment" /> */}
                    <ul>
                        {paintAndGraphicsDescription.map((list, index) => {
                            return (
                                <li key={list.optionCode} data-testid={`color_${index}`}>
                                    {list.optionCode} {'-'} {list.optionDescription}
                                    {list.customerInputExist && (
                                        <span className="provide-details">
                                            {' '}
                                            (Provide details below)
                                        </span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                    {paintAndGraphicsDescription.map((paintAndGraphicsOption, index) => {
                        if (paintAndGraphicsOption.customerInputExist) {
                            const errorColor =
                                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS];
                            const message = `${errorColor}#${paintAndGraphicsOption.optionCode}`;
                            const CPLmessage = `${errorColor}#${paintAndGraphicsOption.optionCode}#CPL`;

                            return (
                                <div
                                    className="description-container"
                                    key={paintAndGraphicsOption.optionCode}
                                >
                                    <div className="description-label">
                                        {' '}
                                        {paintAndGraphicsOption.optionCode} -{' '}
                                        {paintAndGraphicsOption.optionDescription}{' '}
                                        <InfoIcon role="img" aria-label="info" />
                                    </div>
                                    {paintAndGraphicsOption.optionCode === 'CPL' && (
                                        <div
                                            className={`${hasError(
                                                'comment',
                                                CPLmessage,
                                            )
                                                ? 'usa-form-group--error description-box '
                                                : 'description-box '
                                                }  ${'cpl-desc '}`}
                                        >
                                            {commentsErrorMsg(CPLmessage, true)}
                                            {/* prettier-ignore */}
                                            <TextInput
                                                data-testid={`text-cpl-description-testid-${index}`}
                                                type='input'
                                                label='Enter the exact custom lettering to be displayed'
                                                id='colorOptionCPLDescription'
                                                className={`${hasError(
                                                    'comment',
                                                    CPLmessage,
                                                )
                                                    ? 'usa-input--error'
                                                    : ''
                                                    } comment`}
                                                name={paintAndGraphicsOption.optionCode}
                                                value={paintAndGraphicsOption.customerCPLInput}
                                                onChange={(e) =>
                                                    handleTextArea(
                                                        e.target.value,
                                                        paintAndGraphicsOption.optionCode,
                                                        true,
                                                    )}
                                                onBlur={(e) =>
                                                    handleOnBlurHandler(
                                                        e.target.value,
                                                        paintAndGraphicsOption.optionCode,
                                                        true,
                                                    )}
                                                required
                                                maxLength='24'
                                                containerClassName='usa-form-group'
                                            />
                                            <div className="comment-box-desc">
                                                24 characters allowed
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={`${hasError(
                                            'comment',
                                            message,
                                        )
                                            ? 'usa-form-group--error description-box '
                                            : 'description-box '
                                            } ${paintAndGraphicsOption.optionCode === 'CPL'
                                                ? 'cpl-addl-desc'
                                                : ''
                                            }`}
                                    >
                                        {commentsErrorMsg(message)}
                                        {/* prettier-ignore */}
                                        <TextInput
                                            data-testid={`text-description-testid-${index}`}
                                            type='textarea'
                                            label='Enter a description of your requested option'
                                            id='colorOptionDescription'
                                            className={`${hasError(
                                                'comment',
                                                message,
                                            )
                                                ? 'usa-input--error'
                                                : ''
                                                } comment `}
                                            name={paintAndGraphicsOption.optionCode}
                                            value={paintAndGraphicsOption.customerInput}
                                            key='agencyOrderNumber'
                                            onChange={(e) => handleTextArea(e.target.value, paintAndGraphicsOption.optionCode)}
                                            onBlur={(e) => handleOnBlurHandler(e.target.value, paintAndGraphicsOption.optionCode)}
                                            required
                                            maxLength='1000'
                                        />
                                        <div className="comment-box-desc">
                                            1000 characters allowed
                                        </div>

                                        <FilesUpload
                                            optionCode={paintAndGraphicsOption.optionCode}
                                            page="COLORS_SELECTION"
                                        />
                                    </div>
                                </div>
                            );
                        }

                        return null;
                    })}
                </div>
            </>
        );
    };

    // if (!selectedContract) {
    //     return handleReturnLeasingNavigation(LeasingSteps.COMPARE_AND_SELECT);
    // }

    if (!availableColors || availableColors.length === 0) {
        return (
            <div className='no-colors-selection'>
                <EmptyState
                hasBackground
                containerStyles="margin-top-neg-2 padding-y-10"
                topText="No colors available"
                />

                <LeasingNavigation
                    onNext={handleSubmit}
                    onPrevious={handlePrevious}
                    hide={['cancel', 'submit', 'reject']}
                />
            </div>
        )
    }

    const buildColorList = () => {
        return availableColors.map((x) => ({
            value: x.value,
            label: `${x.label} ${x.price > 0 ? `+$${x.price}` : ''}`,
        }));
    };
    
    return (
        <>
            <div className="colors-selection">
                {!!selectedColors && (
                    <>
                        <div role="region" tabIndex="0">
                            <h2
                                className="usa-h2 margin-y-0"
                                data-testid="vehicle-requisition-color-selection__title"
                            >
                                Choose colors and provide details
                            </h2>
                            <p
                                className="colors-page-description"
                                data-testid="vehicle-requisition-color-selection__info"
                            >
                                If applicable, enter description(s) for the options selected,
                                and upload relevant attachments.
                            </p>
                        </div>
                        {/* <PageAlerts page={RequisitionStep.COLOR_SELECTION} form="colors" /> */}

                        <div
                            className={classNames('color-selection-container', {
                                'more-options':
                                    isRequisitionCPT ||
                                    isRequisitionCPL ||
                                    paintAndGraphicsDescription?.length,
                            })}
                        >
                            {!isRequisitionCPT && (
                                <div className="paint-heading-section">STANDARD and ADDITIONAL COST COLORS</div>
                            )}
                            <div className="top-section">
                                <span
                                    className="quantity-selection-title"
                                    data-testid="vehicle-requisition-color-selection__vehicle-quantity"
                                >
                                    Selected Quantity of Vehicles:{' '}
                                    <span className="selected-count">{vehicleQuantity}</span>
                                </span>
                            </div>
                            {!isRequisitionCPT && (
                                <div className="color-selection-body">
                                    <div
                                        className={classNames('color-selection-list', {
                                            'more-options': isRequisitionCPT,
                                        })}
                                    >
                                        {!selectedColors.length ? (
                                            <>
                                                <div className="display-flex flex-row flex-justify-center">
                                                    <p data-testid="vehicle-requisition-color-selection__no-color">
                                                        No Colors selected
                                                    </p>
                                                </div>
                                                <div className="display-flex flex-row flex-justify-center">
                                                    <EmptyState />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {selectedColors.map((color, index) => (
                                                    <div
                                                        className={classNames(
                                                            'grid-row selected-color-row',
                                                        )}
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={index}
                                                        data-testid="vehicle-requisition-color-selection__selected-color-rows"
                                                    >
                                                        {!isRequisitionCPT && (
                                                            <div
                                                                className="cpt-label"
                                                                key={`cpt-label-${color.value}`}
                                                            >
                                                                <span className="selection-title">
                                                                    Select color
                                                                </span>
                                                                <span className="selection-description">
                                                                    (If you require a Custom Paint Option,
                                                                    please go back to the Vehicle Comparison
                                                                    page and select CPT option, if available.)
                                                                </span>
                                                            </div>
                                                        )}
                                                        <div className="grid-col-7">
                                                            <div
                                                                className={classNames(
                                                                    'usa-form-group quantity-form-group',
                                                                    hasError(
                                                                        'colors',
                                                                        VehicleRequisitionErrors[
                                                                        VALIDATION_ERRORS_SECTIONS.COLORS
                                                                        ],
                                                                    )
                                                                        && getErrorForColor() !== null
                                                                        ? 'usa-form-group--error'
                                                                        : '',
                                                                )}
                                                            >
                                                                {getErrorForColor()}
                                                                <SelectDropdown
                                                                    label="Selected Color"
                                                                    data-testid={`vehicle-requisition-color-selection__select-color-${index}`}
                                                                    id="color-selection-dropdown"
                                                                    name="color-selection-dropdown"
                                                                    options={buildColorList()}
                                                                    defaultValue={
                                                                        selectedColors[index]?.color?.value
                                                                    }
                                                                    onChange={(e) => selectColor(e, index)}
                                                                    value={selectedColors[index]?.color?.value}
                                                                />
                                                            </div>
                                                        </div>
                                                        {isRequisitionCPT && (
                                                            <>
                                                                <div className="grid-col-4">
                                                                    <div
                                                                        className={classNames(
                                                                            'usa-form-group quantity-form-group',
                                                                            hasError(
                                                                                'colorSelection',
                                                                                'colors',
                                                                                VehicleRequisitionErrors[
                                                                                VALIDATION_ERRORS_SECTIONS.COLORS
                                                                                ],
                                                                            )
                                                                                ? 'usa-form-group--error'
                                                                                : '',
                                                                        )}
                                                                    >
                                                                        {getErrorTextIfAny()}
                                                                        <input
                                                                            type="number"
                                                                            className={classNames(
                                                                                'usa-input color-quantity-input',
                                                                                hasError(
                                                                                    'colorSelection',
                                                                                    'colors',
                                                                                    VehicleRequisitionErrors[
                                                                                    VALIDATION_ERRORS_SECTIONS
                                                                                        .COLORS
                                                                                    ],
                                                                                )
                                                                                    ? 'usa-input--error'
                                                                                    : '',
                                                                            )}
                                                                            onChange={
                                                                                (event) =>
                                                                                    onChangeQuantity(event, index) // eslint-disable-next-line
                                                                            }
                                                                            onBlur={() => handleValidations()}
                                                                            value={selectedColors[index]?.quantity}
                                                                            data-testid={`vehicle-requisition-color-selection__vehicle-quantity-${index}`}
                                                                            aria-label="quantity per color"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="grid-col-1 delete-button-container">
                                                                    <button
                                                                        type="button"
                                                                        className="usa-button--unstyled delete-button"
                                                                        onClick={
                                                                            (event) =>
                                                                                deleteCurrentColor(event, index) // eslint-disable-next-line
                                                                        }
                                                                        data-testid={`vehicle-requisition-color-selection__delete-selected-color-${index}`}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            {paintAndGraphicsOpt()}
                        </div>
                    </>
                )}
            </div>

            <LeasingNavigation
                onNext={handleSubmit}
                onPrevious={handlePrevious}
                hide={['cancel', 'submit', 'reject']}
            />
        </>
    );
};