import { ReadonlyModifier, SelectControl } from '@gsa/afp-shared-form-utils';
import React, { useMemo, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { LeasingSteps } from '../../../leasing-consts';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';
import generatePointOfContactForm from '../../../../requisitions-and-orders/change-sets/DeliveryAddress/address-component/point-of-contact-form';
import { Link } from 'react-router-dom';
import useLeasingNavigation from '../../../../../hooks/useLeasingNavigation';


export const AddressModifier =
  (
    { includeTitle } = {
      includeTitle: false,
    }
  ) =>
    (value) => {
      const lines = [
        value?.address1,
        value?.address2,
        [[value?.city, value?.state].filter(Boolean).join(', '), value?.zip]
          .filter(Boolean)
          .join(' '),
        value?.phone,
      ].filter(Boolean);

      return (
        <div>
          {includeTitle && value?.title && <strong>{value.title}</strong>}
          {lines.length === 0
            ? emDashUnicode
            : lines.map((line, index) => <div key={index}>{line}</div>)}
        </div>
      );
    };

export function generateDealershipDeliveryForm({
  countryCodes,
  shipmentLocation,
  deliveryOption,
  standardItemSubCategory,
  deliveryAddress,
  licenseDeliveryAddress,
  optionCityData,
  stateId,
  defaultStates,
  selectedContract,
  getDealershipsByCityStateMake,
  fleetManagementCenters,
  formGeneratorRef,
  selectedCountry,
  getCitybyStateId,
  getStateList
}) {
  const { getUrlForStep } = useLeasingNavigation();
  const defaultValues = useMemo(() => {
    return {
      selectedCountry,
      stateId,
      selectedStateId:deliveryAddress?.selectedStateId,
      deliveryDealership: {
        selectedCity: deliveryAddress?.deliveryDealership?.selectedCity || '-1',
        selectedDealerId: deliveryAddress?.deliveryDealership?.selectedDealerId || '-1',
      },
      deliveryPoC: {
        firstName: deliveryAddress?.deliveryPoC?.firstName || '',
        lastName: deliveryAddress?.deliveryPoC?.lastName || '',
        email: deliveryAddress?.deliveryPoC?.email || '',
        phone: {
          countryCode: deliveryAddress?.deliveryPoC?.phone?.countryCode || '',
          number: deliveryAddress?.deliveryPoC?.phone?.number || '',
          extension: deliveryAddress?.deliveryPoC?.phone?.extension || '',
        },
        fax: {
          countryCode: deliveryAddress?.deliveryPoC?.fax?.countryCode || '',
          number: deliveryAddress?.deliveryPoC?.fax?.number || '',
          extension: deliveryAddress?.deliveryPoC?.fax?.extension || '',
        },
      },
      fmcLocation: licenseDeliveryAddress?.fmcLocation.toString(),
      fmcOfficeLocationDetails: licenseDeliveryAddress?.fmcOfficeLocationDetails,
      licenseDeliveryPoC: {
        firstName: licenseDeliveryAddress?.licenseDeliveryPoC?.firstName || '',
        lastName: licenseDeliveryAddress?.licenseDeliveryPoC?.lastName || '',
        email: licenseDeliveryAddress?.licenseDeliveryPoC?.email || '',
        phone: {
          countryCode: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.countryCode || '',
          number: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.number || '',
          extension: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.extension || '',
        },
        fax: {
          countryCode: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.countryCode || '',
          number: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.number || '',
          extension: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.extension || '',
        },
      },
      selectedDealer: deliveryAddress?.selectedDealer
    };
  }, []);

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        gap: '40px',
        sections: [
          {
            header: 'Delivery Address',
            element: {
              header: {
                style: {
                  color: '#005EA2',
                  fontSize: '16px',
                  fontWeight: '900',
                  textTransform: 'uppercase',
                  paddingBottom: '5px',
                  borderBottom: '1px solid #DFE1E2',
                  marginBottom: '40px',
                },
              },
            },
            fields: [
              {
                required: true,
                component: () => {
                  return (
                    <Alert type="info">
                      <div className="delivery-banner-info">
                        You&apos;ve selected{' '}
                        <b>
                          {deliveryOption[0]?.optionCode}-
                          {deliveryOption[0]?.optionDescription}
                        </b>
                        {standardItemSubCategory && (
                          <>
                            {' '}
                            for a <b>{standardItemSubCategory.split(',')[0]}</b>
                          </>
                        )}
                        in state of <b>{shipmentLocation}</b>.{' '}
                        <span>
                          Select your city below, then choose from a list of
                          available dealerships. If you&apos;d like to change
                          your delivery option selection, please revisit the{' '}
                          <Link
                            to={getUrlForStep(LeasingSteps.COMPARE_AND_SELECT)}
                          >
                            Compare and Select
                          </Link>{' '}
                          page prior to order placement.
                        </span>
                      </div>
                    </Alert>
                  );
                },
              },
              {
                dependentOn: 'selectedCountry',
                dependentValue: (value) => {
                  return !(value === 'US' || value === 'PR')
                },
                dependentWaitType: 'hidden',
                id: 'selectedStateId',
                label: 'State',
                type: 'select',
                required: true,
                options: defaultStates,
              },
              {
                id: 'deliveryDealership.selectedCity',
                label: 'Select a city to view available dealerships ',
                type: 'select',
                required: true,
                component: (props, useFormContext) => {
                  const { watch, setError, setValue } = useFormContext();
                  const [updatedCityOptions, setUpdatedCityOptions] = useState(optionCityData);

                  const watchSelectedStateId = watch('selectedStateId');

                  useMemo(async () => {
                    // clearErrors();
                    if (!watchSelectedStateId) {
                      return;
                    }
                    setUpdatedCityOptions([{
                      label: 'Select City',
                      value: '-1',
                    }]);
                    getStateList({}).then(({data}) => {
                      console.log('state', data )
                      const selectedStateId = data?.find(state => state.stateCode === watchSelectedStateId)?.id;
                      if(selectedStateId) {
                        setValue('stateId', selectedStateId);
                        getCitybyStateId({
                          variables: {
                            stateId: parseInt(selectedStateId, 10),
                            make: parseInt(selectedContract?.makeCode, 10),
                            acceptsEv: undefined,
                          },
                        })
                          .then(({ data }) => {
                            if (data) {
                              const cities = data?.reduce((uniqueCities, dealer) => {
                                if (!uniqueCities.has(dealer.city)) {
                                  uniqueCities.add(dealer.city);
                                }
                                return uniqueCities;
                              }, new Set());
                              const citiesData = Array.from(cities).map((city) => ({
                                label: city,
                                value: city,
                              }));
                              citiesData.unshift({
                                label: 'Select City',
                                value: '-1',
                              });
                              setUpdatedCityOptions(citiesData);
                              console.log('citiesData',citiesData)
                            }
                          })
                          .catch((e) => {
                            console.error(e);
                            setError('deliveryDealership.selectedStateId', {
                              type: 'manual',
                              message: 'Error fetching cities',
                            });
                          });
                      }
                    })


                  }, [watchSelectedStateId]);

                  return SelectControl(
                    {
                      ...props,
                      className: [],
                    },
                    {
                      id: props.id,
                      options: updatedCityOptions,
                    },
                  )
                }
              },
            ]
          },
          {
            altStyle: 'alt-blue-border',
            columnWidth: 'col-8-12',
            fields: [
              {
                id: 'deliveryDealership.selectedDealerId',
                label: 'Choose dealership',
                type: 'select',
                required: true,
                component: (props, useFormContext) => {
                  const { watch, setError, setValue, getValues } = useFormContext();
                  const [dealerData, setDealerData] = useState([
                    {
                      label: 'Select Dealership',
                      value: '-1',
                    },
                  ]);
                  const [dealerList, dealerListTo] = useState(null);

                  const watchCity = watch('deliveryDealership.selectedCity');
                  const watchStateId = watch('stateId') || stateId;
                  useMemo(async () => {
                    // clearErrors();
                    if (!watchCity || !watchStateId) {
                      return;
                    }
                    getDealershipsByCityStateMake({
                      variables: {
                        state: parseInt(watchStateId, 10),
                        city: watchCity,
                        make: parseInt(selectedContract?.makeCode, 10),
                        acceptsEv: undefined,
                      },
                    })
                      .then(({ data }) => {
                        if (data) {
                          dealerListTo(data);
                          setDealerData([
                            { label: 'Select Dealership', value: '-1' },
                            ...data
                              .map(({ dealerName, id }) => ({
                                label: dealerName,
                                value: id,
                              }))
                              .sort((a, b) => a.label.localeCompare(b.label)),
                          ]);


                          const dealer = data?.find((x) => {
                            return x.id === parseInt(getValues('deliveryDealership.selectedDealerId'), 10);
                          });

                          if (!dealer) {
                            setValue('deliveryDealership.selectedDealerId', '-1');
                            setValue('selectedDealer', undefined);
                          }
                        }
                      })
                      .catch((e) => {
                        console.error(e);
                        setError('deliveryDealership.selectedDealerId', {
                          type: 'manual',
                          message: 'Error fetching states',
                        });
                      });
                  }, [watchCity]);

                  return SelectControl(
                    {
                      ...props,
                      className: [],
                      value: getValues('deliveryDealership.selectedDealerId'),
                    },
                    {
                      id: props.id,
                      options: dealerData,
                      element: {
                        control: {
                          onChange: (value) => {
                            const dealer = dealerList?.find((x) => {
                              return x.id === parseInt(value, 10);
                            });

                            if (!dealer) {
                              setValue('selectedDealer', undefined);
                            } else {
                              setValue('selectedDealer', {
                                address1: dealer.address1,
                                address2: dealer.address2,
                                city: dealer.city,
                                country: dealer.country.countryCode,
                                dealerName: dealer.dealerName,
                                title: dealer.dealerName,
                                dealershipDeliveryCode: dealer.dealershipDeliveryCode,
                                email: dealer.email,
                                id: dealer.id,
                                phone: dealer.phone,
                                zip: dealer.postalCode,
                                state: dealer.state.stateCode,
                                stateId: dealer.state.id,
                                countryId: dealer.country.id,
                              });
                            }
                          }
                        }
                      }
                    },
                  )
                }
              },
              {
                dependentOn: 'selectedDealer',
                dependentValue: (value) => {
                  return value !== undefined
                },
                dependentWaitType: 'hidden',
                type: 'readonly',
                id: 'selectedDealer',
                element: {
                  control: {
                    rawModifierValue: true,
                    modifier: AddressModifier({
                      includeTitle: true,
                    })
                  }
                }
              }
            ]
          }
        ],
      },
      generatePointOfContactForm({
        title: 'Delivery point of contact',
        countryCodes,
        subSection: true,
        idPrefix: 'deliveryPoC',
      }),
      {
        header: 'License Plate Delivery Address',
        dependentOn: 'selectedCountry',
        dependentValue: (value) => {
          return (value === 'US' || value === 'PR')
        },
        element: {
          header: {
            style: {
              color: '#005EA2',
              fontSize: '16px',
              fontWeight: '900',
              textTransform: 'uppercase',
              paddingBottom: '5px',
              borderBottom: '1px solid #DFE1E2',
            },
        },
        },
        gap: '40px',
        sections: [
          {
            gap: '40px',
            sections: [
              {
                gap: '40px',
                fields: [
                  {
                    label: 'FMC/field office location',
                    id: 'fmcLocation',
                    type: 'combobox',
                    options: [
                      { label: '-select-', value: '-1' },
                      ...fleetManagementCenters
                        .map((fmc) => ({ label: fmc.name, value: fmc.id }))
                        .sort((a, b) => a.label.localeCompare(b.label)),
                    ],
                    element: {
                      control: {
                        className: 'full-width-combobox',
                        onChange: (value) => {
                          if (!value || value === '-1') {
                            return;
                          }

                          const fmc = fleetManagementCenters.find(
                            (fmc) => fmc.id === value,
                          );
                          formGeneratorRef.current.setValue('fmcOfficeLocationDetails', {
                            location: fmc.name,
                            deliveryAddress: {
                              address1: fmc.address1,
                              address2: fmc.address2,
                              address3: fmc.address3,
                              city: fmc.city,
                              state: fmc.stateCode,
                              zip: fmc.zipCode,
                            },
                          });
                        },
                      },
                    },
                  },
                ],
              }
            ]
          },
          {
            dependentOn: 'fmcLocation',
            dependentValue: (value) =>  {
                return value && value !== '-1';
            },
            altStyle: 'alt-blue-border',
            gap: '30px',
            sections: [
                {
                    fieldLayout: 'horizontal',
                    fields: [
                        {
                            label: "FMC/Field Office location",
                            id: 'fmcOfficeLocationDetails.location',
                            type: 'readonly',
                        },
                        {
                            label: 'Delivery address',
                            id: 'fmcOfficeLocationDetails.deliveryAddress',
                            type: 'readonly',
                            element: {
                                control: {
                                    modifier: ReadonlyModifier.AddressModifier(),
                                    rawModifierValue: true,
                                }
                            }
                        }
                    ]
                }
            ]
          },{
                ...generatePointOfContactForm({
                  title: 'Point of Contact',
                  subSection: true,
                  countryCodes,
                  idPrefix: 'licenseDeliveryPoC',
                }),
              },
        ],
      },
    ],
  };

  return [content, defaultValues];
}
