import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, Button, Checkbox, Alert } from '@gsa/afp-component-library';
import './MasReqPrintPreviewModal.scss';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { MasSections } from '../../context/VehicleRequisitionContext/VehicleRequisitionInitialState';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

const MasPrintPreviewModal = ({
  handleClose,
  handlePrint,
  isUrgReq,
  status,
}) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { masPrintPreview, urgReqPrintPreview } = state;
  const isReceivedByGsa = status === RequisitionStatus.RECEIVED_BY_GSA;
  const urgentReqPrintSections = isReceivedByGsa
    ? urgReqPrintPreview
    : _.omit(urgReqPrintPreview, [
        'Contract line data',
        'Vendor acceptance',
        'Selected vendor and funding confirmation',
      ]);
  const masPrintSections = isReceivedByGsa
    ? masPrintPreview
    : _.omit(masPrintPreview, 'Contract line data');
  const requirementPreview = isUrgReq
    ? urgentReqPrintSections
    : masPrintSections;
  const printPreviewSectionKeys = Object.keys(requirementPreview);

  const showError = useMemo(() => {
    const checkIfAllFalse = Object.values(requirementPreview).filter(
      (item) => item === true,
    );
    if (!checkIfAllFalse.length) {
      return true;
    }
    return false;
  }, [requirementPreview]);

  const handleCheckbox = (label) => {
    return (event) => {
      const { checked } = event.target;

      let newValues = {};
      if (label === MasSections.AllSections) {
        newValues = Object.keys(requirementPreview).reduce(
          (accum, currKey) => ({ ...accum, [currKey]: checked }),
          {},
        );
      } else {
        newValues = { [label]: checked };
      }

      if (isUrgReq) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_URGENT_REQ_PRINT_PREVIEW,
          payload: newValues,
        });
      } else {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAS_PRINT_PREVIEW,
          payload: newValues,
        });
      }
    };
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleClose();
        handlePrint();
      }}
    >
      <Modal
        data-testid="print-requisition-modal"
        title={<div className="modal-title">Print requisition</div>}
        className="print-preview-modal"
        onClose={() => {
          handleClose();
        }}
        actions={
          <div className="mas-print-preview-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="save-draft-modal-cancel-button-delete-modal"
              onClick={() => handleClose()}
              label="Cancel"
            />
            <Button
              type="submit"
              leftIcon={{ name: 'print' }}
              variant="primary"
              label="Print requisition"
              disabled={showError}
            />
          </div>
        }
      >
        {showError && (
          <Alert type="error" className="show-error-alert">
            Please select at least one section to print
          </Alert>
        )}
        <div className="modal-instructions">
          Choose which sections of this summary you’d like to print
        </div>
        <Checkbox
          className="all-sections-checkbox print-preview-checkbox"
          name={`${MasSections.AllSections}`}
          checked={Object.values(requirementPreview).every(Boolean)}
          onChange={handleCheckbox(MasSections.AllSections)}
          label={`${MasSections.AllSections}`}
        />

        {printPreviewSectionKeys.map((key) => (
          <Checkbox
            key={key}
            className="print-preview-checkbox"
            name={`${key}`}
            checked={requirementPreview[key]}
            onChange={handleCheckbox(key)}
            label={key}
          />
        ))}
      </Modal>
    </form>
  );
};

export default MasPrintPreviewModal;

MasPrintPreviewModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
  isUrgReq: PropTypes.bool,
  status: PropTypes.string,
};

MasPrintPreviewModal.defaultProps = {
  isUrgReq: false,
  status: '',
};
