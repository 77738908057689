import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import React from 'react';
import { formatPhoneNumber } from '../../../components/ReviewComponents/AgencyFinancial/agencyFinancialUtils';

const AgencyInformation = ({ agencyInfo }) => {
  const getAgencyInfo = () => {
    if (Object.keys(agencyInfo).length > 0) {
      return [
        {
          id: 'agencyInfo',
          title: (
            <div className="add-options-accordion-title">
              Agency information
            </div>
          ),
          content: (
            <div className="order-details-acc-body">
              <p className="order-mod-acc-sub-title">Agency Information</p>
              <div className="order-mod-row-titles">
                <div className="order-mod-delivery-info-column">
                  <div className="order-mod-row-title">
                    Agency point of contact
                  </div>
                  <div className="title-desc">
                    <div>
                      {agencyInfo?.firstName} {agencyInfo?.lastName}
                    </div>
                    <div>{agencyInfo?.email}</div>
                    <div>
                      {agencyInfo?.phone?.countryCode &&
                        `Phone: ${agencyInfo?.phone?.countryCode}`}
                      {agencyInfo?.phone?.number &&
                        `-${formatPhoneNumber(agencyInfo?.phone?.number)}`}
                      {agencyInfo?.phone?.extension &&
                        ` Ext. ${agencyInfo.phone.extension}`}
                    </div>
                    <div>
                      {agencyInfo?.fax?.countryCode &&
                        `Fax: ${agencyInfo?.fax?.countryCode}`}
                      {agencyInfo?.fax?.number && `-${agencyInfo?.fax?.number}`}
                      {agencyInfo?.fax?.extension &&
                        ` Ext. ${agencyInfo.fax.extension}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
          expanded: false,
        },
      ];
    }
    return [
      {
        id: 'noAgencyInfo',
        title: (
          <div className="add-options-accordion-title">Agency information</div>
        ),
        content: (
          <div className="order-details-acc-body">
            (No modifications has been made in this section)
          </div>
        ),
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getAgencyInfo()}
        className="add-options-accordion"
      />
    </>
  );
};
AgencyInformation.propTypes = {
  agencyInfo: PropTypes.arrayOf(Object),
};
AgencyInformation.defaultProps = {
  agencyInfo: [],
};
export default AgencyInformation;
