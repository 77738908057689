import { RequisitionStatus } from '../RequisitionDetailsUtils';

export const displayMessageLines = (isAreq, status) => {
  if (!isAreq) {
    return false;
  }

  const validStatuses = [
    RequisitionStatus.CONTRACTING_APPROVAL,
    RequisitionStatus.RECEIVED_BY_GSA,
    RequisitionStatus.ORDER_RECEIVED,
    RequisitionStatus.PENDING_CUSTOMER_RESPONSE,
    RequisitionStatus.ORDERING_ADMIN_APPROVAL,
  ];

  return validStatuses.includes(status);
};
