import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    SEARCH_ORDER_MOD,
    CREATE_ORDER_MOD,
    SEARCH_CONTRACT,
    UPDATE_ORDER_MODIFICATION,
    GET_CATALOGS_FOR_CATEGORY,
    FETCH_SIGNED_URL_REQUISITIONS,
    GET_ASSIGNED_ENGINEERS,
    GET_ORDER_MOD_ATTACHMENT_TYPES,
    GET_ORDER_MODIFICATION_BY_ID,
    CREATE_CHANGE_SET,
    UPDATE_CHANGE_SET,
    GET_CHANGE_SET_BY_ID,
    GET_OPTIONS_BY_CONTRACT_NUMBER,
    UPDATE_CHANGE_SET_ORDERS,
    GET_MIN_REQ,
} from '../../services/data-layer';
import { OrderModInitialState } from './OrderModInitialState';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
  SUBMIT_ORDER_MOD: 4,
};

export const ChangeSetType = {
  MODIFY: 'MODIFY',
  TERMINATE: 'TERMINATE',
};

export const OrderModificationType = {
  CONTRACTUAL: 'Contractual', // Financial changes
  ADMINISTRATIVE: 'Administrative', // Not financial changes
};

export const BulkOrderModsContext = createContext(OrderModInitialState);

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function BulkOrderModProvider({ children }) {
  const params = useQueryParams();

  const [orderModRows, setOrderModRows] = useState([]);
  const [orderModCount, setOrderModCount] = useState(0);
  const [orderModDirection, setOrderModDirection] = useState('DESC');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const [orderBy, setOrderBy] = useState('createdAt');
  const [orderModName, setOrderModName] = useState(null);
  const [createModHasError, setCreateModHasError] = useState(false);
  const [createModErrorMessage, setCreateModErrorMessage] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [selectedOrdersForMod, setSelectedOrdersForMod] = useState([]);
  const [sameRequisitionNumberMods, setSameRequisitionNumberMods] =
    useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [contractErrorMessage, setContractErrorMessage] = useState('');
  const [noContractFound, setNoContractFound] = useState('');
  const [updateModHasError, setUpdateModHasError] = useState(false);
  const [updateModErrorMessage, setUpdateErrorMessage] = useState(null);
  const [updatedDraft, setUpdatedDraft] = useState();
  const [changeSets, setChangeSets] = useState([]);
  const [currentChangeSet, setCurrentChangeSet] = useState();
  const [statesOptions, setStatesOptions] = useState([]);
  const [countriesCallingCodeData, setCountriesCallingCodeData] = useState([]);
  const [orderModHasError, setOrderModHasError] = useState(
    OrderModInitialState.orderModHasError,
  );
  const [buyerCodeList, setBuyerCodeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [changeSetError, setChangeSetError] = useState('');
  const [changeSetOrderWithPO, setChangeSetOrderWithPO] = useState(false);
  const [submitOrderModHasError, setSubmitOrderModHasError] = useState(false);
  const [submitOrderModErrorMessage, setSubmitOrderModErrorMessage] =
    useState('');

  const OrderChangesInitData = {
    orderChangeType: '',
    priceChangePerOrder: '',
    quantityChangePerOrder: '',
    fob: null,
    buyerCode: null,
    inspectionPoint: null,
    surcharge: 0,
    originCity: null,
    originState: null,
    shipmentDate: null,
    selectedOptions: [],
    message3: null,
    message4: null,
    message5: null,
  };
  const AgencyContactInitData = {
    email: '',
    faxCountryCode: '',
    faxExtension: '',
    faxNumber: '',
    firstName: '',
    lastName: '',
    phoneCountryCode: '',
    phoneExtension: '',
    phoneNumber: '',
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderChanges, setOrderChanges] = useState(OrderChangesInitData);
  const [agencyContact, setAgencyContact] = useState(AgencyContactInitData);
  const [minRequiredOptions, setMinRequiredOptions] = useState(null);
  const [orderModAttachmentTypes, setOrderModAttachmentTypes] = useState([]);
  const [selectedChangeset, setSelectedChangeSet] = useState({});
  const [terminatedChangeSets, setTerminatedChangeSets] = useState([]);
  const [optedForTermination, setOptedForTermination] = useState(false);
  const [modificationId, setModificationId] = useState(
    params.get('modificationId'),
  );
  const [currentChangeSetType, setCurrentChangeSetType] = useState(
    ChangeSetType.MODIFY,
  );
  const [additionalComment, setAdditionalComment] = useState(null);
  const [message1, setMessage1] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);
  const [message5, setMessage5] = useState(null);
  const [submitComment, setSubmitComment] = useState(null);
  const [changeSetId, setChangeSetId] = useState(params.get('changeSetId'));
  // const [updateModHasError, setCreateModHasError] = useState(false);
  const [orderModAttachments, setOrderModAttachments] = useState([]);

  const [requisitionStateContext] = useState(
    OrderModInitialState.requisitionStateContext,
  );
  const [mailingStateContext] = useState(
    OrderModInitialState.mailingStateContext,
  );
  const [deliveryStateContext] = useState(
    OrderModInitialState.deliveryStateContext,
  );

  const [requisitionAddress, setRequisitionAddress] = useState(
    OrderModInitialState.requisitionStateContext,
  );

  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL_REQUISITIONS);
  const getLabelValueFromItem = (item) => {
    return {
      label: item.description,
      value: item.code,
    };
  };
  const getLabelValueFromBuyerCode = (item) => {
    return {
      label: item.name,
      value: item.standardVgPocId,
    };
  };

  const getValuesFromCallingCode = (item) => {
    return {
      label: item.description,
      value: `${item.code}:${item.description}`,
    };
  };

  useEffect(() => {
    if (currentStep === OrderModSteps.SELECT_ORDER_MOD) {
      setPerPage(10);
    }
  }, [currentStep]);

  const [fetchAssignedEngineersList] = useLazyQuery(GET_ASSIGNED_ENGINEERS, {
    fetchPolicy: 'cache-first',
  });

  const [getModification, { loading: loadingModification }] = useLazyQuery(
    GET_ORDER_MODIFICATION_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        modificationId,
      },
      onCompleted: () => {
        setIsLoaded(false);
      },
    },
  );

  const [getMinRequiredOptions, { loading: minReqOptionsLoading }] =
    useLazyQuery(GET_MIN_REQ, {
      onCompleted: (data) => {
        setMinRequiredOptions(
          data.getMinRequiredOptions.map((option) => option.equipmentCode.code),
        );
      },
    });

  const [
    getOptionsData,
    { data: optionsByContractData, loading: optionsLoading },
  ] = useLazyQuery(GET_OPTIONS_BY_CONTRACT_NUMBER, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log({ error });
      setIsLoaded(true);
    },
    onCompleted: ({ storeGetContractLineEquipment }) => {
      let ordersFromSameReq = false;

      if (selectedOrdersForMod.length > 1) {
        ordersFromSameReq = selectedOrdersForMod.every(
          (order, _index, orders) =>
            order.requisitionNumber === orders[0].requisitionNumber,
        );
      } else if (selectedOrdersForMod.length === 1) {
        ordersFromSameReq = true;
      }

      if (ordersFromSameReq) {
        getMinRequiredOptions({
          variables: {
            standardItemId: selectedOrdersForMod[0]?.standardItemId,
          },
        });
      } else {
        setMinRequiredOptions(null);
      }
      const inReqOptions =
        storeGetContractLineEquipment?.equipment
          ?.map((option) => {
            return {
              code: option?.equipmentCode,
              inReq: (
                option?.tags?.value ||
                []
              ).some((tag) => tag.includes('IN_REQ')),
            };
          })
          .filter((option) => option.code) || [];

      const selectedOptionsWithInReq =
        currentChangeSet?.modifications?.selectedOptions?.map((option) => {
          const inReqOption = inReqOptions?.find(
            (iro) => iro.code === option.optionCode,
          );
          return {
            ...option,
            inReq: inReqOption?.inReq || false,
          };
        });

      setSelectedOptions(selectedOptionsWithInReq);
      setOrderChanges((prev) => ({
        ...prev,
        ...currentChangeSet?.modifications,
        selectedOptions: selectedOptionsWithInReq,
      }));
      setIsLoaded(true);
    },
  });

  const getOrderModification = async () => {
    if (modificationId) {
      const { data } = await getModification();
      setUpdatedDraft(data?.getOrderModificationById);
      // setModificationId(data?.getOrderModificationById?.orderModificationId);
      setChangeSetId(currentChangeSet?.orderChangeSetModId);
      setChangeSets(data?.getOrderModificationById?.changeSets);
      if (currentChangeSet && currentChangeSet?.modifications) {
        setOrderChanges((prev) => ({
          ...prev,
          ...currentChangeSet.modifications,
        }));
      }

      setSubmitComment(data?.getOrderModificationById?.submissionComments);
      setSelectedContract(data?.getOrderModificationById?.contractNumber);
    }
  };
  useEffect(() => {
    if (modificationId) {
      setCurrentStep(OrderModSteps.SUBMIT_ORDER_MOD);
      // eslint-disable-next-line no-use-before-define
      getStaterelatedData();
      (async () => {
        await getOrderModification();
      })();
    }
  }, [modificationId]);
  const [getStatesData] = useLazyQuery(GET_CATALOGS_FOR_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  const [_getCountriesCallingCode] = useLazyQuery(GET_CATALOGS_FOR_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  const [getOrderModAttachmentTypes, { loading: loadingAttachmentTypes }] =
    useLazyQuery(GET_ORDER_MOD_ATTACHMENT_TYPES, {
      fetchPolicy: 'network-only',
    });

  const getStaterelatedData = async () => {
    const { data: states } = await getStatesData({
      variables: {
        category: 'ISOStateCode-US',
      },
    });
    const { data: orderModAttachmentTypesData } =
      await getOrderModAttachmentTypes();

    setOrderModAttachmentTypes(
      orderModAttachmentTypesData?.getAllOrderModAttachmentTypes,
    );
    const { data } = await _getCountriesCallingCode({
      variables: { category: 'CountryCallingCode' },
    });
    const phoneCountryCallerCodesModifiedData =
      data?.getCatalogsForCategory?.map(getValuesFromCallingCode);
    phoneCountryCallerCodesModifiedData.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    setCountriesCallingCodeData([...phoneCountryCallerCodesModifiedData]);
    setStatesOptions(
      states?.getCatalogsForCategory
        ?.map(getLabelValueFromItem)
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    );

    const { data: engineerList } = await fetchAssignedEngineersList();
    const uniqueBuyer =
      engineerList?.getStandardsVehicleGroupPocs?.rows?.filter(
        (entry, index, self) =>
          entry.name.trim() !== '' &&
          index === self.findIndex((e) => e.name.trim() === entry.name.trim()),
      );
    const buyerCodeListWithDefault = [
      { label: '--', value: '' },
      ...uniqueBuyer
        ?.map(getLabelValueFromBuyerCode)
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    ];
    setBuyerCodeList(buyerCodeListWithDefault);
  };

  const [createOrderModMutation] = useMutation(CREATE_ORDER_MOD, {
    onError: (error) => {
      setCreateModHasError(true);
      setCreateModErrorMessage(error.message);
    },
    onCompleted: async ({ createOrderModification }) => {
      setModificationId(createOrderModification.orderModificationId);
      setCreateModHasError(false);
      setCreateModErrorMessage('');
      setUpdatedDraft(createOrderModification);
      setChangeSets(createOrderModification.changeSets);
      setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
    },
  });

  const [updateOrderModMutation] = useMutation(UPDATE_ORDER_MODIFICATION, {
    onError: (error) => {
      setUpdateModHasError(true);
      setUpdateErrorMessage(error.message);
    },
    onCompleted: ({ updateOrderModification }) => {
      setUpdateModHasError(false);
      setUpdatedDraft(updateOrderModification);
      setChangeSets(updateOrderModification.changeSets);
    },
  });

  const [getChangeSetDetails, { loading: loadingChangeSet }] = useLazyQuery(
    GET_CHANGE_SET_BY_ID,
    {
      onError: (error) => {
        setContractErrorMessage(error);
      },
      onCompleted: ({ getChangeSetById }) => {
        if (getChangeSetById) {
          setCurrentChangeSet(getChangeSetById);
          setOrderChanges((prev) => ({
            ...prev,
            ...getChangeSetById.modifications,
          }));
        } else {
          setContractErrorMessage('Contract not found');
        }
      },
    },
  );

  const [createChangeSetMutation] = useMutation(CREATE_CHANGE_SET, {
    onError: (error) => {
      setCreateModHasError(true);
      setCreateModErrorMessage(error.message);
    },
    onCompleted: ({ createOrderModChangeSet }) => {
      setChangeSetId(createOrderModChangeSet?.orderChangeSetModId);
      setCurrentChangeSet({
        ...createOrderModChangeSet,
        changeSetNumber: changeSets?.length + 1,
      });
      setSelectedOrdersForMod(
        createOrderModChangeSet?.changeSetOrders?.map((order) => order.order) ||
          [],
      );
      setCurrentStep(OrderModSteps.UPDATE_ORDER_MOD);
      setAgencyContact(AgencyContactInitData);
      setOrderChanges(OrderChangesInitData);
      getOrderModification().then(() => {
        if (selectedOrdersForMod?.length > 0) {
          getOptionsData({
            variables: {
              contractLineId: selectedOrdersForMod[0]?.contractLineId,
            },
          });
        }
      });
    },
  });
  const [updateChangeSetMutation] = useMutation(UPDATE_CHANGE_SET, {
    onError: (error) => {
      setCreateModHasError(true);
      setCreateModErrorMessage(error.message);
      setIsLoaded(true)
    },
    onCompleted: async ({ updateOrderModChangeSet }) => {
      setCurrentChangeSet({
        ...updateOrderModChangeSet,
        changeSetNumber: currentChangeSet?.changeSetNumber || 1,
      });

      setSelectedOrdersForMod(
        updateOrderModChangeSet?.changeSetOrders?.map((order) => order.order) ||
          [],
      );
      setOrderChanges((prev) => ({
        ...prev,
        ...currentChangeSet?.modifications,
      }));
      await getOrderModification();
      if (selectedOrdersForMod?.length > 0) {
        await getOptionsData({
          variables: {
            contractLineId: selectedOrdersForMod[0]?.contractLineId,
          },
        });
      }
        setIsLoaded(true);

    },
  });

  const [updateChangeSetOrders] = useMutation(UPDATE_CHANGE_SET_ORDERS, {
    onError: (error) => {
      setUpdateModHasError(true);
      setUpdateErrorMessage(error.message);
        setIsLoaded(true);
    },
    onCompleted: async ({ updateOrderModChangeSetOrders }) => {
      setCurrentChangeSet({
        ...updateOrderModChangeSetOrders,
        changeSetNumber: currentChangeSet?.changeSetNumber || 1,
      });

      setSelectedOrdersForMod(
        updateOrderModChangeSetOrders?.changeSetOrders?.map(
          (order) => order.order,
        ) || [],
      );
      await getOrderModification();
      if (selectedOrdersForMod?.length > 0) {
        await getOptionsData({
            variables: {
                contractLineId: selectedOrdersForMod[0]?.contractLineId,
            },
        });
      }
      setIsLoaded(true);
    },
  });
  const updateOrderModificationMutation = async () => {
    await updateOrderModMutation({
      variables: {
        updatedOrderModificationRequest: {
          modificationId: updatedDraft?.orderModificationId || modificationId,
          changeSets: {},
        },
      },
    });
  };
  const getOrderModChangeSet = async () => {
    if (changeSetId || currentChangeSet?.orderChangeSetModId) {
      const id = changeSetId || currentChangeSet?.orderChangeSetModId;
      await getChangeSetDetails({
        variables: {
          changeSetId: id,
        },
      });
    }
  };
  const createOrderModChangeSetMutation = async () => {
    setIsLoaded(false);
    await createChangeSetMutation({
      variables: {
        modificationId: updatedDraft?.orderModificationId || modificationId,
        changeSet: {
          type: currentChangeSetType || currentChangeSet?.type,
          additionalComments: additionalComment,
          modificationData: {},
          orderIds: selectedOrdersForMod.map((order) => order.orderId),
        },
      },
    });
  };
  const updateOrderModChangeSetMutation = async (
    orderIds,
    clearDeliveryInformation,
    changeInSelectedOrders
  ) => {
    const changes = { ...orderChanges };
    delete changes.attachments;
    changes?.selectedOptions?.map((option) => {
      // eslint-disable-next-line no-param-reassign
      delete option.inReq;
      // eslint-disable-next-line no-param-reassign
      delete option.additionalShippingDays;
      return option;
    });

    if (clearDeliveryInformation) {
      changes.requisitionAddress = null;
      changes.requisitionContact = null;
      changes.mailingAddress = null;
      changes.mailingContact = null;
      changes.deliveryAddress = null;
      changes.deliveryContact = null;
    }
    if(changeInSelectedOrders){
        setAgencyContact(AgencyContactInitData);
        setMessage3(null);
        setMessage4(null);
        setMessage5(null)
        changes.agencyContact = null;
        changes.contact = AgencyContactInitData;
        changes.message3 = null;
        changes.message4 = null;
        changes.message5 = null;
    } else {
        changes.contact = agencyContact;
        changes.message3 = message3;
        changes.message4 = message4;
        changes.message5 = message5;
    }
    setIsLoaded(false);
    await updateChangeSetMutation({
      variables: {
        modificationId: updatedDraft?.orderModificationId || modificationId,
        changeSet: {
          changeSetId: currentChangeSet?.orderChangeSetModId,
          type: currentChangeSetType || currentChangeSet?.type,
          additionalComments: additionalComment,
          modificationData: {
            ...changes,
            selectedOptions,
          },
          orderIds:
            orderIds || selectedOrdersForMod.map((order) => order.orderId),
          // selectedOrdersForMod.map((order)) => order.id).filter(order => order)
        },
      },
    });
  };
  const updateOrderModChangeSetOrders = async (orderIds) => {
    setIsLoaded(false);
    await updateChangeSetOrders({
      variables: {
        changeSetOrders: {
          changeSetId: currentChangeSet?.orderChangeSetModId,
          orderIds:
            orderIds || selectedOrdersForMod.map((order) => order.orderId),
        },
      },
    });
  };
  const updateOrderModChangeSetWithEmptyOrdersMutation = async () => {
    await updateOrderModChangeSetOrders([]);
  };

  const terminateOrderModificationMutation = async () => {
    setTerminatedChangeSets((prev) => [
      ...prev,
      selectedChangeset.orderChangeSetModId,
    ]);
  };

  const [getContractByNumber] = useLazyQuery(SEARCH_CONTRACT, {
    onError: (error) => {
      setContractErrorMessage(error);
    },
    onCompleted: async ({ storeGetContractByContractNumber }) => {
      if (storeGetContractByContractNumber) {
        setContractErrorMessage('');
        await createOrderModMutation({
          variables: {
            modificationName: orderModName,
            contractNumber: selectedContract,
          },
        });
      } else {
        setContractErrorMessage('Contract not found');
      }
    },
  });

  const [
    getAllOrdersBy,
    { data: getAllOrdersByData, loading: onOrderModsLoading },
  ] = useLazyQuery(SEARCH_ORDER_MOD, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      currentStep === OrderModSteps.SUBMIT_ORDER_MOD ||
      currentStep === OrderModSteps.UPDATE_ORDER_MOD
    ) {
      (async () => {
        const { data: orderModAttachmentTypesData } =
          await getOrderModAttachmentTypes();
        setOrderModAttachmentTypes(
          orderModAttachmentTypesData?.getAllOrderModAttachmentTypes,
        );
      })();
    }
  }, [currentStep]);

  useEffect(() => {
    if (orderModName && selectedContract) {
      setContractErrorMessage('');
      getContractByNumber({
        variables: {
          contractNumber: selectedContract,
        },
      });
    }
  }, [orderModName, selectedContract]);

  useEffect(() => {
    const filters =
      selectedFilters?.map((filter) => {
        return {
          operator: filter.key === 'orderRange' ? '$between' : '$like',
          key: filter.key === 'orderRange' ? 'caseNumber' : filter.key,
          value: filter.value,
        };
      }) || [];

    setNoContractFound('');

    if (selectedFilters?.length > 0) {
      getAllOrdersBy({
        variables: {
          contractNumber: selectedContract,
          query: {
            limit: paginationState.limit,
            offset: paginationState.offset,
            orderBy,
            orderDirection: orderModDirection,
            filters: { operator: '$or', conditions: filters },
          },
        },
      });
    } else {
      setOrderModCount(0);
      setOrderModRows([]);
    }
  }, [paginationState, orderBy, orderModDirection, selectedFilters]);

  useEffect(() => {
    if (selectedOrdersForMod) {
      setSameRequisitionNumberMods(
        selectedOrdersForMod.length > 0 &&
          selectedOrdersForMod.every(
            (order, _index, orders) =>
              order.requisitionNumber === orders[0].requisitionNumber,
          ),
      );
    }
  }, [selectedOrdersForMod]);

  useEffect(() => {
    if (!getAllOrdersByData) {
      return;
    }
    const { searchOrdersByContract: data } = getAllOrdersByData;
    if (!data?.rows?.length) {
      setNoContractFound(
        `No orders can be found within contract ${selectedContract} per your entries`,
      );
    } else {
      setNoContractFound('');
    }
    setOrderModCount(data?.count);
    setOrderModRows(data?.rows);

    getStaterelatedData();
  }, [getAllOrdersByData]);

  const [mailingSameAsRequisition, setMailingSameAsRequisition] =
    useState(false);
  const [deliverySameAsRequisition, setDeliverySameAsRequisition] =
    useState(false);
  const formGeneratorRef = {
    requisitionAddress: useRef(null),
    mailingAddress: useRef(null),
    deliveryAddress: useRef(null),
    agencyContact: useRef(null),
  };

  const value = {
    orderModRows,
    setOrderModRows,
    orderModCount,
    onOrderModsLoading,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderModDirection,
    setOrderModDirection,
    getAllOrdersBy,
    orderModName,
    setOrderModName,
    createModHasError,
    createModErrorMessage,
    setCreateModHasError,
    setCreateModErrorMessage,
    updatedDraft,
    setSelectedFilters,
    selectedFilters,
    selectedOrdersForMod,
    setSelectedOrdersForMod,
    currentStep,
    setCurrentStep,
    setSelectedContract,
    selectedContract,
    contractErrorMessage,
    noContractFound,
    updateModErrorMessage,
    updateModHasError,
    currentChangeSet,
    setCurrentChangeSet,
    statesOptions,
    countriesCallingCodeData,
    returnSignedUrl,
    orderModHasError,
    setOrderModHasError,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    buyerCodeList,
    selectedOptions,
    setSelectedOptions,
    orderModAttachmentTypes,
    changeSets,
    setChangeSets,
    setUpdatedDraft,
    getOrderModification,
    loadingModification,
    updateOrderModificationMutation,
    terminateOrderModificationMutation,
    getOrderModChangeSet,
    loadingChangeSet,
    createOrderModChangeSetMutation,
    updateOrderModChangeSetMutation,
    updateOrderModChangeSetOrders,
    selectedChangeset,
    setSelectedChangeSet,
    terminatedChangeSets,
    setTerminatedChangeSets,
    additionalComment,
    setAdditionalComment,
    message1,
    setMessage1,
    message2,
    setMessage2,
    message3,
    setMessage3,
    message4,
    setMessage4,
    message5,
    setMessage5,
    submitComment,
    setSubmitComment,
    modificationId,
    changeSetId,
    setChangeSetId,
    setCurrentChangeSetType,
    optedForTermination,
    setOptedForTermination,
    orderChanges,
    setOrderChanges,
    updateOrderModChangeSetWithEmptyOrdersMutation,
    requisitionAddress,
    setRequisitionAddress,
    agencyContact,
    setAgencyContact,
    orderModAttachments,
    setOrderModAttachments,
    submitOrderModHasError,
    setSubmitOrderModHasError,
    submitOrderModErrorMessage,
    setSubmitOrderModErrorMessage,
    mailingSameAsRequisition,
    setMailingSameAsRequisition,
    deliverySameAsRequisition,
    setDeliverySameAsRequisition,
    formGeneratorRef,
    getOptionsData,
    optionsLoading,
    optionsByContractData,
    changeSetError,
    setChangeSetError,
    loadingAttachmentTypes,
    isLoaded,
    paginationState,
    setPaginationState,
    minRequiredOptions,
    minReqOptionsLoading,
    sameRequisitionNumberMods,
    changeSetOrderWithPO,
    setChangeSetOrderWithPO,
  };

  return (
    <BulkOrderModsContext.Provider value={value}>
      {children}
    </BulkOrderModsContext.Provider>
  );
}

BulkOrderModProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
