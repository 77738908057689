import React from 'react';
import { Spinner } from '@gsa/afp-component-library';

const OverlaySpinner = () => {
  return (
    <div
      role="none"
      className="afp-modal-overlay"
      style={{ background: 'rgba(0, 0, 0, 0.25)' }}
      data-testid="overlay-spinner"
    >
      <Spinner
        size="large"
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
        }}
      />
    </div>
  );
};

export default OverlaySpinner;
