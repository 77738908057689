import React from 'react';
import PropTypes from 'prop-types';

import './VALPrintContentTable.scss';

const VALPrintContentTable = ({ columns, data }) => {
  const headers = columns.map((column) => column.accessor);
  return (
    <table className="val-print-table usa-table usa-table--borderless usa-table--full-width">
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.accessor}
              scope="col"
              colSpan={1}
              role="columnheader"
            >
              {column.Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr>
            {headers.map((item) => (
              <td aria-label="td">{row[item]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

VALPrintContentTable.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default VALPrintContentTable;
