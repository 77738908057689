import React, { useContext } from 'react';
import {
  Alert,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import {
  VALIDATION_ERRORS_SECTIONS,
  STEPS,
} from '../../constants/VehicleRequisitionConstants';
import ErrorActions from '../../../../context/ErrorContext/ErrorActions';
import ErrorContext from '../../../../context/ErrorContext/ErrorContext';
import './NonLowBidReason.scss';

const justificationMsg = {
  exceededLengthAlert:
    'Text you have entered is more than 800 characters, Please check',
  standardAlertMsg:
    'You are required to provide justification for this non-low price selection below before submitting your order.',
};

const NonLowBidReason = () => {
  const { state, setNonLowBidJustification } = useContext(
    VehicleRequisitionContext,
  );
  const { dispatch: errorDispatch, state: errorState } =
    useContext(ErrorContext);
  const { nonLowBidJustification } = state;
  const nonLowBidError = errorState[STEPS.NON_LOW_BID_REASON];

  const dispatchError = () => {
    errorDispatch({
      type: ErrorActions.ADD_ERROR,
      payload: {
        page: STEPS.NON_LOW_BID_REASON,
        form: 'nonLowBidJustification',
        error: VALIDATION_ERRORS_SECTIONS.JUSTIFICATION,
      },
    });
  };

  const clearError = () => {
    errorDispatch({
      type: ErrorActions.REMOVE_ERROR,
      payload: {
        page: STEPS.NON_LOW_BID_REASON,
        form: 'nonLowBidJustification',
        error: VALIDATION_ERRORS_SECTIONS.JUSTIFICATION,
      },
    });
  };

  const validateInput = (value) => {
    if (value?.length === 0) {
      dispatchError();
    } else {
      clearError();
    }
  };

  return (
    <>
      <div>
        <h2 data-testid="justification-main-title" className="usa-h2 title-1">
          Provide justification for your non-low price selection
        </h2>
        <span className="title-2">
          You have selected a non-low price vehicle.{' '}
        </span>
        <span className="title-3">
          In accordance with the Federal Acquisition Regulation Part 16.505, you
          must provide a written justification when the lowest priced vehicle as
          equipped is not selected. Your best value justification will be
          included in the order file and is subject to Inspector General review.
          You may also upload relevant supporting documents for your
          justification.
        </span>
      </div>

      <div className="text-editor-container">
        {nonLowBidError?.nonLowBidJustification?.size > 0 && (
          <Alert
            data-testid="vehicle-requisition-color-selection__alert"
            type="error"
            slim="true"
          >
            <span>
              {nonLowBidError?.nonLowBidJustification?.size > 0
                ? justificationMsg.standardAlertMsg
                : justificationMsg.exceededLengthAlert}
            </span>
          </Alert>
        )}

        <TextInput
          type="textarea"
          label={
            <div className="justification-info">
              Provide justification for your non-low price selection{' '}
              <RequiredFieldIndicator />
            </div>
          }
          data-testid="nonLowBidJustification"
          onChange={(e) => {
            setNonLowBidJustification(e.target.value);
            validateInput(e.target.value);
          }}
          name="nonLowBidJustification"
          value={nonLowBidJustification}
          characterLimit={800}
          inputClass="non-low-bid-text-area"
        />
      </div>
    </>
  );
};

export default NonLowBidReason;
