import React from 'react';
import PropTypes from 'prop-types';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const OrderModAttachments = ({ attachments }) => {
  const renderAttachments = () => {
    const orderModAttachments = attachments.filter(
      (item) => item.changeSetId === null,
    );

    return orderModAttachments?.map((item) => (
      <div>
        <h4> {item?.attachmentType?.description}</h4>
        <AttachmentDownload
          name={item?.fileMetadata?.name}
          metadataId={item?.metadataId}
        />
        <p>Note: {item?.description}</p>
      </div>
    ));
  };
  return (
    <>
      {attachments && attachments.length > 0 && (
        <div className="order-mod-attachment-list">{renderAttachments()}</div>
      )}
    </>
  );
};
OrderModAttachments.propTypes = {
  attachments: PropTypes.objectOf(Object).isRequired,
};

export default OrderModAttachments;
