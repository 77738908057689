import { connectModal, Modal, Button } from '@gsa/afp-component-library';
import { PhotosModalComp } from '@/components/PhotosModal/PhotosModalComp';

export default connectModal(function VehicleImagePreviewModal({
    contract,
    allImages,
    closeModal,
}) {
    return (
        <Modal
            title={
                <div className="modal-title">
                    <span className="model-name">
                        {contract.modelName || contract?.model?.modelCode}
                    </span>
                    <span className="vendor-name">{contract.vendorName}</span>
                </div>
            }
            onClose={() => {
                closeModal()
            }}
            variant="large"
            actions={
                <>
                    <Button
                        data-testid="veh-req-photos-modal"
                        type="button"
                        className="modal-close-button"
                        onClick={() => {
                            closeModal()
                        }}
                        label="Close"
                    />
                </>
            }
        >
            <PhotosModalComp imageData={allImages} />
        </Modal>
    )
});