import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';

import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_FLEET_MANAGEMENT_CENTERS } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import { ZONE } from './filter-item-constants';

const placeholderOption = {
  value: '',
  label: '- Select -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } = FilterPanel;

const FMCFilterItem = ({ filter }) => {
    
    const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([placeholderOption]);
  const [zone, setZone] = useState();

  useEffect(() => {
    clearOneFilter(filter);
    const filteredOptions = options?.filter(location => location?.zoneId === parseInt(zone,10))?.map((marshallingDetails) => ({
        value: marshallingDetails.id,
        label: marshallingDetails.name,
      }))
    setFilterOptions(filteredOptions
        ? [placeholderOption, ...filteredOptions]
        : [placeholderOption])
  }, [zone]);

  useEffect(() => {
    const zoneFilter = getFilterFromState(ZONE) || {};
    setZone(zoneFilter.value);
  }, [mergedFilters]);

  const [
    fetchAgencyFilterOptions,
    { data: marshallingOptions, loading: marshallingLoading },
  ] = useLazyQuery(GET_FLEET_MANAGEMENT_CENTERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAgencyFilterOptions({
      variables: {
        operation: 'view',
        subject: StoreSubjects.Order,
      },
    });
  }, []);

  useEffect(() => {
    if (marshallingOptions?.getFleetManagementCenters) {
    const marshallingList = sortBy(
        marshallingOptions.getFleetManagementCenters,
        'name',
      );
      setOptions(marshallingList);
    }
  }, [marshallingOptions]);

  if (marshallingLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options: filterOptions,
      }}
    />
  );
};

FMCFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default FMCFilterItem;