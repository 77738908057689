import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

import { Link } from 'react-router-dom';
import { Purchase_Vehicles } from '../../constants';

const BreadCrumbs = () => (
  <DSBreadcrumbs
    trail={[
      <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
        Home
      </a>,
      <Link className="text-underline" to="/purchase">
        {Purchase_Vehicles}
      </Link>,
    ]}
    current="Vehicle Listing"
  />
);
export default BreadCrumbs;
