import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  // ALL_VEHICLE_AVAILABILITY,
  SEARCH_VEHICLE_AVAILABILITY,
} from './queries';
import { OrderByMap, exportFile } from './helpers';
import {
  GET_ACTIVE_VEHICLE_GROUPS,
  GET_VAL_SINS,
} from '../../services/data-layer/standards';
import { VEHICLE_GROUP_TAG_STORE } from '../../constants/constants';

export const VehicleAvailabilityContext = createContext({});

export default function VehicleAvailabilityProvider({ children }) {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('standartItemCode');
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);
  const [allStandardItemTypes, setAllStandardItemTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [standardItemTypes, setStandardItemTypes] = useState([]);
  const [valRows, setVALData] = useState([]);
  const [printData, setPrintData] = useState(false);
  const [valPrintRows, setVALPrintData] = useState([]);
  const [valCount, setVALCount] = useState(0);
  const [isReset, setResetPagination] = useState(false);
  const [printContentLoading, setPrintContentLoading] = useState(true);
  const [exportData, setExportData] = useState('');
  // Query for getting Vehicle types
  const [_getVehicleTypes] = useLazyQuery(GET_ACTIVE_VEHICLE_GROUPS, {
    onCompleted: (data) => {
      if (data?.getActiveVehicleGroups) {
        const list = [];
        const sortedItems = [...data?.getActiveVehicleGroups].sort((a, b) =>
          a.title.localeCompare(b.title),
        );
        sortedItems.forEach((i) => {
          list.push({
            label: i.title,
            value: i.code,
          });
        });

        setAllVehicleTypes(list);
      }
    },
    fetchPolicy: 'no-cache',
    variables: {
      childTag: VEHICLE_GROUP_TAG_STORE,
    },
  });
  const [_getVALSins] = useLazyQuery(GET_VAL_SINS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getVALSins) {
        setAllStandardItemTypes(
          data.getVALSins.map((i) => ({
            label: `${i.standardItemNumber} - ${i.title}`,
            value: i.id,
          })),
        );
      } else {
        setAllStandardItemTypes([]);
      }
    },
  });
  // Query for getting VAL data
  const [
    getVehicleAvailability,
    { data: vehicleAvailabilityListingData, loading: onVALDataLoading },
  ] = useLazyQuery(SEARCH_VEHICLE_AVAILABILITY, {
    fetchPolicy: 'c',
  });

  useEffect(() => {
    _getVehicleTypes();
  }, []);

  useEffect(() => {
    _getVALSins({
      variables: {
        vehicleTypeIds: vehicleTypes.map((i) => i.value),
      },
    });
  }, [vehicleTypes]);

  // Getting VAL data on selecting vehicleTypes & standardItems
  useEffect(() => {
    getVehicleAvailability({
      variables: {
        ...(vehicleTypes.length && {
          vehicleTypeIds: vehicleTypes.map((i) => i.value),
        }),
        ...(standardItemTypes.length && {
          standardItemIds: standardItemTypes.map((i) => Number(i.value)),
        }),
        order,
        orderBy: OrderByMap[orderBy] || 'standardItem',
        offset: (currentPage - 1) * perPage,
        limit: perPage,
      },
    });
  }, [vehicleTypes, standardItemTypes, order, orderBy, currentPage, perPage]);

  // Resetting filters on getting new VAL data
  useEffect(() => {
    if (!vehicleAvailabilityListingData?.searchVAL) {
      return;
    }
    const { searchVAL } = vehicleAvailabilityListingData;
    setVALData(searchVAL.rows);
    setVALCount(searchVAL.count);
  }, [vehicleAvailabilityListingData]);

  const exportVALListings = (format, listings) => {
    exportFile(format, listings);
    setExportData('');
  };

  // Query for getting All VAL data when trigger export function. Separate dependecy from useEffect for vehicleAvailabilityListingData.
  const [getAllVALData, { loading: AllVALDataLoading }] = useLazyQuery(
    SEARCH_VEHICLE_AVAILABILITY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { searchVAL } = data;
        if (searchVAL) {
          exportVALListings(exportData, searchVAL.rows);
        }
      },
    },
  );

  const [getAllVALDataForPrint, { loading: printDataLoading }] = useLazyQuery(
    SEARCH_VEHICLE_AVAILABILITY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { searchVAL } = data;
        if (searchVAL) {
          setVALPrintData(searchVAL.rows);
        }
      },
    },
  );

  useEffect(() => {
    if (exportData) {
      let standardItemIds = [];
      if (allStandardItemTypes && allStandardItemTypes.length > 0) {
        standardItemIds = allStandardItemTypes.map((i) => Number(i.value));
      }
      if (standardItemTypes && standardItemTypes.length > 0) {
        standardItemIds = standardItemTypes.map((i) => Number(i.value));
      }
      getAllVALData({
        variables: {
          standardItemIds,
          order,
          orderBy: OrderByMap[orderBy] || 'standardItemCode',
          offset: 0,
          limit: valCount,
        },
      });
    }
  }, [exportData]);

  useEffect(() => {
    if (printData) {
      let standardItemIds = [];
      if (allStandardItemTypes && allStandardItemTypes.length > 0) {
        standardItemIds = allStandardItemTypes.map((i) => Number(i.value));
      }
      if (standardItemTypes && standardItemTypes.length > 0) {
        standardItemIds = standardItemTypes.map((i) => Number(i.value));
      }
      getAllVALDataForPrint({
        variables: {
          standardItemIds,
          order,
          orderBy: OrderByMap[orderBy] || 'standardItemCode',
          offset: 0,
          limit: valCount,
        },
      });
    }
  }, [printData]);

  const value = {
    allVehicleTypes,
    allStandardItemTypes,
    vehicleTypes,
    setVehicleTypes,
    standardItemTypes,
    setStandardItemTypes,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    valRows,
    valCount,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    isReset,
    setResetPagination,
    onVALDataLoading,
    printContentLoading,
    setPrintContentLoading,
    setExportData,
    AllVALDataLoading,
    printData,
    setPrintData,
    valPrintRows,
    printDataLoading,
  };

  return (
    <VehicleAvailabilityContext.Provider value={value}>
      {children}
    </VehicleAvailabilityContext.Provider>
  );
}

VehicleAvailabilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
