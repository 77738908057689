import React, { useEffect, useState } from 'react';
import { Alert, Button } from '@gsa/afp-component-library';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import DisplayRequisitionMessageLines from './DisplayRequsitionMessageLines';
import RequisitionMessageLinesForm from './RequisitionMessageLinesForm';
import {
  GET_REQUISITION_MESSAGE_LINES,
  UPDATE_REQUISITION_MESSAGE_LINE,
} from '../../../../services/data-layer';
import './RequisitionMessageLines.scss';

const RequisitionMessageLines = ({ requisitionId, isEditAllowed }) => {
  const [currentMode, setCurrentMode] = useState('view');
  const [messageLines, setMessageLines] = useState(null);
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

  const [getReqMessageLines, { data: messageLinesRes }] = useLazyQuery(
    GET_REQUISITION_MESSAGE_LINES,
    { fetchPolicy: 'no-cache' },
  );

  const [submitReqMessageLines] = useMutation(UPDATE_REQUISITION_MESSAGE_LINE);

  const formProps = useForm({
    defaultValues: {
      messageLine1: '',
      messageLine2: '',
      messageLine3: '',
      messageLine4: '',
      messageLine5: '',
    },
    reValidateMode: 'onChange',
  });

  const { setValue, getValues } = formProps;

  const handleRMLFormSubmit = async () => {
    const data = {
      requisitionId: messageLines?.requisitionId ?? requisitionId,
      requisitionMessageLinesId:
        messageLines?.requisitionMessageLinesId ?? uuidv4(),
      messageLine1: getValues('messageLine1'),
      messageLine2: getValues('messageLine2'),
      messageLine3: getValues('messageLine3'),
      messageLine4: getValues('messageLine4'),
      messageLine5: getValues('messageLine5'),
    };

    await submitReqMessageLines({ variables: { data } }); // Upsert message lines
    setMessageLines(data);
    setCurrentMode('view');
    setUpdatedSuccessfully(true);
  };

  useEffect(() => {
    getReqMessageLines({
      variables: { requisitionId },
    });
  }, []);

  useEffect(() => {
    if (messageLinesRes && messageLinesRes?.getRequisitionMessageLinesById) {
      setMessageLines(messageLinesRes.getRequisitionMessageLinesById);
      setValue(
        'messageLine1',
        messageLinesRes.getRequisitionMessageLinesById.messageLine1 ?? null,
      );
      setValue(
        'messageLine2',
        messageLinesRes.getRequisitionMessageLinesById.messageLine2 ?? null,
      );
      setValue(
        'messageLine3',
        messageLinesRes.getRequisitionMessageLinesById.messageLine3 ?? null,
      );
      setValue(
        'messageLine4',
        messageLinesRes.getRequisitionMessageLinesById.messageLine4 ?? null,
      );
      setValue(
        'messageLine5',
        messageLinesRes.getRequisitionMessageLinesById.messageLine5 ?? null,
      );
    }
  }, [messageLinesRes]);

  return (
    <section role="tab" tabIndex="0" className="margin-bottom-2 margin-top-1  ">
      <div>
        <div className="font-body-lg text-bold">
          Contracting officer message lines
        </div>
        <div>
          <p className="font-body-sm" />
          {updatedSuccessfully && (
            <Alert type="success" onClose={() => setUpdatedSuccessfully(false)}>
              Message lines saved successfully
            </Alert>
          )}
        </div>

        <div>
          <div className="bg-primary-lightest padding-bottom-2 padding-top-05 padding-2">
            {isEditAllowed && (
              <div className="margin-bottom-2 grid-row">
                <div className="grid-col font-body-md">
                  Add message lines and click &quot;Save&quot; to update
                </div>
                <div className="grid-col">
                  {currentMode === 'edit' && (
                    <Button
                      variant="unstyled"
                      type="reset"
                      className="margin-top-1 float-right "
                      onClick={() => setCurrentMode('view')}
                      label="Cancel"
                    />
                  )}

                  {currentMode === 'edit' && (
                    <Button
                      variant="outline"
                      className="margin-top-1 float-right"
                      onClick={handleRMLFormSubmit}
                      leftIcon={{ name: 'edit' }}
                      label="Save"
                    />
                  )}

                  {currentMode === 'view' && (
                    <Button
                      variant="outline"
                      className="margin-top-1 float-right"
                      onClick={() => setCurrentMode('edit')}
                      leftIcon={{ name: 'edit' }}
                      label="Edit"
                    />
                  )}
                </div>
              </div>
            )}

            {currentMode === 'view' && (
              <DisplayRequisitionMessageLines messageLines={messageLines} />
            )}

            {currentMode === 'edit' && (
              <FormProvider {...formProps}>
                <form data-testid="message-lines-form" className="">
                  <RequisitionMessageLinesForm />
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

RequisitionMessageLines.propTypes = {
  requisitionId: PropTypes.string,
  isEditAllowed: PropTypes.bool,
};

RequisitionMessageLines.defaultProps = {
  requisitionId: null,
  isEditAllowed: false,
};

export default RequisitionMessageLines;
