import z from 'zod';

export const receiptStatusModalSchema = z.object({
  receivedDate: z.coerce
    .date()
    .refine(
      (date) => {
        return date.getTime() !== 0;
      },
      { message: 'Agency received date is required' },
    )
    .refine(
      (date) => {
        return date <= new Date();
      },
      { message: 'The date selected can not be greater than the current date' },
    ),
});
