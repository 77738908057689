import React, { useState, useContext, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { TextInput, Button, Modal, Alert } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import {
  FETCH_SIGNED_URL,
  CREATE_REQUISITION_ATTACHMENT,
} from '../../../../services/data-layer';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import WarningSvg from '../../../../assets/images/warning-icon.svg';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { isExecFile } from '../../../../utilities/file';

const { appURLs } = window.AFP_CONFIG;
let setInActive = true;

const OptionDetailFileUploadModal = ({
  onClose,
  modalInfo,
  download,
  refetch,
  page,
}) => {
  const { state, updateDraftRequisition, dispatch } = useContext(
    VehicleRequisitionContext,
  );
  const { draftRequisition, totalUploadedFiles, requisitionAttachmentTypes } =
    state;

  const hiddenFileInput = useRef(null);
  const [uploadedFile, setUploadedFile] = useState({});
  const [note, setNote] = useState('');
  const [networkReturnedUrl, setNetworkReturnedUrl] = useState({});
  const [currentFile, setCurrentFile] = useState({});
  const [fileSizeCheck, setFileSizeCheck] = useState(false);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  let foundAttachmentType = {};

  if (page === 'OPTION_DETAILS') {
    foundAttachmentType = requisitionAttachmentTypes.find(
      (a) => a.attachmentType === 'req_purchaseline_equipment',
    );
  } else {
    foundAttachmentType = requisitionAttachmentTypes.find(
      (a) => a.attachmentType === 'req_color_options',
    );
  }

  useEffect(() => {
    if (modalInfo?.selectedItem) {
      setUploadedFile(cloneDeep(modalInfo?.selectedItem));
    } else {
      setUploadedFile({});
    }
  }, [modalInfo?.selectedItem]);

  useEffect(() => {
    if (draftRequisition?.requisitionId && totalUploadedFiles) {
      updateDraftRequisition(draftRequisition.requisitionId);
    }
  }, [totalUploadedFiles]);

  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL, {
    onCompleted: (data) => {
      setNetworkReturnedUrl(data.generateSignedUrl);
    },
  });

  const [createAttachment] = useMutation(CREATE_REQUISITION_ATTACHMENT, {
    onCompleted: (data) => {
      if (foundAttachmentType?.attachmentType === 'req_color_options') {
        const obj = cloneDeep(uploadedFile) || {};

        if (
          uploadedFile &&
          uploadedFile[modalInfo?.selectedOption]?.items?.length
        ) {
          obj[modalInfo?.selectedOption].items.push({
            file: uploadedFile,
            note,
            attachmentId: data.createRequisitionAttachment.id,
            metadataId: networkReturnedUrl?.metadataId,
          });
        } else {
          obj[modalInfo?.selectedOption] = {
            items: [
              {
                file: uploadedFile,
                note,
                attachmentId: data.createRequisitionAttachment.id,
                metadataId: networkReturnedUrl?.metadataId,
              },
            ],
          };
        }

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_TOTAL_UPLOADED_FILES,
          payload: obj,
        });

        setUploadedFile(obj);
      }
      onClose();
    },
  });

  const toBeRounded = (bytes) => {
    const converted = bytes / (1024 * 1024);
    const size = converted.toFixed(2);
    return Number(size);
  };

  const handleAddFiles = async () => {
    axios
      .put(networkReturnedUrl.signedUrl, currentFile, {
        headers: {
          'Content-Type': currentFile.type,
        },
      })
      .then(async (res) => {
        await createAttachment({
          variables: {
            input: {
              requisitionId: draftRequisition?.requisitionId,
              metadataId: networkReturnedUrl?.metadataId,
              name: res.config.data.name,
              status: 0,
              attachmentTypeId:
                foundAttachmentType?.requisitionAttachmentTypeId,
              description: note,
              docStoreUri: appURLs.store,
              size: toBeRounded(uploadedFile.size),
              fileMimeType: currentFile.type,
              signedUrl: res.config.url,
              equipmentCode: uploadedFile.selectedOption,
            },
          },
        });

        refetch();
      });

    return null;
  };

  function handleChange(event) {
    const newObj = event.target.files[0];
    isExecFile(newObj).then((isExec) => {
      if (isExec) {
        setFileSizeCheck(false);
        setInvalidFileType(true);
        event.preventDefault();
        return;
      }

      setInvalidFileType(false);

      if (newObj?.size <= 1024 * (1024 * 10)) {
        setCurrentFile(newObj);
        setFileSizeCheck(false);
        const fileUploadedObj = {
          name: newObj.name,
          size: newObj.size,
          selectedOption: modalInfo?.selectedOption,
        };
        fileUploadedObj.selectedOption = modalInfo?.selectedOption;

        setUploadedFile(fileUploadedObj);

        returnSignedUrl({
          variables: {
            input: {
              requisitionId: draftRequisition?.requisitionId,
              name: fileUploadedObj?.name,
              status: 0,
              attachmentTypeId:
                foundAttachmentType?.requisitionAttachmentTypeId,
              description: '',
              docStoreUri: appURLs.store,
              size: toBeRounded(fileUploadedObj?.size),
              fileMimeType: newObj.type,
            },
          },
        });
      } else {
        setFileSizeCheck(!fileSizeCheck);
      }

      setInActive = false;
    });
  }

  const deleteUploadFile = () => {
    setUploadedFile({});
    setCurrentFile({});
    setInActive = true;
    hiddenFileInput.current.value = '';
  };

  return (
    <div className="selected-paint-list-section">
      {(modalInfo?.action === 'delete' || modalInfo?.selectedOption) && (
        <div className="afp-modal-overlay modalContainer draft-modal">
          <Modal
            title={
              <h2>
                {modalInfo?.selectedItem
                  ? 'Edit file documentation'
                  : 'Upload file'}
              </h2>
            }
            className="save-draft-modal"
            onClose={() => {
              setUploadedFile({});
              setCurrentFile({});
              setNote('');
              setTooltip(false);
              onClose();
              setInActive = true;
            }}
            actions={
              <div className="save-draft-requisition-button-row">
                <Button
                  type="button"
                  variant="unstyled"
                  className="save-draft-requisition-action-button"
                  data-testid="save-draft-modal-cancel-button"
                  onClick={() => {
                    setUploadedFile({});
                    setCurrentFile({});
                    setNote('');
                    setTooltip(false);
                    onClose();
                    setInActive = true;
                  }}
                  label="Cancel"
                />
                <Button
                  disabled={setInActive}
                  type="button"
                  variant="default"
                  className="save-draft-requisition-action-button"
                  data-testid="submit-requisition-modal-save-button"
                  onClick={() => {
                    handleAddFiles();
                    setUploadedFile({});
                    setCurrentFile({});
                    setNote('');
                  }}
                  label={!modalInfo?.selectedItem ? 'Add file' : 'Save'}
                />
              </div>
            }
          >
            {!modalInfo?.selectedItem && (
              <>
                {fileSizeCheck && (
                  <Alert type="error" className="margin-top-4">
                    10MB is your file size limit.
                  </Alert>
                )}
                {invalidFileType && (
                  <Alert type="error" className="margin-top-4">
                    Only accepts .pdf, .docx, .jpeg files.
                  </Alert>
                )}
                <div className="file-upload-title-2">Supporting file</div>
                <div className="file-upload-desc-1">
                  Accepts .pdf, .docx, .jpeg files
                </div>

                {uploadedFile?.name && (
                  <div
                    className="enable-tooltip"
                    role="button"
                    aria-label="upload"
                    tabIndex={0}
                    onClick={() => {
                      if (uploadedFile?.name) {
                        setTooltip(!tooltip);
                      }
                    }}
                    onKeyDown={() => {}}
                  />
                )}

                <div className="tooltip-doc-upload">
                  <div className="usa-file-input">
                    <div className="usa-file-input__target">
                      <div
                        className="usa-file-input__instructions"
                        aria-hidden="true"
                      >
                        <span className="usa-file-input__drag-text">
                          {' '}
                          Drag file here or&nbsp;{' '}
                        </span>
                        <span className="usa-file-input__choose">
                          {' '}
                          choose from folder{' '}
                        </span>{' '}
                        (maximum size: 10 MB)
                      </div>
                      <div className="usa-file-input__box" />
                      <input
                        disabled={uploadedFile?.name}
                        name="file"
                        title=""
                        ref={hiddenFileInput}
                        id="attachment-file-input-single"
                        data-testid="attachment-file-input"
                        className="usa-file-input__input"
                        type="file"
                        aria-describedby="file-input-specific-hint"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        accept=".pdf,.docx, .jpeg,"
                      />
                    </div>
                  </div>

                  {tooltip && (
                    <div className="top">
                      <div className="waning-tooltip-section">
                        <img src={WarningSvg} alt="warning icon" />
                        <span className="warning-msg">
                          You cannot upload more than one file in this window.
                        </span>
                      </div>
                      <i />
                    </div>
                  )}
                </div>
              </>
            )}

            {uploadedFile?.name && (
              <div className="file-uploaded-section">
                {modalInfo?.selectedItem ? (
                  <span className="grid-col-6">
                    <Button
                      data-testid="download-button-modal"
                      variant="unstyled"
                      onClick={() => download(uploadedFile)}
                      label={uploadedFile.name}
                    />
                  </span>
                ) : (
                  <span className="grid-col-6">{uploadedFile.name}</span>
                )}
                <span className="grid-col-4">
                  {' '}
                  {toBeRounded(uploadedFile.size)}MB
                </span>
                {!modalInfo?.selectedItem && (
                  <span className="grid-col-2">
                    <Button
                      data-testid="delete-button"
                      type="button"
                      variant="unstyled"
                      className="delete"
                      onClick={() => deleteUploadFile()}
                      label="Delete"
                    />
                  </span>
                )}
              </div>
            )}

            <div className="">
              <Alert type="warning" className="margin-top-4">
                Help prevent a privacy incident by ensuring that any supporting
                document uploaded here does not contain{' '}
                <a
                  href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  personally identifiable information
                </a>{' '}
                (PII).
              </Alert>
            </div>

            <div className="upload-section">
              <div className="file-upload-title-2">Note</div>
              <span>A few words to help identify this document</span>
              <TextInput
                id="input-section-comment"
                data-testid="input-section-comment"
                type="textarea"
                maxLength={100}
                errorMessage=""
                defaultValue={
                  note?.length ? note : modalInfo?.selectedItem?.note
                }
                onBlur={({ target }) => {
                  if (uploadedFile?.name) {
                    setInActive = false;
                  } else {
                    setInActive = true;
                  }
                  setNote(target.value);
                }}
              />
              <div>100 characters allowed</div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

OptionDetailFileUploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalInfo: PropTypes.instanceOf(Object),
  download: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

OptionDetailFileUploadModal.defaultProps = {
  modalInfo: {},
};

export default OptionDetailFileUploadModal;
