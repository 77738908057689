import React from 'react';
import withDraftRequisition from '../../hocs/withDraftRequistion/withDraftRequisition';
import MasRequisition from '../../pages/non-standard-purchase/mas-requisition/MasRequisition';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';

const MasRequirementContainer = () => {
  return withDraftRequisition(MasRequisition, true);
};

const MasRequirementContainerWithContext = () => {
  return (
    <VehicleRequisitionContextProvider>
      <MasRequirementContainer />
    </VehicleRequisitionContextProvider>
  );
};

export default MasRequirementContainerWithContext;
