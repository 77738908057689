import React from 'react';
import { Tag } from '@gsa/afp-component-library';
import { useStoreReportsContext } from '../useStoreReportsContext';

const SelectedReport = () => {
  const { reportName, isFirstStep } = useStoreReportsContext();
  if (!reportName || isFirstStep()) return null;
  return (
    <div className="grid-col-5 margin-bottom-7">
      <div className="border-left-2px border-base-lighter padding-left-2">
        <h3 className="title-s margin-bottom-05 border-base-lighter border-bottom-2px">
          Report
        </h3>
        <Tag
          variant="Info-Light"
          className="text-accent-cool-darker"
          style={{ textTransform: 'none' }}
          data-testid="selected-report-tag"
        >
          {reportName}
        </Tag>
      </div>
    </div>
  );
};

export default SelectedReport;
