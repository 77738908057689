import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from '@gsa/afp-component-library';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AdditionalReqFilesTable from '../../../../components/MasAdditionalRequirementsTable/MasAdditionalRequirementsTable';
import { findErrors } from '../utils/ServiceUtils';
import '../MasRequisition.scss';

const UploadRequirements = ({ attachmentTypes }) => {
  const {
    formState: {
      errors: { additionalRequirements: formErrors },
    },
  } = useFormContext();

  const reqErrors =
    formErrors?.length &&
    formErrors[0].attachments &&
    formErrors[0].attachments?.root
      ? formErrors[0].attachments.root
      : null;

  const validation = (value) => {
    const errors = findErrors(value, attachmentTypes);
    if (errors) {
      return `${errors}`;
    }
    return null;
  };

  const { fields, append, remove, update } = useFieldArray({
    name: 'additionalRequirements.0.attachments',
    rules: {
      validate: (value) => validation(value),
    },
  });

  return (
    <>
      <div className="urgent-req-title-2">Documentation</div>
      {reqErrors && (
        <div className="requirements-alert">
          <Alert slim type="error">
            <div className="error-title">
              This section is missing the following required file documents.
            </div>
            {reqErrors.message.split('_').map((req) => (
              <li className="error-link">{req}</li>
            ))}
            <div className="error-text">
              In order to continue, please upload all required file documents,
              and be sure to select the corresponding document category for
              each.
            </div>
          </Alert>
        </div>
      )}
      <div className="top-padding-10 margin-botton-30">
        You may load multiple files; however, the combined size of all files
        cannot exceed 4 MB. File name will be converted to acceptable system
        files. All special characters will be removed. File names should be
        alpha numeric, and can also include underscores, commas periods and
        blank spaces. File types supported include Word, Excel, text, PDF, JPEG,
        etc.
      </div>

      {attachmentTypes?.length > 1 && (
        <AdditionalReqFilesTable
          reqFiles={fields}
          append={append}
          remove={remove}
          update={update}
          reqType="MAS"
          attachmentTypes={attachmentTypes}
        />
      )}
    </>
  );
};

export default UploadRequirements;

UploadRequirements.propTypes = {
  attachmentTypes: PropTypes.instanceOf(Array).isRequired,
};
