import { GET_ACTIVE_VEHICLE_GROUPS } from '@/services/data-layer/standards';
import { useDispatchQuery } from '../hooks/useDispatchQuery';
import {
    setReplacementVehiclesTableTotal,
    setActiveContracts,
    setMinOptions,
    setCalculatedPriceBreakdown,
    setFeatureOptions,
    setAddOptions,
    setStandardCodes,
    setAttachmentTypes,
    setAttachments
} from '../reducers/leasing';
import { UPDATE_REQUISITION_DRAFT_NEW } from "../services/create-requisition.jsx";
import { getGroupedMinimumRequirementsData, getGroupedVehicleInfoData } from '@/utilities/CompareVehicleUtils';
import {
    CREATE_LEASING_REQUISITION_DRAFT,
    GET_LEASING_REQUISITION,
    VEHICLE_EXISTS_IN_REQUISITION,
    GET_REQUISITION_CART,
    GET_VEHICLE_BY_FILTER,
    GET_ZONES,
    GET_CATALOGS_FOR_CATEGORY,
    CANCEL_REQUISITION,
    CREATE_LEASING_REPLACEMENT_VEHICLE,
    DELETE_LEASING_REPLACEMENT_VEHICLE,
    GET_LEASING_REPLACEMENT_VEHICLES,
    GET_STANDARD_ITEM,
    GET_PROGRAM_NAME_BY_STD_CODE,
    GET_ACTIVE_CONTRACTS,
    GET_OPTIONAL_REQ,
    GET_MIN_REQ,
    GET_CALCULATED_PRICE,
    GET_STANDARD_CODES,
    GET_FEATURE_OPTIONS,
    GET_CONTRACT_LINE_MODEL_COLORS,
    GET_REQUISITION_ATTACHMENT_TYPES,
    GENERATE_READ_SIGNED_URL,
    GET_REQUISITION_ATTACHMENTS,
    CREATE_REQUISITION_ATTACHMENT,
    FETCH_SIGNED_URL,
    GET_FLEET_MANAGEMENT_CENTERS,
    GET_ALL_VENDOR_MARSHALLER_LOCATIONS,
    GET_USER_BY_ID,
    GET_PDF_URL,
    GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE_CITY,
    GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE,
    GET_STATES,
    GET_REQUISITION_ACTIVITIES,
    SUBMIT_FMC_APPROVAL,
    SUBMIT_ZONAL_APPROVAL,
    SUBMIT_LEASING_REQ_TO_GSA,
    GET_PURCHASE_COLLISION_FOR_ALL_VENDOR_ON_YES, GET_PURCHASE_COLLISION_FOR_ALL_VENDOR,
    RETURN_REQUISITION,
    GET_LEASING_FUNDING_AVAILABILITY,
    SUBMIT_ORDERING_ADMIN_APPROVAL,
    GET_LEASING_REQUISITION_TABLE_DETAILS
} from '../services/data-layer';
import { GET_ACTIVE_VEHICLE_GROUP_TYPES, GET_REQUISITION_SINS } from "../services/data-layer/standards.jsx";
import { TRANSACTION_TYPES } from '../utilities/CompareVehicleUtils.jsx';


export function useGetVehicleByFilterWithZones() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_VEHICLE_BY_FILTER, {
        apolloOptions: {
            fetchPolicy: 'no-cache'
        },
        transformResult: async ({ data, getState }) => {
            if (!data) {
                throw new Error('No vehicle found. Verify vehicle information.');
            }

            let { zones } = getState().leasingReducer;
            const zone = zones?.find(zone => zone?.id == data?.fleetManagementCenter?.zoneId);

            let vehicle = structuredClone(data);
            if(zone) {
                vehicle.fleetManagementCenter.zone = zone;
            }

            return vehicle;
        }
    });
}

export function useGetZones() {
    const { runQuery } = useDispatchQuery();

    return runQuery(GET_ZONES);
}

export function useVehicleExistsInRequisition() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(VEHICLE_EXISTS_IN_REQUISITION, {
      apolloOptions: {
        networkPolicy: 'no-cache',
      }
    });
}

export function useCreateLeasingDraft() {
    const { registerMutation } = useDispatchQuery();

    return registerMutation(CREATE_LEASING_REQUISITION_DRAFT);
}

export function useGetLeasingRequisition(requisitionId) {
    const { runQuery } = useDispatchQuery();

    return runQuery(GET_LEASING_REQUISITION, {
        apolloOptions: {
            skip: !requisitionId,
            fetchPolicy: 'no-cache'
        },
        variables: {
            requisitionId
        }
    })
}

export function useGetLeasingRequisitionLazily() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_LEASING_REQUISITION, {
        apolloOptions: {
            fetchPolicy: 'no-cache'
        }
    })
}

export function useGetLeasingRequisitionTableDetailsLazily() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_LEASING_REQUISITION_TABLE_DETAILS, {
        apolloOptions: {
            fetchPolicy: 'no-cache'
        },
    })
}

export function useCreateLeasingVehicleReplacement() {
    const { registerMutation } = useDispatchQuery();

    return registerMutation(CREATE_LEASING_REPLACEMENT_VEHICLE);
}

export function useDeleteLeasingVehicleReplacement() {
    const { registerMutation } = useDispatchQuery();

    return registerMutation(DELETE_LEASING_REPLACEMENT_VEHICLE);
}

export function useGetLeasingReplacementVehicles() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_LEASING_REPLACEMENT_VEHICLES, {
        apolloOptions: {
            fetchPolicy: 'no-cache'
        },
        transformResult: async ({ data, dispatch }) => {
            dispatch(setReplacementVehiclesTableTotal(data.count))
            const rows = data.rows.map(vehicle => ({
                ...vehicle,
                vin: vehicle.serialNumberVin,
                standardItemNumber: vehicle.standardItemNumber,
            }));
            return {rows, count: data.count}
        }
    });
}

export function useCancelRequisition() {
    const { registerMutation } = useDispatchQuery();

    return registerMutation(CANCEL_REQUISITION);
}

export function useGetStates(options = {}) {
    const { runQueryNow } = useDispatchQuery();

    return runQueryNow(GET_CATALOGS_FOR_CATEGORY, options);
}

export function useGetStatesOptions() {
    const { runQueryNow } = useDispatchQuery();

    const { data, loading } = runQueryNow(GET_CATALOGS_FOR_CATEGORY, {
        variables: { category: `ISOStateCode-US` },
        transformResult: ({ data }) => {
            return data.map(({ description, code }) => ({
                label: description,
                value: code.split('-')[1],
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        }
    });

    return { data, loading }
}

export function useGetStateList() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_STATES);
}

export function useGetCountries(options = {}) {
    const { runQueryNow } = useDispatchQuery();

    return runQueryNow(GET_CATALOGS_FOR_CATEGORY, options);
}

export function useGetVehicleTypes(options = {}) {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_ACTIVE_VEHICLE_GROUPS, options);
}

export function useGetStandardItem() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_STANDARD_ITEM)
}

export function useGetProgrammeName() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_PROGRAM_NAME_BY_STD_CODE)
}

export function useGetDealershipsByCityStateMake() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE_CITY);
}

export function useGetCitybyStateId() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE);
}

export function useGetDealershipStates(shipmentLocation, deliveryDealershipStateId) {
    const { runQueryNow } = useDispatchQuery();
    const { data, loading } = runQueryNow(GET_STATES, {
        transformResult: ({ data }) => {
            const defaultState = data?.find((state) =>
                deliveryDealershipStateId
                  ? parseInt(state?.id, 10) ===
                    parseInt(deliveryDealershipStateId, 10)
                  : state.stateName === shipmentLocation,
              );
            return defaultState ? { stateId : parseInt(defaultState.id, 10), stateCode: defaultState.stateCode }: {};
        }
    });

    return { data, loading }
}

export function useGetActiveContracts(pageNumber = 0, offset = 1) {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_ACTIVE_CONTRACTS, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No active contracts found.');
            }
            dispatch(setActiveContracts(data));
        }
    });
}

export function useGetAddOptions() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_OPTIONAL_REQ, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No optional options found.');
            }
            dispatch(setAddOptions(data));
        }
    });
}

export function useGetMinOptions() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_MIN_REQ, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No min options found.');
            }
            const minimumRequirementDataFlattened = getGroupedMinimumRequirementsData(data);
            dispatch(setMinOptions(minimumRequirementDataFlattened));
        }
    });
}

export function useGetCalculatedPrice() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_CALCULATED_PRICE, {
        apolloOptions: { fetchPolicy: 'network-only' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No calculated price found.');
            }
            dispatch(setCalculatedPriceBreakdown(data?.modelCostBreakDown));
        }
    });
}

export function useGetStandardCodes() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_STANDARD_CODES, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No standard codes found.');
            }
            dispatch(setStandardCodes(data.rows));
        }
    });
}

export function useGetFeatureOptions() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_FEATURE_OPTIONS, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data, getState, dispatch }) => {
            if (!data) {
                throw new Error('No feature options found.');
            }
            const returnData = getGroupedVehicleInfoData(data?.getFeatureOptions)
            dispatch(setFeatureOptions(returnData));
        }
    });
}

export function useGetVehicleGroupTypes(options = {}) {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_ACTIVE_VEHICLE_GROUP_TYPES, options);
}

export function useGetVehicleGroupTypesLazy() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_ACTIVE_VEHICLE_GROUP_TYPES, {
        apolloOptions: { fetchPolicy: 'no-cache' },
    });
}

export function useGetStandardItems(options = {}) {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_REQUISITION_SINS, {
        fetchPolicy: 'no-cache',
        ...options,
    });
}

export function useGetStandardItemsLazy() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_REQUISITION_SINS, {
        apolloOptions: { fetchPolicy: 'no-cache' },
        transformResult: async ({ data }) => {
            if (!data) {
                throw new Error('No feature options found.');
            }
            return data;
        }
    });
}

export function useGetColors({ contractLineId }) {
    const { registerQuery, runQuery } = useDispatchQuery();
    const [getStandardColors] = registerQuery(GET_STANDARD_CODES, {
        variables: {
            filters: {
                operator: 'EQ',
                key: 'code_metadata_id',
                value: 1,
            },
        },
        transformResult: ({ data }) => {
            return data.rows.map((row) => ({
                ...row,
                description: row.title
            }))
        }
    });

    if (!contractLineId) {
        console.warn("Contract line id is required to get colors, but we got", contractLineId);
        return runQuery(GET_STANDARD_CODES, {
            apolloOptions: {
                skip: !contractLineId
            },
            variables: {
                filters: {
                    operator: 'EQ',
                    key: 'code_metadata_id',
                    value: 1,
                },
            },
            transformResult: ({ data }) => {
                return data.rows.map((row) => ({
                    ...row,
                    description: row.title
                }))
            }
        });
    }

    return runQuery(GET_CONTRACT_LINE_MODEL_COLORS, {
        variables: {
            contractLineId: Number.parseInt(contractLineId, 10),
            transactionType: TRANSACTION_TYPES.LEASING,
        }
    }).then((results) => {
        if (results.data.length > 0) {
            return results;
        }

        return getStandardColors();
    })
}

export function useGetAttachmentTypes() {
    const { runQuery } = useDispatchQuery();
    return runQuery(GET_REQUISITION_ATTACHMENT_TYPES)
        .then(({ data, dispatch }) => {
            dispatch(setAttachmentTypes(data));
        })
}

export function useGenerateReadSignedUrl() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GENERATE_READ_SIGNED_URL, {
        fetchPolicy: 'no-cache',
        onCompleted: async ({ data }) => {
            return data.generateReadSignedURL;
        },
    });
}

export function useGetRequisitionAttachments({ requisitionId }) {
    const { runQuery } = useDispatchQuery();
    return runQuery(GET_REQUISITION_ATTACHMENTS, {
        variables: {
            requisitionId
        }
    }).then(({ data, dispatch }) => {
        dispatch(setAttachments(data));
        return data;
    })
}

export function useGetRequisitionAttachmentsLazy({ requisitionId }) {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_REQUISITION_ATTACHMENTS, {
        apolloOptions: {
            fetchPolicy: 'no-cache'
        },
        variables: {
            requisitionId
        },
        onCompleted: ({ data, dispatch }) => {
            dispatch(setAttachments(data));
            return data;
        }
    });
}

export function useCreateRequisitionAttachment() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(CREATE_REQUISITION_ATTACHMENT);
}

export function useGenerateSignedUrl() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(FETCH_SIGNED_URL);
}
export function useGeneratePDF() {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(GET_PDF_URL, {
        apolloOptions: {
            fetchPolicy: 'network-only'
        },
        transformResult: async ({ data, getState }) => {
            if (!data) {
                throw new Error('Error');
            }
            return data;
        }
    });
}

export function useUpdateLeasingDraft() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(UPDATE_REQUISITION_DRAFT_NEW);
}

export function useGetLeasingDraft() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_REQUISITION_CART);
}

export function useGetCollisionsForSelection() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_PURCHASE_COLLISION_FOR_ALL_VENDOR, {
        apolloOptions: {
            fetchPolicy: 'network-only'
        }}
    );
}

export function useGetCollisionsForSelectionOnYes() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_PURCHASE_COLLISION_FOR_ALL_VENDOR_ON_YES);
}

export function useGetRequisitionActivities() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_REQUISITION_ACTIVITIES);
  }

export function useSubmitToFMCApproval() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(SUBMIT_FMC_APPROVAL);
}

export function useSubmitForZonalApproval() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(SUBMIT_ZONAL_APPROVAL);
}

export function useSubmitForOrderingAdminApproval() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(SUBMIT_ORDERING_ADMIN_APPROVAL);
}

export function useSubmitLeasingReqToGSA() {
    const { registerMutation } = useDispatchQuery();
    return registerMutation(SUBMIT_LEASING_REQ_TO_GSA);
}

export function useGetAllVendorMarshallerLocations() {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_ALL_VENDOR_MARSHALLER_LOCATIONS);
}

export function useGetFleetManagementCenters() {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_FLEET_MANAGEMENT_CENTERS);
}

export function useGetUserById(options = {}) {
    const { runQueryNow } = useDispatchQuery();
    return runQueryNow(GET_USER_BY_ID, options);
}

export function useGetCountryCodeOptions() {
    const { runQueryNow } = useDispatchQuery();
    const { data, loading } = runQueryNow(GET_CATALOGS_FOR_CATEGORY, {
        variables: { category: `CountryCallingCode` },
        transformResult: ({ data }) => {
            return data.map(({ description, code }) => ({
                label: description,
                value: `${code}:${description}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        }
    });

    return { data, loading }
}

export function useReturnRequisition() {
    const { registerMutation } = useDispatchQuery();

    return registerMutation(RETURN_REQUISITION);
}

export function useGetFundingAvailability() {
    const { registerQuery } = useDispatchQuery();
    return registerQuery(GET_LEASING_FUNDING_AVAILABILITY, {
        apolloOptions: { fetchPolicy: 'network-only' },
        transformResult: ({ data }) => {
            return data;
        }
    });
  }