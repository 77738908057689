import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FilterTableFrame } from '@gsa/afp-component-library';
import { useDispatch } from 'react-redux';
import OrderVehicleFilterProvider from '../providers/order-vehicle-filter-provider';
import { setOrdersTab } from '../../../reducers/order';
import OrderVehicleFilter from './order-vehicle-filters';
import OrderVehicleTable from './order-vehicle-table';

const OrderVehicles = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setOrdersTab('orderVehicles'));
  }, []);

  const OrderVehicleFrame = useMemo(
    () =>
      FilterTableFrame(null, null, OrderVehicleFilter, OrderVehicleTable),
    [],
  );

  return (
    <>
      <OrderVehicleFilterProvider>
        <OrderVehicleFrame filterToggle />
      </OrderVehicleFilterProvider>
    </>
  );
};

OrderVehicles.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderData: PropTypes.objectOf(Object).isRequired,
};

export default OrderVehicles;
