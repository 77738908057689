import React from 'react';
import PropTypes from 'prop-types';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const VendorAcceptanceReview = ({ attachments }) => {
  const vendorAcceptanceAttachments =
    attachments?.getAllRequisitionAttachments?.filter(
      (li) => li.attachmentType.attachmentType === 'req_vendor_acceptance',
    );

  return (
    <div className="supp-doc-section">
      <div className="title">Supporting documents</div>
      {vendorAcceptanceAttachments?.map((li) => {
        return (
          <div className="quote-doc-section">
            <div className="attachment-section">
              <AttachmentDownload name={li?.name} metadataId={li?.metadataId} />
              <div className="title-desc">{li?.description}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

VendorAcceptanceReview.propTypes = {
  attachments: PropTypes.instanceOf(Object),
};

VendorAcceptanceReview.defaultProps = {
  attachments: { getAllRequisitionAttachments: [] },
};

export default VendorAcceptanceReview;
