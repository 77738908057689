import React, { useState } from "react";
import { FilterPanel, Spinner } from '@gsa/afp-component-library';
import {
    placeholderOption,
    vehicleSubTypeFilterKey,
    vehicleTypeFilterKey
} from "../../../../vehicle-listings/filter-item-constants.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVehicleSubType, setSelectedVehicleType } from "@/reducers/leasing.jsx";
import VehicleSubtypeFilter from "./vehicle-listing-sub-type-filter.jsx";
import { useGetVehicleGroupTypes } from "../../../../../requests/leasing.jsx";
import { VEHICLE_GROUP_TAG_STORE } from "../../../../../constants/constants.jsx";
import { setAvailableVehicleSubTypes } from "../../../../../reducers/leasing.jsx";
const { FilterPanel: ComponentFilterPanel, useFilterPanel } = FilterPanel;


const VehicleListingsFilter = ({
    resetButton,
    initVehicleType,
    initVehicleSubType,
    getListing,
    getVehicleGroupTypes,
    loadingVehicleGroupTypes,
}) => {
    const {
        vehicleTypeFilters,
        vehicleListingsPagination,
        availableVehicleSubTypes
    } = useSelector(state => state.leasingReducer);
    const [lastFilterState, setLastFilterState] = useState([]);
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);

    const handleClearAll = () => {
        if (typeof resetButton === 'function') {
            resetButton();
        }
    };

    useGetVehicleGroupTypes({
        variables: {
            groupId: urlParams.get('filter-vehicleType') || initVehicleType,
            tag: VEHICLE_GROUP_TAG_STORE,
        },
        onCompleted: (data) => {
            dispatch(setAvailableVehicleSubTypes([placeholderOption, ...data.getActiveVehicleGroupTypes.map((type) => ({ label: type.title, value: type.code })).sort((a, b) => a.label.localeCompare(b.label))]));
        }
    })

    const getVehicleSubTypes = (vehicleType) => {
        return getVehicleGroupTypes({
            variables: {
                groupId: vehicleType.value,
                tag: VEHICLE_GROUP_TAG_STORE,
            }
        }).then(({ data }) => {
            dispatch(setAvailableVehicleSubTypes([placeholderOption, ...data.map((type) => ({ label: type.title, value: type.code })).sort((a, b) => a.label.localeCompare(b.label))]));
            return data.map((type) => type.code);
        }).catch((e) => {
            console.error('error', e)
        });
    }

    const handleFilterChange = (filters) => {
        const diff = filters.filter((f) => {
            return !lastFilterState.find((lf) => lf.key === f.key && lf.value === f.value);
        });

        const clearedFilters = lastFilterState.filter((lf) => {
            return !filters.find((f) => f.key === lf.key);
        });

        if (diff.length === 0 && clearedFilters.length === 0) return;

        let vehicleTypeCleared = clearedFilters.find((f) => f.key === vehicleTypeFilterKey);
        let vehicleSubTypeCleared = clearedFilters.find((f) => f.key === vehicleSubTypeFilterKey);

        if (vehicleTypeCleared) {
            setLastFilterState([]);
            resetButton();
            return;
        }

        if (vehicleSubTypeCleared) {
            let vehicleType = filters.find((f) => f.key === vehicleTypeFilterKey);
            dispatch(setSelectedVehicleSubType(''));
            getVehicleSubTypes(vehicleType).then((subTypes) => {
                getListing(subTypes, vehicleListingsPagination.currentPage, vehicleListingsPagination.itemsPerPage)
            });
            setLastFilterState(filters);
            return;
        }

        let vehicleType = diff.find((f) => f.key === vehicleTypeFilterKey);
        let vehicleSubType = diff.find((f) => f.key === vehicleSubTypeFilterKey);

        if (vehicleType) {
            dispatch(setSelectedVehicleType(vehicleType.value));
            getVehicleSubTypes(vehicleType).then((subTypes) => {
                if (!vehicleSubType) {
                    getListing(subTypes, vehicleListingsPagination.currentPage, vehicleListingsPagination.itemsPerPage)
                }
            });
        }

        if (vehicleSubType) {
            dispatch(setSelectedVehicleSubType(vehicleSubType.value));
            getListing([vehicleSubType.value], vehicleListingsPagination.currentPage, vehicleListingsPagination.itemsPerPage);
        }

        setLastFilterState(filters);
    };

    if (!vehicleTypeFilters || !availableVehicleSubTypes) return <Spinner />;
    
    let subTypeValueFound = availableVehicleSubTypes.find((type) => type.value === (urlParams.get('filter-vehicleSubType') || initVehicleSubType));

    return (
        <div data-testid="filters-test" className="table-filters-container">
            <ComponentFilterPanel
                clearButtonLabel="Reset All"
                key="filter-panel"
                filterStructure={[
                    {
                        key: vehicleTypeFilterKey,
                        title: 'Vehicle Type',
                        type: 'select',
                        value: urlParams.get('filter-vehicleType') || initVehicleType,
                        options: vehicleTypeFilters,
                        operator: '$exact',
                        permanent: false,
                        expanded: true,
                    },
                    {
                        key: vehicleSubTypeFilterKey,
                        title: 'Vehicle Sub-type',
                        type: 'select',
                        component: VehicleSubtypeFilter({ subTypes: availableVehicleSubTypes, lastFilterState }),
                        value: subTypeValueFound ? subTypeValueFound.value : '',
                        options: availableVehicleSubTypes,
                        operator: '$in',
                        permanent: false,
                        expanded: true,
                        loading: loadingVehicleGroupTypes
                    },
                ]}
                order={[]}
                setQueryFiltersState={handleFilterChange}
                handleClearAll={handleClearAll}
            />
        </div>
    );
};

export default VehicleListingsFilter;