import React, { useMemo } from 'react';
import AddressComponent from './address-component/AddressComponent';
import useBulkOrderModsState from '../../useBulkOrderModsState';
import './DeliveryAddress.scss';

export const requisitionSection = {
  id: 'requisitionAddress',
  addressKey: 'requisitionAddress',
  contactKey: 'requisitionContact',
  isChecked: false,
  title: 'Requisitioning address',
  subTitle:
    'Enter the complete address of the office requisitioning the vehicle.',
  canBeSameAsRequisition: false,
};

export const mailingSection = {
  id: 'mailingAddress',
  addressKey: 'mailingAddress',
  contactKey: 'mailingContact',
  isChecked: false,
  title: 'Mailing address',
  subTitle:
    "Certificates of Origin are made out to the agency and address provided in the mailing address. Parts and service manuals are also sent to the mailing address. Do not provide a person's name in the mailing address.",
  canBeSameAsRequisition: true,
};

export const deliverySection = {
  id: 'deliveryAddress',
  addressKey: 'deliveryAddress',
  contactKey: 'deliveryContact',
  isChecked: false,
  title: 'Delivery address',
  subTitle: '',
  canBeSameAsRequisition: true,
};

function getDefaultValuesForAddress(order, addressKey, contactKey) {
  return {
    address: {
      addressLine1: order[addressKey]?.addressLine1,
      addressLine2: order[addressKey]?.addressLine2,
      city: order[addressKey]?.city,
      stateCode: order[addressKey]?.stateCode,
      zipcode: order[addressKey]?.zipcode,
      countryCode: order[addressKey]?.countryCode,
      entityName: order[addressKey]?.entityName || order?.entityName,
    },
    contact: {
      firstName: order[contactKey]?.firstName,
      lastName: order[contactKey]?.lastName,
      email: order[contactKey]?.email,
      phone: {
        countryCode: order[contactKey]?.phoneCountryCode,
        number: order[contactKey]?.phoneNumber,
        extension: order[contactKey]?.phoneExtension,
      },
      fax: {
        countryCode: order[contactKey]?.faxCountryCode,
        number: order[contactKey]?.faxNumber,
        extension: order[contactKey]?.faxExtension,
      },
    },
  };
}

const DeliveryAddress = () => {
  const {
    mailingSameAsRequisition,
    deliverySameAsRequisition,
    setMailingSameAsRequisition,
    setDeliverySameAsRequisition,
    selectedOrdersForMod,
  } = useBulkOrderModsState();

  const shouldUseOrderAddresses = useMemo(() => {
    // if all selectedOrdersForMod have the same requisitionNumber
    return (
      selectedOrdersForMod.length > 0 &&
      selectedOrdersForMod.every(
        (order, _index, orders) =>
          order.requisitionNumber === orders[0].requisitionNumber,
      )
    );
  }, [selectedOrdersForMod]);

  let requisitionAddressDefaults = null;
  let mailingAddressDefaults = null;
  let deliveryAddressDefaults = null;
  if (shouldUseOrderAddresses) {
    requisitionAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'requisitionerAddress',
      'requisitionerContact',
    );
    mailingAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'mailingAddress',
      'mailingContact',
    );
    deliveryAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'deliveryAddress',
      'deliveryContact',
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      <AddressComponent
        section={requisitionSection}
        addressDefaults={requisitionAddressDefaults}
      />

      <AddressComponent
        section={mailingSection}
        sameAsRequisition={mailingSameAsRequisition}
        setSameAsRequisition={setMailingSameAsRequisition}
        addressDefaults={mailingAddressDefaults}
      />

      <AddressComponent
        section={deliverySection}
        sameAsRequisition={deliverySameAsRequisition}
        setSameAsRequisition={setDeliverySameAsRequisition}
        addressDefaults={deliveryAddressDefaults}
      />
    </div>
  );
};

export default DeliveryAddress;
