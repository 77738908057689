import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  STEPS,
  initialStoreReportsSteps,
  reportFormDefaultValues,
} from './constants';
import {
  CHECK_ORDER_NUMBER,
  CHECK_REQUISITION_NUMBER,
  GENERATE_STORE_REPORT,
  GET_STORE_REPORTS,
} from '../../services/data-layer';
import { useSystemAlert } from '../../services/system-alert';
import {
  exportFileContent,
  FileMimeType,
  UTCtoISOString,
} from '../../utilities/commonUtils';
import { UserTypes } from '../../constants/user-constants';
import { StoreSubjects } from '../../constants/constants';
import { REPORT_ERROR } from './errors';

export const SearchByFilters = 'SEARCH_BY_FILTERS';
export const SearchByOrderNumber = 'SEARCH_BY_ORDER_NUMBER';
export const SearchByRequisitionNumber = 'SEARCH_BY_REQUISITION_NUMBER';

export const StoreReportsContext = createContext();

export const StoreReportsProvider = ({ children }) => {
  const { setSystemAlert, clearSystemAlert } = useSystemAlert();
  const [storeReportStepCurrentIndicator, setStoreReportStepCurrentIndicator] =
    useState(1);
  const { currentUser } = useCurrentUser();

  function isInternalUser() {
    return currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE;
  }

  function isVendor() {
    return currentUser?.userType?.id === UserTypes.VENDOR;
  }

  // TODO remove default values conditional after implement Leasing
  const getFormDefaultValues = () => {
    if (isInternalUser() || isVendor()) {
      return reportFormDefaultValues;
    }

    return { ...reportFormDefaultValues, transactionTypes: ['VEHICLE_SALE'] };
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: getFormDefaultValues(),
  });

  const [searchMethod, setSearchMethod] = useState('');
  const [storeReports, setStoreReports] = useState([]);
  const [reportName, setReportName] = useState('');
  const [reportSubject, setReportSubject] = useState('');

  useEffect(() => {
    const reportType = methods.getValues('reportType');
    methods.reset({ ...getFormDefaultValues(), reportType });
  }, [reportName]);

  function isFirstStep() {
    return storeReportStepCurrentIndicator === 1;
  }

  function isCustomer() {
    return currentUser?.userType?.id === UserTypes.CUSTOMER;
  }

  function isRequisitionReport() {
    return StoreSubjects.Requisition === reportSubject;
  }

  function isOrderReport() {
    return StoreSubjects.Order === reportSubject;
  }

  function flowReset() {
    methods.setValue('reportType', '');
    setStoreReportStepCurrentIndicator(1);
    setReportName('');
    setSearchMethod(SearchByFilters);
  }

  const [getStoreReports] = useLazyQuery(GET_STORE_REPORTS, {
    onError: (err) => {
      setSystemAlert({
        errorHint: err.message,
      });
    },
    onCompleted: (data) => {
      const reports = data?.getStoreReportList;
      if (!reports?.length) {
        setSystemAlert({
          type: 'warning',
          heading: 'No reports found',
          message: 'Make sure you have permission to view store reports.',
        });
      }
      setStoreReports(data?.getStoreReportList);
    },
  });

  function isReportError(err, name) {
    return (
      (err?.graphQLErrors?.length &&
        err?.graphQLErrors[0]?.extensions?.exception?.name === name) ||
      err?.networkError
    );
  }

  const [generateStoreReport, { loading: generatingReport }] = useLazyQuery(
    GENERATE_STORE_REPORT,
    {
      onCompleted: (_data) => {
        if (_data) {
          const { content, contentType } = _data.generateStoreReport;
          setSystemAlert({
            type: 'success',
            heading: 'Download started',
            focused: true,
            showClose: true,
            message: (
              <div>
                Your download has started successfully. Please check your
                downloads folder.
              </div>
            ),
          });

          exportFileContent(
            content,
            contentType,
            `${reportName}.${FileMimeType[contentType]}`,
          );

          flowReset();
        }
      },
      onError: (err) => {
        if (isReportError(err, REPORT_ERROR.TIMEOUT)) {
          setSystemAlert({
            type: 'warning',
            focused: true,
            showClose: true,
            message: (
              <div>
                Report generation exceeded time limit. Please select
                &lsquo;email report&rsquo; option.
              </div>
            ),
          });
          return;
        }
        if (isReportError(err, REPORT_ERROR.SIZE_LIMIT)) {
          setSystemAlert({
            type: 'warning',
            focused: true,
            showClose: true,
            message: (
              <div>
                Report generation exceeded size limit. Please select
                &lsquo;email report&rsquo; option.
              </div>
            ),
          });
          return;
        }

        setSystemAlert({
          errorHint: err.message,
        });
      },
    },
  );

  const [emailStoreReport, { loading: emailingReport }] = useLazyQuery(
    GENERATE_STORE_REPORT,
    {
      onCompleted: (data) => {
        if (data?.generateStoreReport?.statusCode === 200) {
          setSystemAlert({
            type: 'success',
            heading: 'Export Initiated',
            focused: true,
            showClose: true,
            message: (
              <div>
                The report you&apos;ve created is now being processed for export
                and will be emailed to you upon completion. Depending on the
                file size, this may take up to 15 minutes or more. If you
                experience technical difficulties exporting, please contact the
                GSA Fleet Technical Support team at fleet.helpdesk@gsa.gov or
                866-472-6711 from 8:00 a.m. - 7:00 p.m. ET, Monday–Friday.
              </div>
            ),
          });
          flowReset();
          return;
        }
        setSystemAlert({
          errorHint: 'Fail to generate email report',
        });
      },
      onError: (err) => {
        setSystemAlert({
          errorHint: err.message,
        });
      },
    },
  );

  const [checkRequisitionNumberQuery, { loading: checkingRequisitionNumber }] =
    useLazyQuery(CHECK_REQUISITION_NUMBER, {
      onCompleted: (data) => {
        if (!data.checkRequisitionNumber?.found) {
          methods.setError('requisitionNumber', {
            type: 'not_found',
            message: 'Requisition not found',
          });
        }
      },
      onError: (err) => {
        setSystemAlert({
          errorHint: `Failed checking the requisition number: ${err.message}`,
        });
      },
    });

  const [checkOrderNumberQuery, { loading: checkingOrderNumber }] =
    useLazyQuery(CHECK_ORDER_NUMBER, {
      onCompleted: (data) => {
        if (!data.checkOrderNumber?.found) {
          methods.setError('orderNumber', {
            type: 'not_found',
            message: 'Order not found',
          });
        }
      },
      onError: (err) => {
        setSystemAlert({
          errorHint: `Failed checking the order number: ${err.message}`,
        });
      },
    });

  async function checkRequisitionNumber(requisitionNumber) {
    const res = await checkRequisitionNumberQuery({
      variables: { requisitionNumber },
    });
    return res?.data?.checkRequisitionNumber;
  }

  async function checkOrderNumber(orderNumber) {
    const res = await checkOrderNumberQuery({
      variables: { orderNumber },
    });
    return res?.data?.checkOrderNumber;
  }

  const handlePageNavigation = async (action) => {
    if (action === 'continue') {
      await methods.trigger();
      if (!methods.formState.isValid) return;
    }
    methods.reset({ ...methods.getValues() }, { keepValues: true });
    if (action === 'continue') {
      setStoreReportStepCurrentIndicator((prev) => prev + 1);
    } else if (action === 'previous') {
      setStoreReportStepCurrentIndicator((prev) => prev - 1);
    }
  };

  const setStep = (step) => {
    switch (step) {
      case STEPS.REPORT_SELECTION:
        setStoreReportStepCurrentIndicator(1);
        break;
      case STEPS.SEARCH_METHOD:
        setStoreReportStepCurrentIndicator(2);
        break;
      case STEPS.REPORT_FILTERS:
        setStoreReportStepCurrentIndicator(3);
        break;
      default:
        break;
    }
  };

  async function canGenerateReport(data) {
    if (data?.orderNumber) {
      const check = await checkOrderNumber(data.orderNumber);
      return check?.found;
    }

    if (data?.requisitionNumber) {
      const check = await checkRequisitionNumber(data.requisitionNumber);
      return check?.found;
    }

    return true;
  }

  const clearQueryParams = (params) => {
    const { reportBuildOption } = params;
    if (reportBuildOption === SearchByOrderNumber) {
      return { orderNumber: params.orderNumber };
    }
    if (reportBuildOption === SearchByRequisitionNumber) {
      return { requisitionNumber: params.requisitionNumber };
    }
    if (reportBuildOption === SearchByFilters) {
      const empty = (v) => !v || !v.length;
      const filters = Object.entries(params).reduce((acc, [key, value]) => {
        if (empty(value) || value === 'ALL') {
          return acc;
        }
        acc[key] = value;
        return acc;
      }, {});

      const startDateToUTC = UTCtoISOString(filters?.startDate);
      const endDateToUTC = UTCtoISOString(filters?.endDate, true);
      if (isInternalUser()) {
        const gsaFilters = {
          agencyCode: filters?.agencyCode,
          bureauCode: filters?.bureauCode,
          officeCode: filters?.officeCode,
          startDate: startDateToUTC,
          endDate: endDateToUTC,
          transactionTypes: filters?.transactionTypes,
          requisitionType: filters?.requisitionType,
        };
        if (isRequisitionReport()) {
          gsaFilters.requisitionStatuses = filters?.requisitionStatuses;
        }
        if (isOrderReport()) {
          gsaFilters.orderStatuses = filters?.orderStatuses;
        }
        return gsaFilters;
      }
      if (isCustomer()) {
        const customerFilters = {
          agencyCode: filters?.agencyCode,
          bureauCode: filters?.bureauCode,
          officeCode: filters?.officeCode,
          startDate: startDateToUTC,
          endDate: endDateToUTC,
          transactionTypes: filters?.transactionTypes,
        };
        if (isRequisitionReport()) {
          customerFilters.requisitionStatuses = filters?.requisitionStatuses;
          customerFilters.requisitionType =
            filters?.requisitionType !== 'ALL'
              ? filters?.requisitionType
              : undefined;
        }
        if (isOrderReport()) {
          customerFilters.orderStatuses = filters?.orderStatuses;
        }
        return customerFilters;
      }
      if (isVendor()) {
        const vendorFilters = {
          startDate: startDateToUTC,
          endDate: endDateToUTC,
          transactionTypes: filters?.transactionTypes,
        };
        if (filters?.requisitionType !== 'ALL' && isRequisitionReport()) {
          vendorFilters.requisitionType = filters?.requisitionType;
        }
        if (isOrderReport()) {
          vendorFilters.orderStatuses = filters?.orderStatuses;
        }
        return vendorFilters;
      }
    }
    return {};
  };

  const onSubmit = async (data) => {
    const { reportType, ...queryParams } = data;
    clearSystemAlert();

    if (!(await canGenerateReport(data))) {
      return;
    }

    const params = clearQueryParams(queryParams);

    generateStoreReport({
      variables: {
        input: {
          outputType: 'XLSX',
          reportType,
          delivery: 'DOWNLOAD',
          queryParams: params,
        },
      },
    });
  };

  const onEmailSubmit = async (data) => {
    const { reportType, ...queryParams } = data;
    clearSystemAlert();

    if (!(await canGenerateReport(data))) {
      return;
    }

    const params = clearQueryParams(queryParams);
    emailStoreReport({
      variables: {
        input: {
          outputType: 'XLSX',
          reportType,
          delivery: 'EMAIL',
          queryParams: params,
        },
      },
    });
  };

  const handleSubmitExportReport = () => {
    methods.handleSubmit(onSubmit)();
  };

  const handleSubmitEmailReport = () => {
    methods.handleSubmit(onEmailSubmit)();
  };

  let storeReportStepCurrent = {};
  let storeReportsSteps = [
    ...initialStoreReportsSteps.map((step) => {
      const newStep = { ...step };
      if (
        parseInt(newStep.stepNumber, 10) <
        parseInt(storeReportStepCurrentIndicator, 10)
      ) {
        newStep.status = 'completed';
      } else if (
        parseInt(newStep.stepNumber, 10) ===
        parseInt(storeReportStepCurrentIndicator, 10)
      ) {
        newStep.status = 'current';
        storeReportStepCurrent = {
          ...newStep,
          text: newStep.label,
          current: newStep.stepNumber,
        };
      } else {
        newStep.status = 'not completed';
      }
      return newStep;
    }),
  ];

  const finalSearchMethods = [SearchByOrderNumber, SearchByRequisitionNumber];
  if (finalSearchMethods.includes(searchMethod)) {
    storeReportsSteps = storeReportsSteps.filter(
      (step) => step.stepNumber !== 3,
    );
  }

  const isLastStep =
    storeReportsSteps.length === storeReportStepCurrentIndicator;
  return (
    <StoreReportsContext.Provider
      value={{
        storeReportStepCurrent,
        storeReportsSteps,
        reportName,
        setReportName,
        setReportSubject,
        setStep,
        isFirstStep,
        isLastStep,
        searchMethod,
        setSearchMethod,
        handlePageNavigation,
        handleSubmitExportReport,
        handleSubmitEmailReport,
        getStoreReports,
        storeReports,
        generateStoreReport,
        generatingReport,
        emailingReport,
        isInternalUser,
        isCustomer,
        isVendor,
        isRequisitionReport,
        isOrderReport,
        checkRequisitionNumber,
        checkOrderNumber,
        checkingRequisitionNumber,
        checkingOrderNumber,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </StoreReportsContext.Provider>
  );
};

StoreReportsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function useStoreReportsContext() {
  const {
    storeReportsSteps,
    storeReportStepCurrent,
    reportName,
    setReportName,
    setReportSubject,
    setStep,
    isFirstStep,
    isLastStep,
    searchMethod,
    setSearchMethod,
    handlePageNavigation,
    handleSubmitExportReport,
    handleSubmitEmailReport,
    getStoreReports,
    storeReports,
    generateStoreReport,
    generatingReport,
    emailingReport,
    isInternalUser,
    isCustomer,
    isVendor,
    isRequisitionReport,
    isOrderReport,
    checkRequisitionNumber,
    checkOrderNumber,
    checkingRequisitionNumber,
    checkingOrderNumber,
  } = useContext(StoreReportsContext);
  return {
    storeReportsSteps,
    storeReportStepCurrent,
    reportName,
    setReportName,
    setReportSubject,
    setStep,
    isFirstStep,
    isLastStep,
    searchMethod,
    setSearchMethod,
    handlePageNavigation,
    handleSubmitExportReport,
    handleSubmitEmailReport,
    getStoreReports,
    storeReports,
    generateStoreReport,
    generatingReport,
    emailingReport,
    isInternalUser,
    isCustomer,
    isVendor,
    isRequisitionReport,
    isOrderReport,
    checkRequisitionNumber,
    checkOrderNumber,
    checkingRequisitionNumber,
    checkingOrderNumber,
  };
}
