// this function set the focus to the first element that is specified by
// elementClassName and provide the scrolling to top
// it does not return anything, hence it's not an arrow function
export const resetFocusToFirstElement = function _resetFocusToFirstElement(
  elementClassName = 'AFP-TABLE',
  scrollToTop = true,
) {
  if (scrollToTop) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  let $container =
    elementClassName !== 'AFP-TABLE'
      ? document.querySelector(`.${elementClassName}:first-of-type`)
      : document.querySelector(
          '.afp-responsive-table tbody tr:first-of-type button:first-of-type',
        );

  if (!$container) {
    $container = document.querySelector(
      '.afp-responsive-table thead tr:first-of-type button:first-of-type',
    );
  }

  if ($container?.focus) {
    $container.focus();
  }
};

export default resetFocusToFirstElement;
