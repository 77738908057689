import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_OFFICES_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import {
  LEASING_AGENCY_FILTER,
  LEASING_BUREAU_FILTER,
  LEASING_OFFICE_FILTER,
} from './filter-item-constants';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const LeasingOfficeFilterItem = ({ filter }) => {
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();
  const [selectedOfficeCode, setSelectedOfficeCode] = useState();
  const [options, setOptions] = useState([]);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getOffices, { data: officeOptions, loading: officeOptionsLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const agencyFilter = getFilterFromState(LEASING_AGENCY_FILTER) || {};

    setAgencyCode(agencyFilter.value);

    const bureauFilter = getFilterFromState(LEASING_BUREAU_FILTER) || {};

    setBureauCode(bureauFilter.value);

    const officeFilter = getFilterFromState(LEASING_OFFICE_FILTER) || {};
    setSelectedOfficeCode(officeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);

    if (!agencyCode || bureauCode?.length !== 1) {
      return;
    }

    getOffices({
      variables: {
        agencyCode,
        bureauCode: bureauCode[0],
        operation: 'view',
        subject: StoreSubjects.Order,
      },
    });
  }, [bureauCode]);

  useEffect(() => {
    if (!officeOptions?.getOfficesByPermission) {
      return;
    }

    setOptions([
      ...officeOptions?.getOfficesByPermission.map(
        ({ officeCode, officeName }) => ({
          value: officeCode,
          label: officeName,
        }),
      ),
    ]);
  }, [officeOptions]);

  if (officeOptionsLoading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return <p>Cannot filter by office when multiple bureaus are selected</p>;
  }

  return (
    <div id="leasing-group-filter-item" className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: selectedOfficeCode || [],
        }}
      />
    </div>
  );
};

LeasingOfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LeasingOfficeFilterItem;