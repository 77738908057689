import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const ContractECFilterContext = createContext({});
const useContractECFilter = () => useContext(ContractECFilterContext);

const initialState = {
  error: '',
  filters: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const contractECReducer = (state, { action, payload }) => {
  const {
    setFilters,
  } = actions;
  const { error } = initialState;

  switch (action) {
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid order filter action');
  }
};

const ContractECFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    contractECReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchFilters = (value) => dispatch(actions.setFilters, value);

  return (
    <ContractECFilterContext.Provider
      value={{
        ...state,
        ...props,
        setFilters: dispatchFilters,
      }}
    >
      {children}
    </ContractECFilterContext.Provider>
  );
};

ContractECFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ContractECFilterProvider as default, useContractECFilter };
