import React, { useMemo } from 'react';
import { PageTitle, FilterTableFrame } from '@gsa/afp-component-library';
// import { useReactToPrint } from 'react-to-print';
import VehicleAvailabilityProvider from './VehicleAvailabilityProvider';
import VehicleAvailabilityToolbar from './VehicleAvailabilityToolbar';
import VehicleAvailabilityFilters from './Filters/VehicleAvailabilityFilters';
import VehicleAvailabilityTable from './VehicleAvailabilityTable';
import Breadcrumbs from './widgets/Breadcrumbs';
import './VehicleAvailabilityListing.scss';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';

const VehicleAvailabilityListing = () => {
  const todayFormatted = new Intl.DateTimeFormat('en-US').format(new Date());

  const TopRightSection = () => <VehicleAvailabilityToolbar />;
  const VehicleAvailabilityListingFrame = useMemo(
    () =>
      FilterTableFrame(
        null,
        TopRightSection,
        VehicleAvailabilityFilters,
        VehicleAvailabilityTable,
      ),
    [],
  );

  return (
    <VehicleAvailabilityProvider>
      <div className="vehicle-availability">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Breadcrumbs />
          <ContactBuyingPopover />
        </div>
        <PageTitle
          data-testid="vehicle-availability-header"
          title={`Vehicle Availability Listing (VAL) ${todayFormatted}`}
        />
        <p
          data-testid="vehicle-availability-header-desc"
          className="vehicle-availability-header-desc"
        >
          All vehicle orders must be submitted to GSA Fleet no later than 3:00
          PM EST on the closeout date indicator below.
        </p>
        <VehicleAvailabilityListingFrame filterToggle />
      </div>
    </VehicleAvailabilityProvider>
  );
};

export default VehicleAvailabilityListing;
