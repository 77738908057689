import React from 'react';
import { StepIndicator } from '@gsa/afp-component-library';
import { useStoreReportsContext } from '../useStoreReportsContext';

const ReportsStepIndicator = () => {
  const { storeReportsSteps, storeReportStepCurrent } =
    useStoreReportsContext();
  return (
    <StepIndicator
      className="non-sop-step-indicator"
      counterSize="big"
      heading={storeReportStepCurrent}
      onClick={() => {}}
      steps={storeReportsSteps}
    />
  );
};

export default ReportsStepIndicator;
