import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useImport } from './state/hook';
import { reportsConfig } from './config';
import './process-import.css';

const ProcessImport = ({ useDataProcessor }) => {
  const {
    importedData,
    setProcessedResults,
    processedResults,
    setProcessedResponse,
  } = useImport();

  const { batchSize } = reportsConfig.ORDERLINE;
  const [count, setcount] = useState(0);
  const [batches, setBatches] = useState([]);
  const { process, processDataForFile, handleSystemErrors } = useDataProcessor(
    (data) => {
      setProcessedResults((results) => [...results, ...data]);
      setcount(count > 0 ? count - 1 : 0);
    },
    () => {
      const systemErrors = handleSystemErrors(
        batches[count - 1],
        batchSize * (count - 1) + 1,
      );
      setProcessedResults((response) => [...response, ...systemErrors]);
      setcount(count > 0 ? count - 1 : 0);
    },
  );

  useEffect(() => {
    const batchesData = [];
    const data = [...importedData];
    while (data.length > 0) {
      const chunk = data.splice(0, batchSize);
      batchesData.push(chunk);
    }
    setBatches(batchesData);
    setcount(batchesData.length);
  }, [importedData]);

  useEffect(() => {
    if (processedResults.length === importedData.length) {
      setProcessedResponse(processDataForFile(processedResults));
    }
  }, [processedResults]);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => process({
        variables: {
          data: batches[count - 1],
          startingIndex: batchSize * (count - 1) + 1,
        },
      }), 1000);
    }
  }, [count]);

  return (
    <div className="progress-import-container">
      <div className="process-import_overlay" />
      <div className="process-import_progress">
        <h2 className="title-l-bold">Please wait</h2>
        <p> We are uploading data and checking for errors.</p>
        <progress max={importedData.length} value={processedResults.length} />
        <p className="title-m-bold padding-y-0 margin-y-0">
          {processedResults.length} records out of {importedData.length}{' '}
          processed
        </p>
      </div>
    </div>
  );
};

ProcessImport.defaultProps = {
  useDataProcessor: () => {},
};

ProcessImport.propTypes = {
  useDataProcessor: propTypes.func,
};

export default ProcessImport;
