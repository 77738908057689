import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../services/data-layer/standards';
import { VEHICLE_GROUP_TAG_STORE } from '../../constants/constants';

const VehicleFilterContext = createContext({});
const useVehicleFilter = () => useContext(VehicleFilterContext);

const initialState = {
  error: '',
  filters: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const orderVehicleFilterReducer = (state, { action, payload }) => {
  const { setFilters, setError } = actions;
  const { error } = initialState;

  switch (action) {
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid order vehicle filter action');
  }
};

const VehicleFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    orderVehicleFilterReducer,
    initialState,
    () => initialState,
  );

  const { data: activeVehicleGroupsData, loading: loadingActiveVehicleGroups } =
    useQuery(GET_ACTIVE_VEHICLE_GROUPS, {
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    });

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const setFilters = (value) => dispatch(actions.setFilters, value);

  if (loadingActiveVehicleGroups) return null;

  return (
    <VehicleFilterContext.Provider
      value={{
        ...state,
        ...props,
        setFilters,
        vehicleTypes: activeVehicleGroupsData?.getActiveVehicleGroups || [],
      }}
    >
      {children}
    </VehicleFilterContext.Provider>
  );
};

VehicleFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { VehicleFilterProvider as default, useVehicleFilter };
