import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GENERATE_ORDER_STATUS_SHEET } from '../../services/data-layer';

const VendorDownloadTemplate = ({ emptyTemplate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getOrderStatusSheet, { data: generatePDFResponse }] = useLazyQuery(
    GENERATE_ORDER_STATUS_SHEET,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const waitingPDFLoop = (url) => {
    const maxTry = 15; // 30 seconds wait in total
    const timer = setTimeout(() => {
      const httpReq = new XMLHttpRequest();
      httpReq.open(
        'GET',
        generatePDFResponse.generateOrderStatusSheet.signedUrl,
        true,
      );
      httpReq.responseType = 'blob';
      httpReq.send();
      httpReq.onload = (response) => {
        if (response?.target && response?.target?.status === 200) {
          const blob = new Blob([httpReq.response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const blobUrl = URL.createObjectURL(blob);

          // Create a temporary anchor element to trigger the download
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.download = 'vendor_orders_sheet.xlsx';
          anchor.click();
          setIsLoading(false);
        } else if (
          response?.target &&
          response?.target?.status !== 200 &&
          waitingPDFLoop.tryCount < maxTry
        ) {
          waitingPDFLoop.tryCount += 1;
          waitingPDFLoop(url);
        } else {
          // throw error
          setIsLoading(false);
        }
      };
      clearTimeout(timer);
    }, 3000);
  };
  waitingPDFLoop.tryCount = 0;

  useEffect(() => {
    if (generatePDFResponse?.generateOrderStatusSheet?.succeed) {
      waitingPDFLoop(generatePDFResponse.generateOrderStatusSheet.signedUrl);
    } else {
      setIsLoading(false);
    }
  }, [generatePDFResponse]);

  const downloadFile = async () => {
    setIsLoading(true);
    try {
      await getOrderStatusSheet({
        variables: {
          query: {
            offset: 0,
            limit: 10000,
            orderBy: 'createdAt',
            orderDirection: 'DESC',
            filters: {
              operator: '$and',
              conditions: [],
            },
          },
        },
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      {!emptyTemplate ? (
        <Button
          type="button"
          variant="outline"
          label="Download report"
          onClick={downloadFile}
          leftIcon={{
            className: '',
            name: 'file_download',
            type: 'button',
          }}
        >
          Download File
        </Button>
      ) : (
        <a
          href={`/templates/Empty Vehicle Status Template.xlsx`}
          download="Empty Vehicle Status Template.xlsx"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            type="button"
            variant="outline"
            label="Download empty vehicle status template"
            leftIcon={{
              className: '',
              name: 'file_download',
              type: 'button',
            }}
          />
        </a>
      )}
    </div>
  );
};

VendorDownloadTemplate.defaultProps = {
  emptyTemplate: false,
};

VendorDownloadTemplate.propTypes = {
  emptyTemplate: propTypes.bool,
};
export default VendorDownloadTemplate;
