export const OrderModInitialState = {
  selectedCountryContext: [],
  orderModHasError: [],
  selectedCountryStatesContext: [],
  countryCallingCodesContext: [],
  requisitionDeliveryAddress: [],
  requisitionSameAddressState: [],
  reqAddressStateContext: {},
  statesRequisitionFetchedFrmContext: [],
  statesMailingFetchedFrmContext: [],
  statesDeliveryFetchedFrmContext: [],
  uspsAddressVerificationModalState: false,
  mailingSameAsRequisition: false,
  deliverySameAsRequisition: false,
  requisitionStateContext: {
    id: 'Requisition Address',
    isValidated: false,
    country: 'US',
    selectedCountryDescription: '',
    agencyOrganizationName: '',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    isMilitary: false,
    militaryOrDiplomaticAddress: '',
    rankAndFullName: '',
    postOffice: '',
  },
  reqValidatedAddress: {},
  mailingStateContext: {
    id: 'Mailing Address',
    isValidated: false,
    country: '',
    selectedCountryDescription: '',
    agencyOrganizationName: '',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    isMailingSameAsRequisition: false,
    isMilitary: false,
    rankAndFullName: '',
    militaryOrDiplomaticAddress: '',
    postOffice: '',
  },
  mailingValidatedAddress: {},
  deliveryStateContext: {
    id: 'Delivery address',
    isValidated: false,
    country: '',
    agencyOrganizationName: '',
    selectedCountryDescription: '',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    isDeliverySameAsRequisition: false,
    isMilitary: false,
  },
  deliveryValidatedAddress: {},
  validatedAddressNotFound: [],
  requisitionAddressErrorContext: [],
  mailingAddressErrorContext: [],
  deliveryAddressErrorContext: [],
  addressVerificationModal: false,
  optionalReqData: [],
  formErrorFocus: {
    vehicleQuantity: false,
  },
  uspsModalOptionsSelectedState: [],
  isDomesticShipment: false,
  updatedDraft: {},
  selectedOptions: [],
  selectedOrdersForMod: [],
};

export default OrderModInitialState;
