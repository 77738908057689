/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  PageTitle,
  Button,
  SelectDropdown,
  ComboBox,
  Label,
  Alert,
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import useOrderGuideState from './useOrderGuideState';
import {
  getTableColumns,
  ORDER_GUIDE_TABLE_COLUMN_HEADERS,
} from './tableHelpers';
import {
  getContractRenewalYears,
  getSinsTypeahead,
  getContractsForSinAndYear,
} from '../../requests/ordering-guide';

import './OrderingGuideFilter.scss';
import Breadcrumbs from './widgets/Breadcrumbs';
import { setContractSelectionDetails } from '../../reducers/ordering-guide';
import { OrderingGuideSteps } from './constants';

const dropdownInitialStates = [
  {
    value: '',
    label: '-select-',
    defaultValue: true,
  },
];

const requiredContractYear = 'Contract year is required';

export const getStandardItemLabel = (item) => {
  return {
    label: `${item.standardItemNumber}, ${item.title}`,
    value: item.standardItemNumber,
  };
};

const ContractList = () => {
  const {
    orderBy,
    orderDirection,
    setOrderDirection,
    setOrderBy,
    paginationState,
    setPaginationState,
  } = useOrderGuideState();

  const dispatch = useDispatch();

  const {
    contractList: { rows: orderGuideRows, count: orderCount },
    orderGuideLoading,
    selecteContractYear,
    selectedSINValue,
    currentStep,
    contractRenewalYear,
    sinListforYear,
    showSuccessMessage,
  } = useSelector((state) => state.orderingGuideReducer);

  const [sinDropdown, sinDropdownTo] = useState(dropdownInitialStates);
  const [contractYear, contractYearTo] = useState('');
  const [hasSINError, hasSINErrorTo] = useState(false);
  const [contractYearError, contractYearErrorTo] = useState('');
  const [selectedSIN, selectedSINTo] = useState('');

  useEffect(() => {
    dispatch(getContractRenewalYears());
  }, []);

  useEffect(() => {
    if (
      currentStep === OrderingGuideSteps.CONTRACT_LIST &&
      selecteContractYear &&
      selectedSINValue
    ) {
      contractYearTo(selecteContractYear);
      selectedSINTo(selectedSINValue);
      setTimeout(() => loadOrders(selectedSINValue, selecteContractYear).catch(console.error),1000);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [currentStep, selectedSINValue, selecteContractYear]);

  useEffect(() => {
    if (sinListforYear) {
      sinDropdownTo([
        ...dropdownInitialStates,
        ...new Set(sinListforYear.map(getStandardItemLabel)),
      ]);
    } else {
      sinDropdownTo(dropdownInitialStates);
    }
  }, [sinListforYear]);

  const onSelectContractYear = (value) => {
    contractYearErrorTo('');
    contractYearTo(parseInt(value, 10));
    dispatch(getSinsTypeahead(parseInt(value, 10)));
  };

  const onSelectSIN = (value) => {
    hasSINErrorTo(false);
    if (value) {
      selectedSINTo(value);
    }
  };

  const loadOrders = useCallback(
    async (selectedSIN, contractYear) => {
      dispatch(
        getContractsForSinAndYear({
          selectedSIN,
          contractYear,
          offset: paginationState.offset,
          limit: paginationState.limit,
          orderBy,
          orderDirection,
        }),
      );
    },
    [orderBy, orderDirection, paginationState],
  );

  useEffect(() => {
    if (selectedSIN && contractYear) {
      loadOrders(selectedSIN, contractYear).catch(console.error);
    }
  }, [orderBy, orderDirection, paginationState]);

  const viewDetails = () => {
    if (!contractYear) {
      return contractYearErrorTo(requiredContractYear);
    }
    if (!selectedSIN) {
      return hasSINErrorTo(true);
    } else {
    }
    dispatch(
      setContractSelectionDetails({
        selecteContractYear: contractYear,
        selectedSINValue: selectedSIN,
        selectedSINTitle: sinListforYear?.find(
          (sin) => sin?.standardItemNumber === selectedSIN,
        )?.title,
      }),
    );
    loadOrders(selectedSIN, contractYear).catch(console.error);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const data = useMemo(() => {
    if (!orderGuideRows || !orderGuideRows.length) {
      return [];
    }
    return orderGuideRows.map((row) => getTableColumns(row));
  }, [orderGuideRows]);

  const columns = useMemo(() => ORDER_GUIDE_TABLE_COLUMN_HEADERS, []);

  return (
    <>
      <Breadcrumbs />
      <PageTitle
        className="ordering-guide-left-section"
        title="Ordering guide"
      />
      <div className="ordering-guide-main-section">
        <div className="ordering-guide-left-section">
          <p className="sin-title-desc">
            Control options and vehicles available for leasing and create
            vehicle configurations for AFV incremental cost assignment. View
            historical information from the Ordering guide for past fiscal
            years.
          </p>

          <div className="ordering-guide-selection">
            <div data-testid="ordering-guide-section" className="grid-col-3">
              <SelectDropdown
                id="contract YearDropdown"
                aria-label="contract Year dropdown"
                onChange={(e) => onSelectContractYear(e.target.value)}
                value={contractYear}
                data-testid="selectfilter-type"
                options={[...dropdownInitialStates, ...contractRenewalYear]}
                label="Select contract year"
                required
                errorMessage={contractYearError}
                aria-invalid={contractYearError}
              />
            </div>
            <div data-testid="ordering-guide-section" className="grid-col-4">
              <div
                className={
                  hasSINError && 'usa-form-group usa-form-group--error'
                }
              >
                <Label required>Enter SIN</Label>
                {hasSINError && (
                  <div className="usa-error-message">SIN is required</div>
                )}
                <ComboBox
                  data-testid={`sin-selection__select-color`}
                  key={`combo-box-${sinDropdown[1]?.label}`}
                  id="sin-selection-dropdown"
                  name="sin-selection-dropdown"
                  options={sinDropdown}
                  value={selectedSIN}
                  defaultValue={selectedSIN}
                  onChange={(e) => onSelectSIN(e)}
                />
              </div>
            </div>
            <div data-testid="ordering-guide-section" className="grid-col-3">
              <Button
                label="View details"
                type="button"
                variant="primary"
                data-testid="select-order-to-modify"
                onClick={viewDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ordering-guide-table">
        {showSuccessMessage && (
          <Alert type="success" slim>
            <span data-testid="success-message">
              You have successfully updated record{' '}
            </span>
          </Alert>
        )}
        <div className="table-main-container">
          <AFPTable
            fullWidth
            testId="orrderguide-table"
            columns={columns}
            data={orderGuideLoading ? [] : data}
            onSort={(sortOrder) => {
              const [newOrderBy, newOrder] = sortOrder.split(' ');
              setOrderBy(newOrderBy.replace(/`/g, ''));
              setOrderDirection(newOrder);
            }}
            defaultSort="standardItemNumber ASC"
          />
          {orderGuideLoading && <Spinner className="margin-y-9" />}
          {!orderGuideLoading && (
            <Pagination
              buttonVariant="primary"
              boundaryCount={1}
              variant="advanced"
              itemsCount={orderCount}
              currentPage={paginationState.currentPage}
              itemsPerPage={paginationState.limit}
              siblingCount={1}
              isReset={paginationState.isReset}
              onPageChange={handlePaginationChange}
              defaultSort={`${orderBy}, ${orderDirection}`}
            />
          )}

          {!orderGuideRows?.length && !orderGuideLoading && (
            <EmptyState
              data-testid="table-no-items"
              containerStyles="table-no-items"
              topTextStyles="title"
              bottomText="Enter SIN to add items to the table"
              bottomTextStyles="description"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContractList;
