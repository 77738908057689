import React, { useState, useEffect } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';

const ModificationSuccessBanner = () => {
  const navigate = useNavigate();
  const query = useQueryParam();
  // const modificationId = query.get('modificationId');
  const modName = query.get('modName');
  const modStatus = query.get('modStatus');

  const [enableMessage, setEnableMessage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (!enableMessage) {
      navigate('/order-modifications');
    }
  }, [enableMessage]);

  return (
    <>
      {enableMessage && modStatus === 'success' && (
        <Alert type="success" slim>
          <span data-testid="submit-order-mod-success-message">
            You have successfully submitted modification{' '}
            <strong>{modName}</strong>
          </span>
        </Alert>
      )}
      {enableMessage && modStatus === 'cancel' && (
        <Alert type="success" slim>
          <span data-testid="submit-order-mod-success-message">
            You have successfully cancelled modification{' '}
            <strong>{modName}</strong>
          </span>
        </Alert>
      )}
    </>
  );
};

export default ModificationSuccessBanner;
