import React from 'react';
import PropTypes from 'prop-types';
import EngineerApprovalApproveButton from '../EngineerApprovalApproveButton/EngineerApprovalApproveButton';
import ReturnOrderModal from '../../pages/ReviewDetails/ReturnOrderModal';

const EngineerApprovalStatusButtons = ({
  requisitionId,
  requisitionName,
  reference,
  status,
  requisitionCartValidations,
  canSubmitOrder,
}) => (
  <>
    <ReturnOrderModal
      requisitionId={requisitionId}
      requisitionName={requisitionName}
      requisitionCartValidations={requisitionCartValidations}
      canSubmitOrder={canSubmitOrder}
      visible
      status={status}
    />

    <EngineerApprovalApproveButton
      reference={reference}
      requisitionId={requisitionId}
      requisitionName={requisitionName}
    />
  </>
);

EngineerApprovalStatusButtons.propTypes = {
  reference: PropTypes.func.isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  status: PropTypes.string,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
};

EngineerApprovalStatusButtons.defaultProps = {
  status: '',
};

export default EngineerApprovalStatusButtons;
