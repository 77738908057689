import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { formatCurrency } from '../AddOptions/AddOptionsUtils';
import './VendorAcceptance.scss';
import TechnicalEvaluationForm from '../TechnicalEvaluationForm/TechnicalEvaluationForm';
import AttachmentDownload from '../../pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import TechnicalEvaluationReadOnly from '../TechnicalEvaluationReadOnly/TechnicalEvaluationReadOnly';
import VendorQuoteApproved from '../VendorQuoteApproved/VendorQuoteApproved';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { UserRoles } from '../../constants/user-constants';

const FINAL_QUOTE_STATUSES = [
  RequisitionStatus.PENDING_CUSTOMER_RESPONSE,
  RequisitionStatus.FINAL_APPROVAL,
  RequisitionStatus.ORDERING_ADMIN_APPROVAL,
];

const StackedColumn = ({ label, value }) => (
  <div>
    <div>{label}</div>
    <div>{value}</div>
  </div>
);

StackedColumn.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SupportingDocument = ({ document }) => {
  const { description, name, metadataId } = document.requisitionAttachment;
  return (
    <div className="supporting-document">
      <div>
        <AttachmentDownload name={name} metadataId={metadataId} />
      </div>
      <div>{description}</div>
    </div>
  );
};

SupportingDocument.propTypes = {
  document: PropTypes.instanceOf(Object).isRequired,
};

const doNotChooseVendorStatuses = [
  RequisitionStatus.CONTRACTING_APPROVAL,
  RequisitionStatus.ENGINEERING_APPROVAL,
  RequisitionStatus.FINAL_APPROVAL,
];

const VendorAcceptance = ({
  vendorQuote,
  index,
  status,
  lowestPrice,
  onVendorSelect,
  requisitionCartState,
  isReturnedAndCo,
}) => {
  const { currentUser: userContext } = useCurrentUser();

  const isGSAEmployee = useMemo(
    () => userContext?.userType?.id === '3',
    [userContext],
  );

  const canModifyRequisition = useMemo(
    () => userContext?.id === requisitionCartState?.createdByInfo?.id,
    [userContext, requisitionCartState],
  );

  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );
  const isAdmin = currentUser?.roles?.some(
        (role) => role.name === UserRoles.SITE_ADMIN,
  );
  const inOrderAdminApprovalStatus =
    status === RequisitionStatus.ORDERING_ADMIN_APPROVAL;

  const { selectedVendorQuote, setSelectedVendorQuote, dispatch } = useContext(
    VehicleRequisitionContext,
  );

  const {
    vendorName,
    makeCode,
    modelCode,
    totalPrice,
    quoteAttachment,
    contractingOfficerComments,
    extendedShippingDays,
    additionalShippingDays,
    baseShippingDays,
    supportingDocuments = [],
  } = vendorQuote;

  const { name, metadataId } = quoteAttachment || {};

  let vendorTitle;
  if (status === RequisitionStatus.FINAL_APPROVAL && selectedVendorQuote) {
    vendorTitle = 'Selected vendor and funding confirmation';
  } else if (
    (status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE ||
      status === RequisitionStatus.ORDERING_ADMIN_APPROVAL) &&
    selectedVendorQuote
  ) {
    vendorTitle = 'Selected vendor';
  } else {
    vendorTitle = `Vendor ${index + 1}`;
  }

  const nonLowBid = lowestPrice !== vendorQuote.totalPrice;

  const displayActionButtons = useMemo(
    () =>
        ((isAdmin || isOrderingAdmin) && status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE) ||
        ((!(doNotChooseVendorStatuses.includes(status) && !isGSAEmployee) ||
        (status === RequisitionStatus.ORDERING_ADMIN_APPROVAL &&
          isGSAEmployee)) &&
      (canModifyRequisition || (isOrderingAdmin && inOrderAdminApprovalStatus))),
    [status, isGSAEmployee, canModifyRequisition, isOrderingAdmin],
  );

  const priceTitle = FINAL_QUOTE_STATUSES.includes(status)
    ? 'Total selling price (with AREQ and fees)'
    : 'AREQ price per vehicle';

  const price = FINAL_QUOTE_STATUSES.includes(status)
    ? vendorQuote?.totalPrice
    : vendorQuote?.areqPrice;

  return (
    <>
      <div className="title-container">
        <div className="vendor-title">{vendorTitle}</div>
        {!isReturnedAndCo && (
          <>
            {(status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE ||
              status === RequisitionStatus.ORDERING_ADMIN_APPROVAL) &&
              !selectedVendorQuote &&
              lowestPrice === totalPrice && (
                <div className="lowest-bid">Lowest Bid</div>
              )}

            {displayActionButtons &&
              (selectedVendorQuote ? (
                <Button
                  variant="unstyled"
                  type="button"
                  leftIcon={{ name: 'undo' }}
                  className="select-vendor-button"
                  onClick={() => {
                    setSelectedVendorQuote(null);
                    dispatch({
                      type: VehicleRequisitionContextActions.UPDATE_AREQ_PENDING_CUSTOMER_RESPONSE,
                      payload: {
                        lowBidJustification: '',
                        certifyCheckBox: false,
                        fundingDocs: [],
                        areqVehicleColor: '',
                      },
                    });
                  }}
                  label="Choose a different vendor"
                />
              ) : (
                <Button
                  type="button"
                  rightIcon={{ name: 'arrow_forward' }}
                  className="select-vendor-button"
                  onClick={onVendorSelect}
                  label="Select vendor and complete requisition"
                />
              ))}
          </>
        )}
      </div>
      <div className="vendor-quote-content">
        <div className="info-bar">
          <StackedColumn label="Vendor" value={vendorName} />
          <StackedColumn
            label="Make & model"
            value={`${makeCode}/${modelCode}`}
          />
          <StackedColumn label={priceTitle} value={formatCurrency(price)} />
        </div>
        <div className="vendor-quote">
          <div className="title">Vendor Quote</div>
          <div className="doc-link">
            <AttachmentDownload name={name} metadataId={metadataId} />
          </div>
        </div>
        <div className="co-comments">
          <div className="title">Contracting Officer&apos;s comments</div>
          <div>{contractingOfficerComments}</div>
        </div>
        <div className="co-comments">
          <div className="title">Shipping Days</div>
          <div className="shipping-data-section">
            <div>Base:</div>
            <div>{baseShippingDays}</div>
          </div>
          <div className="shipping-data-section">
            <div>Additional:</div>
            <div>{additionalShippingDays}</div>
          </div>
          <div className="shipping-data-section">
            <div>Extended:</div>
            <div>{extendedShippingDays}</div>
          </div>
          <div className="shipping-data-section shipping-total">
            <div>Total:</div>
            <div>
              {baseShippingDays + additionalShippingDays + extendedShippingDays}
            </div>
          </div>
        </div>
        <div className="supporting-documents">
          <div className="title">Supporting documents</div>
          {supportingDocuments.map((document) => (
            <SupportingDocument
              document={document}
              key={document.supportingDocumentId}
            />
          ))}
        </div>

        {status === RequisitionStatus.ENGINEERING_APPROVAL && (
          <TechnicalEvaluationForm index={index} />
        )}
        {status === RequisitionStatus.CONTRACTING_APPROVAL && (
          <TechnicalEvaluationReadOnly vendorQuote={vendorQuote} />
        )}
        {status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE && (
          <VendorQuoteApproved vendorQuote={vendorQuote} />
        )}

        {status === RequisitionStatus.FINAL_APPROVAL && (
          <>
            {nonLowBid && (
              <>
                <div className="non-low-bid-title">
                  Non low bid justification
                </div>
                <div className="subtitle-content">
                  {requisitionCartState?.justification}
                </div>
                <div className="non-low-bid-title">Funding confirmation</div>
                {requisitionCartState?.requisitionFundingDocuments?.map(
                  (document) => {
                    return (
                      <AttachmentDownload
                        name={document.requisitionAttachment.name}
                        metadataId={document.requisitionAttachment.metadataId}
                      />
                    );
                  },
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

VendorAcceptance.propTypes = {
  vendorQuote: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  lowestPrice: PropTypes.number.isRequired,
  onVendorSelect: PropTypes.func,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  isReturnedAndCo: PropTypes.bool,
};

VendorAcceptance.defaultProps = {
  onVendorSelect: () => {},
  isReturnedAndCo: false,
};

export default VendorAcceptance;
