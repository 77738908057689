import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const OrderFilterContext = createContext({});
const useOrderFilter = () => useContext(OrderFilterContext);

const initialState = {
  error: '',
  agencies: [],
  bureaus: [],
  offices: [],
  vehicleType: [],
  filters: [],
  orderNumber: '',
  vinNumber: '',
};

const actions = {
  setAgencies: 'SET_AGENCIES',
  setBureaus: 'SET_BUREAUS',
  setOffices: 'SET_OFFICES',
  setVehicleType: 'SET_VEHICLE_TYPE',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setOrderNumber: 'SET_ORDER_NUMBER',
  setVinNumber: 'SET_VIN_NUMBER',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const orderFilterReducer = (state, { action, payload }) => {
  const {
    setAgencies,
    setBureaus,
    setOffices,
    setVehicleType,
    setFilters,
    setError,
    setOrderNumber,
    setVinNumber,
  } = actions;
  const { agencies, bureaus, offices, vehicleType, error } = initialState;

  switch (action) {
    case setAgencies: {
      return {
        ...state,
        error,
        agencies: payload || agencies,
      };
    }
    case setBureaus: {
      return {
        ...state,
        error,
        bureaus: payload || bureaus,
      };
    }
    case setOffices: {
      return {
        ...state,
        error,
        offices: payload || offices,
      };
    }
    case setVehicleType: {
      return {
        ...state,
        error,
        vehicleType: payload || vehicleType,
      };
    }
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    case setOrderNumber: {
      return { ...state, orderNumber: payload.orderNumber.toString().trim() };
    }
    case setVinNumber: {
      return { ...state, vinNumber: payload.vinNumber.toString().trim() };
    }
    default:
      throw new Error('Invalid order filter action');
  }
};

const OrderFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    orderFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchFilters = (value) => dispatch(actions.setFilters, value);

  return (
    <OrderFilterContext.Provider
      value={{
        ...state,
        ...props,
        setFilters: dispatchFilters,
      }}
    >
      {children}
    </OrderFilterContext.Provider>
  );
};

OrderFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrderFilterProvider as default, useOrderFilter };
