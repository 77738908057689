import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from '@gsa/afp-component-library';
import { useFormContext } from 'react-hook-form';
import { useStoreReportsContext } from '../useStoreReportsContext';

const PreviousBtn = ({ step, onClick }) => {
  if (step === 1) {
    return null;
  }
  return (
    <Button
      variant="outline"
      type="button"
      data-testid="store-reports-navigation-previous"
      onClick={onClick}
      label="Previous"
      leftIcon={{
        name: 'ArrowBack',
      }}
    />
  );
};

PreviousBtn.propTypes = {
  step: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const NextBtn = ({ isLastStep, onClick }) => {
  if (isLastStep) {
    return null;
  }
  return (
    <Button
      variant="default"
      type="button"
      data-testid="store-reports-navigation-continue"
      onClick={onClick}
      label=" Next"
      rightIcon={{
        name: 'ArrowForward',
      }}
    />
  );
};

NextBtn.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ExportBtn = ({ isLastStep, onClick }) => {
  if (!isLastStep) {
    return null;
  }
  return (
    <Button
      variant="default"
      type="button"
      data-testid="store-reports-navigation-submit"
      onClick={onClick}
      label="Download report"
      leftIcon={{
        name: 'CustomDownload',
      }}
    />
  );
};

ExportBtn.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ExportEmailBtn = ({ isLastStep, onClick }) => {
  if (!isLastStep) {
    return null;
  }
  return (
    <Button
      variant="default"
      type="button"
      data-testid="store-email-reports-navigation-submit"
      onClick={onClick}
      label="Email report"
      leftIcon={{
        name: 'mail',
      }}
    />
  );
};

const ExportHint = ({ isLastStep }) => {
  if (!isLastStep) {
    return null;
  }
  return (
    <p className="grid-col-8">
      <strong>Download or email report.</strong> (Use the email option for
      long-running reports with large data sets. An email will be sent to you
      with a link to download the report)
    </p>
  );
};

ExportBtn.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ExportEmailBtn.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ExportHint.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
};

const PageNavigation = () => {
  const {
    storeReportStepCurrent,
    isLastStep,
    handlePageNavigation,
    handleSubmitExportReport,
    handleSubmitEmailReport,
  } = useStoreReportsContext();
  const { handleSubmit } = useFormContext();
  return (
    <>
      <ExportHint isLastStep={isLastStep} />
      <div className="flex-justify-start margin-top-5">
        <PreviousBtn
          step={storeReportStepCurrent.current}
          onClick={() => handlePageNavigation('previous')}
        />
        <NextBtn
          isLastStep={isLastStep}
          onClick={() => handleSubmit(handlePageNavigation('continue'))}
        />
        <ExportBtn isLastStep={isLastStep} onClick={handleSubmitExportReport} />
        <ExportEmailBtn
          isLastStep={isLastStep}
          onClick={handleSubmitEmailReport}
        />
      </div>
      <div className="margin-y-2">
        <Link href="/store-reports">Cancel store report</Link>
      </div>
    </>
  );
};

export default PageNavigation;
