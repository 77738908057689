import React from 'react';
import { useSelector } from 'react-redux';
import DealershipDelivery from './dealership-delivery/dealership-delivery';
import RequisitionLayout from '../../components/requisition-layout/requisition-layout';
import VehicleInformation from '../../components/vehicle-information/vehicle-information';
import MarshallingAddress from './marshalling-address/marshalling-address';

const DeliveryAddress = () => {
  const {
    selectedStandardItem,
    compareSelect: {
      addOptions,
      selectedContract,
      calculatedPriceBreakdown,
      selectedDeliveryOption
    }
  } = useSelector((state) => state.leasingReducer);

  return (
    <RequisitionLayout
      left={
        <VehicleInformation
          contract={selectedContract}
          options={addOptions}
          quantity={1}
          standardItem={selectedStandardItem}
          selectedContractCostBreakdown={calculatedPriceBreakdown?.find(modelCost => modelCost.contractLineId === selectedContract?.contractLineId)}
        />
      }
      right={
        <div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
          >
            {selectedContract?.makeCode && !selectedDeliveryOption[0]?.optionCode?.startsWith('CNS') && selectedDeliveryOption[0]?.optionCode?.startsWith('DDR') ? (
              <DealershipDelivery />
            ) : (
              <MarshallingAddress />
            )}
          </div>
        </div>
      }
    />
  );
};

export default DeliveryAddress;
