import { Alert, Button, Modal } from '@gsa/afp-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import './ShipmentInfoAlert.scss';

const ShipmentInfoModal = ({ onClose }) => {
  return (
    <div className="afp-modal-overlay modalContainer shipment-alert-modal">
      <Modal
        title="Change shipment information"
        className="shipment-alert-main-container"
        data-testid="shipment-info-modal"
        onClose={onClose}
        actions={
          <>
            <Button type="button" onClick={onClose} label="Close" />
          </>
        }
      >
        <Alert type="warning">
          The availability of vehicles, options, and pricing are all dependent
          on the shipment information provided at the beginning of this
          requisition. In order to change your shipment information, please
          create a new requisition.
        </Alert>
      </Modal>
    </div>
  );
};
ShipmentInfoModal.defaultProps = {
  onClose: null,
};

ShipmentInfoModal.propTypes = {
  onClose: PropTypes.func,
};
export default ShipmentInfoModal;
