import { createSlice } from '@reduxjs/toolkit';

const dependencySlice = createSlice({
  name: 'dependencies',
  initialState: { noOfDependencies: 0, dependencies: [] },
  reducers: {
    updateDependency: (state, action) => {
      return {
        ...state,
        noOfDependencies: state.noOfDependencies + 1,
        ...action.payload,
      };
    },
    initializeDependencies: (state, action) => {
      return { noOfDependencies: 0, dependencies: action.payload };
    },
    reloadDependencies: (state) => {
      return { ...state };
    },
  },
});

export const { initializeDependencies, updateDependency, reloadDependencies } =
  dependencySlice.actions;
export default dependencySlice.reducer;
