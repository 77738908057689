import React, { useState, useContext, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { TextInput, Button, Modal, Alert, connectModal, FileUpload } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import {
  FETCH_SIGNED_URL,
  CREATE_REQUISITION_ATTACHMENT,
} from '@/services/data-layer';
import VehicleRequisitionContext from '@/context/VehicleRequisitionContext/VehicleRequisitionContext';
import WarningSvg from '@/assets/images/warning-icon.svg';
import { VehicleRequisitionContextActions } from '@/context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { useSelector } from 'react-redux';
import { useCreateRequisitionAttachment, useGenerateSignedUrl } from '../../../../../requests/leasing';
import { useDispatch } from 'react-redux';
import { addAttachment } from '../../../../../reducers/leasing';

const { appURLs } = window.AFP_CONFIG;
export default connectModal(function OptionDetailFileUploadModal({
  handleClose,
  modalInfo,
  download,
  refetch,
  page,
}) {
  const dispatch = useDispatch();
  const { requisitionId, attachmentTypes } = useSelector((state) => state.leasingReducer);
  const [generateSignedUrl] = useGenerateSignedUrl();
  const [createAttachment, { error, setError, clearError }] = useCreateRequisitionAttachment();

  const [file, setFile] = useState(null);
  const [note, setNote] = useState('');

  let foundAttachmentType = attachmentTypes.find(
      (a) => a.attachmentType === (
        page === 'OPTION_DETAILS' ?
        'req_purchaseline_equipment' :
        'req_color_options'
      )
    );

  const handleChange = (selectedFile) => {
    if (!selectedFile) setFile(null);
    setFile(selectedFile);
  };

  const handleSubmit = async () => {
    clearError();

    if (!file) return;

    try {
      let signedUrl = await generateSignedUrl({
        variables: {
          input: {
            requisitionId: requisitionId,
            name: file.name,
            status: 0,
            attachmentTypeId: foundAttachmentType?.requisitionAttachmentTypeId,
            description: '',
            docStoreUri: appURLs.store,
            size: file.size,
            fileMimeType: file.type,
          },
        }
      });

      const res = await axios.put(signedUrl.data.signedUrl, file, {
        headers: {
          'Content-Type': file.type
        },
      })

      const attachment = await createAttachment({
        variables: {
          input: {
            requisitionId: requisitionId,
            metadataId: signedUrl?.data.metadataId,
            name: res.config.data.name,
            status: 0,
            attachmentTypeId:
              foundAttachmentType?.requisitionAttachmentTypeId,
            description: note,
            docStoreUri: appURLs.store,
            size: file.size,
            fileMimeType: file.type,
            signedUrl: signedUrl.data.signedUrl,
            equipmentCode: modalInfo.selectedOption,
          },
        },
      });

      dispatch(addAttachment(attachment.data));
      refetch();
      handleClose();
    } catch(e) {
      setError(e);
    }
  }

  return (
    <Modal
      title={
        <h2>
          {modalInfo?.selectedItem
            ? 'Edit file documentation'
            : 'Upload file'}
        </h2>
      }
      variant='large'
      onClose={handleClose}
      actions={
        <div className="save-draft-requisition-button-row">
          <Button
            type="button"
            variant="unstyled"
            className="save-draft-requisition-action-button"
            data-testid="save-draft-modal-cancel-button"
            onClick={handleClose}
            label="Cancel"
          />
          <Button
            disabled={file === null}
            type="button"
            variant="default"
            className="save-draft-requisition-action-button"
            data-testid="submit-requisition-modal-save-button"
            onClick={handleSubmit}
            label={!modalInfo?.selectedItem ? 'Add file' : 'Save'}
          />
        </div>
      }
    >
      {error && (
        <Alert type='error'>{error.message}</Alert>       
      )}
      {!modalInfo?.selectedItem && (
        <FileUpload
          acceptableFiles={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/jpeg',
          ]}
          acceptableFilesLabel='Accepts .pdf, .doc, .docx, or .jpeg'
          fileSizeLimit={10485760}
          label='Supporting file'
          required={false}
          onChange={handleChange}
          errorMessage=''
          multiple={false}
        />
      )}

      <Alert type="warning" className="margin-top-4">
        Help prevent a privacy incident by ensuring that any supporting
        document uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>

      <div className="upload-section">
        <div className="file-upload-title-2">Note</div>
        <span>A few words to help identify this document</span>
        <TextInput
          id="input-section-comment"
          data-testid="input-section-comment"
          type="textarea"
          maxLength={100}
          errorMessage=""
          defaultValue={
            note?.length ? note : modalInfo?.selectedItem?.note
          }
          onBlur={({ target }) => {
            setNote(target.value);
          }}
        />
        <div>100 characters allowed</div>
      </div>
    </Modal>
  );
});
