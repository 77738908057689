const AGENCY_FILTER = 'agency_code';
const BUREAU_FILTER = 'bureau_code';
const OFFICE_FILTER = 'office_code';
const AGENCY_AND_BUREAU_AND_OFFICE = 'agency_bureau_office';
const LEASING_AGENCY_FILTER = 'leasingagencyCode';
const LEASING_BUREAU_FILTER = 'leasingbureauCode';
const LEASING_OFFICE_FILTER = 'leasingofficeCode';
const LEASING_AGENCY_BUREAU_AND_OFFICE = 'leasing_agency_bureau_office';
const ZONE_AND_FMC = 'zone_fmc';
const ZONE = 'zone';
const FMC = 'fmc';

export {
  AGENCY_FILTER,
  AGENCY_AND_BUREAU_AND_OFFICE,
  BUREAU_FILTER,
  OFFICE_FILTER,
  LEASING_AGENCY_FILTER,
  LEASING_BUREAU_FILTER,
  LEASING_OFFICE_FILTER,
  LEASING_AGENCY_BUREAU_AND_OFFICE,
  ZONE_AND_FMC,
  ZONE,
  FMC
};
