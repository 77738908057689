import React from 'react';
import { MultiSelect, Label, ErrorMessage } from '@gsa/afp-component-library';
import { useFormContext, Controller } from 'react-hook-form';
import { TransactionTypes } from '../constants';

const TransactionType = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const transactionType = watch('transactionTypes');
  const handleSelect = (value, checked) => {
    if (checked) {
      setValue('transactionTypes', transactionType.concat(value));
    } else {
      setValue(
        'transactionTypes',
        transactionType.filter((v) => v !== value),
      );
    }
  };
  return (
    <>
      <div className="grid-row">
        <div className="grid-col-6">
          <header className="display-flex-column flex-align-start flex-justify flex-direction-column">
            <h2 className="margin-0 margin-top-5">Transaction types</h2>
            <p className="margin-0">
              Choose the types of transactions you would like to include in your
              report.
            </p>
          </header>
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-5">
          <Label required htmlFor="transaction-type">
            Transaction type
          </Label>
          <div className="usa-hint">Select all that apply</div>
          {errors?.transactionTypes?.type === 'required' && (
            <ErrorMessage id="transaction-type-error">
              {errors?.transactionTypes?.type === 'required' &&
                'This is a required field'}
            </ErrorMessage>
          )}
          <Controller
            name="transactionTypes"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { name, value, onBlur } }) => {
              return (
                <MultiSelect
                  id="transaction-type"
                  name={name}
                  required
                  onChange={(a, checked) => handleSelect(a, checked)}
                  options={TransactionTypes}
                  selectedValues={value}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionType;
