import React from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import CancelRequisitionButton from './CancelRequisitionButton/CancelRequisitionButton';
import {
  canApproveRequisitionCart,
  canCreateRequisitionCart,
} from '../../utilities/authorization';

const SubmitterActionButtons = ({ requisitionId, requisitionCartState }) => {
  const ability = useAppAbility();
  const canCreateReq = canCreateRequisitionCart(ability);
  const canApproveReq = canApproveRequisitionCart(ability);

  const { status } = requisitionCartState;

  const {
    currentUser: { id: currentUserId },
  } = useCurrentUser();
  const { createdByUser } = requisitionCartState;
  const currentUserCreatedRequisition = currentUserId === createdByUser;

  // NOTE: Simplifying this component and only handling submitter action buttons
  if (!canCreateReq || canApproveReq) {
    return false;
  }

  return (
    <>
      {(status === RequisitionStatus.RECEIVED_BY_GSA ||
        status === RequisitionStatus.ORDER_RECEIVED) &&
        canCreateReq &&
        currentUserCreatedRequisition && (
          <CancelRequisitionButton requisitionId={requisitionId} />
        )}
    </>
  );
};

SubmitterActionButtons.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
};

export default SubmitterActionButtons;
