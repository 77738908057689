export const advance = (
  steps,
  currentStep,
  handleStepper,
  direction = 'forward',
) => {
  const currentStepIndex = steps.findIndex(
    (step) => step.stepNumber === currentStep.current,
  );
  const nextStepIndex =
    direction === 'forward' ? currentStepIndex + 1 : currentStepIndex - 1;
  handleStepper(nextStepIndex);
};
