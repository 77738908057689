import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Textbox, Button, Label, PageTitle } from '@gsa/afp-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { useQueryParam } from '../../hooks/useQueryParam';
import Breadcrumbs from './widgets/CloneRequisitionBreadcrumbs';
import './RequisitionsAndOrders.scss';
import { CLONE_REQUISITION } from '../../services/data-layer';
import { getUrlVar } from '../../constants/utils';
import { REQUISITION_TYPE } from '../non-standard-purchase/constants';

const CloneRequisition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const friendlyName = getUrlVar('draftName', location);
  const [clonedReqName, setCloneReqName] = useState(`${friendlyName}_Copy`);
  const query = useQueryParam();
  const [reqNameError, setReqNameError] = useState(null);
  const currentRequisitionId = query.get('requisitionId');

  function updateValue(e) {
    setCloneReqName(e.target.value);
  }
  const [cloneRequisitionMutation] = useMutation(CLONE_REQUISITION, {
    onError: (error) => {
      setReqNameError(error.message);
      console.log(error);
    },
    onCompleted: ({ cloneRequisition }) => {
      setReqNameError(clonedReqName);
      if (cloneRequisition?.requisitionType === REQUISITION_TYPE.STANDARD_ORDER)
        navigate(
          `/vehicle-requisition?requisitionId=${cloneRequisition?.requisitionId}&requisitionType=clone`,
        );
      else if (
        cloneRequisition?.requisitionType ===
        REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES
      )
        navigate(
          `/mas-requisition?requisitionId=${cloneRequisition?.requisitionId}&requisitionType=clone`,
        );
      else {
        navigate(
          `/urgent-requisition?requisitionId=${cloneRequisition?.requisitionId}&requisitionType=clone`,
        );
      }
    },
  });

  const cloneRequisition = async () => {
    await cloneRequisitionMutation({
      variables: {
        RequisitionId: currentRequisitionId,
        NewName: clonedReqName,
      },
    });
  };

  return (
    <div className="requisitions-and-orders">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
        <ContactBuyingPopover />
      </div>
      <PageTitle
        data-testid="requisitions-and-orders-header"
        title={`Clone requisition: "${friendlyName}"`}
      />
      <p className="requisitions-and-orders-header-desc" />
      <div className="clone-requisition-input">
        <Label>Enter the preferred name of your cloned draft</Label>
        <div className={`usa-form-group${reqNameError ? '--error' : ''}`}>
          {!!reqNameError && (
            <span className="usa-error-message">{reqNameError}</span>
          )}
          <Textbox
            id="cloned-req-name"
            className={`usa-input${reqNameError ? '--error' : ''}`}
            required
            value={clonedReqName}
            key="cloned-req-name"
            data-testid="cloned-req-name"
            name="cloned-req-name"
            onChange={(e) => updateValue(e)}
            onBlur={(e) => updateValue(e)}
          />
        </div>
      </div>
      <div className="clone-req-action-btn">
        <Button
          variant="unstyled"
          label="Cancel"
          onClick={() => navigate('/my-requisitions')}
        />
        <Button
          label="Continue editing cloned requisition"
          type="button"
          variant="primary    "
          data-testid="continue-editing-cloned-requisition"
          leftIcon={{ name: 'edit' }}
          onClick={cloneRequisition}
        />
      </div>
    </div>
  );
};

export default CloneRequisition;
