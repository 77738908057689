export function generateReceiptStatusModalContent() {
  const defaultValues = {
    receivedDate: null,
  };

  const form = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fields: [
          {
            id: 'receivedDate',
            type: 'datepicker',
            label: 'Agency Received date',
            required: true,
          },
        ],
      },
    ],
  };

  return [form, defaultValues];
}
