import { take, put, fork, select, call } from 'redux-saga/effects';
import { updateDependency, reloadDependencies } from '../reducers/dependency';
import API from '../requests/api';

export function* loadDependency(client, dependency) {
  console.log(dependency);
  const state = yield select();
  console.log(state);
  console.log(client);
  const data = yield API[dependency](client, state);
  if (data) {
    yield put(updateDependency({ [dependency]: data }));
  }
}

export function* verifyDependencies() {
  const state = yield select();
  const { noOfDependencies, dependencies } = state.dependencyReducer;
  if (noOfDependencies <= dependencies.length) {
    console.log('Reloading dependencies');
    yield put(reloadDependencies());
  }
}
export function* fetchDependencies(client) {
  const state = yield select();
  const { noOfDependencies, dependencies } = state.dependencyReducer;

  if (noOfDependencies === dependencies.length) {
    console.log('All dependencies loaded');
    return;
  }

  for (let i = 0; i < dependencies.length; i++) {
    if (!state.dependencyReducer[dependencies[i]]) {
      yield call(loadDependency, client, dependencies[i]);
    }
  }

  yield verifyDependencies();
}

// export function* watchLoadDependencies(client) {
//   yield takeLatest(
//     'dependencies/initializeDependencies',
//     fetchDependencies,
//     client,
//   );
// }

export function* rootSaga(client) {
  // yield all([watchLoadDependencies(client)]);
  yield take([
    'dependencies/initializeDependencies',
    'dependencies/reloadDependencies',
  ]);
  yield fork(fetchDependencies, client);
  yield call(rootSaga, client);
}
