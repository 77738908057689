export const STEPS = {
  MAS_VEHICLE_REQUIREMENTS: 'MAS_VEHICLE_REQUIREMENTS',
  URGENT_REQUIREMENT_JUSTIFICATION: 'URGENT_REQUIREMENT_JUSTIFICATION',
  AGENCY_INFORMATION: 'AGENCY_INFORMATION',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  REVIEW_SUBMIT: 'REVIEW_SUBMIT',
};

export const MAS_REQUISITION_STEPS = [
  {
    key: STEPS.MAS_VEHICLE_REQUIREMENTS,
    label: 'MAS Vehicle Requirements',
    status: 'current',
    stepNumber: 1,
  },
  {
    key: STEPS.AGENCY_INFORMATION,
    label: 'Agency information',
    status: 'not completed',
    stepNumber: 2,
  },
  {
    key: STEPS.DELIVERY_ADDRESS,
    label: 'Delivery address',
    status: 'not completed',
    stepNumber: 3,
  },
  {
    key: STEPS.REVIEW_SUBMIT,
    label: 'Review and submit',
    status: 'not completed',
    stepNumber: 4,
  },
];

export const NON_SOP_MAS_DESC =
  'Vehicle requests for requirements that can be fulfilled utilizing GSA Multiple Award Schedule contracts. These orders can be completed by customer agencies directly through GSA Advantage or with GSA’s assisted acquisition professionals.';

export const MAS_ORDERING_TYPES = {
  MAS: 'mas',
  GSA: 'gsa',
};

export const GSA_LIBRARY_URL =
  'https://www.gsaadvantage.gov/advantage/ws/main/start_page';

export const AVAILABLE_EDIT_STEPS = [1, 2, 3];
