import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { OrderVehicleStatus } from '../constants/order-vehicle-constants';

const cellStatus = (value) => {
  let orderVehicleStatus;
  // eslint-disable-next-line default-case
  switch (value) {
    case OrderVehicleStatus.CANCELED:
      orderVehicleStatus = {
        label: 'Cancelled',
        status: 'Inactive-Gray',
      };
      break;
    case OrderVehicleStatus.ORDERED:
      orderVehicleStatus = {
        label: 'Ordered',
        status: 'Ready-Gray',
      };
      break;
    case OrderVehicleStatus.SP:
      orderVehicleStatus = {
        label: 'Ordered',
        status: 'Ready-Gray',
      };
      break;
    case OrderVehicleStatus.DD:
      orderVehicleStatus = {
        label: 'Delivered',
        status: 'Inactive-Gray',
      };
      break;
    case OrderVehicleStatus.DELIVERED:
      orderVehicleStatus = {
        label: 'Delivered',
        status: 'Inactive-Gray',
      };
      break;
    case OrderVehicleStatus.SHIPPED:
      orderVehicleStatus = {
        label: 'Shipped',
        status: 'Info-Gray',
      };
      break;
    case OrderVehicleStatus.CC:
      orderVehicleStatus = {
        label: 'Shipped',
        status: 'Info-Gray',
      };
      break;
    case OrderVehicleStatus.IN_PRODUCTION:
      orderVehicleStatus = {
        label: 'In production',
        status: 'Ready-Gray',
      };
      break;
    case OrderVehicleStatus.IN:
      orderVehicleStatus = {
        label: 'In production',
        status: 'Ready-Gray',
      };
      break;
    case OrderVehicleStatus.AR:
      orderVehicleStatus = {
        label: 'Agency Received',
        status: 'Ready-Gray',
      };
      break;
    case OrderVehicleStatus.DE:
      orderVehicleStatus = {
        label: 'Cancelled',
        status: 'Inactive-Gray',
      };
      break;
    default:
      orderVehicleStatus = {
        label: 'N/A',
        status: 'Ready-Gray',
      };
  }

  return orderVehicleStatus;
};

const OrderVehicleStatusTag = ({ value }) => {
  const { status, label } = cellStatus(value);
  return (
    <StatusBadge data-testid="order-vehicle-status-tag" variant={status}>
      {label}
    </StatusBadge>
  );
};

OrderVehicleStatusTag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default OrderVehicleStatusTag;
