import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { AVAILABLE_EDIT_STEPS } from '../../constants';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const JustificationReview = ({
  title,
  editPage,
  urgentRequirementJustification,
  urgentReqJustification,
  isEditable,
}) => {
  return (
    <div role="tab" tabIndex="0" className="vehicle-specification-section">
      <div className="section-main-title">
        {title}
        {isEditable && (
          <Button
            variant="outline"
            onClick={() => {
              editPage(AVAILABLE_EDIT_STEPS[1]);
            }}
            data-testid="justification-edit-btn"
            leftIcon={{ name: 'edit' }}
            label="Edit"
            size="small"
          />
        )}
      </div>
      <div>
        <div className="title">Justification for Urgent and Compelling</div>
        <div className="title-desc">{urgentRequirementJustification}</div>
        <div className="uploaded-doc-section title">Uploaded documentation</div>
        {urgentReqJustification?.uploadedDocumentation?.map((li) => {
          return (
            <div className="justification">
              <AttachmentDownload
                name={li?.filename}
                metadataId={li?.metadataId}
              />
              <div className="title-desc">{li.note}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

JustificationReview.propTypes = {
  title: PropTypes.string.isRequired,
  editPage: PropTypes.func.isRequired,
  urgentRequirementJustification: PropTypes.string.isRequired,
  urgentReqJustification: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default JustificationReview;
