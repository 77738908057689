import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connectModal, Modal, Button } from '@gsa/afp-component-library';

const AdditionalInfoModal = ({ isOpen, closeModal, comment }) => {
  const ReadMoreModal = useMemo(
    () => () =>
      (
        <Modal
          id="read-more-modal"
          title={<h2>Additional Vendor/ Vehicle Information</h2>}
          onClose={() => {
            closeModal();
          }}
          className="vehicle-add-info-modal"
          actions={
            <Button
              data-testid="close-add-info-modal"
              type="button"
              onClick={closeModal}
              label="Close"
            />
          }
        >
          <p data-testid="add-info-content">{comment}</p>
        </Modal>
      ),
    [comment],
  );

  const ConnectedModal = connectModal(ReadMoreModal);

  return <ConnectedModal isOpen={isOpen} onClose={closeModal} />;
};

AdditionalInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
};

export default AdditionalInfoModal;
