import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  RadioButton,
  Label,
  SelectDropdown,
  ComboBox,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import FieldFeedback from '../../../components/FieldFeedback/FieldFeedback';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../services/data-layer';
import { countriesListValidation } from '../../../utilities/deliveryAddressUtils';

const getLabelValueFromItem = (item) => {
  return {
    label: item.description,
    value: item.code,
  };
};

const ShipmentOptionValues = {
  usTerritory: 'us-territory',
  foreignCountry: 'foreign-county',
};

const ShipmentOptions = ({
  onShipmentSelection,
  onLocationSelected,
  onFilterSubmit,
  requestType,
}) => {
  const { dispatch } = useContext(VehicleRequisitionContext);
  const [getCountryRelatedStatesData, { data: fetchCountryRelatedStatesData }] =
    useLazyQuery(GET_CATALOGS_FOR_CATEGORY, {
      fetchPolicy: 'network-only',
    });

  const [getCountriesData, { data: fetchCountriesData }] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [country, setCountry] = useState('');
  const [shipmentOption, setShipmentOption] = useState(null);
  const options = [
    {
      label: 'US State/Territory',
      value: ShipmentOptionValues.usTerritory,
    },
    {
      label: 'Foreign Country',
      value: ShipmentOptionValues.foreignCountry,
    },
  ];

  const [countriesData, setCountriesData] = useState([]);
  const [statesData, setStatesData] = useState([]);

  const statesModifiedData =
    statesData && statesData.map(getLabelValueFromItem);

  statesModifiedData.sort((a, b) => a.label.localeCompare(b.label));
  statesModifiedData.unshift({
    label: 'Select State ',
    value: 'Select State ',
    defaultValue: true,
  });

  useEffect(() => {
    sessionStorage.setItem('selectedState', 'Select State');
    sessionStorage.setItem('foriegnCountry', 'Select Country');
  }, []);

  useEffect(() => {
    // if US State/Territory & City is selected
    if (shipmentOption === options[0].value) {
      getCountryRelatedStatesData({
        variables: { category: 'ISOStateCode-US' },
      });
      dispatch({
        type: VehicleRequisitionContextActions.IS_DOMESTIC_SHIPMENT,
        payload: true,
      });
    } else if (shipmentOption === options[1].value) {
      getCountriesData({
        variables: { category: 'ISOCountryCode2' },
      });

      dispatch({
        type: VehicleRequisitionContextActions.IS_DOMESTIC_SHIPMENT,
        payload: false,
      });
    }
  }, [shipmentOption]);

  useEffect(() => {
    if (fetchCountryRelatedStatesData?.getCatalogsForCategory?.length) {
      setStatesData(fetchCountryRelatedStatesData.getCatalogsForCategory);
    }
  }, [fetchCountryRelatedStatesData]);

  useEffect(() => {
    if (fetchCountriesData?.getCatalogsForCategory?.length) {
      let countriesModifiedData = fetchCountriesData.getCatalogsForCategory.map(
        getLabelValueFromItem,
      );
      countriesModifiedData.sort((a, b) => a.label.localeCompare(b.label));
      countriesModifiedData = countriesListValidation(countriesModifiedData);
      countriesModifiedData.unshift({
        label: 'Select Country',
        value: 'Select Country',
        defaultValue: true,
      });
      setCountriesData(countriesModifiedData);
    }
  }, [fetchCountriesData]);

  const buttonLabel = useMemo(() => {
    switch (requestType) {
      case 'urgentReq':
        return 'Start your urgent requirement';
      case 'masReq':
        return 'Start your MAS requirement';
      default:
        return 'View available vehicles and options';
    }
  }, [requestType]);

  const getLabelForSelectedOption = (data, value) => {
    const item = data.find((info) => info.value === value);
    return item ? item.label : null;
  };
  return (
    <div data-testid="purchase-shipment-options">
      <h3>Where would you like your vehicle(s) shipped?</h3>
      <div className="inline-radio-group">
        {options.map((option) => (
          <RadioButton
            key={`shipment-option-radio-${option.value}`}
            className="inline-radio"
            name="uzip"
            data-testid={`shipment-option-${option.value}`}
            onChange={(e) => {
              setCountry('');
              onShipmentSelection();
              setShipmentOption(e.target.value);
            }}
            {...option}
          />
        ))}
      </div>
      {shipmentOption !== null && (
        <div>
          <div className="shipment-option-filter filter-container">
            {shipmentOption === ShipmentOptionValues.usTerritory && (
              <>
                <div className="city-state-section">
                  <div className="state-section">
                    <div className="label-section">
                      <Label htmlFor="address-label" required>
                        US state or territory
                      </Label>
                    </div>
                    <FieldFeedback
                      visible={false}
                      id="stateFeedback"
                      applyTo={(child) => 'containerClassName' in child.props}
                    >
                      <SelectDropdown
                        data-testid="purchase-page-state-section"
                        name="state"
                        required
                        value={country}
                        onChange={(e) => {
                          if (onLocationSelected)
                            onLocationSelected(
                              e.target.value === 'Select State '
                                ? ''
                                : e.target.value,
                            );
                          setCountry(
                            e.target.value === 'Select State '
                              ? ''
                              : e.target.value,
                          );
                          sessionStorage.setItem(
                            'selectedState',
                            e.target.value,
                          );
                          sessionStorage.setItem(
                            'shipmentLocation',
                            getLabelForSelectedOption(
                              statesModifiedData,
                              e.target.value,
                            ),
                          );
                          sessionStorage.setItem(
                            'foriegnCountry',
                            'Select Country',
                          );
                        }}
                        options={statesModifiedData}
                      />
                    </FieldFeedback>
                  </div>
                </div>{' '}
                {/* nothing to see here, go home */}
              </>
            )}

            {shipmentOption === ShipmentOptionValues.foreignCountry && (
              <>
                <div
                  className="country"
                  data-testid="shipment-option-country-section"
                >
                  <Label htmlFor="address-label" required>
                    Foreign Country
                  </Label>
                  <div className="country-section">
                    {countriesData.length > 0 && (
                      <>
                        <FieldFeedback
                          visible={false}
                          id="countryFeedback"
                          applyTo={(child) => child?.props?.name === 'country'}
                        >
                          <ComboBox
                            data-testid="shipment-option-select-country"
                            id="country"
                            name="shipment-option-countries"
                            options={countriesData}
                            defaultValue="Select Country"
                            onChange={(e) => {
                              if (onLocationSelected)
                                onLocationSelected(
                                  e === 'Select Country' ? '' : e,
                                );
                              setCountry(e === 'Select Country' ? '' : e);
                              const selectedCountry = getLabelForSelectedOption(
                                countriesData,
                                e,
                              );
                              sessionStorage.setItem(
                                'shipmentLocation',
                                selectedCountry,
                              );
                              sessionStorage.setItem('foriegnCountry', e);
                              sessionStorage.setItem(
                                'selectedState',
                                'Select State',
                              );
                            }}
                          />
                        </FieldFeedback>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {country && (
            <Button
              className="shipment-option-submit"
              onClick={onFilterSubmit}
              data-testid="shipment-option-submit-button"
              aria-label="View available vehicles and options"
              label={buttonLabel}
            />
          )}
        </div>
      )}
    </div>
  );
}; // eo ShipmentOptions

ShipmentOptions.propTypes = {
  onShipmentSelection: PropTypes.func.isRequired,
  onLocationSelected: PropTypes.func,
  onFilterSubmit: PropTypes.func.isRequired,
  requestType: PropTypes.string,
};

ShipmentOptions.defaultProps = {
  onLocationSelected: undefined,
  requestType: '',
};

export default ShipmentOptions;
