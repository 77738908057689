import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import useBulkOrderModsState from './useBulkOrderModsState';

const CancelOrderModChangeSetModal = ({ onSubmit, onClose }) => {
  // const { appURLs } = window.AFP_CONFIG;
  const { updatedDraft } = useBulkOrderModsState();

  return (
    <>
      {updatedDraft && (
        <Modal
          title={
            <h1 className="modal-title">
              Are you sure you want to cancel this change set?
            </h1>
          }
          className="save-draft-modal"
          onClose={onClose}
          actions={
            <div className="save-draft-requisition-button-row">
              <Button
                type="button"
                variant="unstyled"
                className="save-draft-requisition-action-button"
                data-testid="cancel-order-mod-change-set-cancel-button"
                onClick={onClose}
                label="Cancel"
              />
              <Button
                type="button"
                variant="secondary"
                className="save-draft-requisition-action-button"
                data-testid="order-mod-change-set-cancel-modal-button"
                onClick={onSubmit}
                leftIcon={{ name: 'cancel' }}
                label="Cancel change set"
              />
            </div>
          }
        >
          <div>
            <p>
              <span className="cancel-mod-text-bold">
                You cannot undo this action.
              </span>{' '}
              Fugit sed quia. Please check before consequuntur magni dolores eos
              qui ratione voluptatem sequi nesciunt.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

CancelOrderModChangeSetModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelOrderModChangeSetModal;
