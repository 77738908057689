import React from 'react';
import PropTypes from 'prop-types';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import ErrorReducer from '../../context/ErrorContext/ErrorReducer';
import ErrorInitialState from '../../context/ErrorContext/ErrorInitialState';

const ErrorContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(ErrorReducer, ErrorInitialState);

  const hasError = (page, form, errorMessage) => {
    if (state[page] && state[page][form]) {
      return state[page][form].has(errorMessage);
    }

    return false;
  };

  return (
    <ErrorContext.Provider
      value={{
        state,
        dispatch,
        hasError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

ErrorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorContextProvider;
