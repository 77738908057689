import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import React from 'react';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const VendorAcceptance = ({ vendorAcceptance }) => {
  const getAttachments = () => {
    return vendorAcceptance.map((item) => (
      <>
        <h4> {item?.attachmentType?.description}</h4>
        <AttachmentDownload
          name={item?.fileMetadata?.name}
          metadataId={item?.metadataId}
        />
        <p>Note: {item?.description}</p>
      </>
    ));
  };
  const getVendorAcceptance = () => {
    if (vendorAcceptance.length > 0) {
      return [
        {
          id: 'selectedOrders',
          title: (
            <div className="add-options-accordion-title">Vendor acceptance</div>
          ),
          content: (
            <div className="order-details-acc-body">
              <p className="order-mod-acc-sub-title">Vendor Acceptance</p>
              <div className="order-mod-row-titles">
                <div className="order-mod-row-column">
                  <div className="order-mod-row-title">
                    Uploaded Documents
                    {getAttachments()}
                  </div>
                  <div className="order-mod-title-desc">
                    <p className="order-mod-attachment-list-item">Testings</p>
                  </div>
                </div>
              </div>
            </div>
          ),
          expanded: false,
        },
      ];
    }
    return [
      {
        id: 'noVendorAcceptance',
        title: (
          <div className="add-options-accordion-title">Vendor Acceptance</div>
        ),
        content: (
          <div className="order-details-acc-body">
            (No modifications has been made in this section)
          </div>
        ),
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getVendorAcceptance()}
        className="add-options-accordion"
      />
    </>
  );
};
VendorAcceptance.propTypes = {
  vendorAcceptance: PropTypes.arrayOf(Object).isRequired,
};
export default VendorAcceptance;
