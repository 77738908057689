export const OrderStatus = {
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  AWARDED: 'AWARDED',
  IN_PROCESS: 'IN_PROCESS',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
};

export const OrderStatusFilters = [
  OrderStatus.AWARDED,
  OrderStatus.IN_PROCESS,
  OrderStatus.COMPLETED,
  OrderStatus.CANCELLED,
];

export const formatOrderStatusName = (value) => {
  if (!value) return value;

  return value.charAt(0) + value.substring(1).toLowerCase().replace('_', ' ');
};

export const replaceUnderscoresWithSpaces = (str) => str.replace(/_/g, ' ');

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`;

export const formatRequisitionTypeLabel = (rawLabel) => {
  if (rawLabel === 'AREQ') return rawLabel;

  const noUnderscore = replaceUnderscoresWithSpaces(rawLabel);
  return capitalize(noUnderscore);
};

export const formatRequisitionStatusLabel = (rawLabel) => {
  if (rawLabel === 'RECEIVED_BY_GSA') return 'Received by GSA';

  const noUnderscore = replaceUnderscoresWithSpaces(rawLabel);
  return capitalize(noUnderscore);
};
