import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connectModal, Modal, Button } from '@gsa/afp-component-library';

export default function NotAvailableModal({ isOpen, closeModal }) {
  const ReadMoreModal = useMemo(
    () => () =>
      (
        <Modal
          id="not-available-modal"
          title={<h2>Currently not available</h2>}
          onClose={() => {
            closeModal();
          }}
          className="vehicle-not-available-modal"
          actions={
            <Button
              data-testid="close-not-available-modal"
              type="button"
              onClick={closeModal}
              label="Close"
            />
          }
        >
          <p data-testid="not-available-message-content">
            {' '}
            The item you have selected is currently not available. Please email
            our{' '}
            <a className="usa-link" href="mailto:vehicle.buying@gsa.gov">
              Customer Care
            </a>{' '}
            for assistance.
          </p>
        </Modal>
      ),
    [],
  );

  const ConnectedModal = connectModal(ReadMoreModal);

  return <ConnectedModal isOpen={isOpen} onClose={closeModal} />;
}

NotAvailableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
