import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import { rootSaga } from './sagas';

const createReduxStore = (client) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { client },
        },
      }).concat(sagaMiddleware),
  });
  sagaMiddleware.run(rootSaga, client);
  return store;
};

export default createReduxStore;
