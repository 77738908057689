import React from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const RequisitionMessageLinesForm = ({ messageLines }) => {
  const showFormField = (controlName, label) => {
    return (
      <Controller
        name={controlName}
        render={({ field: { value, onChange, ref } }) => (
          <TextInput
            characterLimit="35"
            type="textarea"
            label={label}
            onChange={onChange}
            defaultValue={messageLines[controlName] ?? null}
            value={value}
            inputRef={ref}
            name={`field_${controlName}`}
            inputClass="line-text-box"
          />
        )}
      />
    );
  };

  return (
    <>
      {showFormField('messageLine1', 'Line 1')}
      {showFormField('messageLine2', 'Line 2')}
      {showFormField('messageLine3', 'Line 3')}
      {showFormField('messageLine4', 'Line 4')}
      {showFormField('messageLine5', 'Line 5')}
    </>
  );
};

RequisitionMessageLinesForm.propTypes = {
  messageLines: PropTypes.shape({
    messageLine1: PropTypes.string,
    messageLine2: PropTypes.string,
    messageLine3: PropTypes.string,
    messageLine4: PropTypes.string,
    messageLine5: PropTypes.string,
  }),
};

RequisitionMessageLinesForm.defaultProps = {
  messageLines: {
    messageLine1: null,
    messageLine2: null,
    messageLine3: null,
    messageLine4: null,
    messageLine5: null,
  },
};

export default RequisitionMessageLinesForm;
