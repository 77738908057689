import './file-upload.scss';
import { useGetAttachmentTypes, useGetRequisitionAttachments, useGetRequisitionAttachmentsLazy } from '../../../../../requests/leasing';
import { useSelector } from 'react-redux';
import { useModal, Checkbox, Button, AFPTable, EmptyState, AFPTableRowAction } from '@gsa/afp-component-library';
import { useState } from 'react';
import OptionDetailFileUploadModal from './option-detail-file-upload';
import AttachmentDownload from '@/pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import AttachmentDeleteModal from '@/pages/ReviewDetails/Attachment/AttachmentDeleteModal/AttachmentDeleteModal';
import AttachmentUploadEditModal from '@/pages/ReviewDetails/Attachment/AttachmentEditModal/AttachmentEditModal';
import { setAttachments } from '../../../../../reducers/leasing';

function getFilesForOption(attachments, optionCode, foundAttachmentType) {
  let defaultTotalFiles = [];
  if (attachments.length > 0) {
    const filteredFiles = attachments
      .filter(
        (f) =>
          f.attachmentTypeId ===
          foundAttachmentType?.requisitionAttachmentTypeId &&
          f.equipmentCode === optionCode,
      )
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    defaultTotalFiles = filteredFiles
  }

  return defaultTotalFiles;
}

export default function FileUpload({ optionCode, page }) {
  const { attachments, attachmentTypes, requisitionId } = useSelector((state) => state.leasingReducer);
  const [selectedFile, setSelectedFile] = useState({});
  const [showDocs, setShowDocs] = useState();

  const uploadModal = useModal();
  const deleteFileModal = useModal();
  const updateFileModal = useModal();

  useGetAttachmentTypes();
  useGetRequisitionAttachments({ requisitionId }).then((attachments) => {
    if (getFilesForOption(attachments, optionCode, foundAttachmentType).length > 0) {
      setShowDocs(true);
    }
  })
  const [getRequisitionAttachments] = useGetRequisitionAttachmentsLazy({ requisitionId });

  const handleSelectedRow = (event, row) => {
    setSelectedFile({
      id: row.original.requisitionAttachmentId,
      description: row?.original?.description,
    });
    if (event === 'Delete file') {
      deleteFileModal.openModal();
    } else if (event === 'Edit') {
      updateFileModal.openModal();
    }
  };

  let foundAttachmentType = {};

  if (page === 'OPTION_DETAILS') {
    foundAttachmentType = attachmentTypes.find(
      (a) => a.attachmentType === 'req_purchaseline_equipment',
    );
  } else {
    foundAttachmentType = attachmentTypes.find(
      (a) => a.attachmentType === 'req_color_options',
    );
  }

  const refetchCall = () => {
    getRequisitionAttachments()
      .then(({ data, dispatch }) => {
        if (getFilesForOption(data, optionCode, foundAttachmentType).length > 0) {
          setShowDocs(true);
        }
        dispatch(setAttachments(data))
      });
  };

  const defaultTotalFiles = getFilesForOption(attachments, optionCode, foundAttachmentType);

  const toggleShowDocs = () => {
    setShowDocs(!showDocs);
  };

  const actionList = [
    {
      icon: 'edit',
      label: 'Edit',
    },
    {
      icon: 'delete',
      label: 'Delete file',
    },
  ];

  const columns = [
    {
      Header: 'File name',
      accessor: 'filename',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        return (
          <AttachmentDownload
            // eslint-disable-next-line react/prop-types
            name={original?.name}
            // eslint-disable-next-line react/prop-types
            metadataId={original?.metadataId}
          />
        );
      },
    },
    {
      Header: 'Document',
      accessor: 'document',
      sortable: false,
      cellClassName: 'attachment-note',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        return original?.attachmentType?.attachmentType;
      },
    },
    {
      Header: 'Note',
      accessor: 'note',
      sortable: false,
      cellClassName: 'attachment-note',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        return original?.description;
      },
    },
    {
      Header: 'Actions',
      id: 'table-row-action',
      sortable: false,
      headerClassName: 'cell-center',
      cellClassName: 'cell-center',
      // eslint-disable-next-line react/prop-types
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const { row } = props;
        return (
          <AFPTableRowAction
            actions={actionList}
            onSelectAction={(evt) => handleSelectedRow(evt, row)}
            {...props}
          />
        );
      },
    },
  ]

  return (
    <>
      <div>
        <Checkbox
          className="supporting-docs-checkbox"
          checked={showDocs}
          onChange={toggleShowDocs}
          label="Supporting documents"
        />

        {showDocs && (
          <>
            <div className="file-upload-section">
              <span>Supporting documentation</span>
              <Button
                data-testid="upload-file-button"
                variant="outline"
                onClick={() => {
                  uploadModal.openModal();
                }}
                leftIcon={{ name: 'add' }}
                label="Upload File"
              />
            </div>
            <div className="attachments-table-section">
              {defaultTotalFiles.length > 0 ? (
                <AFPTable
                  testId="additional-requirements-files-table"
                  columns={columns}
                  data={defaultTotalFiles}
                />
              ) : (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No attachments found for this requisition"
                />
              )}
            </div>
          </>
        )}
      </div>

      <OptionDetailFileUploadModal
        isOpen={uploadModal.isOpen}
        handleClose={uploadModal.closeModal}
        modalInfo={{
          selectedOption: optionCode
        }}
        download={() => { "" }}
        refetch={refetchCall}
        page={page}
      />

      <AttachmentDeleteModal
        isOpen={deleteFileModal.isOpen}
        handleClose={deleteFileModal.closeModal}
        fileToDelete={selectedFile}
        onCompleted={refetchCall}
      />

      <AttachmentUploadEditModal
        isOpen={updateFileModal.isOpen}
        handleClose={updateFileModal.closeModal}
        selectedAttachment={selectedFile}
        onCompleted={refetchCall}
      />
    </>
  );
};