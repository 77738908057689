import { useDispatch, useSelector } from 'react-redux';
import { setLeasingSteps, setCurrentStep } from '@/reducers/leasing';
import { useNavigate } from 'react-router-dom';
import { LeasingSteps } from '../pages/leasing/leasing-consts';
import { useLocation } from 'react-router-dom';
import { useCancelRequisition } from '../requests/leasing';
import { Navigate } from 'react-router-dom';
import { RequisitionViewMode } from '../pages/leasing/leasing-consts';

export default function useLeasingNavigation() {
    const dispatch = useDispatch();
    const {leasingSteps, requisitionId, viewMode} = useSelector((state) => state.leasingReducer);
    const navigate = useNavigate();
    const location = useLocation();
    const [cancelRequisition] = useCancelRequisition();

    function getUrlForStep(step) {
        if (requisitionId) {
            return `/lease/${requisitionId}/${step}`;
        } else {
            return `/lease/${step}`;
        }
    }

    function handleLeasingNavigation(gotoStep, overrideRequisitionId, force) {
        if (gotoStep === LeasingSteps.BEGIN) {
            return navigate(`/${gotoStep}`);
        }

        if (viewMode === RequisitionViewMode.REVIEW_EDIT && !force) {
            gotoStep = LeasingSteps.REVIEW_SUBMIT;
        }

        let gotoStepIndex = leasingSteps.findIndex(step => step.key === gotoStep);
        let updatedSteps = [...leasingSteps].map((step, index) => {
            if (index < gotoStepIndex) {
                return { ...step, status: 'completed' };
            }
            if (index === gotoStepIndex) {
                return { ...step, status: 'current' };
            }
            return { ...step, status: 'not completed' };
        });

        let currentStep = updatedSteps.find(step => step.status === 'current');

        dispatch(setLeasingSteps(updatedSteps));
        dispatch(setCurrentStep(currentStep));

        if (requisitionId || overrideRequisitionId) {
            navigate(`/lease/${overrideRequisitionId || requisitionId}/${gotoStep}`);
        } else {
            navigate(`/lease/${gotoStep}`);
        }
    }

    function handleReturnLeasingNavigation(gotoStep) {
        let gotoStepIndex = leasingSteps.findIndex(step => step.key === gotoStep);
        let updatedSteps = [...leasingSteps].map((step, index) => {
            if (index < gotoStepIndex) {
                return { ...step, status: 'completed' };
            }
            if (index === gotoStepIndex) {
                return { ...step, status: 'current' };
            }
            return { ...step, status: 'not completed' };
        });

        let currentStep = updatedSteps.find(step => step.status === 'current');

        dispatch(setLeasingSteps(updatedSteps));
        dispatch(setCurrentStep(currentStep));

        if (requisitionId) {
            return <Navigate to={`/lease/${requisitionId}/${gotoStep}`} />;
        } else {
            return <Navigate to={`/lease/${gotoStep}`} />
        }
    }

    function addRequisitionIdToUrl(requisitionId) {
        let pathParts = location.pathname.split('/')
        pathParts.splice(2, 0, requisitionId);
        pathParts = pathParts.join('/');
        navigate(pathParts);
    }

    function removeRequisitionIdFromUrl() {
        let pathParts = location.pathname.split('/');
        pathParts.splice(2, 1);
        pathParts = pathParts.join('/');
        navigate(pathParts);
    }

    async function handleCancel() {
        if (requisitionId) {
            await cancelRequisition({ 
                variables: { requisitionId}
            })
        }

        handleLeasingNavigation(LeasingSteps.BEGIN);
    }

    return {
        handleLeasingNavigation,
        handleReturnLeasingNavigation,
        handleCancel,
        addRequisitionIdToUrl,
        removeRequisitionIdFromUrl,
        getUrlForStep
    }
}