import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@gsa/afp-component-library';
import useBulkOrderModsState from './useBulkOrderModsState';

const DisabledOrderStatus = 'CANCELLED';
const DisabledOrderModStatus = ['ATTACH_TO_MOD', 'DRAFT_MOD'];

const checkDisable = (orderMod, selectedOrdersFromChangeSet = []) => {
  return (
    DisabledOrderModStatus.some(
      (status) =>
        status === orderMod?.modificationStatus &&
        !selectedOrdersFromChangeSet?.includes(orderMod?.orderId),
    ) || orderMod.status === DisabledOrderStatus
  );
};

const OrderModPropType = {
  orderId: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  modificationStatus: PropTypes.string,
  status: PropTypes.string,
  quantity: PropTypes.number,
  unitPriceToCustomer: PropTypes.number,
};

function SelectOrderMods({ orderMod }) {
  const {
    selectedOrdersForMod,
    setSelectedOrdersForMod,
    currentChangeSet,
    updateOrderModChangeSetOrders,
  } = useBulkOrderModsState();

  const [selectedOrdersFromChangeSet] = useState(() =>
    currentChangeSet?.changeSetOrders?.map((order) => order?.orderId),
  );

  const [checked, setChecked] = useState(
    !!selectedOrdersForMod?.find(
      (order) => order.orderId === orderMod.orderId,
    ) || false,
  );

  const disabled = checkDisable(orderMod, selectedOrdersFromChangeSet);

  useEffect(() => {
    if (orderMod.orderId) {
      setChecked(
        !!selectedOrdersForMod?.find(
          (order) => order.orderId === orderMod.orderId,
        ) || false,
      );
    }
  }, [orderMod]);

  useEffect(() => {
    if (
      selectedOrdersForMod?.find((order) => order.orderId === orderMod.orderId)
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedOrdersForMod]);

  const updateSelectedOrders = (value) => {
    let orders = [];
    if (value) {
      orders = [...selectedOrdersForMod, orderMod];
    } else {
      orders = selectedOrdersForMod.filter(
        (order) => order.orderId !== orderMod.orderId,
      );
    }

    const orderIds = orders.map((order) => order.orderId);
    setSelectedOrdersForMod(orders);
    if (currentChangeSet?.orderChangeSetModId) {
      updateOrderModChangeSetOrders(orderIds);
    }
    setChecked(value);
  };
  return disabled ? null : (
    <Checkbox
      className="select-orders-checkbox"
      name="test"
      checked={checked}
      aria-label="checkbox"
      onChange={(e) => updateSelectedOrders(e.target.checked)}
    />
  );
}

SelectOrderMods.propTypes = {
  orderMod: PropTypes.shape(OrderModPropType).isRequired,
};

export default SelectOrderMods;
