import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Pagination } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_REQUISITION_ACTIVITIES,
  CREATE_REQUISITION_COMMENT,
} from '../../../services/data-layer';
import CommentModal from '../../../components/CommentModal/CommentModal';
import CommentActivityCard from '../../comments-activity/CommentActivityCard';
import './RequisitionActivityTab.scss';
import { resetFocusToFirstElement } from '../../../utilities/commons';

const RequisitionActivitiesTab = ({
  requisitionId,
  existingRequisitionActivities,
}) => {
  useTitle('Review and Submit - Activity');

  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [requisitionActivities, setRequisitionActivities] = useState([]);
  const activitiesCnt =
    requisitionActivities.length || existingRequisitionActivities.length;

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [resetPagination, setResetPagination] = useState(false);

  const [getRequisitionActivitiesData, { data: requisitionActivitiesData }] =
    useLazyQuery(GET_REQUISITION_ACTIVITIES, {
      fetchPolicy: 'no-cache',
    });

  const [submitRequisitionMutation] = useMutation(CREATE_REQUISITION_COMMENT, {
    onCompleted: () => {
      setCurrentPage(1);

      getRequisitionActivitiesData({
        variables: {
          requisitionId,
          offset: 0,
          limit: perPage,
          order: [['createdAt', 'DESC']],
        },
      });
    },
  });

  useEffect(() => {
    if (requisitionId) {
      getRequisitionActivitiesData({
        variables: {
          requisitionId,
          offset: (currentPage - 1) * perPage,
          limit: perPage,
          order: [['createdAt', 'DESC']],
        },
      });
    }
  }, [requisitionId, currentPage, perPage]);

  const handlePaginationChange = (newCurPage, newPerPage) => {
    resetFocusToFirstElement('activity-panel-dot');
    if (newPerPage !== perPage) {
      setCurrentPage(1);
      setPerPage(newPerPage);
      setResetPagination(true);
    } else {
      setCurrentPage(newCurPage);
      setPerPage(newPerPage);
    }
  };

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivities) {
      const { rows } = requisitionActivitiesData.getRequisitionActivities;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    const { appURLs } = window.AFP_CONFIG;
    submitRequisitionMutation({
      variables: {
        requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  const activities =
    requisitionActivities && requisitionActivities.length > 0
      ? requisitionActivities
      : existingRequisitionActivities;

  return (
    <div className="requisition-activities-container">
      <div className="display-flex flex-align-end">
        {requisitionId && (
          <Button
            type="button"
            variant="outline"
            data-testid="add-comment-button"
            className="margin-left-auto"
            onClick={() => {
              setShowAddCommentModal(true);
            }}
            leftIcon={{ name: 'edit' }}
            label="Add comment"
          />
        )}
      </div>
      <CommentModal
        data-testid="add-comment-modal"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={handleCancelComment}
      />

      <div className="contain_activity-trackr">
        {activities &&
          activities.map((requisitionActivity, index) => (
            <CommentActivityCard
              key={requisitionActivity?.id || index}
              activity={requisitionActivity}
              requisitionType="draft"
            />
          ))}
      </div>
      <Pagination
        buttonVariant="primary"
        boundaryCount={1}
        variant="advanced"
        className="activities-pagination"
        itemsCount={activitiesCnt}
        currentPage={currentPage}
        itemsPerPage={perPage}
        siblingCount={1}
        isReset={resetPagination}
        onPageChange={handlePaginationChange}
        defaultSort="createdDateTime DESC"
      />
    </div>
  );
};

RequisitionActivitiesTab.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  existingRequisitionActivities: PropTypes.instanceOf(Array),
};

RequisitionActivitiesTab.defaultProps = {
  existingRequisitionActivities: [],
};

export default RequisitionActivitiesTab;
