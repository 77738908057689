import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { RequisitionStatusType } from '../pages/my-requisitions/tableHelpers';

const RequisitionStatusBadge = ({ value }) => {
  if (!value) return null;

  const { color, label } = RequisitionStatusType[value] || {
    color: 'Warning-Gray',
    label: `INVALID STATUS (${value})`,
  };

  return (
    <StatusBadge data-testid="req-status-tag" variant={color}>
      {label}
    </StatusBadge>
  );
};

RequisitionStatusBadge.propTypes = {
  value: PropTypes.string.isRequired,
};

export default RequisitionStatusBadge;
