import React from 'react';
import PropTypes from 'prop-types';
import EngineerActionButtons from './EngineerActionButtons';
import SubmitterActionButtons from './SubmitterActionButtons';
import ApproverActionButtons from './ApproverActionButtons';
import CoActionButtons from './CoActionButtons';
import SubmitterRoundTwoActionButtons from '../../components/SubmitterRoundTwoActionButtons/SubmitterRoundTwoActionButtons';
import useRequisitionReview from '../../utilities/useRequisitionReview';
import { OrderingAdminActionButtons } from './OrderingAdminActionButtons/OrderingAdminActionButtons';
import OrderAdminApprovalActionButtons from '../../components/OrderAdminApprovalActionButtons/OrderAdminApprovalActionButtons';

const ActionButtons = ({
  requisitionId,
  requisitionCartState,
  requisitionCartValidations,
  canSubmitOrder,
  reference,
  currentStandardItem,
}) => {
  const { isVendorDirect, vendorDirectLoaded } = useRequisitionReview();
  return (
    <>
      {vendorDirectLoaded && (
        <>
          <SubmitterActionButtons
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            isVendorDirect={isVendorDirect}
          />
          <ApproverActionButtons
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            requisitionCartValidations={requisitionCartValidations}
            canSubmitOrder={canSubmitOrder}
            currentStandardItem={currentStandardItem}
            isVendorDirect={isVendorDirect}
          />
          <OrderingAdminActionButtons
            reference={reference}
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            requisitionCartValidations={requisitionCartValidations}
            canSubmitOrder={canSubmitOrder}
          />
          <EngineerActionButtons
            reference={reference}
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            requisitionCartValidations={requisitionCartValidations}
            canSubmitOrder={canSubmitOrder}
          />
          <CoActionButtons
            reference={reference}
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            canSubmitOrder={canSubmitOrder}
            isVendorDirect={isVendorDirect}
            currentStandardItem={currentStandardItem}
          />

          <SubmitterRoundTwoActionButtons
            reference={reference}
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            requisitionCartValidations={requisitionCartValidations}
            canSubmitOrder={canSubmitOrder}
          />
          <OrderAdminApprovalActionButtons
            reference={reference}
            requisitionId={requisitionId}
            requisitionCartState={requisitionCartState}
            requisitionCartValidations={requisitionCartValidations}
            canSubmitOrder={canSubmitOrder}
          />
        </>
      )}
    </>
  );
};

ActionButtons.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
  currentStandardItem: PropTypes.instanceOf(Object),
};

ActionButtons.defaultProps = {
  currentStandardItem: undefined,
};

export default ActionButtons;
