import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetDealershipsByCityStateMake,
  useGetDealershipStates,
  useGetCountryCodeOptions,
  useGetFleetManagementCenters,
  useGetStatesOptions,
  useGetCitybyStateId,
  useGetStateList
} from '@/requests/leasing';
import { GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE } from '../../../../../services/data-layer';
import { useQuery } from '@apollo/client';
import { setDealershipDeliveryAddress } from '@/reducers/leasing';
import TelephoneControl from '../../../../../components/FormComponents/telephone-input';
import useLeasingNavigation from '@/hooks/useLeasingNavigation';
import LeasingNavigation from '../../../components/navigation';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { LeasingSteps } from '@/pages/leasing/leasing-consts.js';
import dealershipDeliverySchema from './dealership-delivery-schema';
import { generateDealershipDeliveryForm } from './dealership-delivery-form';
import useUpdateDraft from '../../../components/update-draft/update-leasing-draft';
import { Spinner } from '@gsa/afp-component-library';

import './DealershipDelivery.scss';

const transformCityValues = (data) => {
  if (data) {
    const cities = data?.reduce((uniqueCities, dealer) => {
      if (!uniqueCities.has(dealer.city)) {
        uniqueCities.add(dealer.city);
      }
      return uniqueCities;
    }, new Set());
    const citiesData = Array.from(cities).map((city) => ({
      label: city,
      value: city,
    }));
    citiesData.unshift({
      label: 'Select City',
      value: '-1',
      defaultValue: true,
    });
    return citiesData;
  }
  else {
    return [{
      label: 'Select City',
      value: '-1',
      defaultValue: true,
    }]
  }

}
const DealershipDelivery = () => {
  const [updateDraft] = useUpdateDraft();
  const formGeneratorRef = useRef();
  const { handleLeasingNavigation } = useLeasingNavigation();

  const {
    shipmentLocation,
    shipmentCountry,
    dealershipDeliveryAddress,
    compareSelect: { selectedContract, selectedDeliveryOption },
  } = useSelector((state) => state.leasingReducer);
  const dispatch = useDispatch();
  const [getDealershipsByCityStateMake] = useGetDealershipsByCityStateMake();
  const { data: countryCodeOptions, loading: countryCodesLoading } = useGetCountryCodeOptions();
  const { data: fleetManagementCenters, loading: fmcLoading } = useGetFleetManagementCenters();
  const { data: stateById, loading: stateListLoading } = useGetDealershipStates(shipmentLocation);
  const { data: defaultStates, stateLoading } = useGetStatesOptions();
  const [getCitybyStateId] = useGetCitybyStateId();
  const [getStateList] = useGetStateList()

  const { data: optionCityData, loading: optionCityDataLoading } = useQuery(GET_DELIVERY_DEALERSHIPS_BY_MAKE_STATE, {
    skip: !stateById?.stateId,
    variables: {
      stateId: parseInt(stateById?.stateId, 10),
      make: parseInt(selectedContract?.makeCode, 10),
      acceptsEv: undefined,
    },
  });
  const citydropdown = transformCityValues(optionCityData?.getDeliveryDealerships)

  const [content, defaultValues] = generateDealershipDeliveryForm({
    countryCodes: countryCodeOptions,
    deliveryAddress: dealershipDeliveryAddress?.deliveryAddress,
    licenseDeliveryAddress: dealershipDeliveryAddress?.licenseDeliveryAddress,
    shipmentLocation,
    deliveryOption: selectedDeliveryOption,
    standardItemSubCategory: selectedContract?.standardItemSubCategory,
    handleLeasingNavigation,
    optionCityData: citydropdown,
    stateId: stateById?.stateId,
    defaultStates,
    selectedContract,
    getDealershipsByCityStateMake,
    fleetManagementCenters: fleetManagementCenters || [],
    formGeneratorRef,
    selectedCountry: shipmentCountry || 'US',
    getCitybyStateId,
    getStateList
  });


  const handlePreviousNavigation = () => {
    handleLeasingNavigation(LeasingSteps.REVIEW_VEHICLE_BUILD);
  };

  const handleSubmit = async (data) => {
    const kansasCityMailingAddress = {
        title: 'GSA Fleet Zonal Vital Records Center',
        address1: '2300 Main Street',
        address2: 'Suite 7NE',
        city: 'Kansas City',
        state: 'MO',
        zip: '64108',
        country: 'US'
    }

    const euMailingAddress = {
        title: 'GSA Fleet IFMS',
        address1: 'McCully Barracks, Bldg 6295',
        address2: 'Ober-Olmer Str',
        city: 'Wackernheim',
        state: '',
        zip: '55362',
        country: 'DE'
    }

    const italyMailingAddress = {
        title: 'EURO-IFMS',
        address1: 'US Army Depot Camp Darby (Gate 27)',
        address2: 'Via Aurelia (Bldg 5130)',
        city: 'Localita’ Tombolo-Coltano',
        state: '',
        zip: '56010',
        country: 'IT'
    }

    let mailingAddress = kansasCityMailingAddress;
    if (shipmentCountry === 'IT') {
      mailingAddress = italyMailingAddress;
    }
    if (shipmentCountry === 'DE') {
      mailingAddress = euMailingAddress;
    }

    const input = {
      clientData: {
          clientState: {
            dealershipDeliveryAddress: {
                  deliveryAddress: data,
                  licenseDeliveryAddress: {
                    fmcLocation: data.fmcLocation,
                    fmcOfficeLocationDetails: data.fmcOfficeLocationDetails,
                    licenseDeliveryPoC: data.licenseDeliveryPoC
                  },
                  mailingAddress,
              }
          }
      },
  }
    updateDraft(input, LeasingSteps.REVIEW_SUBMIT).then((data) => {
      if (data) {
          dispatch(setDealershipDeliveryAddress(input.clientData.clientState.dealershipDeliveryAddress));
          handleLeasingNavigation(LeasingSteps.REVIEW_SUBMIT);
      }
  });
  };

  if (countryCodesLoading || stateListLoading || optionCityDataLoading || fmcLoading || stateLoading) {
    return <Spinner size="large" />;
  }

  return (
    <div className="delivery-deliverySection">
      <div tabIndex="0" role="tab" className="review-vehicle-build-title">
        <h2 className="usa-h2">Provide delivery address</h2>
        <p>Please provide information for the following addresses. </p>
      </div>
      <FormGenerator
        id="dealership-delivery-address-form"
        ref={formGeneratorRef}
        schema={dealershipDeliverySchema}
        content={content}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        onError={console.log}
        controls={{
          phone: TelephoneControl,
        }}
      />

      <LeasingNavigation
        onPrevious={handlePreviousNavigation}
        hide={['cancel', 'submit', 'reject']}
        nextFormId="dealership-delivery-address-form"
      />
    </div>
  );
};

export default DealershipDelivery;
