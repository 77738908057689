import generatePointOfContactForm from '../DeliveryAddress/address-component/point-of-contact-form';

export function generateAgencyContactForm({ agencyContact, countryCodes }) {
  const defaultValues = {
    firstName: agencyContact?.firstName || '',
    lastName: agencyContact?.lastName || '',
    email: agencyContact?.email || '',
    phone: {
      countryCode: agencyContact?.phone?.countryCode || '',
      number: agencyContact?.phone?.number || '',
      extension: agencyContact?.phone?.extension || '',
    },
    fax: {
      countryCode: agencyContact?.fax?.countryCode || '',
      number: agencyContact?.fax?.number || '',
      extension: agencyContact?.fax?.extension || '',
    },
  };

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      generatePointOfContactForm({
        title: 'Agency point of contact',
        subsection: false,
        countryCodes,
        ignoreIdPrefix: true,
      }),
    ],
  };

  return [content, defaultValues];
}
