import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RequisitionStatus } from '../../RequisitionDetailsUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

const MultipleVendorInstructions = ({ status, type }) => {
  const { selectedVendorQuote } = useContext(VehicleRequisitionContext);

  let content = {
    title: 'Provide vendor responses and quotes',
    description: '',
  };

  if (status === RequisitionStatus.CONTRACTING_APPROVAL) {
    content = {
      title:
        'Review vendor bids and engineer evaluation, then submit to ordering Admin',
      description:
        'You may also return to the previous step to make further edits',
    };
  } else if (
    status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE &&
    Boolean(selectedVendorQuote)
  ) {
    content = {
      title: 'Review and confirm funding',
      description: `Review this ${type} and ensure the information is correct. Below, 
      attach relevant funding documentation demosntrating funding has been obligated to fulfill
      this ${type}. `,
    };
  } else if (status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE) {
    content = {
      title: 'Select vendor to complete requisition',
      description: `Please select the vendor you wish to fulfill the vehicle need.  
      If it is the non-low bid, you will be prompted to enter a non-low bid justification.`,
    };
  } else if (status === RequisitionStatus.FINAL_APPROVAL) {
    if (type === 'AREQ') {
      content = {
        title: 'Review and confirm funding',
        description: `Review this ${type} and ensure the information is correct.`,
      };
    } else {
      content = {
        title: 'Review and confirm funding',
        description: `Review this ${type} and ensure the information is correct. 
        Below, attach relevant funding documentation demonstrating funding has been obligated to fulfill this ${type}.`,
      };
    }
  } else if (
    status === RequisitionStatus.ORDERING_ADMIN_APPROVAL &&
    Boolean(selectedVendorQuote)
  ) {
    content = {
      title: 'Review and confirm funding',
      description: `Review this ${type} and ensure the information is correct. Below, 
      attach relevant funding documentation demosntrating funding has been obligated to fulfill
      this ${type}. `,
    };
  } else if (status === RequisitionStatus.ORDERING_ADMIN_APPROVAL) {
    content = {
      title: 'Select vendor to complete requisition',
      description: `Please select the vendor you wish to fulfill the vehicle need.  
      If it is the non-low bid, you will be prompted to enter a non-low bid justification.`,
    };
  }

  const { title, description } = content;

  return (
    <>
      <div className="actions-section-title">CURRENT STEP ACTIONS</div>
      <section className="vendor-quote-section-title">
        <div className="quote-title">{title}</div>
        <div className="title-desc">{description}</div>
      </section>
    </>
  );
};

MultipleVendorInstructions.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MultipleVendorInstructions;
