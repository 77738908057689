import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button, Alert } from '@gsa/afp-component-library';

export default function EngineeringReviewModal({
  handleClick,
  onClose,
  cancel,
}) {
  const EngineeringReviewActions = (
    <>
      <Button
        type="button"
        variant="unstyled"
        label="Cancel"
        onClick={cancel}
      />
      <Button
        type="button"
        variant="primary"
        label="Yes"
        onClick={handleClick}
      />
    </>
  );
  return (
    <div
      role="dialog"
      aria-modal="true"
      className="margin-y-9 display-flex margin-x-auto"
    >
      <Modal
        variant="large"
        actions={EngineeringReviewActions}
        onClose={onClose}
        showAlert
        alert={
          <Alert type="info" slim>
            You have selected standard item that will require engineering review
            once this requisition is submitted to GSA.
          </Alert>
        }
      >
        <p>Do you still wish to proceed with your selection?</p>
      </Modal>
    </div>
  );
}

EngineeringReviewModal.propTypes = {
  handleClick: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  cancel: propTypes.func.isRequired,
};
