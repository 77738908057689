import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from '../RequisitionDetailsUtils';
import SubmitToEngineerButton from '../SubmitToEngineerButton/SubmitToEngineerButton';
import CancelRequisitionButton from '../CancelRequisitionButton/CancelRequisitionButton';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './OrderingAdminActionButtons.scss';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';
import { UserRoles } from '../../../constants/user-constants';

const ReturnToEditButton = ({ dispatch }) => {
  return (
    <Button
      data-testid="return-to-edit-button"
      type="button"
      variant="outline"
      onClick={() => {
        dispatch({
          type: VehicleRequisitionContextActions.SET_IS_LEASING_ADDITIONAL_REQUIREMENTS_PREVIEW,
          payload: false,
        });
      }}
      label="Return to edit"
      leftIcon={{
        name: 'arrow_back',
      }}
    />
  );
};

ReturnToEditButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const NextButton = ({ reference }) => {
  return (
    <>
      <Button
        data-testid="review-and-submit-to-engineer-button"
        type="button"
        onClick={() => reference.current.handleReview()}
        label="Next"
        leftIcon={{
          name: 'arrow_forward',
        }}
      />
    </>
  );
};

NextButton.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
};

export const OrderingAdminActionButtons = ({
  reference,
  requisitionId,
  requisitionCartState,
}) => {
  const {
    dispatch,
    state: { isLeasingAdditionalRequirementsPreview },
  } = useContext(VehicleRequisitionContext);
  const [errorList] = useState([]);

  const { requisitionStatus } = requisitionCartState;

  const ability = useAppAbility();
  const isAdmin = useMemo(
    () => ability?.can(StoreOperations.Manage, StoreSubjects.All),
    [ability],
  );
  const { currentUser } = useCurrentUser();
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );

  const canSubmitToEngineer = () => {
    if (!isOrderingAdmin && !isAdmin) {
      return false;
    }

    if (errorList.length > 0) {
      return false;
    }

    switch (requisitionStatus) {
      case RequisitionStatus.ORDERING_ADMIN_REVIEW:
        return true;
      default:
        return false;
    }
  };

  if (!canSubmitToEngineer()) {
    return null;
  }

  return (
    <div id="ordering-admin-action-buttons">
      {isLeasingAdditionalRequirementsPreview ? (
        <>
          <ReturnToEditButton dispatch={dispatch} />
          <CancelRequisitionButton requisitionId={requisitionId} forceDisplay />
          <SubmitToEngineerButton
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
          />
        </>
      ) : (
        <NextButton reference={reference} />
      )}
    </div>
  );
};

OrderingAdminActionButtons.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
};
