import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MenuDialog } from '@gsa/afp-component-library';
import classnames from 'classnames';

const reqActions = [
  {
    icon: 'print',
    label: 'Print summary',
    action: 'print summary',
  },
  {
    icon: 'check',
    label: 'Place order',
    action: 'place order',
  },
  {
    icon: 'edit',
    label: 'Rename',
    action: 'rename',
  },
  {
    icon: 'edit',
    label: 'Post comment',
    action: 'postComment',
  },
  {
    icon: 'undo',
    label: 'Return to submitter',
    action: 'returnToSubmitter',
  },
  {
    icon: 'cancel',
    label: 'Cancel requisition',
    action: 'cancel',
  },
  {
    icon: 'content_copy',
    label: 'Clone requisition',
    action: 'clone',
  },
  {
    icon: 'undo',
    label: 'Reopen',
    action: 'reopen',
  },
  {
    icon: 'check',
    label: 'Submit to approver',
    action: 'submitToApprover',
  },
];

const RequisitionActions = ({
  show,
  toggleMenu,
  printSummary,
  menuList,
  submitToApprover,
  postComment,
  returnToSubmitter,
}) => {
  const menuDialogClasses = classnames([
    'position-absolute',
    'padding-0',
    'translate-left',
  ]);

  return (
    <div data-testid="requisition-action-test-id">
      <MenuDialog
        className={menuDialogClasses}
        show={show}
        actionIconSize="usa-icon--size-3"
        state="hover"
        items={reqActions.filter(
          (action) => menuList.indexOf(action.label) > -1,
        )}
        onClick={(action) => {
          switch (action) {
            case 'print summary':
              printSummary();
              break;
            case 'rename':
              break;
            case 'reopen':
              break;
            case 'clone':
              break;
            case 'cancel':
              break;
            case 'postComment':
              postComment();
              break;
            case 'returnToSubmitter':
              returnToSubmitter();
              break;
            case 'submitToApprover':
              submitToApprover();
              break;

            default:
              break;
          }
        }}
        iconColor="text-gray-50"
        onClose={(e) => {
          toggleMenu(show, e);
        }}
      />
    </div>
  );
};

RequisitionActions.defaultProps = {
  show: false,
  toggleMenu: () => false,
  printSummary: () => {},
  menuList: [],
  submitToApprover: () => {},
  postComment: () => {},
  returnToSubmitter: () => {},
};

RequisitionActions.propTypes = {
  show: PropTypes.bool,
  toggleMenu: PropTypes.func,
  printSummary: PropTypes.func,
  menuList: PropTypes.arrayOf(PropTypes.string),
  submitToApprover: PropTypes.func,
  postComment: PropTypes.func,
  returnToSubmitter: PropTypes.func,
};

export default memo(RequisitionActions);
