import z from 'zod';
import { pointOfContactShema } from './point-of-contact-schema';

const addressComponentSchema = z
  .object({
    isMilitary: z.boolean(),
    militaryAddress: z.object({
      rankAndFullName: z.string(),
      militaryOrDiplomaticAddress: z.string(),
      postOffice: z.string(),
    }),
    address: z.object({
      addressLine1: z.string(),
      addressLine2: z.string(),
      city: z.string(),
      stateCode: z.string(),
      countryCode: z.string(),
      entityName: z.string(),
    }),
    zipcode: z
      .string()
      .min(1, {
        message: 'Please fill out the zipcode',
      })
      .regex(/^[0-9]+$/, {
        message: 'The zipcode must be numeric',
      }),
    contact: pointOfContactShema,
  })
  .superRefine(({ address, militaryAddress, isMilitary }, ctx) => {
    if (isMilitary) {
      if (!militaryAddress.rankAndFullName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the rank and full name',
          path: ['militaryAddress', 'rankAndFullName'],
          fatal: true,
        });
      }
      if (!militaryAddress.militaryOrDiplomaticAddress) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the military or diplomatic address',
          path: ['militaryAddress', 'militaryOrDiplomaticAddress'],
          fatal: true,
        });
      }
      if (!militaryAddress.postOffice || militaryAddress.postOffice === '-1') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the post office',
          path: ['militaryAddress', 'postOffice'],
          fatal: true,
        });
      }
    } else {
      if (!address.addressLine1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the address line 1',
          path: ['address', 'addressLine1'],
          fatal: true,
        });
      }
      if (!address.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the city',
          path: ['address', 'city'],
          fatal: true,
        });
      }
      if (!address.stateCode || address.stateCode === '-1') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the state',
          path: ['address', 'stateCode'],
          fatal: true,
        });
      }
      if (!address.countryCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the country',
          path: ['address', 'countryCode'],
          fatal: true,
        });
      }
    }
  });

export default addressComponentSchema;
