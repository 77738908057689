import { useContext } from 'react';
import { OrderGuideECContext } from './OrderGuideECProvider';

export default function useOrderGuideECState() {
  const context = useContext(OrderGuideECContext);
  if (!context) {
    throw new Error(
      'useMyOrdersECState can only be used within OrderGuideProvider',
    );
  }
  return context;
}
