import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const OrderGuideContext = createContext({});

export default function OrderGuideProvider({ children }) {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [orderBy, setOrderBy] = useState('standardItemNumber');
  const [orderDirection, setOrderDirection] = useState('ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 5,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });

  const value = {
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    paginationState,
    setPaginationState,
  };

  return (
    <OrderGuideContext.Provider value={value}>
      {children}
    </OrderGuideContext.Provider>
  );
}

OrderGuideProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
