import React, { useMemo } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import useOrderModsState from './useOrderModsState';
import { resetFocusToFirstElement } from '../../utilities/commons';
import {
  getTableColumns,
  ORDER_MOD_TABLE_COLUMNS,
} from './OrderModTableHelpers';

const OrderModsTable = () => {
  const {
    onOrderModsLoading,
    orderModRows,
    orderModCount,
    orderBy,
    orderModDirection,
    setOrderModDirection,
    setOrderBy,
    paginationState,
    setPaginationState,
  } = useOrderModsState();

  const handlePaginationChange = (newCurPage, newPerPage) => {
    const offset = (newCurPage - 1) * newPerPage;

    setPaginationState({
      limit: newPerPage,
      offset,
      currentPage: newCurPage,
    });

    resetFocusToFirstElement();
  };

  const data = useMemo(() => {
    if (!orderModRows || !orderModRows.length) {
      return [];
    }
    return orderModRows.map((row) => getTableColumns(row));
  }, [orderModRows]);

  const columns = useMemo(() => ORDER_MOD_TABLE_COLUMNS, []);
  return (
    <>
      <div className="table-main-container">
        {columns.length > 0 && (
          <AFPTable
            testId="order-mods-table"
            columns={columns}
            hiddenColumns={['id']}
            data={onOrderModsLoading ? [] : data}
            onSort={(sortOrder) => {
              const [newOrderBy, newOrderModDirection] = sortOrder.split(' ');
              setOrderBy(newOrderBy.replace(/`/g, ''));
              setOrderModDirection(newOrderModDirection);
            }}
          />
        )}

        {onOrderModsLoading ? (
          <Spinner className="margin-y-9" />
        ) : (
          <>
            <Pagination
              buttonVariant="primary"
              boundaryCount={1}
              variant="advanced"
              itemsCount={orderModCount}
              currentPage={paginationState.currentPage}
              itemsPerPage={paginationState.limit}
              siblingCount={1}
              isReset={paginationState.isReset}
              onPageChange={handlePaginationChange}
              defaultSort={`${orderBy}, ${orderModDirection}`}
            />
            {!orderModRows?.length && (
              <EmptyState
                data-testid="table-no-items"
                containerStyles="table-no-items"
                topText="No Order Mods Available"
                topTextStyles="title"
                bottomText="There are no matches for the filtered values at left."
                bottomTextStyles="description"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OrderModsTable;
