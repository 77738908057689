import React from 'react';
import propTypes from 'prop-types';
import { FileUpload } from '@gsa/afp-component-library';
import {
  ACCEPT_FILE_SHEETS_TYPES,
  ACCEPT_FILE_SHEETS_LABELS,
} from '../../pages/non-standard-purchase/constants';

function VendorFileUpload({
  fileState,
  currentDownloadStrategyState,
  setFileState,
  setFileImportErrorState,
  fileImportErrorState,
}) {
  return (
    <FileUpload
      required
      label="Upload the updated status report file"
      defaultValue={fileState}
      acceptableFiles={ACCEPT_FILE_SHEETS_TYPES}
      acceptableFilesLabel={ACCEPT_FILE_SHEETS_LABELS}
      fileSizeLimit={currentDownloadStrategyState?.maxFileSize} // MB
      onChange={(file) => {
        setFileState(file);
        setFileImportErrorState(!file && 'This is a required field');
      }}
      errorMessage={fileImportErrorState}
    />
  );
}

VendorFileUpload.defaultProps = {
  fileState: null,
  currentDownloadStrategyState: {},
  setFileState: () => {},
  setFileImportErrorState: () => {},
  fileImportErrorState: {},
};

VendorFileUpload.propTypes = {
  fileState: propTypes.instanceOf(Object),
  currentDownloadStrategyState: propTypes.instanceOf(Object),
  setFileState: propTypes.func,
  setFileImportErrorState: propTypes.func,
  fileImportErrorState: propTypes.instanceOf(Object),
};

export default VendorFileUpload;
