import ViewImageSvg from '@/assets/images/view-image.svg';
import './vehicle-image-preview.scss';
import { useModal } from '@gsa/afp-component-library';
import VehicleImagePreviewModal from './vehicle-image-preview-modal';

export default function VehicleImagePreview({
    contract,
    allImages,
    heroicImage,
}) {
    const { isOpen, openModal, closeModal } = useModal();

    if (!allImages || allImages.length === 0) {
        return null;
    }

    return (
        <>
            <div className='vehicle-image-preview'>
                <button
                    data-testid="image-hover-click-id"
                    className="vehicle-image-preview-button"
                    onClick={() => {
                        openModal();
                    }}
                    role="button"
                    tabIndex="0"
                >
                    <img
                        data-testid="sampleVehicle-vehicle-card-image-alt"
                        className="vehicle-image-preview-icon"
                        src={ViewImageSvg}
                        alt="ViewImageSvg"
                    />
                    <span className="preview-image-label">View Photos</span>
                </button>
            </div>

            <VehicleImagePreviewModal
                isOpen={isOpen}
                closeModal={closeModal}
                contract={contract}
                allImages={allImages}
            />
        </>
    )
}