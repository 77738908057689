import React, { useContext } from 'react';
import { Alert } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';

export const SaveDraftRequistionSuccessMessage = () => {
  const { state } = useContext(VehicleRequisitionContext);
  const { draftRequisition } = state;
  return (
    <>
      <Alert type="success" slim>
        <span data-testid="save-draft-success-message">
          You have successfully saved your draft:{' '}
          {draftRequisition?.friendlyName}. This draft will be available for 45
          days.
        </span>
      </Alert>
    </>
  );
};

export default SaveDraftRequistionSuccessMessage;
