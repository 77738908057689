/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import './DeleteRequirementModal.scss';
import { useWatch } from 'react-hook-form';

export default function DeleteRequirementModal({
  handleClose,
  handleDelete,
  itemToDelete,
  control,
}) {
  const additionalRequirements = useWatch({
    control,
    name: 'additionalRequirements',
  });

  const { description } = additionalRequirements[itemToDelete];

  return (
    <Modal
      className="delete-req-modal"
      onClose={handleClose}
      actions={
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button
            type="button"
            variant="unstyled"
            onClick={handleClose}
            label="Cancel"
          />
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              handleDelete(itemToDelete);
              handleClose();
            }}
            label="Delete"
          />
        </div>
      }
    >
      <div className="delete-modal-title">
        Are you sure you want to delete this additional requirement?{' '}
      </div>

      <div className="delete-req-content-block">{description}</div>

      <div className="pls-note-block">
        <strong>PLEASE NOTE:</strong> Any associated additional documents you
        uploaded will also be deleted.
      </div>
    </Modal>
  );
}

DeleteRequirementModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  itemToDelete: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
};
