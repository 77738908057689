import React from 'react';
import withDraftRequisition from '../../hocs/withDraftRequistion/withDraftRequisition';
import VehicleRequisition from '../../pages/VehicleRequisition/VehicleRequisition';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';

const VehicleRequisitionContainer = () => {
  return withDraftRequisition(VehicleRequisition);
};

const VehicleRequisitionContainerWithContext = () => {
  return (
    <VehicleRequisitionContextProvider>
      <VehicleRequisitionContainer />
    </VehicleRequisitionContextProvider>
  );
};

export default VehicleRequisitionContainerWithContext;
