import ImageViewer from "../../../../../utilities/ImageViewer.jsx";
import {v4 as uuidv4} from "uuid";
import React from "react";

const { appURLs } = window.AFP_CONFIG;

const SelectTypeCard = ({ list, onVehicleTypeClick }) => {
    const rows = [];
    const rowItems = list.map((item) => {
        const imageURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${item.code}.svg`;

        return (
            <div
                key={item.id}
                data-testid={`on-vehicle-type-click-${item.code}`}
                className="sin-type-card"
                onClick={() => onVehicleTypeClick(item)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') onVehicleTypeClick(item);
                }}
                role="button"
                tabIndex="0"
            >
                <div className="details-container">
                    <div className="image-container">
                        <ImageViewer
                            imgUrl={imageURL}
                            defaultImgUrl={`${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`}
                        />
                    </div>
                    <div className="underline-section" />
                    <div className="image-label">{item.title}</div>
                </div>
            </div>
        );
    });

    for (let index = 0; index < rowItems.length; index += 5) {
        rows.push(
            <div className="sin-listing-row" key={uuidv4()}>
                {rowItems.slice(index, index + 5)}
            </div>,
        );
    }

    return <>{rows}</>;
};

export default SelectTypeCard;