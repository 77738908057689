import React from 'react';
import './AREQSidebar.scss';
import { useModal, connectModal, Button } from '@gsa/afp-component-library';
import { WarnIcon } from '../../../../assets/images/warn-icon';
import AreqModal from '../../../../components/Areq/AreqModal/AreqModal';

const AREQSidebar = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const DisplayModal = connectModal(AreqModal);

  const openAreqModal = () => {
    openModal();
  };

  return (
    <>
      <DisplayModal isOpen={isOpen} onClose={closeModal} cancel={closeModal} />

      <div className="areq-sidebar desc">
        <h4 className="title">
          <div className="title-icon">
            <WarnIcon width={15} height={15} className="icon" />
          </div>
          <div className="title-text">This is an AREQ requisition</div>
        </h4>
        <p>
          The Additional Requirements (AREQ) option code allows customers to
          request specific open market equipment not listed in the Compare and
          Select page.
        </p>
        <p>
          Customers are required to provide information detailing their
          additional requirements before sending the requisitions to GSA for
          their review.
        </p>
        <Button
          variant="unstyled"
          label="Review complete AREQ process and instructions here"
          onClick={openAreqModal}
        />
      </div>
      <hr />
    </>
  );
};

export default AREQSidebar;
