/* eslint-disable react/jsx-curly-newline,react/jsx-no-bind */
import React from 'react';
import { Accordion } from '@gsa/afp-component-library';
import AgencyContactForm from './AgencyContact/agency-contact';

const AgencyContact = () => {
  const getAgencyContactContent = () => {
    return [
      {
        id: 'agencyContact',
        title: (
          <div className="add-options-accordion-title">Agency information</div>
        ),
        content: (
          <div className="ordermod-address-accordion-container">
            <AgencyContactForm />
          </div>
        ),
        expanded: false,
      },
    ];
  };

  return (
    <Accordion
      multiselectable={false}
      bordered="true"
      items={getAgencyContactContent()}
      className="add-options-accordion"
    />
  );
};

export default AgencyContact;
