import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';

const ErrorMessage = ({ id, alert, description, footer, heading }) => {
  return (
    <div
      id={`${id}-error`}
      data-testid={`${id}-error`}
      className="grid-container tablet:padding-x-10 tablet:padding-bottom-5"
    >
      <Alert type="error" heading={heading}>
        {alert}
      </Alert>
      <div
        data-testid={`${id}-error-description`}
        className="margin-top-2 tablet:margin-bottom-0"
      >
        {description}
      </div>
      <div
        data-testid={`${id}-error-footer`}
        className="tablet:margin-top-0 tablet:padding-top-0"
      >
        {footer}
      </div>
    </div>
  );
};

ErrorMessage.defaultProps = {
  id: undefined,
  alert: undefined,
  heading: undefined,
  description: undefined,
  footer: undefined,
};

ErrorMessage.propTypes = {
  id: PropTypes.string,
  alert: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.node,
  footer: PropTypes.node,
};

export default ErrorMessage;
