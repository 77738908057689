import _ from 'lodash';

const feedback = {
  countryCode: ['Please provide country', 'Please select a Country'],
  addressLine1: ['Please provide address', 'Please provide Address'],
  city: ['Please provide City', 'Please provide City'],
  stateCode: ['Please provide State', 'Please provide State'],
  zipcode: ['Please provide Zip code', 'Please provide Zip code'],
  firstName: ['Please provide first name', 'Please provide First Name'],
  lastName: ['Please provide last name', 'Please provide Last Name'],
  phoneCountryCode: [
    'Please provide country code',
    'Please select a valid Country Code',
  ],
  phoneNumber: ['Please provide phone number', 'Invalid Phone Number'],
  phoneExtension: ['Invalid phone number extension'],
  faxCountryCode: [
    'Please provide country code',
    'Please select a valid Country Code',
  ],
  faxNumber: ['Please provide fax number', 'Invalid Fax Number'],
  faxExtension: ['Invalid fax number extension'],
  email: ['Please provide email address', 'Invalid Email Address'],
  entityName: ['Please provide agency/organization name'],
  rankAndFullName: ['Please provide rank and full name'],
  militaryOrDiplomaticAddress: ['Please provide address'],
  postOffice: ['Please select post office'],
};

export const validateAddressFields = (
  receivedKey,
  rawValue,
  focused = null,
  isExport = false,
  internationalAddress = false,
  selectedCountry = '1',
) => {
  const key = receivedKey;
  const errors = [];
  let value;
  let temp;

  const handledUndefined = ['faxNumber1'];
  if (rawValue === undefined && handledUndefined.indexOf(key) === -1) {
    return {
      errors: [{ key, visibility: 'error', text: 'Invalid value' }],
    };
  }

  if (rawValue !== null && rawValue !== undefined) {
    value = rawValue.toString().trim();
  } else {
    value = '';
  }

  switch (key) {
    case 'countryCode':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null ||
        value === 'Select Country' ||
        value.length > 20
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'addressLine1':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,.'-]*$/) === null ||
        value.length < 5 ||
        value.length > 50
      ) {
        errors.push({
          key,
          visibility: focused ? true : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: false,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'addressLine2':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      }
      break;
    case 'city':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z\s,.'-]*$/) === null ||
        value.length > 50 ||
        !value.trim().length
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'stateCode':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null ||
        value === 'Select State'
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'zipcode':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else if (value.length && value.match(/^[0-9]+$/) != null && isExport) {
        errors.push({ key, visibility: false, text: false });
      } else if (
        !internationalAddress &&
        value.match(/^\d{5}(?:[-\s]\d{4})?$/) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'firstName':
    case 'lastName':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (value.match(/^[a-z ,.'-]+$/i) === null || value.length > 50) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneCountryCode':
      temp = value.split(':');
      [value] = temp;
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value !== undefined &&
        (value === '' || Number.isNaN(parseInt(value, 10)))
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneNumber':
      value = value.replace(/\D/g, '');
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: feedback[key][0],
        });
      } else if (
        selectedCountry === '1' &&
        (value.length < 10 || value.length > 10)
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else if (
        selectedCountry !== '1' &&
        value.match(/^([1-9]{1}[0-9]{9})$/im) === null &&
        value.match(/^\d{9}$/) === null &&
        value.match(/^([1-9]{1}[0-9]{15})$/im) === null &&
        (value.length > 15 || value.length < 8)
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneExtension':
      if (value !== '' && value.match(/^([1-9]{1,5})$/im) === null) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: 'Invalid extension number',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxCountryCode':
      temp = value.split(':');
      [value] = temp;
      if (
        value !== undefined &&
        value !== '' &&
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxNumber':
      value = value.replace(/\D/g, '');
      if (
        value !== '' &&
        value.match(/^([1-9]{1}[0-9]{9})$/im) === null &&
        value.match(/^([1-9]{1}[0-9]{15})$/im) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxExtension':
      if (value !== '' && value.match(/^([1-9]{1,5})$/im) === null) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: 'Invalid extension number',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'email':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: feedback[key][0],
        });
      } else if (
        value.match(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'entityName':
    case 'agencyOrganizationName':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'rankAndFullName':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'militaryOrDiplomaticAddress':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'postOffice':
      if (value === 'select' || value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: feedback[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'deliveryDate':
      if (value === null || value === '') {
        errors.push({
          key,
          visibility: 'error',
          text: 'Please provide delivery date',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    default:
      break;
  }

  return { errors };
};

export const validateAddress = (
  addressContext,
  actionType = null,
  dispatch = null,
) => {
  let errorsToUpdate = [];

  // FOR REQUISITION ADDRESS
  let addressKey = Object.keys(addressContext);
  let forDeletion = [];
  if (addressContext.isMilitary) {
    forDeletion = [
      'countryCode',
      'city',
      'stateCode',
      'addressLine1',
      'firstName',
      'lastName',
    ];
  } else if (!addressContext.isMilitary) {
    forDeletion = ['entityName', 'rankAndFullName', 'postOffice'];
  }

  addressKey = addressKey.filter((item) => !forDeletion.includes(item));

  addressKey.forEach((key) => {
    const { errors } = validateAddressFields(key, addressContext[key], true);
    errorsToUpdate = errorsToUpdate.concat(errors);
  });
  errorsToUpdate = errorsToUpdate.filter((i) => i.text !== false);

  if (actionType && dispatch && errorsToUpdate.length > 0) {
    dispatch({
      type: actionType,
      payload: errorsToUpdate,
    });
  }
  return errorsToUpdate;
};

export const updatecurrentState = (
  receivedState,
  updateDraftRequisition,
  setCountrySelected,
  selectedField,
  requisitionDraftId,
  requisitionHasError,
  enteredText,
) => {
  const currentState = receivedState;

  if (selectedField === 'countryCode') {
    currentState.countryCode = enteredText;
    setCountrySelected(currentState.countryCode);
    if (requisitionDraftId && requisitionHasError.length === 0) {
      updateDraftRequisition(requisitionDraftId);
    }
  } else if (selectedField === 'addressLine1') {
    currentState.addressLine1 = enteredText;
  } else if (selectedField === 'addressLine2') {
    currentState.addressLine2 = enteredText;
  } else if (selectedField === 'city') {
    currentState.city = enteredText;
  } else if (selectedField === 'stateCode') {
    currentState.stateCode = enteredText;
  } else if (selectedField === 'zipcode') {
    currentState.zipcode = enteredText;
  } else if (selectedField === 'zipcodePlusFour') {
    currentState.zipcodePlusFour = enteredText;
  } else if (selectedField === 'firstName') {
    currentState.firstName = enteredText;
  } else if (selectedField === 'lastName') {
    currentState.lastName = enteredText;
  } else if (selectedField === 'email') {
    currentState.email = enteredText;
  } else if (selectedField === 'phoneCountryCode') {
    currentState.phoneCountryCode = enteredText;
  } else if (selectedField === 'phoneNumber') {
    currentState.phoneNumber = enteredText;
  } else if (selectedField === 'phoneExtension') {
    currentState.phoneExtension = enteredText;
  } else if (selectedField === 'faxCountryCode') {
    currentState.faxCountryCode = enteredText;
  } else if (selectedField === 'faxNumber') {
    currentState.faxNumber = enteredText;
  } else if (selectedField === 'faxExtension') {
    currentState.faxExtension = enteredText;
  } else if (selectedField === 'entityName') {
    currentState.entityName = enteredText;
  } else if (selectedField === 'postOffice') {
    currentState.postOffice = enteredText;
  } else if (selectedField === 'militaryOrDiplomaticAddress') {
    currentState.militaryOrDiplomaticAddress = enteredText;
  } else if (selectedField === 'rankAndFullName') {
    currentState.rankAndFullName = enteredText;
  } else if (selectedField === 'isDealershipDelivery') {
    currentState.isDealershipDelivery = enteredText;
  } else if (selectedField === 'dealerId') {
    currentState.deliveryId = parseInt(enteredText, 10);
  }

  return currentState;
};

export const validateSelection = (selAddress, validatedAddress) => {
  let updatedWithChecks = validatedAddress;
  if (selAddress === 'USPS') {
    updatedWithChecks = { ...updatedWithChecks, isChecked: true };
  } else {
    updatedWithChecks = { ...updatedWithChecks, isChecked: false };
  }

  return updatedWithChecks;
};

export const getStateCodeOnly = (countryState) => {
  return countryState?.slice(countryState?.indexOf('-') + 1);
};

const matchableAddressKeys = ['addressLine1', 'city', 'stateCode', 'zipcode']; // ignoring address2 since it is not shown in the modal
export const addressesMatch = (addressA, addressB) =>
  _.isEqual(
    _.pick(
      { ...addressA, state: getStateCodeOnly(addressA?.stateCode) },
      matchableAddressKeys,
    ),
    _.pick(
      { ...addressB, state: getStateCodeOnly(addressB?.stateCode) },
      matchableAddressKeys,
    ),
  );

// We need to have same comparable keys since the structure in placed initially does not match
// https://stackoverflow.com/a/50101979
export const adaptKeys = (validAddress) => {
  const newObj = {};
  delete Object.assign(newObj, validAddress, {
    addressLine1: validAddress?.address,
  }).address;
  delete Object.assign(newObj, validAddress, { zipcode: validAddress?.zip })
    .zipcode;
  return newObj;
};

export const countriesListValidation = (data) => {
  const domesticOptions = ['US', 'PR', 'GU', 'VI', 'MP', 'AS'];
  // data filtered for US States and Territories
  const filteredCountriesData = data.filter((item) => {
    const checkTerritories = domesticOptions.find((li) => {
      return item.value === li;
    });
    if (!checkTerritories) {
      return item;
    }
    return null;
  });

  return filteredCountriesData;
};
