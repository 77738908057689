import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import './option-unavailable-modal.scss';

export default function OptionsUnavailableModal({ handleClose, unavailableOptions , handleContinue }) {
  return (
    <div className="afp-modal-overlay">
      <Modal
        className=""
        onClose={handleClose}
        actions={
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="button"
              variant="unstyled"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              onClick={handleContinue}
              label="Confirm"
            />
          </div>
        }
        title={<div className='unavailable-modal-title'>Verify your selection</div>}
      >
        <div className="unavailable-modal-body">
          <div>The following options are not available on the vehicle model that you have selected. </div>
          <div className="options-list">
            <ul>
              {unavailableOptions?.map(option => {
                return <li>{option?.optionDescription}</li>
              })}
            </ul>
          </div>
          <div>Please confirm that you would like to proceed, or go back to modify your selection.</div>
        </div>
      </Modal>
    </div>
  );
}

OptionsUnavailableModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
