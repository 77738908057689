import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canApproveRequisitionCart } from '../../../../utilities/authorization';
import CheckedIcon from '../../../../assets/images/checked-icon';
import {
  PLACE_ORDER,
  SUBMIT_TO_APPROVER,
  SUBMIT_TO_ENGINEER,
  SUBMIT_TO_CO,
} from '../../constants/VehicleRequisitionConstants';
import useRequisitionReview from '../../../../utilities/useRequisitionReview';

const SubmitBtnLabel = () => {
  const { isEngineerReviewRequired, isVendorDirect } = useRequisitionReview();

  const ability = useAppAbility();
  const canApproveRequisition = canApproveRequisitionCart(ability);

  // If approver 3 paths Submit to Engineer , Submit to CO and place order
  if (canApproveRequisition) {
    if (isEngineerReviewRequired) {
      return (
        <>
          <CheckedIcon /> {SUBMIT_TO_ENGINEER}
        </>
      );
    }

    if (!isVendorDirect) {
      return (
        <>
          <CheckedIcon /> {SUBMIT_TO_CO}
        </>
      );
    }
    return PLACE_ORDER;
  }
  return (
    <>
      <CheckedIcon /> {SUBMIT_TO_APPROVER}
    </>
  );
};

export default SubmitBtnLabel;
