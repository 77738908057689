import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import './ReviewVehicleBuild.scss';
import { Spinner } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import ReviewDetails from '../../../../components/ReviewComponents/ReviewDetails/ReviewDetails';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '../../../../components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import {
  GET_PDF_URL,
  GET_REQUISITION_ACTIVITIES,
  CREATE_REQUISITION_COMMENT,
} from '../../../../services/data-layer';
import ErrorContext from '../../../../context/ErrorContext/ErrorContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { STEPS } from '../../constants/VehicleRequisitionConstants';

const ReviewVehicleBuild = ({ step }) => {
  const { state } = useContext(VehicleRequisitionContext);

  const {
    selectedContractCostBreakdown,
    optionalReqData,
    vehicleColors,
    taggedOptions,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    contractAgency,
    getStandardItem,
    nonLowBidJustification,
    selectedContractAgencyInformation,
    selectedContract,
    draftRequisition,
    draftSelectedColors,
    currentStandardItem,
    paintAndGraphicsOptions,
  } = state;
  const colorsFromDraft = [];
  if (draftSelectedColors?.length > 0) {
    const draftCPT =
      draftRequisition?.clientData?.selectedOptions?.selectedOptions?.find(
        (option) => option.optionCode === 'CPT' && option.isChecked,
      );
    if (!draftCPT) {
      draftSelectedColors.forEach((c) => {
        colorsFromDraft.push({
          color: {
            label: c.makeColorName,
            value: c.makeColorCode,
            price: c.makeColorPriceToCustomer,
            vendorPrice: c.makeColorPriceToGsa,
          },
          quantity: draftRequisition?.quantity,
        });
      });
    }
  }

  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT);

  const [isLoading, setIsLoading] = useState(false);
  const [requisitionActivities, setRequisitionActivities] = useState([]);
  const standardItem = sessionStorage.getItem('standardItem');
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const { dispatch } = useContext(ErrorContext);
  const code =
    currentStandardItem?.standardItemNumber || standardItem?.standardItemNumber;
  const defaultFriendlyName =
    draftRequisition?.friendlyName ||
    `${code}_${month}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;

  const [getPDFUrl, { data: generatePDFResponse }] = useLazyQuery(GET_PDF_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const [getRequisitionActivitiesData, { data: requisitionActivitiesData }] =
    useLazyQuery(GET_REQUISITION_ACTIVITIES, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!!step && step === STEPS.REVIEW_SUBMIT) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: true,
      });
    }
    if (draftRequisition?.requisitionId) {
      getRequisitionActivitiesData({
        variables: {
          requisitionId: draftRequisition?.requisitionId,
          offset: 0,
          limit: 20,
          order: [['createdAt', 'DESC']],
        },
      });
    }
  }, [draftRequisition?.requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivities) {
      const { rows } = requisitionActivitiesData.getRequisitionActivities;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  const waitingPDFLoop = (url) => {
    const maxTry = 15; // 30 seconds wait in total
    const timer = setTimeout(() => {
      const httpReq = new XMLHttpRequest();
      httpReq.open('GET', generatePDFResponse.generatePDF.signedUrl, true);
      httpReq.send();
      httpReq.onload = (response) => {
        if (response?.target && response?.target?.status === 200) {
          // open the PDF
          window.open(url, '_blank');
          setIsLoading(false);
        } else if (
          response?.target &&
          response?.target?.status !== 200 &&
          waitingPDFLoop.tryCount < maxTry
        ) {
          waitingPDFLoop.tryCount += 1;
          waitingPDFLoop(url);
          setIsLoading(false);
        } else {
          // throw error
          setIsLoading(false);
        }
      };
      httpReq.onerror = () => {
        setIsLoading(false);
      };
      clearTimeout(timer);
    }, 3000);
  };
  waitingPDFLoop.tryCount = 0;

  useEffect(() => {
    if (generatePDFResponse?.generatePDF?.succeed) {
      waitingPDFLoop(generatePDFResponse.generatePDF.signedUrl);
    }
  }, [generatePDFResponse]);

  const printPDF = () => {
    const firstActivity = requisitionActivities && requisitionActivities[0];
    const lastActivity =
      requisitionActivities &&
      requisitionActivities[requisitionActivities.length - 1];
    let agencyName = '';
    let bureauName = '';
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      agencyName = sessionStorage.getItem('receivingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('receivingBureau')?.split('-')[1];
    } else {
      agencyName = sessionStorage.getItem('orderingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('orderingBureau')?.split('-')[1];
    }
    const agencyInfo = {
      name: agencyName,
    };
    const bureauInfo = {
      name: bureauName,
    };
    setIsLoading(true);

    getPDFUrl({
      variables: {
        contentModel: 'new_requisition',
        recordId: draftRequisition?.requisitionId,
        friendlyName: defaultFriendlyName,
        sections: 'all',
        vehicleContract: JSON.stringify({
          clarifications:
            selectedContract?.clarifications ?? [],
          offerLineArtifact: selectedContract?.offerLineArtifact ?? [],
          baseEngineRating: selectedContract?.baseEngineRating ?? {},
          vendorName: selectedContract?.vendorName ?? '',
          modelName: selectedContract?.modelName ?? '',
          modelYear: selectedContract?.modelYear ?? '',
          comment: selectedContract?.comment ?? '',
        }),
        requisitionStandardItem: JSON.stringify(currentStandardItem),
        calculatedPriceInfoState: JSON.stringify(selectedContractCostBreakdown),
        selectedContractAgencyInformation: JSON.stringify(
          selectedContractAgencyInformation,
        ),
        agencyInfo: JSON.stringify(agencyInfo),
        bureauInfo: JSON.stringify(bureauInfo),
        firstActivity: JSON.stringify(firstActivity),
        lastActivity: JSON.stringify(lastActivity),
        perVehicleOptions: JSON.stringify(
          selectedContractCostBreakdown?.perVehicleOptions ?? [],
        ),
        selectedColor:
          (draftSelectedColors[0]?.makeColorName ||
            vehicleColors[0]?.color?.label) ??
          '',
        paintAndGraphicsOptions: JSON.stringify(paintAndGraphicsOptions),
        taggedOptions: JSON.stringify(taggedOptions),
        requisitionAddress: JSON.stringify(requisitionStateContext),
        mailingAddress: JSON.stringify(mailingStateContext),
        deliveryAddress: JSON.stringify(deliveryStateContext),
        nonLowBidJustification: JSON.stringify(nonLowBidJustification),
        areqList: JSON.stringify([]),
      },
    });
  };

  const handleAddComment = async (comment) => {
    const { appURLs } = window.AFP_CONFIG;
    await submitRequisitionComment({
      variables: {
        requisitionId: draftRequisition.requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
    setShowAddCommentModal(false);
  };

  return (
    <>
      {isLoading && (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      <div
        tabIndex="0"
        role="tab"
        className="margin-bottom-205 review-vehicle-build-title"
      >
        <h2 className="usa-h2">Review vehicle build details</h2>
        <p>
          Please review the following vehicle build details and proceed to the
          next step.
        </p>
      </div>
      <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
        <RequisitionActionsButtonDropdown
          className="requisition-action-button"
          menuItems={{
            [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
              onClick: printPDF,
            },
            [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
              onClick: () => setShowAddCommentModal(true),
            },
          }}
        />
      </div>
      <ReviewDetails
        vehicleColors={
          vehicleColors?.length > 0 ? vehicleColors : colorsFromDraft
        }
        taggedOptions={taggedOptions}
        selectedContractCostBreakdown={selectedContractCostBreakdown}
        vehicleOptions={
          draftRequisition?.clientData?.selectedOptions?.selectedOptions
        }
        requisitionAddress={requisitionStateContext}
        mailingAddress={mailingStateContext}
        deliveryAddress={deliveryStateContext}
        contractAgency={contractAgency}
        getStandardItem={getStandardItem}
        nonLowBidJustification={nonLowBidJustification}
        optionalReqData={optionalReqData}
        selectedContractAgencyInformation={selectedContractAgencyInformation}
        calculatedPriceData={selectedContractCostBreakdown}
        selectedContract={selectedContract}
        expanded
        isEditable
      />

      <CommentModal
        data-testid="add-comment-modal-urg-req"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={() => setShowAddCommentModal(false)}
        title="Post a comment"
        body="Your comment will be added to this timeline and sent to your GSA service rep."
        isRequired
      />
    </>
  );
};

ReviewVehicleBuild.propTypes = {
  step: PropTypes.string,
};

ReviewVehicleBuild.defaultProps = {
  step: null,
};
export default ReviewVehicleBuild;
