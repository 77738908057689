import { useTitle } from '@gsa/afp-shared-ui-utils';
import { useModal } from '@gsa/afp-component-library';
import AccountDetailsPanel from './account-details-panel';
import './select-replacement-vehicles.scss';
import LeasingNavigation from '../../components/navigation';
import VehicleReplacementSearch from './replacement-search';
import { useSelector } from "react-redux";
import { LeasingSteps } from "../../leasing-consts";
import ReplacementVehicleTable from './replacement-vehicle-table/replacement-vehicle-table.jsx';
import useLeasingNavigation from '../../../../hooks/useLeasingNavigation.jsx';
import SaveDraftModal from '../../components/save-draft/save-draft-modal.jsx';

const SelectReplacementVehicles = () => {
    useTitle('Select Replacement Vehicles | Leasing');
    const { requisitionId, account } = useSelector((state) => state.leasingReducer);
    const { handleLeasingNavigation } = useLeasingNavigation();
    const { isOpen, openModal, closeModal } = useModal();

    const handleSubmit = () => {
        if (!requisitionId) {
            openModal();
        } else {
            handleLeasingNavigation(LeasingSteps.DESTINATION_AND_VEHICLE_TYPE);
        }
    }

    const handlePreviousNavigation = () => {
        handleLeasingNavigation(LeasingSteps.REPLACEMENT_VEHICLES);
    }

    [!account && 'next', !account && 'previous']

    return (
        <>
            <div className='select-replacement-vehicles'>
                <div className='replacement-vehicles-panel' data-testid='replacement-vehicles-panel'>
                    <div className='replacement-vehicles'>
                        <div className='replacement-vehicles-title'>
                            <h3>Replacement Vehicles</h3>
                            <p>
                                Please select vehicles that you would like to replace. You may enter a vehicle selection either by license plate, or VIN. When selecting more than one vehicle, all of the selected vehicles must belong to the same customer account. All of the vehicles on a requisition must have the same vehicle model, options, and paint color selected.
                            </p>
                        </div>

                        <VehicleReplacementSearch />

                        <ReplacementVehicleTable />
                    </div>

                    <LeasingNavigation
                        onNext={handleSubmit}
                        onPrevious={handlePreviousNavigation}
                        hide={[!account && 'next', 'previous', 'submit', 'reject']}
                    />
                </div>

                {account && <AccountDetailsPanel />}
            </div>
            <SaveDraftModal
                isOpen={isOpen}
                onClose={closeModal}
                afterSubmit={(saved) => {
                    handleLeasingNavigation(LeasingSteps.DESTINATION_AND_VEHICLE_TYPE, saved.data.requisitionId)
                }}
            />
        </>
    )
}

export default SelectReplacementVehicles;