import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VehicleOtherFeatureSection = ({
  vehicleOtherFeaturesData,
  lowestBidData,
}) => {
  return (
    <>
      <div className="veh-overview__container">
        <header className="veh-overview__header">Other Features</header>
        <div className="veh-overview__body">
          <table className="usa-table full-width-table" role="presentation">
            <tbody>
              {vehicleOtherFeaturesData.map((vehicleOtherFeatureItem) => {
                const fragementKey =
                  vehicleOtherFeatureItem.optionCategoryName +
                  vehicleOtherFeatureItem.optionCategoryCode;
                return (
                  <React.Fragment key={fragementKey}>
                    <tr>
                      <td
                        data-testid={`vehicle-option-category-${vehicleOtherFeatureItem.optionCategoryName}`}
                      >
                        <strong>
                          {vehicleOtherFeatureItem.optionCategoryName}
                        </strong>
                      </td>
                      {vehicleOtherFeatureItem.options[0].otherFeatureValues.map(
                        (row) => {
                          const activateLowestBid =
                            row.modelName === lowestBidData.modelName &&
                            row.vendorName === lowestBidData.vendorName;
                          return (
                            <td
                              aria-label="td"
                              className={cx('option-cell', {
                                'lowest-bid': activateLowestBid,
                              })}
                            />
                          );
                        },
                      )}
                    </tr>
                    {vehicleOtherFeatureItem.options.map((item) => {
                      return (
                        <tr key={item.optionCode}>
                          <td
                            data-testid={`vehicle-option-description-${item.optionDescription}`}
                          >
                            <label className="option-label">
                              {item.optionCode} - {item.optionDescription}
                            </label>
                          </td>
                          {item.otherFeatureValues.map((option) => {
                            const activateLowestBid =
                              option.modelName === lowestBidData.modelName &&
                              option.vendorName === lowestBidData.vendorName
                                ? 'lowest-bid'
                                : '';
                            return (
                              <td
                                key={option.modelName + option.vendorName}
                                data-testid={`vehicle-optionValue-${option.optionValue}`}
                                className={`option-cell ${activateLowestBid}`}
                              >
                                <label className="option-label">
                                  {option.optionValue || '--'}
                                </label>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default VehicleOtherFeatureSection;
