import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'moment';
import {
  AFPTable,
  AFPTableRowAction,
  useModal,
  EmptyState,
  Spinner
} from '@gsa/afp-component-library';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { useSelector } from 'react-redux';
import AttachmentDeleteModal from '../../../ReviewDetails/Attachment/AttachmentDeleteModal/AttachmentDeleteModal';
import AttachmentUploadEditModal from '../../../ReviewDetails/Attachment/AttachmentEditModal/AttachmentEditModal';
import { setAttachments } from '../../../../reducers/leasing';
import { useGetAttachmentTypes, useGetRequisitionAttachmentsLazy } from '../../../../requests/leasing';
import { useTitle } from '@gsa/afp-shared-ui-utils';

export default function LeasingAttachmentsTable() {
  useTitle('Review and Submit - Attachments');
  const { requisitionId, attachments } = useSelector((state) => state.leasingReducer);
  const [selectedFile, setSelectedFile] = useState(null);
  const deleteFileModal = useModal();
  const editFileModal = useModal();
  const [getRequisitionAttachments, {loading}] = useGetRequisitionAttachmentsLazy({requisitionId});
  useGetAttachmentTypes();

  const getAttachments = () => {
    getRequisitionAttachments().then(({ data, dispatch }) => {
      if (data) {
        dispatch(setAttachments(data));
      }
    });
  };

  useEffect(() => {getAttachments()}, []);

  const handleSelectedRow = (event, row) => {
    if (event === 'Delete') {
      setSelectedFile(row.original);
      deleteFileModal.openModal();
    }
    if (event === 'Edit') {
      setSelectedFile(row.original);
      editFileModal.openModal();
    }
  };

  const getAllowedActions = (row) => {
    const actionButtons = [{ icon: 'edit', label: 'Edit' }];
    if (
      attachments?.find(
        (req) =>
          req.requisitionAttachmentTypeId === row?.original?.attachmentTypeId,
      )
    ) {
      return actionButtons;
    }
    actionButtons.push({ icon: 'delete', label: 'Delete' });
    return actionButtons;
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(() => {
    const filterdColumns = [
      {
        Header: 'Files',
        accessor: 'type',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Uploaded by',
        accessor: 'createdUserInfo.fullName',
        Cell: ({ row: { original } }) => {
          return <>{original.createdUserInfo?.fullName}</>;
        },
      },
      {
        Header: 'Date / Time',
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <>
              {Moment(original.createdAt).format('MM/DD/YYYY')} &#8226;{' '}
              {Moment(original.createdAt).format('hh:mm A')} ET
            </>
          );
        },
      },
      {
        Header: 'Comments',
        accessor: 'description',
        sortable: false,
        cellClassName: 'attachment-note',
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={getAllowedActions(row)}
              onSelectAction={(event) => handleSelectedRow(event, row)}
              {...props}
            />
          );
        },
      },
    ];

    return filterdColumns;
  }, [attachments]);

  return (
    <div>
      <div className="attachments-container">
        <div className="attachments-container-header">
          <div className="attachments-container-header-text">
            Below are all files associated with this requisition. You may see
            who uploaded the attachment, when, what type of requisition event
            category the attachment falls within, and optional details.
          </div>
        </div>

        <AFPTable
          testId="requisition-attachment-table"
          columns={columns}
          data={attachments || []}
        />

        {loading && <Spinner aria-busy="true" size="medium" />}

        {!attachments?.length && (
          <EmptyState
            hasBackground
            containerStyles="margin-top-neg-2 padding-y-10"
            topText="No attachments found for this requisition"
          />
        )}
      </div>

      <AttachmentDeleteModal
        isOpen={deleteFileModal.isOpen}
        handleClose={deleteFileModal.closeModal}
        fileToDelete={selectedFile}
        onCompleted={() => {
          getAttachments();
        }}
      />
      <AttachmentUploadEditModal
        isOpen={editFileModal.isOpen}
        handleClose={editFileModal.closeModal}
        selectedAttachment={selectedFile}
        onCompleted={() => {
          getAttachments();
        }}
      />
    </div>
  );
};