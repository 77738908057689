import React, { useState } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateContractLinebyId } from '../../requests/ordering-guide';
import { setShowSuccessMessage, setUpdatedContractLineECs } from '../../reducers/ordering-guide';


const leasingStatusDropdown = [
    {
      value: 1,
      label: 'Available',
      defaultValue: true,
    },
    {
      value: 0,
      label: 'Unavailable',
    },
  ];

function LeasingStatusChange({ orderGuide }) {
  const {
    updatedContractECs
  } = useSelector((state) => state.orderingGuideReducer);

  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const [isLeaseAvailable, isLeaseAvailableTo] = useState(orderGuide?.isLeaseAvailable)
  const changeLeasingStatus = (value) => {
    dispatch(setShowSuccessMessage(false))
    if(orderGuide?.contractLineId){
      dispatch(updateContractLinebyId({
        contractLineId: +orderGuide?.contractLineId,
        isLeaseAvailable: parseInt(value,10)
      }))
    }
    if(orderGuide?.contractLineECId){
      let updatedECList = [...updatedContractECs];
      const updatedECIndex = updatedECList?.findIndex(ec => ec?.contractLineECId === orderGuide?.contractLineECId)
      if(updatedECIndex !== -1){
        updatedECList[updatedECIndex] = {
          ...updatedECList[updatedECIndex],
          isLeaseAvailable: parseInt(value,10)
        };
      } else {
        updatedECList.push({...orderGuide, isLeaseAvailable: parseInt(value,10)});
      }
      dispatch(setUpdatedContractLineECs({
        updatedContractECs: updatedECList
      }));
    }
    isLeaseAvailableTo(value)
  }
  return (
    <SelectDropdown
      id="contract-leasing-status"
      aria-label="contract Leasing Status"
      onChange={(e) => changeLeasingStatus(e.target.value)}
      value={orderGuide?.isLeaseAvailable}
      data-testid="selectfilter-type"
      disabled={!currentUser?.roles?.some((role) => role?.id === '1503')}
      options={leasingStatusDropdown}
      required
    />
  );
}


export default LeasingStatusChange;
