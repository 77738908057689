import { orderLineMapping } from './orderline/mapping';
import useOrderLineDataProcessor from './orderline/use-orderline-data-processor';

// eslint-disable-next-line import/prefer-default-export
export const reportsConfig = {
  DEFAULT: { value: 'DEFAULT', label: '-Select-', maxFileSize: 30 },
  ORDERLINE: {
    value: 'OL',
    label: 'Order Line',
    filetype: 'xlsx',
    sheets: ['Instructions', 'OrderLines', 'Reference'],
    dataSheet: 'OrderLine',
    maxFileSize: 30,
    batchSize: 50,
    mapping: orderLineMapping,
    processorHook: useOrderLineDataProcessor,
    uniqueIdenfier: 'CaseNumber',
    errorFileName: 'OL_Errors',
    errorSheetName: 'OrderLine Upload Errors',
    errorSheetHeaders: [
      'Row Number',
      'Vehicle line number',
      'Case Number',
      'Status',
      'Errors',
    ],
    errorSheetColumnWidths: [
      { wch: 25 },
      { wch: 25 },
      { wch: 9 },
      { wch: 125 },
    ],
  },
};
