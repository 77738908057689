import React, { useEffect, useState } from 'react';
import {
  MultiSelect,
  Checkbox,
  Label,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { GET_REQUISITIONS_STATUSES } from '../queries';

const capitalized = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

const RequisitionStatus = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const selectedRequisitionStatuses = watch('requisitionStatuses');
  const [getRequisitionStatues, { data: requisitionsStatusData }] =
    useLazyQuery(GET_REQUISITIONS_STATUSES, {
      fetchPolicy: 'c',
    });
  const [allRequisitionStatuses, setAllRequisitionStatuses] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getRequisitionStatues();
  }, []);

  useEffect(() => {
    if (requisitionsStatusData) {
      setAllRequisitionStatuses(
        requisitionsStatusData?.getRequisitionStatuses || [],
      );
    }
  }, [requisitionsStatusData]);

  const handleSelectAll = (value, checked) => {
    if (checked) {
      setSelectAll(true);
      setValue(
        'requisitionStatuses',
        allRequisitionStatuses.map((v) => v),
      );
    } else {
      setValue('requisitionStatuses', []);
      setSelectAll(false);
    }
  };

  const handleSelect = (value, checked) => {
    if (checked) {
      setValue(
        'requisitionStatuses',
        selectedRequisitionStatuses.concat(value),
      );
    } else {
      setSelectAll(false);
      setValue(
        'requisitionStatuses',
        selectedRequisitionStatuses.filter((v) => v !== value),
      );
    }
  };
  return (
    <div className="grid-row margin-1px">
      <div className="grid-col-5">
        <Label required htmlFor="requisition_statuses">
          Requisition status
        </Label>
        {errors?.requisitionStatuses && (
          <ErrorMessage id="requisition-number-error">
            {errors?.requisitionStatuses.type === 'required' &&
              'This is a required field'}
          </ErrorMessage>
        )}
        <Checkbox
          id="all-requisition-statuses"
          data-testid="all-requisition-statuses-checkbox"
          label="All requisitions statuses"
          name="All Requisitions"
          disabled={false}
          onChange={({ target: { value, checked } }) =>
            handleSelectAll(value, checked)
          }
          checked={selectAll}
        />
        <Controller
          name="requisitionStatuses"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { value } }) => {
            return (
              <MultiSelect
                id="requisition_statuses"
                onChange={(a, checked) => handleSelect(a, checked)}
                options={allRequisitionStatuses.map((status) => ({
                  label: capitalized(status.replace(/_/g, ' ')),
                  value: status,
                }))}
                selectedValues={value}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default RequisitionStatus;
