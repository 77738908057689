import React from 'react';
import { TextInput } from '@gsa/afp-component-library';
import { VehicleCard } from '../VehicleCard/VehicleCard';
import './compare-select-pages.scss';

const ContractImagesSection = ({ contracts, lowBidContract, quantity }) => {
  return (
    <div className="vehicle-header-cards">
      <div className="store-compare-quantity-section">
        <div className="quantity-input">
          Vehicle quantity: {quantity}
        </div>
      </div>
      {contracts.map((contract) => (
        <VehicleCard
          imageData={contract.attachments}
          makeName={contract.makeName}
          modelName={contract.modelName}
          vendorName={contract.vendorName}
          contractLineId={contract.contractLineId}
          isActive={contract?.contractLineId === lowBidContract?.contractLineId}
          key={`${contract?.contractLineId}-${contract.modelCode}`}
        />
      ))}
    </div>
  );
};

export default ContractImagesSection;
