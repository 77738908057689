/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { StatusBadge, Menu, Button } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { RequisitionTransactionType, StoreOperations, StoreSubjects } from '../../constants/constants';
import useMyRequisitionsState from './useMyRequisitionsState';
import './RequisitionsTable.scss';
import WarningSvg from '../../assets/images/warning-icon.svg';
import WarningRed from '../../assets/images/warning-red.svg';
import {
  RequisitionStatus,
  RequisitionStatusLabels,
} from '../ReviewDetails/RequisitionDetailsUtils';
import { REQUISITION_TYPE } from '../non-standard-purchase/constants';
import { useNavigate } from 'react-router-dom';

export const RequisitionStatusType = {
  [RequisitionStatus.DRAFT]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.DRAFT,
  },
  [RequisitionStatus.CANCELLED]: {
    color: 'Inactive Gray',
    label: RequisitionStatusLabels.CANCELLED,
  },
  [RequisitionStatus.DELETED]: {
    color: 'Inactive Gray',
    label: RequisitionStatusLabels.DELETED,
  },
  [RequisitionStatus.PENDING_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.PENDING_APPROVAL,
  },
  [RequisitionStatus.DRAFT_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.DRAFT_APPROVAL,
  },
  [RequisitionStatus.FINAL_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.FINAL_APPROVAL,
  },
  [RequisitionStatus.ENGINEERING_REVIEW]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.ENGINEERING_REVIEW,
  },
  [RequisitionStatus.ON_HOLD]: {
    color: 'Urgent-Gray',
    label: RequisitionStatusLabels.ON_HOLD,
  },
  [RequisitionStatus.ENGINEERING_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.ENGINEERING_APPROVAL,
  },
  [RequisitionStatus.RETURNED]: {
    color: 'Urgent-Gray',
    label: RequisitionStatusLabels.RETURNED,
  },
  [RequisitionStatus.RECEIVED_BY_GSA]: {
    color: 'Ready-Gray',
    label: RequisitionStatusLabels.RECEIVED_BY_GSA,
  },
  [RequisitionStatus.ORDER_RECEIVED]: {
    color: 'Ready-Gray',
    label: RequisitionStatusLabels.ORDER_RECEIVED,
  },
  [RequisitionStatus.CONTRACTING_REVIEW]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.CONTRACTING_REVIEW,
  },
  [RequisitionStatus.CONTRACTING_APPROVAL]: {
    color: 'Warning-Gray',
    label: 'Contracting approval',
  },
  [RequisitionStatus.PENDING_CUSTOMER_RESPONSE]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.PENDING_CUSTOMER_RESPONSE,
  },
  [RequisitionStatus.CONTRACTING_FINAL_REVIEW]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.CONTRACTING_FINAL_REVIEW,
  },
  [RequisitionStatus.ORDERING_ADMIN_REVIEW]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.ORDERING_ADMIN_REVIEW,
  },
  [RequisitionStatus.ORDERING_ADMIN_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.ORDERING_ADMIN_APPROVAL,
  },
  [RequisitionStatus.FMC_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.FMC_APPROVAL,
  },
  [RequisitionStatus.ZONAL_APPROVAL]: {
    color: 'Warning-Gray',
    label: RequisitionStatusLabels.ZONAL_APPROVAL,
  },
};

const rowActions = [
  {
    icon: 'check',
    label: 'Place order',
    action: 'place order',
  },
  {
    icon: 'edit',
    label: 'Rename',
    action: 'rename',
  },
  {
    icon: 'cancel',
    label: 'Cancel requisition',
    action: 'cancel',
  },
  {
    icon: 'content_copy',
    label: 'Clone requisition',
    action: 'clone',
  },
  {
    icon: 'undo',
    label: 'Reopen',
    action: 'reopen',
  },
  {
    icon: 'account_circle',
    label: 'Assign to me',
    action: 'assignEngineerToRequisitionCartById',
  },
  {
    icon: 'alarm',
    label: 'Place on hold',
    action: 'placeOnHold',
  },
  {
    icon: 'alarm',
    label: 'Resume engineer review',
    action: 'resumeEngineerReview',
  },
];

const ActionColumn = (props) => {
  const {
    toggleShowEditModal,
    setSelectedRequisition,
    setNewRequisitionName,
    setCancelRequisitionComments,
    toggleShowCancelModal,
    toggleShowReopenModal,
    setShowPutOnHoldModal,
    resumeEngineerReview,
    assignEngineerToRequisitionCartById,
  } = useMyRequisitionsState();

  const navigate = useNavigate();
  const { row } = props;

  return (
    <Menu
      actionIconSize="usa-icon--size-3"
      menuDialogState="hover"
      menuItems={rowActions.filter(
        (action) => row.values?.actions?.indexOf(action.label) > -1,
      )}
      onActionClick={(action) => {
        setSelectedRequisition(row);

        switch (action) {
          case 'placeOnHold':
            setShowPutOnHoldModal(true);
            break;
          case 'resumeEngineerReview':
            resumeEngineerReview(row.original.requisitionId);
            break;
          case 'place order':
            navigate(
              `/requisition-details?requisitionId=${row.original.requisitionId}`,
            );
            break;
          case 'rename':
            toggleShowEditModal(true);
            setNewRequisitionName(row.values.name);
            break;
          case 'reopen':
            toggleShowReopenModal(true);
            break;
          case 'clone':
            navigate(
              `/clone-requisition?requisitionId=${row.original.requisitionId}&draftName=${row.original.name}`,
            );
            break;
          case 'assignEngineerToRequisitionCartById':
            assignEngineerToRequisitionCartById(row);
            break;
          // NOTE: This cancel is required somehow/someway
          case 'cancel':
          default:
            toggleShowCancelModal(true);
            setCancelRequisitionComments('');
            break;
        }
      }}
      {...props}
    />
  );
};

const viewRequisitionDetailsStatuses = [
  RequisitionStatus.DRAFT_APPROVAL,
  RequisitionStatus.FINAL_APPROVAL,
  RequisitionStatus.RECEIVED_BY_GSA,
  RequisitionStatus.ORDER_RECEIVED,
  RequisitionStatus.ENGINEERING_REVIEW,
  RequisitionStatus.CONTRACTING_REVIEW,
  RequisitionStatus.ENGINEERING_APPROVAL,
  RequisitionStatus.CONTRACTING_APPROVAL,
  RequisitionStatus.PENDING_CUSTOMER_RESPONSE,
  RequisitionStatus.CONTRACTING_FINAL_REVIEW,
  RequisitionStatus.ENGINEERING_APPROVAL,
  RequisitionStatus.ON_HOLD,
  RequisitionStatus.ORDERING_ADMIN_REVIEW,
  RequisitionStatus.ORDERING_ADMIN_APPROVAL,
  RequisitionStatus.FMC_APPROVAL,
];

export const getRequisitionLink = ({
  requisitionStatus,
  isReqViewUser,
  isNotReqEditUser,
  requisitionId,
  requisitionType,
  transactionType,
}) => {
  let hrefValue = '';
  if (
    viewRequisitionDetailsStatuses.includes(requisitionStatus) ||
    (isReqViewUser && isNotReqEditUser)
  ) {
    if (transactionType === RequisitionTransactionType.VEHICLE_LEASE) {
      hrefValue = `/lease/${requisitionId}`;
    } else {
      hrefValue = `/requisition-details?requisitionId=${requisitionId}`;
    }
  } else if (requisitionType === REQUISITION_TYPE.URGENT_REQUIREMENT) {
    hrefValue = `/urgent-requisition?requisitionId=${requisitionId}`;
  } else if (requisitionType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) {
    hrefValue = `/mas-requisition?requisitionId=${requisitionId}`;
  } else if (transactionType === RequisitionTransactionType.VEHICLE_LEASE) {
    hrefValue = `/lease/${requisitionId}`;
  } else {
    hrefValue = `/vehicle-requisition?requisitionId=${requisitionId}`;
  }

  return hrefValue;
};

export const REQUISITION_TABLE_COLUMN_HEADERS = [
  {
    Header: 'Requisition Number',
    accessor: 'number',
  },
  {
    Header: 'Requisition Name',
    accessor: 'name',
    Cell: ({
      value,
      row: {
        original: {
          requisitionStatus,
          requisitionId,
          isSINexpired,
          requisitionType,
          transactionType
        },
      },
    }) => {
      const [enableTooltip, setEnableTooltip] = useState(false);
      const ability = useAppAbility();
      const isReqViewUser = useMemo(
        () => ability?.can(StoreOperations.View, StoreSubjects.Requisition),
        [ability],
      );

      const isNotReqEditUser = useMemo(
        () =>
          ability?.cannot(StoreOperations.Update, StoreSubjects.Requisition),
        [ability],
      );


      return (
        <>
          {requisitionStatus === RequisitionStatus.CANCELLED && (
            <span> {value} </span>
          )}
          {!isSINexpired && requisitionStatus !== RequisitionStatus.CANCELLED && (
            <Link
              className="requisition-name-link"
              to={getRequisitionLink({
                requisitionStatus,
                isReqViewUser,
                isNotReqEditUser,
                requisitionId,
                requisitionType,
                transactionType,
              })}
            >
              {value}
            </Link>
          )}

          {isSINexpired && requisitionStatus !== 'CANCELLED' && (
            <span className="requisition-name tooltip disabled">
              <span className="tooltip-text">
                <img src={WarningSvg} alt="warning" className="warning-icon" />
                Your requisition cannot be completed because the standard item
                is not available.
              </span>
              <img src={WarningRed} alt="warning" className="warning-icon" />
              <Button
                variant="unstyled"
                onClick={() => {
                  if (enableTooltip) {
                    setEnableTooltip(true);
                  }
                }}
                label={value}
              />
            </span>
          )}
        </>
      );
    },
  },
  {
    Header: 'Requisition status',
    accessor: 'requisitionStatus',
    Cell: ({ value }) => {
      const { color, label } = RequisitionStatusType[value] || {
        color: 'Warning-Gray',
        label: `INVALID STATUS (${value})`,
      };

      return <StatusBadge variant={color}>{label}</StatusBadge>;
    },
  },
  {
    Header: 'Edited',
    accessor: 'updatedAt',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    sortable: false,
    Cell: ActionColumn,
  },
];

const dateFormat = new Intl.DateTimeFormat('en', {
  dateStyle: 'short',
});
const timeFormat = new Intl.DateTimeFormat('en', {
  dateStyle: 'short',
  timeStyle: 'short',
});

// Check to see if Requisition Status is Draft.
const isDraft = (row) => {
  const status = _get(row, 'requisitionStatus');
  const reqNumber = _get(row, 'requisitionNumber');
  return (status === 'DRAFT' && !reqNumber) || reqNumber === '--'
    ? 'TBD'
    : reqNumber;
};

export const getTableColumns = (row) => ({
  number: isDraft(row),
  name: _get(row, 'friendlyName'),
  requisitionStatus: _get(row, 'requisitionStatus'),
  updatedAt: dateFormat.format(new Date(_get(row, 'updatedAt'))),
  actions: _get(row, 'allowedActions'),
  vehicles: _get(row, 'vehicles'),
  standardItemCode: _get(row, 'standardItemCode'),
  scheduleLine: _get(row, 'scheduleLine'),
  totalSellingPrice: _get(row, 'totalSellingPrice'),
  quantity: _get(row, 'quantity'),
  vehicleTypeCode: _get(row, 'vehicleTypeCode'),
  boac: _get(row, 'boac'),
  modelCode: _get(row, 'modelCode'),
  julianDate: _get(row, 'julianDate'),
  serialNumber: _get(row, 'serialNumber'),
  bereauName: _get(row, 'bureauInfoName'),
  officeName: _get(row, 'officeInfoName'),
  createdUser: `${_get(row, 'createdUserInfoFirstName')} ${_get(
    row,
    'createdUserInfoLastName',
  )}`,
  modifiedUser: `${_get(row, 'updatedUserInfoFirstName') || _get(row, 'createdUserInfoFirstName')} ${_get(row,'updatedUserInfoLastName') || _get(row,'createdUserInfoLastName')}`,
  transactionType: _get(row, 'transactionType'),
  requisitionType: _get(row, 'requisitionType'),
  createdAt: timeFormat.format(new Date(_get(row, 'createdAt'))),
  remainingDays: `${_get(row, 'remainingDays')} days`,
  requisitionId: _get(row, 'requisitionId'),
  isSINexpired: _get(
    row,
    'requisitionCartValidations.systemAvailability.isSINexpired',
  ),
  assignedEngineer: `${_get(row, 'updatedUserInfoFirstName')} ${_get(
      row,
      'updatedUserInfoLastName',
  )}`,
});

ActionColumn.propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
};
