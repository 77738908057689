import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import {
  SUBMIT_CONTRACTING_REVIEW,
  SUBMIT_CONTRACTING_REVIEW_FROM_CO_TO_ENGINEER,
} from '../../../services/data-layer';
import DeleteAdditionalReqFileModal from '../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import AdditionalVendorsSection from './AdditionalVendorsSection';
import { transformAttachmentPayload } from '../urgent-requisition/utils/UrgReqCommonUtils';

let currentIndex = 0;
const MultipleAddVendors = ({
  reference,
  draftId,
  requisitionName,
  customClass,
  fromCoToEngineer,
  visible = true,
}) => {
  const navigate = useNavigate();
  const formProps = useForm({
    defaultValues: {
      vendorQuoteDetails: [
        {
          vendor: '',
          makeModel: '',
          vendorQuote: [],
          description: '',
          attachments: [],
          priceEstimate: null,
        },
      ],
    },
    reValidateMode: 'onChange',
  });

  const { handleSubmit, getValues, setValue } = formProps;

  const [submitContractingReviewMutation] = useMutation(
    fromCoToEngineer
      ? SUBMIT_CONTRACTING_REVIEW_FROM_CO_TO_ENGINEER
      : SUBMIT_CONTRACTING_REVIEW,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const onSubmit = async () => {
    const data = getValues();
    const vendorDetailsArr = [];
    data.vendorQuoteDetails.forEach((li) => {
      const supportingAttachments = transformAttachmentPayload(li.attachments);
      vendorDetailsArr.push({
        vendorName: li.vendor,
        makeModel: li.makeModel,
        contractingOfficerComments: li.description,
        totalPrice: Number(li.priceEstimate),
        quote: {
          name: li.vendorQuote[0].name,
          metadataId: li.vendorQuote[0].metadataId,
          description: li.vendorQuote[0].description,
          signedUrl: li.vendorQuote[0].signedUrl,
        },
        supportingDocuments: supportingAttachments,
      });
    });
    const payload = {
      requisitionId: draftId,
      requisitionQuotes: vendorDetailsArr,
    };
    await submitContractingReviewMutation({
      variables: payload,
    });

    navigate(
      `/my-requisitions?requisitionId=${draftId}&draftName=${requisitionName}`,
    );
  };

  const allFieldsData = getValues();
  useImperativeHandle(reference, () => ({
    ...reference.current,
    submitForm() {
      handleSubmit(onSubmit)();
    },
  }));

  const {
    fields: allVendorQuotes,
    append,
    remove: removeVendor,
    control,
  } = useFieldArray({
    control: formProps.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'vendorQuoteDetails', // unique name for your Field Array
  });

  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const handleDeleteModal = (index) => {
    currentIndex = index;
    deleteFileModal.openModal();
  };

  const deleteFile = () => {
    setValue(`vendorQuoteDetails.${currentIndex}.vendorQuote`, []);
    deleteFileModal.closeModal();
  };

  if (!visible) {
    return null;
  }

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="vendor-quote-form"
        onSubmit={handleSubmit}
        className={cn('vendor-quote-form', { [customClass]: customClass })}
      >
        <div className="container">
          <div className="areq-information-tab" role="tab" tabIndex="0">
            <div className="add-req-btn-container">
              <Button
                data-testid="add-vendor-btn"
                type="button"
                variant="primary"
                label="Add Vendor"
                leftIcon={{ name: 'add', className: 'plus-icon' }}
                onClick={() => {
                  append({
                    vendor: '',
                    makeModel: '',
                    vendorQuote: [],
                    description: '',
                    attachments: [],
                    priceEstimate: null,
                  });
                }}
              />
            </div>

            <div className="additional-reqs-container">
              {allVendorQuotes.map((additionalReq, index) => (
                <AdditionalVendorsSection
                  draftId={draftId}
                  index={index}
                  indexChar={String.fromCharCode('A'.charCodeAt() + index)}
                  removeVendor={removeVendor}
                  control={control}
                  isDeleteModaOpen={deleteFileModal.isOpen}
                  handleDelete={(val) => handleDeleteModal(val)}
                />
              ))}
            </div>

            <DisplayDeleteFileModal
              isOpen={deleteFileModal.isOpen}
              handleClose={deleteFileModal.closeModal}
              handleDelete={deleteFile}
              filename={
                allFieldsData.vendorQuoteDetails[currentIndex]?.vendorQuote[0]
                  ?.name
              }
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

MultipleAddVendors.propTypes = {
  reference: PropTypes.func.isRequired,
  draftId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  fromCoToEngineer: PropTypes.bool,
  visible: PropTypes.bool,
};

MultipleAddVendors.defaultProps = {
  customClass: '',
  fromCoToEngineer: false,
  visible: true,
};

export default MultipleAddVendors;
