import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import AddressReview from '../AddressReview/AddressReview';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import { GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE } from '../../../services/data-layer';

const AddressReviewGroup = ({
  requisitionAddress,
  mailingAddress,
  deliveryAddress,
  isEditable,
  dealershipCode,
  dealershipAddressInfo,
}) => {
  const [dealershipAddress, setDealershipAddress] = useState(null);

  const [getDealershipByDealershipCode] = useLazyQuery(
    GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (dealershipAddressInfo) {
      setDealershipAddress(dealershipAddressInfo);
    }
  }, [dealershipAddressInfo]);

  useEffect(() => {
    if (dealershipCode?.trim()) {
      const getDealershipInfo = async () => {
        const { data } = await getDealershipByDealershipCode({
          variables: {
            dealershipCode,
          },
        });

        setDealershipAddress(data?.getDeliveryDealershipByDealershipCode);
      };
      getDealershipInfo();
    }
  }, [dealershipCode]);

  return (
    <>
      <div className="title-section">
        <span className="selected-options-header">Addresses</span>
        {isEditable && (
          <SectionEditButton
            showButton
            id="address-review-edit"
            step={STEPS.DELIVERY_ADDRESS}
          />
        )}
      </div>
      {requisitionAddress && (
        <AddressReview
          title="Requisitioning Address"
          addressData={requisitionAddress}
        />
      )}
      {mailingAddress && (
        <AddressReview title="Mailing Address" addressData={mailingAddress} />
      )}

      {dealershipAddress && (
        <AddressReview
          title="Dealership delivery address"
          addressData={dealershipAddress}
          displayPointOfContact={false}
        />
      )}

      {deliveryAddress && (
        <AddressReview title="Delivery address" addressData={deliveryAddress} />
      )}
    </>
  );
};

AddressReviewGroup.propTypes = {
  requisitionAddress: PropTypes.instanceOf(Object),
  mailingAddress: PropTypes.instanceOf(Object),
  deliveryAddress: PropTypes.instanceOf(Object),
  isEditable: PropTypes.bool,
  dealershipCode: PropTypes.string,
  dealershipAddressInfo: PropTypes.instanceOf(Object),
};

AddressReviewGroup.defaultProps = {
  isEditable: false,
  requisitionAddress: undefined,
  mailingAddress: undefined,
  deliveryAddress: undefined,
  dealershipCode: '',
  dealershipAddressInfo: undefined,
};

export default AddressReviewGroup;
