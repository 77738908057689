import React, { useMemo, useEffect } from 'react';
import {
  AFPTable,
  Pagination,
  Spinner,
  EmptyState,
} from '@gsa/afp-component-library';
import useVehicleAvailabilityState from './useVehicleAvailabilityState';
import { getTableColumns, VAL_TABLE_COLUMN_HEADERS } from './helpers';
import './VehicleAvailabilityTable.scss';
import { resetFocusToFirstElement } from '../../utilities/commons';

const VehicleAvailabilityTable = () => {
  const {
    valRows,
    valCount,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    isReset,
    setResetPagination,
    onVALDataLoading,
    AllVALDataLoading,
    printDataLoading,
  } = useVehicleAvailabilityState();

  const handlePaginationChange = (newCurPage, newPerPage) => {
    if (newPerPage !== perPage) {
      setCurrentPage(1);
      setPerPage(newPerPage);
      setResetPagination(true);
      setTimeout(() => {
        setResetPagination(false);
        resetFocusToFirstElement();
      }, 1000);
    } else {
      setCurrentPage(newCurPage);
      setPerPage(newPerPage);
      resetFocusToFirstElement();
    }
  };

  useEffect(() => {
    if (!AllVALDataLoading) {
      resetFocusToFirstElement();
    }
  }, [AllVALDataLoading]);

  const data = useMemo(() => {
    if (!valRows || !valRows.length) {
      return [];
    }
    return valRows.map((row) => getTableColumns(row));
  }, [valRows]);

  const columns = useMemo(() => VAL_TABLE_COLUMN_HEADERS, []);

  return (
    <div className="table-main-container">
      {(AllVALDataLoading || printDataLoading || onVALDataLoading) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      <AFPTable
        testId="vehicle-availability-table"
        columns={columns}
        data={data}
        onSort={(sortOrder) => {
          const [newOrderBy, newOrder] = sortOrder.split(' ');
          setOrderBy(newOrderBy.replace(/`/g, ''));
          setOrder(newOrder);
        }}
      />
      <Pagination
        buttonVariant="primary"
        boundaryCount={1}
        variant="advanced"
        itemsCount={valCount}
        currentPage={currentPage}
        itemsPerPage={perPage}
        siblingCount={1}
        isReset={isReset}
        onPageChange={handlePaginationChange}
        defaultSort={`${orderBy}, ${order}`}
      />
      {!onVALDataLoading && !valRows?.length ? (
        <EmptyState
          data-testid="val-table-no-listings"
          containerStyles="table-no-items"
          topText="No Vehicles Available"
          topTextStyles="title"
          bottomText="There are no matches for the filtered values at left."
          bottomTextStyles="description"
        />
      ) : null}
    </div>
  );
};

export default VehicleAvailabilityTable;
