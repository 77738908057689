export default function generatePointOfContactForm({
  title,
  countryCodes,
  subSection,
  ignoreIdPrefix,
  idPrefix,
}) {
  const generateId = (id) => {
    if (ignoreIdPrefix) {
      return id;
    } else if (idPrefix) {
      return `${idPrefix}.${id}`;
    } else {
      return `contact.${id}`;
    }
  }

  return {
    gap: '1rem',
    sections: [
      {
        header: title,
        element: {
          header: {
            style: subSection
              ? {
                  borderBottom: '1px solid #dfe1e2',
                  fontSize: '1rem',
                }
              : {
                  borderBottom: 'none',
                  fontSize: '1rem',
                  color: '#005ea2',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  marginBottom: '0px',
                  paddingBottom: '1rem',
                  fontWeight: '900',
                },
          },
          section: subSection
            ? []
            : {
                style: {
                  marginTop: '1rem',
                },
              },
        },
        fieldLayout: 'horizontal',
        fields: [
          {
            id: generateId('firstName'),
            label: 'First Name',
            type: 'text',
            required: true,
          },
          {
            id: generateId('lastName'),
            label: 'Last Name',
            type: 'text',
            required: true,
          },
        ],
      },
      {
        fields: [
          {
            id: generateId('email'),
            label: 'Email',
            type: 'text',
            required: true,
          },
          {
            id: generateId('phone'),
            type: 'phone',
            required: true,
            suppressErrors: {
              field: true,
              message: true,
            },
            element: {
              control: {
                countryCodes,
              },
            },
          },
          {
            id: generateId('fax'),
            type: 'phone',
            suppressErrors: {
              field: true,
              message: true,
            },
            element: {
              control: {
                countryCodes,
              },
            },
          },
        ],
      },
    ],
  };
}
