import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utilities/CompareVehicleUtils';

const UnBuildableRequiredOption = ({
  unResOpt,
  unResolvedOptionsSelectionHandler,
  selectedVendor,
}) => {
  const [selectedRequiredOption, setSelectedRequiredOption] = useState(
    unResOpt.requiredOptions.length === 1 ? unResOpt.requiredOptions[0] : null,
  );
  const handleRequiredSelection = (
    itemList,
    vendorSelected,
    unResolvedOption,
    selectedIndex,
  ) => {
    unResolvedOptionsSelectionHandler(
      itemList,
      vendorSelected,
      unResolvedOption,
    );
    setSelectedRequiredOption(unResOpt.requiredOptions[selectedIndex]);
  };

  const collisionLabel = (requiredOption) => {
    let collidedOptionsLabel = '';
    requiredOption.collidedOptions.forEach((option, index, arr) => {
      collidedOptionsLabel = `${collidedOptionsLabel} ${option.optionCode} - ${
        option.optionDescription
      } ${arr.length - 1 === index ? '' : '&'}`;
    });
    return collidedOptionsLabel;
  };

  return (
    <>
      <div className="required-section-main">
        <div className="required-section-non-build text-base-light">
          <div className="required-title">+ REQUIRED</div>
        </div>
        {unResOpt.requiredOptions.length > 1 && (
          <div className="required-sub-title">
            Choose between the following required options:
          </div>
        )}
        {unResOpt.requiredOptions.map((itemList, index) => {
          return (
            <div className="required-options-list-non-build">
              <span className="conflict-radio">
                {/* prettier-ignore */}
                <input
                  data-testid='radio-conflict-input'
                  type='radio'
                  className='radio-button'
                  name={unResOpt.optionDescription}
                  onClick={() => {handleRequiredSelection(itemList, selectedVendor, unResOpt, index)}}
                />
              </span>

              <span className="conflict-option-description">
                {collisionLabel(itemList)}
              </span>
              <span>{formatCurrency(itemList.requiredOptionPrice)}</span>
            </div>
          );
        })}
        {selectedRequiredOption &&
          (selectedRequiredOption.includedCollidedOptions.length > 0 ||
            selectedRequiredOption.excludedCollidedOptions.length > 0) && (
            <div className="modal-body-req-sub-title">
              Based on your selection of{' '}
              <span className="selected-option">
                {collisionLabel(selectedRequiredOption)}
              </span>
              , this vendor <span className="include">INCLUDES</span> and{' '}
              <span className="exclude">EXCLUDES</span> additional options
              below:
            </div>
          )}
        {selectedRequiredOption &&
          selectedRequiredOption.includedCollidedOptions &&
          selectedRequiredOption.includedCollidedOptions.length !== 0 && (
            <div className="included-options">
              <div className="includes-section">
                <div className="include-title">+ INCLUDES</div>
              </div>
              {selectedRequiredOption.includedCollidedOptions.map(
                (affectedInOption) => {
                  return (
                    <li className="option-description">
                      {affectedInOption.optionCode} -{' '}
                      {affectedInOption.optionDescription}
                    </li>
                  );
                },
              )}
            </div>
          )}
        {selectedRequiredOption &&
          selectedRequiredOption.excludedCollidedOptions &&
          selectedRequiredOption.excludedCollidedOptions.length !== 0 && (
            <div className="excluded-options">
              <div className="excludes-section">
                <div className="exclude-title">– EXCLUDES</div>
              </div>
              {selectedRequiredOption.excludedCollidedOptions.map(
                (affectedExOption) => {
                  return (
                    <li className="option-description">
                      {affectedExOption.optionCode} -{' '}
                      {affectedExOption.optionDescription}
                    </li>
                  );
                },
              )}
            </div>
          )}
      </div>
    </>
  );
};
UnBuildableRequiredOption.propTypes = {
  unResOpt: PropTypes.instanceOf(Object).isRequired,
  selectedVendor: PropTypes.string.isRequired,
  unResolvedOptionsSelectionHandler: PropTypes.func.isRequired,
};

const RenderUnBuildableOptions = ({
  unResolvedData,
  selectedVendor,
  unResolvedOptionsSelectionHandler,
}) => {
  const data =
    unResolvedData &&
    unResolvedData.filter(
      (item) => item.contractLineId === selectedVendor.toString(),
    );

  if (data && data.length) {
    return (
      <>
        {data[0].unResolvedOptions.map((unResOpt) => {
          return (
            <>
              <div className="modal-body-title">
                Based on your selection of the following option:{' '}
              </div>
              <div className="option-title">
                {' '}
                {unResOpt.optionCode} - {unResOpt.optionDescription}{' '}
              </div>

              <div className="conflict-options-non-build">
                {(unResOpt.includedOptions &&
                  unResOpt.includedOptions.length) !== 0 && (
                  <div className="included-options-non-build">
                    <div className="includes-section text-base-light">
                      <div className="include-title">+ INCLUDES</div>
                    </div>
                    {unResOpt.includedOptions.map((affectedInOption) => {
                      return (
                        <li className="option-description">
                          {affectedInOption.optionCode} -{' '}
                          {affectedInOption.optionDescription}
                        </li>
                      );
                    })}
                  </div>
                )}

                {(unResOpt.excludedOptions &&
                  unResOpt.excludedOptions.length) !== 0 && (
                  <div className="excluded-options-non-build">
                    <div className="excludes-section text-base-light">
                      <div className="exclude-title">– EXCLUDES</div>
                    </div>
                    {unResOpt.excludedOptions.map((affectedExOption) => {
                      return (
                        <li className="option-description">
                          {affectedExOption.optionCode} -{' '}
                          {affectedExOption.optionDescription}
                        </li>
                      );
                    })}
                  </div>
                )}
                <UnBuildableRequiredOption
                  unResOpt={unResOpt}
                  unResolvedOptionsSelectionHandler={
                    unResolvedOptionsSelectionHandler
                  }
                  selectedVendor={selectedVendor}
                />
              </div>
            </>
          );
        })}
      </>
    );
  }

  return null;
};

RenderUnBuildableOptions.propTypes = {
  unResolvedData: PropTypes.instanceOf(Array).isRequired,
  selectedVendor: PropTypes.string.isRequired,
  unResolvedOptionsSelectionHandler: PropTypes.func.isRequired,
};

export default RenderUnBuildableOptions;
