import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  SelectDropdown,
  Form,
  TextInput,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
} from '../non-standard-purchase/constants';
import {
  FETCH_SIGNED_URL_FOR_ORDER_MODS,
  CREATE_ORDER_MOD_ATTACHMENT,
} from '../../services/data-layer';

const UploadOrderModAttachmentModal = ({
  modificationId,
  changeSetId,
  handleAttachmentUpload,
  orderModAttachmentTypes,
}) => {
  const { appURLs } = window.AFP_CONFIG;

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const [values, setValues] = useState({
    fileType: -1,
    file: null,
    description: '',
  });

  const [fileAddErrors, setFileAddErrors] = useState(null);

  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL_FOR_ORDER_MODS);

  const [addAttachmentRecord] = useMutation(CREATE_ORDER_MOD_ATTACHMENT, {
    onCompleted: (data) => {
      handleAttachmentUpload(data);
      setShowAttachmentModal(false);
    },
  });

  const onClickAddAttachment = () => {
    setShowAttachmentModal(true);
  };

  const handleFileChange = (e) => {
    if (fileAddErrors?.file) {
      setFileAddErrors({
        ...fileAddErrors,
        file: null,
      });
    }
    setValues({ ...values, file: e });
  };

  const updateValue = (e, type) => {
    if (type === 'fileType' && fileAddErrors?.groupAssignment) {
      setFileAddErrors({
        ...fileAddErrors,
        groupAssignment: null,
      });
    }
    const updatedValues = { ...values };
    updatedValues[type] = e.target.value;

    setValues(updatedValues);
  };

  const toBeRounded = (bytes) => {
    const converted = bytes / (1024 * 1024);
    const size = converted.toFixed(2);
    return Number(size);
  };

  const handleCancel = () => {
    setShowAttachmentModal(false);
  };

  const handleSubmitAttachmentForm = async () => {
    try {
      setFileAddErrors(null);

      let errorList = {};

      if (!values.file) {
        errorList = {
          ...errorList,
          file: 'Please add a Supporting file for upload',
        };
      }
      const attachmentType = orderModAttachmentTypes.filter(
        (item) => item.type === values.fileType,
      );

      if (values.fileType === -1) {
        errorList = {
          ...errorList,
          groupAssignment: 'Please choose a valid File category',
        };
      }

      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
      } else {
        const returnSignedUrlData = await returnSignedUrl({
          variables: {
            input: {
              orderModificationId: modificationId,
              changeSetId,
              name: values?.file?.name,
              status: 1,
              type: values?.fileType,
              docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
            },
          },
        });
        const { metadataId, signedUrl } =
          returnSignedUrlData?.data?.generateSignedUrlForOrderMods;

        await axios.put(signedUrl, values.file, {
          headers: {
            'Content-Type': values.file.type,
          },
        });
        await addAttachmentRecord({
          variables: {
            input: {
              orderModificationId: modificationId,
              changeSetId,
              name: values?.file?.name,
              status: 1,
              metadataId,
              typeId: attachmentType[0]?.id,
              // docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
              signedUrl,
            },
          },
        });

        setValues({
          fileType: -1,
          file: null,
          description: '',
          id: '',
        });
        setFileAddErrors(null);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const getFileCategoryTypes = () => {
    let types = [
      {
        label: '-select-',
        value: -1,
        id: '',
      },
    ];

    if (orderModAttachmentTypes.length > 0) {
      types = [
        ...types,
        ...orderModAttachmentTypes.map((i) => ({
          label: i.description,
          value: i.type,
          id: i.id,
        })),
      ];
    }

    return types;
  };

  return (
    <>
      {!!showAttachmentModal && (
        <div className="afp-modal-overlay modal-overlay">
          <Modal
            title="Upload file"
            onClose={handleCancel}
            className="upload-attachment-modal"
            variant="large"
            actions={
              <div>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  data-testid="order-mod-attachment-upload-cancel-button"
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  data-testid="order-mod-attachment-upload-submit-button"
                  onClick={handleSubmitAttachmentForm}
                  label="Add file(s)"
                />
              </div>
            }
          >
            <Form
              name="order-attachment-upload"
              className="order-mod-attachments-upload-form"
            >
              <FileUpload
                acceptableFiles={ACCEPT_FILE_TYPES}
                data-testid="order-mod-attachment-upload-file"
                acceptableFilesLabel={ACCEPT_FILE_LABELS}
                onChange={handleFileChange}
              />
              {fileAddErrors?.file && (
                <ErrorMessage id="file-error">
                  {fileAddErrors?.file}
                </ErrorMessage>
              )}
              <Alert type="warning">
                Help prevent a privacy incident by ensuring that any supporting
                document uploaded here does not contain personally identifiable
                information (PII).
              </Alert>

              <SelectDropdown
                id="groupAssignment"
                onChange={(e) => updateValue(e, 'fileType')}
                value={values.fileType}
                label="Document category"
                data-testid="order-mod-attachment-upload-file-type"
                required
                options={getFileCategoryTypes()}
              />
              {fileAddErrors?.groupAssignment && (
                <ErrorMessage id="group-assignment-error">
                  {fileAddErrors?.groupAssignment}
                </ErrorMessage>
              )}
              <h4>Note</h4>
              <TextInput
                type="textarea"
                placeholder="Type comment here..."
                value={values.description}
                onChange={(e) => updateValue(e, 'description')}
                data-testid="order-mod-attachment-upload-description"
                maxLength={100}
                hint={`${100} characters allowed`}
              />
            </Form>
          </Modal>
        </div>
      )}
      <div className="order-mod-attachment-upload-btn">
        <Button
          type="button"
          onClick={onClickAddAttachment}
          leftIcon={{ name: 'add' }}
          label="Upload file"
          variant="outline"
          data-testid="order-mod-attachment-upload-button"
        />
      </div>
    </>
  );
};

UploadOrderModAttachmentModal.propTypes = {
  modificationId: PropTypes.string.isRequired,
  changeSetId: PropTypes.string,
  handleAttachmentUpload: PropTypes.func.isRequired,
  orderModAttachmentTypes: PropTypes.instanceOf(Array),
};

UploadOrderModAttachmentModal.defaultProps = {
  orderModAttachmentTypes: [],
  changeSetId: null,
};

export default UploadOrderModAttachmentModal;
