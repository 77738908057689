import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';
import { getUrlVar } from '../../constants/utils';

const ReturnRequisitionSuccessMessage = ({ fromApproverToCo }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const friendlyName = getUrlVar('draftName', location);
  const [enableMessage, setEnableMessage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
      navigate('/my-requisitions');
    }, 10000);
  }, []);

  return (
    <>
      {enableMessage && !fromApproverToCo && (
        <Alert type="success" slim>
          <span data-testid="return-requisition-success-message">
            You have successfully returned <strong>{friendlyName}</strong> to
            the submitter.`
          </span>
        </Alert>
      )}
      {enableMessage && fromApproverToCo && (
        <Alert type="success" slim>
          <span data-testid="return-requisition-success-message">
            You have successfully returned <strong>{friendlyName}</strong> to
            the Contracting Officer.`
          </span>
        </Alert>
      )}
    </>
  );
};

ReturnRequisitionSuccessMessage.propTypes = {
  fromApproverToCo: PropTypes.bool.isRequired,
};

export default ReturnRequisitionSuccessMessage;
