import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { AVAILABLE_EDIT_STEPS } from '../../constants';
import AttachmentDownload from '../../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const VehicleRequirementsReview = ({
  title,
  editPage,
  isEditable,
  additionalRequirements,
}) => {
  const columns = additionalRequirements.filter(
    (req) => req.attachments?.length,
  );

  return (
    <div role="tab" tabIndex="0" className="vehicle-specification-section">
      <div className="section-main-title">
        {title}
        {isEditable && (
          <Button
            variant="outline"
            onClick={() => editPage(AVAILABLE_EDIT_STEPS[0])}
            data-testid="vehicle-spec-edit-section"
            leftIcon={{ name: 'edit' }}
            label="Edit"
            size="small"
          />
        )}
      </div>
      <div>
        {columns.map((col) => (
          <div className="section-row">
            <div className="row-column">
              <div className="title" data-testid="vehicle-req-title">
                {col?.description}
              </div>
              {col?.attachments?.map((attachment) => (
                <>
                  <AttachmentDownload
                    name={attachment?.name}
                    metadataId={attachment?.metadataId}
                  />
                  <div className="title-desc" data-testid="vehicle-req-desc">
                    {attachment?.description}
                  </div>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

VehicleRequirementsReview.propTypes = {
  title: PropTypes.string.isRequired,
  editPage: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  additionalRequirements: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ).isRequired,
};

export default VehicleRequirementsReview;
