import React from 'react';
import PropTypes from 'prop-types';
import CheckedIcon from '../../assets/images/checked-icon';
import { SUBMIT_TO_APPROVER } from '../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';

const SubmitterRoundTwoApproveButton = ({ reference }) => {
  return (
    <>
      <button
        data-testid="engineer-approval-approve-button"
        type="button"
        className="co-approve-button"
        onClick={() => {
          reference.current.submitCustomerResponse();
        }}
      >
        <CheckedIcon />
        <span className="button-text">{SUBMIT_TO_APPROVER}</span>
      </button>
    </>
  );
};

SubmitterRoundTwoApproveButton.propTypes = {
  reference: PropTypes.func.isRequired,
};

export default SubmitterRoundTwoApproveButton;
