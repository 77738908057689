import React, { useContext, useEffect } from 'react';
import { TextInput } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import ErrorContext from '../../../../context/ErrorContext/ErrorContext';
import './OptionDetails.scss';
import { STEPS } from '../../constants/VehicleRequisitionConstants';
import ErrorActions from '../../../../context/ErrorContext/ErrorActions';
import FilesUpload from '../FilesUpload/FilesUpload';

const OptionDetails = () => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { dispatch: errorDispatch, hasError } = useContext(ErrorContext);
  const { taggedOptions } = state;
  const errorMessage = 'Description should not be empty';
  const renderTaggedOptionsList = () =>
    taggedOptions?.map(({ optionDescription }) => (
      <li>
        {optionDescription} <strong>(Please provide details below)</strong>
      </li>
    ));

  // validates non-empty input
  const validate = ({ target }, optionCode) => {
    const { value } = target;

    // empty input add error
    if (!value?.trim()) {
      errorDispatch({
        type: ErrorActions.ADD_ERROR,
        payload: {
          page: STEPS.OPTION_DETAILS,
          form: optionCode,
          error: `error in option code ${optionCode}`,
        },
      });
    }

    // remove error - dispatch state and update draft requisition
    if (value?.trim()) {
      errorDispatch({
        type: ErrorActions.REMOVE_ERROR,
        payload: {
          page: STEPS.OPTION_DETAILS,
          form: optionCode,
          error: `error in option code ${optionCode}`,
        },
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS_CUSTOMER_INPUT,
        payload: {
          optionCode,
          description: value,
        },
      });

      // const updatedTaggedOptions = taggedOptions?.map((option) => {
      //   const { optionCode } = option;
      //   return optionCode1 === optionCode
      //     ? { ...option, customerInput: value }
      //     : option;
      // });

      // dispatch({
      //   type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS,
      //   payload: updatedTaggedOptions,
      // });

      // if (requisitionDraftId) updateDraftRequisition(requisitionDraftId);
    }
  };

  const updateCustomerInput = ({ target }, optionCode) => {
    const { value } = target;
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS_CUSTOMER_INPUT,
      payload: {
        optionCode,
        description: value,
      },
    });

    // const updatedTaggedOptions = taggedOptions?.map((option) => {
    //   const { optionCode } = option;
    //   return optionCode1 === optionCode
    //     ? { ...option, customerInput: value }
    //     : option;
    // });

    // dispatch({
    //   type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS,
    //   payload: updatedTaggedOptions,
    // });
  };

  const initPageValidation = () => {
    taggedOptions?.forEach(({ optionCode }) => {
      errorDispatch({
        type: ErrorActions.CLEAR_ERRORS,
        payload: {
          page: STEPS.OPTION_DETAILS,
          form: optionCode,
        },
      });
    });
  };

  useEffect(() => {
    initPageValidation();
  }, []);

  return (
    <div className="option-details">
      <h2>Provide details for selected options</h2>
      <p>
        Some of your selected options require additional details. Enter the
        descriptions applicable to the options selected. You may also upload
        relevant attachments.
      </p>
      <div className=" option-details-main margin-bottom-6 padding-3 border-1px border-gray-30">
        <h3 className="text-uppercase text-primary">
          Selected Options which require details
        </h3>
        <ul className="usa-list margin-bottom-7">
          {renderTaggedOptionsList()}
        </ul>
        {taggedOptions?.map(
          ({ optionDescription, optionCode, customerInput }) => {
            return (
              <>
                <div>
                  <p>
                    {optionCode} - {optionDescription}
                  </p>
                  <div className="bg-white padding-x-5 margin-bottom-9 padding-y-205 border-1px border-gray-30">
                    <TextInput
                      className="usa-input width-full"
                      label={
                        <p className="text-bold">
                          Description of your requested option{' '}
                          <abbr
                            title="required"
                            className="usa-label--required"
                          >
                            *
                          </abbr>
                        </p>
                      }
                      value={customerInput}
                      type="textarea"
                      inputClass="usa-input maxw-full maxh-none minh-none height-card"
                      aria-describedby={`tagged-option-input-${optionCode}`}
                      characterLimit={500}
                      id={`customer-input-${optionCode}`}
                      errorMessage={
                        hasError(
                          STEPS.OPTION_DETAILS,
                          optionCode,
                          `error in option code ${optionCode}`,
                        )
                          ? errorMessage
                          : null
                      }
                      onBlur={(value) => validate(value, optionCode)}
                      onChange={(value) => {
                        updateCustomerInput(value, optionCode);
                      }}
                    />

                    <FilesUpload
                      optionCode={optionCode}
                      page="OPTION_DETAILS"
                    />
                  </div>
                </div>
              </>
            );
          },
        )}
      </div>
    </div>
  );
};

export default OptionDetails;
