import { useContext } from 'react';
import { MyRequisitionsContext } from './MyRequisitionsProvider';

export default function useMyRequisitionsState() {
  const context = useContext(MyRequisitionsContext);
  if (!context) {
    throw new Error(
      'useMyRequisitionsState can only be used within MyRequisitionsProvider',
    );
  }
  return context;
}
