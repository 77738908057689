import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import { addressFormHasChanges } from '../../../UpdateOrderModHelpers';

export default function generateAddressComponentChangeHandler({
  setSameAsRequisition,
  setOrderChanges,
  section,
  formGeneratorRef,
}) {
  return async function handleChange(context, changedFieldId) {
    const { setError, clearErrors } = context;
    const values = context.getValues();

    if (changedFieldId === `${section.id}-sameAsRequisitioningAddress`) {
      const newValue = values[`${section.id}-sameAsRequisitioningAddress`];
      setSameAsRequisition(newValue);
    }

    const formAddress = {
      ...values.address,
      ...values.militaryAddress,
      zipcode: values.zipcode,
      isMilitary: values.isMilitary,
    };

    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState[section.addressKey]) newState[section.addressKey] = {};
      if (!newState[section.contactKey]) newState[section.contactKey] = {};

      newState[section.addressKey] = {
        ...values.address,
        ...values.militaryAddress,
        zipcode: values.zipcode,
        isMilitary: values.isMilitary,
      };
      newState[section.contactKey] = values.contact;

      return newState;
    });

    if (addressFormHasChanges(section, formAddress, values.contact)) {
      const { errors } = await formGeneratorRef.current.validate(
        values,
        {},
        {},
        true,
      );

      applyFormErrorsRecursively(changedFieldId, errors, setError, clearErrors);
    } else {
      clearErrors();
    }
  };
}
