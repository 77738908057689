import { useMutation } from '@apollo/client';
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilRefresher_UNSTABLE } from 'recoil';
import {
  ASSIGN_ENGINEER_TO_REQUISITION_CART_BY_ID,
  CANCEL_REQUISITION,
  RENAME_REQUISITION,
  REOPEN_REQUISITION,
} from './queries';
import { filterSelector } from './storeHelper';
import useLocalState from '../../hooks/useLocalState';
import {
  PLACE_REQUISITION_ON_HOLD,
  RESUME_ENGINEERING_REVIEW,
} from '../../services/data-layer';

export const MyRequisitionsContext = createContext({});

export default function MyRequisitionsProvider({ children }) {
  // confirmation
  const [
    showCancelRequisitionSuccessMessage,
    setShowCancelRequisitionSuccessMessage,
  ] = useState(false);
  const [
    showRenameRequisitionSuccessMessage,
    setShowRenameRequisitionSuccessMessage,
  ] = useState(false);
  const [updatedRequisition, setUpdatedRequisition] = useState(null);

  // action modal controls
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPutOnHoldModal, setShowPutOnHoldModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState(null);
  const [newRequisitionName, setNewRequisitionNameState] = useState('');
  const [requisitionNameError, setRequisitionNameError] = useState(null);
  const [showReopenModal, toggleShowReopenModal] = useState(false);
  const [cancelRequisitionComments, setCancelRequisitionComments] =
    useState('');
  const [requsitionAction, setRequsitionAction] =
    useLocalState('requsitionAction');

  const refreshSearch = useRecoilRefresher_UNSTABLE(filterSelector);

  const [cancelRequisitionCommentError, setCancelRequisitionCommentError] =
    useState(null);

  const handleCancelSuccess = (sel) => {
    setUpdatedRequisition(sel);
    setShowCancelRequisitionSuccessMessage(true);
    setTimeout(() => {
      setShowCancelRequisitionSuccessMessage(false);
      setUpdatedRequisition(null);
    }, 5000);
  };

  const handleRenameSuccess = (sel) => {
    setUpdatedRequisition(sel);
    setShowRenameRequisitionSuccessMessage(true);
    setTimeout(() => {
      setShowRenameRequisitionSuccessMessage(false);
      setUpdatedRequisition(null);
    }, 5000);
  };

  const toggleShowEditModal = (showValue) => {
    if (showValue) {
      setRequisitionNameError(null);
    }

    setShowEditModal(showValue);
  };

  const toggleShowPutOnHoldModal = () => {
    setShowPutOnHoldModal(!showPutOnHoldModal);
  };

  const toggleShowCancelModal = (showValue) => {
    if (showValue) {
      setCancelRequisitionCommentError(null);
    }
    setShowCancelModal(showValue);
  };

  const [renameRequisitionMutation] = useMutation(RENAME_REQUISITION, {
    onCompleted: () => {
      toggleShowEditModal(false);
      refreshSearch();
      handleRenameSuccess({
        ...selectedRequisition.original,
        name: newRequisitionName,
      });
    },
    onError: (err) => {
      setRequisitionNameError(err.message);
    },
  });

  const [putRequisitionOnHoldMutation] = useMutation(
    PLACE_REQUISITION_ON_HOLD,
    {
      onCompleted: () => {
        setShowPutOnHoldModal(false);
        refreshSearch();
      },
    },
  );

  const [resumeEngineerReviewMutation] = useMutation(
    RESUME_ENGINEERING_REVIEW,
    {
      onCompleted: () => {
        refreshSearch();
      },
    },
  );

  const [cancelRequisitionMutation] = useMutation(CANCEL_REQUISITION, {
    onCompleted: () => {
      toggleShowCancelModal(false);
      refreshSearch();
      handleCancelSuccess(selectedRequisition.original);
    },
  });

  const [reopenRequisitionMutation] = useMutation(REOPEN_REQUISITION, {
    onCompleted: () => {
      toggleShowReopenModal(false);
      refreshSearch();
    },
  });

  const [assignEngineerToRequisitionCartByIdMutation] = useMutation(
    ASSIGN_ENGINEER_TO_REQUISITION_CART_BY_ID,
    {
      onCompleted: () => {
        refreshSearch();
      },
      onError: () => {
        refreshSearch();
      },
    },
  );

  const renameRequisition = () => {
    const { requisitionId } = selectedRequisition.original;
    if (requisitionId && newRequisitionName) {
      setRequisitionNameError(null); // clear any feedback

      if (newRequisitionName === selectedRequisition.original.name) {
        setRequisitionNameError(
          `Enter a new name for Requisition ${selectedRequisition.original.name}`,
        );
      } else if (newRequisitionName.length > 50) {
        setRequisitionNameError('This name exceeds the 50 character limit.');
      } else {
        renameRequisitionMutation({
          variables: {
            RequisitionId: requisitionId,
            NewName: newRequisitionName,
          },
        });
      }
    }
  };

  const putRequisitionOnHold = (requisitionId, comment) => {
    putRequisitionOnHoldMutation({
      variables: {
        requisitionId,
        comment,
      },
    });
    // }
  };

  const resumeEngineerReview = (requisitionId) => {
    resumeEngineerReviewMutation({
      variables: {
        requisitionId,
      },
    });
    // }
  };

  const setNewRequisitionName = (name) => {
    setNewRequisitionNameState(name);
    if (name.length > 50) {
      setRequisitionNameError('This name exceeds the 50 character limit.');
    } else {
      setRequisitionNameError(null);
    }
  };

  const cancelRequisition = () => {
    const { requisitionId } = selectedRequisition.original;
    if (requisitionId) {
      if (cancelRequisitionComments.trim().length < 1) {
        setCancelRequisitionCommentError(
          'Please provide comments for cancellation',
        );
      } else {
        setCancelRequisitionCommentError(null);
        cancelRequisitionMutation({
          variables: {
            RequisitionId: requisitionId,
          },
        });
        setCancelRequisitionComments('');
      }
    }
  };
  const reopenRequisition = () => {
    const { requisitionId } = selectedRequisition.original;
    if (requisitionId) {
      reopenRequisitionMutation({
        variables: {
          RequisitionId: requisitionId,
        },
      });
    }
  };

  useEffect(() => {
    const { requisition, action, comment } = requsitionAction || {};

    if (requisition?.requisitionId && action) {
      if (action === 'cancelRequisition') {
        setSelectedRequisition({
          original: requisition,
        });
        setCancelRequisitionComments(comment);
        if (selectedRequisition?.original) {
          cancelRequisition();
          setRequsitionAction(null);
        }
      }
    }
  }, [requsitionAction, selectedRequisition?.original]);

  const assignEngineerToRequisitionCartById = (assignedEngineerId) => {
    const selectedReq = assignedEngineerId;
    if (!selectedReq?.original) {
      return;
    }

    if (!selectedReq?.original?.requisitionId) {
      return;
    }

    assignEngineerToRequisitionCartByIdMutation({
      variables: {
        requisitionId: selectedReq.original.requisitionId,
        assignedEngineerId,
      },
    });
  };

  const value = {
    // Filters
    // Modal Controls
    showEditModal,
    toggleShowEditModal,
    showPutOnHoldModal,
    setShowPutOnHoldModal,
    toggleShowPutOnHoldModal,
    putRequisitionOnHold,
    resumeEngineerReview,
    showCancelModal,
    toggleShowCancelModal,
    showReopenModal,
    toggleShowReopenModal,
    selectedRequisition,
    setSelectedRequisition,
    renameRequisition,
    newRequisitionName,
    setNewRequisitionName,
    requisitionNameError,
    setRequisitionNameError,
    cancelRequisitionComments,
    setCancelRequisitionComments,
    cancelRequisitionCommentError,
    setCancelRequisitionCommentError,
    cancelRequisition,
    reopenRequisition,
    showCancelRequisitionSuccessMessage,
    showRenameRequisitionSuccessMessage,
    updatedRequisition,
    assignEngineerToRequisitionCartById,
  };

  return (
    <MyRequisitionsContext.Provider value={value}>
      {children}
    </MyRequisitionsContext.Provider>
  );
}

MyRequisitionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
