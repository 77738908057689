import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@gsa/afp-component-library';
import classNames from 'classnames';
import './QuantityInput.scss';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

const QuantityInput = ({
  quantity,
  setQuantity,
  setQuantityHasError,
  readOnly = false,
}) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { quantityHasError } = state;
  const [errorText, setErrorText] = useState(null);
  const vehicleQuantityRef = useRef();

  const validQuantity = (value) => {
    return value >= 1 && value <= 999;
  };

  const handleValid = () => {
    setQuantityHasError(false);
    setErrorText(null);
  };

  const handleInvalid = () => {
    setQuantityHasError(true);
    setErrorText('Quantity can only be between 1 and 999');
  };

  const KEYCODE_PERIOD = 190;
  const KEYCODE_DASH = 189;
  const KEYCODE_PLUS = 187;
  const KEYCODE_SUBSTRACT = 109;
  const KEYCODE_DECIMAL_POINT = 110;

  const handleKeyDown = (event) => {
    const { keyCode } = event;
    switch (keyCode) {
      case KEYCODE_PERIOD:
      case KEYCODE_DASH:
      case KEYCODE_PLUS:
      case KEYCODE_SUBSTRACT:
      case KEYCODE_DECIMAL_POINT:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const onChange = (event) => {
    const val = event?.target?.value;
    if (val === '') {
      setQuantityHasError(true);
      return setQuantity(val);
    }
    setQuantityHasError(false);
    // eslint-disable-next-line no-unused-expressions
    return validQuantity(val) && setQuantity(val);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validQuantity(quantity) ? handleValid() : handleInvalid();
  }, [quantity]);

  useEffect(() => {
    if (vehicleQuantityRef.current)
      dispatch({
        type: VehicleRequisitionContextActions.SET_FORM_ERROR_FOCUS,
        payload: { vehicleQuantity: vehicleQuantityRef },
      });
  }, [vehicleQuantityRef]);

  return (
    <div className="store-compare-quantity-section">
      <div
        className={classNames('quantity-input', {
          error: errorText,
        })}
      >
        <TextInput
          disabled={readOnly}
          data-testid="quantity-input-test-id"
          label={
            readOnly ? 'Quantity of vehicles ' : 'Enter quantity of vehicles'
          }
          name="vehicle-quantity"
          type="text"
          inputRef={vehicleQuantityRef}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          value={quantity}
          errorMessage={errorText}
          required={!readOnly}
          showLabelError={false}
          errorMessagePlacementEnd="true"
          aria-invalid={quantityHasError ? 'true' : 'false'}
        />
      </div>
    </div>
  );
};

QuantityInput.propTypes = {
  quantity: PropTypes.string.isRequired,
  setQuantity: PropTypes.func.isRequired,
  setQuantityHasError: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

QuantityInput.defaultProps = {
  readOnly: false,
};

export default QuantityInput;
