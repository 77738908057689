import { useContext } from 'react';
import { OrderGuideContext } from './OrderGuideProvider';

export default function useOrderGuideState() {
  const context = useContext(OrderGuideContext);
  if (!context) {
    throw new Error(
      'useMyOrdersState can only be used within OrderGuideProvider',
    );
  }
  return context;
}
