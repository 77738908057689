/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { TabSet } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import ColorSelection from './components/ColorSelection/ColorSelection';
import OptionDetails from './components/OptionDetails/OptionDetails';
import ReviewVehicleBuild from './components/ReviewVehicleBuild/ReviewVehicleBuild';
import AgencyInformation from './components/AgencyInformation/AgencyInformation';
import DeliveryAddress from './components/DeliveryAddress/DeliveryAddress';
import ReviewAndSubmit from './components/ReviewAndSubmit/ReviewAndSubmit';
import NonLowBidReason from './components/NonLowBidReason/NonLowBidReason';
import { STEPS } from './constants/VehicleRequisitionConstants';
import { getStepTitle } from './utils/RequisitionStepsUtils';
import MultipleAdditionalRequirements from '../../components/MultipleAdditionalRequirements/MultipleAdditionalRequirements';
import AttachmentTable from '../ReviewDetails/Attachment/AttachmentTable/AttachmentTable';
import RequisitionActivitiesTab from '../ReviewDetails/tabs/RequisitionActivitiesTab';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import AreqReviewVehicleBuild from '../../components/Areq/AreqReviewVehicleBuild/AreqReviewVehicleBuild';

const CurrentStep = ({
  step,
  clickPaginationHandler,
  setPreventNavigation,
  preventNavigation,
  handleStepper,
  indicatorSteps,
}) => {
  const { state, requisitionDraftId, dispatch } = useContext(
    VehicleRequisitionContext,
  );
  const {
    selectedContract,
    selectedContractCostBreakDown,
    selectedOptionsForPrice,
    reviewSubmitTab,
    isAreq,
  } = state;
  useTitle(getStepTitle(step));

  switch (step) {
    case STEPS.AREQ_INFORMATION:
      return (
        <MultipleAdditionalRequirements
          handleStepper={handleStepper}
          indicatorSteps={indicatorSteps}
        />
      );
    case STEPS.PAINT_GRAPHICS:
      return (
        <ColorSelection
          requisitionDraftId={requisitionDraftId}
          setPreventNavigation={setPreventNavigation}
          preventNavigation={preventNavigation}
        />
      );
    case STEPS.OPTION_DETAILS:
      return <OptionDetails />;
    case STEPS.REVIEW_VEHICLE_BUILD:
      if (isAreq) {
        return <AreqReviewVehicleBuild />;
      }

      return (
        <ReviewVehicleBuild
          selectedContractCostBreakDown={selectedContractCostBreakDown}
        />
      );
    case STEPS.NON_LOW_BID_REASON:
      return <NonLowBidReason
        setPreventNavigation={setPreventNavigation}
        preventNavigation={preventNavigation}
      />;
    case STEPS.AGENCY_INFORMATION:
      return (
        <AgencyInformation
          requisitionDraftId={requisitionDraftId}
          selectedContract={selectedContract}
          selectedOptionsForPrice={selectedOptionsForPrice}
          setPreventNavigation={setPreventNavigation}
          preventNavigation={preventNavigation}
          handlePageNavigation={clickPaginationHandler}
        />
      );
    case STEPS.DELIVERY_ADDRESS:
      return (
        <DeliveryAddress
          handlePageNavigation={clickPaginationHandler}
          requisitionDraftId={requisitionDraftId}
          handleStepper={handleStepper}
        />
      );
    case STEPS.REVIEW_SUBMIT:
      if (state?.draftRequisition) {
        const tabs = [
          {
            heading: 'Review summary',
            tabSelectedWhenOpen: reviewSubmitTab === 'Review summary',
            content: isAreq ? (
              <AreqReviewVehicleBuild />
            ) : (
              <ReviewVehicleBuild step={STEPS.REVIEW_SUBMIT} />
            ),
          },
        ];

        if (
          state?.draftRequisition?.requisitionStatus === 'RETURNED' ||
          !isAreq
        ) {
          tabs.push({
            heading: 'Activity',
            tabSelectedWhenOpen: reviewSubmitTab === 'Activity',
            content: (
              <RequisitionActivitiesTab requisitionId={requisitionDraftId} />
            ),
          });
        }

        tabs.push({
          heading: 'Attachments',
          tabSelectedWhenOpen: reviewSubmitTab === 'Attachments',
          content: (
            <AttachmentTable
              requisitionId={requisitionDraftId}
              uploadAttachment
            />
          ),
        });

        const onTabSelect = (tabIndex) => {
          const { heading } = tabs[tabIndex];
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_REVIEW_SUBMIT_TAB,
            payload: heading,
          });
        };

        return <TabSet tabs={tabs} onSelect={onTabSelect} />;
      }

      return <ReviewAndSubmit />;

    default:
      return null;
  }
};

CurrentStep.propTypes = {
  step: PropTypes.string.isRequired,
  clickPaginationHandler: PropTypes.func.isRequired,
  notifyUserRef: PropTypes.shape({}).isRequired,
  handleStepper: PropTypes.func.isRequired,
  indicatorSteps: PropTypes.array.isRequired,
};

const RequisitionSteps = ({
  clickPaginationHandler,
  setPreventNavigation,
  preventNavigation,
  handleStepper,
  indicatorSteps,
}) => {
  const [key, setKey] = useState();
  const { state } = useContext(VehicleRequisitionContext);
  const { currentStep } = state;
  useEffect(() => {
    setKey(currentStep?.key);
  }, [currentStep?.key]);

  return (
    <CurrentStep
      step={`${key}`}
      clickPaginationHandler={clickPaginationHandler}
      setPreventNavigation={setPreventNavigation}
      preventNavigation={preventNavigation}
      handleStepper={handleStepper}
      indicatorSteps={indicatorSteps}
    />
  );
};

RequisitionSteps.propTypes = {
  clickPaginationHandler: PropTypes.func.isRequired,
  notifyUserRef: PropTypes.shape({}).isRequired,
  handleStepper: PropTypes.func.isRequired,
  indicatorSteps: PropTypes.array.isRequired,
};

export default RequisitionSteps;
