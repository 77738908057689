import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

export function handleAgencyInformationSubmit({
  dispatch,
  handlePageNavigation,
  selectedContractAgencyInformation,
  requisitionDraftId,
  updateDraftRequisition,
  costBreakdown,
  clearSystemAlert,
}) {
  return (data) => {
    const agencyInformation = {
      ...selectedContractAgencyInformation,
      agency: data.agency.split(' - ')[0],
      agencyName: data.agency.split(' - ')[1],
      bureau: data.bureau.split(' - ')[0],
      bureauName: data.bureau.split(' - ')[1],
      office: data.office,
      groupAssignment: data.office,

      agencyOrderNumber: data.agencyOrderNumber,
      requisitionBOAC: data.requisition.boac,
      requisitionJulian: data.requisition.julian,
      requisitionSerialNumber: data.requisition.serialNumber,

      signalCode: data.signalCode,
      serviceCode: data.serviceCode,
      fundCode: data.fundCode,
      signalSupplementaryAddress: data.supplementaryAddressCode,

      // Treasury Account Number
      treasuryAccountSymbol: data.treasuryAccountSymbol,

      additionalAreqFunds: data.additionalAreqFunds,
      finAdditionalFundsPerUnit: data.additionalAreqFunds,

      // CONTACT INFORMATION
      firstName: data.contact.firstName,
      lastName: data.contact.lastName,
      email: data.contact.email,
      phoneCountryCallingCode: data.contact.phone.countryCode,
      phoneNumber: data.contact.phone.number,
      phoneExtension: data.contact.phone.extension,
      faxCountryCallingCode: data.contact.fax.countryCode,
      faxNumber: data.contact.fax.number,
      faxExtension: data.contact.fax.extension,

      agencyFinancialData: data.agencyFinancialData,

      unitPrice: costBreakdown?.baseAndOptionsPrice,
      unitPriceWithAreqs: data.unitPriceWithAreqs,

      // DOD information
      transportationControlNumber: data.dod?.transportationControlNumber,
      markForInformation: data.dod?.markForInformation,
      nationalStockNumber: data.dod?.nationalStockNumber,
      registrationNumber: data.dod?.registrationNumber,
      accountingClassificationReferenceNumber:
        data.dod?.accountingClassificationReferenceNumber,
    };

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_CONTRACT_AGENCY_INFORMATION,
      payload: agencyInformation,
    });

    updateDraftRequisition(requisitionDraftId);
    handlePageNavigation('continue');
    clearSystemAlert();
  };
}
