import { useContext } from 'react';
import { BulkOrderModsContext } from './BulkOrderModProvider';

export default function useBulkOrderModsState() {
  const context = useContext(BulkOrderModsContext);
  if (!context) {
    throw new Error(
      'useOrderModssState can only be used within OrderModsProvider',
    );
  }
  return context;
}
