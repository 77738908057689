import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import { Button } from '@gsa/afp-component-library';

import {
  GENERATE_READ_SIGNED_URL,
  GET_DOC_METADATA,
} from '../../../../services/data-layer';

export default function AttachmentDownload({ name, metadataId }) {
  const [docMimeType, setDocMimeType] = useState('');
  const [fileName, setFileName] = useState('');

  const [getGeneratedSignedUrl] = useLazyQuery(GENERATE_READ_SIGNED_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      const response = await fetch(data.generateReadSignedURL, {
        method: 'GET',
        headers: { 'Content-Type': docMimeType },
      });
      const blob = await response.blob();
      fileDownload(blob, fileName);
    },
  });

  const [getDocMetaData] = useLazyQuery(GET_DOC_METADATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setDocMimeType(data?.getDocMetadata?.fileMimeType);
      setFileName(data?.getDocMetadata?.name);
      getGeneratedSignedUrl({
        variables: {
          fileType: data?.getDocMetadata?.fileMimeType,
          fileKey: data?.getDocMetadata?.fileLocation,
        },
      });
    },
  });

  const download = () => {
    getDocMetaData({
      variables: {
        id: metadataId,
      },
    });
  };

  if (!metadataId) {
    return <>Invalid Metadata ID</>;
  }

  return (
    <Button
      data-testid="download-button"
      variant="unstyled"
      onClick={() => download()}
      label={name}
    />
  );
}

AttachmentDownload.propTypes = {
  name: PropTypes.string.isRequired,
  metadataId: PropTypes.string.isRequired,
};
