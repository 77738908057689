import {USDateStrToUTC} from "../../utilities/commonUtils.jsx";

const generateFilterCondition = (key, value, operator) => {
  if (!key || !value || !value.length) {
    return [];
  }
  return [
    {
      operator: operator || '$in',
      key,
      value,
    },
  ];
};

const generateBetweenFilterCondition = (key, value, operator) => {
  if (!key || !value || !value?.value?.beginning || !value?.value?.end) {
    return [];
  }
  // eslint-disable-next-line no-case-declarations
  const startDate = USDateStrToUTC(value?.value?.beginning);
  // eslint-disable-next-line no-case-declarations
  const endDate = USDateStrToUTC(value?.value?.end, true);
  return [
    {
      operator: operator || '$between',
      key,
      value: [startDate, endDate],
    },
  ];
};

const generateRequisitionTypeFilter = (values) => {
  return {
    operator: '$literal',
    key: 'requisitionType',
    value: `((requisition_type & ${values.join(') OR (requisition_type & ')}))`,
  };
};

export const getRequisitionFilters = ({
  vehicleTypes,
  requisitionStatuses,
  offices,
  bureaus,
  agencies,
  activityAddress,
  julianDate,
  serialNumber,
  requisitionNumber,
  requisitionTypes,
  assignees,
  requisitionTransactionTypes,
  closeOutDate,
  leasingOffices,
  leasingAgencies,
  leasingBureaus,
  zone,
  fmc,
  fsr,
  boac,
}) => {
  return [
    // Add condition for status
    ...generateFilterCondition('requisition_status', requisitionStatuses),
    // // Add condition for type
    ...(requisitionTypes.length
      ? [...generateFilterCondition('requisition_type', requisitionTypes)]
      : []),
    // Add condition for vehicleType
    ...generateFilterCondition(
      'vehicle_type_code',
      vehicleTypes.map((type) => type.code),
    ),
    ...agencies,
    ...bureaus,
    ...offices,
    ...leasingOffices,
    ...leasingAgencies,
    ...leasingBureaus,
    ...generateFilterCondition('zone_id', zone, '$exact'),
    ...generateFilterCondition('fmc_id', fmc, '$exact'),
    ...generateFilterCondition('fsr_user_email_address', fsr, '$like'),
    ...generateFilterCondition('boac', boac, '$like'),
    ...generateFilterCondition('requisition_number', requisitionNumber, '$like'),
    ...generateFilterCondition('assigned_engineer_id', assignees),
    ...generateFilterCondition('transaction_type', requisitionTransactionTypes),
    ...generateBetweenFilterCondition('order_end_date', closeOutDate)
  ];
};

export const OrderByMap = {
  number: 'requisition_number',
  updatedAt: 'updated_at',
  requisitionStatus: 'requisition_status',
  name: 'friendly_name',
  status: 'status',
};
