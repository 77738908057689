const exeMagicBytes = ['4d5a', '7573746172'];

export async function isExecFile(file) {
  if (file.type === 'application/x-msdownload') {
    return Promise.resolve(true);
  }
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.addEventListener('loadend', (e) => {
      const header = [...new Uint8Array(e.target.result)]
        .map((b) => {
          return b.toString(16);
        })
        .join('');
      resolve(
        exeMagicBytes.some((bytes) => {
          return header.startsWith(bytes);
        }),
      );
    });

    try {
      reader.readAsArrayBuffer(file.slice(0, 5));
    } catch (error) {
      reject(error);
    }
  });
}
