import { gql } from '@apollo/client';

export const GET_REQUISITIONS = gql`
  query SearchRequisition(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $filters: [ExtendedFilteredField!]!
  ) {
    searchRequisition(
      offset: $offset
      limit: $limit
      order: $order
      filters: [{ operator: "$and", conditions: $filters }]
    ) {
      count
      rows {
        requisitionId
        friendlyName
        requisitionStatus
        requisitionType
        createdAt
        updatedAt
        standardItemCode
        modelCode
        scheduleLine
        totalSellingPrice
        quantity
        vehicleTypeCode
        boac
        julianDate
        serialNumber
        vehicles {
          makeColorCode
          makeColorName
          makeColorPriceToCustomer
          makeColorPriceToGsa
        }
        createdByInfo {
          id
          firstName
          lastName
        }
        updatedByInfo {
          id
          firstName
          lastName
        }
        agencyInfo {
          id
          name
        }
        bureauInfo {
          id
          name
        }
        officeInfo {
          officeCode
          agencyCode
          bureauCode
          officeName
        }
        allowedActions
        assignedEngineer {
          id
          firstName
          lastName
        }
        transactionType
        requisitionNumber
      }
      hasMore
    }
  }
`;

export const GET_BUREAU = gql`
  query GetBureau($agencyCode: String) {
    getBureaus(agencyCode: $agencyCode) {
      shortName
      name
      id
    }
  }
`;

export const GET_REQUISITIONS_STATUSES = gql`
  query GetRequisitionStatuses {
    getRequisitionStatuses
  }
`;

export const GET_OFFICE_BY_AGENCY = gql`
  query GetOfficesNextByAgency($agencyCode: String) {
    getOfficesNextByAgency(agencyCode: $agencyCode) {
      officeName
      officeCode
    }
  }
`;

export const RENAME_REQUISITION = gql`
  mutation RenameRequistion($RequisitionId: String!, $NewName: String!) {
    renameRequisition(requisitionId: $RequisitionId, newName: $NewName)
  }
`;

export const CANCEL_REQUISITION = gql`
  mutation CancelRequistion($RequisitionId: String!) {
    cancelRequisition(requisitionId: $RequisitionId)
  }
`;
export const REOPEN_REQUISITION = gql`
  mutation ReopenRequisition($RequisitionId: String!) {
    reopenRequisition(requisitionId: $RequisitionId)
  }
`;

export const GET_ASSIGNED_ENGINEERS = gql`
  query {
    getStandardsVehicleGroupPocs {
      rows {
        standardVgPocId
        standardCodeId
        pocType
        name
        email
        standardsCode {
          id
        }
      }
    }
  }
`;

export const ASSIGN_ENGINEER_TO_REQUISITION_CART_BY_ID = gql`
  mutation AssignEngineerToRequisitionCartById($requisitionId: String!) {
    assignEngineerToRequisitionCartById(requisitionId: $requisitionId) {
      requisitionId
      assignedEngineerId
      assignedEngineer {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_REQUISITIONS_EXTENDED= gql`
 query SearchRequisitionExtended(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $filters: [ExtendedFilteredField!]!
  ) {
    searchRequisitionExtended(
      offset: $offset
      limit: $limit
      order: $order
      filters: [{ operator: "$and", conditions: $filters }]
    ) {
      count
      rows {
        requisitionId
        boac
        createdAt
        updatedAt
        friendlyName
        modelCode
        quantity
        requisitionStatus
        requisitionNumber
        requisitionType
        standardItemCode
        totalSellingPrice
        transactionType
        agencyInfoName
        bureauInfoName
        allowedActions
        officeInfoName
        createdUserInfoFirstName
        createdUserInfoLastName
        createdUserInfoEmail
        assignedEngineerFirstName
        assignedEngineerLastName
        assignedEngineerEmail
        updatedUserInfoFirstName
        updatedUserInfoLastName
        updatedUserInfoEmail
      }
    }
  }`;
