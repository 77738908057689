import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import { Label, DatePicker } from '@gsa/afp-component-library';
import { useStoreReportsContext } from '../useStoreReportsContext';

const DateRange = () => {
  const { isOrderReport, isRequisitionReport } = useStoreReportsContext();

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const startDate = watch('startDate');

  function maxDateStr(d) {
    if (!d.isValid()) {
      return '';
    }
    return d.subtract(-1, 'year').isAfter(moment())
      ? moment().format('YYYY-MM-DD')
      : d.format('YYYY-MM-DD');
  }

  const minEndDateStr = moment(startDate).isValid()
    ? moment(startDate).format('YYYY-MM-DD')
    : '';
  const maxEndDateStr = maxDateStr(moment(startDate));

  function getErrorMessage(type) {
    if (type === 'required') {
      return 'This is a required field';
    }
    return null;
  }

  function recordType() {
    if (isRequisitionReport()) {
      return 'vehicle requisition';
    }
    if (isOrderReport()) {
      return 'order';
    }

    return 'record';
  }

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-8">
          <header className="display-flex-column flex-align-start flex-justify flex-direction-column">
            <h2 className="margin-0 margin-top-5">Date selection</h2>
            <p className="margin-0">
              Choose a date range for the report. This date range is based on
              the date the {recordType()} was created. The period from the
              starting date to the ending date cannot exceed 1 year (365 days).
            </p>
          </header>
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-2">
          <Controller
            name="startDate"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <>
                  <DatePicker
                    name={name}
                    id="startDate"
                    label={
                      <Label required className="text-bold">
                        Starting date
                      </Label>
                    }
                    hint="mm/dd/yyyy"
                    minDate={moment('01/01/2014').format('YYYY-MM-DD')}
                    maxDate={moment().format('YYYY-MM-DD')}
                    onChange={(val) => {
                      onChange(val ?? '');
                    }}
                    defaultValue={value || ''}
                    errorMessage={getErrorMessage(errors?.startDate?.type)}
                    aria-invalid={errors && errors.startDate ? 'true' : 'false'}
                  />
                </>
              );
            }}
          />
        </div>
        <div className="grid-col-2 margin-left-3">
          <Controller
            name="endDate"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <>
                  <DatePicker
                    name={name}
                    id="endDate"
                    label={
                      <Label required className="text-bold">
                        Ending date
                      </Label>
                    }
                    hint="mm/dd/yyyy"
                    minDate={minEndDateStr}
                    disabled={!startDate}
                    maxDate={maxEndDateStr}
                    onChange={(val) => {
                      onChange(val ?? '');
                    }}
                    defaultValue={value || ''}
                    errorMessage={getErrorMessage(errors?.endDate?.type)}
                    aria-invalid={errors && errors.endDate ? 'true' : 'false'}
                  />
                </>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DateRange;
