import { Icon } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import './ReviewSummaryMessage.scss';
import { GET_REQUISITION_ACTIVITIES_BY_TYPE } from '../../../services/data-layer';

const ReviewSummaryMessage = ({ requisitionId }) => {
  const [requisitionActivities, setRequisitionActivities] = useState([]);

  const { currentUser } = useCurrentUser();
  const [
    getRequisitionActivitiesData,
    {
      data: requisitionActivitiesData,
      loading: requisitionActivitiesDataLoading,
    },
  ] = useLazyQuery(GET_REQUISITION_ACTIVITIES_BY_TYPE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getRequisitionActivitiesData({
      variables: {
        requisitionId,
        offset: 0,
        limit: 1,
        order: [['createdAt', 'DESC']],
        type: 'REQUISITION_CREATED',
      },
    });
  }, [requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivitiesByType) {
      const { rows } = requisitionActivitiesData.getRequisitionActivitiesByType;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivitiesByType) {
      const { rows } = requisitionActivitiesData.getRequisitionActivitiesByType;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  const RequisitionResubmitMessage = ({ requisitionActivity }) => {
    const { comment, createdUserInfo } = requisitionActivity;

    if (!createdUserInfo || !comment) {
      return <></>;
    }

    const { fullName: userName, email, id: userId } = createdUserInfo;
    return (
      <>
        <div
          className="resubmit-comment-body"
          data-testid="resubmit-comment-body"
        >
          <div className="fa-svg_user w-embed">
            <Icon iconName="account_circle" className="usa-icon--size-1" />
          </div>
          <span className="resubmit-comment-body-title">
            {currentUser.id !== userId ? (
              <a className="user-a-tag" href={`mailto:${email}`}>
                {userName}
              </a>
            ) : (
              userName
            )}{' '}
            has commented:
          </span>
          <div
            className="resubmit-comment-body-message"
            data-testid="resubmit-comment-message"
          >
            <span>{comment}</span>
          </div>
        </div>
      </>
    );
  };
  RequisitionResubmitMessage.propTypes = {
    requisitionActivity: PropTypes.instanceOf(Array).isRequired,
  };
  return (
    <>
      {!requisitionActivitiesDataLoading && (
        <div className="resubmit-comment-section">
          <div className="resubmit-comment">
            <span
              className="resubmit-comment-title"
              data-testid="resubmit-comment-title"
            >
              This requisition has been resubmitted for your approval
            </span>
            {requisitionActivities &&
              requisitionActivities.map((requisitionActivity) => (
                <RequisitionResubmitMessage
                  requisitionActivity={requisitionActivity}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

ReviewSummaryMessage.propTypes = {
  requisitionId: PropTypes.string.isRequired,
};

export default ReviewSummaryMessage;
