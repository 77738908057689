export const getBasePrice = (orderData) => {
  const basePrice = orderData?.orderVehicles[0]?.basePriceToGsa;
  return {
    basePrice,
    basePriceWithSurcharge: basePrice * orderData.purchaseRate,
  };
};

export const colorDisplay = (vehicle) => {
  const { makeColorCode, makeColorName } = vehicle || {};

  if (makeColorCode && makeColorName) {
    return `${makeColorCode} - ${makeColorName}`;
  }

  if (makeColorCode) return makeColorCode;

  return makeColorName;
};
