import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import useVehicleAvailabilityState from '../useVehicleAvailabilityState';
import './VehicleAvailabilityFilters.scss';

const { FilterPanel: ComponentFilterPanel } = FilterPanel;

const VehicleAvailabilityFilters = () => {
  const VehicleTypeOption = {
    label: 'standardItemSubCategoryName',
    value: 'standardItemSubCategoryCode',
    key: 'vehicleTypeFilter',
  };
  const StandardItemOption = {
    label: 'standardItemCode',
    value: 'standardItemCode',
    key: 'standardItemFilter',
  };

  const {
    setResetPagination,
    standardItemTypes,
    allStandardItemTypes,
    setStandardItemTypes,
    vehicleTypes,
    allVehicleTypes,
    setVehicleTypes,
  } = useVehicleAvailabilityState();

  const handleVehicleTypeFilterChange = (values) => {
    // const { value } = VehicleTypeOption;
    const newVehicleTypes = allVehicleTypes.filter((item) =>
      values.includes(item.value),
    );
    setVehicleTypes(newVehicleTypes);
  };

  const handleStandartItemFilterChange = (values) => {
    // const { value } = StandardItemOption;
    const newStandardItemTypes = allStandardItemTypes.filter((item) => {
      return values.includes(item.value);
    });
    setStandardItemTypes(newStandardItemTypes);
  };

  const handleFiltersChange = (filters) => {
    const vehicleTypeFilter =
      filters.find((filter) => filter.key === VehicleTypeOption.key)?.value ||
      [];
    const standardItemFilter =
      filters.find((filter) => filter.key === StandardItemOption.key)?.value ||
      [];

    setResetPagination(true);
    setTimeout(() => {
      setResetPagination(false);
    }, 1000);

    if (vehicleTypes.length !== vehicleTypeFilter.length) {
      handleVehicleTypeFilterChange(vehicleTypeFilter || []);
      return;
    }
    if (standardItemTypes.length !== standardItemFilter.length) {
      handleStandartItemFilterChange(standardItemFilter || []);
    }
  };

  return (
    <ComponentFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={[
        {
          key: VehicleTypeOption.key,
          title: 'Vehicle Type',
          type: 'multiselect',
          value: [],
          options: allVehicleTypes.map((vehicleType) => ({
            label: vehicleType.label,
            value: vehicleType.value,
          })),
          operator: '$in',
          hideClear: true,
        },
        {
          key: StandardItemOption.key,
          title: 'Standard Item',
          type: 'multiselect',
          value: [],
          options: allStandardItemTypes.map((standardItem) => ({
            label: standardItem.label,
            value: standardItem.value,
          })),
          operator: '$in',
          hideClear: true,
        },
      ]}
      model="Task"
      order={[]}
      setQueryFiltersState={handleFiltersChange}
      showClearIcon
    />
  );
};

export default VehicleAvailabilityFilters;
