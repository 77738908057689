import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from '@gsa/afp-component-library';
import { formatCurrency } from '../../../utilities/CompareVehicleUtils';
import SectionEditButton from '../../ReviewComponents/SectionEditButton/SectionEditButton';
import { STEPS } from '@/pages/VehicleRequisition/constants/VehicleRequisitionConstants.jsx';
import { formatPhoneNumber } from '../../ReviewComponents/AgencyFinancial/agencyFinancialUtils';

import './AreqDetails.scss';
import { concatStrings } from '@/utilities/formatUtil.jsx';
import {
  filterBuildableContracts,
  filterUnbuildableContracts,
  groupContractsByVendorName,
} from '@/pages/areqMultipleVendors/areqMultipleVendorUtil.jsx';
import AttachmentDownload from '../../../pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

export const UserContact = ({ name, email, phoneNumber }) => (
  <>
    <strong>{name}</strong>
    <br />
    <Link href={`mailto:${email}`}>{email}</Link>
    <br />
    {formatPhoneNumber(phoneNumber)}
  </>
);

export const Detail = ({ children }) => {
  return <div className="detail">{children}</div>;
};

export const OneThird = ({ children }) => (
  <div className="one-third">
    {children}
    <div
      data-testid="clear-div"
      style={{
        clear: 'both',
      }}
    />
  </div>
);

export const DetailGroupGroup = ({ header, children }) => (
  <div data-testid="detail-group-group" className="detail-group-group">
    <div
      data-testid="detail-group-group-header"
      className="detail-group-group-header"
    >
      <span>{header}</span>
    </div>
    <div
      data-testid="detail-group-group-body"
      className="detail-group-group-body"
    >
      {children}
    </div>
  </div>
);
export const DetailGroup = ({ header, isEditable, children }) => {
  return (
    <div tabIndex="0" role="tab" className="detail-group">
      <div className="review-details">
        <div
          data-testid="detail-group"
          className={classNames({
            'detail-group-header': true,
            editable: isEditable,
          })}
        >
          {header}
          {isEditable && (
            <SectionEditButton
              showButton
              id="required-options-edit"
              step={STEPS.AREQ_INFORMATION}
            />
          )}
        </div>
        <div className="detail-group-body">{children}</div>
      </div>
    </div>
  );
};

export const AdditionalRequirementsAggregatedPriceEstimate = ({
  additionalRequirements,
}) => (
  <>
    {formatCurrency(
      additionalRequirements.reduce(
        (sum, additionalRequirement) =>
          sum + (Number(additionalRequirement?.priceEstimate) ?? 0),
        0,
      ),
    )}
  </>
);

export const AdditionalRequirements = ({ additionalRequirements }) => {
  return (
    <div className="additional-requirments">
      <table>
        <thead>
          <tr>
            <th className="description" colSpan={2}>
              Additional requirements
            </th>
            <th className="estimated-price">Estimated Price</th>
          </tr>
        </thead>
        <tbody>
          {additionalRequirements.map((additionalRequirement, index) => {
            return (
              <Fragment
                key={additionalRequirement.requisitionAdditionalRequirementId}
              >
                <tr>
                  <td className="count">
                    <strong>{index + 1}.</strong>
                  </td>
                  <td className="description">
                    {additionalRequirement.description}
                  </td>

                  <td className="estimated-price">
                    {formatCurrency(additionalRequirement.priceEstimate)}
                  </td>
                </tr>
                {!!additionalRequirement?.attachments?.length &&
                  additionalRequirement.attachments.map((attachment) => (
                    <Fragment key={attachment.requisitionAttachmentId}>
                      <tr>
                        <td aria-label="td" />
                        <td colSpan={2}>
                          <AttachmentDownload
                            name={attachment.name}
                            metadataId={attachment.metadataId}
                          />
                          {attachment.description && (
                            <div className="attachment-description">
                              {attachment.description}
                            </div>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const AreqDetails = ({
  agencyReferenceNumber,
  additionalRequirements,
  isEditable,
  onClickOverride,
  headerOverride,
  createdByInfo,
  allActiveContracts,
  allCostBreakdown,
  contractCostBreakDowns,
  isLeasing,
  primary,
  secondary,
}) => {
  const header = headerOverride || 'AREQ details';
  const buildableVendorNames = useMemo(() => {
    const buildableContracts = filterBuildableContracts({
      allActiveContracts,
      allCostBreakdown,
      contractCostBreakDowns,
    });

    if (!buildableContracts?.length === 0) {
      return [];
    }

    return Object.keys(groupContractsByVendorName(buildableContracts));
  }, [allActiveContracts, allCostBreakdown, contractCostBreakDowns]);

  const unuildableVendorNames = useMemo(() => {
    const unbuildableContracts = filterUnbuildableContracts({
      allActiveContracts,
      allCostBreakdown,
      contractCostBreakDowns,
    });

    if (!unbuildableContracts) {
      return [];
    }

    return Object.keys(groupContractsByVendorName(unbuildableContracts));
  }, [allActiveContracts, allCostBreakdown, contractCostBreakDowns]);

  return (
    <>
      <Detail>
        <div className="title-section">
          <span>{header}</span>
          <SectionEditButton
            showButton={isEditable}
            id="options-per-vehicle-edit"
            step={STEPS.COMPARE_SELECT}
            onClickOverride={onClickOverride}
          />
        </div>
        <div role="tab" tabIndex="0">
          <DetailGroupGroup header="Agency reference number">
            {agencyReferenceNumber}
          </DetailGroupGroup>

          <OneThird>
            <DetailGroupGroup header="AREQ preparer">
              <UserContact
                name={concatStrings(
                  createdByInfo?.firstName,
                  createdByInfo?.lastName,
                )}
                email={createdByInfo?.email}
                phoneNumber={createdByInfo?.phoneNumber}
              />
            </DetailGroupGroup>
            <DetailGroupGroup header="Primary engineer">
              <UserContact
                name={primary.name}
                email={primary.email}
                phoneNumber={primary.phoneNumber}
              />
            </DetailGroupGroup>
            <DetailGroupGroup header="Secondary engineer contact">
              <UserContact
                name={secondary.name}
                email={secondary.email}
                phoneNumber={secondary.phoneNumber}
              />
            </DetailGroupGroup>
          </OneThird>
          {/* )} */}

          <DetailGroupGroup>
            <AdditionalRequirements
              additionalRequirements={additionalRequirements}
            />
          </DetailGroupGroup>

          <DetailGroupGroup header="Aggregate price estimate of AREQ from Independent Government Cost Estimate (IGCE)">
            <AdditionalRequirementsAggregatedPriceEstimate
              additionalRequirements={additionalRequirements}
            />
          </DetailGroupGroup>

          {isLeasing ? null : (
            <>
              <DetailGroupGroup header="Eligible vendors for AREQ">
                {buildableVendorNames.length
                  ? buildableVendorNames.map((vendorName) => {
                      return (
                        <div className="review-options-option-row">
                          {vendorName}
                        </div>
                      );
                    })
                  : '--'}
              </DetailGroupGroup>

              <DetailGroupGroup header="Non-eligible vendors for this AREQ">
                {unuildableVendorNames.length
                  ? unuildableVendorNames.map((vendorName) => {
                      return (
                        <div className="review-options-option-row">
                          {vendorName}
                        </div>
                      );
                    })
                  : '--'}
              </DetailGroupGroup>
            </>
          )}
        </div>
      </Detail>
    </>
  );
};

export default AreqDetails;
