import { createSlice } from '@reduxjs/toolkit';

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: { vehicles: [] },
  reducers: {
    // example of a reducer
    addVehicle: (state, action) => {
      return { ...state, vehicles: [...state.vehicles, action.payload] };
    },
  },
});

export const { addVehicle } = purchaseSlice.actions;
export default purchaseSlice.reducer;
