import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import ModificationFileUploadTable from '../widgets/ModificationFileUploadTable';
import useBulkOrderModsState from '../useBulkOrderModsState';

const Areq = ({ remove, update, reqFiles, append }) => {
  const { orderModAttachmentTypes } = useBulkOrderModsState();
  const attachmentType = orderModAttachmentTypes?.find(
    (aType) => aType?.type === 'AREQ',
  );

  const appendFiles = (values) => {
    append({
      name: values?.name,
      status: 1,
      typeId: attachmentType?.id,
      description: values?.description,
      signedUrl: values?.signedUrl,
      metadataId: values?.metadataId,
    });
  };
  const getAredContent = () => {
    return [
      {
        id: 'orderChange',
        title: <div className="add-options-accordion-title">AREQ</div>,
        content: (
          <div className="ordermod-accordion-container">
            <div className="info-header">
              <label className="info-label" htmlFor="agencyInfo">
                Upload modification documents
              </label>
            </div>

            <>
              <ModificationFileUploadTable
                reqFiles={reqFiles}
                append={appendFiles}
                remove={remove}
                update={update}
                reqType={attachmentType?.type}
              />
            </>
          </div>
        ),
        expanded: false,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getAredContent()}
        className="add-options-accordion"
      />
    </>
  );
};

Areq.propTypes = {
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  row: PropTypes.instanceOf(Object),
  reqFiles: PropTypes.instanceOf(Array).isRequired,
};

Areq.defaultProps = {
  row: {
    original: {
      signedUrl: '',
      name: '',
    },
  },
};

export default Areq;
