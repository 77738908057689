import { RequisitionStatus } from '../pages/ReviewDetails/RequisitionDetailsUtils';
import { canCreateRequisitionCart } from './authorization';

export const canEditInReview = (ability, requistion, currentUser) => {
  const canCreateRequisition = canCreateRequisitionCart(ability);

  return (
    canCreateRequisition &&
    (requistion?.requisitionStatus === RequisitionStatus.DRAFT ||
      requistion?.requisitionStatus === RequisitionStatus.RETURNED) &&
    (requistion?.createdByUser === currentUser.id ||
      requistion?.createdByInfo?.id === currentUser.id)
  );
};
