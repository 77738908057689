import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { createRef, useEffect, useRef, useState } from "react";

export const useDispatchQuery = () => {
    const dispatch = useDispatch();

    const promisifiedQuery = (query, apolloQuery, baseOptions, setError, clearError) => 
        (options = {}) => {
            return new Promise((resolve, reject) => {
                dispatch((dispatch, getState, { client }) => {
                    apolloQuery({
                        client,
                        variables: { ...baseOptions.variables, ...options.variables },
                        onError: (e) => {
                            // serve the error that came from the backend, don't make something new.
                            const error = new Error();
                            if (e.graphQLErrors[0].extensions.exception) {
                                error.name = e.graphQLErrors[0].extensions.exception.name;
                                // hide/ignore the stack trace when in production
                                // or pick up the stack trace from the backend and allow
                                // it to be used in the frontend
                                if (window.AFP_CONFIG.showGraphqlErrors) {
                                    error.stack = e.graphQLErrors[0].extensions.exception.stacktrace;
                                } else {
                                    error.stack = null;
                                }
                                error.message= e.graphQLErrors[0].message;
                            } else if (e.networkError.result.errors[0]) {
                                console.log(e.networkError.result.errors[0]);
                                error.message = e.networkError.result.errors[0].message;
                            } else {
                                error.message = 'An error occurred';
                            }

                            const err = {
                                dispatch,
                                error,
                                variables: options.variables || null,
                                getState,
                                query,
                            }

                            reject(err);
                        }
                    })
                    .then((response) => {
                        const data = response.data?.[query.definitions[0].selectionSet.selections[0].name.value];

                        if (!data && response.error) {
                            throw new Error(response.error.networkError.result.errors[0].message);
                        }

                        return data;
                    })
                    .then(async (data) => {
                        let transformedData = await baseOptions?.transformResult?.({
                            dispatch,
                            data,
                            variables: options.variables || null,
                            getState,
                            query,
                        }) || data;

                        return {
                            dispatch,
                            data: transformedData,
                            variables: options.variables || null,
                            getState,
                            query,
                        }
                    }).then(async (data) => {
                        baseOptions?.onCompleted?.(data);
                        resolve(data);
                    }).catch((e) => {
                        setError(e);

                        const err = {
                            dispatch,
                            error: e,
                            variables: options.variables || null,
                            getState,
                            query,
                        }

                        baseOptions?.onError?.(err);
                        reject(err)
                    });
                });
            })
        }

    return {
        runQueryNow: (query, options = {}) => {
            const { data, ...rest } = useQuery(query, {
                fetchPolicy: 'no-cache',
                ...options
            });

            let transformedData = data?.[query.definitions[0].selectionSet.selections[0].name.value];
            if (!rest.loading && data && options.transformResult) {
                transformedData = options.transformResult({
                    data: data[query.definitions[0].selectionSet.selections[0].name.value],
                    dispatch,
                    variables: options.variables || null,
                });
            }

            return {
                data: transformedData,
                ...rest
            }
        },
        runQuery: (query, baseOptions = {}) => {
            if (!query) {
                throw new Error('query is required')
            }

            return new Promise((resolve, reject) => {
                useQuery(query, {
                    variables: baseOptions.variables || {},
                    fetchPolicy: 'no-cache',
                    ...baseOptions?.apolloOptions,
                    onCompleted: async (response) => {
                        const rawData = response[query.definitions[0].selectionSet.selections[0].name.value];

                        if (!rawData && response.error) {
                            throw new Error(response.error.networkError.result.errors[0].message);
                        }

                        let transformedData = await baseOptions?.transformResult?.({
                            dispatch,
                            data: rawData,
                            variables: baseOptions.variables || null,
                            query,
                        }) || rawData;

                        const data = {
                            dispatch,
                            data: transformedData,
                            variables: baseOptions.variables || null,
                            query,
                        }

                        baseOptions?.onCompleted?.(data);
                        resolve(data);
                    },
                    onError: (e) => {
                        setError(e);

                        const err = {
                            dispatch,
                            error: e,
                            variables: baseOptions.variables || null,
                            query,
                        }

                        baseOptions?.onError?.(err);
                        reject(err)
                    }
                });

                useEffect(() => {
                    if (baseOptions?.apolloOptions?.skip) {
                        resolve({
                            dispatch,
                            data: null,
                            variables: baseOptions.variables || null,
                        });
                    }
                }, []);
            });
        },
        registerQuery: (query, baseOptions = {}) => {
            if (!query) {
                throw new Error('query is required')
            }
        
            const [error, setError] = useState(null);
            const [apolloQuery, { loading }] = useLazyQuery(query, {
                ...(baseOptions?.apolloOptions || {})
            });
    
            const clearError = () => {
                setError(null);
            }
        
            return [
                promisifiedQuery(query, apolloQuery, baseOptions, setError, clearError),
                {
                    loading,
                    error,
                    clearError,
                    setError
                }
            ];
        },
        registerMutation: (mutation, baseOptions = {}) => {
            if (!mutation) {
                throw new Error('mutation is required')
            }

            const [error, setError] = useState(null);
            const [apolloQuery, { loading }] = useMutation(mutation, {
                ...(baseOptions?.apolloOptions || {})
            });
        
            const clearError = () => {
                setError(null);
            }
        
            return [
                promisifiedQuery(mutation, apolloQuery, baseOptions, setError, clearError),
                {
                    loading,
                    error,
                    clearError,
                    setError
                }
            ];
        }
    }
}