import React from 'react';

const RevertIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_31008_170625)">
        <path
          d="M9.4932 15.0844C13.1676 14.7004 16.0339 11.5367 16.0339 7.69062C16.0339 3.5856 12.7686 0.257812 8.74057 0.257812C4.71254 0.257812 1.44727 3.5856 1.44727 7.69062C1.44727 9.18544 1.88028 10.5772 2.62535 11.7427L4.40327 10.1733C3.99796 9.43961 3.76682 8.59262 3.76682 7.69062C3.76682 4.89113 5.99367 2.62174 8.74057 2.62174C11.4875 2.62174 13.7143 4.89113 13.7143 7.69062C13.7143 10.2294 11.883 12.3322 9.4932 12.7018V9.52614C9.4932 9.44584 9.39994 9.40337 9.34119 9.4569L4.88492 13.5203C4.84441 13.5573 4.84441 13.6219 4.88492 13.6588L9.34119 17.7222C9.39994 17.7758 9.4932 17.7332 9.4932 17.6529V15.0844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31008_170625">
          <path
            d="M0.160156 0.257812H14.3202C15.977 0.257812 17.3202 1.60096 17.3202 3.25781V14.746C17.3202 16.4029 15.977 17.746 14.3202 17.746H0.160156V0.257812Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RevertIcon;
