import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Icon } from '@gsa/afp-component-library';
import { GET_REQUISITION_ACTIVITIES_BY_TYPE } from '../../services/data-layer';
import './ReqReturnedBanner.scss';

const ReqReturnedBanner = ({ requisitionId, isMas }) => {
  const [getRequisitionActivitiesData, { data: requisitionActivitiesData }] =
    useLazyQuery(GET_REQUISITION_ACTIVITIES_BY_TYPE, {
      fetchPolicy: 'no-cache',
    });

  const { approverName, comment } = useMemo(() => {
    const bannerData = {
      approverName: '',
      comment: '',
    };

    if (
      requisitionActivitiesData?.getRequisitionActivitiesByType?.rows?.length
    ) {
      bannerData.approverAgency =
        requisitionActivitiesData.getRequisitionActivitiesByType.rows[0].requisitionCart?.agencyInfo?.name;
      bannerData.approverName =
        requisitionActivitiesData.getRequisitionActivitiesByType.rows[0]?.createdUserInfo?.fullName;
      bannerData.comment =
        requisitionActivitiesData.getRequisitionActivitiesByType.rows[0]?.comment;
    }
    return bannerData;
  }, [requisitionActivitiesData]);

  useEffect(() => {
    getRequisitionActivitiesData({
      variables: {
        requisitionId,
        offset: 0,
        limit: 1,
        order: [['createdAt', 'DESC']],
        type: 'REQUISITION_RETURNED',
      },
    });
  }, [requisitionId]);

  return (
    <div className="returned-banner">
      <div
        className="returned-banner-title"
        data-testid="req-returned-banner-title"
      >
        This requisition has been returned.
      </div>
      {isMas && (
        <div className="returned-banner-main">
          Please update and resubmit this requisition within 7 days, or it will
          be automatically canceled.
        </div>
      )}
      <div className="returned-banner-user">
        <div className="fa-svg_user w-embed">
          <Icon iconName="account_circle" className="usa-icon--size-1" />
        </div>
        <span className="username">{approverName}</span>, has commented:
      </div>
      <div className="returned-banner-main">{comment}</div>
    </div>
  );
};

ReqReturnedBanner.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  isMas: PropTypes.bool,
};

ReqReturnedBanner.defaultProps = {
  isMas: false,
};

export default ReqReturnedBanner;
