import React from 'react';

export const OrderingAdminReviewTaskList = () => {
  const tasks = [
    'Please review all information',
    'Provide information for additional requirements',
    <>
      Submit requisition to <strong>GSA Engineering Review</strong>
    </>,
  ];

  return (
    <div data-testid="ordering-admin-review-task-list">
      <div className="title">Ordering Admin&apos;s tasks:</div>
      <ol>
        {tasks.map((task) => (
          <li key={task} className="task">
            {task}
          </li>
        ))}
      </ol>
    </div>
  );
};
