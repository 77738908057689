import { Modal, Button, connectModal, Alert, Spinner } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useSelector } from 'react-redux';
import { generateReturnRequisitionForm } from './return-requisition-modal-form';
import { returnRequisitionSchema } from './return-requisition-modal-form-schema';
import { useReturnRequisition } from '../../../../../requests/leasing';
import { useNavigate } from 'react-router-dom';
import { RequisitionStatus } from '../../../../ReviewDetails/RequisitionDetailsUtils';
import '../review-and-submit.scss'

export function ReturnRequisitionModal({
    onClose,
    method
}) {
    const { requisitionName, requisitionId, requisitionNumber, requisitionStatus } = useSelector((state) => state.leasingReducer);
    const [returnRequisition, { loading, error, setError, clearError }] = method;
    const navigate = useNavigate();

    const [modalFormContent, defaultValues] = generateReturnRequisitionForm({
        requisitionName
    });

    const handleSubmit = async (data) => {
        clearError();

        returnRequisition({
            variables: {
                requisitionId,
                submitComment: data.comment
            }
        }).then(() => {
            navigate(`/my-requisitions?filter-requisitionNumber=${encodeURIComponent(requisitionNumber)}`);
        }).catch((err) => {
            setError(err?.error?.message || err?.error || err?.message || err);
        })
    };

    const closeModal = () => {
        onClose()
    }

    let areYouSure = 'Are you sure you want to return the following requisition?';
    if (requisitionStatus === RequisitionStatus.FMC_APPROVAL) {
        areYouSure = 'Are you sure you want to return the following requisition to the customer?';
    }

    return (
        <Modal
            variant="small"
            onClose={closeModal}
            actions={
                <div>
                    <Button
                        variant="unstyled"
                        onClick={closeModal}
                        label="Cancel"
                        disabled={loading}
                    />
                    <Button
                        variant="secondary"
                        label="Return requisition"
                        leftIcon={{ name: 'cancel' }}
                        type="submit"
                        form="modal-form" // important to target the form inside the modal
                        disabled={loading}
                    />
                </div>
            }
        >
            {loading ? <Spinner /> : (
                <>
                    <div>
                        <h2>
                            {areYouSure}
                        </h2>

                        <div className='bg-primary-lighter font-body-xs text-secondary padding-2 margin-bottom-3 text-bold return-req-text'>
                            {requisitionName}
                        </div>
                    </div>

                    {error && <Alert className='margin-bottom-1' type='error'>{error}</Alert>}

                    <FormGenerator
                        id="modal-form" // important to give the form an id so that the submit button can target it
                        schema={returnRequisitionSchema}
                        content={modalFormContent}
                        defaultValues={defaultValues}
                        onSubmit={handleSubmit}
                    />
                </>
            )}
        </Modal>
    );
}

export default connectModal(ReturnRequisitionModal);