import { groupBy } from 'lodash';
import ErrorActions from '../../../context/ErrorContext/ErrorActions';
import { STEPS } from '../constants/VehicleRequisitionConstants';
import { RequisitionStatus } from '../../ReviewDetails/RequisitionDetailsUtils';
import { OPTION_QUANTITY_REQUIRED_CODE } from '../../../utilities/CompareVehicleUtils';

const groupOptionsPerCatalog = (optionalReqs, selectedOptions) => {
  const { getOptionalOptions = [] } = optionalReqs;
  if (!selectedOptions) {
    return [];
  }

  const optionsWithCategory = selectedOptions.reduce((options, option) => {
    const optionToPush = { ...option };
    const foundOptionalReq = getOptionalOptions.find(
      (opt) => opt.equipmentCode?.code === option.optionCode,
    );
    if (foundOptionalReq) {
      optionToPush.optionCategory = foundOptionalReq.equipmentCode.categoryCode;
      optionToPush.optionDescription =
        optionToPush.optionDescription || foundOptionalReq.equipmentCode.title;
      optionToPush.quantityRequired =
        foundOptionalReq.equipmentCode.quantityRequired === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
        foundOptionalReq.equipmentCode.quantityRequired === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;
    }
    options.push(optionToPush);
    return options;
  }, []);

  const optionsWithCategoryMap = groupBy(
    optionsWithCategory,
    (opt) =>
      (opt.optionCategory && opt.optionCategory.code) ||
      opt?.optionCategoryCode,
  );

  if (!optionsWithCategoryMap) {
    return [];
  }

  const optionsToReturn = Object.keys(optionsWithCategoryMap).reduce(
    (options, option) => {
      if (!option) {
        return options;
      }

      const optionCategoryToPush = {
        optionCategoryCode: option,
        optionCategoryDescription:
          optionsWithCategoryMap[option][0]?.optionCategory?.title,
        options: optionsWithCategoryMap[option],
      };

      options.push(optionCategoryToPush);
      return options;
    },
    [],
  );

  return optionsToReturn;
};

export default groupOptionsPerCatalog;

export const getCurrentFiscalYear = () => {
  let fy = new Date().getFullYear();
  if (new Date().getMonth() >= 9) {
    fy += 1;
  }
  return fy;
};

export const isDodUser = (agencyDod) => {
  const isAgencyDod = agencyDod
    ? 'true'
    : sessionStorage.getItem('isAgencyDod');
  return isAgencyDod !== null && isAgencyDod === 'true';
};

export const ADDRESS_FEEDBACK = {
  countryCode: [
    'Please select country from dropdown',
    'Please select a valid Country',
  ],
  addressLine1: ['Please fill out the Address', 'Please enter a valid Address'],
  city: ['Please fill out the City', 'Please enter a valid City'],
  stateCode: [
    'Please select state from dropdown',
    'Please select a valid state',
  ],
  zipcode: ['Please fill out the Zip1', 'Please enter a valid Zip1'],
  firstName: [
    'Please fill out the First Name',
    'Please enter a valid First Name',
  ],
  lastName: ['Please fill out the Last Name', 'Please enter a valid Last Name'],
  phoneCountryCode: [
    'Please verify country code',
    'Please select a valid Country Code',
  ],
  phoneNumber: ['Please fill out the Phone Number', 'Invalid Phone Number'],
  phoneExtension: ['Invalid phone number extension'],
  faxCountryCode: [
    'Please verify country code',
    'Please select a valid Country Code',
  ],
  faxNumber: ['Please fill out the Fax Number', 'Invalid Fax Number'],
  faxExtension: ['Invalid fax extension'],
  email: ['Please fill out the Email Address', 'Invalid Email Address'],
  entityName: ['Please fill out the agency/organization'],
  rankAndFullName: ['Please provide rank and full name'],
  militaryOrDiplomaticAddress: ['Please provide address'],
  postOffice: ['Please select post office'],
  dealership_delivery_city_select: ['Please select city'],
  dealership_delivery_select: ['Please select Dealership'],
  entity: ['Please select Agency/Organization'],
};

export const feedback = {
  agency: [],
  entity: [],
  agencyName: [],
  bureau: [],
  bureauName: [],
  groupAssignment: [],
  unitPrice: [],
  additionalAreqFunds: [
    'Please fill out the Additional Funds field',
    'Please enter a valid amount',
  ],
  unitPriceWithAreqs: [],
  shipmentLocation: [],

  agencyOrderNumber: [
    'Please fill out the agency order number field',
    'Invalid Agency Order Number',
    'The Agency Order Number must be alphanumeric and 15 characters long',
  ],
  requisitionBOAC: [
    'Please fill out the requisition number',
    'Invalid requisition number',
    'The entered BOAC is not tied to your agency',
    "You've entered an incorrect requisition number",
    "The entered BOAC does not exist in your Agency's Wallet. Please contact your Agency's Finance POC to add the BOAC in your agency Wallet",
    'The entered Requisition number is not tied to your agency',
    'This requisition number has already been used.',
  ],
  requisitionJulian: ["You've entered an incorrect requisition number"],
  requisitionSerialNumber: [
    'Please fill out the Requisition Serial Number',
    "You've entered an incorrect requisition number",
  ],
  serviceCode: [
    'Please fill out the Service Code',
    'Invalid service code',
    'Invalid service code, valid code must be two digits of your agency code or the first two digits from Requisition number/BOAC',
  ],
  tas: [
    "The Treasury Account Symbol (TAS) is missing in your Agency's Wallet. Please contact your Agency Administrator to update the TAS in your Agency Wallet",
  ],
  fundCode: ['Please fill out the fund code', 'Invalid fund code'],
  signalCode: ['Please fill out the Signal Code'],
  signalSupplementaryAddress: [
    'Please fill out the supplementary address',
    'Invalid supplementary address code',
    'The entered BOAC is not tied to your agency',
    'The service provided to derive the Supplementary Address Code is currently unavailable, please try again at a later time.',
    'The Service Code and Fund Code is not valid for your Supplementary Address Code. Please ensure the Service Code and Fund Code are entered correctly',
    'The Supplementary Address Code ($boac$) that was derived from your Service Code and Fund Code is not tied to your agency. Please ensure the Service Code and Fund Code are entered correctly.',
    "The Supplementary Address Code ($boac$) that was derived from your Service Code and Fund Code does not exist in your Agency's Wallet.",
    'The Fund Code is not valid for your Supplementary Address Code. Please ensure the Fund Code is entered correctly.',
    'The Supplementary Address Code ($boac$) that was derived from your Agency Code and Fund Code is not tied to your agency. Please ensure the Agency Code and Fund Code are entered correctly.',
    "The Supplementary Address Code ($boac$) that was derived from your Agency Code and Fund Code does not exist in your Agency's Wallet.",
    "The Treasury Account Symbol (TAS) is missing in your Agency's Wallet. Please contact your Agency Administrator to update the TAS in your Agency Wallet.",
    "The entered BOAC does not exist in your Agency's Wallet. Please contact your Agency's Finance POC to add the BOAC in your agency Wallet",
  ],
  firstName: [
    'Please fill out the first name',
    'Please enter a valid first name',
  ],
  lastName: ['Please fill out the last name', 'Please enter a valid last name'],
  phoneNumber: ['Please fill out the phone number', 'Invalid phone number'],
  email: ['Please fill out the email address', 'Invalid email address'],
  agencyFinancialData: ['Invalid agency financial data'],
  transportationControlNumber: [
    'Please fill out the Transportation Control Number',
    'Invalid Transportation Control Number',
    'The Transportation Control Number must be alphanumeric',
  ],
  markForInformation: [
    'Please fill out the Mark For Information',
    'Invalid Mark For Information',
    'The Mark For Information must be alphanumeric',
  ],
  nationalStockNumber: [
    'Please fill out the National Stock Number',
    'Invalid National Stock Number',
    'The National Stock Number must be alphanumeric',
  ],
  registrationNumber: [
    'Please fill out the Registration Number',
    'Invalid Registration Number',
    'The Registration Number must be alphanumeric',
  ],
  accountingClassificationReferenceNumber: [
    'Please fill out the Accounting Classification Reference Number',
    'Invalid Accounting Classification Reference Number',
    'The Accounting Classification Reference Number must be alphanumeric',
  ],
  hasAreq: [],
};

export const shouldCheckBoacErrorForSubmitter = (requisition) => {
  return (
    requisition &&
    (requisition.requisitionStatus === RequisitionStatus.DRAFT ||
      requisition.requisitionStatus === RequisitionStatus.RETURNED ||
      requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA)
  );
};

export const otherStatusForSubmitter = (requisition) => {
  return (
    requisition &&
    requisition.requisitionStatus !== RequisitionStatus.DRAFT &&
    requisition.requisitionStatus !== RequisitionStatus.RETURNED &&
    requisition.requisitionStatus !== RequisitionStatus.RECEIVED_BY_GSA
  );
};

export const shouldCheckBoacErrorForApprover = (requisition) => {
  return (
    requisition &&
    (requisition.requisitionStatus === RequisitionStatus.DRAFT_APPROVAL ||
      requisition.requisitionStatus === RequisitionStatus.FINAL_APPROVAL ||
      requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA)
  );
};

export const shouldCheckBoacErrorForEngineer = (requisition) => {
  return (
    requisition &&
    requisition.requisitionStatus === RequisitionStatus.ENGINEERING_REVIEW
  );
};

export const isValidBoac = (boac) => {
  return boac && boac.length >= 6;
};

export const validateFss19Response = (
  agencyInformation,
  boacCodeCOrL,
  validateBoacInWalletForAddressCode,
  errorDispatch,
  isAgencyDod,
) => {
  const { agencyCode, bureauCode } = agencyInformation;
  const isDod = isDodUser(isAgencyDod);

  if (boacCodeCOrL.error) {
    errorDispatch({
      type: ErrorActions.ADD_ERROR,
      payload: {
        page: STEPS.AGENCY_INFORMATION,
        errorKey: 'agencyInformation',
        error: feedback.signalSupplementaryAddress[3],
      },
    });
  } else if (!boacCodeCOrL.boac) {
    errorDispatch({
      type: ErrorActions.ADD_ERROR,
      payload: {
        page: STEPS.AGENCY_INFORMATION,
        errorKey: 'agencyInformation',
        error: isDod
          ? feedback.signalSupplementaryAddress[4]
          : feedback.signalSupplementaryAddress[7],
      },
    });
  } else if (
    agencyCode &&
    bureauCode &&
    isValidBoac(agencyInformation.requisitionBOAC)
  ) {
    validateBoacInWalletForAddressCode({
      variables: {
        boac: boacCodeCOrL.boac,
        agencyCode,
        bureauCode,
        fiscalYear: getCurrentFiscalYear(),
        salesGroup: 'TM12',
      },
    });
  }
};

export const validateBoacResponse = (
  agencyInformation,
  validateBOAC,
  boacResponseType,
  errorDispatch,
  isAgencyDod,
) => {
  const isDod = isDodUser(isAgencyDod);
  const isRequisitionResponse = boacResponseType === 'requisitionBOAC';
  const isAddressCodeResponse =
    boacResponseType === 'signalSupplementaryAddress';

  const isAddressCodeBoac =
    agencyInformation.signalCode === 'B' ||
    agencyInformation.signalCode === 'K';
  const isRequisitionBOAC =
    agencyInformation.signalCode === 'A' ||
    agencyInformation.signalCode === 'J';
  const isFss19Boac =
    agencyInformation.signalCode === 'C' ||
    agencyInformation.signalCode === 'L';

  if (!validateBOAC.tiedToAgency) {
    if (isRequisitionResponse) {
      errorDispatch({
        type: ErrorActions.ADD_ERROR,
        payload: {
          page: STEPS.AGENCY_INFORMATION,
          errorKey: 'agencyInformation',
          error:
            isFss19Boac || isAddressCodeBoac
              ? feedback.requisitionBOAC[5]
              : feedback.requisitionBOAC[2],
        },
      });
    }
    if (isAddressCodeResponse) {
      if (isFss19Boac) {
        const agencyError = isDod
          ? feedback.signalSupplementaryAddress[5]
          : feedback.signalSupplementaryAddress[8];

        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: STEPS.AGENCY_INFORMATION,
            errorKey: 'agencyInformation',
            error: agencyError.replace('$boac$', validateBOAC.boac),
          },
        });
      } else {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: STEPS.AGENCY_INFORMATION,
            errorKey: 'agencyInformation',
            error: feedback.signalSupplementaryAddress[2],
          },
        });
      }
    }
  } else if (
    (isRequisitionResponse && isRequisitionBOAC) ||
    (isAddressCodeResponse && isAddressCodeBoac) ||
    (isAddressCodeResponse && isFss19Boac)
  ) {
    if (validateBOAC.isValid) {
      if (!validateBOAC.tas || validateBOAC.tas === 'null') {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: STEPS.AGENCY_INFORMATION,
            errorKey: 'agencyInformation',
            error: feedback.tas[0],
          },
        });
      }
    } else {
      const walletError = isDod
        ? feedback.signalSupplementaryAddress[6]
        : feedback.signalSupplementaryAddress[9];
      errorDispatch({
        type: ErrorActions.ADD_ERROR,
        payload: {
          page: STEPS.AGENCY_INFORMATION,
          errorKey: 'agencyInformation',
          error: isFss19Boac
            ? walletError.replace('$boac$', validateBOAC.boac)
            : feedback.requisitionBOAC[4],
        },
      });
    }
  }
};

export const initiateBoacValidation = (
  agencyInformation,
  requisitionValidationContext,
  errorDispatch,
  isAgencyDod,
) => {
  const { agencyCode } = agencyInformation;
  const isDod = isDodUser(isAgencyDod);
  const { validateBoacInWallet, getBoacForSignalCodeCOrL } =
    requisitionValidationContext;

  if (agencyInformation?.signalCode) {
    const bureauCode = agencyInformation.bureauCode || '00';
    const boac = ['B', 'C', 'K', 'L'].includes(agencyInformation.signalCode)
      ? agencyInformation.signalSupplementaryAddress
      : agencyInformation.requisitionBOAC;

    if (agencyCode && bureauCode && isValidBoac(boac)) {
      errorDispatch({
        type: ErrorActions.CLEAR_ERRORS,
        payload: {
          page: STEPS.AGENCY_INFORMATION,
          errorKey: 'agencyInformation',
        },
      });

      validateBoacInWallet({
        variables: {
          boac,
          agencyCode,
          bureauCode,
          fiscalYear: getCurrentFiscalYear(),
          salesGroup: 'TM12',
        },
      });

      if (
        agencyInformation.signalCode === 'C' ||
        agencyInformation.signalCode === 'L'
      ) {
        if (
          (isDod &&
            agencyInformation.serviceCode &&
            agencyInformation.fundCode) ||
          (!isDod && agencyInformation.fundCode)
        ) {
          getBoacForSignalCodeCOrL({
            variables: {
              boacRequest: {
                fundCode: agencyInformation.fundCode,
                isDoD: true,
                agencyCode: agencyCode.toString().padStart(2, '0'),
                serviceCode: agencyInformation.serviceCode,
              },
            },
          });
        }
      }
    }
  }
};

export const validateAddressFields = (
  receivedKey,
  rawValue,
  focused = null,
  isExport = false,
  internationalAddress = false,
  selectedCountry = '1',
) => {
  const key = receivedKey;
  const errors = [];
  let value;
  let temp;

  const handledUndefined = ['faxNumber1'];
  if (rawValue === undefined && handledUndefined.indexOf(key) === -1) {
    return {
      errors: [{ key, visibility: 'error', text: 'Invalid value' }],
    };
  }

  if (rawValue !== null && rawValue !== undefined) {
    value = rawValue.toString().trim();
  } else {
    value = '';
  }

  switch (key) {
    case 'countryCode':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null ||
        value === 'Select Country' ||
        value.length > 20
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'addressLine1':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,.'-]*$/) === null ||
        value.length < 5 ||
        value.length > 50
      ) {
        errors.push({
          key,
          visibility: focused ? true : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: false,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'addressLine2':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      }
      break;
    case 'city':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z\s,.'-]*$/) === null ||
        value.length > 50 ||
        !value.trim().length
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'stateCode':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null ||
        value === 'Select State'
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'zipcode':
      if (value === '' && !internationalAddress && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (value === '' && !internationalAddress && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (value.length && value.match(/^[0-9]+$/) != null && isExport) {
        errors.push({ key, visibility: false, text: false });
      } else if (
        !internationalAddress &&
        value.match(/^\d{5}(?:[-\s]\d{4})?$/) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'firstName':
    case 'lastName':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (value.match(/^[a-z ,.'-]+$/i) === null || value.length > 50) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneCountryCode':
      temp = value.split(':');
      [value] = temp;
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value !== undefined &&
        (value === '' || Number.isNaN(parseInt(value, 10)))
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneNumber':
      value = value.replace(/\D/g, '');
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (value === '' && focused) {
        errors.push({
          key,
          visibility: true,
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        selectedCountry === '1' &&
        (value.length < 10 || value.length > 10)
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else if (
        selectedCountry !== '1' &&
        value.match(/^([1-9]{1}[0-9]{9})$/im) === null &&
        value.match(/^\d{9}$/) === null &&
        value.match(/^([1-9]{1}[0-9]{15})$/im) === null &&
        (value.length > 15 || value.length < 8)
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'phoneExtension':
      if (value !== '' && value.match(/^([1-9]{1,5})$/im) === null) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: 'Invalid extension number',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxCountryCode':
      temp = value.split(':');
      [value] = temp;
      if (
        value !== undefined &&
        value !== '' &&
        value.match(/^[a-zA-Z0-9\s,'-]*$/) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxNumber':
      value = value.replace(/\D/g, '');
      if (
        value !== '' &&
        value.match(/^([1-9]{1}[0-9]{9})$/im) === null &&
        value.match(/^([1-9]{1}[0-9]{15})$/im) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'faxExtension':
      if (value !== '' && value.match(/^([1-9]{1,5})$/im) === null) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: 'Invalid extension number',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'email':
      if (value === '' && !focused) {
        errors.push({
          key,
          visibility: 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else if (
        value.match(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) === null
      ) {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][1],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'entityName':
    case 'agencyOrganizationName':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'rankAndFullName':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'militaryOrDiplomaticAddress':
      if (value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'postOffice':
      if (value === 'select' || value === '') {
        errors.push({
          key,
          visibility: focused ? 'tooltip' : 'error',
          text: ADDRESS_FEEDBACK[key][0],
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    case 'deliveryDate':
      if (value === null || value === '') {
        errors.push({
          key,
          visibility: 'error',
          text: 'Please provide delivery date',
        });
      } else {
        errors.push({ key, visibility: false, text: false });
      }
      break;
    default:
      break;
  }

  return { errors };
};

//  This is for USPS validation
const getAddressValidationVariables = (address) => {
  return {
    address: address.addressLine1,
    address2: address.addressLine2,
    city: address.city,
    state: address.stateCode,
    zip: address.zipcode,
  };
};

const getAddressErrors = (addressContext, checkIfExport = false) => {
  let addressErrors = [];
  let deletionForAddress = [];

  let addressKey = Object.keys(addressContext);
  if (addressContext.isMilitary) {
    deletionForAddress = [
      'city',
      'countryCode',
      'stateCode',
      'firstName',
      'lastName',
      'addressLine1',
      'entityName',
    ];
  } else {
    deletionForAddress = [
      'militaryOrDiplomaticAddress',
      'rankAndFullName',
      'postOffice',
      'entityName',
      'deliveryDealership',
    ];
  }

  addressKey = addressKey?.filter(
    (item) => !deletionForAddress?.includes(item),
  );
  const internationalAddress =
    addressContext?.countryCode && addressContext?.countryCode !== 'US';

  addressKey.forEach((key) => {
    const { errors } = validateAddressFields(
      key,
      addressContext[key],
      true,
      checkIfExport,
      internationalAddress,
      addressContext?.phoneCountryCode,
    );
    addressErrors = addressErrors.concat(errors);
  });
  addressErrors = addressErrors.filter((i) => i.text !== false);
  return addressErrors;
};

export const addressValidations = {
  getAddressErrors,
  getAddressValidationVariables,
};
