import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { CANCEL_ORDER_MODIFICATION } from '../../services/data-layer';

const CancelOrderModModal = ({ orderModId, orderModName, onClose }) => {
  // const { appURLs } = window.AFP_CONFIG;

  const navigate = useNavigate();

  const [cancelOrderModMutation] = useMutation(CANCEL_ORDER_MODIFICATION, {
    onError: (error) => {
      console.log(error);
      onClose();
    },
    onCompleted: () => {
      navigate(`/order-modifications?modName=${orderModName}&modStatus=cancel`);
      onClose();
    },
  });
  const onSubmit = async () => {
    await cancelOrderModMutation({
      variables: {
        orderModId,
      },
    });
  };

  return (
    <>
      {orderModId && orderModName && (
        <Modal
          title={
            <h1 className="modal-title">
              Are you sure want to cancel this modification draft?
            </h1>
          }
          className="save-draft-modal"
          onClose={onClose}
          actions={
            <div className="save-draft-requisition-button-row">
              <Button
                type="button"
                variant="unstyled"
                className="save-draft-requisition-action-button"
                data-testid="cancel-order-mod-modal-cancel-button"
                onClick={onClose}
                label="Cancel"
              />
              <Button
                type="button"
                variant="secondary"
                className="save-draft-requisition-action-button"
                data-testid="order-mod-cancel-modal-button"
                onClick={() => onSubmit()}
                leftIcon={{ name: 'cancel' }}
                label="Cancel modification"
              />
            </div>
          }
        >
          <div className="cancel-mod-sub-title">
            <p className="sub-title-text">{orderModName}</p>
          </div>
          <div>
            <p>
              <span className="cancel-mod-text-bold">
                You cannot undo this action.
              </span>{' '}
              Fugit sed quia. Please check before consequuntur magni dolores eos
              qui ratione voluptatem sequi nesciunt.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

CancelOrderModModal.propTypes = {
  orderModId: PropTypes.string.isRequired,
  orderModName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelOrderModModal;
