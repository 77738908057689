import React from 'react';
import PropTypes from 'prop-types';
import { InfoIcon } from '../../../../assets/images/info-icon';
import './OptionDescriptionTooltip.scss';

const OptionDescriptionTooltip = ({ optionDescription }) => {
  if (!optionDescription) return null;

  return (
    <span className="tooltip">
      <span className="info-icon">
        <InfoIcon role="img" />
      </span>
      <div className="tooltip-info tooltip-under">{optionDescription}</div>
    </span>
  );
};

OptionDescriptionTooltip.propTypes = {
  optionDescription: PropTypes.string.isRequired,
};

export default OptionDescriptionTooltip;
