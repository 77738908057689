import React, { useEffect } from "react";
import { FilterPanel } from '@gsa/afp-component-library';
import {
    placeholderOption,
    vehicleTypeFilterKey
} from "../../../../vehicle-listings/filter-item-constants.jsx";
import { useSelector } from "react-redux";

const { FilterPanelField, useFilterPanel } =
    FilterPanel;

const VehicleSubtypeFilter = ({ subTypes, lastFilterState }) => ({ filter }) => {
    const {
    } = useSelector(state => state.leasingReducer);

    const { mergedFilters, clearOneFilter } = useFilterPanel();

    useEffect(() => {
        const diff = mergedFilters.filter((f) => {
            return !lastFilterState.find((lf) => lf.key === f.key && lf.value === f.value);
        });
        if ((diff.length === 0 && lastFilterState.length > 0) || lastFilterState.length === 0) return;

        let vehicleType = diff.find((f) => f.key === vehicleTypeFilterKey);
        if (vehicleType) clearOneFilter(filter);
    }, [mergedFilters])

    return (
        <FilterPanelField
            key={[filter.key]}
            filter={{
                ...filter,
                type: 'select',
                options: subTypes || [placeholderOption],
            }}
        />
    );
};


export default VehicleSubtypeFilter;