import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageViewer = ({ imgUrl, defaultImgUrl }) => {
  const [imageLink, setImageLink] = useState(imgUrl);

  const onImgUrlError = () => {
    setImageLink(defaultImgUrl);
  };
  return <img src={imageLink} alt="" onError={onImgUrlError} />;
};

ImageViewer.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  defaultImgUrl: PropTypes.string.isRequired,
};

export default ImageViewer;
