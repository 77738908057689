import { Modal, Button, connectModal, Alert } from '@gsa/afp-component-library'
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { generateSaveDraftModalContent } from './save-draft-modal-form';
import { saveDraftFormSchema } from './save-draft-modal-form-schema';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useSaveDraft from './save-draft-submit';
import { setRequisitionId } from '@/reducers/leasing';
import useLeasingNavigation from '@/hooks/useLeasingNavigation';
import { setReplacementVehicles } from '../../../../reducers/leasing';

export function SaveDraftModal({
    onSubmit,
    afterSubmit,
    onClose,
    values,
}) {
    const { addRequisitionIdToUrl } = useLeasingNavigation();
    const [saveDraft, { error, clearError, setError }] = useSaveDraft();
    const { account } = useSelector((state) => state.leasingReducer)

    let requisitionName = values?.requisitionName;
    if (!requisitionName) {
        if (account?.customer?.legacyCustomerNumber) {
            const today = moment();
            requisitionName = [
                account?.customer?.legacyCustomerNumber,
                `${today.format("MMMM")}${today.format("DD")}`.toUpperCase(),
                `${today.format("HHmm")}`,
            ].join("_");
        } else {
            requisitionName = "";
        }
    }

    const [modalFormContent, defaultValues] = generateSaveDraftModalContent({
        requisitionName
    });

    const handleSubmit = async (data) => {
        clearError();

        try {
            if (onSubmit) {
                await onSubmit(data);
            } else {
                const saved = await saveDraft(data);
                saved.dispatch(setRequisitionId(saved.data.requisitionId));
                saved.dispatch(setReplacementVehicles(
                    saved.data.requisitionLeasingCustomerAccount.vehicleReplacements
                        .map(vehicle => ({
                            ...vehicle,
                            vin: vehicle.serialNumberVin,
                            standardItem: {
                                standardItemNumber: 'ok'
                            }
                        }))
                ))
                addRequisitionIdToUrl(saved.data.requisitionId);
                afterSubmit?.(saved);
            }

            onClose();
        } catch (err) {
            setError(err?.error?.message || err?.error || err?.message || err);
        }
    };

    const closeModal = () => {
        onClose()
    }

    return (
        <Modal
            variant="small"
            onClose={closeModal}
            actions={
                <div>
                    <Button
                        variant="unstyled"
                        onClick={closeModal}
                        label="Cancel"
                    />
                    <Button
                        variant="primary"
                        label="Submit"
                        type="submit"
                        form="modal-form" // important to target the form inside the modal
                    />
                </div>
            }
        >
            <div>
                <h2>Save your draft requisition</h2>
                <p>Upon creating your draft, all uploads and inputs will be captured and autosaved until you submit your order.</p>
            </div>

            {error && <Alert className='margin-bottom-1' type='error'>{error}</Alert>}

            <FormGenerator
                id="modal-form" // important to give the form an id so that the submit button can target it
                schema={saveDraftFormSchema}
                content={modalFormContent}
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
}

export default connectModal(SaveDraftModal);