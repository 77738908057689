import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@apollo/client';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import {
  CREATE_REQUISITION_COMMENT,
  SUBMIT_REQUISITION,
} from '../../../../services/data-layer';
import { utcDateToMMDDYYYY } from '../../../../utilities/commonUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { AccordionUtilComponent } from '../../../../utilities/AccordionUtilComponent';
import AgencyInformationReview from '../../review-details/agency-information/AgencyInformationReview';
import DeliveryInformationReview from '../../review-details/delivery-information/DeliveryInformationReview';
import RequisitionSubmitModal from '../utils/RequisitionSubmissionModal';
import VehicleRequirementsReview from '../../review-details/vehicle-requirements/VehicleRequirementsReview';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import MasPrintPreviewModal from '../../../../components/MasReqPrintPreviewModal/MasReqPrintPreviewModal';
import { RequisitionStatus } from '../../../ReviewDetails/RequisitionDetailsUtils';
import ReqReturnedBanner from '../../../../components/ReqReturnedBanner/ReqReturnedBanner';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '../../../../components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown';
import CancelRequisitionButton from '../../../ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';
import { UserRoles } from '../../../../constants/user-constants';

const MasRequisitionReviewPage = ({ handlePageNavigation }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const navigate = useNavigate();

  const {
    draftRequisition,
    requisitionStateContext,
    deliveryStateContext,
    mailingStateContext,
    deliveryDate,
    specialDeliveryInstructions,
    additionalRequirements,
    selectedContractAgencyInformation,
  } = state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReturnerCO, setIsReturnerCO] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [isPrintPreview, setIsPrintPreview] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const printRef = useRef(null);
  const showReturnedBanner =
    draftRequisition?.requisitionStatus === RequisitionStatus.RETURNED ||
    draftRequisition?.requisitionStatus === RequisitionStatus.CANCELLED;

  const printReqModal = useModal();
  const DisplayMasReqPrintModal = connectModal(MasPrintPreviewModal);

  const {
    currentUser: { id: currentUserId, roles },
  } = useCurrentUser();

  let currentUserCreatedRequisition = false;
  if (draftRequisition && draftRequisition.createdByInfo) {
    currentUserCreatedRequisition =
      currentUserId === draftRequisition.createdByInfo.id;
  } else {
    currentUserCreatedRequisition = true;
  }

  const canEdit = !(
    draftRequisition?.requisitionStatus === RequisitionStatus.RETURNED &&
    !currentUserCreatedRequisition
  );

  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT, {
    onCompleted: () => {
      setShowAddCommentModal(false);
    },
  });

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    const { appURLs } = window.AFP_CONFIG;
    submitRequisitionComment({
      variables: {
        requisitionId: draftRequisition?.requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  const handleClosePrintModal = () => {
    printReqModal.closeModal();
  };

  const ability = useAppAbility();
  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const isSubmitter = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );

  const isContractingOfficer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Solicitation),
    [ability],
  );

  const isSiteAdmin = roles?.some((role) => role.name === UserRoles.SITE_ADMIN);
  const isOrderingAdmin = roles?.some((role) => role.name === UserRoles.ORDERING_ADMIN);

  console.log(isApprover);
  console.log(isSubmitter);
  const pageStyle = `
    @media print {
      footer {
        position: fixed;
        bottom: 0;
      }
    }
  `;

  const printSummary = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
    onAfterPrint: () => setIsPrintPreview(false),
  });

  useEffect(() => {
    if (isPrintPreview) {
      printSummary();
    }
  }, [isPrintPreview]);

  const handlePrint = () => {
    setIsPrintPreview(true);
  };

  const handlePrintSummary = () => {
    setIsExpanded(true);
    printReqModal.openModal();
  };

  const [submitRequisitionMutation] = useMutation(SUBMIT_REQUISITION, {
    fetchPolicy: 'no-cache',
  });

  const submitMASReq = async () => {
    const requisitionId = draftRequisition?.requisitionId;
    const results = await submitRequisitionMutation({
      variables: {
        requisitionId,
        submitComment: '',
      },
    });

    const resubmittedStatus = results.data.submitRequisition.resubmittedStatus
      ? '&resubmittedStatus=true'
      : '';

    navigate(
      `/my-requisitions?requisitionId=${requisitionId}&draftName=${draftRequisition?.friendlyName}${resubmittedStatus}`,
    );
  };

  return (
    <div className="mas-req-review" ref={printRef}>
      <div>
        <div className="title">Review requisition summary and submit</div>
        <div className="title-desc">
          Please review all entered information below prior to submitting your
          requisition. If you would like to make any edits, you may select the
          “Edit” link on the right of each section or navigate back within the
          requisition.{' '}
        </div>
      </div>
      <div className="vehicle-spec-review">
        <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
          <RequisitionActionsButtonDropdown
            menuItems={{
              [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
                onClick: handlePrintSummary,
              },
              [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
                onClick: () => setShowAddCommentModal(true),
              },
              // NOTE: This is commented out because old code did not include this action
              // [REQUISITION_BUTTON_DROPDOWN_ACTIONS.SUBMIT_TO_APPROVER]: {
              //   onClick: () => setIsModalOpen(true),
              // },
            }}
          />
        </div>
        {showReturnedBanner && (
          <ReqReturnedBanner
            requisitionId={draftRequisition?.requisitionId}
            setIsReturnerCO={setIsReturnerCO}
            isMas
          />
        )}
      </div>

      <div className="vehicle-req-review">
        <AccordionUtilComponent
          label="MAS vehicle requirements"
          printPreview={isPrintPreview}
          isMas
          content={
            <VehicleRequirementsReview
              title="MAS vehicle requirements"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              additionalRequirements={additionalRequirements}
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>

      <div className="agency-information-review">
        <AccordionUtilComponent
          label="Agency information"
          printPreview={isPrintPreview}
          isMas
          content={
            <AgencyInformationReview
              title="Agency information"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              selectedContractAgencyInformation={
                selectedContractAgencyInformation
              }
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>

      <div className="delivery-info-review">
        <AccordionUtilComponent
          label="Delivery information"
          printPreview={isPrintPreview}
          isMas
          content={
            <DeliveryInformationReview
              title="Delivery information"
              editPage={(editStep) => {
                handlePageNavigation(editStep);
              }}
              requisitionAddress={requisitionStateContext}
              mailingAddress={mailingStateContext}
              deliveryAddress={deliveryStateContext}
              deliveryDate={utcDateToMMDDYYYY(deliveryDate)}
              specialDeliveryInstructions={specialDeliveryInstructions}
              isEditable={canEdit}
            />
          }
          expanded={isExpanded}
        />
      </div>
      {((isSubmitter && currentUserCreatedRequisition) ||
        (isApprover && currentUserCreatedRequisition) || isSiteAdmin || isOrderingAdmin) &&
        (!isContractingOfficer ) && (
          <div className="button-section">
            <Button
              variant="outline"
              type="button"
              data-testid="review-submit-prev-btn"
              onClick={() => {
                handlePageNavigation('previous');
              }}
              leftIcon={{ name: 'arrow_back' }}
              label="Previous"
            />
            <CancelRequisitionButton
              requisitionId={draftRequisition?.requisitionId}
            />
            <Button
              variant="default"
              data-testid="review-submit-action-req-btn"
              type="button"
              onClick={() => {
                if (!(isReturnerCO || isApprover)) {
                  return submitMASReq();
                }
                return setIsModalOpen(true);
              }}
              leftIcon={{ name: 'check' }}
              label={
                isReturnerCO || (isApprover && currentUserCreatedRequisition)
                  ? 'Submit to contracting officer'
                  : 'Submit to approver'
              }
            />
          </div>
        )}
      {isModalOpen && (
        <RequisitionSubmitModal
          requisitionId={draftRequisition?.requisitionId}
          onClose={() => setIsModalOpen(false)}
          draftName={draftRequisition?.friendlyName}
        />
      )}

      {showAddCommentModal && (
        <CommentModal
          data-testid="add-comment-modal-urg-req"
          title="Post a comment"
          body="Your comment will be added to the activity tracker and viewable by all participating parties"
          isOpen={showAddCommentModal}
          onSubmit={handleAddComment}
          onCancel={handleCancelComment}
          isRequired
        />
      )}

      <DisplayMasReqPrintModal
        isOpen={printReqModal.isOpen}
        handleClose={handleClosePrintModal}
        handlePrint={handlePrint}
        reqStatus={draftRequisition?.requisitionStatus}
      />
    </div>
  );
};

MasRequisitionReviewPage.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  currentStep: PropTypes.shape({
    text: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default MasRequisitionReviewPage;
