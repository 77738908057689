import React from 'react';

const CheckedIcon = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5992 2.29456C14.5992 2.0473 14.5063 1.80004 14.3391 1.62202L13.0756 0.27693C12.9084 0.0989034 12.6761 0 12.4439 0C12.2116 0 11.9793 0.0989034 11.8121 0.27693L5.71767 6.77488L2.98632 3.85723C2.81909 3.67921 2.58683 3.5803 2.35457 3.5803C2.12232 3.5803 1.89006 3.67921 1.72283 3.85723L0.459348 5.20232C0.292122 5.38035 0.199219 5.6276 0.199219 5.87486C0.199219 6.12212 0.292122 6.36938 0.459348 6.54741L5.08593 11.4728C5.25315 11.6508 5.48541 11.7497 5.71767 11.7497C5.94993 11.7497 6.18219 11.6508 6.34941 11.4728L14.3391 2.9671C14.5063 2.78908 14.5992 2.54182 14.5992 2.29456Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedIcon;
