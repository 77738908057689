import { useContext } from 'react';
import { OrderModsContext } from './OrderModsProvider';

export default function useOrderModsState() {
  const context = useContext(OrderModsContext);
  if (!context) {
    throw new Error(
      'useOrderModssState can only be used within OrderModsProvider',
    );
  }
  return context;
}
