import { TagsToWatch } from '../constants/constants';

export function isTaggedSin(standardItem) {
  if (!standardItem?.tags?.value?.includes) {
    return false;
  }

  return (
    TagsToWatch.filter((tag) => standardItem.tags.value.includes(tag)).length >
    0
  );
}

export function isTaggedOptions(taggedOptions) {
  return taggedOptions?.length > 0;
}
