import React from 'react';
import { Accordion } from '@gsa/afp-component-library';
import DeliveryAddress from './DeliveryAddress/DeliveryAddress';

const DeliveryInformation = () => {
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={[
          {
            id: 'orderChange',
            title: (
              <div
                className="add-options-accordion-title"
                data-testid="delivery-information-title"
              >
                Delivery Information
              </div>
            ),
            content: (
              <div className="ordermod-address-accordion-container">
                <DeliveryAddress />
              </div>
            ),
            expanded: false,
          },
        ]}
        className="add-options-accordion"
      />
    </>
  );
};

export default DeliveryInformation;
