import React, { useEffect, useMemo, useState, } from 'react';
import { AFPTable, Pagination, Spinner } from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';
import { LeasingSteps, RequisitionViewMode } from "../../leasing-consts";
import SectionEditButton from "../../../../components/ReviewComponents/SectionEditButton/SectionEditButton";
import { useGetLeasingReplacementVehicles } from "../../../../requests/leasing";

const ReviewReplacementVehicles = ({onEdit}) => {
    const [vehicles, setVehicles] = useState({ rows: [], count: 0 })
    const [pagination, setPagination] = useState({
        order: [["createdAt", "DESC"]],
        limit: 10,
        offset: 1
    })
    const { requisitionId, viewMode } = useSelector((state) => state.leasingReducer);
    const [getReplacementVehicles, { loading }] = useGetLeasingReplacementVehicles();

    useEffect(() => {
        getReplacementVehicles({
            variables: {
                requisitionId,
                offset: (pagination.offset - 1) * pagination.limit,
                limit: pagination.limit,
                order: pagination.order
            }
        }).then(({ data }) => {
            setVehicles(data);
        });
    }, [pagination])

    const columns = useMemo(
        () => [
            {
                Header: 'License Plate',
                accessor: 'tagNumber',
                sortable: false,
            },
            {
                Header: 'VIN',
                accessor: 'vin',
                sortable: false,
            },
            {
                Header: 'Old SIN',
                accessor: 'standardItemNumber',
                sortable: false,
            }
        ],
        [vehicles.rows],
    );
    return (
        <div role="region" tabIndex="0" className="replacement-vehicle">
            <div className="title-section">
                <span>Replacement vehicles</span>
                <SectionEditButton
                    showButton={viewMode === RequisitionViewMode.EDIT}
                    id="replacement-vehicle-edit"
                    step={LeasingSteps.REPLACEMENT_VEHICLES}
                    editPage={onEdit}
                />
            </div>

            <div>
                {loading ? <Spinner
                    aria-busy="true"
                    size="small"
                    className="row-spinner-address"
                /> : (
                    <>
                        <AFPTable
                            testId="additional-requirements-files-table"
                            columns={columns}
                            data={vehicles.rows}
                        />
                        <Pagination
                            buttonVariant="primary"
                            variant="advanced"
                            itemsCount={vehicles.count}
                            currentPage={pagination.offset}
                            itemsPerPage={pagination.limit}
                            onPageChange={(value, limit) => {
                                setPagination({ ...pagination, offset: value, limit })
                            }}
                        /></>
                )}

            </div>
        </div>
    );
};


export default ReviewReplacementVehicles;
