import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import React from 'react';

const OrderChanges = ({ modifications }) => {
  const getOrderChanges = () => {
    console.log('modifications', modifications);

    return [
      {
        id: 'selectedOrders',
        title: <div className="add-options-accordion-title">Order changes</div>,
        content: (
          <div className="order-details-acc-body">
            <p className="order-mod-acc-sub-title">Order Changes</p>
            <div className="order-mod-row-titles">
              <div className="order-mod-row-column">
                <div className="order-mod-row-title">
                  Quantity change per order
                </div>
                <div className="order-mod-title-desc">
                  {modifications?.quantityChangePerOrder.length > 0
                    ? modifications?.quantityChangePerOrder
                    : '0'}
                </div>
              </div>
              <div className="order-mod-row-column">
                <div className="order-mod-row-title">
                  Price change per vehicle
                </div>
                <div className="order-mod-title-desc">
                  {'$'}
                  {modifications?.priceChangePerOrder.length > 0
                    ? parseFloat(modifications?.priceChangePerOrder).toFixed(2)
                    : '0.00'}
                </div>
              </div>
              <div className="order-mod-row-column">
                <div className="order-mod-row-title">Selected color change</div>
                <div className="order-mod-title-desc">
                  {modifications?.makeColorName ?? 'N/A'}
                </div>
              </div>
            </div>
            <div className="order-mod-row-titles">
              <div className="order-mod-row-column">
                <div className="order-mod-row-title">Shipment Date</div>
                <div className="order-mod-title-desc">
                  {modifications?.shipmentDate?.length > 0
                    ? modifications?.shipmentDate
                    : '-'}
                </div>
              </div>
            </div>
          </div>
        ),
        expanded: false,
      },
    ];
  };
  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        items={getOrderChanges()}
        className="add-options-accordion"
      />
    </>
  );
};
OrderChanges.propTypes = {
  modifications: PropTypes.objectOf(Object).isRequired,
};
export default OrderChanges;
