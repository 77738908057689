import { useState } from 'react';
import { RadioButton, PageTitle } from '@gsa/afp-component-library';
import './vehicle-tabs.scss';

export default function OptionSelection({selectedOption, setSelectedOption}) {
  const OPTIONS = {
    REPLACE_VEHICLE: 'REPLACE_VEHICLE',
    TURN_IN: 'TURN_IN',
    DO_NOT_REPLACE: 'DO_NOT_REPLACE',
  };

  return (
    <>
      <PageTitle title="Select a request action" />
      <div className="option-selections-container">
        <RadioButton
          key={'replace-vehicle-button'}
          name={'replace-vehicle'}
          label={'Replace vehicle'}
          value={OPTIONS.REPLACE_VEHICLE}
          checked={OPTIONS.REPLACE_VEHICLE === selectedOption}
          onChange={(ev) => {
            setSelectedOption(ev.target.value);
          }}
        />

        <RadioButton
          key={'turn-in-button'}
          name={'turn-in'}
          label={'Turn in vehicle'}
          value={OPTIONS.TURN_IN}
          checked={OPTIONS.TURN_IN === selectedOption}
          onChange={(ev) => {
            setSelectedOption(ev.target.value);
          }}
        />

        <RadioButton
          key={'do-not-replace-button'}
          name={'do-not-replace'}
          label={'Do not replace'}
          value={OPTIONS.DO_NOT_REPLACE}
          checked={OPTIONS.DO_NOT_REPLACE === selectedOption}
          onChange={(ev) => {
            setSelectedOption(ev.target.value);
          }}
        />
      </div>
    </>
  );
}
