import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { OrderStatus } from './Filters/OrderFilterHelpers';

const cellStatus = (value) => {
  let orderStatus;
  // eslint-disable-next-line default-case
  switch (value) {
    case OrderStatus.COMPLETED:
      orderStatus = {
        label: 'Completed',
        status: 'Inactive-Gray',
      };
      break;
    case OrderStatus.CANCELLED:
      orderStatus = {
        label: 'Cancelled',
        status: 'Inactive-Gray',
      };
      break;
    case OrderStatus.RECEIVED_BY_GSA:
      orderStatus = {
        label: 'Received by GSA',
        status: 'Ready-Gray',
      };
      break;
    case OrderStatus.AWARDED:
      orderStatus = {
        label: 'Awarded',
        status: 'Ready-Gray',
      };
      break;
    case OrderStatus.IN_PROCESS:
      orderStatus = {
        label: 'In process',
        status: 'Info-Gray',
      };
      break;
    case OrderStatus.DRAFT:
      orderStatus = {
        label: 'Draft',
        status: 'Info-Gray',
      };
      break;
    case OrderStatus.PENDING:
      orderStatus = {
        label: 'Pending',
        status: 'Info-Gray',
      };
      break;
    default:
      orderStatus = {
        label: 'N/A',
        status: 'Ready-Gray',
      };
  }

  return orderStatus;
};

const OrderStatusTag = ({ value }) => {
  if (!value) return null;
  const { status, label } = cellStatus(value);
  return (
    <StatusBadge data-testid="order-status-tag" variant={status}>
      {label}
    </StatusBadge>
  );
};

OrderStatusTag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default OrderStatusTag;
