import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
  Icon,
  Checkbox,
} from '@gsa/afp-component-library';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import './AdditionalReqForm.scss';
import { useQuery } from '@apollo/client';
import AdditionalReqFilesTable from '../AdditionalReqFilesTable/AdditionalReqFilesTable';
import { GET_REQUISITION_ATTACHMENT_TYPES } from '../../../services/data-layer';

export const AdditionalReqForm = ({
  index,
  setItemToDelete,
  displayDeleteRequirementModal,
  requisitionType,
}) => {
  const { formState } = useFormContext();

  const { errors } = formState;

  const attachments = useWatch({
    name: `additionalRequirements.${index}.attachments`,
  });
  const [showDocs, setShowDocs] = useState(Boolean(attachments?.length));
  const requirementMapObj = {
    req_additional_requirement: 'Requisition Additional Requirement',
    req_igce: 'Requisition IGCE Documentation',
  };
  const [attachmentTypes, setAttachmentTypes] = useState([]);

  const { data: requisitionAttachmentTypeData } = useQuery(
    GET_REQUISITION_ATTACHMENT_TYPES,
  );

  useEffect(() => {
    const types = [
      {
        label: '-select-',
        value: -1,
        disabled: true,
      },
    ];

    if (
      !requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes?.length
    ) {
      return setAttachmentTypes(types);
    }

    return setAttachmentTypes(
      types.concat(
        requisitionAttachmentTypeData.getAllRequisitionAttachmentTypes
          .filter(({ attachmentType }) =>
            Object.keys(requirementMapObj).includes(attachmentType),
          )
          .map(({ requisitionAttachmentTypeId, attachmentType }) => ({
            value: requisitionAttachmentTypeId,
            label: requirementMapObj[attachmentType],
          })),
      ),
    );
  }, [requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes]);
  const toggleShowDocs = () => {
    if (attachments?.length || !showDocs) setShowDocs(true);
    else setShowDocs(false);
  };

  const {
    fields: reqFiles,
    append,
    remove,
    update,
  } = useFieldArray({
    name: `additionalRequirements.${index}.attachments`,
  });

  return (
    <section className="section-container">
      <div className="above-section-container">
        <div className="req-title">
          Additional requirement {index + 1}
          {index === 0 && <RequiredFieldIndicator />}
        </div>

        {index !== 0 && (
          <div className="usa-button--unstyled delete-btn">
            <Icon className="usa-icon--size-2" iconName="close" />
            <Button
              type="button"
              variant="unstyled"
              label="Delete this requirement"
              onClick={() => {
                setItemToDelete(index);
                displayDeleteRequirementModal.openModal();
              }}
            />
          </div>
        )}
      </div>
      <div className="form-section-container">
        <div className="form-section">
          <Controller
            rules={{ required: 'Description is required' }}
            // control={control}
            name={`additionalRequirements.${index}.description`}
            render={({ field: { value, onChange, ref } }) => (
              <TextInput
                characterLimit={100}
                type="textarea"
                label={
                  <>
                    Enter a description of your additional requirement{' '}
                    <RequiredFieldIndicator />
                  </>
                }
                onChange={onChange}
                value={value}
                inputRef={ref}
                name={`additionalRequirements.${index}.description`}
                data-testid={`additional-requirement-description-input-${index}`}
                inputClass="description-textarea"
                errorMessage={
                  errors?.additionalRequirements?.[index]?.description?.message
                }
              />
            )}
          />

          <Checkbox
            className="supporting-docs-checkbox"
            name={`additionalRequirements.${index}.supportingDocs`}
            checked={showDocs}
            onChange={toggleShowDocs}
            label="Supporting documents"
          />

          {showDocs && attachmentTypes?.length > 1 && (
            <AdditionalReqFilesTable
              reqFiles={reqFiles}
              append={append}
              remove={remove}
              update={update}
              additionalRequirementIndex={index}
              attachmentTypes={attachmentTypes}
              requisitionType={requisitionType}
            />
          )}

          {requisitionType === 'AREQ' && (
            <Controller
              name={`additionalRequirements.${index}.priceEstimate`}
              rules={{
                required: 'Amount is required',
              }}
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  type="number"
                  label={
                    <>
                      Price estimate from Independent Government Cost Estimate
                      (IGCE) <RequiredFieldIndicator />
                    </>
                  }
                  onChange={onChange}
                  value={value}
                  prefix="$"
                  inputRef={ref}
                  name={`additionalRequirements.${index}.priceEstimate`}
                  data-testid={`additional-requirement-price-estimate-input-${index}`}
                  errorMessage={
                    errors?.additionalRequirements?.[index]?.priceEstimate
                      ?.message
                  }
                />
              )}
            />
          )}
        </div>
      </div>
    </section>
  );
};

AdditionalReqForm.propTypes = {
  index: PropTypes.number.isRequired,
  setItemToDelete: PropTypes.func.isRequired,
  displayDeleteRequirementModal: PropTypes.instanceOf(Object).isRequired,
  requisitionType: PropTypes.string,
};

AdditionalReqForm.defaultProps = {
  requisitionType: 'AREQ',
};
