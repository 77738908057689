import { useContext } from 'react';
import { VehicleContext } from './vehicle-provider';

export default function useVehicleState() {
  const context = useContext(VehicleContext);
  if (!context) {
    throw new Error(
      'useVehicleState can only be used within Vehicle List Provider',
    );
  }
  return context;
}
