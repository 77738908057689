import React, { useContext } from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { getVehicleListingInfo } from './vehicleListingUtil';
import { Purchase_Vehicles } from '../../constants';

const Breadcrumbs = ({ mode }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const { isDomesticShipment, vehicleData } = getVehicleListingInfo(state);

  return (
    <DSBreadcrumbs
      trail={[
        <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
          Home
        </a>,
        <Link to="/purchase">{Purchase_Vehicles}</Link>,
        <Link
          to={{
            pathname: '/vehicle-listings',
            search: `?mode=${mode}&domestic=${isDomesticShipment}`,
            state: vehicleData,
          }}
        >
          Vehicle Listing
        </Link>,
      ]}
      current="Vehicle Requisition"
    />
  );
};
export default Breadcrumbs;

Breadcrumbs.propTypes = {
  mode: PropTypes.string.isRequired,
};
