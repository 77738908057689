export const orderChangesFeedback = {
  quantityChangePerOrder: [
    'Please enter a valid Quantity',
    'Vehicle quantities may only decrease',
    'Total vehicle quantities on any order cannot be less than 0',
  ],
  priceChangePerOrder: ['Please enter a valid Price'],
  fob: [],
  shipmentDate: [],
  surCharge: [],
  inspectionPoint: [],
  originState: [],
  buyerCode: [],
  originCity: [],
};

export const validateSelectedQuantityChange = (
  selectedOrdersForMod,
  quantityChangePerOrder,
) => {
  // eslint-disable-next-line no-case-declarations
  const selectedQuantity = parseInt(quantityChangePerOrder, 10);
  if (selectedQuantity < 0) {
    // eslint-disable-next-line no-case-declarations
    const absQuantity = Math.abs(selectedQuantity);
    const orderForTerm = selectedOrdersForMod?.filter(
      (item) => parseInt(item.quantity, 10) === absQuantity,
    );
    if (orderForTerm?.length > 0) {
      if (orderForTerm?.length === selectedOrdersForMod?.length) {
        return {
          allOrdersForTermination: true,
          orderAffected: orderForTerm,
        };
      }
      return {
        allOrdersForTermination: false,
        orderAffected: orderForTerm,
      };
    }
  }
  return null;
};

export const contactFormHasChanges = (contact) => {
  return Boolean(
    contact?.firstName ||
      contact?.lastName ||
      contact?.email ||
      contact?.phone?.countryCode ||
      contact?.phone?.number ||
      contact?.phone?.extension ||
      contact?.fax?.countryCode ||
      contact?.fax?.number ||
      contact?.fax?.extension,
  );
};

export const addressFormHasChanges = (sectionId, address, contact) => {
  return Boolean(
    address?.[`${sectionId}-sameAsRequisitioningAddress`] ||
      address?.isMilitary ||
      address?.militaryAddress?.rankAndFullName ||
      address?.militaryAddress?.militaryOrDiplomaticAddress ||
      (address?.militaryAddress?.postOffice &&
        address?.militaryAddress?.postOffice !== '-1') ||
      address?.countryCode ||
      address?.addressLine1 ||
      address?.addressLine2 ||
      address?.city ||
      (address?.stateCode && address?.stateCode !== '-1') ||
      address?.zipcode ||
      (address?.isSameAsRequisition && sectionId !== 'requisitionAddress') ||
      contactFormHasChanges(contact),
  );
};

export const addressFormHasChangesFromOrder = (
  orderAddress,
  orderContact,
  formAddress,
  formContact,
) => {
  if (!formAddress || !formContact) return false;

  let orderAddressStateCode = orderAddress?.stateCode?.split('-') || [];
  // remove any country prefix from state code if there is any (e.g. US-CA)
  orderAddressStateCode =
    orderAddressStateCode[orderAddressStateCode.length - 1];

  return Boolean(
    orderAddress?.addressLine1 !== formAddress?.addressLine1 ||
      orderAddress?.addressLine2 !== formAddress?.addressLine2 ||
      orderAddress?.city !== formAddress?.city ||
      orderAddressStateCode !== formAddress?.stateCode ||
      orderAddress?.zipcode !== formAddress?.zipcode ||
      orderAddress?.entityName !== formAddress?.entityName ||
      orderAddress?.countryCode !== formAddress?.countryCode ||
      orderContact?.firstName !== formContact?.firstName ||
      orderContact?.lastName !== formContact?.lastName ||
      orderContact?.email !== formContact?.email ||
      orderContact?.phoneCountryCode !== formContact?.phone?.countryCode ||
      (orderContact?.phoneNumber || '') !== formContact?.phone?.number ||
      (orderContact?.phoneExtension || '') !== formContact?.phone?.extension ||
      orderContact?.faxCountryCode !== formContact?.fax?.countryCode ||
      (orderContact?.faxNumber || '') !== formContact?.fax?.number ||
      (orderContact?.faxExtension || '') !== formContact?.fax?.extension,
  );
};
