import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import { GET_STANDARD_CODES } from '../../../services/data-layer';
import PaintAndGraphics from '../PaintAndGraphics/PaintAndGraphics';
import CostSummary from '../CostSummary/CostSummary';
import OptionsPerVehicle from '../OptionsPerVehicle/OptionsPerVehicle';
import Justification from '../Justification/Justification';
import './ReviewDetails.scss';
import AddressReviewGroup from '../AddressReviewGroup/AddressReviewGroup';
import AgencyFinanceInfo from '../AgencyFinancial/AgencyFinancialInformation';
import TaggedOptionDetails from '../TaggedOptionDetails/TaggedOptionDetails';
import AreqDetails from '../../Areq/AreqDetails/AreqDetails';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import groupOptionsPerCatalog from '../../../pages/VehicleRequisition/utils/VehicleRequisitionUtils';
import { filterOptions } from '../ReviewOptions/reviewOptionsUtil';
import { canEditInReview } from '@/utilities/requisitionReviewUtils.jsx';
import { isAreqType } from '@/pages/non-standard-purchase/constants.jsx';
import VehicleRequisitionContextActions from '@/context/VehicleRequisitionContext/VehicleRequisitionContextActions.jsx';

const ReviewDetails = ({
  selectedContractCostBreakdown,
  selectedContractAgencyInformation,
  requisitionAddress,
  mailingAddress,
  deliveryAddress,
  getStandardItem,
  nonLowBidJustification,
  vehicleColors,
  taggedOptions,
  optionalReqData,
  isEditable,
  expanded,
  calculatedPriceData,
  currentStandardItem,
  agencyReferenceNumber,
  additionalRequirements,
  isAreq,
  printPreview,
  toggleViewSelectedOptions,
}) => {
  const {
    dispatch,
    state: {
      areqPrintPreview = {},
      currentStep,
      requisitionAttachmentTypes,
      paintAndGraphicsOptions,
      draftRequisition,
      allActiveContracts,
      allCostBreakdown,
      dealershipDeliveryContext,
      deliveryStateContext,
    },
  } = useContext(VehicleRequisitionContext);

  const [primary, setPrimary] = useState({});
  const [secondary, setSecondary] = useState({});

  const [getStandardCodes] = useLazyQuery(GET_STANDARD_CODES, {
    fetchPolicy: 'c',
  });

  useEffect(() => {
    if (!currentStandardItem) {
      return;
    }

    const retrievePocs = async () => {
      const { data } = await getStandardCodes({
        variables: {
          filters: {
            operator: 'EQ',
            key: 'code',
            value: currentStandardItem.vehicleTypeCode?.parentCode,
          },
        },
      });

      const standardCodePocs = data.getStandardCodes?.rows.reduce(
        (list, code) => {
          return list.concat(code.standardsVehicleGroupPocs || []);
        },
        [],
      );

      setPrimary(
        standardCodePocs.find((c) => c.pocType === 'PocPrimaryEngineer') || {},
      );

      setSecondary(
        standardCodePocs.find((c) => c.pocType === 'PocSecondaryEngineer') ||
          {},
      );
    };
    retrievePocs();
  }, [currentStandardItem]);

  const ability = useAppAbility();
  const getCurrentUser = useCurrentUser();
  const requisitionEditable =
    isEditable &&
    canEditInReview(ability, draftRequisition, getCurrentUser.currentUser);

  const [options, setOptions] = useState([]);
  let items = [];

  useEffect(() => {
    if (!optionalReqData || !selectedContractCostBreakdown) {
      return;
    }

    const groupedPerVehicleOptions = groupOptionsPerCatalog(
      optionalReqData,
      selectedContractCostBreakdown.perVehicleOptions,
    );

    const groupedPerOrderOptions = groupOptionsPerCatalog(
      optionalReqData,
      selectedContractCostBreakdown.perOrderOptions,
    );

    const myOptions = filterOptions(
      groupedPerVehicleOptions.concat(groupedPerOrderOptions),
      paintAndGraphicsOptions,
      taggedOptions,
    );

    setOptions(myOptions);
  }, [optionalReqData, selectedContractCostBreakdown]);

  const editPage = (step) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP,
      payload: {
        key: step,
      },
    });
  };

  if (isAreq) {
    items.push({
      id: 'AREQ details',
      title: 'AREQ details',
      content: (
        <AreqDetails
          agencyReferenceNumber={agencyReferenceNumber}
          additionalRequirements={additionalRequirements}
          isEditable={requisitionEditable}
          createdByInfo={draftRequisition?.createdByInfo}
          allActiveContracts={allActiveContracts}
          allCostBreakdown={allCostBreakdown}
          primary={primary}
          secondary={secondary}
        />
      ),
      expanded,
    });
  }

  items.push({
    id: 'Options per vehicle',
    title: 'Options per vehicle',
    content: (
      <OptionsPerVehicle
        selectedContractCostBreakdown={selectedContractCostBreakdown}
        isEditable={true}
        toggleViewSelectedOptions={toggleViewSelectedOptions}
        options={options}
        onEdit={editPage}
        isAreq={isAreq}
      />
    ),
    expanded,
  });
  items.push({
    id: 'Paint and graphics',
    title: 'Paint and graphics',
    content: (
      <PaintAndGraphics
        vehicleColors={vehicleColors}
        taggedOptions={taggedOptions}
        paintAndGraphicsOptions={paintAndGraphicsOptions}
        requisition={draftRequisition}
        isEditable={requisitionEditable}
        requisitionAttachmentTypes={requisitionAttachmentTypes}
        onEdit={editPage}
      />
    ),
    expanded,
  });

  if (taggedOptions?.length > 0) {
    items.push({
      id: 'Options details',
      title: 'Options details',
      content: (
        <TaggedOptionDetails
          taggedOptions={taggedOptions}
          draftRequisition={draftRequisition}
          isEditable={requisitionEditable}
          requisitionAttachmentTypes={requisitionAttachmentTypes}
          onEdit={editPage}
        />
      ),
      expanded,
    });
  }

  items.push({
    id: 'Cost summary',
    title: 'Cost summary',
    content: (
      <CostSummary
        calculatedPriceData={calculatedPriceData}
        selectedContractCostBreakdown={selectedContractCostBreakdown}
        requisition={draftRequisition}
        isEditable={requisitionEditable}
        isAreq={isAreq}
      />
    ),
    expanded,
  });

  if (nonLowBidJustification && currentStep.key !== 'REVIEW_VEHICLE_BUILD') {
    items.push({
      id: 'Non-low price justification',
      title: 'Non-low price justification',
      content: (
        <Justification
          title="Justification for your non-low price selection"
          justificationData={nonLowBidJustification}
          isEditable={requisitionEditable}
        />
      ),
      expanded,
    });
  }

  if (
    selectedContractAgencyInformation?.agency &&
    currentStep.key !== 'REVIEW_VEHICLE_BUILD'
  ) {
    items.push({
      id: 'Agency information',
      title: 'Agency information',
      content: (
        <>
          <AgencyFinanceInfo
            selectedContractAgencyInformation={
              selectedContractAgencyInformation
            }
            standardItemData={getStandardItem}
            isEditable={requisitionEditable}
            calculatedPriceData={calculatedPriceData}
            isAreq={isAreq || isAreqType(draftRequisition?.requisitionType)}
          />
        </>
      ),
      expanded,
    });
  }

  if (
    currentStep.key !== 'REVIEW_VEHICLE_BUILD' &&
    (requisitionAddress?.addressLine1 ||
      requisitionAddress?.militaryOrDiplomaticAddress)
  ) {
    items.push({
      id: 'Delivery information',
      title: 'Delivery information',
      content: (
        <AddressReviewGroup
          requisitionAddress={requisitionAddress}
          mailingAddress={mailingAddress}
          deliveryAddress={deliveryAddress}
          isEditable={requisitionEditable}
          dealershipAddressInfo={dealershipDeliveryContext}
          dealershipCode={
            deliveryStateContext?.deliveryDealership?.dealershipDeliveryCode ||
            ''
          }
        />
      ),
      expanded,
    });
  }

  if (printPreview) {
    items = items.filter(({ title }) => areqPrintPreview[title]);
  }

  return (
    <div className="review-detail">
      <Accordion items={items} />
    </div>
  );
};

ReviewDetails.propTypes = {
  selectedContractCostBreakdown: PropTypes.instanceOf(Object).isRequired,
  selectedContractAgencyInformation: PropTypes.instanceOf(Object).isRequired,
  requisitionAddress: PropTypes.instanceOf(Object),
  mailingAddress: PropTypes.instanceOf(Object),
  deliveryAddress: PropTypes.instanceOf(Object),
  getStandardItem: PropTypes.instanceOf(Object),
  nonLowBidJustification: PropTypes.string,
  vehicleColors: PropTypes.instanceOf(Object).isRequired,
  taggedOptions: PropTypes.instanceOf(Object).isRequired,
  optionalReqData: PropTypes.instanceOf(Object).isRequired,
  calculatedPriceData: PropTypes.instanceOf(Object).isRequired,
  currentStandardItem: PropTypes.instanceOf(Object).isRequired,
  agencyReferenceNumber: PropTypes.string.isRequired,
  additionalRequirements: PropTypes.instanceOf(Array).isRequired,
  isAreq: PropTypes.bool,
  isEditable: PropTypes.bool,
  expanded: PropTypes.bool,
  printPreview: PropTypes.bool,
  toggleViewSelectedOptions: PropTypes.func,
};

ReviewDetails.defaultProps = {
  getStandardItem: undefined,
  requisitionAddress: undefined,
  mailingAddress: undefined,
  deliveryAddress: undefined,
  nonLowBidJustification: undefined,
  isAreq: false,
  isEditable: false,
  expanded: false,
  printPreview: false,
  toggleViewSelectedOptions: null,
};

export default ReviewDetails;
