import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import './MinimumRequirementsSection.scss';
import { generateEcClarification } from '../VendorClarifications/vendorClarificationUtil';
import { InfoIcon } from '../../assets/images/info-icon';
import Clarifications from '../AddOptions/Clarifications';
import OptionDescriptionTooltip from '../AddOptions/components/OptionDescriptionTooltip/OptionDescriptionTooltip';
import { extractOptionDescription } from '../AddOptions/AddOptionsUtils';

export const MinimumRequirementsSection = ({
  minimumRequirementsData,
  lowestBidData,
}) => {
  const minimumRequirementsRef = useRef(null);
  const simpleBarInstanceRef = useRef(null);

  useEffect(() => {
    if (minimumRequirementsRef.current && !simpleBarInstanceRef.current) {
      simpleBarInstanceRef.current = new SimpleBar(minimumRequirementsRef.current);

      const contentWrapper = minimumRequirementsRef.current.querySelector(
        '.simplebar-content-wrapper'
      );
      if (contentWrapper) {
        contentWrapper.tabIndex = 0;
      }
    }
    
    return () => {
      if (simpleBarInstanceRef.current) {
        simpleBarInstanceRef.current.unMount();
        simpleBarInstanceRef.current = null;
      }
    };
  }, [minimumRequirementsData]);

  const getInnerAccordionContent = (minReq) => {
    return (
      <table className="usa-table full-width-table" role="presentation">
        <tbody>
          {minReq?.options?.map((option) => (
            <tr key={option?.optionCode}>
              <td
                data-testid={`${minReq?.optionCategoryCode}-${option?.optionCode}-optionDescription`}
              >
                {option?.optionCode} - {option?.optionDescription}
                <OptionDescriptionTooltip
                  optionDescription={extractOptionDescription(option)}
                />
              </td>
              <td
                data-testid={`${minReq?.optionCategoryCode}-${option?.optionCode}-associationText`}
                className="contract-cell"
              >
                {option?.associationText}
              </td>

              {option?.vendorValues?.map((value) => {
                const activateLowestBid =
                  value?.modelName === lowestBidData?.modelName &&
                  value.vendorName === lowestBidData?.vendorName
                    ? 'lowest-bid'
                    : '';

                const {
                  clarificationClarifications,
                  clarificationExceptions,
                  clarificationsExist,
                } = generateEcClarification(value?.contractLineEcClarification);

                return (
                  <td
                    data-testid={`${minReq?.optionCategoryCode}-${option?.optionCode}-${value?.vendorName}-${value?.modelName}`}
                    key={`${minReq?.optionCategoryCode}-${option?.optionCode}-${value?.vendorName}-${value?.modelName}`}
                    className={`contract-cell ${activateLowestBid}`}
                  >
                    {value?.optionValue}
                    {clarificationsExist && (
                      <span className="tooltip">
                        <span className="info-icon">
                          <InfoIcon role="img" />
                        </span>
                        <div className="tooltip-info">
                          <Clarifications
                            title="Equipment clarifications: "
                            clarifications={clarificationClarifications}
                          />
                          <Clarifications
                            title="Technical exceptions: "
                            clarifications={clarificationExceptions}
                          />
                          <i />
                        </div>
                      </span>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const getAccordionGroupContent = () => {
    return (
      <>
        {minimumRequirementsData?.map((minReq) => (
          <div className="min-req__container" key={minReq?.optionCategoryName}>
            <header className="min-req__header">
              {minReq?.optionCategoryName}
            </header>
            <div className="min-req__body">
              {getInnerAccordionContent(minReq)}
            </div>
          </div>
        ))}
      </>
    );
  };

  const getMainAccordionContent = () => {
    const accordionGroupProps = {
      items: [
        {
          title: 'Minimum Requirements',
          bordered: true,
          expanded: false,
          id: 'minimum-requirements',
          content: getAccordionGroupContent() || <></>,
        },
      ],
    };

    return (
      <div className="accordion-group-custom" ref={minimumRequirementsRef}>
        <Accordion {...accordionGroupProps} />
      </div>
    );
  };
  return getMainAccordionContent();
};

export default MinimumRequirementsSection;
