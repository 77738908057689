import React from 'react';

const ErrorDisplayComponent = () => {
  return (
    <>
      <div>
        <h1>404. That&apos;s an error.</h1>
        <div>The Requested URL was not found</div>
      </div>
    </>
  );
};

export default ErrorDisplayComponent;
