import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import { SUBMIT_REQUISITION } from '../../../../services/data-layer';

const RequisitionSubmitModal = ({ requisitionId, onClose, draftName }) => {
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [submitRequisitionMutation] = useMutation(SUBMIT_REQUISITION, {
    fetchPolicy: 'no-cache',
  });
  const onSubmit = async () => {
    const results = await submitRequisitionMutation({
      variables: {
        requisitionId,
        submitComment: comment,
      },
    });

    const resubmittedStatusParam =
      results?.data?.submitRequisition?.resubmittedStatus === true
        ? '&resubmittedStatus=true'
        : '';

    onClose();
    navigate(
      `/my-requisitions?requisitionId=${requisitionId}&draftName=${draftName}${resubmittedStatusParam}`,
    );
  };

  return (
    <div className="afp-modal-overlay modalContainer non-sop-submit-modal">
      <Modal
        title={<h1 className="modal-title">Confirm submission</h1>}
        className="save-draft-modal"
        onClose={onClose}
        actions={
          <div className="save-draft-requisition-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="modal-urgent-req-cancel-button"
              onClick={onClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="default"
              className="save-draft-requisition-action-button"
              data-testid="modal-urgent-req-submit-button"
              onClick={() => onSubmit()}
              label="Submit requisition"
            />
          </div>
        }
      >
        <div className="title-desc">
          Are you sure you want ot submit this requisition?
        </div>
        <div className="comment-section">
          <div className="title border-bottom"> Add a Comment</div>
          <div className="title-desc">
            Your comment will be added to the Activity tracker upon submission
            of this requisition and viewable by all participating parties
          </div>
          <TextInput
            type="textarea"
            data-testid="urgent-req-submit-comment"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            name="justification"
            inputClass="urg-req-input-text"
          />
        </div>
      </Modal>
    </div>
  );
};

RequisitionSubmitModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  draftName: PropTypes.string.isRequired,
};

export default RequisitionSubmitModal;
